import React, { useState, useEffect } from 'react';
import { Box, Grid, Table, TableBody, TableCell, TableRow,RadioGroup, Radio, FormControlLabel ,TextField, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from '../../utils/axios';
import _ from 'lodash';
import config from '../../config';
import Button from '../../common/components/button';
import CustomLoading from '../../common/components/CustomLoading';
import { notiFailed, notiSuccess } from '../../utils/helpers';
import IconSelect from '../../icons/arrow.svg';
import Text from '../../common/components/text';
import NumberFormat from 'react-number-format';
const { baseUrl } = config;

const useStyles = makeStyles(theme => ({
  pageContainer: {
    paddingTop: '52px',
    width: '65%',
    margin: '0 auto',
    transition: 'all 0.4s',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      width: '90%'
    }
  },
  pageTitle: {
    color: '#000',
    fontSize: '26px',
    fontWeight: 500,
    lineHeight: '26px',
    textTransform: 'uppercase',
    marginBottom: '15px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px !important',
      marginBottom: '-25px'
    }
  },
  infoEs: {
    fontSize: '14px',
    position: 'relative',
    '& span': {
      paddingLeft: '17px'
    },
    '& span::before': {
      position: 'absolute',
      left: 0,
      top: '50%',
      width: '12px',
      height: '1px',
      background: '#009ee3',
      content: '""'
    }
  },
  table: {
    marginTop: '10px',
    border: 'solid #000',
    borderWidth: '1px 0',
    '& td': {
      fontSize: '15px',
      fontFamily: 'Actual_Light',
      color: '#333'
    },
    '& .MuiTableCell-root': {
      padding: '24px'
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0
    },
    '& .MuiOutlinedInput-input': {
      padding: '13.5px 14px'
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiTableRow-root': {
        display: 'flex',
        flexDirection: 'column'
      },
      '& .MuiTableCell-root': {
        borderBottom: 0
      },
      '& .MuiTableRow-root td:first-child': {
        paddingBottom: '16px',
        paddingLeft: 0,
        paddingRight: 0
      },
      '& .MuiTableRow-root td:last-child': {
        padding: 0
      }
    }
  },
  icoEs: {
    position: 'relative',
    '&::after': {
      display: 'block',
      position: 'absolute',
      left: '1px',
      bottom: '-4px',
      width: '14px',
      height: '1px',
      background: '#009ee3',
      content: '""'
    }
  },
  customSelectField: {
    width: '100%', 
    marginRight: 10,
    height: '46px',
    padding: '0 20px 0 15px',
    background: `#fff url(${IconSelect}) right center /50px 8px no-repeat`,
    border: '1px solid #C4C4C4',
    boxSizing: 'border-box',
    color: '#333333',
    fontFamily: 'Actual',
    fontSize: '14px',
    fontWeight: '400',
    fontStyle: 'normal',
    verticalAlign: 'top',
    lineHeight: '38px',
    transition: 'background 0.3s, border 0.3s',
    '-moz-appearance': 'none',
    '-webkit-appearance': 'none',
    appearance: 'none',
    '&:focus': {
      outlineStyle: 'none',
      borderColor: '#000'
    },
    '&::-ms-expand': {
      display: 'none'
    }
  },
  fieldLabelTableCell: {
    width: '20%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}));


const validateSchemaFormEmail = yup.object({
  email : yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  title : yup.string().required('Title is required'),
  name : yup.string().required('Name is required'),
  password :  yup.string().min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/^(?=.*([a-z]|[A-Z]))(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'Password must be at least 8 characters in combination of letters, numbers, and symbols (0-9, A-Z, at least one symbol)').required('Password is required'),
  firstName : yup.string().required('First Name is required'),
  lastName : yup.string().required('First Name is required'),
  textNumber : yup.string().required('Number is required'),
  confirmPassword : yup.string().oneOf([yup.ref('password')], 'Passwords must match').required('Confirm Password is required')
})

function FormUpdateSubId({infoEdit}) {
  const classes = useStyles();
  const [listTitle, setListTitle] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initValue, setInitValue] = useState({
    email : '',
    name: '',
    title : 'Administrator',
    password : '',
    confirmPassword : '',
    type : '1',
    textOther : '',
    firstName : '',
    lastName : '',
    textNumber : '',
    mobileNumber : '',
    officeNumber : '',
    mobileType : '1'
  })

  useEffect(() => {
    getListTitle();
  }, []);

  useEffect(() => {
    formik.setFieldValue('email',_.get(infoEdit, 'email'));
    formik.setFieldValue('name',_.get(infoEdit, 'fullName'));
    formik.setFieldValue('firstName',_.get(infoEdit, 'firstName'));
    formik.setFieldValue('lastName',_.get(infoEdit, 'lastName'));
    formik.setFieldValue('mobileNumber',_.get(infoEdit, 'mobileNumber', ''));
    formik.setFieldValue('officeNumber',_.get(infoEdit, 'officeNumber', ''));
   
    if(!!_.get(infoEdit, 'officeNumber', '')) {
      formik.setFieldValue('mobileType', '2');
      formik.setFieldValue('textNumber', _.get(infoEdit, 'officeNumber', ''))
    }else {
      formik.setFieldValue('mobileType', '1');
      formik.setFieldValue('textNumber', _.get(infoEdit, 'mobileNumber', ''))
    }

  }, [_.get(infoEdit, 'email')])

  useEffect(() => {
    const isTitle = listTitle.filter(item => {
      return item.name === _.get(infoEdit, 'jobTitle')
    });

    if(isTitle.length === 0) {
      formik.setFieldValue('title', -1);
      formik.setFieldValue('textOther', _.get(infoEdit, 'jobTitle'))
    }else {
      formik.setFieldValue('title', _.get(infoEdit, 'jobTitle'));
      formik.setFieldValue('textOther', _.get(infoEdit, ''));
    }

  }, [_.get(infoEdit, 'jobTitle'), listTitle.length])

  async function getListTitle() {
    try {
      const { data } = await axios.get(`${baseUrl}/companies/register/job-titles`);
      setListTitle(data);
    } catch (e) {

    }
  }


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValue,
    validationSchema: validateSchemaFormEmail,
    onSubmit: async (values, {resetForm}) => {
      if(values.password !== values.confirmPassword) {
        return notiFailed('Please same password !')
      }

      let makePhone = {};

      if(formik.values.mobileType === '2') {
        makePhone = {
          officeNumber : formik.values.textNumber,
        }
      }else {
        makePhone = {
          mobileNumber : formik.values.textNumber,
        }
      }


      setLoading(true);
      await axios.patch(`${baseUrl}/users/sub/update`, {
        id : _.get(infoEdit, 'id'),
        name : values.name,
        password : formik.values.password,
        jobTitle : formik.values.title === '-1' ? formik.values.textOther : formik.values.title,
        ...makePhone,
        firstName : formik.values.firstName,
        lastName : formik.values.lastName
      }).then(() => {
        notiSuccess('Update Success !')
      }).catch(() => {
        notiFailed('Update Failed !')
      })

      setLoading(false);

    }
  });


  return(
    <div>
      <Text className={classes.pageTitle}>
        Profile
      </Text>
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <p className={classes.infoEs}><span>Required</span></p>
      </Box>

      <form onSubmit={formik.handleSubmit}>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell className={classes.fieldLabelTableCell}>
              <span className={classes.icoEs}>ID (E-mail)</span>
            </TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="email"
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    value={formik.values.email}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fieldLabelTableCell}>
              <span className={classes.icoEs}>Name</span>
            </TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="firstName"
                    placeholder={'First Name'}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    value={formik.values.firstName}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    placeholder={'Last Name'}
                    variant="outlined"
                    name="lastName"
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    value={formik.values.lastName}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fieldLabelTableCell}>
              <span className={classes.icoEs}>Title</span>
            </TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <select
                    id="title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    placeholder="Please select"
                    className={classes.customSelectField}
                  >
                    {listTitle?.map((item, index) => {
                      return <option key={index} value={item.name}>{item.name}</option>
                    })}
                    <option value={'-1'}>Other</option>
                  </select>
                </Grid>
                {formik.values.title === '-1' && <Grid item md={6} xs={12}>
                    <TextField
                      InputProps={{
                        classes: {
                          input: classes.textInput
                        }
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      name="textOther"
                      value={formik.values.textOther}
                      onChange={formik.handleChange}
                    />
                  </Grid>}

              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fieldLabelTableCell}>
              <span className={classes.icoEs}>Password</span>
            </TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="password"
                    name="password"
                    onChange={e => {
                      if (!formik.touched.password) {
                        formik.setFieldTouched('password');
                      }
                      formik.handleChange(e);
                    }}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    value={formik.values.password}
                    autoComplete="new-password"
                  />
                  {!formik.values.password ?
                    <Text size="small" style={{ marginTop: '7px' }}>
                      At least 8 characters in combination of letters, numbers, and symbols (0-9, A-Z, at least one symbol)
                    </Text>
                    : 
                    formik.errors.password && 
                    <Text size="small" style={{ marginTop: '7px' }}>
                      Password must be at least 8 characters in combination of letters, numbers, and symbols (0-9, A-Z, at least one symbol)
                    </Text>}
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fieldLabelTableCell}>
              <span className={classes.icoEs}>Confirm Password</span>
            </TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="password"
                    name="confirmPassword"
                    onChange={e => {
                      if (!formik.touched.confirmPassword) {
                        formik.setFieldTouched('confirmPassword');
                      }
                      formik.handleChange(e);
                    }}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    value={formik.values.confirmPassword}
                  />
                  {formik.errors.confirmPassword && formik.touched.confirmPassword && 
                    <Text size="small" style={{ marginTop: '7px' }}>
                      Passwords do not match
                    </Text>}
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fieldLabelTableCell}>
              <span className={classes.icoEs}>Main Contact Number</span>
            </TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="textNumber"
                    onChange={formik.handleChange}
                    error={formik.touched.textNumber && Boolean(formik.errors.textNumber)}
                    value={formik.values.textNumber}
                    helperText={formik.touched.textNumber && formik.errors.textNumber}
                    InputProps={{
                      inputComponent: NumberFormat
                    }}
                  />

                  <p style={{color :'gray'}}>* Numbers only (e.g., 9497774444)</p>

                </Grid>
                <Grid item>
                  <RadioGroup id="mobileType" value={formik.values.mobileType} name="mobileType" onChange={formik.handleChange}
                              row aria-label="mobileType"  defaultValue={'1'}>
                    <FormControlLabel classes={{label : classes.textInput}} value="2" control={<Radio color="primary"/>} label="Office Number"/>
                    <FormControlLabel classes={{label : classes.textInput}} value="1" control={<Radio color="primary"/>} label="Mobile Number"/>
                  </RadioGroup>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
        <Grid item style={{marginTop : 20}}>
          <Button type="submit">{loading ?  <CustomLoading color={'white'} size={18}/> : 'Save'}</Button>
        </Grid>
      </form>
    </div>
  )
}

export default FormUpdateSubId;
