import React, { useState, useEffect, useCallback } from 'react';
import Banner from '../../common/components/Banner';
import { useAuth } from '../../contexts/auth';
import SubIdManagementBanner from '../../config/images/sub-id-management-banner.jpg';
import BreadCrumb2 from './../../common/components/BreadCrumb2';
import Text from '../../common/components/text';
import { makeStyles } from '@material-ui/core/styles';
import debounce from 'lodash/debounce'
import {
    Grid,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    IconButton,
    Modal,
  Radio,
  MenuItem,
  TextField,
    FormControlLabel,
  RadioGroup,
    Checkbox,
    useMediaQuery
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import SelectIcon from '../../icons/ico_select.png';
import classNames from 'classnames';
import Pagination from '@material-ui/lab/Pagination';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import axios from '../../utils/axios';
import { notiFailed } from './../../utils/helpers.js';
import config from '../../config';
import _ from 'lodash';
import moment from 'moment';
import CustomLoading from './../../common/components/CustomLoading';
import { sendEventForSubId, ACTION_SEARCH_SUBID, ACTION_CHANGE_STATUS_SUBID, ACTION_REGISTER_NEW_SUBID } from './../../utils/gaHelpers';
import IconSelect from '../../icons/arrow.svg';
import { Scrollbars } from 'react-custom-scrollbars';

const useStyles = makeStyles(theme => ({
    pageContainer: {
        paddingTop: '52px',
        width: '80%',
        margin: '0 auto',
        transition: 'all 0.4s',
        marginBottom: '20px',
        [theme.breakpoints.down(1245)]: {
            width: '95%'
        },
        [theme.breakpoints.down(1060)]: {
            width: '90%',
            paddingTop: '30px'
        }
    },
    pageTitle: {
        color: '#000',
        fontSize: '26px',
        fontWeight: 500,
        lineHeight: '26px',
        textTransform: 'uppercase'
    },
    contactBtn: {
        display: 'inline-block',
        position: 'relative',
        height: '40px',
        padding: '0 28px',
        boxSizing: 'border-box',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '40px',
        background: '#062b55',
        color: '#fff',
        transition: 'all 0.4s',
        '&:hover': {
            background: '#011d3d'
        }
    },
    selectBox: {
        width: '180px',
        margin: '0 10px 0 0',
        color: '#999',
        height: '40px',
        padding: '0 20px 0 12px',
        border: '1px solid #ddd',
        boxSizing: 'border-box',
        fontFamily: 'Actual',
        fontSize: '14px',
        fontWeight: 'normal',
        verticalAlign: 'top',
        transition: 'background 0.3s, border 0.3s',
        background: `#fff url(${SelectIcon}) right center /25px 8px no-repeat`,
        '-webkit-appearance': 'none'
    },
    searchInput: {
        height: '40px',
        padding: '0 12px',
        background: '#fff',
        border: '1px solid #ddd',
        boxSizing: 'border-box',
        color: '#212121',
        fontFamily: 'Actual',
        fontSize: '14px',
        fontWeight: 'normal',
        verticalAlign: 'top',
        transition: 'background 0.3s, border 0.3s'
    },
    btn: {
        display: 'inline-block',
        minWidth: '25px',
        height: '40px',
        padding: '0 30px',
        background: '#666',
        color: '#fff',
        fontSize: '14px',
        lineHeight: '41px',
        textAlign: 'center',
        transition: 'all 0.4s',
        border: 0,
        '&:hover': {
            cursor: 'pointer',
            background: '#444'
        }
    },
    registerNewSubIdBtn: {
        marginLeft: '10px'
    },
    table: {
        border: 'solid #000',
        borderWidth: '1px 0',
        '& th': {
            padding: '25px 0 21px 0',
            fontWeight: 'normal',
            lineHeight: '20px',
            verticalAlign: 'middle',
            wordWrap: 'break-word',
            wordBreak: 'break-all',
            textAlign: 'center',
            backgroundColor: '#fff',
            borderBottom: '0',
            borderTop: '1px solid #eee'
        },
        '& td': {
            padding: '25px 0 21px 0',
            fontWeight: 'normal',
            lineHeight: '20px',
            verticalAlign: 'middle',
            wordWrap: 'break-word',
            wordBreak: 'break-all',
            textAlign: 'center',
            backgroundColor: '#fff',
            borderBottom: '0',
            borderTop: '1px solid #eee',
            color: '#666666'
        },
        '& p': {
            fontSize: '16px'
        }
    },
    changeStatusLabel: {
        display: 'inline-block',
        margin: '10px 10px 0 0',
        fontSize: '14px',
        height: '30px'
    },
    pagination: {
        "& .Mui-selected": {
            backgroundColor: '#00ADEF',
            color: '#fff'
        }
    },
    paper: {
        position: 'absolute',
        width: 900,
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down(920)]: {
            width: '95%',
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalHeader: {
        position: 'relative',
        padding: '16px 30px 14px 30px',
        background: '#38393e',
        boxSizing: 'border-box',
        '& h2': {
            position: 'relative',
            padding: '6px 0',
            color: '#fff',
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '18px',
            textAlign: 'left'
        }
    },
    modalBody: {
        position: 'relative',
        padding: '30px 30px 50px 30px',
        background: '#fff',
        [theme.breakpoints.down(600)]: {
            paddingLeft: '15px',
            paddingRight: '15px'
        }
    },
    textInput : {
        fontSize : 14
    },
    infoEs: {
        position: 'static',
        marginBottom: '14px',
        fontSize: '14px',
        lineHeight: '14px',
        textAlign: 'right',
        right: 0,
        top: '-29px',
        '& span': {
            display: 'inline-block',
            position: 'relative',
            paddingLeft: '17px'
        },
        '& span::before': {
            position: 'absolute',
            left: 0,
            top: '50%',
            width: '12px',
            height: '1px',
            background: '#009ee3',
            content: '""'
        }
    },
    inputContainer: {
        padding: '30px 40px',
        background: '#f8f8f8',
        border: 'solid #000',
        borderWidth: '1px 0',
        marginBottom: '20px',
        '& input': {
            width: '220px'
        },
        [theme.breakpoints.down(920)]: {
            '& input': {
                width: '100%',
                marginTop: '10px'
            }
        },
        [theme.breakpoints.down(600)]: {
            paddingLeft: '15px',
            paddingRight: '15px'
        }
    },
    icoEs: {
        fontSize: '15px',
        lineHeight: '40px',
        display: 'inline-block',
        position: 'relative',
        paddingBottom: 0,
        '&::after': {
            display: 'block',
            position: 'absolute',
            left: '1px',
            bottom: '5px',
            width: '14px',
            height: '1px',
            background: '#009ee3',
            content: '""'
        }
    },
    infoTs1: {
        position: 'relative',
        marginTop: '5px',
        color: '#999',
        fontSize: '13px',
        lineHeight: '17px'
    },
    closeBtn: {
        position: 'absolute',
        right: '8px',
        top: 0
    },
    tPoint: {
        '& td': {
            color: '#019ee3'
        }
    },
    stop: {
        '& td': {
            color: '#ccc'
        },
        '& .state': {
            color: '#d70606'
        }
    },
    noSubUsersContainer: {
        padding: '105px 0 101px 0',
        textAlign: 'center'
    },
    checkBoxContainer: {
        '& .Mui-checked .MuiSvgIcon-root': {
            fill: '#009ee3'
        }
    },
    searchContainer: {
        display: 'grid', 
        gridTemplateColumns: '100px auto', 
        gridColumnGap: '20px',
        '& select': {
            width: '100%'
        }
    },
    inputField: {
        '& .MuiOutlinedInput-notchedOutline': {
          transition: 'background 0.3s, border 0.3s',
          borderColor: '#C4C4C4'
        },
        '& .MuiInputBase-input': {
          color: '#333333',
        },
        '& .MuiInputBase-input::placeholder': {
          color: '#BDBDBD',
          opacity: 1
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: 0,
          fontSize: '14px',
          color: '#333333',
          fontFamily: 'Actual',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '17.5px'
        },
        '& .MuiOutlinedInput-input': {
          padding: '11px 15px'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: '#C4C4C4 !important'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#000 !important',
          borderWidth: '1px'
        },
        '& .MuiOutlinedInput-adornedEnd': {
          paddingRight: 0
        },
        '& a': {
            minWidth: '85px',
            width: '85px',
            padding: 0
        },
        [theme.breakpoints.down(400)]: {
            '& a': {
                minWidth: '60px',
                width: '60px',
                padding: 0
            }   
        }
    },
    card: {
        position: 'relative',
        fontFamily: 'Actual',
        minHeight: '150px',
        marginTop: '15px',
        padding: '23px 20px 21px 20px',
        border: 'solid #ddd',
        borderWidth: '1px 1px 1px 0',
        boxSizing: 'border-box',
        color: '#333',
        '&::before': {
            position: 'absolute',
            left: 0,
            top: '-1px',
            width: '2px',
            height: 'calc(100% + 2px)',
            background: '#019ee3',
            content: '""',
            zIndex: 9
        }
    },
    cardHeader: {
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        '& div': {
            display: 'flex', 
            alignItems: 'center'
        },
        '& .state': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '16px'
        },
        '& .MuiIconButton-root': {
            padding: 0,
            marginRight: '5px'
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#009ee3'
        }
    },
    cardContent: {
        marginTop: '20px',
        '& div': {
            display: 'grid',
            gridTemplateColumns: '100px auto',
            '& p': {
                fontSize: '12px !important'
            }
        }
    },
    primaryCard: {
        color: '#009ee3'
    },
    disableCard: {
        color: '#ccc',
        '& .state': {
            color: '#d70606'
        }
    },
    useCard: {
        '& .date': {
            color: '#999'
        }
    },
    changeStatusContainer: {
        '& select': {
            width: '100%'
        },
        '& div': {
            display: 'grid',
            gridTemplateColumns: 'auto 85px',
            gridColumnGap: '20px'
        }
    },
    changeStatusTitle: {
        color: '#999',
        marginTop: '25px',
        marginBottom: '5px'
    },
    registerNewSubIdMobileBtn: {
        position: 'relative',
        height: '40px',
        padding: '0 24px',
        boxSizing: 'border-box',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '40px',
        background: '#062b55',
        color: '#fff',
        transition: 'all 0.4s',
        display: 'inline-block',
        width: '100%',
        marginTop: '10px',
        textAlign: 'center',
        cursor: 'pointer'
    },
    pageTitleMobile: {
        fontSize: '16px !important',
        fontWeight: 500,
        lineHeight: '16px',
        textTransform: 'uppercase',
        marginBottom: '18px'
    },
    loadingIndicator: {
        textAlign: 'center',
        marginTop: '25px'
    },
    confirmChangeStatusBtn: {
        width: '18px', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        margin: '0 auto',
        '& .MuiCircularProgress-svg': {
            width: '18px'
        }
    },
    customSelectField: {
        width: 220, 
        marginRight: 10,
        height: '40px',
        padding: '0 20px 0 15px',
        background: `#fff url(${IconSelect}) right center /50px 8px no-repeat`,
        border: '1px solid #C4C4C4',
        boxSizing: 'border-box',
        color: '#333333',
        fontFamily: 'Actual',
        fontSize: '14px',
        fontWeight: '400',
        fontStyle: 'normal',
        verticalAlign: 'top',
        lineHeight: '38px',
        transition: 'background 0.3s, border 0.3s',
        '-moz-appearance': 'none',
        '-webkit-appearance': 'none',
        appearance: 'none',
        '&:focus': {
          outlineStyle: 'none',
          borderColor: '#000'
        },
        '&::-ms-expand': {
          display: 'none'
        },
        [theme.breakpoints.down(920)]: {
            width: '100%',
            marginRight: 0,
            marginTop: '10px'
        }
    },
    inputContentContainer: {
        [theme.breakpoints.down(920)]: {
            width: '100%'
        }
    },
    lastNameInput: {
        marginLeft : 10,
        [theme.breakpoints.down(920)]: {
            marginLeft : 0
        }
    },
    otherTitle: {
        width: 220,
        [theme.breakpoints.down(920)]: {
            width: '100%'
        }
    },
    phoneInputContainer: {
        marginRight: 10,
        [theme.breakpoints.down(920)]: {
            width: '100%',
            marginRight: 0
        }
    }
}));

const { baseUrl } = config;

export default function SubIdManagement() {
    const regEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    const classes = useStyles();
    const user = useAuth();
    const isChangeLayout = useMediaQuery('(max-width:1060px)');
    const [subIdUsers, setSubIdUsers] = useState([]);
    const [countSubIdUsers, setCountSubIdUsers] =  useState(0);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(5);
    const [loading, setLoading] = useState(false);
    const [openNewSubIdModal, setOpenNewSubIdModal] = useState(false);
    const [filters, setFilters] = useState({ offset, limit });
    const [currentPage, setCurrentPage] = useState(1);
    const [countPage, setCountPage] = useState(1);
    const [searchType, setSearchType] = useState('email');
    const [searchValue, setSearchValue] = useState('');
    const [selectedUpdateStatus, setSelectedUpdateStatus] = useState('B');
    const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
    const [emailDebound, setEmailDebound] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);
    const [infoUserDuplicate, setInfoUserDuplicate] = useState({
        companyName: "",
        email: "",
        firstName: "",
        fullName: "",
    })


    const [newSubIdUser, setNewSubIdUser] = useState({
        email: '',
        name: '',
        title: '',
        firstName : '',
        lastName : '',
        textOther : '',
        mobileNumberText: '',
        contactNumber: '1'
    });

    const [listTitle, setListTitle] = useState([]);
    const [loadingAddNewSubUser, setLoadingAddNewSubUser] = useState(false);

    const handleOpen = () => {
        setOpenNewSubIdModal(true);
    };

    const handleClose = () => {
        setOpenNewSubIdModal(false);
    };

    const getSubIdUsers = async (filters = {}) => {
        try {
            setLoading(true);
            const { data } = await axios.get(`${baseUrl}/users/sub/search`, { params: filters });
            setSubIdUsers(data.data);
            setCountSubIdUsers(data.total);
            const numberPage = Math.ceil(data.total / limit);
            setCountPage(numberPage);
        } catch (err) {
            notiFailed(`Something happened getting subid users`);
        } finally {
            setLoading(false);
        }
    };

    async function getJobTitle() {

        try {
            const { data } = await axios.get(`${baseUrl}/companies/register/job-titles`);
            setListTitle(data);
        } catch (e) {
            notiFailed(`Something happened getting subid users`);
        }
    }

    const handleChangePage = (event, currentPage) => {
        const newOffset = limit * currentPage - limit;
        const newFilters = {
            ...filters,
            offset: newOffset
        };
        setCurrentPage(currentPage);
        setOffset(newOffset);
        setFilters(newFilters);
        getSubIdUsers(newFilters);
    };

    const handleChangeSearchType = e => {
        setSearchType(e.target.value);
    };

    const handleChangeSearchValue = e => {
        setSearchValue(e.target.value);
    };

    const handleChecked = (id, checked) => {
        setSubIdUsers(subIdUsers.map(u => {
            if (u.id === id) {
                return {
                    ...u,
                    checked
                };
            }

            return u;
        }));
    };

    const handleSearch = () => {
        const filter = {
            ...filters,
            offset: 0
        };
        delete filter.name;
        delete filter.email;
        if (searchValue) {
            filter[searchType] = searchValue;
        }

        setOffset(0);
        setCurrentPage(1);
        setFilters(filter);
        getSubIdUsers(filter);
        sendEventForSubId(ACTION_SEARCH_SUBID);
    };

    const handleChangeStatus = async () => {
        const checked = subIdUsers.filter(u => u.checked).map(u => u.id);

        if (checked.length === 0) {
            return notiFailed(`Please select`);
        }

        let endPoint = `${baseUrl}/users/sub/disable`;
        if (selectedUpdateStatus === 'A') {
            endPoint = `${baseUrl}/users/sub/in-use`;
        }

        try {
            setLoadingChangeStatus(true);
            await axios.post(endPoint, checked);
            sendEventForSubId(ACTION_CHANGE_STATUS_SUBID);
            getSubIdUsers(filters);
        } catch (err) {
            notiFailed(`Something happened change status subid users`);
        } finally {
            setLoadingChangeStatus(false);
        }
    };

    async function checkValidateEmail(value) {
        const { data } = await axios.get(`${config.baseUrl}/companies/register/check-email`, { params: { email: value } });

        if(data.email) {
            setIsDuplicateEmail(true)
            setIsValidEmail(false);
            setInfoUserDuplicate(data);
        }else {
            setIsDuplicateEmail(false)
            setInfoUserDuplicate({
                companyName: "",
                email: "",
                firstName: "",
                fullName: "",
            })
        }
    }

    useEffect(() => {
        if(newSubIdUser.email !== '') {
            const isValid = validateEmail(newSubIdUser.email)
            setIsValidEmail(isValid);
        }
    }, [newSubIdUser.email])

    const debounce = useCallback(
      _.debounce((value) => {

          if(value !== '') {
              checkValidateEmail(value)
          }

      }, 200),
      []
    );

    function handleChangeEmail(event) {
        setNewSubIdUser({
            ...newSubIdUser,
            email: event.target.value
        })
        debounce(event.target.value)
    }

    const handleChangeNewSubId = e => {
        setNewSubIdUser({
            ...newSubIdUser,
            [e.target.name]: e.target.value
        });
    };



    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleAddNewSubIdUser = async () => {



        if(isDuplicateEmail) {
            return notiFailed('Email Duplicated !');
        }

        if (_.trim(newSubIdUser.email) === '') {
            return notiFailed('Please enter E-mail address.');
        }

        if (!regEmail.test(newSubIdUser.email)) {
            return notiFailed('Invalid E-mail address. Please check again and re-enter\n(e.g., user@emailaddress.com).');
        }

        if (_.trim(newSubIdUser.title) === '' || newSubIdUser.title === '-2') {
            return notiFailed('Please enter your title.');
        }

        if (_.trim(newSubIdUser.title) === '-1' && _.trim(newSubIdUser.textOther) === '') {
            return notiFailed('Please enter your other title.');
        }

        if (_.trim(newSubIdUser.firstName) === '') {
            return notiFailed('Please enter your first name.');
        }

        if (_.trim(newSubIdUser.lastName) === '') {
            return notiFailed('Please enter your last name.');
        }



        if (_.trim(newSubIdUser.mobileNumberText) === '')  {
            return notiFailed('Please enter your phone number.');
        }

        if (!/^[0-9]*$/.test(newSubIdUser.mobileNumberText)) {
            return notiFailed('Phone number can only contain numbers e.g., 9497774444');
        }

        let infoMobile = {};

        if(newSubIdUser.contactNumber === '2') {
            infoMobile = {
                mobileNumber : newSubIdUser.mobileNumberText
            }
        }else {
            infoMobile = {
                officeNumber : newSubIdUser.mobileNumberText
            }
        }

        const makePayload = {
            ...newSubIdUser,
            ...infoMobile,
            jobTitle : newSubIdUser.title === '-1' ? newSubIdUser.textOther : newSubIdUser.title
        }

        try {
            setLoadingAddNewSubUser(true);
            await axios.post(`${baseUrl}/users/sub`, makePayload);
            sendEventForSubId(ACTION_REGISTER_NEW_SUBID);
            setNewSubIdUser({
                email: '',
                name: '',
                jobTitle: '',
                textOther : '',
                mobileNumberText: ''
            });
            handleClose();
            const filter = {
                ...filters,
                offset: 0
            };
            setOffset(0);
            setCurrentPage(1);
            setFilters(filter);
            getSubIdUsers(filters);
        } catch (err) {
            notiFailed(`Something happened add new subid users`);
        } finally {
            setLoadingAddNewSubUser(false);
        }
    };

    useEffect(() => {
        getSubIdUsers(filters);
        getJobTitle();
    }, []);

    return (
        <>
            <Banner
                imageUrl={SubIdManagementBanner}
                className="OrderHistory_Banner banner100"
            >
                <h1 className="title_animation OrderHistory_Title">Sub Id Management</h1>
                <BreadCrumb2 items={[`${user.firstName} ${user.lastName}`, 'Sub Id Management']}></BreadCrumb2>
            </Banner>

            {!isChangeLayout ?
            <div className={classes.pageContainer}>
                <Text className={classes.pageTitle}>
                    Sub Id Management
                </Text>

                <Grid container style={{ paddingTop: '30px' }}>
                    <Grid
                        container
                        item
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <Link to="/support/contact-us" className={classes.contactBtn}>Having trouble? Contact us now</Link>
                        </Grid>
                        <Grid item>
                            <select className={classes.selectBox} onChange={handleChangeSearchType}>
								<option value="email">ID</option>
								<option value="name">Name</option>
							</select>
                            <input type="text" value={searchValue} className={classes.searchInput} onChange={handleChangeSearchValue} />
                            <a className={classes.btn} onClick={handleSearch}>Search</a>
                            <a className={`${classes.btn} ${classes.registerNewSubIdBtn}`} onClick={handleOpen}>Register new sub ID</a>
                        </Grid>
                    </Grid>
                </Grid>

                <TableContainer
                    component={Paper}
                    style={{ marginTop: '20px', boxShadow: 'none', marginBottom: '0px' }}
                >
                    <Table
                        stickyHeader
                        size="small"
                        aria-label="sticky table"
                        className={classes.table}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Text weight="light">
                                        Registration Date
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text weight="light">
                                        ID (E-mail)
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text weight="light">
                                        Name
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text weight="light">
                                        Main Contact Number
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text weight="light">
                                        Account Status
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text weight="light">
                                        Select
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan="6">
                                        <div className={classes.loadingIndicator}>
                                            <CustomLoading size={40} color="#00adef"></CustomLoading>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    {countSubIdUsers === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan="6">
                                                <div className={classes.noSubUsersContainer}>
                                                    <Text>No item is registered.</Text>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ) : subIdUsers.map(u => (
                                        <TableRow key={u.id} className={classNames('', {
                                            [classes.tPoint]: u.role === 'Admin' || u.role === 'Primary',
                                            [classes.stop]: u.disabled
                                        })}>
                                            <TableCell>
                                                <Text weight="light">
                                                    {moment(u.createdAt).format('MM/DD/YYYY')}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text weight="light">
                                                    {u.email}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text weight="light">
                                                    {_.trim(`${u.firstName} ${u.lastName}`)}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text weight="light">
                                                    {u.officeNumber ? u.officeNumber : u.mobileNumber}
                                                </Text>
                                            </TableCell>
                                            <TableCell className="state">
                                                <Text weight="light">
                                                    {u.disabled ? 'Disabled' : 'In Use'}
                                                </Text>
                                            </TableCell>
                                            <TableCell className={classes.checkBoxContainer}>
                                                {u.role === 'Admin' || u.role === 'Primary' ? (
                                                    <Text weight="light">
                                                        Primary
                                                    </Text>
                                                ) : (
                                                    <Checkbox
                                                        checked={!!u.checked}
                                                        size="medium"
                                                        onChange={e => handleChecked(u.id, e.target.checked)}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                { !loading && countPage > 1 && (
                    <Grid container justify="center" style={{ paddingTop: '30px' }}>
                        <Pagination count={countPage} page={currentPage} onChange={handleChangePage} color="primary" className={classes.pagination}/>
                    </Grid>
                )}
                <Grid container style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                    <Grid
                        container
                        item
                        justify="flex-end"
                        alignItems="flex-end"
                    >
                        <label className={classes.changeStatusLabel}>Change Status (selected)</label>
                        <select className={classes.selectBox} value={selectedUpdateStatus} onChange={e => setSelectedUpdateStatus(e.target.value)}>
                            <option value="B">Disabled</option>
                            <option value="A">In Use</option>
                        </select>
                        <button onClick={handleChangeStatus} className={classes.btn} disabled={loadingChangeStatus} style={{ padding: '0 15px', minWidth: '90px' }}>
                            <p style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {loadingChangeStatus ? <CustomLoading color="#fff" size={18} /> : 'Confirm'}
                            </p>
                        </button>
                    </Grid>
                </Grid>
            </div>
            : 
            <div className={classes.pageContainer}>
                <Text className={classes.pageTitleMobile}>
                    Sub Id Management
                </Text>
                <div className={classes.searchContainer}>
                    <select className={classes.selectBox} onChange={handleChangeSearchType}>
                        <option value="email">ID</option>
                        <option value="name">Name</option>
                    </select>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={searchValue}
                        onChange={handleChangeSearchValue}
                        className={classes.inputField}
                        InputProps={{
                          endAdornment: (
                            <a className={classes.btn} onClick={handleSearch}>Search</a>
                          )
                        }}
                    />
                </div>
                <div className={classes.cardContainer}>
                    {loading ? (
                        <div className={classes.loadingIndicator}>
                            <CustomLoading size={40} color="#00adef"></CustomLoading>
                        </div>
                    ) : (
                        <>
                            {
                                subIdUsers.map(u => (
                                    <div 
                                        className={classNames([classes.card], {
                                            [classes.primaryCard]: u.role === 'Admin' || u.role === 'Primary',
                                            [classes.disableCard]: u.disabled,
                                            [classes.useCard]: !u.disabled && u.role !== 'Admin' && u.role !== 'Primary'
                                        })} 
                                        key={u.id}
                                    >
                                        <div className={classes.cardHeader}>
                                            <div>
                                                {u.role !== 'Admin' && u.role !== 'Primary' &&
                                                    <Checkbox
                                                        checked={!!u.checked}
                                                        size="medium"
                                                        onChange={e => handleChecked(u.id, e.target.checked)}
                                                    />
                                                }
                                                <span className="state">
                                                    {u.role === 'Admin' || u.role === 'Primary' 
                                                    ? 'MASTER' : u.disabled ? 'Disabled' : 'In Use'}
                                                </span>
                                            </div>
                                            <p className="date">2018-04-26</p>
                                        </div>
                                        <div className={classes.cardContent}>
                                            <div>
                                                <p className="title">ID (E-mail)</p>
                                                <p className="value">{u.email}</p>
                                            </div>
                                            <div>
                                                <p className="title">Name</p>
                                                <p className="value">{_.trim(`${u.firstName} ${u.lastName}`)}</p>
                                            </div>
                                            <div>
                                                <p className="title">Contact Number</p>
                                                <p className="value">{u.officeNumber ? u.officeNumber : u.mobileNumber}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }      
                        </>
                    )}
                </div>
                { !loading && countPage > 1 && (
                    <Grid container justify="center" style={{ paddingTop: '30px' }}>
                        <Pagination count={countPage} page={currentPage} onChange={handleChangePage} color="primary" className={classes.pagination}/>
                    </Grid>
                )}
                <div className={classes.changeStatusContainer}>
                    <p className={classes.changeStatusTitle}>Change Status (selected)</p>
                    <div>
                        <select className={classes.selectBox} value={selectedUpdateStatus} onChange={e => setSelectedUpdateStatus(e.target.value)}>
                            <option value="B">Disabled</option>
                            <option value="A">In Use</option>
                        </select>
                        <button onClick={handleChangeStatus} className={classes.btn} disabled={loadingChangeStatus}>
                            <p className={classes.confirmChangeStatusBtn}>
                                {loadingChangeStatus ? <CustomLoading color="#fff" size={18} /> : 'Confirm'}
                            </p>
                        </button>
                    </div>
                </div>
                <a className={classes.registerNewSubIdMobileBtn} onClick={handleOpen}>Register new sub ID</a>
                <Link to="/support/contact-us" className={classes.registerNewSubIdMobileBtn}>Having trouble? Contact us now</Link>
            </div>}
            <Modal
                open={openNewSubIdModal}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >
                <div className={classes.paper}>
                    <div className={classes.modalHeader}>
                        <h2>Register New Sub ID</h2>
                    </div>
                    <Scrollbars autoHeight autoHeightMin={452}>
                        <div className={classes.modalBody}>
                            <p className={classes.infoEs}><span>Required</span></p>

                            <div className={classes.inputContainer}>
                                <Grid container style={{ marginBottom: '10px' }}>
                                    <Grid item style={{width : 200}}>
                                        <span className={classes.icoEs}>ID (E-mail)</span>
                                    </Grid>
                                    <Grid item className={classes.inputContentContainer}>
                                        <input style={{border : `${!isValidEmail ? '1px solid red' : ''}`}} type="text" className={classes.searchInput} name="email" value={newSubIdUser.email} onChange={handleChangeEmail} />
                                        {isDuplicateEmail &&
                                        <Text size="small" style={{ marginTop: '7px' }}>

                                            Your company already has a sub user <span className={classes.italic}>{infoUserDuplicate.fullName}, </span>
                                            <span className={classes.italic}>{infoUserDuplicate.email}</span>.<br/>
                                            <br/>
                                        </Text>}
                                    </Grid>

                                </Grid>

                                <Grid container  style={{ marginBottom: '10px' }}>
                                    <Grid item style={{width : 200}}>
                                        <span className={classes.icoEs}>Name</span>
                                    </Grid>
                                    <Grid item spacing={2} className={classes.inputContentContainer}>
                                        <input type="text" className={classes.searchInput} placeholder="First Name" name="firstName" value={newSubIdUser.firstName} onChange={handleChangeNewSubId} />
                                        <input type="text" placeholder="Last Name" className={`${classes.searchInput} ${classes.lastNameInput}`} name="lastName" value={newSubIdUser.lastName} onChange={handleChangeNewSubId} />
                                    </Grid>
                                </Grid>
                                <Grid container  style={{ marginBottom: '10px' }}>
                                    <Grid item style={{width : 200}}>
                                        <span className={classes.icoEs}>Title</span>
                                    </Grid>
                                    <Grid item className={classes.inputContentContainer}>
                                        <select
                                            id="title"
                                            name="title"
                                            placeholder="Please select"
                                            className={classNames([classes.customSelectField])}
                                            defaultValue={'-2'}
                                            onChange={handleChangeNewSubId}
                                        >
                                            <option value={'-2'}>Please select</option>
                                            {listTitle?.map((item, index) => {
                                                return <option key={index} value={item.name}>{item.name}</option>
                                            })}
                                            <option value={'-1'}>Other</option>
                                        </select>
                                        {newSubIdUser.title === '-1' && <input
                                        id="textOther"
                                        name="textOther"
                                        onChange={handleChangeNewSubId}
                                        className={`${classes.searchInput} ${classes.otherTitle}`}
                                        />}

                                    </Grid>
                                </Grid>

                                <Grid container style={{ marginBottom: '10px' }}>
                                    <Grid item style={{width : 200}}>
                                        <span className={classes.icoEs}>Main Contact Number</span>
                                    </Grid>
                                    <Grid item className={classes.phoneInputContainer}>
                                        <input 
                                            type="text" 
                                            className={classes.searchInput} 
                                            name="mobileNumberText" 
                                            value={newSubIdUser.mobileNumberText} 
                                            onChange={e => {
                                                if (!/^[0-9]*$/.test(e.target.value)) {
                                                    return;
                                                }
                                                handleChangeNewSubId(e);
                                            }}/>
                                        <p className={classes.infoTs1}>* Numbers only (e.g., 9497774444)</p>
                                    </Grid>
                                    <Grid item>
                                        <RadioGroup id="contactNumber"
                                                    onChange={handleChangeNewSubId}
                                                    row aria-label="position" name="contactNumber" defaultValue="1">
                                            <FormControlLabel  value="1" control={<Radio color="primary"/>} label="Office Number"/>
                                            <FormControlLabel  value="2" control={<Radio color="primary"/>} label="Mobile Number"/>
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </div>
                            <Grid container justify="center" alignItems="center">
                                <button style={{ padding: '0 15px', minWidth: '90px' }} className={classes.btn} onClick={handleAddNewSubIdUser} disabled={loadingAddNewSubUser}>
                                    <p style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {loadingAddNewSubUser ? <CustomLoading color="#fff" size={18} /> : 'Confirm'}
                                    </p>
                                </button>
                            </Grid>
                        </div>
                    </Scrollbars>

                    <IconButton onClick={handleClose} className={classes.closeBtn}>
                        <CloseOutlinedIcon
                            className="btn_close_order_details"
                            fontSize="large"
                            style={{ color: '#FFF' }}
                        />
                    </IconButton>
                </div>
            </Modal>
        </>
    )
}
