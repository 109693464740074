import React, {useState, useEffect} from 'react';
import Banner from '../../common/components/Banner';
import BannerImage from '../../config/images/visual_area01_2.jpg';
import TitleAnimation from '../../common/components/TitleAnimation';
import BreadCrumb2 from '../../common/components/BreadCrumb2';
import Text from '../../common/components/text';
import axios from 'axios';
import {baseUrl} from '../../config/index';
// import Pagination from '@material-ui/lab/Pagination';
import NewsCard from './components/NewsCard';
import {Grid} from '@material-ui/core';
import styled from 'styled-components';
import Pagination from './components/Pagination';
import { useAuth } from '../../contexts/auth';
import config from '../../config';

const StyledTitleContainer = styled.div `
  width: 1200px;
  margin: auto;
  @media (max-width: 1240px) {
    width: 790px;
  }

  @media (max-width: 830px) {
    width: 590px;
  }

  @media (max-width: 630px) {
    width: 380px;
  }

  @media (max-width: 400px) {
    width: 320px;
  }

  @media (max-width: 350px) {
    width: 280px;
  }

  @media (max-width: 310px) {
    width: 220px;
  }
`;

const StyledCardContainer = styled.div `
  width: 1200px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;

  @media (max-width: 1240px) {
    width: 790px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 830px) {
    width: 590px;
  }

  @media (max-width: 630px) {
    width: 380px;
    grid-template-columns: 1fr;
  }

  @media (max-width: 400px) {
    width: 320px;
  }

  @media (max-width: 350px) {
    width: 280px;
  }

  @media (max-width: 310px) {
    width: 220px;
  }
`;

const StyledTitle = styled.p `
  font-size: 26px !important;
  font-weight: 500;
  padding-top: 60px;
  width: 1200px;
  margin: auto;
`;

export default function Notice() {
  const user = useAuth();
  const [noticeData, setNoticeData] = useState([]);
  const [count, setCount] = useState();
  const [limit, setLimit] = useState(6);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState({ limit, offset });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [width, setWindowWidth] = useState(0);

  const getNoticeData = async (filters = {}) => {
    const {data} = await axios.get(`${baseUrl}/notice/search`, {params: filters})

    if (!user.authenticated) {
      const filteredData = data.data.filter(d => d.id == config.idNewsWithoutPromoCode)
      setCount(Math.ceil(filteredData.length / 6))
      setNoticeData(filteredData)
      setTotalData(filteredData.length);
    } else {
      setCount(Math.ceil(data.total / 6))
      setNoticeData(data.data)
      setTotalData(data.total);
    }
  }

  const handlePageChange = (e, currentPage) => {
    setCurrentPage(currentPage);
    const newOffset = limit * currentPage - limit;
    setOffset(newOffset);
    const filter = {
      ...filters,
      offset: newOffset
    };
    getNoticeData(filter);
  }

  useEffect(() => { 
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => 
      window.removeEventListener('resize',updateDimensions);
   }, [])
   const updateDimensions = () => {
     const width = window.innerWidth
     setWindowWidth(width)
   }

  useEffect(()=> {
    getNoticeData(filters);
  }, []);

  return (
    <section
      style={{
        backgroundColor: 'var(--light-color, #ffffff)',
        position: 'relative',
        paddingBottom: '100px'
      }}
    >
      <BreadCrumb2 items={['News', 'Notice']}></BreadCrumb2>
      <Banner imageUrl={BannerImage} className="banner100">
        <TitleAnimation>NOTICE</TitleAnimation>
      </Banner>
      
      <StyledTitleContainer>
        <StyledTitle>NOTICE</StyledTitle>
      </StyledTitleContainer>
      <StyledCardContainer>
          {noticeData.length > 0 ? noticeData.map((notice, index) => {
            return (
                <NewsCard
                  index={index}
                  id={notice.id}
                  date={notice.createdAt}
                  title={notice.title}
                  body={notice.content}
                  hits={notice.views}
                />
            )
          }) : false}
      </StyledCardContainer>
      {/* <Grid
      container
      justify="center"
      item
      xs={12}
      style={{ marginTop: '10px', marginBottom: '20px' }}
      alignItems="center"
      spacing={2}
    >
      <Pagination page={currentPage} onChange={handlePageChange} count={count} variant="outlined" shape="rounded" />
    </Grid> */}
    <Grid
      container
      justify="center"
      item
      xs={12}
      style={{ marginTop: '10px', marginBottom: '20px', margin: 'auto' }}
      alignItems="center"
      spacing={width >= 600 ? 3 : 1}
    >
    <Pagination
      uploadsPerPage={6}
      totalUploads={totalData}
      pagenumber={currentPage}
      handlePageChange={handlePageChange}
    />
    </Grid>
    </section>
  )
}