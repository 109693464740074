import React from 'react';
import CheckBoxOn from '../../../config/images/bg_checkbox_on.png';
import CheckBox from '../../../config/images/bg_checkbox.png';
import _ from 'lodash';
import {useHistory} from 'react-router-dom';
import {decodeStringHtml} from '../../../utils/helpers';
import moment from 'moment';

function CardMostViewDataHub({ tab, type,  product, selectedCards, id, handleSelectedCard, width}) {


  const history = useHistory();

  function handleSelectCard(event) {
    event.stopPropagation();
    handleSelectedCard(id)

  }

  return (
    <div onClick={() => history.push(`/salestools/data-hub/${type === 2 ? 'video-content' : 'download-center'}/${id}`)} className={`${width <= 480 ? `car-data-hub-full` : `car-data-hub-full-most`} card-data-hub-video`}>

      <div
        style={{ backgroundImage: `url(${_.get(product, 'thumbnail.assetUrl')})` }}
        className={`${tab === 1 ? 'img-thumbnail-full' : 'img-thumbnail'}`}>

      </div>
      <div className={`${tab === 2 ? 'text-info-data-hub' : 'text-info-data-hub-full'} ${type === 2 ? 'wrap-info-time' : ''}`}>
              <span className={'text-title-data-hub'}>
                {decodeStringHtml(product?.title)}
              </span>

        {type === 1 && <div className="wrap-info-file">
          <span className={'border-right'}/>
          <span>{_.get(product, 'attachment.assetKey').split('.').pop().toUpperCase()}</span>
          <span className={'border-left'}/>
          <span>{Math.round(product?.fileSize / 1024)} KB</span>

        </div>}
        {type === 2 && <span className={'time-video'}>{moment(product.updatedAt).format('YYYY-MM-DD')}</span>}
      </div>
    </div>
  );
}

export default CardMostViewDataHub;
