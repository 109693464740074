import * as yup from 'yup';
import _ from 'lodash';

export const schemaEss = yup.object().shape({
  // claimType : yup.string().notOneOf(['select'], 'Claim Type is required'),
  // type : yup.string().notOneOf(['select'], 'Value Claim Type is required'),

  //installerName : yup.string().required('Name is required'),
  //installerAddress1 : yup.string().required('Address is required'),
  //installerAddress2 : yup.string().required('Address2 is required'),
  //installerCity : yup.string().required('City is required'),
  //installerState : yup.string().notOneOf(['select'], 'State is required'),
  //installerContact : yup.string().required('Contact name is required'),
  //installerZipcode : yup.string().required('Zipcode is required'),
  // installerEmail: yup.string().required('Email is required').matches(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/, 'Invalid email address'),
  //installerPhone: yup.string().required("Contact number is required").matches(/^[0-9]*$/, "This entry can only contain numbers (e.g., 9497774444)."),

  //claimType : yup.string().notOneOf(['select'], 'Claim Type is required'),
  description : yup.string().required('Description of your issue'),
  errorLog : yup.string().required('Other evidence is required'),

  isUploadProofDate : yup.string().when(
    ['validateProofOfDate'], {
      is : (validateProofOfDate) => validateProofOfDate,
      then : yup.string().required('Proof of Permission-to-Operate Date is required')
    }
  ),


  // projectSys : yup.string().notOneOf(['select'], 'Project is required'),
  // isSearchProject : yup

  // projectNameSys : yup.string().when('choseTypeProject',)
  projectNameSys : yup.string().when(
    ['choseTypeProject', 'projectInfo'], {
      is: (choseTypeProject, projectInfo) => projectInfo !== null || choseTypeProject === '2' || (choseTypeProject === '1' && projectInfo === null),
      then: yup.string().required('Project Name is required')
    }),

  // projectAddress : yup.string().when(
  //   ['choseTypeProject', 'projectInfo'], {
  //     is: (choseTypeProject, projectInfo) => projectInfo !== null || choseTypeProject === '2' || (choseTypeProject === '1' && projectInfo === null),
  //     then: yup.string().required('Project Address is required')
  //   }),

  projectAddressManual: yup.object().nullable().when('choseTypeProject', { is: value => value === '2', then: yup.object().nullable().notOneOf([null], 'project Address is required') }),
  projectStateManual : yup.string().when('choseTypeProject', {
    is : (value) => value === '2',
    then : yup.string().notOneOf(['select'], 'State is required')
  }),

  projectCityManual : yup.string().when('choseTypeProject', {
    is : (value) => value === '2',
    then : yup.string().required('City is required')
  }),

  projectZipCodeManual : yup.string().when('choseTypeProject', {
    is : (value) => value === '2',
    then : yup.string().required("Zipcode is required").test({ name: 'length', message: 'Zipcode must be 5 digits long', exclusive: true, test: val => val && _.toString(_.trim(val)).length === 5 })
  }),

  projectInstallationDate : yup.mixed().when(
    ['choseTypeProject', 'projectInfo'], {
      is: (choseTypeProject, projectInfo) => projectInfo !== null || choseTypeProject === '2' || (choseTypeProject === '1' && projectInfo === null),
      then: yup.mixed().nullable().notOneOf([null], 'Installation date is required')
    }),


  projectSystemType : yup.string().when(
    ['choseTypeProject', 'projectInfo'], {
      is: (choseTypeProject, projectInfo) => choseTypeProject === '2' || (choseTypeProject === '1' && projectInfo === null),
      then: yup.string().notOneOf(['select'], 'System Type is required')
    }),

  // projectPowerClass : yup.string().when(
  //   'choseTypeProject', {
  //     is: (choseTypeProject) => choseTypeProject === '2',
  //     then: yup.string().notOneOf(['select'], 'Power Class is required')
  //   }),
  //
  projectProductName : yup.string().when(
    ['choseTypeProject', 'projectInfo'], {
      is: (choseTypeProject, projectInfo) => projectInfo !== null || choseTypeProject === '2' || (choseTypeProject === '1' && projectInfo === null),
      then: yup.string().notOneOf(['select'], 'Product Name is required')
    }),

  projectInverterPower : yup.string().when(
    ['choseTypeProject', 'projectInfo'], {
      is: (choseTypeProject, projectInfo) => projectInfo !== null || choseTypeProject === '2' || (choseTypeProject === '1' && projectInfo === null),
      then: yup.string().notOneOf(['select'], 'Product Name is required')
    }),
  //
  // projectNumberOfPanel : yup.string().when(
  //   'choseTypeProject', {
  //     is: (choseTypeProject) => choseTypeProject === '2',
  //     then: yup.string().required('Number Of Panel is required')
  //   }),

  // projectId : yup.object().when(
  //   'choseTypeProject', {
  //     is : (choseTypeProject) => choseTypeProject === '1',
  //     then : yup.object().shape({
  //       id : yup.string
  //     }).required('Project Id is required')
  //   }
  // ),

  serialNumbers : yup.array().when('choseTypeSerialUpload', {
    is: value => value === '1',
    then: yup.array().min(1, 'Serial Number is required')
  }),

  // proofOfPermissionToOperateDate : yup.string().when(
  //   'choseTypeProject', {
  //     is : (choseTypeProject) => choseTypeProject === '2',
  //     then : yup.string().required('proofOfPermissionToOperateDate is required')
  //   }
  // ),

  isUploadSerialNumber : yup.string().when(
    'choseTypeSerialUpload', {
      is : (choseTypeSerialUpload) => choseTypeSerialUpload === '2',
      then : yup.string().required('Upload Serial Number is required')
    }
  ),

  projectInfo: yup.object().nullable().when('choseTypeProject', { is: value => value === '1', then: yup.object().nullable().notOneOf([null], 'projectId is required') }),

  // projectInverterSerialNumber : yup.string().when(
  //   ['choseTypeProject', 'projectInfo'], {
  //     is: (choseTypeProject, projectInfo) => choseTypeProject === '2' || (choseTypeProject === '1' && projectInfo === null),
  //     then: yup.string().required('Inverter Serial Number is required').test({ name: 'length', message: 'Serial must be 14 digits long', exclusive: true, test: val => val && _.toString(_.trim(val)).length === 14 })
  //   }),

  numberOfBattery : yup.string().notOneOf(['select'], 'Number Of Battery is required'),
  //totalPowerInstaller : yup.string().required('Total PV Power installed is required'),


  // distributorName : yup.string().required('Distributor Name is required'),
  invoiceForLaborCost : yup.string().when(
    'chooseRefund', {
      is : (chooseRefund) => chooseRefund === '2',
      then : yup.string().required('Invoice for Labor Cost Reimbursement is required')
    }
  ),
  infoBank : yup.string().when(
    ['chooseBank', 'chooseRefund'], {
      is : (chooseBank, chooseRefund) => chooseBank === '2' && chooseRefund === '2',
      then : yup.string().required('Bank Account Information is required')
    }
  ),


  purchasingChannel: yup.string().notOneOf(['select'], 'Purchasing channel is required'),
  nameOfChanelOtherDistributor : yup.string().when(['nameOfPurchasingChannelDistributor'], {
    is : (nameOfPurchasingChannelDistributor) => nameOfPurchasingChannelDistributor !== null && nameOfPurchasingChannelDistributor?.name === 'Other',
    then : yup.string().required('nameOfChanelOtherDistributor is required')
  }),
  nameOfPurchasingChannelDistributor: yup.object().nullable().when('purchasingChannel', { is: value => value === 'distributor', then: yup.object().nullable().notOneOf([null], 'Name of purchasing channel is required') }),
  nameOfPurchasingChannelOther: yup.string().when('purchasingChannel', { is: value => value === 'other', then: yup.string().required('Name of purchasing channel is required') }),
  //nameOfPurchasingChannelSelect: yup.string().when('purchasingChannel', { is: value => value === 'select', then: yup.string().required('Please select Purchasing Channel') }),
  nameOfPurchasingChannelSelect: yup.object().nullable().when('purchasingChannel', { is: value => value === 'select', then: yup.object().nullable().notOneOf([null], 'Name of purchasing channel is required') }),




  // projectNameSys: yup.string().required('Project Name is required'),
  // addressSys : yup.string().required('Address is required'),
  // installationDateSys : yup.mixed().nullable().notOneOf([null], 'Installation date is required'),
  // productNameSys : yup.string().required('Product Name is required'),
  // systemTypeSys : yup.string().notOneOf(['select'], 'System Type is required'),
  // inverterSerialNumberSys : yup.string().required('Inverter serial number is required'),
  // inverterPowerSys : yup.string().required('Inverter power is required'),
  // batterySerialNumberSys : yup.string().required('Batteries Serial Number is required'),
  // batteryTypeSys : yup.string().required('Battery Type is required'),
  // numberOfBattery : yup.string().required('Number of batteries is required'),



  // totalPvPowerInstalled : yup.string().required('Total PV Power installed is required')
  // distributorName : yup.string().required('Distributor Name is required'),

});
