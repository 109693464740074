import React, { useState, useEffect, useMemo } from 'react';
import BreadCrumb2 from '../../common/components/BreadCrumb2';
import Banner from '../../common/components/Banner';
import RegistrationBanner from '../../config/images/registration-banner.jpg';
import { makeStyles } from '@material-ui/core';
import { useAuth } from '../../contexts/auth';
import Navbar from './components/Navbar';
import ProjectInfo from './components/ProjectInfo';
import SystemInfo from './components/SystemInfo';
import PurchasingInfo from './components/PurchasingInfo';
import HomeOwnerInfo from './components/HomeOwnerInfo';
import ReviewInfo from './components/ReviewInfo';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from '../../utils/axios';
import config from '../../config';
import { notiFailed, notiLabor, notiSuccess } from '../../utils/helpers.js';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import RouteLeavingGuard from '../registration/RouteLeavingGuard';
import useExitPrompt from './../../hooks/useExitPrompt';
import { projectService } from '../../services';
import Notification from './components/Notification';
import BannerProjectRes from '../claim/components/BannerProjectRes';
import {keyProject} from './components/keyProject';
import { schemaProject } from './components/schemaProject';
import { initialState } from './components/initialStateProject';

const useStyles = makeStyles(theme => ({
    container: {
        width: '72.5%',
        margin: '60px auto 70px auto',
        [theme.breakpoints.down(1245)]: {
            width: '90%',
            marginTop: '30px'
        },
        [theme.breakpoints.down(700)]: {
            width: '92%'
        }
    },
    // tagRequired: {
    //     background: '#EB5757',
    //     position: 'relative',
    //     padding: '2px 8px',
    //     marginLeft: '15px',
    //     marginRight: '7px',
    //     zIndex: 1,
    //     color: '#fff',
    //     '&::before' : {
    //         background: '#EB5757',
    //         width: '13px',
    //         height: '13px',
    //         content: '""',
    //         display: 'inline-block',
    //         position: 'absolute',
    //         left: '-7px',
    //         bottom: '4px',
    //         transform: 'rotate( 45deg)',
    //         zIndex: 2
    //     }
    // },
}));


const { baseUrl } = config;

export default function ProjectRegistration() {
    const classes = useStyles();
    const user = useAuth();
    const history = useHistory();
    const location = useLocation();
    const [currentStep, setCurrentStep] = useState(0);
    const [serialNumberFile, setSerialNumberFile] = useState('');
    const [essSerialNumberFile, setEssSerialNumberFile] = useState('');
    const [batteryCapacities, setBatteryCapacities] = useState([]);
    const [saleRegions, setSaleRegions] = useState([]);
    const [distributors, setDistributors] = useState([]);
    const [filesProject, setFilesProject] = useState([]);
    const [filesProof, setFilesProof] = useState([]);
    const [productModels, setProductModels] = useState([]);
    const [essProductModels, setEssProductModels] = useState([]);
    const [powerClassesModules, setPowerClassesModules] = useState([]);
    const [modelModules, setModelModules] = useState([]);
    const [brandsPanel, setBrandsPanel] = useState([]);
    const [brandsBattery, setBrandsBattery] = useState([]);
    const [batteryModels, setBatteryModels] = useState([]);
    // const [powerClassesEss, setPowerClassesEss] = useState([]);
    const [isEnablePreviewButton, setEnablePreviewButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSaveDraft, setLoadingSaveDraft] = useState(false);
    const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
    const [pvInverterBrands, setPvInverterBrands] = useState([]);
    const [pvInverterModels, setPvInverterModels] = useState([]);
    // const [rackingBrands, setRackingBrands] = useState([]);
    // const [rackingModels, setRackingModels] = useState([]);
    const [batteryInverterPower, setBatteryInverterPower] = useState([]);
    // const [pow]
    const [expandAccordion, setExpandAccordion] = useState({
        expandPanel: true,
        expandBattery: true,
        expandInverter: false,
        expandRacking: false
    })
    const { register, handleSubmit, errors, control, watch ,setValue, trigger ,getValues, clearErrors, formState } = useForm({
        resolver : yupResolver(schemaProject),
        mode: "onChange",
        reValidateMode: "onChange",
        shouldUnregister: false,
        shouldFocusError: false,
        defaultValues: initialState
    });
    const data = watch();
    const moduleBrand = watch('moduleBrand');
    const isSerialNumber = watch('isSerialNumber');
    const moduleSerialList = watch('moduleSerialNumber');



    const volume = watch('modulePowerClass');
    const numberOfModules = watch('numberOfModules');

    const batteryBrand = watch('batteryBrand');
    const modelBattery = watch('batteryModel');
    const essSerial = watch('essSerialNumber');
    const isEssSerialNumber = watch('isEssSerialNumber');
    const isManualSerialPanelNotMatchDb = watch('isManualSerialPanelNotMatchDb');
    const isDuplicateAddress = watch('isDuplicateAddress')

    const pvInverterBrand = watch('pvInverterBrand');
    const pvInverterModel = watch('pvInverterModel');
    // console.log(pvInverterBrand, 'pvInverterBrand Lisr');
    // console.log(isSerialNumber, 'ss');

    const moduleSerial = useMemo(() => {
        const uniqModuleSerialNumber = _.remove(
          _.uniqBy(moduleSerialList, 'productName'),
          p => p.productName
        );

        return uniqModuleSerialNumber
    }, [moduleSerialList]);


    const goBack = () => setCurrentStep(currentStep - 1);
    const next = () => {
        setCurrentStep(currentStep + 1);
    };

    const handleGoToReview = () => {
        setCurrentStep(4);

    };

    // useEffect(() => {
    //     isDuplicateAddress && notiFailed('This address is already used in another project.')
    // }, [isDuplicateAddress])

    useEffect(() => {
        scrollToTop();
    },[currentStep])

    useEffect(() => {
        scrollToTop()
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        if(currentStep === 4) {
            trigger(keyProject).then(r => console.log(r));
        }
    }, [currentStep])

    // useEffect(() => {
    //     setValue('firstName', _.get(user, 'firstName', ''));
    //     setValue('lastName', _.get(user, 'lastName', ''));
    // }, [user?.id])


    const renderStep = () => {
        switch(currentStep) {
            case 0:
                return (
                    <ProjectInfo
                        goBack={goBack}
                        next={next}
                        register={register}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        isEnablePreviewButton={isEnablePreviewButton}
                        handleGoToReview={handleGoToReview}
                        saleRegions={saleRegions}
                        handleSaveDraft={handleSaveDraft}
                        loadingSaveDraft={loadingSaveDraft}
                        filesProject={filesProject}
                        filesProof={filesProof}
                        setFilesProject={setFilesProject}
                        setFilesProof={setFilesProof}
                        watch={watch}
                        clearErrors={clearErrors}
                    />
                )
            case 1:
                return (
                    <SystemInfo
                        goBack={goBack}
                        next={next}
                        register={register}
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        errors={errors}
                        serialNumberFile={serialNumberFile}
                        batteryCapacities={batteryCapacities}
                        setSerialNumberFile={setSerialNumberFile}
                        isEnablePreviewButton={isEnablePreviewButton}
                        handleGoToReview={handleGoToReview}
                        handleSaveDraft={handleSaveDraft}
                        loadingSaveDraft={loadingSaveDraft}
                        clearErrors={clearErrors}
                        productModels={productModels}
                        essProductModels={essProductModels}
                        essSerialNumberFile={essSerialNumberFile}
                        setEssSerialNumberFile={setEssSerialNumberFile}
                        expandAccordion={expandAccordion}
                        handleChangeAccordion={handleChangeAccordion}
                        brandsPanel={brandsPanel}
                        brandsBattery={brandsBattery}
                        pvInverterBrands={pvInverterBrands}
                        pvInverterModels={pvInverterModels}
                        batteryInverterPower={batteryInverterPower}
                        modelModules={modelModules}
                        powerClassesModules={powerClassesModules}
                        batteryModels={batteryModels}
                    />
                )
            case 2:
                return (
                    <PurchasingInfo
                        goBack={goBack}
                        next={next}
                        register={register}
                        control={control}
                        watch={watch}
                        errors={errors}
                        isEnablePreviewButton={isEnablePreviewButton}
                        handleGoToReview={handleGoToReview}
                        distributors={distributors}
                        handleSaveDraft={handleSaveDraft}
                        loadingSaveDraft={loadingSaveDraft}
                    />
                )
            case 3:
                return (
                    <HomeOwnerInfo
                        goBack={goBack}
                        next={next}
                        register={register}
                        control={control}
                        errors={errors}
                        isEnablePreviewButton={isEnablePreviewButton}
                        handleGoToReview={handleGoToReview}
                        handleSaveDraft={handleSaveDraft}
                        loadingSaveDraft={loadingSaveDraft}
                        watch={watch}
                    />
                )
            case 4:
                return (
                    <ReviewInfo
                        loading={loading}
                        goBack={goBack}
                        goToStep={goToStep}
                        getValues={getValues}
                        watch={watch}
                        filesProof={filesProof}
                        errors={errors}
                        control={control}
                        disabled={!formState?.isDirty || !formState?.isValid}
                        loadingSaveDraft={loadingSaveDraft}
                        handleSaveDraft={handleSaveDraft}
                        handleSubmit={handleSubmit(onSubmit, onSubmitFailed)}
                    />
                )
            default:
                return (
                    <ProjectInfo
                        goBack={goBack}
                        next={next}
                        register={register}
                        control={control}
                        errors={errors}
                        isEnablePreviewButton={isEnablePreviewButton}
                        handleGoToReview={handleGoToReview}
                        saleRegions={saleRegions}
                        handleSaveDraft={handleSaveDraft}
                        loadingSaveDraft={loadingSaveDraft}
                        watch={watch}
                    />
                )
        }
    }



    const goToStep = step => setCurrentStep(step);
    useEffect(() => {
        getModuleModels();
        getSaleRegions();
        getBatteryCapacities();
        getDistributors();
        getProductModels();
        getEssProductModels();
        getBrandsSystemInfo(1);
        getBrandsSystemInfo(2);
        getBrandsSystemInfo(3, true);
        // getPowerClass('modules');
    }, []);

    useEffect(() => {
        if(modelBattery !== '') {
            getBatteryInverterPower(modelBattery);
        }
    }, [modelBattery])

    useEffect(() => {
        if(batteryBrand !== 'select') {
          const brandsBatteryId = brandsBattery?.find(item => item.name === batteryBrand)?.id
          getBatteryModels(brandsBatteryId);
        }
    }, [batteryBrand])

    useEffect(() => {
        if(pvInverterBrand !== 'select') {
            const pvInverterBrandsId = pvInverterBrands?.find(item => item.name === pvInverterBrand)?.id
            getPvInverterModels(pvInverterBrandsId);
        }
    }, [pvInverterBrand])

    // useEffect(() => {
    //     if(volume !== 'select' || volume !== '' ) {
    //         getModelsByPowerClass('modules', volume);
    //     }
    // }, [volume])

    useEffect(() => {
        if (currentStep === 4) {
            setEnablePreviewButton(true);
            if(!getQualifyLaborWarranty(getValues())) {
                notiLabor(`This project will not qualify for labor reimbursement. Please review the reasons on this page.`);
            }
        }
    }, [currentStep]);

    useEffect(() => {
        if (formState.isDirty) {
          if (location.pathname === '/projects/project-registration'){
            setShowExitPrompt(true);
          }
        }
    }, [formState.isDirty]);

    useEffect(() => {
        setValue('projectPictures', filesProject);
    }, [filesProject]);


    useEffect(() => {
        setValue('projectPictureProf', filesProof);
    }, [filesProof]);

    const handleSaveDraft = async () => {
        const currentData = getValues();
        const data = _.pickBy(currentData, value => {
            if (value === 'select') {
                return false;
            }

            if (!value) {
                return false;
            }

            if (_.get(value, 'length') === 0) {
                return false;
            }

            return true;
        });

        const formData = new FormData();

        const fields = ['projectAddress2', 'projectCity',
            'projectState', 'projectZipCode', 'purchasingChannel', 'firstName', 'lastName',
            'email', 'phoneNumber', 'note'];

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            const fieldVal = data[field];
            if (fieldVal) {
                formData.append(field, fieldVal);
            }
        }

        formData.append('projectName', getProjectName(data));
        formData.append('isManualSerialPanelNotMatchDb', _.get(data, 'isManualSerialPanelNotMatchDb', false))
        formData.append('isManualSerialBatteryNotMatchDb', _.get(data, 'isManualSerialBatteryNotMatchDb', false))
        formData.append('region', data.region);
        formData.append('latitude', data.latitude);
        formData.append('longitude', data.longitude);
        formData.append('projectAddress', _.get(data.projectAddress, 'label', ''));

        if (_.get(data, 'projectPictures.length') > 0) {
            formData.append('projectImages', _.join(_.map(_.filter(_.get(data, 'projectPictures'), p => p.url), p => p.url), '|'));
        }

        if (_.get(data, 'projectPictureProf.length') > 0) {
            formData.append('proofOfOperateDate', _.join(_.map(_.filter(_.get(data, 'projectPictureProf'), p => p.url), p => p.url), '|'));
        }

        if (data.installationDate) {
            formData.append('installationDate', moment(data.installationDate).format('YYYY-MM-DD'));
        }

        if (data.operateDate) {
            formData.append('operateDate',  moment(data.operateDate).format('YYYY-MM-DD'));
        }
        //done project information

        let serialNumbers = [];

        //panel
        if (data.battery && data.moduleBrand === 'Q CELLS' && data.isSerialNumber
          && _.get(data, 'moduleSerialNumber.length') > 0) {
            for (let i = 0; i < data.moduleSerialNumber.length; i++) {
                const current = data.moduleSerialNumber[i];
                const serialNumber = {
                    type: 'modules',
                    serialNumber: current?.serialNumber || '',
                    productType: data?.moduleBrand || '',
                    productName: data?.moduleModel || '',
                    volume: data?.modulePowerClass || '',
                    source: 'web'
                };
                serialNumbers.push(serialNumber);
            }
        }

        //battery
        if (data.battery && ['AC', 'DC'].includes(data.systemType) && data.batteryBrand === 'Q CELLS'
          && _.get(data, 'essSerialNumber.length') > 0) {
            for (let i = 0; i < data.essSerialNumber.length; i++) {
                const current = data.essSerialNumber[i];
                const serialNumber = {
                    type: 'inverter',
                    serialNumber: current?.serialNumber || '',
                    productName: data?.batteryModel || '',
                    source: 'web'
                };
                serialNumbers.push(serialNumber);
            }
        }

        formData.append('serialNumbers', JSON.stringify(serialNumbers));
        formData.append('manualSerialNumber', !!currentData.isManualSerialNumber);
        formData.append('manualInverter', !!currentData.isManualEssSerialNumber);

        const isManualProductInfo = (currentData.moduleBrand === 'select' && currentData.moduleBrand !== 'Q CELLS') ||
          (currentData.moduleBrand === 'Q CELLS' && data.isSerialNumber && currentData.isManualSerialNumber) ||
          (currentData.moduleBrand === 'Q CELLS' && !data.isSerialNumber);

        if(data.moduleBrand) {
            formData.append('moduleBrand', data.moduleBrand);
        }

        if(data.moduleBrand === 'Other') {
            formData.append('nameOfModuleBrandPanelOther', data.nameOfModuleBrandPanelOther)
        }



        //change productName -> moduleModel
        if (data.moduleModel) {
            formData.append('moduleModel', data.moduleModel);
        }
        //change volume -> modulePowerClass

        if (data.modulePowerClass) {
            formData.append('modulePowerClass', data.modulePowerClass);
        }
        if (data.numberOfModules) {
            formData.append('numberOfModules', data.numberOfModules);
        }
        //battery = yes
        const isBattery = data.battery;
        formData.append('battery', !!isBattery);
        if (isBattery && data.systemType) {
            formData.append('systemType', data.systemType);
        }
        if (isBattery && data.batteryBrand) {
            formData.append('batteryBrand', data.batteryBrand);
        }
        //battery: battery = no || systemSize = select || AC/DC
        // if (isBattery) {
        //     if (data.batteryEnergy && data.batteryBrand === 'Q CELLS') {
        //         formData.append('batteryEnergy', data.batteryEnergy);
        //     }
        // }
        //battery: AC/DC && qcells

        //battery: AC/DC && not qcells
        if (isBattery && !isSelectBatteryCapacity) {
            if (data.batteryCapacity2) {
                formData.append('batteryCapacity', data.batteryCapacity2);
            }

        }

        formData.append('isEssSerialNumber', isEssSerialNumber)

        // if(data.batteryBrand === 'Q CELLS' && !isEssSerialNumber) {
        //     formData.append('batteryInverterPower', data.batteryInverterPower);
        // }

        // if (data.batteryModel) {
        //     formData.append('batteryModel', data.batteryModel);
        // }

        const batteryModel = data?.batteryBrand === 'Q CELLS' ? (isEssSerialNumber ? _.get(essSerial, '[0].productName', data.batteryModel) : data.batteryModel) : data.batteryModel;
        const batteryInverterPower = data?.batteryBrand === 'Q CELLS' ? (isEssSerialNumber ? _.get(essSerial, '[0].volume', data.batteryInverterPower) : data.batteryInverterPower) : data.batteryInverterPower;
        //const batteryEnergy = data?.batteryBrand === 'Q CELLS' ? data.batteryEnergy : '';
        const isSelectBatteryCapacity = ['AC', 'DC'].includes(data.systemType) && data.batteryBrand === 'Q CELLS';
        if (isBattery && isSelectBatteryCapacity) {
            if (data.batteryEnergy && data.batteryEnergy !== 'select' && isBattery) {
                formData.append('batteryEnergy', data.batteryEnergy);
            }
        }

        //formData.append('isSerialNumber', data.isSerialNumber);
        if(batteryModel && batteryModel !== 'select' && isBattery) {
            formData.append('batteryModel', batteryModel);
        }

        if(data.batteryBrand === 'Other') {
            formData.append('nameOfModuleBrandBatteryOther', data.nameOfModuleBrandBatteryOther)
        }

        if(batteryInverterPower && batteryInverterPower !== 'select' && isBattery) {
            formData.append('batteryInverterPower', batteryInverterPower);
        }


        //!!batteryInverterPower &&
        //!!batteryEnergy && formData.append('batteryEnergy', batteryEnergy);

        data.pvInverterBrand && formData.append('pvInverterBrand', data.pvInverterBrand);

        if(data.pvInverterBrand === 'Other') {
            formData.append('namePvInverterBrandOther', data.namePvInverterBrandOther);
        }

        data.pvInverterModel && formData.append('pvInverterModel', data.pvInverterModel);

        data.rackingBrand && formData.append('rackingBrand', data.rackingBrand);

        if(data.rackingBrand === 'other') {
            formData.append('nameRackingBrandOther', data.nameRackingBrandOther)
        }

        data.rackingModel && formData.append('rackingModel', data.rackingModel);


        if (data.purchasingChannel) {
            let nameOfPurchasingChannel = '';
            switch(data.purchasingChannel) {
                case 'distributor':
                    nameOfPurchasingChannel = _.get(data, 'nameOfPurchasingChannelDistributor.name');
                    break;
                case 'other':
                    nameOfPurchasingChannel = data.nameOfPurchasingChannelOther;
                    break;
                case 'qcells':
                    nameOfPurchasingChannel = 'Q CELLS';
                default:
                    nameOfPurchasingChannel = null;
                    break;
            }

            if (nameOfPurchasingChannel) {
                formData.append('nameOfPurchasingChannel', nameOfPurchasingChannel);
            }
            nameOfPurchasingChannel === 'Other' && data.purchasingChannel === 'distributor' && formData.append('nameOfChanelOtherDistributor', data.nameOfChanelOtherDistributor || '');
        }

        formData.append('panelQualified', _.get(data, 'panelQualified', false))
        formData.append('batteryQualified', _.get(data, 'batteryQualified', false))
        formData.append('panelNonQcells', _.get(data, 'panelNonQcells', false))
        formData.append('batteryNonQcells', _.get(data, 'batteryNonQcells', false))

        formData.append('isMobileNumber', data.selectedContactNumber === '1');
        formData.append('isDraft', true);
        formData.append('source', 'web');
        formData.append('qualifyLaborWarranty', getQualifyLaborWarranty(data));
        formData.append('systemSize', getSystemSize(data));
        //formData.append('note', data.note)

        try {
            setLoadingSaveDraft(true);
            const { data } = await axios.post(`${baseUrl}/projects/save`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            notiSuccess('Draft saved successfully!');
            history.push({
                pathname: `/projects/draft/${data.id}/0`,
                state: {
                  disablePrompt: true
                }
            });
        } catch (err) {
            notiFailed(`There was a problem when edit draft project ${err.message}`);
        } finally {
            setLoadingSaveDraft(false);
        }
    };

    const onSubmit = async data => {
        const formData = new FormData();

        formData.append('projectName', getProjectName(data));
        formData.append('isManualSerialPanelNotMatchDb', data.isManualSerialPanelNotMatchDb)
        formData.append('isManualSerialBatteryNotMatchDb', data.isManualSerialBatteryNotMatchDb)
        formData.append('projectAddress', _.get(data.projectAddress, 'label', ''));
        formData.append('latitude', data.latitude);
        formData.append('region', data.region);
        formData.append('longitude', data.longitude);
        formData.append('projectAddress2', data.projectAddress2);
        formData.append('projectCity', data.projectCity);
        formData.append('projectState', data.projectState);
        formData.append('projectZipCode', data.projectZipCode);
        if (_.get(data, 'projectPictures.length') > 0) {
            formData.append('projectImages', _.join(_.map(_.filter(_.get(data, 'projectPictures'), p => p.url), p => p.url), '|'));
        }else {
            formData.append('projectImages', '');
        }

        if (_.get(data, 'projectPictureProf.length') > 0) {
            formData.append('proofOfOperateDate', _.join(_.map(_.filter(_.get(data, 'projectPictureProf'), p => p.url), p => p.url), '|'));
        }else {
            formData.append('proofOfOperateDate', '');
        }
        formData.append('installationDate', moment(data.installationDate).format('YYYY-MM-DD'));
        if (data.operateDate) {
            formData.append('operateDate',  moment(data.operateDate).format('YYYY-MM-DD'));
        }

        formData.append('moduleBrand', data.moduleBrand);
        //formData.append('isSerialNumber', data.isSerialNumber);
        let serialNumbers = [];
        if (data.moduleBrand === 'Q CELLS' && data.isSerialNumber
        && _.get(data, 'moduleSerialNumber.length') > 0) {
            for (let i = 0; i < data.moduleSerialNumber.length; i++) {
                const current = data.moduleSerialNumber[i];
                const serialNumber = {
                    type: 'modules',
                    serialNumber: current?.serialNumber,
                    productType: data?.moduleBrand,
                    productName: data?.moduleModel,
                    volume: data?.modulePowerClass,
                    source: 'web'
                };
                serialNumbers.push(serialNumber);
            }
        }

        if (data.battery && ['AC', 'DC'].includes(data.systemType) && data.batteryBrand === 'Q CELLS'
            && _.get(data, 'essSerialNumber.length') > 0) {
            for (let i = 0; i < data.essSerialNumber.length; i++) {
                const current = data.essSerialNumber[i];
                const serialNumber = {
                    type: 'inverter',
                    serialNumber: current?.serialNumber,
                    productName: data?.batteryModel,
                    source: 'web'
                };
                serialNumbers.push(serialNumber);
            }
        }
        formData.append('serialNumbers', JSON.stringify(serialNumbers));
        formData.append('manualSerialNumber', !!data.isManualSerialNumber);
        formData.append('manualInverter', !!data.isManualEssSerialNumber);

        const isManualProductInfo = (data.moduleBrand === 'select'|| data.moduleBrand !== 'Q CELLS') ||
        (data.moduleBrand === 'Q CELLS' && data.isSerialNumber && data.isManualSerialNumber) ||
        (data.moduleBrand === 'Q CELLS' && !data.isSerialNumber);

        if(data.moduleModel) {
            formData.append('moduleModel', data.moduleModel);
        }

        if(data.moduleBrand === 'Other') {
            formData.append('nameOfModuleBrandPanelOther', data.nameOfModuleBrandPanelOther)
        }

        if(data.modulePowerClass) {
            formData.append('modulePowerClass', data.modulePowerClass);
        }

        if(data.numberOfModules) {
            formData.append('numberOfModules', data.numberOfModules);
        }

        // if (isManualProductInfo) {
        //     formData.append('moduleModel', data.moduleModel);
        //     formData.append('modulePowerClass', data.modulePowerClass);
        //     formData.append('numberOfModules', data.numberOfModules);
        // }

        const isBattery = data.battery;
        formData.append('battery', !!isBattery);
        if (isBattery) {
            formData.append('systemType', data.systemType);
            formData.append('batteryBrand', data.batteryBrand);
        }
        const isSelectBatteryCapacity = ['AC', 'DC'].includes(data.systemType) && data.batteryBrand === 'Q CELLS';
        if (isBattery && isSelectBatteryCapacity) {
            if (data.batteryCapacity1) {
                formData.append('batteryCapacity', data.batteryCapacity1);
            }
            if (data.batteryEnergy && data.batteryEnergy !== 'select' && isBattery) {
                formData.append('batteryEnergy', data.batteryEnergy);
            }
        }
        if (isBattery && !isSelectBatteryCapacity) {
            if (data.batteryCapacity2) {
                formData.append('batteryCapacity', data.batteryCapacity2);
            }
            if (data.batteryProductName1) {
                formData.append('batteryProductName', data.batteryProductName1);
            }
        }
        formData.append('isEssSerialNumber', isEssSerialNumber)
        const batteryModel = data?.batteryBrand === 'Q CELLS' ? (isEssSerialNumber ? _.get(essSerial, '[0].productName', data.batteryModel) : data.batteryModel) : data.batteryModel;
        const batteryInverterPower = data?.batteryBrand === 'Q CELLS' ? (isEssSerialNumber ? _.get(essSerial, '[0].volume', data.batteryInverterPower) : data.batteryInverterPower) : data.batteryInverterPower;
        //const batteryEnergy = data?.batteryBrand === 'Q CELLS' ? data.batteryEnergy : '';

        if(batteryModel && batteryModel !== 'select' && isBattery) {
            formData.append('batteryModel', batteryModel);
        }

        if(data.batteryBrand === 'Other') {
            formData.append('nameOfModuleBrandBatteryOther', data.nameOfModuleBrandBatteryOther)
        }

        if(batteryInverterPower && batteryInverterPower !== 'select' && isBattery) {
            formData.append('batteryInverterPower', batteryInverterPower);
        }

        //!!batteryModel && formData.append('batteryModel', batteryModel);
        //!!batteryInverterPower && formData.append('batteryInverterPower', batteryInverterPower);
        //!!batteryEnergy && formData.append('batteryEnergy', batteryEnergy);
        formData.append('panelQualified', data.panelQualified)
        formData.append('batteryQualified', data.batteryQualified)
        formData.append('panelNonQcells', data.panelNonQcells)
        formData.append('batteryNonQcells', data.batteryNonQcells)


        if (!isBattery || (isBattery && data.systemType === 'select')) {
            if (data.inverterProductBrand) {
                formData.append('inverterProductType', data.inverterProductBrand);
            }
            if (data.inverterProductName) {
                formData.append('inverterProductName', data.inverterProductName);
            }
        }

        if (isBattery && data.systemType === 'AC') {
            if (data.pvInverterProductBrand) {
                formData.append('pvProductType', data.pvInverterProductBrand);
            }
            if (data.pvInverterProductName) {
                formData.append('pvProductName', data.pvInverterProductName);
            }
        }


        data.pvInverterBrand && formData.append('pvInverterBrand', data.pvInverterBrand);
        if(data.pvInverterBrand === 'Other') {
            formData.append('namePvInverterBrandOther', data.namePvInverterBrandOther);
        }
        data.pvInverterModel && formData.append('pvInverterModel', data.pvInverterModel);

        data.rackingBrand && formData.append('rackingBrand', data.rackingBrand);
        if(data.rackingBrand === 'other') {
            formData.append('nameRackingBrandOther', data.nameRackingBrandOther)
        }
        data.rackingModel && formData.append('rackingModel', data.rackingModel);

        formData.append('purchasingChannel', data.purchasingChannel);
        let nameOfPurchasingChannel = '';
        switch(data.purchasingChannel) {
            case 'distributor':
                nameOfPurchasingChannel = _.get(data, 'nameOfPurchasingChannelDistributor.name');
                break;
            case 'other':
                nameOfPurchasingChannel = data.nameOfPurchasingChannelOther;
                break;
            case 'qcells':
            default:
                nameOfPurchasingChannel = 'Q CELLS';
                break;
        }
        formData.append('nameOfPurchasingChannel', nameOfPurchasingChannel);
        nameOfPurchasingChannel === 'Other' && data.purchasingChannel === 'distributor' && formData.append('nameOfChanelOtherDistributor', data.nameOfChanelOtherDistributor || '');

        formData.append('firstName', data.firstName);
        formData.append('lastName', data.lastName);
        formData.append('email', data.email);
        formData.append('phoneNumber', data.phoneNumber);
        formData.append('isMobileNumber', data.selectedContactNumber === '2');

        if (data.note) {
            formData.append('note', data.note);
        }
        formData.append('isDraft', false);
        formData.append('source', 'web');
        formData.append('qualifyLaborWarranty', getQualifyLaborWarranty(data));
        formData.append('systemSize', getSystemSize(data));

        try {
            setLoading(true);
            await axios.post(`${baseUrl}/projects/save`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            notiSuccess('Register project successfully!');
            history.push({
                pathname: '/projects/myprojects',
                state: {
                  disablePrompt: true
                }
            });
        } catch (err) {
            notiFailed(`There was a problem when register new project ${err.message}`);
        } finally {
            setLoading(false);
        }
    };

    const onSubmitFailed = async errors => {
        showErrorMessage(errors, keyProject)
    };

    const handleChangeAccordion = key => {
        setExpandAccordion({
            ...expandAccordion,
            [key]: !expandAccordion[key]
        })
    };

    const getBatteryCapacities = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/projects/battery-capacities`);
            setBatteryCapacities(data);
        } catch (err) {
            notiFailed(`Something happened getting battery capacities`);
        }
    };

    const getSaleRegions = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/companies/salesregions/search`);
            setSaleRegions(data);
        } catch (err) {
            notiFailed(`Something happened getting sale regions`);
        }
    };

    const getDistributors = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/distributors`);
            setDistributors(data);
        } catch (err) {
            notiFailed(`Something happened getting distributors`);
        }
    };

    const getProductModels = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/projects/product-name/modules`);
            setProductModels(data);
        } catch (err) {
            notiFailed(`Something happened getting product models`);
        }
    };

    async function getPowerClass(productType) {
        try {
          const { data } = await projectService.getPowerClass(productType);
          productType === 'modules' && setPowerClassesModules(data)
        } catch (error) {
          console.log(error);
        }
    }

    async function getModelsByPowerClass(productType, volume) {
        try {
          const { data } = projectService.getModelsByPowerClass(productType, volume);
          setModelModules(data)
        } catch (error) {
          console.log(error);
        }
    }

    async function getModuleModels() {
        try {
            const { data } = projectService.getQCellsModelModules();
            setModelModules(data);
        } catch (error) {
            console.log(error);
        }
    }

    async function getBrandsSystemInfo(productType, withoutQcells) {
        try {
          const { data } = await projectService.getBrandsSystemInfo(productType, withoutQcells);
          productType === 1 && setBrandsPanel(data);
          productType === 2 && setBrandsBattery(data);
          productType === 3 && withoutQcells === true && setPvInverterBrands(data);
        } catch (error) {
          console.log(error);
        }
    }

    async function getBatteryModels(manufacturerId) {
        try {
          const { data } = await projectService.getModelsSystemInfo(manufacturerId);
          setBatteryModels(data)
        } catch (error) {
          console.log(error);
        }
    }

    async function getPvInverterModels(manufacturerId) {
        try {
          const { data } = await projectService.getModelsSystemInfo(manufacturerId);
          setPvInverterModels(data)
        } catch (error) {
          console.log(error);
        }
    }

    const getEssProductModels = async () => {
        try {
            // const { data } = await axios.get(`${baseUrl}/projects/product-name/ess`);
            const { data } = projectService.getQCellsEssModel();
            setEssProductModels(data);
        } catch (err) {
            notiFailed(`Something happened getting product ess models`);
        }
    };

    async function getBatteryInverterPower(productName) {
        try {
          const { data } = await projectService.getBatteryInverterPower(productName);
          setBatteryInverterPower(data)
        } catch (error) {
          console.log(error);
        }
    }

    const showErrorMessage = (errors, errorKeys = []) => {
        let message = '';

        for (let i = 0; i < errorKeys.length; i++) {
            if (errors[errorKeys[i]]) {
                message = errors[errorKeys[i]].message;
                break;
            }
        }

        return notiFailed(message);
    };

    const getProjectName = data => {
        if (_.trim(data.projectName)) {
            return _.trim(data.projectName);
        }

        const firstName = data.firstName;
        const lastName = data.lastName;
        const city = data.projectCity;
        let systemSize = null;



        if (data.moduleBrand === 'Q CELLS' && data.isSerialNumber && !data.isManualSerialNumber) {
            const uniqModuleSerialNumber = _.remove(_.uniqBy(_.get(data, 'moduleSerialNumber'), 'productName'), p => p.productName);
            systemSize = _.get(data, 'moduleSerialNumber.length') * _.get(uniqModuleSerialNumber, '[0].volume', data.modulePowerClass);
        } else {
            systemSize = volume * numberOfModules;
        }

        if (data.moduleBrand !== 'Q CELLS') {
            systemSize = volume * numberOfModules;
        }

        if (systemSize) {
            systemSize = `${systemSize / 1000} kW`;
        }

        return _.join(_.remove([_.trim(`${firstName || ''} ${lastName || ''}`), systemSize, city], value => !!value), ', ');
    };

    const getQualifyLaborWarranty = data => {
        let systemSize = null;
        const operateDate = _.get(data, 'operateDate');
        const volume = watch('modulePowerClass');
        const numberOfModules = watch('numberOfModules');
        const panelQualified  = watch('panelQualified');
        const batteryQualified = watch('batteryQualified');
        const panelNonQcells  = watch('panelNonQcells');
        const batteryNonQcells = watch('batteryNonQcells')


        const uniqModuleSerialNumber = _.remove(_.uniqBy(_.get(data, 'moduleSerialNumber'), 'productName'), p => p.productName);
        if(uniqModuleSerialNumber?.length > 0) {
            systemSize = (_.get(data, 'moduleSerialNumber.length') * data.modulePowerClass) / 1000;
        }else {
            systemSize = (volume !== '' && numberOfModules !== '')
              ? (_.toNumber(volume) * _.toNumber(numberOfModules)) / 1000
              : ''
        }



        if(_.get(data, 'operateDate') && !!systemSize) {
            const perateDateI = moment(_.get(data, 'operateDate')).add(3, 'M').format('YYYY-MM-DD');
            const dateNow = moment().format('YYYY-MM-DD')
            let laborQcell = false;
            let laborQuanlify = false;

            if(!panelNonQcells === true || !batteryNonQcells === true) {
                laborQcell = true
            }

            if(panelQualified === true || batteryQualified === true) {
                laborQuanlify = true
            }


            return moment(perateDateI).isSameOrAfter(dateNow) && systemSize <= 25 && laborQcell && laborQuanlify
        }

        return false;
    };

    const getSystemSize = data => {
        // let systemSize = null;

        const sysSize = data?.moduleBrand !== 'Q CELLS' && volume !== '' && numberOfModules !== ''
          ? (_.toNumber(volume) * _.toNumber(numberOfModules)) / 1000
          : (!isSerialNumber ? (_.toNumber(volume) * _.toNumber(numberOfModules)) / 1000 : (_.toNumber(_.get(moduleSerial, '[0].volume', volume)) * (isManualSerialPanelNotMatchDb ? numberOfModules : moduleSerialList.length)) / 1000)

        // if (data.moduleBrand === 'Q CELLS' && data.isSerialNumber && !data.isManualSerialNumber) {
        //     const uniqModuleSerialNumber = _.remove(_.uniqBy(_.get(data, 'moduleSerialNumber'), 'productName'), p => p.productName);
        //     systemSize = _.get(data, 'moduleSerialNumber.length') * _.get(uniqModuleSerialNumber, '[0].volume');
        // } else {
        //     systemSize = data.volume * data.numberOfModules;
        // }

        return sysSize || 0
    };

    return (
        <>
            <RouteLeavingGuard
                when={
                    formState.isDirty
                }
                navigate={(path,state) => history.push({pathname: path, state: state})}
                shouldBlockNavigation={location => {
                    if (formState.isDirty) {
                        if (location.pathname !== '/projects/project-registration' && !_.get(location, 'state.disablePrompt')) {
                            return true
                        }
                    }
                    return false
                }}
            />
            <Notification />
            <BannerProjectRes step={currentStep} title={'Project Registration'} sub_title={['SALES TOOLS', 'PROJECT MANAGEMENT', 'PROJECT REGISTRATION']}/>
            <div>
                <Navbar currentStep={currentStep} goToStep={goToStep} />
                <div className={classes.container}>
                    {renderStep()}
                </div>
            </div>
        </>
    )
}
