import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import Text from '../../../../common/components/text';
import Button from '../../../../common/components/button';
import TextButton from '../../../../common/components/button/TextButton';

import { useAPIClient } from '../../../../contexts/api';
import { useAuth } from '../../../../contexts/auth';
import _ from 'lodash';
import Checkbox from './../../../../common/components/input/Checkbox';
import { Grid } from '@material-ui/core';
import CustomLoading from './../../../../common/components/CustomLoading';
import { sendEventForShop, ACTION_BUY_PRODUCT } from './../../../../utils/gaHelpers';
import classNames from 'classnames';

const Container = styled.div`
  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      width: 65%;
    }
  `}
`;

const StyledDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.colors.dark};
  margin: 1rem 0 2rem;
`;

const Delivery = ({ submitOrder, countProductInAura, nextProgess }) => {
  const apiClient = useAPIClient();
  const user = useAuth();
  const [companyBranches, set] = useState();
  const [loading, set_loading] = useState(true);
  const [diffAddress, set_diffAddress] = useState(false);
  const [savedNewAddress, set_savedNewAddress] = useState(false);
  const [editCompanyAddress, setEditCompanyAddress] = useState({});
  const [originalAddress, setOriginalAddress] = useState({});
  const [loadingSubmitOrder, setLoadingSubmitOrder] = useState(false);

  useEffect(() => {
    async function fetch() {
      set_loading(true);
      const { data } = await apiClient.getCompanyBranch(user.companyId);
      set(data);
      set_loading(false);
      setEditCompanyAddress(data[0]);
    }

    fetch();
  }, [user.companyId]);

  const formatAddress = ({
    address,
    addressCont,
    city,
    state,
    zipCode,
    officeNumber
  }) => {
    if (!addressCont) {
      return `${address || ''}
              ${city || ''}, ${state || ''} ${zipCode || ''}
              ${officeNumber || ''}`;
    } else {
      return `${address || ''}
              ${addressCont || ''}
              ${decodeStringHtml(city) || ''}, ${state || ''} ${zipCode || ''}
              ${officeNumber || ''}`;
    }
  };

  const handleEditCompanyAddress = (name, value) =>
    setEditCompanyAddress({ ...editCompanyAddress, [name]: value });

  const handleCancel = () => {
    setEditCompanyAddress({ ...companyBranches[0] });
    set_diffAddress(false);
  };

  const checkEmptyDifferentAddress = () => {
    let isError = false;
    const keys = ['address', 'addressCont', 'city', 'state', 'zipCode', 'officeNumber'];
    for (const key of keys) {
      if (_.trim(editCompanyAddress[key]) === '') {
        isError = true;
      }
    }

    return isError;
  };

  function decodeStringHtml(string) {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(`<!doctype html><body>${string}`, 'text/html').body.textContent;

    return decodedString || '';
  }

  const formatForm = () => {
    return (
      <div className="edit_address">
        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item xs={12} sm={6}>
            <div className="label_inp">Address</div>
            <input
              className={classNames("address_inp", { error: _.trim(editCompanyAddress.address) === '' })}
              type="text"
              label="Address"
              value={editCompanyAddress.address}
              onChange={e => handleEditCompanyAddress('address', e.target.value)}
            />
            { _.trim(editCompanyAddress.address) === '' &&
              <p className="error_msg">
                Address is required
              </p> }
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className="label_inp">Address Continued</div>
            <input
              className={classNames("address_inp", { error: _.trim(editCompanyAddress.addressCont) === '' })}
              type="text"
              label="Address Continued"
              value={editCompanyAddress.addressCont}
              onChange={e => handleEditCompanyAddress('addressCont', e.target.value)}
            />
            { _.trim(editCompanyAddress.addressCont) === '' &&
              <p className="error_msg">
                Address Continued is required
              </p> }
          </Grid>
          <Grid item xs={6} sm={3}>
            <div className="label_inp">City</div>
            <input
              className={classNames("address_inp", { error: _.trim(editCompanyAddress.city) === '' })}
              type="text"
              label="City"
              value={decodeStringHtml(editCompanyAddress.city)}
              onChange={e => handleEditCompanyAddress('city', e.target.value)}
            />
            { _.trim(editCompanyAddress.city) === '' &&
              <p className="error_msg">
                City is required
              </p> }
          </Grid>
          <Grid item xs={6} sm={3}>
            <div className="label_inp">State</div>
            <input
              className={classNames("address_inp", { error: _.trim(editCompanyAddress.state) === '' })}
              type="text"
              label="State"
              value={editCompanyAddress.state}
              onChange={e => handleEditCompanyAddress('state', e.target.value)}
            />
            { _.trim(editCompanyAddress.state) === '' &&
              <p className="error_msg">
                State is required
              </p> }
          </Grid>

          <Grid item xs={6} sm={3}>
            <div className="label_inp">Zip Code</div>
            <input
              className={classNames("address_inp", { error: _.trim(editCompanyAddress.zipCode) === '' })}
              type="text"
              label="Zip Code"
              value={editCompanyAddress.zipCode}
              onChange={e => handleEditCompanyAddress('zipCode', e.target.value)}
            />
            { _.trim(editCompanyAddress.zipCode) === '' &&
              <p className="error_msg">
                Zip Code is required
              </p> }
          </Grid>

          <Grid item xs={6} sm={3}>
            <div className="label_inp">Office Number</div>
            <input
              className={classNames("address_inp", { error: _.trim(editCompanyAddress.officeNumber) === '' })}
              type="text"
              label="Office Number"
              value={editCompanyAddress.officeNumber}
              onChange={e =>
                handleEditCompanyAddress('officeNumber', e.target.value)
              }
            />
            { _.trim(editCompanyAddress.officeNumber) === '' &&
              <p className="error_msg">
                Office Number is required
              </p> }
          </Grid>
        </Grid>
      </div>
    );
  };

  let name = '';
  if (_.get(user, 'fullName')) {
    name = _.get(user, 'fullName');
  } else {
    if (_.get(user, 'firstName') == _.get(user, 'lastName')) {
      name = _.get(user, 'firstName');
    } else {
      name = _.get(user, 'firstName') + ' ' + _.get(user, 'lastName');
    }
  }

  const renderCheckDiffentAddress = () => {
    if (savedNewAddress === true) {
      return (
        <div
          style={{
            cursor: 'pointer',
            color: '#6b6bf7',
            textDecoration: 'underline'
          }}
          onClick={e => {
            set_diffAddress(true);
          }}
        >
          Edit
        </div>
      );
    }
    return (
      <Checkbox
        style={{
          userSelect: 'none',
          marginTop: '40px',
          marginBottom: '-20p'
        }}
        id="fdsfdsfewfw"
        label="Different Address?"
        checked={diffAddress}
        onChange={(a, b) => {
          setOriginalAddress({ ...editCompanyAddress });
          set_diffAddress(b);
        }}
      />
    );
  };

  return (
    <Container>
      <Text className="confirm_address_2" as="h3" weight="normal">
        Confirm Address
      </Text>
      <StyledDivider />
      <div style={{ marginBottom: '2rem' }}>
        <Text as="h4" weight="medium">
          <span dangerouslySetInnerHTML={{__html: name}} />
        </Text>
        {loading ? (
          'Loading...'
        ) :
          (
            countProductInAura < 1 && <div>
            {diffAddress ? (
              <div>{formatForm(companyBranches[0])}</div>
            ) : (
              <Text color="secondaryDark" style={{ whiteSpace: 'pre-line' }}>
                {companyBranches && formatAddress(companyBranches[0])}
              </Text>
            )}
            <Grid
              container
              justify="space-between"
              alignItems="center"
              style={{ paddingTop: '20px' }}
            >
              {diffAddress ? (
                <TextButton
                  onClick={() => {
                    if (checkEmptyDifferentAddress()) {
                      return;
                    }
                    set_diffAddress(false);
                    set([{ ...editCompanyAddress }]);
                    if (savedNewAddress === false) {
                      set_savedNewAddress(true);
                    }
                  }}
                >
                  Save
                </TextButton>
              ) : (
                renderCheckDiffentAddress()
              )}

              {diffAddress ? (
                <TextButton onClick={handleCancel}>Cancel</TextButton>
              ) : (
                false
              )}
            </Grid>
          </div>
        )}
        {countProductInAura >= 1 && <Text style={{marginTop : 20}}>Upon confirming the order, a digital coupon code will be generated.</Text>}
      </div>


      { countProductInAura >= 1 && <Button
        className="btn_confirm_order"
        disabled={loading || loadingSubmitOrder}
        style={{ alignSelf: 'flex-start', margin: '1rem 0' }}
        onClick={() => nextProgess(companyBranches[0], diffAddress, editCompanyAddress)}
      >
        Next
      </Button>}


      {!diffAddress && countProductInAura < 1 ? (
        <Button
          className="btn_confirm_order"
          disabled={loading || loadingSubmitOrder}
          style={{ alignSelf: 'flex-start', margin: '1rem 0' }}
          onClick={async () => {
            sendEventForShop(ACTION_BUY_PRODUCT);
            setLoadingSubmitOrder(true);
            await submitOrder(companyBranches[0], diffAddress, editCompanyAddress);
            setLoadingSubmitOrder(false);
          }}
        >
          { !loadingSubmitOrder ? 'Confirm Order' : <CustomLoading color="#fff" size={16}/> }
        </Button>
      ) : (
        false
      )}
    </Container>
  );
};

export default Delivery;
