import React from 'react';
import PopOver from '../../../config/images/popover_icon.svg';
import useStyles from './useStyles';

function NotiSerialNumber({text}) {
  const classes = useStyles();

  return(
    <div className={classes.notiBoxSN}>
      <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>
      <p>
        {text}
      </p>
    </div>
  )
}

export default NotiSerialNumber;