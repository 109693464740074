import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import CustomButton from '../../../common/components/button';
import useStyles from './useStyles';
import CustomLoading from '../../../common/components/CustomLoading';


function FieldDetail({ next, goBack, register, control, errors, watch, setValue, handleSaveDraft, loading}) {
  const classes = useStyles();
  return(
    <div>
      <div className={classes.stepInfo}>
        <span className={classes.sectionTitleStep}>
          6. Field Details
        </span>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              PV1 String Voltage
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="pv1"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              PV2 String Voltage
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="pv2"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              PV3 String Voltage
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="pv3"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              PV4 String Voltage
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="pv4"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
             Load L1 to N Voltage
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="loadL1Voltage"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Load L2 to N Voltage
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="loadL2Voltage"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Critical Loads connected
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="criticalConnected"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Total PV Power installed
            <span>*</span>
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="totalPvPowerInstalled"
            className={classNames([classes.inputField], {
              [classes.errorInputField]: errors.totalPvPowerInstalled
            })}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Operational mode use
          </span>
        </div>
        <div>
          <Controller
            name="operationalModeUse"
            control={control}
            as={
              <select
                placeholder="Purchasing Channel"
                className={classNames([classes.selectField], {
                  //[classes.errorSelectField]: errors.purchasingChannel
                })}
              >
                <option value={'select'}>Please select</option>
                <option value={1}>Self use</option>
                <option value={2}>Time of use</option>
                <option value={3}>Backup mode</option>
                <option value={4}>Other</option>
              </select>
            }
          />
        </div>
      </div>
      <p className={classes.requiredText}>* Required</p>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }}/>
      <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
        <CustomButton color="secondary" className="saveDraftBtn" onClick={goBack}>Back</CustomButton>
        <div>
          <CustomButton onClick={handleSaveDraft} color="secondary" className="saveDraftBtn" disabled={loading}>
            { loading ? <CustomLoading size={16} color="#009EE3" /> : 'Save Draft' }
          </CustomButton>
          <CustomButton onClick={next}>Next</CustomButton>
        </div>
      </Box>
    </div>
  )
}

export default FieldDetail;

