import React from 'react';
import styled from 'styled-components';
import {
  lightThemeColor,
  primaryLightThemeColor,
  secondaryDarkThemeColor
} from '../../../utils/colors';
import Text from '../text';

const Wrapper = styled('section')`
  label {
    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;
    padding-left: 30px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;

    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 15px;
      height: 15px;
      transition: transform 0.28s ease;
      border-radius: 3px;
      border: 1px solid ${secondaryDarkThemeColor};
      background-color: ${lightThemeColor};
    }

    &:after {
      content: '';
      display: block;
      width: 10px;
      height: 5px;
      border-bottom: 2px solid ${primaryLightThemeColor};
      border-left: 2px solid ${primaryLightThemeColor};
      transform: rotate(-45deg) scale(0);
      transition: transform ease 0.25s;
      position: absolute;
      top: 3.5px;
      left: 3px;
    }
  }

  input[type='checkbox'] {
    display: none;

    &:checked ~ label {
      &:before {
        border: 1px solid ${primaryLightThemeColor};
      }

      &:after {
        transform: rotate(-45deg) scale(1);
      }
    }
  }
`;

export default function Checkbox({
  style,
  className,
  id,
  label,
  checked,
  onChange
}) {
  return (
    <Wrapper style={style} className={className}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={e => onChange?.(e, !checked)}
      />
      <label htmlFor={id}>
        <Text
          color={checked ? 'primaryLight' : 'secondaryDark'}
          style={{ lineHeight: '1rem' }}
        >
          {label}
        </Text>
      </label>
    </Wrapper>
  );
}
