import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Banner from '../../../common/components/Banner';
import BannerImage from '../../../config/images/visual_area02_1.jpg';
import VideoContentBanner from '../../../config/images/visual_area03_2.jpg';
import TitleAnimation from '../../../common/components/TitleAnimation';
import BreadCrumb2 from '../../../common/components/BreadCrumb2';
import Text from '../../../common/components/text';
import {Grid, makeStyles} from '@material-ui/core';
import styled from 'styled-components';
import DownloadIcon from '../../../config/images/ico_download.png';
import axios from 'axios';
import {baseUrl} from '../../../config/index';
import moment from 'moment';
import Card from '../components/card';
import parse from 'html-react-parser';
import { decodeStringHtml, notiFailed } from '../../../utils/helpers';
import _ from 'lodash';
import ReactPlayer from 'react-player';
import { sendEventForDataHub, ACTION_DOWNLOAD_DATAHUB_DETAIL, ACTION_DOWNLOAD_VIDEO_CONTENT_DETAIL } from './../../../utils/gaHelpers';
import { ArrowLeftIcon } from '@material-ui/pickers/_shared/icons/ArrowLeftIcon';
import { ArrowRightIcon } from '@material-ui/pickers/_shared/icons/ArrowRightIcon';
import CardMobileDataHub from '../components/CardMobileDataHub';
import CardMostViewDataHub from '../components/CardMostViewDataHub';

const StyledButton = styled.button `
  background-color: #666;
  color: #fff;
  cursor: pointer;
  border: 0px;
  width: 110px;
  height: 50px;
  transition: all 0.4s;
  font-size: 16px;
  font-weight: 600;
  :hover {
    background-color: #444444;
  }
`;
const StyledButtonPaginate = styled.button `
  background-color: #fff;
  color: #666;
  cursor: pointer;
  border: 0px;
  font-weight: bold;
  width: 110px;
  height: 50px;
  margin-right: 10px;
  border: 1px solid #808080a1;
  font-size: 16px;

  :hover {
    border: 1px solid #808080a1;
    font-weight: 500;
    color: black;
  }
  
  @media only screen and (max-width: 480px) {
      width: 50px;
      margin-right: 0px;
  }
`;

const StyledButtonPaginateNext = styled.button `
  background-color: #666;
  color: white;
  cursor: pointer;
  font-weight: bold;
  border: 0px;
  width: 110px;
  height: 50px;
  margin-right: 10px;
  border: 1px solid #808080a1;
  font-size: 16px;

  :hover {
    border: 1px solid #808080a1;
    font-weight: 500;
    background-color: #444;
  }
  
  @media only screen and (max-width: 480px) {
      width: 50px;
      margin-right: 0px;
      background: white;
      border-left: none;
  }
`;

const StyledATag = styled.a `
  color: #666;
  font-size: 16px;
  margin-left: 100px;

  :hover {
    color: #009FE2;
  }
  
  @media only screen and (max-width: 789px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 12px;
  }
`

const StyledDataHubContainer = styled.div `
  width: 63vw;
  margin: auto;
  margin-bottom: -20px;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
  
  @media only screen and (min-width: 1600px) {
    margin-top: 60px;
  }

  @media only screen and (max-width: 375px) {
    width: 100%;
  }
`

const StyledTitleTextContainer = styled.div `
  padding: 20px 60px;

  @media only screen and (max-width: 1200px) {
    padding: 20px 20px;
  }

  @media only screen and (max-width: 375px) {
    padding: 20px 20px;
  }
`
const useStyles = makeStyles(theme => ({
  content: {
    '& em': {
      fontStyle: 'italic'
    },
    '& ul': {
      listStyleType: 'disc',
      marginLeft: '40px'
    },
    '& ol': {
      marginLeft: '40px'
    },
    '& li': {
      listStyleType: 'inherit'
    }
  },
  downloadLink: {
    color: '#000',
    marginLeft: '20px !important',
    position: 'relative',
    transition: 'color 0.4s',
    display: 'inline-block',
    wordBreak : 'break-all',
    '&::before': {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        height: '1px',
        background: '#fff',
        content: '""'
    },
    '&::after': {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: 0,
        height: '1px',
        background: '#019ee3',
        content: '""',
        transition: 'width 0.6s 0.2s'
    },
    '&:hover': {
        color: '#019ee3',
        textDecoration: 'none'
    },
    '&:hover::before': {
        width: 0,
        transition: 'width 0.4s'
    },
    '&:hover::after': {
        width: '100%'
    }
  }
}));

export default function Detail() {
  const classes = useStyles();
  const { detailCategory, detailId } = useParams();
  const [detail, setDetail] = useState()
  const [prevDetail, setPrevDetail] = useState();
  const [nextDetail, setNextDetail] = useState();
  const [downloadCenterData, setDownloadCenterData] = useState([]);
  const [mostViewedData, setMostViewedData] = useState([]);
  const [width, setWindowWidth] = useState(0);

  const getDetailById = async () => {
    if (detailCategory === 'video-content') {
      const {data} = await axios.get(`${baseUrl}/academy/detail/${detailId}`)
      setDetail(data);
    } else {
      const {data} = await axios.get(`${baseUrl}/data-hub/detail/${detailId}`)
      setDetail(data);
    }
  }

  const getData = async () => {
    const {data} = detailCategory === 'video-content' ?
    await axios.get(`${baseUrl}/academy/search`) :
    await axios.get(`${baseUrl}/data-hub/search`)

    const indexOfNextDetail = data.data.findIndex(x => x.id === +detailId) + 1;
    const indexOfPrevDetail = data.data.findIndex(x => x.id === +detailId) - 1;

    setDownloadCenterData(data.data);
    setNextDetail(indexOfNextDetail);
    setPrevDetail(indexOfPrevDetail);
  }

  const getMostViewedData = async () => {
    const {data} = await axios.get(`${baseUrl}/academy/search?limit=4&offset=0&academyTypeId=1`)

    setMostViewedData(data.data);
  }

  const handleClick = (id) => {
    const detail = downloadCenterData[id];
    window.location = `/salestools/data-hub/${detailCategory}/${detail.id}`
  }

  const productDescHtml = (data) => {
    const parseedData = parse(`${data}`);
    return typeof(parseedData) === 'object' ? parse(`${data}`) : parse(parseedData);
  };

  const handleDownloadAttachment = async (e, url, name) => {
    if (detailCategory === 'download-center') {
      sendEventForDataHub(ACTION_DOWNLOAD_DATAHUB_DETAIL);
    } else {
      sendEventForDataHub(ACTION_DOWNLOAD_VIDEO_CONTENT_DETAIL);
    }
    e.preventDefault();

    const instance = axios.create();
    delete instance.defaults.headers.common["Authorization"];

    try {
      const response = await instance({ url, method: 'GET', responseType: 'blob' });
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      notiFailed(`There was a problem download attachment ${err.message}`);
    }
  }

  useEffect(()=> {
    getDetailById();
    getData();
    getMostViewedData();
  }, [detailId]);

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () =>
      window.removeEventListener('resize', updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };


  return (
    <section
      className={'page-data-hub'}
      style={{
        backgroundColor: 'var(--light-color, #ffffff)',
        position: 'relative',
      }}
    >
      <BreadCrumb2 items={['Sales Tools', 'Data Hub']}/>
      <Banner imageUrl={ detailCategory === 'download-center' ? BannerImage : VideoContentBanner} className="banner100">
        <TitleAnimation>Data Hub</TitleAnimation>
      </Banner>
      {detail ?
      detailCategory === 'download-center' ?
      <StyledDataHubContainer>
        <div className={'wr-title'}>
          <span className={'text-title-data-hub-info'}>DATA HUB</span>
        </div>
        <div className={'wrap-info-data-hub-date'}>
          <StyledTitleTextContainer>
            <span>{decodeStringHtml(detail.title)}</span>
          <Text size="small" weight="light" style={{fontSize: '11px'}}>{moment(detail.createdAt).format('YYYY[.]MM[.]DD')}</Text>
          </StyledTitleTextContainer>
        </div>
        <div className={'data-hub'} style={{ marginTop: '40px', borderBottom: '1px solid #000'}}>
          <div className={'wrap-content-data-hub-info'} style={{ display: 'grid', gridTemplateColumns: '1fr 9fr', columnGap: '17px', gridAutoRows: 'minMax(1px, auto)'}}>
            <span className={'title-content-data-hub'}>Category</span>
            <span className={'value-content-data-hub'}>{detail.categoryName}</span>
            <span className={'title-content-data-hub'}>Language</span>
            <span className={'value-content-data-hub'}>{detail.language}</span>
            <span className={'title-content-data-hub'}>Products</span>
            <span className={'value-content-data-hub'}>None</span>
            <span className={'title-content-data-hub'}>Content</span>
            <span className={'value-content-data-hub'}>
              <div
                className={classes.content}
                dangerouslySetInnerHTML={{
                  __html: detail.content
                }}
              />
            </span>
          </div>
          <div className={'download-img-data-hub'}>
            <StyledATag onClick={e => handleDownloadAttachment(e, detail.attachment.assetUrl, detail.attachment.assetKey)} className={classes.downloadLink} href={detail.attachment.assetUrl}><img src={DownloadIcon} style={{position: 'relative', left: -20, top: 15, color: '#000'}} />{_.split(detail.attachment.assetKey, '/').pop()}</StyledATag>
          </div>
        </div>

      <div className={'wrap-paginate'}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            { prevDetail === -1 ? false : <StyledButtonPaginate onClick={() => handleClick(prevDetail)}>{width < 480 ? <ArrowLeftIcon/> : 'Previous'}</StyledButtonPaginate> }
            { prevDetail > downloadCenterData.length - 1 ? false : <StyledButtonPaginate onClick={() => handleClick(nextDetail)}>{width < 480 ? <ArrowRightIcon/> : 'Next'}</StyledButtonPaginate> }
          </Grid>
          <Grid item>
            <StyledButton onClick={() => window.location = '/salestools/data-hub/download-center'}>List</StyledButton>
          </Grid>
        </Grid>
      </div>
    </StyledDataHubContainer> : <div className={'wrap-detail-video-content'}>
        <div className={'title-content'}>
          <span>VIDEO CONTENT</span>
        </div>
        <div className={'wrap-date-info-video'}>
          <div className={'wrap-title-video-content'}>
            <span>{decodeStringHtml(detail.title)}</span>
          <Text size="small" weight="light" style={{fontSize: '11px'}}>DATE {moment(detail.createdAt).format('YYYY[.]MM[.]DD')}</Text>
          </div>
        </div>
        <div style={{ marginTop: '20px', paddingBottom: '41px', borderBottom: '1px solid #000'}}>
        <div className={'wrap-info-video'}>
          <div className="wrap-video-content">
            {detail.video ? <ReactPlayer controls width={'100%'} height={'100%'} url={`${detail.video}`} /> : false}
          </div>

          <div className={'wrap-attachment'}>
            <Text weight="light" style={{fontSize: '16px', textAlign: 'left', paddingTop: 20}}>{productDescHtml(detail.content)}</Text>
            {detail.attachment1 ? <StyledATag onClick={e => handleDownloadAttachment(e, detail.attachment1.assetUrl, detail.attachment1.assetKey)} href={detail.attachment1.assetUrl}><img src={DownloadIcon} style={{position: 'relative', left: -20, top: 15, color: '#000'}} />{_.split(detail.attachment1.assetKey, '/').pop()}</StyledATag> : false}
            {detail.attachment2 ? <StyledATag onClick={e => handleDownloadAttachment(e, detail.attachment2.assetUrl, detail.attachment2.assetKey)} href={detail.attachment2.assetUrl}><img src={DownloadIcon} style={{position: 'relative', left: -20, top: 15, color: '#000'}} />{_.split(detail.attachment2.assetKey, '/').pop()}</StyledATag> : false }
          </div>

        </div>
        </div>

        <div className={'wrap-paginate'}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <div>
            { prevDetail === -1 ? false : <StyledButtonPaginate style={{fontSize: '16px'}} onClick={() => handleClick(prevDetail)}>{width < 480 ? <ArrowLeftIcon  style={{ fill: '#808080a1' }}/> : 'Previous'}</StyledButtonPaginate> }
            { prevDetail > downloadCenterData.length - 1 ? false : <StyledButtonPaginateNext style={{fontSize: '16px'}} onClick={() => handleClick(nextDetail)}>{width < 480 ? <ArrowRightIcon style={{ fill: '#808080a1' }}/> : 'Next'}</StyledButtonPaginateNext> }
            </div>
            <Grid item>
              <StyledButton onClick={() => window.location = '/salestools/data-hub/video-content'}>List</StyledButton>
            </Grid>
          </Grid>
        </div>
        <div className={'wrap-text-most'}>
          <span style={{fontSize: '26px', marginTop: '70px', marginBottom: '0px'}}>MOST VIEWED</span>
        </div>
        <div className={'most-view'}>
        {width > 1600 && mostViewedData.map(product => {
          return (
            <Card  key={product.id}
            id={product.id}
            checked={product.checked}
            image={product.thumbnail.assetUrl}
            title={product.title}
            content={product.content}
            fileType={product.fileType}
            fileSize={product.fileSize}
            updatedAt={product.updatedAt}
            category={product.categoryName}
            language={product.language}
            detailCategory="video-content"/>
          )
        })}
        </div>
        <div className="wrap-most-view">
          {width < 1600 && <div className="wrap-card-data-hub">
            {mostViewedData?.map(product => {
              return <CardMostViewDataHub width={width} type={2}  key={product.id} product={product}  id={product.id} tab={1}/>
            })}
            {mostViewedData?.length === 0 && <div className={'no-item'}>
              <span>No item is registered.</span>
            </div>}
          </div>}
        </div>



      </div>
       : false}


    </section>
  )
}
