import React from 'react';
import useStyles from './useStyles';
import PopOver from '../../../config/images/closeModal.svg';

function CardSerialNumber({serial, handleRemoveSerialNumber}) {

  const classes = useStyles();

  return(
    <div className={classes.wrapSerialNumber}>
      {serial} <img onClick={() => handleRemoveSerialNumber(serial)} style={{marginRight: 8, height : 20, opacity: 0.7, cursor : 'pointer'}} src={PopOver}/>
    </div>
  )
}

export default CardSerialNumber;
