import axios from '../utils/axios';
import config from '../config';

function getListClaimHistory(payload) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/claims/search`,
    params : payload
  }

  return axios(request);
}

function getListProject() {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/projects/list`
  }

  return axios(request);
}

function getProductPti() {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/claims/pti-products`
  }

  return axios(request);
}


function updateClaim(payload) {
  const request = {
    method : 'POST',
    url : `${config.baseUrl}/claims/attachment/update`,
    data: payload
  }

  return axios(request);
}


function getProjectById(id) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/projects/detail`,
    params : {
      id : id
    }
  }

  return axios(request);
}

function getListStatusClaim() {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/claims/statuses`
  }

  return axios(request);
}

function saveDraftClaim(payload) {
  const request = {
    method : 'POST',
    url : `${config.baseUrl}/claims/save-draft`,
    data : payload
  }

  return axios(request);
}

function saveClaim(payload) {
  const request = {
    method : 'POST',
    url : `${config.baseUrl}/claims/save`,
    data: payload
  }

  return axios(request)
}

function getDetailClaim(id) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/claims/detail`,
    params : {
      id : id
    }
  }

  return axios(request);
}

function getDistributor() {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/distributors`
  }

  return axios(request);
}

function createClaimEss(payload) {
  const request = {
    method : 'POST',
    url : `${config.baseUrl}/claims/save`,
    data : payload
  }

  return axios(request);
}


export {getListClaimHistory, getListProject, getProjectById, getListStatusClaim, saveDraftClaim, getDetailClaim, saveClaim, getDistributor, createClaimEss, getProductPti, updateClaim};
