import React from 'react';
import PropTypes from 'prop-types';

const BreadCrumb3 = ({ items, classN }) => (
	<nav className={classN.nav}>
		<div className={classN.div}>
			<ul className={classN.ul}>
				<li className={classN.li}>HOME</li>
				{items.map((item, index) => (
					<li key={item} className={classN.li2} dangerouslySetInnerHTML={{__html: item}} />
				))}
			</ul>
		</div>
	</nav>
);

BreadCrumb3.propTypes = {
	items: PropTypes.any.isRequired
};

export default BreadCrumb3;
