import React, { useEffect, useState } from 'react';
import { Box, Button, TextareaAutosize } from '@material-ui/core';
import IconEdit from '../../../icons/edit_icon.svg';
import Moment from 'react-moment';
import CustomButton from '../../../common/components/button';
import CustomLoading from '../../../common/components/CustomLoading';
import useStyles from '../components/useStyles';
import _ from 'lodash';
import OpenImageIcon from '../../../config/images/open_image_icon.svg';
import ImageModal from '../../projects/components/ImageModal';
import classNames from 'classnames';
import { decodeStringHtml } from '../../../utils/helpers';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaEss } from '../components/schemaEss';
import { defaultStateEss } from '../components/defaultStateEss';
import { useAuth } from '../../../contexts/auth';
import { claimService, projectService } from '../../../services';
import axios from '../../../utils/axios';
import { baseUrl } from '../../../config';
import {useParams, useHistory} from 'react-router-dom';
import PopOver from '../../../config/images/popover_icon.svg';
import BannerDetail from '../components/BannerDetail';
import PopoverDetail from '../components/PopoverDetail';
import { keyEss } from '../components/keyEss';
import { filter } from 'rxjs/operators';
import Error from '../components/Error';
import FormUpDateInfoClaim from '../components/FormUpdateInfoClaim';

function ClaimDetailBattery() {
  const classes = useStyles();
  const history  = useHistory();
  const [modalUrl, setModalUrl] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [distributors, setDistributors] = useState([]);
  const [fileErrorLog, setFileErrorLog] = useState([]);
  const [fileEvidence, setFileEvidence] = useState([]);
  const [fileInvoiceLabor, setFileInvoiceLabor] = useState([]);
  const [fileInfoBank, setFileInfoBank] = useState([]);
  const [claimLabel, setClaimLabel] = useState('');
  const [isDraft, setIsDraft] = useState(false);
  const [claimActive, setClaimActive] = useState({});
  const [detailClaim, setDetailClaim] = useState({});
  const [loadingDetail, setLoadingDetail] = useState(true)
  const [open, setOpen] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [keyUpload, setKeyUpload] = useState('');
  const [text, setText] = useState('')
  const {id} = useParams();
  const user = useAuth();

  const { register : registerEss, handleSubmit : handleSubmitEss, errors , control: controlEss, watch : watchEss, trigger : triggerEss ,setValue : setValueEss, getValues , formState : formStateEss, clearErrors : clearErrorsEss } = useForm({
    resolver : yupResolver(schemaEss),
    mode: "onChange",
    reValidateMode: "onChange",
    shouldUnregister: false,
    shouldFocusError: false,
    enableReinitialize: true,
    defaultValues: {
      installerEmail : _.get(user, 'email'),
      installerName : _.get(user , 'fullName'),
      installerContact : _.get(user , 'fullName'),
      installerCity : '',
      installerAddress1 : '',
      installerAddress2 : '',
      installerState : 'select',
      installerZipcode : '',
      installerPhone : '',
      checkedEmail : false,

      infoClaimName : 'ESS',
      claimType : '2',
      errorLogName : '',
      projectId : null,
      ...defaultStateEss,
      installation_date_pv : null,
      comission_date_pv : null,
      affected_module_serial_number : 'select',
      region_pv : 'select',
      site_module_layout_pv : 'select',
      inverter_type_pv : 'select',
      purchase_date_info : null
    }
  });


  const data = watchEss();
  const choseTypeSerialUpload = watchEss('choseTypeSerialUpload');
  const listSerialPickData = watchEss('listSerialPick');
  const choseTypeProject = watchEss('choseTypeProject');


  const renderAddress = (array = []) => {
    const newArray = _.remove(array, value => !!value || value === 'select');
    return newArray.length > 0 ? _.join(newArray, ', ') : '';
  };

  const renderPurchasingChannel = purchasingChannelValue => {
    switch (purchasingChannelValue) {
      case 'qcells':
        return 'Q CELLS';
      case 'distributor':
        return 'Distributor';
      case 'other':
        return 'Other';
      default:
        return '';
    }
  };

  const renderNameOfPurchasingChannel = () => {
    const purchaseChannel = getValues('purchasingChannel');
    switch (purchaseChannel) {
      case 'qcells':
        return 'Q CELLS';
      case 'distributor':
        // return _.get(data, 'nameOfPurchasingChannelDistributor.name');
        return _.get(data, 'nameOfPurchasingChannelDistributor.name') + '' + `${_.get(data, 'nameOfPurchasingChannelDistributor.name') === "Other" ? `-${_.get(data, 'nameOfChanelOtherDistributor', '')}` : ''}`;
      case 'other':
        return getValues('nameOfPurchasingChannelOther');
      default:
        return '';
    }
  };

  const renderField = fieldName => {
    const fieldValue = data[fieldName];
    if (!!fieldValue && fieldValue !== 'select') {
      return fieldValue;
    }

    return null;
  };

  const renderProductType = () => {
    switch (getValues('moduleProductType')) {
      case 'qcells':
        return 'Q CELLS';
      case 'other':
        return 'Other';
      default:
        return 'Q CELLS';
    }
  };

  function renderLaborStatus(laborReimbursement, laborWarranty, choseTypeProject, chooseRefund, batteryQualified) {

    if(choseTypeProject === '2') {
      return 'No'
    }

    if(laborReimbursement && laborWarranty && chooseRefund === '1') {
      return 'No'
    }

    if(!batteryQualified) {
      return 'No'
    }

    if((!laborReimbursement && laborWarranty) || !laborReimbursement) {
      return 'No'
    }

    return 'Yes'
  }



  const renderClaimType = (type) => {
    const action = {
      1 : 'Autotransformer',
      2 : 'Battery Module',
      3 : 'BMS',
      4 : 'ESS Alarm',
      5 : 'Hardware Error',
      6 : 'Inverter',
      7 : 'Meter',
      8 : 'Software',
      9 : 'Other',
      'default' : 'Other'
    }

    return action[parseInt(type)] || action['default']
  }

  function renderStatusName(statusId) {
    const action = {
      1 : 'Submitted',
      2 : 'Requires more info',
      3 : 'In review',
      4 : 'In review',
      8 : 'In review',
      5 : 'Processing',
      11 : 'Approved',
      12 : 'In review',
      6 : 'Closed/Approved',
      7 : 'Closed/Approved',
      9 : 'Closed/Approved',
      10 : 'Closed/Rejected',
      14 : 'Remote Support in Progress',
      15 : 'Closed/Resolved',
      'default' : ''
    }

    return action[statusId] || action['default']
  }

  function handleBackReview() {

  }

  function setGroupEss(url) {
    let typeGroup = '';

    if(/^.*error_logs.*$/.test(url)) {
      typeGroup = 1
    }

    if(/^.*other_evidence.*$/.test(url)) {
      typeGroup = 2
    }

    if(/^.*invoice_for_labor_cost_reimbursement.*$/.test(url)) {
      typeGroup = 3
    }

    if(/^.*bank_account_information.*$/.test(url)) {
      typeGroup = 4
    }

    const actions = {
      1 : 'errorLogImage',
      2 : 'otherEvidence',
      3 : 'laborCost',
      4 : 'bankAccount',

      'default' : 'errorLog'
    }
    return actions[typeGroup] || actions['default'];

  }
  function suggestionsImageEss(strImage) {
    const arrayImage = strImage?.split(',').map(item => {
      return {
        url : item,
        group : setGroupEss(item)
      }
    });

    return _.groupBy(arrayImage, 'group');
  }

  useEffect(() => {
    setValueEss('installerCity', _.get(user, 'infoUser.city'))
    setValueEss('installerAddress1', _.get(user, 'infoUser.address'))
    setValueEss('installerAddress1', _.get(user, 'infoUser.address'))
    setValueEss('companyName', _.get(user, 'infoUser.companyName'))
    setValueEss('installerEmail', _.get(user, 'infoUser.email'))
    setValueEss('installerName', _.get(user, 'infoUser.fullName'))
    setValueEss('installerState', _.get(user, 'infoUser.state'))
    setValueEss('installerZipcode', _.get(user, 'infoUser.zipCode'))
    setValueEss('installerAddress2', _.get(user, 'infoUser.addressCont'))
    setValueEss('installerPhone', _.get(user, 'infoUser.officeNumber') || _.get(user, 'infoUser.mobileNumber') )
  }, [_.get(user, 'infoUser.city')])

  useEffect(() => {
    getDetailClaim()
  }, [])

  async function getDetailClaim() {
    const info = await claimService.getDetailClaim(id);

    const { data } = await axios.get(`${baseUrl}/distributors`);
    setDistributors(data);
    setLoadingDetail(false);
    setText(_.get(info.data, 'evidenceDescription', ''))
    setKeyUpload(_.get(info.data, 'folderId', ''))
    setValueEss('qualifyLaborWarranty', _.get(info.data, 'draftData.projectInfo.qualifyLaborWarranty', ''))
    setValueEss('created', _.get(info.data, 'created', ''))
    setValueEss('isDraft', _.get(info.data, 'isDraft', ''))
    setValueEss('statusId', _.get(info.data, 'statusId', ''))
    setValueEss('checkedEmail', _.get(info.data, 'draftData.checkedEmail'))
    setValueEss('submitterEmail', _.get(info.data, 'draftData.submitterEmail'))
    setValueEss('claimType', _.get(info.data, 'draftData.claimType'))
    setValueEss('infoClaimName', _.get(info.data, 'draftData.claimInfoName', 'select'))
    setValueEss('description', _.get(info.data, 'draftData.description'))
    setValueEss('errorLogName', _.get(info.data, 'draftData.errorLogName'))
    setValueEss('choseTypeProject', _.get(info.data, 'draftData.choseTypeProject'))
    // setValuePv('projectId', _.get(info.data, 'draftData.projectInfo', null))
    if(_.get(info.data, 'draftData.projectId', null)) {
      const projectData  = await projectService.getDetailProject(_.get(info.data, 'draftData.projectId', null));
      let projectInfo = projectData?.data;
      setValueEss('projectInfo', projectInfo)

    }else {
      setValueEss('projectInfo', null)
    }
    //setValueEss('projectInfo', _.get(info.data, 'draftData.projectInfo', null))
    setValueEss('projectAddress', _.get(info.data, 'draftData.projectAddress', ''))
    setValueEss('projectInstallationDate', _.get(info.data, 'draftData.projectInstallationDate', ''))
    setValueEss('projectProductName', _.get(info.data, 'draftData.projectProductName', 'select'))
    setValueEss('projectSystemType', _.get(info.data, 'draftData.projectSystemType', ''))
    setValueEss('projectInverterSerialNumber', _.get(info.data, 'draftData.projectInverterSerialNumber', ''))
    setValueEss('projectInverterPower', _.get(info.data, 'draftData.projectInverterPower', ''))
    setClaimLabel(_.get(info.data, 'claimLabel', ''))
    setValueEss('listSerialPick', _.get(info.data, 'draftData.listSerialPick', []))
    setValueEss('serialNumbers', _.get(info.data, 'draftData.serialNumbers', []))
    //setListSerial(_.get(info.data, 'draftData.listSerialPick', []));

    setIsDraft(_.get(info.data, 'isDraft', true))

    setValueEss('purchasingChannel', _.get(info.data, 'draftData.purchasingChannel'));
    if (_.get(info.data, 'draftData.purchasingChannel') === 'distributor') {
      setValueEss('nameOfPurchasingChannelDistributor', _.get(info.data, 'draftData.nameOfPurchasingChannelDistributor'))
    } else if (_.get(info.data, 'draftData.purchasingChannel') === 'other') {
      setValueEss('nameOfPurchasingChannelOther', _.get(info.data , 'draftData.nameOfPurchasingChannel'));
    }


    setValueEss('pocketWifiSys', _.get(info.data, 'draftData.pocketWifiSys', null))
    setValueEss('projectAddressManual', _.get(info.data, 'draftData.projectAddressManual', null))
    setValueEss('batterySerialNumber', _.get(info.data, 'draftData.batterySerialNumber', ''))
    setValueEss('batteryType', _.get(info.data, 'draftData.batteryType', 'select'))
    setValueEss('numberOfBattery', _.get(info.data, 'draftData.numberOfBattery', 'select'))
    setValueEss('dsp1SwVersion', _.get(info.data, 'draftData.dsp1SwVersion', ''))
    setValueEss('armSwVersion', _.get(info.data, 'draftData.armSwVersion', ''))
    setValueEss('batterySwVersion', _.get(info.data, 'draftData.batterySwVersion', ''))
    setValueEss('criticalLoadsConnected', _.get(info.data, 'draftData.criticalLoadsConnected', ''))
    setValueEss('totalPvPowerInstalled', _.get(info.data, 'draftData.totalPvPowerInstalled', ''))
    setValueEss('choseTypeSerialUpload', _.get(info.data, 'draftData.choseTypeSerialUpload', false))

    setValueEss('projectSys', _.get(info.data, 'draftData.projectSys'))
    setValueEss('inverterPowerSys', _.get(info.data, 'draftData.inverterPowerSys'))
    setValueEss('batterySerialNumberSys', _.get(info.data, 'draftData.batterySerialNumberSys'))
    setValueEss('batteryTypeSys', _.get(info.data, 'draftData.batteryTypeSys'))
    setValueEss('numberOfBattery', _.get(info.data, 'draftData.numberOfBattery'))
    setValueEss('armSwVersion', _.get(info.data, 'draftData.armSwVersion'))
    setValueEss('batterySwVersion', _.get(info.data, 'draftData.batterySwVersion'))
    setValueEss('pv1', _.get(info.data, 'draftData.pv1'))
    setValueEss('pv2', _.get(info.data, 'draftData.pv2'))
    setValueEss('pv3', _.get(info.data, 'draftData.pv3'))
    setValueEss('pv4', _.get(info.data, 'draftData.pv4'))
    setValueEss('loadL1Voltage', _.get(info.data, 'draftData.loadL1Voltage'))
    setValueEss('loadL2Voltage', _.get(info.data, 'draftData.loadL2Voltage'))
    setValueEss('criticalConnected', _.get(info.data, 'draftData.criticalConnected'))
    setValueEss('totalPvPowerInstalled', _.get(info.data, 'draftData.totalPvPowerInstalled'))
    setValueEss('operationalModeUse', _.get(info.data, 'draftData.operationalModeUse'))

    setValueEss('distributorName', _.get(info.data, 'draftData.distributorName'))
    setValueEss('distributorCity', _.get(info.data, 'draftData.distributorCity'))
    setValueEss('poNumber', _.get(info.data, 'draftData.poNumber'))
    setValueEss('purchaseDate', _.get(info.data, 'draftData.purchaseDate', null))
    setValueEss('projectNameSys' , _.get(info.data, 'draftData.projectNameSys', ''));
    setValueEss('addressSys' , _.get(info.data, 'draftData.addressSys', ''));
    setValueEss('installationDateSys' , _.get(info.data, 'draftData.installationDateSys', null));
    setValueEss('systemTypeSys' , _.get(info.data, 'draftData.systemTypeSys', ''));
    setValueEss('productNameSys' , _.get(info.data, 'draftData.productNameSys', ''));
    setValueEss('distributorName' , _.get(info.data, 'draftData.distributorName', ''));
    setValueEss('totalPvPowerInstalled', _.get(info.data, 'draftData.totalPvPowerInstalled', ''));
    const makeData = suggestionsImageEss(_.get(info.data, 'draftData.attachments'))

    setFileErrorLog(makeData?.errorLogImage || [])
    setFileEvidence(makeData?.otherEvidence || [])
    setFileInvoiceLabor(makeData?.laborCost || [])
    setFileInfoBank(makeData?.bankAccount || [])

    setDetailClaim(info.data);

    triggerEss(keyEss).then(() => console.log('trigger'));
  }

  function renderInfoClaim() {
    if(isDraft) {
      return (
        <div className={classes.draftInfo}>
          <div className={classes.leftDraft}>
            <img style={{marginRight: 8, height : 20}} src={PopOver}/> <em>This is a draft that needs to be completed</em>
          </div>
          <div className={classes.rightDraft}>
            <CustomButton style={{height : 35}} onClick={() => {
              history.push({
                pathname : `/claim/edit/2/1/${id}`,
                state : {
                  isPickClaimType : true
                }
              })
            }}>
              Continue Submission
            </CustomButton>
          </div>
        </div>
      )
    }else {
      return (
        <>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent} style={{borderTop: '1px solid rgba(0, 0, 0, .1)'}}>
              <h6>Claim Number</h6>
              <p>{claimLabel}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Qualified for labor warranty</h6>
              <p>{_.get(data, 'isDraft', false) ? '----' : renderLaborStatus(_.get(detailClaim, 'draftData.laborReimbursement', false), _.get(detailClaim, 'draftData.laborWarranty', false), _.get(detailClaim, 'draftData.choseTypeProject', '1') , _.get(detailClaim, 'draftData.chooseRefund', '1'), _.get(detailClaim, 'draftData.batteryQualified', false))}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer} style={{marginBottom : 20}}>
            <div className={classes.reviewSectionContent}>
              <h6>Submitted By</h6>
              <p>{decodeStringHtml(getValues('installerName'))}</p>
            </div>
          </div>
        </>
      )
    }
  }

  function renderSerialNumber() {
    if(choseTypeSerialUpload === '1') {
      return getValues('serialNumbers')?.map(item => item.serialNumber).join(', ').toString()
    }else {
      return listSerialPickData?.map(item => item.serialNumber).join(', ').toString()
    }

  }

  function renderAddressProjectInfo() {
    const infoAddressProject = watchEss('projectAddress')
    const infoAddressProjectManual = watchEss('projectAddressManual')
    const choseTypeProject = watchEss('choseTypeProject');

    if(choseTypeProject === '2') {
      return infoAddressProjectManual?.label || ''
    }

    return infoAddressProject

  }

  return (
    <div className={classes.bgDetail}>
      <BannerDetail />
      {loadingDetail ? <div className={classes.loadingEdit}>
        <CustomLoading size={40} color="#00adef"/>
      </div> :
      <div className={classes.reviewDetail}>
      <div>
      <div className={classes.headerClaim}>
        <div className={classes.leftClaim}>
          <div className={classes.claimInfo}>Claim Details</div>
          <div className={classes.titleClaim}>{!errors?.projectNameSys ? getValues('projectNameSys') : <span style={{color: '#EB5757'}}>Required field missing</span> }</div>
          <div className={classes.statusClaim}>Status: &nbsp; 
            <span style={{marginRight: 6, color: [5, 11, 6, 7 , 9 , 15].includes(data.statusId) ? '#009EE3' : [2,10].includes(data.statusId) ? '#EB5757' : '#000'}}>
              {!data.isDraft ? renderStatusName(data.statusId) : 'Draft' }
            </span>
            {!isDraft && <PopoverDetail />}
          </div>
        </div>

        <div className={classes.rightClaim}>
          <div className={classes.textTypeClaim}>
            Claim Type : <em className={classes.typeclaimInfo}>Battery</em>
          </div>
          <div className={classes.submited}>
            Submission Date :&nbsp;{data.created ?  <em style={{color : '#009EE3'}}><Moment format="L">{data.created}</Moment></em> : '---'}
          </div>
        </div>
      </div>
        {renderInfoClaim()}
        {!isDraft && data.statusId === 2 && <FormUpDateInfoClaim text={text} keyUpload={keyUpload} setKeyUpload={setKeyUpload} id={id}/>}
      <div className={classes.reviewSectionsContainer}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>User Info</h5>
          {isDraft && 
              <Button 
                className={classes.editBtn} 
                onClick={() => history.push({
                  pathname: `/claim/edit/2/1/${id}`,
                  state: {
                    isPickClaimType: true
                  }
                })}
              >
                <span>Edit</span>
                <img src={IconEdit} title='Edit' />
              </Button>}
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Company Name</h6>
            <p>{decodeStringHtml(getValues('companyName'))}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Company Address</h6>
            <p>
              {renderAddress([
                data.installerAddress1,
                data.installerAddress2,
                data.installerCity,
                data.installerState,
                data.installerZipcode
              ])}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div
            style={{
              paddingTop: '13px',
              paddingBottom: '13px'
            }}
            className={classes.reviewSectionContent}
          >
            <h6>User Name</h6>
            <p>{data.installerName}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>E-mail </h6>
            <p>
              {data.installerEmail}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Phone Number</h6>
            <p>
              {data.installerPhone}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Contact E-Mail</h6>
            <p>
              {data.submitterEmail || data.installerEmail}
            </p>
          </div>
        </div>
      </div>
      
      <div className={classes.reviewSectionsContainer}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>Issue</h5>
          {isDraft && 
            <Button 
              className={classes.editBtn} 
              onClick={() => history.push({
                pathname: `/claim/edit/2/1/${id}`,
                state: {
                  isPickClaimType: false
                }
              })}
            >
              <span>Edit</span>
              <img src={IconEdit} title='Edit' />
            </Button>}
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Claim Type</h6>
            <p>
              {renderClaimType(data.claimType)}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Description of the Issue</h6>
            <p>
              {!errors?.description ? getValues('description') : <span style={{color: '#EB5757'}}>Required field missing</span>}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Error Logs</h6>
            <div className={classes.images}>
              <p>{fileErrorLog.length === 0 && <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
              { fileErrorLog.map(p => (
                <div key={p.url}>
                  <img src={p.url} className={classes.image} />
                  <div className={classes.overlay} role="overlay">
                    <img
                      src={OpenImageIcon}
                      onClick={() => {
                        setModalUrl(p.url);
                        setOpenModal(true);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Other evidence</h6>
            <div className={classes.images}>
              { fileEvidence.map(p => (
                <div key={p.url}>
                  <img src={p.url} className={classes.image} />
                  <div className={classes.overlay} role="overlay">
                    <img
                      src={OpenImageIcon}
                      onClick={() => {
                        setModalUrl(p.url);
                        setOpenModal(true);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={classes.reviewSectionsContainer}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>System info</h5>
          {isDraft && 
            <Button 
              className={classes.editBtn} 
              onClick={() => history.push({
                pathname: `/claim/edit/2/2/${id}`,
                state: {
                  isPickClaimType: false
                }
              })}
            >
              <span>Edit</span>
              <img src={IconEdit} title='Edit' />
            </Button>}
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Project name</h6>
            <p>{!errors?.projectNameSys ? getValues('projectNameSys') : <span style={{color: '#EB5757'}}>Required field missing</span> }</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Address</h6>
            <p>{(!errors?.projectAddress && !errors.projectAddressManual) ? renderAddressProjectInfo() : <Error/>}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Installation Date</h6>
            <p>{!errors?.projectInstallationDate ? data.projectInstallationDate && <Moment format="L">{data.projectInstallationDate}</Moment> : <span style={{color: '#EB5757'}}>Required field missing</span> }</p>
          </div>
        </div>
        {/*<div className={classes.reviewSectionContainer}>*/}
        {/*  <div className={classes.reviewSectionContent}>*/}
        {/*    <h6>Permission Date</h6>*/}
        {/*    <p>{data.projectPermissionDate && <Moment format="L">{data.projectPermissionDate}</Moment>}</p>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Product name</h6>
            <p>{!errors?.projectProductName ? getValues('projectProductName'): <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>System type</h6>
            <p>{!errors?.projectSystemType ? getValues('projectSystemType') : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Inverter serial number</h6>
            <p>{!errors?.projectInverterSerialNumber ? renderSerialNumber() : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Inverter power</h6>
            <p>{!errors?.projectInverterPower ? getValues('projectInverterPower') : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Pocket WiFi SW #</h6>
            <p>{getValues('pocketWifiSys')}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Batteries Serial Number</h6>
            <p>{getValues('batterySerialNumber')}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Battery Type</h6>
            <p>{renderField('batteryType')}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Number of batteries</h6>
            <p>{!errors?.numberOfBattery ? getValues('numberOfBattery') :  <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>
      </div>

      <div className={classes.reviewSectionsContainer}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>Software version</h5>
          {isDraft && 
            <Button 
              className={classes.editBtn} 
              onClick={() => history.push({
                pathname: `/claim/edit/2/2/${id}`,
                state: {
                  isPickClaimType: false
                }
              })}
            >
              <span>Edit</span>
              <img src={IconEdit} title='Edit' />
            </Button>}
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>ARM SW Version</h6>
            <p>{getValues('dsp1SwVersion')}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>ARM SW Version</h6>
            <p>{getValues('armSwVersion')}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Battery Master SW Version</h6>
            <p>{getValues('batterySwVersion')}</p>
          </div>
        </div>


      </div>

      <div className={classes.reviewSectionsContainer}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>Field Details</h5>
          {isDraft && 
            <Button 
              className={classes.editBtn} 
              onClick={() => history.push({
                pathname: `/claim/edit/2/2/${id}`,
                state: {
                  isPickClaimType: false
                }
              })}
            >
              <span>Edit</span>
              <img src={IconEdit} title='Edit' />
            </Button>}
        </div>

        {data.systemType === 'DC' && <>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>PV1 String Voltage</h6>
              <p>{getValues('pv1')}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>PV2 String Voltage</h6>
              <p>{getValues('pv2')}</p>
            </div>
          </div>

          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>PV3 String Voltage</h6>
              <p>{getValues('pv3')}</p>
            </div>
          </div>

          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>PV4 String Voltage</h6>
              <p>{getValues('pv4')}</p>
            </div>
          </div>
        </>}

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Load L1 to N Voltage</h6>
            <p>{getValues('loadL1Voltage')}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Load L2 to N Voltage</h6>
            <p>{getValues('loadL2Voltage')}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Critical Loads connected</h6>
            <p>{getValues('criticalLoadsConnected')}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Total PV Power installed</h6>
            <p>{!errors?.totalPvPowerInstalled ? getValues('totalPvPowerInstalled'): <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Operational mode use</h6>
            <p>{renderField('operationalModeUse')}</p>
          </div>
        </div>

      </div>

      <div className={classes.reviewSectionsContainer}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>Purchase Info</h5>
          {isDraft && 
            <Button 
              className={classes.editBtn} 
              onClick={() => history.push({
                pathname: `/claim/edit/2/3/${id}`,
                state: {
                  isPickClaimType: false
                }
              })}
            >
              <span>Edit</span>
              <img src={IconEdit} title='Edit' />
            </Button>}
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent} style={{ borderTop: '1px solid rgba(0, 0, 0, .1)' }}>
            <h6>Purchasing Channel</h6>
            <p>{!errors?.purchasingChannel ? renderPurchasingChannel(data?.purchasingChannel) : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Name of Purchasing Channel</h6>
            <p>{(!errors?.nameOfPurchasingChannelOther && !errors?.nameOfPurchasingChannelDistributor && !errors?.nameOfPurchasingChannelSelect) ? renderNameOfPurchasingChannel() : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>PO Number</h6>
            <p>{getValues('poNumber')}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Purchase Date</h6>
            <p>
              {data.purchaseDate && <Moment format="L">{data.purchaseDate}</Moment>}
            </p>
          </div>
        </div>

      </div>

      <div className={classes.reviewSectionsContainer}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>Labor Reimbursement</h5>
          {isDraft && 
            <Button 
              className={classes.editBtn} 
              onClick={() => history.push({
                pathname: `/claim/edit/2/3/${id}`,
                state: {
                  isPickClaimType: false
                }
              })}
            >
              <span>Edit</span>
              <img src={IconEdit} title='Edit' />
            </Button>}
        </div>
        <div className={classes.reviewSectionContent}>
          <h6>Invoice for Labor Cost Reimbursement</h6>
          <div className={classes.images}>
            <p>{errors.invoiceForLaborCost && <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
            <p>{!errors.invoiceForLaborCost && fileInvoiceLabor.length === 0 ? 'N/A' : ''}</p>
            { fileInvoiceLabor.map(p => (
              <div key={p.url}>
                <img src={p.url} className={classes.image} />
                <div className={classes.overlay} role="overlay">
                  <img
                    src={OpenImageIcon}
                    onClick={() => {
                      setModalUrl(p.url);
                      setOpenModal(true);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.reviewSectionContent}>
          <h6>Bank Account Information</h6>
          <div className={classes.images}>
            <p>{errors.infoBank && <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
            <p>{!errors.infoBank && fileInfoBank.length === 0 ? 'N/A' : ''}</p>
            { fileInfoBank?.map(p => (
              <div key={p.url}>
                <img src={p.url} className={classes.image} />
                <div className={classes.overlay} role="overlay">
                  <img
                    src={OpenImageIcon}
                    onClick={() => {
                      setModalUrl(p.url);
                      setOpenModal(true);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>


      <ImageModal
        open={openModal}
        url={modalUrl}
        name={modalUrl ? modalUrl.substring(modalUrl.lastIndexOf('/') + 1) : ''}
        handleClose={() => {
          setOpenModal(false);
          setModalUrl('');
        }}
      />
    </div>
      </div>}
    </div>
  );
}

export default ClaimDetailBattery;
