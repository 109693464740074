import axios from '../utils/axios';
import config from '../config';

function sendInfoProductAurora(payload) {
  const request = {
    method : 'POST',
    url : `${config.baseUrl}/orders/hubspot/submit`,
    data : payload
  }

  return axios(request);
}


export {sendInfoProductAurora};
