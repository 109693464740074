import axios from '../utils/axios';
import config from '../config';

function getDashboardProjects() {
  const request = {
    method: 'GET',
    url: `${config.baseUrl}/projects/analytic`
  };

  return axios(request);
}

function getDashboardClaims() {
  const request = {
    method: 'GET',
    url: `${config.baseUrl}/claims/analytic`
  };

  return axios(request);
}

export { getDashboardProjects, getDashboardClaims };
