import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import BreadCrumb2 from './../../common/components/BreadCrumb2';
import Banner from '../../common/components/Banner';
import MyPageBanner from '../../config/images/my-page-banner.jpg';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../../common/components/text';
import { Table, TableBody, TableRow, TableCell, Grid, Box, TextField, FormControlLabel, Checkbox, Button, Select, MenuItem, RadioGroup, Radio, TextareaAutosize, InputAdornment } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import config from '../../config';
import axios from '../../utils/axios';
import { notiFailed, notiSuccess, convertHTMLEntity } from './../../utils/helpers.js';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import _ from 'lodash';
import ReactSelect from 'react-select';
import CustomLoading from './../../common/components/CustomLoading';
import classNames from 'classnames';
import RadioIcon from '../../config/images/bg_radio.png';
import RadioOnIcon from '../../config/images/bg_radio_on.png';
import CheckBox from '../../config/images/bg_checkbox.png';
import CheckBoxOn from '../../config/images/bg_checkbox_on.png';
import { useAPIClient } from '../../contexts/api';
import NumberFormat from 'react-number-format';
import { MODULE_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT, ESS_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT } from '../../utils/constants';
import IconSelect from '../../icons/ico_select.png';
import RouteLeavingGuard from './RouteLeavingGuard';
import { sendEventForProfile, ACTION_EDIT_PROFILE } from './../../utils/gaHelpers';

function requiredWith(ref, expectedValue, msg) {
    return this.test({
      name: 'requiredWith',
      exclusive: false,
      message: msg,
      test: function(value) {
        if (this.resolve(ref) === expectedValue) {
            return !!value;
        }
  
        return true;
      }
    });
}

function validateInstallationVolumeValue(ref, type, msg) {
    return this.test({
      name: 'validateInstallationVolumeValue',
      exclusive: false,
      message: msg,
      test: function(value) {
        const selectedInstallationVolume = this.resolve(ref);
        const installationVolume = this.options.context.primaryProducts[type].find(o => o.id === +selectedInstallationVolume);
        
        if (installationVolume) {
          if (installationVolume.min && installationVolume.max){
            return value >= installationVolume.min && value <= installationVolume.max;
          } else if (installationVolume.min) {
            return value >= installationVolume.min;
          } else if (installationVolume.max) {
            return value <= installationVolume.max && value >= 0;
          }
        }
  
        return true;
      }
    });
}
  
yup.addMethod(yup.mixed, 'requiredWith', requiredWith);
yup.addMethod(yup.mixed, 'validateInstallationVolumeValue', validateInstallationVolumeValue);

const useStyles = makeStyles(theme => ({
    pageContainer: {
        paddingTop: '52px',
        width: '65%',
        margin: '0 auto',
        transition: 'all 0.4s',
        marginBottom: '20px',
        [theme.breakpoints.down('md')]: {
            width: '90%'
        }
    },
    pageTitle: {
        color: '#000',
        fontSize: '26px',
        fontWeight: 500,
        lineHeight: '26px',
        textTransform: 'uppercase',
        marginBottom: '15px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '18px !important'
        }
    },
    infoEs: {
        fontSize: '14px',
        position: 'relative',
        '& span': {
            paddingLeft: '17px'
        },
        '& span::before': {
            position: 'absolute',
            left: 0,
            top: '50%',
            width: '12px',
            height: '1px',
            background: '#009ee3',
            content: '""'
        }
    },
    table: {
        marginTop: '10px',
        border: 'solid #000',
        borderWidth: '1px 0',
        '& td': {
          fontSize: '15px',
          fontFamily: 'Actual_Light',
          color: '#333'
        },
        '& .MuiTableCell-root': {
          padding: '24px'
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0
        },
        '& .MuiOutlinedInput-input': {
            padding: '13.5px 14px'
        },
        [theme.breakpoints.down('xs')]: {
            '& .MuiTableRow-root': {
                display: 'flex',
                flexDirection: 'column'
            },
            '& .MuiTableCell-root': {
                borderBottom: 0
            },
            '& .MuiTableRow-root td:first-child': {
                paddingBottom: '16px'
            },
            '& .MuiTableRow-root td:last-child': {
                paddingTop: 0
            }
        }      
    },
    icoEs: {
        position: 'relative',
        '&::after': {
            display: 'block',
            position: 'absolute',
            left: '1px',
            bottom: '-4px',
            width: '14px',
            height: '1px',
            background: '#009ee3',
            content: '""'
        }
    },
    agreeBox: {
        marginTop: '30px',
        padding: '36px 60px',
        background: '#f8f8f8',
        '& .MuiTypography-body1': {
            fontSize: '16px',
            fontFamily: 'Actual_Light'
        },
        '& .Mui-checked .MuiSvgIcon-root': {
            fill: '#009ee3'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '36px 20px'
        }
    },
    businessSegmentCheckbox: {
        '& .MuiTypography-body1': {
            fontFamily: 'Actual_Light',
            fontSize: '15px'
        },
        '& .Mui-checked .MuiSvgIcon-root': {
            fill: '#009ee3'
        }
    },
    confirmBtn: {
        background: '#666',
        color: '#fff',
        transition: 'all 0.4s',
        borderRadius: 0,
        textTransform: 'none',
        fontSize: '16px',
        border: '1px solid #666',
        '& span.MuiButton-label': {
            minWidth: '110px',
            height: '38px'
        },
        '&:hover': {
            backgroundColor: '#444'
        }
    },
    cancelBtn: {
        transition: 'all 0.4s',
        borderRadius: 0,
        textTransform: 'none',
        fontSize: '16px',
        border: '1px solid #666',
        color: '#666',
        marginLeft: '10px',
        '& span.MuiButton-label': {
            padding: '5px 30px'
        },
        '&:hover': {
            border: '1px solid #444',
            color: '#444',
            backgroundColor: '#fff'
        }
    },
    inputField: {
        '& .MuiOutlinedInput-notchedOutline': {
            transition: 'background 0.3s, border 0.3s'
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0
        },
        '& .MuiOutlinedInput-input': {
            padding: '13.5px 14px'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: 'rgba(0, 0, 0, 0.23) !important'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000 !important',
            borderWidth: '1px'
        }
    },
    selectField: {
        borderRadius: 0,
        '& .MuiSelect-outlined': {
          borderRadius: 0
        },
        '& .MuiOutlinedInput-input': {
          padding: '13.5px 14px'
        },
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: 'rgba(0, 0, 0, 0.23) !important'
        },
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#000 !important',
          borderWidth: '1px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          transition: 'background 0.3s, border 0.3s'
        }
    },
    errorInput: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red !important'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red !important',
            borderWidth: '1px'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red !important',
            borderWidth: '1px'
        }
    },
    errorTextarea: {
        border: '1px solid red'
    },
    errorSelect: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red !important'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red !important',
            borderWidth: '1px'
        },
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red !important',
            borderWidth: '1px'
        }
    },
    errorReactSelect: {
        '& .select__control': {
            borderColor: '#EB5757',
            boxShadow: 'none'
        },
        '& .select__control:hover': {
            borderColor: 'red !important'
        }
    },
    selectedMainContactNumber: {
        '& .MuiTypography-body1': {
          fontFamily: 'Actual_Light'
        }
    },
    radio: {
        background: `url(${RadioIcon}) center /100% 100% no-repeat`, 
        width: '20px', 
        height: '20px', 
        lineHeight: '20px', 
        transition: 'background 0.4s'
    },
    radioOn: {
        background: `url(${RadioOnIcon}) center /100% 100% no-repeat`, 
        width: '20px', 
        height: '20px', 
        lineHeight: '20px', 
        transition: 'background 0.4s'
    },
    checkBox: {
        background: `url(${CheckBox}) center /100% 100% no-repeat`, 
        width: '20px', 
        height: '20px', 
        lineHeight: '20px', 
        transition: 'background 0.4s'
    },
    checkBoxOn: {
        background: `url(${CheckBoxOn}) center /100% 100% no-repeat`,
        width: '20px', 
        height: '20px',
        lineHeight: '20px', 
        transition: 'background 0.4s'
    },
    websiteCheckboxText: {
        fontSize: '15px',
        fontFamily: 'Actual_Light',
        color: '#333'
    },
    addressSection: {
        '& p': {
          fontFamily: 'Actual_Light'
        }
    },
    multiSelect: {
        '& .select__control': {
            borderRadius: 0,
            padding: '4px',
            transition: 'borderColor 0.4s',
            minHeight: '46px'
        },
        '& .select__control:hover': {
          borderColor: '#000',
          boxShadow: 'none'
        },
        '& .select__control.select__control--is-focused': {
          borderColor: '#000',
          boxShadow: 'none'
        },
        '& .select__multi-value__remove:hover': {
            backgroundColor: '#00adef',
            color: '#fff'
        }
    },
    w9ImageInput: {
        display: 'none'
    },
    checkSearchImageBtn: {
        border: '1px solid #666',
        boxSizing: 'border-box',
        color: '#000',
        fontSize: '15px',
        lineHeight: '40px',
        padding: '2px 10px',
        borderRadius: 0,
        textTransform: 'none',
        fontFamily: 'Actual_Light',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    overviewTextarea: {
        width: '100%',
        padding: '10px 12px'
    },
    surveySection: {
        marginTop: '5px',
        '& .MuiTypography-body1': {
          fontSize: '15px',
          fontFamily: 'Actual_Light',
        },
        '& .MuiGrid-grid-md-3': {
            maxWidth: '18%'
        }
    },
    sectionPrimaryProduct: {
        '& p': {
          fontSize: '15px',
          fontFamily: 'Actual_Light'
        }
    },
    inputFieldDisable: {
        background: '#f8f8f8',
        color: '#666'
    },
    helperText: {
        fontSize: '14px',
        paddingTop: '5px',
        fontFamily: 'Actual_Light'
    },
    moduleInstallationContentContainer: {
        display: 'grid', 
        gridTemplateColumns: '2fr 10fr',
        gridGap: '10px', 
        alignContent: 'center' 
    },
    moduleInstallationFieldContainer: {
        display: 'grid', 
        gridTemplateColumns: '1fr 1fr',
        gridGap: '10px', 
        alignContent: 'center',
        marginBottom: '10px'
    },
    addMoreProductBtn: {
        textTransform: 'none',
        fontFamily: 'Actual',
        fontSize: '15px',
        paddingLeft: 0,
        paddingRight: 0,
        fontWeight: 400,
        '&:hover': {
          backgroundColor: '#fff'
        }
    },
    essInstallationFieldContainer: {
        display: 'grid', 
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridGap: '10px', 
        alignContent: 'center',
        marginBottom: '10px'
    },
    customSelectField: {
        width: '100%',
        height: '46px',
        padding: '0 20px 0 9px',
        background: `#fff url(${IconSelect}) right center /25px 8px no-repeat`,
        border: '1px solid rgba(0, 0, 0, 0.23)',
        boxSizing: 'border-box',
        color: '#212121',
        fontFamily: 'Actual_Light',
        fontSize: '15px',
        fontWeight: 'normal',
        verticalAlign: 'top',
        transition: 'background 0.3s, border 0.3s',
        '-moz-appearance': 'none',
        '-webkit-appearance': 'none',
        appearance: 'none',
        '&:focus': {
            outlineStyle: 'none',
            borderColor: '#000'
        },
        '&::-ms-expand': {
            display: 'none'
        }
    },
    customSelectFieldDisable: {
        backgroundColor: '#f8f8f8',
        color: '#666'
    },
    errorCustomSelect: {
        borderColor: '#EB5757'
    },
    annualText: {
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '13px',
        color: '#fff !important',
        backgroundColor: '#717171',
        display: 'inline-block',
        borderRadius: '22px',
        height: '16px',
        textAlign: 'center',
        width: '57px',
        lineHeight: '16px',
        marginLeft: '2px',
        marginRight: '2px'
    }
}));

const { baseUrl } = config;

const getSchema = (isNewUser, approveStep) => {
    if (isNewUser) {
        return yup.object().shape({
            website: yup.string().when('notWebsite', { is: false, then: yup.string().required('URL is required').matches(/^([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?/, "Please enter the url except for http://. ex) www.qpartner.com") }),
            firstName: yup.string().required('First Name is required'),
            lastName: yup.string().required('Last Name is required'),
            title: yup.string().notOneOf(['select'], 'Title is required'),
            password: yup.string().required('Password is required.')
                .test({ name: 'is-valid', message: 'Password must be at least 8 characters in combination of letters, numbers, and symbols (0-9, A-Z, at least one symbol)', 
                exclusive: true, test: value => /^(?=.*([a-z]|[A-Z]))(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(value) }),
            confirmPassword: yup.string().required('Passwords do not match').oneOf([yup.ref('password')], 'Passwords do not match'),
            mainContactNumber: yup.string().required("Main contact number is required").matches(/^[0-9]*$/, "This entry can only contain numbers (e.g., 9497774444)."),
            address: yup.string().required("Primary address is required"),
            city: yup.string().required("City is required"),
            state: yup.string().notOneOf(['select'], 'State is required'),
            zipCode: yup.string().required("Zipcode is required"),
            shippingAddress: yup.string().when('hasShippingAddress', { is: true, then: yup.string().required("Primary shipping address is required") }),
            shippingCity: yup.string().when('hasShippingAddress', { is: true, then: yup.string().required("Shipping city is required") }),
            shippingState: yup.string().when('hasShippingAddress', { is: true, then: yup.string().notOneOf(['select'], 'Shipping state is required') }),
            shippingZipCode: yup.string().when('hasShippingAddress', { is: true, then: yup.string().required("Shipping zipcode is required") }),
            otherTitle: yup.string().when('title', { is: 'other', then: yup.string().required('Other Job Title is required.') }),
            salesRegion: yup.array().test({ name: 'required', test: value => value.length > 0, exclusive: true, message: 'Sales region is required' }),
            purchasingChannelS: yup.object().nullable().notOneOf([null], 'Main purchasing channel is required'),
            purchasingChannelT: yup.string().when('purchasingChannelS', { is: value => _.get(value, 'name') === 'Other', then: yup.string().required('Another purchasing channel is required') }),
            agree: yup.bool(),
            installationVolume: yup.string().notOneOf(['select'], 'Installation volume (kW/annual) is required'),
            installationVolumeValue: yup.mixed().when('installationVolume', { is: value => MODULE_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+value), 
              then: yup.number().typeError('Installation volume value is required').required('Installation volume value is required')
              .validateInstallationVolumeValue(yup.ref('installationVolume'), 'installationVolumes', 'Value has to be within the selected range') }),
            modulePrimaryProduct: yup.array().when('notPrimaryProduct', {
                is: false,
                then: yup.array().min(1).of(yup.object().shape({
                  brand: yup.array().typeError('Brand is required').min(1, 'Brand is required')
                }))
            }),
            essInstallationVolume: yup.string().notOneOf(['select'], 'Installation volume (set/annual) is required'),
            essInstallationVolumeValue: yup.mixed().when('essInstallationVolume', { is: value => ESS_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+value), 
              then: yup.number().typeError('Installation volume value is required').required('Installation volume value is required')
              .validateInstallationVolumeValue(yup.ref('essInstallationVolume'), 'essInstallationVolumes', 'Value has to be within the selected range') }),
            essPrimaryProduct: yup.array().when('notEssPrimaryProduct', {
                is: false,
                then: yup.array().min(1).of(yup.object().shape({
                  brand: yup.array().typeError('Brand is required').min(1, 'Brand is required')
                })) 
            })
        });
    } else if (approveStep === 2) {
        return yup.object().shape({
            partnerType: yup.number().notOneOf([-1], 'Main business type is required'),
            title: yup.string().notOneOf(['select'], 'Title is required'),
            otherTitle: yup.string().when('title', { is: 'other', then: yup.string().required('Other Job Title is required.') }),
            mainContactNumber: yup.string().required("Main contact number is required").matches(/^[0-9]*$/, "This entry can only contain numbers (e.g., 9497774444)."),
            purchasingChannelS: yup.object().nullable().notOneOf([null], 'Main purchasing channel is required'),
            purchasingChannelT: yup.string().when('purchasingChannelS', { is: value => _.get(value, 'name') === 'Other', then: yup.string().required('Another purchasing channel is required') }),
            installationVolume: yup.string().notOneOf(['select'], 'Installation volume (kW/annual) is required'),
            installationVolumeValue: yup.mixed().when('installationVolume', { is: value => MODULE_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+value), 
                then: yup.number().typeError('Installation volume value is required').required('Installation volume value is required')
                .validateInstallationVolumeValue(yup.ref('installationVolume'), 'installationVolumes', 'Value has to be within the selected range') }),
            modulePrimaryProduct: yup.array().when('notPrimaryProduct', {
                is: false,
                then: yup.array().min(1).of(yup.object().shape({
                    brand: yup.array().typeError('Brand is required').min(1, 'Brand is required')
                }))
            }),
            essInstallationVolume: yup.string().notOneOf(['select'], 'Installation volume (set/annual) is required'),
            essInstallationVolumeValue: yup.mixed().when('essInstallationVolume', { is: value => ESS_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+value), 
                then: yup.number().typeError('Installation volume value is required').required('Installation volume value is required')
                .validateInstallationVolumeValue(yup.ref('essInstallationVolume'), 'essInstallationVolumes', 'Value has to be within the selected range') }),
            essPrimaryProduct: yup.array().when('notEssPrimaryProduct', {
                is: false,
                then: yup.array().min(1).of(yup.object().shape({
                    brand: yup.array().typeError('Brand is required').min(1, 'Brand is required')
                })) 
            }) 
        })
    } else {
        return yup.object().shape({
            company: yup.string().required('Company is required'),
            partnerType: yup.number().notOneOf([-1], 'Main business type is required'),
            title: yup.string().notOneOf(['select'], 'Title is required'),
            otherTitle: yup.string().when('title', { is: 'other', then: yup.string().required('Other Job Title is required.') }),
            mainContactNumber: yup.string().required("Main contact number is required").matches(/^[0-9]*$/, "This entry can only contain numbers (e.g., 9497774444)."),
            salesRegion: yup.array().test({ name: 'required', test: value => value.length > 0, exclusive: true, message: 'Sales region is required' }),
            purchasingChannelS: yup.object().nullable().notOneOf([null], 'Main purchasing channel is required'),
            purchasingChannelT: yup.string().when('purchasingChannelS', { is: value => _.get(value, 'name') === 'Other', then: yup.string().required('Another purchasing channel is required') }),
            installationVolume: yup.string().notOneOf(['select'], 'Installation volume (kW/annual) is required'),
            installationVolumeValue: yup.mixed().when('installationVolume', { is: value => MODULE_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+value), 
                then: yup.number().typeError('Installation volume value is required').required('Installation volume value is required')
                .validateInstallationVolumeValue(yup.ref('installationVolume'), 'installationVolumes', 'Value has to be within the selected range') }),
            modulePrimaryProduct: yup.array().when('notPrimaryProduct', {
                is: false,
                then: yup.array().min(1).of(yup.object().shape({
                    brand: yup.array().typeError('Brand is required').min(1, 'Brand is required')
                }))
            }),
            essInstallationVolume: yup.string().notOneOf(['select'], 'Installation volume (set/annual) is required'),
            essInstallationVolumeValue: yup.mixed().when('essInstallationVolume', { is: value => ESS_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+value), 
                then: yup.number().typeError('Installation volume value is required').required('Installation volume value is required')
                .validateInstallationVolumeValue(yup.ref('essInstallationVolume'), 'essInstallationVolumes', 'Value has to be within the selected range') }),
            essPrimaryProduct: yup.array().when('notEssPrimaryProduct', {
                is: false,
                then: yup.array().min(1).of(yup.object().shape({
                    brand: yup.array().typeError('Brand is required').min(1, 'Brand is required')
                })) 
            }),
            taxId: yup.string().required("Tax ID is required"),
            w9: yup.mixed().when('isW9', { is: false, then: yup.mixed().test('required', 'W9 is required', value => value.length > 0) }),
        })
    } 
};

const schema = yup.object().shape({
  website: yup.string().when('notWebsite', { is: false, then: yup.string().required('URL is required').matches(/^([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?/, "Please enter the url except for http://. ex) www.qpartner.com") }),
  partnerType: yup.number().notOneOf([-1], 'Main business type is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  title: yup.string().notOneOf(['select'], 'Title is required'),
  password: yup.string().required('Password is required.')
      .test({ name: 'is-valid', message: 'Password must be at least 8 characters in combination of letters, numbers, and symbols (0-9, A-Z, at least one symbol)', 
      exclusive: true, test: value => /^(?=.*([a-z]|[A-Z]))(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(value) }),
  confirmPassword: yup.string().required('Passwords do not match').oneOf([yup.ref('password')], 'Passwords do not match'),
  mainContactNumber: yup.string().required("Main contact number is required").matches(/^[0-9]*$/, "This entry can only contain numbers (e.g., 9497774444)."),
  address: yup.string().required("Primary address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().notOneOf(['select'], 'State is required'),
  zipCode: yup.string().required("Zipcode is required"),
  shippingAddress: yup.string().when('hasShippingAddress', { is: true, then: yup.string().required("Primary shipping address is required") }),
  shippingCity: yup.string().when('hasShippingAddress', { is: true, then: yup.string().required("Shipping city is required") }),
  shippingState: yup.string().when('hasShippingAddress', { is: true, then: yup.string().notOneOf(['select'], 'Shipping state is required') }),
  shippingZipCode: yup.string().when('hasShippingAddress', { is: true, then: yup.string().required("Shipping zipcode is required") }),
  otherTitle: yup.string().when('title', { is: 'other', then: yup.string().required('Other Job Title is required.') }),
  salesRegion: yup.array().test({ name: 'required', test: value => value.length > 0, exclusive: true, message: 'Sales region is required' }),
  purchasingChannelS: yup.object().nullable().notOneOf([null], 'Main purchasing channel is required'),
  purchasingChannelT: yup.string().when('purchasingChannelS', { is: value => _.get(value, 'name') === 'Other', then: yup.string().required('Another purchasing channel is required') }),
  taxId: yup.string().required("Tax ID is required"),
  agree: yup.bool(),
  survey: yup.string().required('Survey is required.'),
  surveyText1: yup.string().requiredWith(yup.ref('survey'), '1', 'Details are required'),
  surveyText2: yup.string().requiredWith(yup.ref('survey'), '2', 'Details are required'),
  surveyText3: yup.string().requiredWith(yup.ref('survey'), '3', 'Details are required'),
  surveyText4: yup.string().requiredWith(yup.ref('survey'), '4', 'Details are required'),
  surveyText5: yup.string().requiredWith(yup.ref('survey'), '5', 'Details are required'),
  surveyText6: yup.string().requiredWith(yup.ref('survey'), '6', 'Details are required'),
  surveyText7: yup.string().requiredWith(yup.ref('survey'), '7', 'Details are required'),
  w9: yup.mixed().when('isW9', { is: false, then: yup.mixed().test('required', 'W9 is required', value => value.length > 0) }),
  installationVolume: yup.string().notOneOf(['select'], 'Installation volume (kW/annual) is required'),
  installationVolumeValue: yup.mixed().when('installationVolume', { is: value => MODULE_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+value), 
    then: yup.number().typeError('Installation volume value is required').required('Installation volume value is required')
    .validateInstallationVolumeValue(yup.ref('installationVolume'), 'installationVolumes', 'Value has to be within the selected range') }),
  modulePrimaryProduct: yup.array().when('notPrimaryProduct', {
      is: false,
      then: yup.array().min(1).of(yup.object().shape({
        brand: yup.array().typeError('Brand is required').min(1, 'Brand is required')
      }))
  }),
  essInstallationVolume: yup.string().notOneOf(['select'], 'Installation volume (set/annual) is required'),
  essInstallationVolumeValue: yup.mixed().when('essInstallationVolume', { is: value => ESS_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+value), 
    then: yup.number().typeError('Installation volume value is required').required('Installation volume value is required')
    .validateInstallationVolumeValue(yup.ref('essInstallationVolume'), 'essInstallationVolumes', 'Value has to be within the selected range') }),
  essPrimaryProduct: yup.array().when('notEssPrimaryProduct', {
      is: false,
      then: yup.array().min(1).of(yup.object().shape({
        brand: yup.array().typeError('Brand is required').min(1, 'Brand is required')
      })) 
  })
});

export default function EditProfile() {
    const user = useAuth();
    const classes = useStyles();
    const history = useHistory();
    const apiClient = useAPIClient();
    const [userProfile, setUserProfile] = useState({});
    const [mainPurchasingChannel, setMainPurchasingChannel] = useState([]);
    const [jobTitles, setJobTitles] = useState([]);
    const [saleRegions, setSaleRegions] = useState([]);
    const [w9Image, setW9Image] = useState('');
    const [primaryProducts, setPrimaryProducts] = useState({
        installationVolumes: [],
        productBrands: [],
        essInstallationVolumes: [],
        essProductBrands: []
    });
    const [userType, setUserType] = useState({
        isNew: false,
        isStep1: false,
        isStep2: false
    });
    const [schema, setSchema] = useState(getSchema(true));
    const { register, handleSubmit, watch, errors, setValue, control, getValues, trigger } = useForm({
        resolver : yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: false,
        defaultValues: {
            purchasingChannelS: null,
            selectedMainContactNumber: '1',
            state: 'select',
            survey: '7',
            primaryProduct: [],
            partnerType: -1,
            title: 'select',
            salesRegion: [],
            installationVolume: 'select',
            isW9: false,
            modulePrimaryProduct: [],
            essPrimaryProduct: [],
            essInstallationVolume: 'select',
            shippingState: 'select'
        },
        context: { primaryProducts }
    });
    const [loading, setLoading] = useState(false);
    const [isOpenPurchaseChannelMenu, setOpenPurchaseChannelMenu] = useState(false);
    const titleVal = watch('title');
    const notWebsite = watch('notWebsite');
    const hasShippingAddress = watch('hasShippingAddress');
    const agree = watch('agree');
    const agree1 = watch('agree1');
    const purchasingChannelSVal = watch('purchasingChannelS');
    const modulePrimaryProduct = watch('modulePrimaryProduct');
    const installationVolumeVal = watch('installationVolume'); 
    const essPrimaryProduct = watch('essPrimaryProduct');
    const essInstallationVolume = watch('essInstallationVolume');
    const installationVolumeValue = watch('installationVolumeValue');
    const essInstallationVolumeValue = watch('essInstallationVolumeValue');
    const notPrimaryProduct = watch('notPrimaryProduct');
    const notEssPrimaryProduct = watch('notEssPrimaryProduct');
    const password = watch('password');

    const getUserProfile = async () => {
        try {
            const [userProfileRes, jobTitlesRes, mainPurchaseChannelRes] = await Promise.all([
                axios.get(`${baseUrl}/users/profile`),
                axios.get(`${baseUrl}/companies/register/job-titles`),
                axios.get(`${baseUrl}/distributors`)
            ]);
            const userProfile = userProfileRes.data;
            const jobTitles = jobTitlesRes.data;
            const mainPurchaseChannel = mainPurchaseChannelRes.data;
            let approveStep = _.get(user, 'approvalStep');
            if (approveStep === 1) {
                if (_.get(userProfile, 'taxId')) {
                    approveStep = 2;
                }
            }
            const userType = {
                isNew: false,
                isStep1: false,
                isStep2: false
            }
            if (!!_.get(user, 'primaryProductType')) {
                userType.isNew = true;
            } else if (approveStep === 2) {
                userType.isStep2 = true;
            } else {
                userType.isStep1 = true;
            }
            setUserType(userType);
            const schema = getSchema(!!_.get(user, 'primaryProductType'), approveStep);
            setSchema(schema);
            setMainPurchasingChannel(mainPurchaseChannel);
            setUserProfile(userProfile);
            setJobTitles(jobTitles);
            setValue('company', convertHTMLEntity(_.get(userProfile, 'companyName')));
            if (_.get(userProfile, 'partnerType')) {
                setValue('partnerType', _.get(userProfile, 'partnerType'));
            }
            if (!!_.get(userProfile, 'website')) {
                setValue('website', _.get(userProfile, 'website'));
            } else {
                setValue('notWebsite', true);
            }
            setValue('firstName', _.get(userProfile, 'firstName'));
            setValue('lastName', _.get(userProfile, 'lastName'));
            if (userProfile.jobTitle) {
                let jobTitle = 'other';
                for (let i = 0; i < jobTitles.length; i++) {
                    if (jobTitles[i].name === userProfile.jobTitle) {
                        jobTitle = jobTitles[i].name;
                        break;
                    }
                }
                if (jobTitle === 'other') {
                    setValue('title', 'other');
                    setValue('otherTitle', userProfile.jobTitle);
                } else {
                    setValue('title', jobTitle);
                }
            }
            setValue('email', _.get(userProfile, 'email'));
            if (_.get(userProfile, 'officeNumber')) {
                setValue('mainContactNumber', _.get(userProfile, 'officeNumber'));
                setValue('selectedMainContactNumber', '1');
            } else {
                setValue('mainContactNumber', _.get(userProfile, 'mobileNumber'));
                setValue('selectedMainContactNumber', '2');
            }
            setValue('address', _.get(userProfile, 'address'));
            setValue('addressCont', _.get(userProfile, 'addressCont'));
            setValue('city', _.get(userProfile, 'city'));
            setValue('zipCode', _.get(userProfile, 'zipCode'));
            setValue('state', _.get(userProfile, 'state'));
            if (userProfile.shippingAddress) {
                setValue('hasShippingAddress', true);
                setValue('shippingAddress', _.get(userProfile, 'shippingAddress'));
                setValue('shippingAddress2', _.get(userProfile, 'shippingAddress2'));
                setValue('shippingCity', _.get(userProfile, 'shippingCity'));
                setValue('shippingState', _.get(userProfile, 'shippingState'));
                setValue('shippingZipCode', _.get(userProfile, 'shippingZipCode'));
            } else {
                setValue('shippingAddress', _.get(userProfile, 'address'));
                setValue('shippingAddress2', _.get(userProfile, 'addressCont'));
                setValue('shippingCity', _.get(userProfile, 'city'));
                setValue('shippingState', _.get(userProfile, 'state'));
                setValue('shippingZipCode', _.get(userProfile, 'zipCode'));
            }
            setValue('salesRegion', _.get(userProfile, 'salesArea') ? _.get(userProfile, 'salesArea').split('|') : []);
            if (userProfile.w9) {
                setW9Image(userProfile.w9.assetUrl.substr(userProfile.w9.assetUrl.lastIndexOf('/') + 1));
                setValue('isW9', true);
            }
            setValue('taxId', _.get(userProfile, 'taxId'));
            setValue('description', _.get(userProfile, 'description'));
            setValue('survey', `${_.get(userProfile, 'survey') !== null ? _.get(userProfile, 'survey') : '3'}`);
            let surveyTextKey = '';
            switch (userProfile.survey) {
                case 1:
                    surveyTextKey = 'surveyText1';
                    break;  
                case 2:
                    surveyTextKey = 'surveyText2';
                    break;
                case 3:
                    surveyTextKey = 'surveyText3';
                    break;  
                case 4:
                    surveyTextKey = 'surveyText4';
                    break;
                case 5:
                    surveyTextKey = 'surveyText5';
                    break;
                case 6:
                    surveyTextKey = 'surveyText6';
                    break;
                case 7:
                    surveyTextKey = 'surveyText7';
                    break;    
            }
            setValue(surveyTextKey, _.get(userProfile, 'surveyText'));
            setValue('agree', _.get(userProfile, 'receptionYn') === 'Y')
            const purchasingChannelText =  _.get(userProfile, 'purchaseChannel');
            if (purchasingChannelText) {
                const data = getPurchasingChannel(mainPurchaseChannel, purchasingChannelText);
                if (data.purchasingChannelS.name === 'Other') {
                    setValue('purchasingChannelS', data.purchasingChannelS);
                    setValue('purchasingChannelT', data.purchasingChannelT);
                } else {
                    setValue('purchasingChannelS', data.purchasingChannelS);
                }
            }
            
            if (userProfile.primaryProducts.length > 0) {
                const moduleProduct = userProfile.primaryProducts.filter(p => p.productType === 'module');
                const essProduct = userProfile.primaryProducts.filter(p => p.productType === 'ess');

                if (moduleProduct.length > 0) {
                    setValue('installationVolume', _.get(moduleProduct, '[0].installationVolumeId'));
                    setValue('installationVolumeValue', _.get(moduleProduct, '[0].installationVolumeData'));
                    if (_.get(moduleProduct, '[0].productBrand') === 'None') {
                        setValue('modulePrimaryProduct', [{ brand: [] }]);
                        setValue('notPrimaryProduct', true);
                    } else {
                        setValue('modulePrimaryProduct', moduleProduct.map(m => ({ brand: m.productBrand ? _.split(m.productBrand, '|') : [] })), { shouldDirty: true })
                    }
                } else {
                    setValue('modulePrimaryProduct', [{ brand: [] }]);
                }

                if (essProduct.length > 0) {
                    setValue('essInstallationVolume', _.get(essProduct, '[0].installationVolumeId'));
                    setValue('essInstallationVolumeValue', _.get(essProduct, '[0].installationVolumeData'));
                    if (_.get(essProduct, '[0].productBrand') === 'None') {
                        setValue('essPrimaryProduct', [{ brand: [] }]);
                        setValue('notEssPrimaryProduct', true);
                    } else {
                        setValue('essPrimaryProduct', essProduct.map(m => ({ brand: m.productBrand ? _.split(m.productBrand, '|') : [] })), { shouldDirty: true })
                    }
                } else {
                    setValue('essPrimaryProduct', [{ brand: [] }]);
                }
            } else {
                setValue('modulePrimaryProduct', [{ brand: [] }]);
                setValue('essPrimaryProduct', [{ brand: [] }]);
            }

            if (_.get(user, 'primaryProductType') === null) {
                handleSubmit(() => {})();
            }
        } catch (err) {
            notiFailed(`Something happened getting user profile`);
        }
    };

    const getPurchasingChannel = (mainPurchasingChannel, purchasingChannel) => {
        let purchasingChannelS = { name: 'Other' };
        let purchasingChannelT = purchasingChannel;
        
        for (let i = 0; i < mainPurchasingChannel.length; i++) {
            if (purchasingChannel === mainPurchasingChannel[i].name) {
                purchasingChannelS = mainPurchasingChannel[i];
                purchasingChannelT = null;
            }
        }

        return {
            purchasingChannelS,
            purchasingChannelT
        };
    };

    const showErrorMessage = (errors, errorKeys = []) => {
        let message = '';
    
        for (let i = 0; i < errorKeys.length; i++) {
            if (errors[errorKeys[i]]) {
                if (errorKeys[i] === 'modulePrimaryProduct') {
                    for (let i = 0; i < errors.modulePrimaryProduct.length; i++) {
                      if (errors.modulePrimaryProduct[i]) {
                        if (errors.modulePrimaryProduct[i].brand) {
                          return notiFailed(errors.modulePrimaryProduct[i].brand.message);
                        }
                      }
                    }
                  } else if (errorKeys[i] === 'essPrimaryProduct') {
                    for (let i = 0; i < errors.essPrimaryProduct.length; i++) {
                      if (errors.essPrimaryProduct[i]) {
                        if (errors.essPrimaryProduct[i].brand) {
                          return notiFailed(errors.essPrimaryProduct[i].brand.message);
                        }
                      }
                    }
                } else {
                    message = errors[errorKeys[i]].message;
                    break;
                }
            }
        }
    
        return notiFailed(message);
    };

    const onSubmitFailed = errors => {
        showErrorMessage(errors, [
            'company', 'partnerType', 'website', 'firstName', 'lastName', 'title', 'otherTitle', 'password', 'confirmPassword', 'mainContactNumber', 
            'address', 'addressCont', 'city', 'state', 'zipCode', 'shippingAddress', 'shippingAddress2', 'shippingCity', 
            'shippingState', 'shippingZipCode', 'salesRegion', 'purchasingChannelS', 'purchasingChannelT',
            'installationVolume', 'installationVolumeValue', 'modulePrimaryProduct', 'essInstallationVolume', 'essInstallationVolumeValue',
             'essPrimaryProduct', 'taxId', 'description', 'survey', 'surveyText1', 'surveyText2', 'surveyText3', 'surveyText4', 
            'surveyText5', 'surveyText6', 'surveyText7'
        ])
    };

    const onSubmit = async data => {
        let formData = new FormData();
        if (userType.isNew) {
            formData.append('companyName', userProfile.companyName);
            formData.append('partnerType', userProfile.partnerType);
            if (data.notWebsite) {
                formData.append('website', '');
            } else {
                formData.append('website', data.website);
            }
            formData.append('firstName', data.firstName);
            formData.append('lastName', data.lastName);
            formData.append('password', data.password);
            formData.append('taxId', userProfile.taxId);

            formData.append('address', data.address);
            if (data.addressCont) {
                formData.append('addressCont', data.addressCont);
            } else {
                formData.append('addressCont', '');
            }
            formData.append('city', data.city);
            formData.append('state', data.state);
            formData.append('zipCode', data.zipCode);

            if (data.hasShippingAddress) {
                formData.append('shippingAddress', data.shippingAddress);
                if (data.shippingAddress2) {
                    formData.append('shippingAddress2', data.shippingAddress2);
                } else {
                    formData.append('shippingAddress2', '');
                }
                formData.append('shippingCity', data.shippingCity);
                formData.append('shippingState', data.shippingState);
                formData.append('shippingZipCode', data.shippingZipCode);
            } else {
                formData.append('shippingAddress', data.address);
                if (data.addressCont) {
                    formData.append('shippingAddress2', data.addressCont);
                } else {
                    formData.append('shippingAddress2', '');
                }
                formData.append('shippingCity', data.city);
                formData.append('shippingState', data.state);
                formData.append('shippingZipCode', data.zipCode);
            }

            formData.append('salesRegion', _.join(data.salesRegion, '|'));

            if (data.description) {
                formData.append('description', data.description);
            } else {
                formData.append('description', '');
            }

            formData.append('agree1Yn', data.agree1 ? 'Y' : 'N');
            formData.append('receptionYn', data.agree ? 'Y' : 'N');
        } else if (userType.isStep2) {
            formData.append('companyName', userProfile.companyName);
            formData.append('partnerType', data.partnerType);
            formData.append('firstName', userProfile.firstName);
            formData.append('lastName', userProfile.lastName);
            formData.append('taxId', userProfile.taxId);

            if (userProfile.website) {
                formData.append('website', userProfile.website);
            }
            formData.append('address', userProfile.address);
            if (userProfile.addressCont) {
                formData.append('addressCont', userProfile.addressCont);
            } else {
                formData.append('addressCont', '');
            }
            formData.append('city', userProfile.city);
            formData.append('state', userProfile.state);
            formData.append('zipCode', userProfile.zipCode);

            if (userProfile.shippingAddress) {
                formData.append('shippingAddress', userProfile.shippingAddress);
                if (userProfile.shippingAddress2) {
                    formData.append('shippingAddress2', userProfile.shippingAddress2);
                } else {
                    formData.append('shippingAddress2', '');
                }
                formData.append('shippingCity', userProfile.shippingCity);
                formData.append('shippingState', userProfile.shippingState);
                formData.append('shippingZipCode', userProfile.shippingZipCode);
            }
            
            formData.append('salesRegion', userProfile.salesArea);

            if (userProfile.description) {
                formData.append('description', userProfile.description);
            } else {
                formData.append('description', '');
            }

            formData.append('receptionYn', userProfile.receptionYn);
        } else {
            formData.append('firstName', userProfile.firstName);
            formData.append('lastName', userProfile.lastName);
            formData.append('companyName', data.company);
            formData.append('partnerType', data.partnerType);
            formData.append('salesRegion', _.join(data.salesRegion, '|'));
            formData.append('taxId', data.taxId);
            if (data.w9.length > 0) {
                formData.append('w9', data.w9[0]);
            }
            if (data.description) {
                formData.append('description', data.description);
            } else {
                formData.append('description', '');
            }

            if (userProfile.website) {
                formData.append('website', userProfile.website);
            }
            formData.append('address', userProfile.address);
            if (userProfile.addressCont) {
                formData.append('addressCont', userProfile.addressCont);
            } else {
                formData.append('addressCont', '');
            }
            formData.append('city', userProfile.city);
            formData.append('state', userProfile.state);
            formData.append('zipCode', userProfile.zipCode);

            if (userProfile.shippingAddress) {
                formData.append('shippingAddress', userProfile.shippingAddress);
                if (userProfile.shippingAddress2) {
                    formData.append('shippingAddress2', userProfile.shippingAddress2);
                } else {
                    formData.append('shippingAddress2', '');
                }
                formData.append('shippingCity', userProfile.shippingCity);
                formData.append('shippingState', userProfile.shippingState);
                formData.append('shippingZipCode', userProfile.shippingZipCode);
            }

            formData.append('receptionYn', userProfile.receptionYn);
        }
        
        if (data.title !== 'other') {
            formData.append('jobTitle', data.title);
        } else {
            formData.append('jobTitle', data.otherTitle);
        }
        
        if (data.selectedMainContactNumber === "1") {
            formData.append('officeNumber', data.mainContactNumber);
            formData.append('mobileNumber', '');
        } else {
            formData.append('mobileNumber', data.mainContactNumber);
            formData.append('officeNumber', '');
        }
        
        if (_.get(data, 'purchasingChannelS.name') === 'Other') {
            formData.append('purchaseChannel', data.purchasingChannelT);
        } else {
            formData.append('purchaseChannel', data.purchasingChannelS.name);
        }
        
        const primaryProducts = [];
        for (let mp of modulePrimaryProduct) {
            let newModule = {
                productType: 'module',
                productBrand: _.join(mp.brand, '|'),
                installationVolumeId: +data.installationVolume
            };

            if (data.notPrimaryProduct) {
                newModule.productBrand = 'None';
            }

            if (MODULE_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+data.installationVolume)) {
                newModule.installationVolumeData = data.installationVolumeValue;
            }

            primaryProducts.push(newModule);
        }
        for (let mp of essPrimaryProduct) {
            const newEss = {
                productType: 'ess',
                productBrand: _.join(mp.brand, '|'),
                installationVolumeId: +data.essInstallationVolume
            };

            if (data.notEssPrimaryProduct) {
                newEss.productBrand = 'None';
            }

            if (ESS_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+data.essInstallationVolume)) {
                newEss.installationVolumeData = data.essInstallationVolumeValue;
            }

            primaryProducts.push(newEss);
        }
        formData.append('primaryProducts', JSON.stringify(primaryProducts));

        // formData.append('survey', data.survey);
        // let surveyText = '';
        // switch (data.survey) {
        //     case '1':
        //         surveyText = data.surveyText1;
        //         break;  
        //     case '2':
        //         surveyText = data.surveyText2;
        //         break;
        //     case '3':
        //         surveyText = data.surveyText3;
        //         break;  
        //     case '4':
        //         surveyText = data.surveyText4;
        //         break;
        //     case '5':
        //         surveyText = data.surveyText5;
        //         break;
        //     case '6':
        //         surveyText = data.surveyText6;
        //         break;
        //     case '7':
        //         surveyText = data.surveyText7;
        //         break;   
        // }
        // formData.append('surveyText', surveyText);

        try {
            setLoading(true);
            await axios.post(`${baseUrl}/users/profile`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            sendEventForProfile(ACTION_EDIT_PROFILE);
            if (_.get(user, 'primaryProductType')) {
                getUserProfile();
                notiSuccess('Your profile was successfully updated');
            } else {
                if (_.get(user, 'approvalStep') === 2) {
                    try {
                        const { data } = await axios.get(`${baseUrl}/users/me/info`);
                        const newData = {
                            ...user.auth
                        };
                        _.set(newData, 'user.primaryProductType', data.primaryProductType);
                        localStorage.setItem('qpartner_user', JSON.stringify(newData));
                        user.setAuth(newData);
                        notiSuccess('Your profile was successfully updated');
                        history.push('/mypage/member');
                    } catch (err) {
                        notiFailed(`Something happened edit profile`);
                    }
                } else {
                    notiSuccess('Your profile was updated successfully. Please allow 1-3 business days for the administrator to approve your registration. Once approved, a confirmation e-mail will be sent');
                    try {
                        await apiClient.logout();
                    } catch (err) {}
                    localStorage.removeItem('qpartner_user');
                    window.location = '/sso/login';
                }
            }
        } catch (err) {
            notiFailed(`Something happened edit profile`);
        } finally {
            setLoading(false);
        }
    };

    const getJobTitles = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/companies/register/job-titles`);
            setJobTitles(data);
        } catch (err) {
            notiFailed(`Something happened getting job titles`);
        }
    };

    const getSaleRegions = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/companies/salesregions/search`);
            setSaleRegions(data);
        } catch (err) {
            notiFailed(`Something happened getting sale regions`);
        }
    };

    const getPrimaryProduct = async () => {
        try {
          const data = await Promise.all([
            axios.get(`${baseUrl}/companies/register/installation-volumes`, { params: { productType: 1 } }),
            axios.get(`${baseUrl}/companies/register/product-brands`, { params: { productType: 1 } }),
            axios.get(`${baseUrl}/companies/register/installation-volumes`, { params: { productType: 2 } }),
            axios.get(`${baseUrl}/companies/register/product-brands`, { params: { productType: 2 } }),
          ]);
          setPrimaryProducts({
            installationVolumes: data[0].data,
            productBrands: data[1].data.map(b => b.name),
            essInstallationVolumes: data[2].data,
            essProductBrands: data[3].data.map(b => b.name)
          });
        } catch (err) {
          notiFailed(`Something happened getting primary product`);
        }
    };

    const renderUnitInstallationVolumn = () => {
       const installationVolume = primaryProducts.installationVolumes.find(v => v.id === +getValues('installationVolume'));
       return installationVolume ? installationVolume.unit : '';                                                      
    };

    const isShowPrompt = () => {
        return _.get(user, 'primaryProductType') === null;
    };

    useEffect(() => {
        getUserProfile();
        getSaleRegions();
        getJobTitles();
        getPrimaryProduct();
    }, []);

    useEffect(() => {
        if (notPrimaryProduct) {
          setValue('modulePrimaryProduct', [{ brand: 'select' }]);
        }
    }, [notPrimaryProduct]);
    
    useEffect(() => {
        if (notEssPrimaryProduct) {
            setValue('essPrimaryProduct', [{ brand: 'select' }]);
        }
    }, [notEssPrimaryProduct]);

    return (
        <>
            <RouteLeavingGuard
                when={
                    isShowPrompt()
                }
                shouldBlockNavigation={location => {
                    if (isShowPrompt()) {
                        if (location.pathname !== '/mypage/member/edit-profile' && !_.get(location, 'state.disablePrompt')) {
                        return true
                        }
                    } 
                    return false
                }}
            />
            <Banner
                imageUrl={MyPageBanner}
                className="OrderHistory_Banner banner100"
            >
                <h1 className="title_animation OrderHistory_Title">My Account</h1>
                <BreadCrumb2 items={[`${user.firstName} ${user.lastName}`, 'My Account']}></BreadCrumb2>
            </Banner>

            <div className={classes.pageContainer}>
                <Text className={classes.pageTitle}>
                    Profile
                </Text>

                <Box
                    display="flex"
                    justifyContent="flex-end"
                >
                    <p className={classes.infoEs}><span>Required</span></p>
                </Box>

                <Table className={classes.table}>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ width: '20%' }}>
                                <span className={classes.icoEs}>Company</span>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            inputRef={register}
                                            name="company"
                                            readOnly={true}
                                            disabled={!userType.isStep1}
                                            className={classNames([classes.inputField], {
                                                [classes.inputFieldDisable]: !userType.isStep1
                                            })}                            
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: '20%' }}>
                                <span className={classes.icoEs}>Main Business Type</span>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <Controller
                                            name="partnerType"
                                            rules={{
                                                required: true
                                            }}
                                            control={control}
                                            as={
                                                <select
                                                    placeholder="Please select"
                                                    defaultValue={-1}
                                                    className={classNames([classes.customSelectField], {
                                                        [classes.errorCustomSelect]: !!errors.partnerType,
                                                        [classes.customSelectFieldDisable]: userType.isNew
                                                    })}
                                                    disabled={userType.isNew}
                                                >
                                                    <option value={-1}>Please select</option>
                                                    <option value={1}>Contractor/EPC</option>
                                                    <option value={2}>Sales Agent</option>
                                                    <option value={3}>Distributor</option>
                                                </select>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Home page URL</span>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            name="website"
                                            inputRef={register}
                                            disabled={notWebsite || !userType.isNew}
                                            className={classNames([classes.inputField], {
                                                [classes.errorInput]: !!errors.website && !notWebsite,
                                                [classes.inputFieldDisable]: notWebsite || !userType.isNew
                                            })}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} style={{ display: 'flex' }}>
                                        <FormControlLabel 
                                            control={
                                                <Checkbox 
                                                    name="notWebsite" 
                                                    size="medium" 
                                                    disabled={!userType.isNew}
                                                    icon={
                                                        <div 
                                                            className={classNames('', {
                                                            [classes.checkBoxOn]: notWebsite,
                                                            [classes.checkBox]: !notWebsite
                                                            })}></div>}
                                                    checkedIcon={
                                                        <div className={classNames('', {
                                                            [classes.checkBoxOn]: notWebsite,
                                                            [classes.checkBox]: !notWebsite
                                                        })}></div>}
                                                    inputRef={register}
                                                />
                                            } 
                                            label={
                                                <p className={classes.websiteCheckboxText}>
                                                    I don't have a website.
                                                </p>
                                            } 
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Name</span>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            name="firstName"
                                            inputRef={register}
                                            className={classNames([classes.inputField], {
                                                [classes.errorInput]: !!errors.firstName,
                                                [classes.inputFieldDisable]: !userType.isNew
                                            })}
                                            disabled={!userType.isNew}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            name="lastName"
                                            inputRef={register}
                                            className={classNames([classes.inputField], {
                                                [classes.errorInput]: !!errors.lastName,
                                                [classes.inputFieldDisable]: !userType.isNew
                                            })}
                                            disabled={!userType.isNew}
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Title</span>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <Controller
                                            name="title"
                                            rules={{
                                                required: true
                                            }}
                                            control={control}
                                            as={
                                                <select
                                                    placeholder="Please select"
                                                    defaultValue={'select'}
                                                    className={classNames([classes.customSelectField], {
                                                        [classes.errorCustomSelect]: !!errors.title
                                                    })}
                                                >
                                                    <option value={'select'}>Please select</option>
                                                    {jobTitles.map(jt => (
                                                        <option className={classes.menuItem} key={jt.id} value={jt.name}>{jt.name}</option>
                                                    ))}
                                                    <option value={'other'}>Other</option>
                                                </select>
                                            }
                                        />
                                    </Grid>
                                    {titleVal === 'other' && (
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                name="otherTitle"
                                                inputRef={register}
                                                className={classNames([classes.inputField], {
                                                    [classes.errorInput]: !!errors.otherTitle
                                                })} 
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>ID (E-mail)</span>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                            inputRef={register}
                                            name="email"
                                            className={classNames([classes.inputField], [classes.inputFieldDisable])}
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Password</span>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            name="password"
                                            type="password"
                                            inputRef={register}
                                            className={classNames([classes.inputField], {
                                                [classes.errorInput]: !!errors.password,
                                                [classes.inputFieldDisable]: !userType.isNew
                                            })}
                                            onChange={() => {
                                                trigger('password');
                                            }}
                                            autoComplete="new-password"
                                            disabled={!userType.isNew}
                                        />
                                        {(!password || (errors.password && errors.password.type === 'required')) && (
                                            <Text size="small" style={{ marginTop: '7px' }}>
                                                At least 8 characters in combination of letters, numbers, and symbols (0-9, A-Z, at least one symbol)
                                            </Text>
                                        )}
                                        {errors.password && errors.password.type === 'is-valid' &&
                                            <Text size="small" style={{ marginTop: '7px' }}>
                                                {errors.password.message}
                                            </Text>
                                            }
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Confirm Password</span>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            name="confirmPassword"
                                            type="password"
                                            inputRef={register}
                                            className={classNames([classes.inputField], {
                                                [classes.errorInput]: !!errors.confirmPassword,
                                                [classes.inputFieldDisable]: !userType.isNew
                                            })}
                                            onChange={() => {
                                                trigger('confirmPassword');
                                            }}
                                            disabled={!userType.isNew}
                                        />
                                        {errors.confirmPassword && 
                                            <Text size="small" style={{ marginTop: '7px' }}>
                                                {errors.confirmPassword.message}
                                            </Text>}
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Main Contact Number</span>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <Controller
                                            name="mainContactNumber"
                                            rules={{
                                                required: true
                                            }}
                                            control={control}
                                            as={
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="Numbers only (e.g., 9497774444)"
                                                    className={classNames([classes.inputField], {
                                                        [classes.errorInput]: !!errors.mainContactNumber
                                                    })}
                                                    InputProps={{
                                                        inputComponent: NumberFormat
                                                    }}                    
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} style={{ display: 'flex' }}>
                                        <Controller
                                            name="selectedMainContactNumber"
                                            rules={{
                                                required: true
                                            }}
                                            control={control}
                                            as={
                                                <RadioGroup row aria-label="position" className={classes.selectedMainContactNumber}>
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />}
                                                        label="Office Number"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        control={<Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />}
                                                        label="Mobile Number"
                                                        labelPlacement="end"
                                                    />
                                                </RadioGroup>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Business Address</span>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Street address"
                                            name="address"
                                            inputRef={register}
                                            className={classNames([classes.inputField], {
                                                [classes.errorInput]: !!errors.address,
                                                [classes.inputFieldDisable]: !userType.isNew
                                            })}
                                            disabled={!userType.isNew}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Ste, unit, building, floor, etc"
                                            name="addressCont"
                                            inputRef={register}
                                            className={classNames([classes.inputField], {
                                                [classes.errorInput]: !!errors.addressCont,
                                                [classes.inputFieldDisable]: !userType.isNew
                                            })}
                                            disabled={!userType.isNew}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item md={4} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="City"
                                            name="city"
                                            inputRef={register}
                                            className={classNames([classes.inputField], {
                                                [classes.errorInput]: !!errors.city,
                                                [classes.inputFieldDisable]: !userType.isNew
                                            })}
                                            disabled={!userType.isNew}
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <Controller
                                            name="state"
                                            rules={{
                                                required: true
                                            }}
                                            control={control}
                                            as={
                                                <select
                                                    placeholder="State"
                                                    defaultValue={'select'}
                                                    className={classNames([classes.customSelectField], {
                                                        [classes.errorCustomSelect]: !!errors.state,
                                                        [classes.customSelectFieldDisable]: !userType.isNew
                                                    })}
                                                    disabled={!userType.isNew}
                                                >
                                                    <option value={'select'}>State</option>
                                                    {saleRegions.map(pc => {
                                                        const [id, value] = pc.split(':');
                                                        return (
                                                            <option className={classes.menuItem} key={id} value={value}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            }
                                        /> 
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Zip Code"
                                            name="zipCode"
                                            inputRef={register}
                                            className={classNames([classes.inputField], {
                                                [classes.errorInput]: !!errors.zipCode,
                                                [classes.inputFieldDisable]: !userType.isNew
                                            })}
                                            disabled={!userType.isNew}
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span>Shipping Address</span>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} className={classes.addressSection}>
                                        <FormControlLabel
                                            style={{ marginLeft: '-9px' }}
                                            control={
                                                <Checkbox 
                                                    size="medium"
                                                    name="hasShippingAddress"
                                                    inputRef={register}
                                                    icon={
                                                    <div 
                                                        className={classNames('', {
                                                        [classes.checkBoxOn]: hasShippingAddress,
                                                        [classes.checkBox]: !hasShippingAddress
                                                        })}></div>}
                                                    checkedIcon={
                                                    <div className={classNames('', {
                                                        [classes.checkBoxOn]: hasShippingAddress,
                                                        [classes.checkBox]: !hasShippingAddress
                                                    })}></div>}
                                                    disabled={!userType.isNew}
                                                />
                                            } 
                                            label={
                                                <p>
                                                    Different from Business Address?
                                                </p>
                                            } 
                                        />
                                        <p>*Indicate for receiving mail and packages if different from business address.</p>
                                    </Grid>
                                    {hasShippingAddress &&
                                    <>
                                        <Grid container spacing={2}>
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="Street address"
                                                    name="shippingAddress"
                                                    inputRef={register}
                                                    className={classNames([classes.inputField], {
                                                        [classes.errorInput]: !!errors.shippingAddress,
                                                        [classes.inputFieldDisable]: !userType.isNew
                                                    })}
                                                    disabled={!userType.isNew}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="Ste, unit, building, floor, etc"
                                                    name="shippingAddress2"
                                                    inputRef={register}
                                                    className={classNames([classes.inputField], {
                                                        [classes.errorInput]: !!errors.shippingAddress2,
                                                        [classes.inputFieldDisable]: !userType.isNew
                                                    })}
                                                    disabled={!userType.isNew}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} style={{ marginTop: '0px' }}>
                                            <Grid item md={4} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="City"
                                                    name="shippingCity"
                                                    inputRef={register}
                                                    className={classNames([classes.inputField], {
                                                        [classes.errorInput]: !!errors.shippingCity,
                                                        [classes.inputFieldDisable]: !userType.isNew
                                                    })}
                                                    disabled={!userType.isNew}
                                                />
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                                <Controller
                                                    name="shippingState"
                                                    rules={{
                                                        required: true
                                                    }}
                                                    control={control}
                                                    as={
                                                        <select
                                                            placeholder="State"
                                                            defaultValue={'select'}
                                                            className={classNames([classes.customSelectField], {
                                                                [classes.errorCustomSelect]: !!errors.shippingState,
                                                                [classes.customSelectFieldDisable]: !userType.isNew
                                                            })}
                                                            disabled={!userType.isNew}
                                                        >
                                                            <option value={'select'}>State</option>
                                                            {saleRegions.map(pc => {
                                                                const [id, value] = pc.split(':');
                                                                return (
                                                                    <option className={classes.menuItem} key={id} value={value}>{value}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="Zip Code"
                                                    name="shippingZipCode"
                                                    inputRef={register}
                                                    className={classNames([classes.inputField], {
                                                        [classes.errorInput]: !!errors.shippingZipCode,
                                                        [classes.inputFieldDisable]: !userType.isNew
                                                    })}
                                                    disabled={!userType.isNew}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>}
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Sales Region(s)</span>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <Controller
                                            name="salesRegion"
                                            rules={{
                                                required: true
                                            }}
                                            control={control}
                                            as={
                                                <ReactSelect
                                                    isMulti
                                                    options={saleRegions}
                                                    className={classNames([classes.multiSelect], {
                                                        [classes.errorReactSelect]: !!errors.salesRegion
                                                    })}
                                                    classNamePrefix="select"
                                                    getOptionLabel={option => option.split(':')[1]}
                                                    getOptionValue={option => option}
                                                    components={{
                                                        IndicatorSeparator: null
                                                    }}
                                                    isDisabled={userType.isStep2}
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Main Purchasing Channel <br />(Name of Distributors)</span>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <Controller
                                            name="purchasingChannelS"
                                            rules={{
                                                required: true
                                            }}
                                            control={control}
                                            as={
                                                <ReactSelect 
                                                    getOptionLabel={option => _.trim(option.name)}
                                                    getOptionValue={option => _.trim(option.name)}
                                                    isSearchable
                                                    isClearable
                                                    isMulti={false}
                                                    options={[...mainPurchasingChannel, { name: 'Other' }]}
                                                    className={classNames([classes.multiSelect], {
                                                        [classes.errorReactSelect]: !!errors.purchasingChannelS
                                                    })}
                                                    classNamePrefix="select"
                                                    placeholder="Type in the distributor name"
                                                    components={{
                                                        IndicatorSeparator: null
                                                    }}
                                                    defaultMenuIsOpen={false}
                                                    menuIsOpen={isOpenPurchaseChannelMenu}
                                                    onInputChange={(_, { action }) => {
                                                        if (action === 'input-change') {
                                                            setOpenPurchaseChannelMenu(true);
                                                        } else if (action === 'menu-close') {
                                                            setOpenPurchaseChannelMenu(false);
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                        <p className={classes.helperText}>*If there is no distributor on the list, please type Other.</p>
                                    </Grid>
                                    {_.get(purchasingChannelSVal, 'name') === 'Other' &&
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                name="purchasingChannelT"
                                                inputRef={register}
                                                className={classNames([classes.inputField], {
                                                    [classes.errorInput]: !!errors.purchasingChannelT
                                                })}
                                            />
                                        </Grid>}
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Module Installation Information</span>
                            </TableCell>
                            <TableCell>
                                <div className={classes.moduleInstallationContentContainer}>
                                    <div>
                                        <span className={classes.icoEsNotRequired}>Installation volume (kW/<span className={classes.annualText}>Annual</span>)</span>
                                    </div>
                                    <div>
                                        <div className={classes.moduleInstallationFieldContainer}>
                                        <div>
                                            <Controller
                                                name="installationVolume"
                                                rules={{
                                                    required: true
                                                }}
                                                control={control}
                                                render={(
                                                    { onChange, value }
                                                ) => (
                                                    <select
                                                        placeholder="Please select"
                                                        defaultValue={'select'}
                                                        className={classNames([classes.customSelectField], {
                                                            [classes.errorCustomSelect]: !!errors.installationVolume
                                                        })}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            if (installationVolumeValue) {
                                                              trigger('installationVolumeValue');
                                                            }
                                                        }}
                                                        value={value}                      
                                                    >
                                                        <option value={'select'}>Select</option>
                                                        {primaryProducts.installationVolumes.map(m => (
                                                            <option key={m.id} value={m.id}>{m.name}</option>
                                                        ))}
                                                    </select>
                                                )}
                                            />
                                        </div>
                                        {MODULE_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+installationVolumeVal) && (
                                            <div>
                                                <Controller
                                                    name="installationVolumeValue"
                                                    rules={{
                                                        required: true
                                                    }}
                                                    control={control}
                                                    render={(
                                                        { onChange, value }
                                                    ) => (
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            className={classNames([classes.inputField], {
                                                                [classes.errorInput]: !!errors.installationVolumeValue
                                                            })}
                                                            InputProps={{
                                                                endAdornment: 
                                                                <InputAdornment position="end">
                                                                    {renderUnitInstallationVolumn()}
                                                                </InputAdornment>,
                                                                inputComponent: NumberFormat
                                                            }}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                trigger('installationVolumeValue');
                                                            }}
                                                            value={value}
                                                        />
                                                    )}
                                                />
                                                {errors.installationVolumeValue && 
                                                    <Text size="small" style={{ marginTop: '7px' }}>
                                                    {errors.installationVolumeValue.message}
                                                    </Text>}
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.moduleInstallationContentContainer}>
                                    <div>
                                        <span className={classes.icoEsNotRequired}>Primary products in use</span>
                                    </div>
                                    <div>
                                        {modulePrimaryProduct.map((m, idx) => (
                                            <div className={classes.moduleInstallationFieldContainer} key={m.id}>
                                                <div>
                                                    <Controller
                                                        name={`modulePrimaryProduct[${idx}].brand`}
                                                        rules={{
                                                            required: true
                                                        }}
                                                        control={control}
                                                        as={
                                                            <ReactSelect 
                                                                getOptionLabel={option => option}
                                                                getOptionValue={option => option}
                                                                isSearchable
                                                                isClearable
                                                                isMulti
                                                                options={[...primaryProducts.productBrands]}
                                                                className={classNames([classes.multiSelect], {
                                                                    [classes.errorReactSelect]: !!_.get(errors, `modulePrimaryProduct[${idx}].brand`) && !notPrimaryProduct
                                                                })}
                                                                classNamePrefix="select"
                                                                placeholder="Please select"
                                                                components={{
                                                                    IndicatorSeparator: null
                                                                }}
                                                                isDisabled={notPrimaryProduct}
                                                            />
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <FormControlLabel 
                                                        control={
                                                            <Checkbox 
                                                                name="notPrimaryProduct" 
                                                                size="medium" 
                                                                icon={
                                                                    <div
                                                                        style={{ marginLeft: '5px' }}
                                                                        className={classNames('', {
                                                                        [classes.checkBoxOn]: notPrimaryProduct,
                                                                        [classes.checkBox]: !notPrimaryProduct
                                                                        })}></div>}
                                                                checkedIcon={
                                                                    <div 
                                                                        style={{ marginLeft: '5px' }}
                                                                        className={classNames('', {
                                                                        [classes.checkBoxOn]: notPrimaryProduct,
                                                                        [classes.checkBox]: !notPrimaryProduct
                                                                    })}></div>}
                                                                inputRef={register}
                                                            />
                                                        }
                                                        label={
                                                            <p className={classes.websiteCheckboxText}>
                                                                None
                                                            </p>
                                                        } 
                                                    />
                                                </div>
                                            </div>
                                        ))}       
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Battery Installation Information</span>
                            </TableCell>
                            <TableCell>
                                <div className={classes.moduleInstallationContentContainer}>
                                    <div>
                                        <span className={classes.icoEsNotRequired}>Installation volume (set/<span className={classes.annualText}>Annual</span>)</span>
                                    </div>
                                    <div>
                                        <div className={classes.moduleInstallationFieldContainer}>
                                            <div>
                                                <Controller
                                                    name="essInstallationVolume"
                                                    rules={{
                                                        required: true
                                                    }}
                                                    control={control}
                                                    render={(
                                                        { onChange, value }
                                                    ) => (
                                                        <select
                                                            placeholder="Please select"
                                                            defaultValue={'select'}
                                                            className={classNames([classes.customSelectField], {
                                                                [classes.errorCustomSelect]: !!errors.essInstallationVolume
                                                            })}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                if (essInstallationVolumeValue) {
                                                                  trigger('essInstallationVolumeValue');
                                                                }
                                                            }}
                                                            value={value}                          
                                                        >
                                                            <option value={'select'}>Select</option>
                                                            {primaryProducts.essInstallationVolumes.map(m => (
                                                                <option key={m.id} value={m.id}>{m.name}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                            </div>
                                            {ESS_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+essInstallationVolume) && (
                                                <div>
                                                    <Controller
                                                        name="essInstallationVolumeValue"
                                                        rules={{
                                                            required: true
                                                        }}
                                                        control={control}
                                                        render={(
                                                            { onChange, value }
                                                        ) => (
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                className={classNames([classes.inputField], {
                                                                    [classes.errorInput]: !!errors.essInstallationVolumeValue
                                                                })}
                                                                InputProps={{
                                                                    endAdornment: 
                                                                    <InputAdornment position="end">
                                                                        SETS
                                                                    </InputAdornment>,
                                                                    inputComponent: NumberFormat
                                                                }}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                    trigger('essInstallationVolumeValue');
                                                                }}
                                                                value={value}
                                                            />
                                                        )}
                                                    />
                                                    {errors.essInstallationVolumeValue && 
                                                        <Text size="small" style={{ marginTop: '7px' }}>
                                                            {errors.essInstallationVolumeValue.message}
                                                        </Text>}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.moduleInstallationContentContainer}>
                                    <div>
                                        <span className={classes.icoEsNotRequired}>Primary products in use</span>
                                    </div>
                                    <div>
                                        {essPrimaryProduct.map((m, idx) => (
                                            <div className={classes.essInstallationFieldContainer} key={m.id}>
                                                <div>
                                                    <Controller
                                                        name={`essPrimaryProduct[${idx}].brand`}
                                                        rules={{
                                                            required: true
                                                        }}
                                                        control={control}
                                                        as={
                                                            <ReactSelect 
                                                                getOptionLabel={option => option}
                                                                getOptionValue={option => option}
                                                                isSearchable
                                                                isClearable
                                                                isMulti
                                                                options={[...primaryProducts.essProductBrands]}
                                                                className={classNames([classes.multiSelect], {
                                                                    [classes.errorReactSelect]: !!_.get(errors, `essPrimaryProduct[${idx}].brand`) && !notEssPrimaryProduct
                                                                })}
                                                                classNamePrefix="select"
                                                                placeholder="Please select"
                                                                components={{
                                                                    IndicatorSeparator: null
                                                                }}
                                                                isDisabled={notEssPrimaryProduct}
                                                            />
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <FormControlLabel 
                                                        control={
                                                            <Checkbox 
                                                                name="notEssPrimaryProduct" 
                                                                size="medium" 
                                                                icon={
                                                                    <div
                                                                        style={{ marginLeft: '5px' }}
                                                                        className={classNames('', {
                                                                        [classes.checkBoxOn]: notEssPrimaryProduct,
                                                                        [classes.checkBox]: !notEssPrimaryProduct
                                                                        })}></div>}
                                                                checkedIcon={
                                                                    <div 
                                                                        style={{ marginLeft: '5px' }}
                                                                        className={classNames('', {
                                                                        [classes.checkBoxOn]: notEssPrimaryProduct,
                                                                        [classes.checkBox]: !notEssPrimaryProduct
                                                                    })}></div>}
                                                                inputRef={register}
                                                            />
                                                        }
                                                        label={
                                                            <p className={classes.websiteCheckboxText}>
                                                                None
                                                            </p>
                                                        } 
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Tax ID</span>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <Controller
                                            name="taxId"
                                            rules={{
                                                required: true
                                            }}
                                            control={control}
                                            render={(
                                                { onChange, value }
                                              ) => (
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    className={classNames([classes.inputField], {
                                                        [classes.errorInput]: !!errors.taxId,
                                                        [classes.inputFieldDisable]: !userType.isStep1
                                                    })}
                                                    onChange={e => {
                                                        if (e.target.value === '') {
                                                            return onChange(e.target.value);
                                                        }

                                                        if (/^([0-9]*-?[0-9]{1,}|[0-9]{1,}|[0-9]{1,}-?[0-9]*)$/.test(e.target.value)) {
                                                            onChange(e.target.value);
                                                        }
                                                    }}
                                                    disabled={!userType.isStep1}
                                                    value={value}                                      
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>W-9</span>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                            value={w9Image}
                                            className={classNames([classes.inputField], {
                                                [classes.errorInput]: !!errors.w9
                                            })}
                                        />
                                        <input
                                            ref={register}
                                            name={"w9"}
                                            accept=".bmp,.jpg,.png,.gif,.zip,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.hwp,.gul,.pdf,.tif,.tiff,.txt,.eps"
                                            className={classes.w9ImageInput}
                                            id="raised-button-file"
                                            type="file"
                                            onChange={e => setW9Image(e.target.value)}
                                        />
                                        <input 
                                            ref={register}
                                            type="checkbox"
                                            name="isW9"
                                            style={{ display: 'none' }}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        {userType.isStep1 ? (
                                            <label htmlFor="raised-button-file">
                                                <Button variant="raised" component="span" className={classes.checkSearchImageBtn}>Search</Button>
                                            </label>
                                        ) : (
                                            <Button variant="raised" component="span" className={classes.checkSearchImageBtn} disabled={!userType.isStep1}>Search</Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span>Note/Overview of Company</span>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <Controller
                                            name="description"
                                            rules={{
                                                required: true
                                            }}
                                            control={control}
                                            as={
                                                <TextareaAutosize 
                                                    rowsMin={5}
                                                    className={classNames([classes.overviewTextarea], {
                                                        [classes.errorTextarea]: !!errors.description
                                                    })}
                                                    disabled={userType.isStep2}
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        {_.get(user, 'approvalStep') !== 2 && 
                        <TableRow>
                            <TableCell colSpan={2}>
                                <span className={classes.icoEs}>How did you hear about the Q.PARTNER Program?</span>
                                <Controller
                                    name="survey"
                                    rules={{
                                        required: true
                                    }}
                                    control={control}
                                    as={
                                        <RadioGroup>
                                            <Grid container className={classes.surveySection} style={{ marginTop: '18px' }}>
                                                <Grid item md={3} xs={12}>
                                                    <FormControlLabel 
                                                        value="7"
                                                        control={
                                                            <Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />
                                                        } 
                                                        label="Through event"
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Name of event"
                                                        name="surveyText7"
                                                        inputRef={register}
                                                        className={classNames([classes.inputField], {
                                                            [classes.errorInput]: !!errors.surveyText7,
                                                            [classes.inputFieldDisable]: true
                                                        })}
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.surveySection}>
                                                <Grid item md={3} xs={12}>
                                                    <FormControlLabel 
                                                        value="2"
                                                        control={
                                                            <Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />
                                                        } 
                                                        label="Through web ads"
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Name of media"
                                                        name="surveyText2"
                                                        inputRef={register}
                                                        className={classNames([classes.inputField], {
                                                            [classes.errorInput]: !!errors.surveyText2,
                                                            [classes.inputFieldDisable]: true
                                                        })}
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.surveySection}>
                                                <Grid item md={3} xs={12}>
                                                    <FormControlLabel 
                                                        value="3"
                                                        control={
                                                            <Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />
                                                        } 
                                                        label="Through social media"
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Name of event"
                                                        name="surveyText3"
                                                        inputRef={register}
                                                        className={classNames([classes.inputField], {
                                                            [classes.errorInput]: !!errors.surveyText3,
                                                            [classes.inputFieldDisable]: true
                                                        })}
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.surveySection}>
                                                <Grid item md={3} xs={12}>
                                                    <FormControlLabel 
                                                        value="4" 
                                                        control={
                                                            <Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />
                                                        } 
                                                        label="Through other installers" 
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Name of installers"
                                                        name="surveyText4"
                                                        inputRef={register}
                                                        className={classNames([classes.inputField], {
                                                            [classes.errorInput]: !!errors.surveyText4,
                                                            [classes.inputFieldDisable]: true
                                                        })}
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.surveySection}>
                                                <Grid item md={3} xs={12}>
                                                    <FormControlLabel 
                                                        value="6"
                                                        control={
                                                            <Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />
                                                        } 
                                                        label="Through Q CELLS" 
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Name of referrer or promotion"
                                                        name="surveyText6"
                                                        inputRef={register}
                                                        className={classNames([classes.inputField], {
                                                            [classes.errorInput]: !!errors.surveyText6,
                                                            [classes.inputFieldDisable]: true
                                                        })}
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.surveySection}>
                                                <Grid item md={3} xs={12}>
                                                    <FormControlLabel 
                                                        value="1" 
                                                        control={
                                                            <Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />
                                                        } 
                                                        label="Through distribution"
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Name of company or referrer"
                                                        name="surveyText1"
                                                        inputRef={register}
                                                        className={classNames([classes.inputField], {
                                                            [classes.errorInput]: !!errors.surveyText1,
                                                            [classes.inputFieldDisable]: true
                                                        })} 
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.surveySection}>
                                                <Grid item md={3} xs={12}>
                                                    <FormControlLabel 
                                                        value="5" 
                                                        control={
                                                            <Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />
                                                        } 
                                                        label="Other"
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        name="surveyText5"
                                                        inputRef={register}
                                                        className={classNames([classes.inputField], {
                                                            [classes.errorInput]: !!errors.surveyText5,
                                                            [classes.inputFieldDisable]: true
                                                        })}
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                        </RadioGroup>
                                    }
                                />
                            </TableCell>
                        </TableRow>}          
                    </TableBody>
                </Table>

                <div className={classes.agreeBox}>
                    <FormControlLabel control={<Checkbox name="agree1" size="medium" inputRef={register} 
                    icon={<div className={classNames('', { [classes.checkBoxOn]: agree1, [classes.checkBox]: !agree1 })}></div>}
                    checkedIcon={<div className={classNames('', { [classes.checkBoxOn]: agree1, [classes.checkBox]: !agree1 })}></div>} />}
                    label={
                        <p>
                            I have read and accepted <a href="/terms" target="_blank" className={classes.agreeBoxLink}>the Terms of Use</a> and <a href="/policy" target="_blank" className={classes.agreeBoxLink}>the Privacy Policy</a>.
                        </p>
                    } />
                    <FormControlLabel control={
                        <Checkbox
                            name="agree" 
                            size="medium"
                            inputRef={register}
                            disabled={!userType.isNew}
                            icon={
                                <div 
                                    className={classNames('', {
                                    [classes.checkBoxOn]: agree,
                                    [classes.checkBox]: !agree
                                    })}></div>}
                            checkedIcon={
                                <div className={classNames('', {
                                    [classes.checkBoxOn]: agree,
                                    [classes.checkBox]: !agree
                                })}></div>} 
                            />} label={
                        <div>
                            <p>
                                Sign up for the Q.PARTNER Newsletter to receive exclusive promotions, latest news, and new product notifications.
                            </p>
                            <p>
                                Stay tuned for exciting updates, including even more ways to get additional benefits.
                            </p>
                        </div>
                    } />
                </div>

                <Grid 
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ textAlign: 'center', marginTop: '40px', marginBottom: '80px' }}
                >
                    <Button className={classes.confirmBtn} onClick={handleSubmit(onSubmit, onSubmitFailed)} disabled={loading}>
                        {loading ? <CustomLoading size={26} color="#fff"></CustomLoading> : 'Confirm'}
                    </Button>
                    <Button className={classes.cancelBtn} onClick={() => history.push('/mypage/member')}>Cancel</Button>
                </Grid>
            </div>
        </>
    )
}