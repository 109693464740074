import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone-uploader';
import TrashIcon from '../../../icons/trash_icon.png';
import ExcelIcon from '../../../icons/excel_icon.png';
import { ExcelRenderer } from 'react-excel-renderer';
import axios from '../../../utils/axios';
import config from '../../../config';
const { baseUrl } = config;

const ProductUploaderInput = ({ className, labelClassName, labelWithFilesClassName, style, labelStyle, labelWithFilesStyle, getFilesFromEvent, accept, multiple, disabled, content, withFilesContent, onFiles, files }) => {
  return (
    <label className={labelClassName} style={labelStyle}>
      <p>
          <span style={{ fontSize: '15px', color: '#000', fontWeight: 400 }}>
            Drag and drop or click to browse (Use the provided template)
          </span>
      </p>
      <input
        className={className}
        style={style}
        type="file"
        accept={accept}
        multiple={multiple}
        disabled={disabled}
        onChange={async e => {
          const target = e.target;
          const chosenFiles = await getFilesFromEvent(e);
          onFiles(chosenFiles);
          //@ts-ignore
          target.value = null;
        }}
      />
    </label>
  );
};

function UploadExcelPv({setSerials, setValue}) {

  const [files, setFiles] = useState([]);

  console.log(files , 'files');


  const renderExcelFilePromise = file => {
    return new Promise((resolve, reject) => {
      ExcelRenderer(file, (err, resp) => {
        if (err) {
          return reject(err);
        } else {
          const serialNumbers = [];
          let serialNumberRowIndex = 0;

          if (resp.rows.length > 1) {
            const row1 = resp.rows[1];
            for (let i = 0; i < row1.length; i++) {
              if (
                (row1[i] && String(row1[i]).replace(/\s/g, '').length === 18) ||
                (row1[i] && String(row1[i]).replace(/\s/g, '').length === 14)
              ) {
                serialNumberRowIndex = i;
              }
            }
          }

          resp.rows
            .filter(e => {
              return e.length && e[0] !== '';
            })
            .forEach((r, idx) => {
              if (idx === 0) {
              } else if (!!r[serialNumberRowIndex]) {
                const serialNumberRaw = String(r[serialNumberRowIndex]);
                const serialNumber = serialNumberRaw.replace(/\s/g, '');
                serialNumbers.push(serialNumber);
              }
            });

          return resolve({ serialNumbers });
        }
      });
    });
  };

  const validateSerialNumber = serialNumber => {
    return /(^(AH|AF|QH|QA|BF)(0600|0700|0760|0860)([A-Z])([1-9]|[A-C])(00[1-9]|0[1-9][0-9]|[1-9][0-9][0-9]|[A-Z]0[1-9]|[A-Z][1-9][0-9])(00[1-9]|0[1-9][0-9]|[1-9][0-9][0-9])$)|([0-9]){18}$/.test(serialNumber);
  };

  async function getSerialNumber(filesUpload){

    const allRenderExcelFilePromise = [];
    for (let i = 0; i < filesUpload.length; i++) {
      allRenderExcelFilePromise.push(renderExcelFilePromise(filesUpload[i].file));
    }

    const allExcelData = await Promise.all(allRenderExcelFilePromise);
    let allSerialNumbers = [];

    for (let i = 0; i < allExcelData.length; i++) {
      allSerialNumbers = [...allSerialNumbers, ...allExcelData[i].serialNumbers];
    }


    let duplicateOtherProject = [];

    if(allSerialNumbers.length > 0) {
      const { data } = await axios.post(
        `${baseUrl}/projects/serial-numbers/status`,
        allSerialNumbers
      );

      setValue('isDuplicateOtherProject', data?.modulesDuplicated?.length > 0)
      setValue('isInValidUpload', data?.modulesInvalid?.length > 0)
      duplicateOtherProject = data?.modulesDuplicated

    }



    const modules = [];
    const invalidModules = [];
    const checkedDuplicates = [];
    const alreadyChecked = [];



    allSerialNumbers.forEach(serialNumber => {
      if (alreadyChecked.indexOf(serialNumber) > -1) {
        checkedDuplicates.push(serialNumber);
      } else if (serialNumber.length !== 18) {
        invalidModules.push({ serialNumber });
      } else if (
        !validateSerialNumber(serialNumber)
      ) {
        invalidModules.push({ serialNumber });
      } else {
        alreadyChecked.push(serialNumber);
        modules.push({
          serialNumber: serialNumber
        });
      }
    });




    setValue('isInValidUpload', invalidModules.length > 0)
    setValue('isDuplicateUpload', checkedDuplicates.length > 0)

    if(invalidModules.length === 0 && checkedDuplicates.length === 0 && duplicateOtherProject.length === 0) {
      //setSerials(modules);
      setValue('listSerialPick', modules)
    }


    if(invalidModules.length > 0 || checkedDuplicates.length > 0 || duplicateOtherProject.length > 0) {
      //setSerials([])
      setValue('listSerialPick', [])
    }
  }




  const handleChangeStatus = (file, status) => {
    let newFiles = [...files];
    if (status === 'done') {
      newFiles = [...files, file];
    } else if (status === 'removed') {
      newFiles = files.filter(f => f.meta.id !== file.meta.id);
    }
    setFiles(newFiles);
    getSerialNumber(newFiles);


  };

  return(
    <div>
      <Dropzone
        onChangeStatus={handleChangeStatus}
        InputComponent={ProductUploaderInput}
        PreviewComponent={null}
        accept=".xls, .xlsx"
        maxFiles={10}
        multiple={true}
        inputContent="Drag and drop or click to browse (Use the provided template)"
        styles={{
          dropzone: {
            overflow: 'hidden',
            borderRadius: 0,
            border: '2px dashed #ACACAC',
            padding: 0,
            color: '#000000',
            backgroundColor: '#f7f7f7'
          },
          preview: {
            margin: '10px'
          }
        }}
      />
      <div style={{ marginTop: '20px' }}>
        {files.length > 0 &&
        files.map(file => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <img
              src={TrashIcon}
              style={{ width: '16px', height: '16px', cursor: 'pointer' }}
              onClick={file.remove}
            />
            <img
              src={ExcelIcon}
              alt="Excel Icon"
              style={{
                width: '30px',
                height: '30px',
                marginLeft: '8px',
                marginRight: '5px'
              }}
            />
            <p>{file.file.name}</p>
          </div>
        ))}
      </div>
    </div>

  )
}


export default UploadExcelPv;
