import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Text from '../components/text';
import _ from 'lodash';
import classNames from 'classnames';
import './navbar_mobile.css';
import axios from '../../utils/axios';
import { baseUrl } from '../../config';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import AccessSubIdModal from './../components/AccessSubIdModal';

export default function NavbarMobile({
  className,
  buttonText,
  links,
  icon,
  currentMonth,
  currentYear,
  numberOfCartItem,
  logout,
  user,
  showUpdateProfileModal
}) {
  const [navbar_open, set_navbar_open] = useState(false);
  const [navItemOpen, set_navItemOpen] = useState([]);
  const [scrolled, set_scrolled] = useState(false);
  const history = useHistory();
  const { authenticated, auth } = useAuth();
  const [isShowModal, setIsShowModal] = useState(false);

  const handleThinkificSSO = async (e, return_url) => {
    e.preventDefault();

    if (!authenticated) {
      window.location.href = `${process.env.REACT_APP_THINKIFIC_URL}${return_url}`;
      return;
    }

    if (_.get(auth, 'user.primaryProductType') === null) {
      return showUpdateProfileModal();
    }

    try {
      const { data } = await axios.post(`${baseUrl}/loginThinkific`);
      const { token } = JSON.parse(localStorage.getItem('qpartner_user'));
      const returnUrl = encodeURIComponent(
        `/pages/academyredirect?qpartnerToken=${token}&rd=${return_url}`
      );
      window.location.href = `${process.env.REACT_APP_THINKIFIC_URL}/api/sso/v2/sso/jwt?jwt=${data.tokenThinkific}&return_to=${returnUrl}&error_url=/404`;
    } catch (err) {
      window.location.href = `${process.env.REACT_APP_THINKIFIC_URL}${return_url}`;
    }
  };

  const handleClickNavItem = a => {
    if (navItemOpen.includes(a)) {
      set_navItemOpen([]);
    } else {
      set_navItemOpen([a]);
    }
  };

  function handleShowModal() {
    setIsShowModal(true);
  }

  React.useEffect(() => {
    window.addEventListener('scroll', function(e) {
      if (document.documentElement.scrollTop > 60) {
        set_scrolled(true);
      } else {
        set_scrolled(false);
      }
    });
  }, []);

  return (
    <React.Fragment>
      <AccessSubIdModal
        open={isShowModal}
        handleClose={() => setIsShowModal(false)}
      />
      <header
        className={classNames('', {
          on: navbar_open,
          off: !navbar_open,
          scrolled: scrolled,
          on_top: !scrolled
        })}
      >
        <div className="header_inner">
          <nav>
            <div className="wr_nav_above">
              <a
                className="btn_m_gnb_cl"
                className={classNames('btn_m_gnb_cl', { on: navbar_open })}
                onClick={e => set_navbar_open(!navbar_open)}
              >
                <em>Menu Close</em>
              </a>
              <a
                className={classNames('btn_m_gnb_op', { on: !navbar_open })}
                onClick={e => set_navbar_open(!navbar_open)}
              >
                <em>Menu Open</em>
              </a>
              <h1 className="logo">
                {user && user.id ? (
                  <Link to={authenticated ? '/dashboard' : '/'} onClick={e => set_navbar_open(false)}>
                    <em>Q.</em>PARTNER
                  </Link>
                ) : (
                  <Link
                    to={authenticated ? '/dashboard' : '/'}
                    onClick={e => set_navbar_open(false)}
                  >
                    <em>Q.</em>PARTNER
                  </Link>
                )}
              </h1>

              {user && user.id ? (
                <ul className="link_t">
                  <li className="list">
                    <span onClick={logout} className="list_m logout">
                      LOGOUT
                    </span>
                  </li>
                </ul>
              ) : (
                <ul className="link_t">
                  <li className="list">
                    <Link
                      onClick={e => set_navbar_open(false)}
                      to="/sso/login"
                      id="top_login_btn"
                      className="list_m login"
                    >
                      LOGIN
                    </Link>
                  </li>
                </ul>
              )}
            </div>
            <div className="wr_nav_below">
              <div className="below_1">
                <div
                  className={classNames('item_nav', {
                    over: navItemOpen.includes(1)
                  })}
                >
                  <div className="dep_m" onClick={e => handleClickNavItem(1)}>
                    <a>ABOUT</a>
                  </div>
                  <div className="item_nav_level2">
                    <ul>
                      <li>
                        <Link
                          onClick={e => set_navbar_open(false)}
                          to="/about/program-introduction"
                        >
                          PROGRAM INTRODUCTION
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={classNames('item_nav', {
                    over: navItemOpen.includes(2)
                  })}
                >
                  <div className="dep_m" onClick={e => handleClickNavItem(2)}>
                    <a>SALES TOOLS</a>
                  </div>
                  <div className="item_nav_level2">
                    <ul>
                      <li>
                        <Link
                          onClick={e => set_navbar_open(false)}
                          to="/salestools/data-hub/download-center"
                        >
                          DATA HUB
                        </Link>
                      </li>
                      <li>
                        <a>PROJECT & HOMEOWNER'S WARRANTY</a>
                        <ul>
                          <li>
                            <Link
                              onClick={e => set_navbar_open(false)}
                              to="/projects/project-introduction"
                            >
                              INTRODUCTION
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={e => set_navbar_open(false)}
                              to="/projects/project-registration"
                            >
                              PROJECT REGISTRATION
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={classNames('item_nav', {
                    over: navItemOpen.includes(3)
                  })}
                >
                  <div className="dep_m" onClick={e => handleClickNavItem(3)}>
                    <a>TRAINING</a>
                  </div>
                  <div className="item_nav_level2">
                    <ul>
                      <li>
                        <a
                          onClick={e => handleThinkificSSO(e, '/collections/nabcep')}
                          href={`${process.env.REACT_APP_THINKIFIC_URL}/collections?category=nabcep`}
                        >
                          NABCEP
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={e => handleThinkificSSO(e, '/collections/ess')}
                          href={`${process.env.REACT_APP_THINKIFIC_URL}/collections/ess`}
                        >
                          ESS
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={e =>
                            handleThinkificSSO(e, '/collections/webinar')
                          }
                          href={`${process.env.REACT_APP_THINKIFIC_URL}/collections/webinar`}
                        >
                          WEBINAR
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={e =>
                            handleThinkificSSO(e, '/collections/offline')
                          }
                          href={`${process.env.REACT_APP_THINKIFIC_URL}/collections/offline`}
                        >
                          OFFLINE
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={classNames('item_nav', {
                    over: navItemOpen.includes(4)
                  })}
                >
                  {/*<div className="dep_m" onClick={e => handleClickNavItem(4)}>*/}
                  {/*  <a>REWARDS</a>*/}
                  {/*</div>*/}
                  <div className="item_nav_level2">
                    <ul>
                      <li>
                        <Link
                          onClick={e => set_navbar_open(false)}
                          to={{
                            pathname: "/rewards/shop",
                            state: { refresh: true }
                          }}
                        >
                          SHOP
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={classNames('item_nav', {
                    over: navItemOpen.includes(5)
                  })}
                >
                  <div className="dep_m" onClick={e => handleClickNavItem(5)}>
                    <a>SUPPORT</a>
                  </div>
                  <div className="item_nav_level2">
                    <ul>
                    <li>
                      <a>PRODUCT & LABOR WARRANTY CLAIM</a>
                      <ul>
                        <li>
                          <Link to="/claim/how-it-works">HOW IT WORKS</Link>
                        </li>
                        <li>
                          <Link to={{
                            pathname : `/claim/info`,
                            state : {
                              type : 'select',
                              isPickClaimType : true
                            }
                          }}>CLAIM SUBMISSION</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                        <Link
                          onClick={e => set_navbar_open(false)}
                          to={`/support/contact-us`}
                        >
                          CONTACT US
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={e => set_navbar_open(false)}
                          to="/support/faq/1"
                        >
                          FAQ
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={classNames('item_nav', {
                    over: navItemOpen.includes(6)
                  })}
                >
                  <div className="dep_m" onClick={e => handleClickNavItem(6)}>
                    <a>NEWS</a>
                  </div>
                  <div className="item_nav_level2">
                    <ul>
                    <li>
                    <Link
                      onClick={e => set_navbar_open(false)}
                      to={{
                        pathname: "/notice",
                        state: { refresh: true }
                      }}
                    >
                      NOTICE
                    </Link>
                  </li>
                    </ul>
                  </div>
                </div>

                {user && user.id ? (
                  <div
                    className={classNames('item_nav item_nav_user', {
                      over: navItemOpen.includes(7)
                    })}
                  >
                    <div className="dep_m" onClick={e => handleClickNavItem(7)}>
                      <a
                        dangerouslySetInnerHTML={{
                          __html: `${user.firstName} ${user.lastName}`
                        }}
                      />
                    </div>
                    <div className="item_nav_level2">
                      <ul>
                        <li>
                          <Link
                            onClick={e => set_navbar_open(false)}
                            to="/dashboard"
                          >
                            DASHBOARD
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={e => set_navbar_open(false)}
                            to="/projects/myprojects"
                          >
                            MY PROJECTS
                          </Link>
                        </li>
                        <li>
                          <a
                            onClick={e => {
                              set_navbar_open(false);
                              handleThinkificSSO(e, '/enrollments');
                            }}
                            href={`${process.env.REACT_APP_THINKIFIC_URL}/enrollments`}
                          >
                            MY COURSES
                          </a>
                        </li>
                        <li>
                          <a>ACCOUNT MANAGEMENT</a>
                          <ul>
                            <li>
                              <Link
                                onClick={e => set_navbar_open(false)}
                                to="/mypage/member"
                              >
                                ACCOUNT INFORMATION
                              </Link>
                            </li>
                            {_.get(auth, 'user.role') !== 'Sub' &&
                              <li>
                                <Link
                                  onClick={e => set_navbar_open(false)}
                                  to="/subidmanagement"
                                >
                                  SUB ID MANAGEMENT
                                </Link>
                              </li>}
                            {_.get(auth, 'user.role') === 'Sub' &&
                              <li>
                                <span
                                  style={{ color: '#666', cursor: 'pointer' }}
                                  onClick={() => {
                                    set_navbar_open(false);
                                    handleShowModal();
                                  }}
                                >
                                  SUB ID MANAGEMENT
                                </span>
                              </li>}
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="below_2">
                <div className="wr_logo">
                  <a href="https://www.q-cells.us/" target="_blank">
                    <img
                      src={`${process.env.REACT_APP_QPARTNER_LEGACY_IMAGE_BASE_URL}/resources/images/layout/qcells.svg`}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <div className="spacer_mobile">&nbsp;</div>
    </React.Fragment>
  );
}
