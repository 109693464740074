import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import IconEdit from '../../../icons/edit_icon.svg';
import Moment from 'react-moment';
import CustomButton from '../../../common/components/button';
import CustomLoading from '../../../common/components/CustomLoading';
import useStyles from './useStyles';
import _ from 'lodash';
import OpenImageIcon from '../../../config/images/open_image_icon.svg';
import ImageModal from '../../projects/components/ImageModal';
import classNames from 'classnames';
import { decodeStringHtml } from '../../../utils/helpers';
import Error from './Error';


function ReviewEss(
  {
    goBack, watch, goToStep, getValues, handleSubmit, errors, disabled, goBackStep, errorInfo, urlImageProof, urlImageProofOfPermisstion,
    type, imagePreviewUrl, loading, setIsReview,
    urlImagePictureOfModule, urlImagePictureOfFrontSide,
    urlImagePictureVocReading, urlImagePicturePhysicalDefect,
    urlImagePictureOfOverall, urlImagePictureOfWire,
    urlImagePictureOfModuleCustomer, urlImagePictureOfVmpp,
    urlImagePictureIr, urlImagePictureBackSideOfModule,
    urlErrors, urlEvidence, urlLaborWarranty, urlInvoiceLabor, urlInfoBank

  }) {
  const classes = useStyles();
  const [modalUrl, setModalUrl] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const data = watch();
  const nameProject = watch('nameProject');
  const choseTypeSerialUpload = watch('choseTypeSerialUpload');
  const listSerialPickData = watch('listSerialPick');
  const choseTypeProject = watch('choseTypeProject');

  const renderAddress = (array = []) => {
    const newArray = _.remove(array, value => !!value || value === 'select');
    return newArray.length > 0 ? _.join(newArray, ', ') : '';
  };

  const renderPurchasingChannel = purchasingChannelValue => {
    switch (purchasingChannelValue) {
      case 'qcells':
        return 'Q CELLS';
      case 'distributor':
        return 'Distributor';
      case 'other':
        return 'Other';
      default:
        return '';
    }
  };

  const renderNameOfPurchasingChannel = () => {
    const purchaseChannel = getValues('purchasingChannel');
    switch (purchaseChannel) {
      case 'qcells':
        return 'Q CELLS';
      case 'distributor':
        return _.get(data, 'nameOfPurchasingChannelDistributor.name') + '' + `${_.get(data, 'nameOfPurchasingChannelDistributor.name') === 'Other' ? `-${_.get(data, 'nameOfChanelOtherDistributor')}` : ''}`;
      case 'other':
        return getValues('nameOfPurchasingChannelOther');
      default:
        return '';
    }
  };

  const renderField = fieldName => {
    const fieldValue = data[fieldName];
    if (!!fieldValue && fieldValue !== 'select') {
      return fieldValue;
    }

    return null;
  };

  const renderProductType = () => {
    switch (getValues('moduleProductType')) {
      case 'qcells':
        return 'Q CELLS';
      case 'other':
        return 'Other';
      default:
        return 'Q CELLS';
    }
  };

  function isEmpty(obj) {
    for(var key in obj) {
      if(obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  //const optionEss = [
  //   { name : 'Please select', value : 'select' },
  //   { name : 'Autotransformer', value : 1 },
  //   { name : 'Battery Module', value: 2 },
  //   { name : 'BMS', value: 3 },
  //   { name : 'ESS Alarm', value: 4 },
  //   { name : 'Hardware Error', value: 5 },
  //   { name : 'Inverter', value: 6 },
  //   { name : 'Meter', value: 7 },
  //   { name : 'Software', value: 8 },
  //   { name : 'Other', value: 9 },
  // ];

  const renderClaimType = (type) => {
    const action = {
      1 : 'Autotransformer',
      2 : 'Battery Module',
      3 : 'BMS',
      4 : 'ESS Alarm',
      5 : 'Hardware Error',
      6 : 'Inverter',
      7 : 'Meter',
      8 : 'Software',
      9 : 'Other',
      'default' : 'Other'
    }

    return action[type] || action['default']
  }

  function handleBackReview() {
    goBack();
  }

  function renderSerialNumber() {
      if(choseTypeSerialUpload === '1' ) {
        return getValues('serialNumbers')?.map(item => item.serialNumber).join(', ').toString()
      }else {
        return listSerialPickData?.map(item => item.serialNumber).join(', ').toString()
      }
  }

  return (
    <div className={classes.reviewInfoContainer}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>User Info</h5>
          <Button className={classes.editBtn} onClick={goBackStep}>
            <span>Edit</span>
            <img src={IconEdit} title='Edit' />
          </Button>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Company Name</h6>
            <p>{decodeStringHtml(getValues('companyName'))}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Company Address</h6>
            <p>
              {renderAddress([
                data.installerAddress1,
                data.installerAddress2,
                data.installerCity,
                data.installerState,
                data.installerZipcode
              ])}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div
            style={{
              paddingTop: '13px',
              paddingBottom: '13px'
            }}
            className={classes.reviewSectionContent}
          >
            <h6>User Name</h6>
            <p>{data.installerName}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>E-mail </h6>
            <p>
              {data.installerEmail}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Phone Number</h6>
            <p>
              {data.installerPhone}
            </p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Contact E-Mail</h6>
            <p>
              {data.submitterEmail || data.installerEmail}
            </p>
          </div>
        </div>



      <div className={classes.reviewSectionsContainer}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>Issue</h5>
          <Button className={classes.editBtn} onClick={() => goToStep(1)}>
            <span>Edit</span>
            <img src={IconEdit} title='Edit' />
          </Button>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Claim Type</h6>
            <p>
              Battery
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Description of the Issue</h6>
            <p>
              {!errors?.description ? getValues('description') : <span style={{color: '#EB5757'}}>Required field missing</span>}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Error Logs</h6>
            <div className={classes.images}>
              <p>{urlErrors.length === 0 && <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
              { urlErrors.map(p => (
                <div key={p.url}>
                  <img src={p.url} className={classes.image} />
                  <div className={classes.overlay} role="overlay">
                    <img
                      src={OpenImageIcon}
                      onClick={() => {
                        setModalUrl(p.url);
                        setOpenModal(true);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Other evidence</h6>
            <div className={classes.images}>
              { urlEvidence.map(p => (
                <div key={p.url}>
                  <img src={p.url} className={classes.image} />
                  <div className={classes.overlay} role="overlay">
                    <img
                      src={OpenImageIcon}
                      onClick={() => {
                        setModalUrl(p.url);
                        setOpenModal(true);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={classes.reviewSectionsContainer}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>System info</h5>
          <Button className={classes.editBtn} onClick={() => goToStep(2)}>
            <span>Edit</span>
            <img src={IconEdit} title='Edit'/>
          </Button>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Project name</h6>
            <p>{!errors?.projectNameSys ? getValues('projectNameSys') : <span style={{color: '#EB5757'}}>Required field missing</span> }</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Address</h6>
            <p>{!errors?.projectAddressManual ? _.get(getValues('projectAddressManual'), 'label') : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Installation Date</h6>
            <p>{!errors?.projectInstallationDate ? data.projectInstallationDate && <Moment format="L">{data.projectInstallationDate}</Moment> : <span style={{color: '#EB5757'}}>Required field missing</span> }</p>
          </div>
        </div>
        {/*<div className={classes.reviewSectionContainer}>*/}
        {/*  <div className={classes.reviewSectionContent}>*/}
        {/*    <h6>Permission Date</h6>*/}
        {/*    <p>{data.projectPermissionDate && <Moment format="L">{data.projectPermissionDate}</Moment>}</p>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Proof of Permission-to-Operate Date</h6>
            <div className={classes.reviewSectionContentImage}>
              {errors?.isUploadProofDate && <Error/>}
              <div className={classes.images}>
                { urlImageProof?.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className={classes.images}>
                { urlImageProofOfPermisstion.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/*<p>{getValues('other_evidence')}</p>*/}
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Product name</h6>
            <p>{!errors?.projectProductName ? getValues('projectProductName'): <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>System type</h6>
            <p>{!errors?.projectSystemType ? getValues('projectSystemType') === 'select'? '' : getValues('projectSystemType')  : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Inverter serial number</h6>
            <p>{(!errors?.serialNumbers && !errors.isUploadSerialNumber) ? renderSerialNumber() : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Inverter power</h6>
            <p>{!errors?.projectInverterPower ? `${getValues('projectInverterPower')} kWh` : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Pocket WiFi SW #</h6>
            <p>{getValues('pocketWifiSys')}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Batteries Serial Number</h6>
            <p>{getValues('batterySerialNumber')}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Battery Type</h6>
            <p>{renderField('batteryType')}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Number of batteries</h6>
            <p>{!errors?.numberOfBattery ? getValues('numberOfBattery') :  <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>
      </div>

      <div className={classes.reviewSectionsContainer}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>Software version</h5>
          <Button className={classes.editBtn} onClick={() => goToStep(2)}>
            <span>Edit</span>
            <img src={IconEdit} title='Edit'/>
          </Button>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>ARM SW Version</h6>
            <p>{getValues('dsp1SwVersion')}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>ARM SW Version</h6>
            <p>{getValues('armSwVersion')}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Battery Master SW Version</h6>
            <p>{getValues('batterySwVersion')}</p>
          </div>
        </div>


      </div>

      <div className={classes.reviewSectionsContainer}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>Field Details</h5>
          <Button className={classes.editBtn} onClick={() => goToStep(2)}>
            <span>Edit</span>
            <img src={IconEdit} title='Edit'/>
          </Button>
        </div>

        {data.systemType === 'DC' && <>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>PV1 String Voltage</h6>
            <p>{getValues('pv1')}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>PV2 String Voltage</h6>
            <p>{getValues('pv2')}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>PV3 String Voltage</h6>
            <p>{getValues('pv3')}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>PV4 String Voltage</h6>
            <p>{getValues('pv4')}</p>
          </div>
        </div>
          </>}

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Load L1 to N Voltage</h6>
            <p>{getValues('loadL1Voltage')}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Load L2 to N Voltage</h6>
            <p>{getValues('loadL2Voltage')}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Critical Loads connected</h6>
            <p>{getValues('criticalLoadsConnected')}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Total PV Power installed</h6>
            <p>{!errors?.totalPvPowerInstalled ? getValues('totalPvPowerInstalled'): <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Operational mode use</h6>
            <p>{renderField('operationalModeUse')}</p>
          </div>
        </div>

      </div>

      <div className={classes.reviewSectionsContainer}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>Purchase Info</h5>
          <Button className={classes.editBtn} onClick={() => goToStep(3)}>
            <span>Edit</span>
            <img src={IconEdit} title='Edit'/>
          </Button>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent} style={{ borderTop: '1px solid rgba(0, 0, 0, .1)' }}>
            <h6>Purchasing Channel</h6>
            <p>{!errors?.purchasingChannel ? renderPurchasingChannel(data?.purchasingChannel) : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Name of Purchasing Channel</h6>
            <p>{(!errors?.nameOfPurchasingChannelOther && !errors?.nameOfPurchasingChannelDistributor && !errors?.nameOfPurchasingChannelSelect) ? renderNameOfPurchasingChannel() : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>PO Number</h6>
            <p>{getValues('poNumber')}</p>
          </div>
        </div>

        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Purchase Date</h6>
            <p>
              {data.purchaseDate && <Moment format="L">{data.purchaseDate}</Moment>}
            </p>
          </div>
        </div>

      </div>

      <div className={classes.reviewSectionsContainer}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>Labor Reimbursement</h5>
          <Button className={classes.editBtn} onClick={() => goToStep(3)}>
            <span>Edit</span>
            <img src={IconEdit} title='Edit'/>
          </Button>
        </div>
        <div className={classes.reviewSectionContent}>
          <h6>Invoice for Labor Cost Reimbursement</h6>
          <div className={classes.images}>
            <p>{errors.invoiceForLaborCost && <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
            <p>{urlInvoiceLabor.length === 0 && !errors.invoiceForLaborCost ? 'N/A' : ''}</p>
            { urlInvoiceLabor.map(p => (
              <div key={p.url}>
                <img src={p.url} className={classes.image} />
                <div className={classes.overlay} role="overlay">
                  <img
                    src={OpenImageIcon}
                    onClick={() => {
                      setModalUrl(p.url);
                      setOpenModal(true);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.reviewSectionContent}>
          <h6>Bank Account Information</h6>
          <div className={classes.images}>
            <p>{errors.infoBank && <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
            <p>{urlInfoBank.length === 0 && !errors.infoBank ? 'N/A' : ''}</p>
            { urlInfoBank?.map(p => (
              <div key={p.url}>
                <img src={p.url} className={classes.image} />
                <div className={classes.overlay} role="overlay">
                  <img
                    src={OpenImageIcon}
                    onClick={() => {
                      setModalUrl(p.url);
                      setOpenModal(true);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>
      <Box style={{marginTop : 25}} display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
        <CustomButton color="secondary" className="saveDraftBtn" onClick={handleBackReview}>Back</CustomButton>
        <CustomButton className={`${!isEmpty({...errors, ...errorInfo}) && 'saveDraftBtnReview'}`} onClick={handleSubmit} disabled={!isEmpty({...errors, ...errorInfo}) || loading}>
          {loading ? <CustomLoading size={18} color="#fff"/> : 'Submit'}
        </CustomButton>
      </Box>

      <ImageModal
        open={openModal}
        url={modalUrl}
        name={modalUrl ? modalUrl.substring(modalUrl.lastIndexOf('/') + 1) : ''}
        handleClose={() => {
          setOpenModal(false);
          setModalUrl('');
        }}
      />
    </div>
  );
}

export default ReviewEss;
