import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import config from '../../../../config';
import IconPM from '../../../../icons/icon_PM.png';
// import IconPM from '../../../../icons/PM-icon-1_90x85px.png';
import IconPMActive from '../../../../icons/icon_PM_active.png';
import IconClaimsLabor from '../../../../icons/icon_CLAIMS_LABOR.png';
import IconClaimsLaborActive from '../../../../icons/icon_CLAIMS_LABOR_active.png';

function SOS({step1, step2, step3, step4, step5, handleChangeStep}) {
  const history = useHistory();
  const logoStep1 = step1 ? IconPMActive : IconPM;
  // const logoStep2 = step2 ? config.urlIconCrash : config.urlIconActive;
  const logoStep3 = step3 ? config.urlIconOffline : config.urlIconOfflineActive;
  const logoStep4 = step4 ? config.urlIconDataHub : config.urlIconDataHubActive;
  const logoStep5 = step5 ? IconClaimsLaborActive : IconClaimsLabor;
  return (
    <div className="wrap-sos">
      <div className="wrap-btn-sso">
        <button
          onClick={() => history.push('/sso/login?url=/dashboard#')}
          className={'btn-sso-box'}
        >
          <span>Log in</span>
        </button>
        <button
          onClick={() => history.push('/register')}
          className={'btn-sso-box btn-bc'}
        >
          BECOME A Q.PARTNER
        </button>
      </div>
      <div className="wrap-info-sso">
        <div className="cs-reward" onClick={() => handleChangeStep(1)}>
          <img src={logoStep1} alt={'logo'}/>
          <span className={step1 && 'active-txt'}>Project Management</span>
        </div>
        {/*<div className="cs-reward" onClick={() => handleChangeStep(2)}>*/}
        {/*  <img src={logoStep2} alt={'logo'} />*/}
        {/*  <span className={step2 && 'active-txt'}>Cash Rewards</span>*/}
        {/*</div>*/}
        <div className="cs-reward" onClick={() => handleChangeStep(2)}>
          <img src={logoStep3} alt={'logo'}/>
          <span className={step3 && 'active-txt'}>Training</span>
          <br/>
        </div>
        <div className="cs-reward" onClick={() => handleChangeStep(3)}>
          <img src={logoStep4} alt={'logo'}/>
          <span className={step4 && 'active-txt'}>Data Hub</span>
        </div>
        <div className="cs-reward" onClick={() => handleChangeStep(4)}>
          <img src={logoStep5} alt={'logo'} style={{ maxHeight: "82px" }}/>
          <span className={step5 && 'active-txt'}>Claims/Labor</span>
        </div>
      </div>
    </div>
  );
}

export default SOS;
