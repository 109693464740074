import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import classNames from 'classnames';
import CheckValidate from '../../../common/components/CheckValidate';
import { Controller } from 'react-hook-form';
import CustomButton from '../../../common/components/button';
import CustomLoading from '../../../common/components/CustomLoading';
import useStyles from './useStyles';
import ViewUser from './ViewUser';
import IconEdit from '../../../icons/edit_icon.svg';
import UploadFileExcel from './UploadFIleExcel';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
const optionBattery = [
  { name : 'Please select', value : 'select' },
  { name : 'Autotransformer', value : 1 },
  { name : 'Battery Module', value: 2 },
  { name : 'BMS', value: 3 },
  { name : 'ESS Alarm', value: 4 },
  { name : 'Hardware Error', value: 5 },
  { name : 'Inverter', value: 6 },
  { name : 'Meter', value: 7 },
  { name : 'Software', value: 9 },
  { name : 'Other', value: 8 },
];

const optionPanel = [
  { name: 'Please select', value: 'select' },
  { name: 'Power loss/under performing module', value: 1 },
  { name: 'Hot spot', value: 2 },
  { name: 'Broken glass', value: 3 },
  { name: 'Connector damage', value: 4 },
  { name: 'Detached cable/junction box damage', value: 5 },
  { name: 'Detached frame', value: 6 },
  { name: 'Other', value: 7 }
];

const optionClaim = [
  { name : 'Please select', value : 'select'},
  { name : 'Panel', value : 1},
  { name : 'Battery', value : 2},
]

function InformationClaimType({ condition, next, setValueEss, setValuePv ,register, watch, control, errors, handleSaveDraft, clearErrors ,isEnablePreviewButton, handleGoToReview, setValue, loading, getValues, text }) {
  const classes = useStyles();
  const currentData = getValues();
  const history = useHistory();
  const [options, setOption] = useState(optionPanel)

  const type = watch('type');
  const claimType = watch('claimType');



  useEffect(() => {

    if(type === '2') {
      setOption(optionBattery);
      setValueEss('type', type);
      setValueEss('claimType', claimType)
    }

    if(type === '1') {
      setOption(optionPanel)
      setValuePv('type', type);
      setValuePv('claimType', claimType);
    }

    if(condition?.type?.toString() === '1' && type !== condition?.type?.toString() && _.get(condition, 'projectInfo', null) !== null) {
      setValueEss('showMess', true)
    }

  }, [type, claimType])



  return (
    <div className={classes.wrapClaim}>
      <h1 style={{fontWeight: 500, fontSize: 16}}>Claim Type</h1>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }} />
      <div className={classes.stepInfo}>
        <span className={classes.sectionTitleStep}>
          1. Claim Type
        </span>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Claim Type
            <span>*</span>
          </span>
        </div>
        <div>
          <Controller
            name="type"
            control={control}
            render={({ onChange, value }) => (
              <select
                // placeholder="Battery Capacity"
                value={value}
                onChange={(event) => {
                  if(event.target.value !== type) {
                    setValue('claimType', 'select')
                  }
                  onChange(event.target.value)
                }}
                className={classNames([classes.selectField], {
                  //[classes.errorSelectField]: errors.batteryCapacity1
                })}
              >
                {optionClaim.map((option, index) => {
                  return <option key={index} value={option.value}>{option.name}</option>;
                })}

              </select>)
            }
          />
        </div>
        <CheckValidate value={watch('type')} error={errors.type}/>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
        </div>
        <div>
          <Controller
            name="claimType"
            control={control}
            as={
              <select
                placeholder="Purchasing Channel"
                className={classNames([classes.selectField], {
                  [classes.errorSelectField]: errors.claimType
                })}
              >
                {options.map((option, index) => {
                  return <option key={index} value={option.value}>{option.name}</option>;
                })}

              </select>
            }
          />
        </div>
        <CheckValidate value={watch('claimType')} error={errors.claimType}/>

      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
        </div>
        <div>
          <ViewUser setValue={setValue} setValueEss={setValueEss} setValuePv={setValuePv}  clearErrors={clearErrors} errors={errors} text={text} register={register} control={control} watch={watch} getValues={getValues}/>
        </div>
      </div>

      <p className={classes.requiredText}>* Required</p>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }}/>
      <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
        <div>
          {isEnablePreviewButton && (
            <CustomButton color="secondary" className="saveDraftBtn" onClick={handleGoToReview}>Back
              Preview</CustomButton>
          )}
        </div>
        <div>
          <CustomButton disabled={type === 'select'} className={`${type === 'select' && 'saveDraftBtnReview'}`} onClick={next}>Next</CustomButton>
        </div>
      </Box>
    </div>
  );
}

export default InformationClaimType;
