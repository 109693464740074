import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from '../../../../common/components/text';
import Price from '../../../../common/components/price';
import { secondaryLightThemeColor } from '../../../../utils/colors';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { VISA_CARD_PRODUCTS_KEYWORD } from './../../../../utils/constants';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  consolidateLabel: {
    backgroundColor: '#00ADEF',
    width: '70px',
    color: '#ffffff',
    textAlign: 'center',
    borderRadius:'5px'
  }
}));

const Container = styled.div`
  display: flex;
  padding: 14px 0;
  border-top: 1px solid ${secondaryLightThemeColor};

  &:last-child {
    border-bottom: 1px solid ${secondaryLightThemeColor};
  }
`;

const Image = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${props => props.src});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

const NameColorSizeContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  width: 300px;
  max-width: 350px;
`;
const Name = styled(Text)`
  width: 190px;
  line-height: 1.2rem;
  margin-bottom: 10px;
  font-weight: 500;
  margin-top: 2px;
`;

const QuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100px;
`;

export default function LineItem({ id, product, consolidate, name, image, quantity, price, color, size, discount, codes }) {
  const classes = useStyles();
  return (
    <Container>
      <Link to={`/rewards/shop/${_.get(product, 'product.productId')}`}><Image role="img" src={image} className="foo407" /></Link>
      <NameColorSizeContainer className="foo406">
        <Link style={{color : 'black'}} to={`/rewards/shop/${_.get(product, 'product.productId')}`}><Name size="h5">{name}</Name></Link>
        {!! discount && <Text size="small" weight="300"><span>Option: {discount}</span></Text>}
        {
          codes && <div>
            <Text size="small" weight="300">
              <span>Code: {codes.code}</span>
            </Text>
        </div>
        }

        {_.toLower(name).includes(VISA_CARD_PRODUCTS_KEYWORD) && consolidate && (
          <Text
            size="h6"
            weight="regular"
            className={classes.consolidateLabel}
          >
            Consolidate
          </Text>
        )}
        <Text size="small" weight="300">
          {color && <span style={{ marginRight: '10px' }}>Color: {color}</span>}
          {size && <span style={{ marginRight: '10px' }}>Size: {size}</span>}
        </Text>
      </NameColorSizeContainer>
      <QuantityContainer className="foo405">
        <span>Qty: {quantity}</span>
      </QuantityContainer>
      <Price className="foo404" color="dark">
        {price}
      </Price>
    </Container>
  );
}

LineItem.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  quantity: PropTypes.number,
  price: PropTypes.number,
  color: PropTypes.string,
  size: PropTypes.string
};
