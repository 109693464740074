export const initialState  = {
  projectState: 'select',
  installationDate: null,
  projectAddress : null,

  latitude : 40.72,
  longitude : -73.935242,
  region : '',
  operateDate: null,
  selectedContactNumber: '1',
  purchasingChannel: 'select',
  nameOfPurchasingChannelDistributor: null,
  isManualSerialPanelNotMatchDb : false,
  isManualSerialBatteryNotMatchDb: false,
  // pvInverterBrand : 'select',
  moduleBrand: 'select',
  nameOfChanelOtherDistributor : '',
  nameOfModuleBrandBatteryOther : '',
  nameOfModuleBrandPanelOther : '',
  rackingBrand : 'select',
  namePvInverterBrandOther : '',
  nameRackingBrandOther : '',
  moduleModel : '',
  battery: false,
  systemType: 'select',
  batteryProductType: 'select',
  batteryBrand : 'select',
  batteryCapacity1: 'select',
  moduleSerialNumber: [],
  projectPictures: [],
  projectPictureProf : [],
  isSerialNumber: true,
  isSerialNumberBattery: true,
  isManualSerialNumber: true,
  isManualBatterySerialNumber : false,
  isInverterSerialNumber: true,
  essSerialNumber: [],
  isEssSerialNumber: true,
  inverterProductName1: 'select',
  isManualEssSerialNumber: false,
  modelBattery: 'select',
  batteryModel : 'select',
  modulePowerClass : '',
  numberOfModules : '',
  batteryEnergy : 'select',
  batteryInverterPower : 'select',
  projectZipCode: '',
  firstName: '',
  lastName: '',
  email: '',
  note : '',
  phoneNumber: '',
  nameOfPurchasingChannelSelect: null,
  nameOfPurchasingChannelOther: '',
  pvInverterModel: '',
  rackingModel : '',
  pvInverterBrand: 'select',

  panelQualified : false,
  batteryQualified : false,
  panelNonQcells : false,
  batteryNonQcells : false,

  isValidModulePanel : false,
  isDuplicateModulePanel : false,
  isDuplicateModulePanelFile : false,

  isValidModuleBattery : false,
  isDuplicateModuleBattery : false,
  isDuplicateModuleBatteryFile : false,

  isDuplicateAddress : false,

}
