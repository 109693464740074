import * as pubSpot from './pubspot';
import * as news from './news';
import * as claim from './claim';
import * as dashboard from './dashboard';
import * as project from './project';

export const pubSpotService = pubSpot;
export const newService = news;
export const claimService = claim;
export const dashboardService = dashboard;
export const projectService = project;
