import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TextField, Box } from '@material-ui/core';
import CustomDatePicker from './CustomDatePicker';
import CustomButton from '../../../common/components/button';
import _ from 'lodash';
import useStyles from './useStyles';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import CustomLoading from '../../../common/components/CustomLoading';
import NumberFormat from 'react-number-format';
import ImageUploader from './ImageUploader';
import CheckValidate from '../../../common/components/CheckValidate';
import MySelect from './MySelect';
import { projectService } from '../../../services';
import ShowMessage from '../../claim/components/ShowMessage';
import moment from 'moment';
import ShowMessageMyProject from './ShowMessageMyProject';

function ZipCodeNumberFormat(props) {
    return (
        <NumberFormat format="#####" {...props} />
    )
}




export default function ProjectInfo({ edit, clearErrors , watch, saleRegions, setValue ,filesProof, setFilesProof, setFilesProject, filesProject, next, register, control, errors, isEnablePreviewButton, handleGoToReview, handleSaveDraft, loadingSaveDraft, isEditCompleteProject = false, loading = false, handleSubmit }) {
    const classes = useStyles();
    const [optionAddress, setOptionAddress] = useState([]);
    const [isOpenDistributorMenu, setOpenDistributorMenu] = useState(false);
    const [loadingAddress, setLoadingAddress] = useState(false);
    const [addressSelected, setAddressSelected] = useState(null);
    const [isOperateDate, setIsOperateDate] = useState(true);
    const data = watch();
    const installationDate = watch('installationDate');
    const operateDate = watch('operateDate');
    const isDuplicateAddress = watch('isDuplicateAddress');

    async function loadOptions(text) {
      if(text !== '') {
        setLoadingAddress(true)
        const res = await projectService.getAddress({query : text})
        setOptionAddress(formatInfo(res.data))

        setLoadingAddress(false)

        return formatInfo(res.data)
      }

      // callback(formatInfo(res.data))

    }

    async function loadOptionAddress(text) {
      return new Promise(resolve => resolve(getOptions(text)));
    }

  const getOptions = async (inputValue) => {
    if(inputValue !== '') {
      setLoadingAddress(true)
      const res = await projectService.getAddress({query : inputValue})
      setOptionAddress(formatInfo(res.data))

      setLoadingAddress(false)

      return formatInfo(res.data)
    }
  };

  const loadSuggestions = _.debounce(loadOptions, 300);

    function formatInfo(data) {
      return data?.results?.map(item => {
        return {
          label : item?.formatted_address?.split(',')?.splice(0,item?.formatted_address?.split(',').length - 3 || 1)?.toString() || item?.formatted_address,
          value : item?.place_id
        }
      })
    }

    async function getDetailPlace(placeId) {
      const infoAddressDetail = await projectService.getDetailAddress(placeId)
      if(infoAddressDetail?.data?.result?.address_components?.length > 0) {
        const post_code = infoAddressDetail?.data?.result?.address_components?.filter(item => item?.types[0] === 'postal_code');
        const state = infoAddressDetail?.data?.result?.address_components?.filter(item => item?.types[0] === 'administrative_area_level_1');
        const city = infoAddressDetail?.data?.result?.address_components?.filter(item => item?.types[0] === 'locality');
        const country = infoAddressDetail?.data?.result?.address_components?.filter(item => item?.types[0] === 'country')
        // setValue('projectAddress2', _.get(infoAddressDetail?.data?.result, 'formatted_address', data?.projectAddress2))
        setValue('projectZipCode', post_code?.length > 0 ? post_code[0].short_name : data?.projectZipCode)
        setValue('projectState', state?.length > 0 ? state[0].long_name  : data?.projectState)
        setValue('projectCity', city?.length > 0 ? city[0].short_name : data?.projectCity);
        setValue('region', country?.length > 0 ? country[0].long_name : data?.region);
        setValue('latitude', _.get(infoAddressDetail?.data?.result, 'geometry.location.lat', data?.latitude));
        setValue('longitude', _.get(infoAddressDetail?.data?.result, 'geometry.location.lng', data?.longitude))
      }
    }

    async function checkDuplicateAddress(address) {
      const info = await projectService.checkDuplicateAddress(address)
      setValue('isDuplicateAddress', info?.data?.length > 0 || false)
    }

    const projectAddress = watch('projectAddress');

    useEffect(() => {
      if((projectAddress !== null || projectAddress !== '' || projectAddress !== undefined)) {
        //checkDuplicateAddress(projectAddress?.label)
        getDetailPlace(projectAddress?.value);
      }
    }, [projectAddress?.value])


    useEffect(() => {
      if(operateDate !== null) {
        const perateDateI = moment(_.get(data, 'operateDate')).add(3, 'M').format('YYYY-MM-DD');
        const dateNow = moment().format('YYYY-MM-DD')
        const isOpe = moment(perateDateI).isSameOrAfter(dateNow)
        setIsOperateDate(isOpe)
      }
    }, [operateDate])



    const onSelect = option => {
      if(option !== null) {
        checkDuplicateAddress(option?.label)
      }else {
        setValue('isDuplicateAddress', false)
      }

      setValue('projectAddress', option)
      clearErrors('projectAddress');
      clearErrors('projectState');
      clearErrors('projectCity');


    };

    let errorDuplicate = {}
    if(isDuplicateAddress) {
      errorDuplicate = {
        message : 'Duplicate Address'
      }
    }else {
      errorDuplicate = undefined
    }


    return (
        <div>
            <h1 style={{fontWeight: 500, fontSize: 16}}>Project Information</h1>
            <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }} />
            <div className={classes.sectionContainerSys} style={{marginTop: 32}}>
                <div 
                    className={classes.sectionHeaderContainer} 
                    style={{
                        alignItems: 'flex-start',
                        marginTop: '12px'
                    }}
                >
                    <span className={classes.sectionTitle}>
                        Project Name
                    </span>
                </div>
                <div>
                    <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])} style={{marginBottom: 0}}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Project Name"
                            name="projectName"
                            className={classes.inputField}
                            inputRef={register}
                        />
                    </div>
                    <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                        <div>
                            <ShowMessage textInfo={'You can name your project whatever you want for your own record.'}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.sectionContainerSys}>
                <div
                    className={classes.sectionHeaderContainer}
                    style={{
                        alignItems: 'flex-start',
                        marginTop: '12px'
                    }}
                >
                    <span className={classes.sectionTitle}>
                        Project Address {' '}
                        <span>*</span>
                    </span>
                </div>
                <div>
                    {/* <div className={classes.addressSection1}> */}
                        <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])} style={{marginBottom: 22}}>
                          <Controller
                            name="projectAddress"
                            key="name-purchasing-channel-projectAddress"
                            control={control}
                            as={
                              <MySelect
                                name="addressLookup"
                                className="addressLookupContainer"
                                label="Address Lookup"
                                asyncSelect
                                valueAddress={projectAddress}
                                components={{
                                  DropdownIndicator: () => null,
                                  IndicatorSeparator: () => null
                                }}
                                loadOptions={loadOptionAddress}
                                onSelect={onSelect}
                              />
                            }
                          />

                          {isDuplicateAddress ? <CheckValidate value={''}/> : <CheckValidate value={watch('projectAddress')} error={errors.projectAddress}/>}
                        </div>
                        {isDuplicateAddress && <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])} style={{marginBottom: 22}}>
                          <ShowMessageMyProject/>
                        </div>}
                        <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])} style={{marginBottom: 22}}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Ste, Unit, Building, Floor, etc"
                                name="projectAddress2"
                                className={classNames([classes.inputField], {
                                    // [classes.errorInputField]: errors.projectAddress2
                                })}
                                inputRef={register}
                            />
                            {/*<CheckValidate value={watch('projectAddress2')} error={errors.description}/>*/}
                        </div>

                        <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])} style={{marginBottom: 22}}>
                            <Controller
                                name="projectState"
                                control={control}
                                as={
                                    <select
                                        placeholder="State"
                                        className={classNames([classes.selectField], {
                                            [classes.errorSelectField]: errors.projectState
                                        })}
                                    >
                                        <option value={'select'}>State</option>
                                        {saleRegions.map(pc => {
                                            const [id, value] = pc.split(':');
                                            return (
                                                <option value={value} key={id}>{value}</option>
                                            )
                                        })}
                                    </select>
                                }
                            />
                            <CheckValidate value={watch('projectState')} error={errors.projectState}/>
                        </div>
                        <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])} style={{marginBottom: 22}}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="City"
                                name="projectCity"
                                className={classNames([classes.inputField], {
                                    [classes.errorInputField]: errors.projectCity
                                })}
                                inputRef={register}
                            />
                        <CheckValidate value={watch('projectCity')} error={errors.projectCity}/>
                        </div>
                        <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                            <Controller
                                name="projectZipCode"
                                control={control}
                                as={
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Zip Code"
                                        className={classNames([classes.inputField], {
                                            [classes.errorInputField]: errors.projectZipCode
                                        })}
                                        InputProps={{
                                            inputComponent: ZipCodeNumberFormat
                                        }}
                                        inputRef={register}
                                    />
                                }
                            />
                            <CheckValidate value={watch('projectZipCode')} error={errors.projectZipCode}/>
                        </div>
                    {/* </div> */}
                </div>
            </div>
            <div className={classes.sectionContainerSys}>
                <div
                    className={classes.sectionHeaderContainer}
                    style={{
                        alignItems: 'flex-start',
                        marginTop: '12px'
                    }}
                >
                    <span className={classes.sectionTitle}>
                        Project Picture
                    </span>
                </div>
                <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                   <ImageUploader
                        files={filesProject}
                        setFiles={setFilesProject}
                   />
                </div>
            </div>
            <div className={classes.sectionContainerSys}>
                <div className={classes.sectionHeaderContainer}>
                    <span className={classes.sectionTitle}>
                        Installation Date {' '}
                        <span>*</span>
                    </span>
                </div>
                <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                    <Controller
                        name="installationDate"
                        control={control}
                        render={(
                            { onChange, value }
                        ) => (
                            <CustomDatePicker
                                fullWidth
                                size="small"
                                variant="inline"
                                format="MM/dd/yyyy"
                                placeholder="mm/dd/yyyy"
                                inputVariant="outlined"
                                value={value}
                                onChange={date => onChange(date)}
                                views={['value', 'month', 'date']}
                                className={classNames([classes.datePicker], {
                                    [classes.errorDatePickerField]: errors.installationDate
                                })}
                                maxDate={new Date()}
                            />
                        )}
                    />
                    <CheckValidate value={watch('installationDate')} error={errors.installationDate}/>
                </div>
            </div>
            <div className={classes.sectionContainerSys} style={{marginBottom : 0}}>
                <div className={classes.sectionHeaderContainer}>
                    <span className={classes.sectionTitle}>
                        Permission-to-Operate Date {' '}
                        <span>*</span>
                    </span>
                </div>
                <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                    <Controller
                        name="operateDate"
                        control={control}
                        render={(
                            { onChange, value }
                        ) => (
                            <CustomDatePicker
                                fullWidth
                                size="small"
                                variant="inline"
                                format="MM/dd/yyyy"
                                placeholder="mm/dd/yyyy"
                                inputVariant="outlined"
                                value={value}
                                onChange={date => onChange(date)}
                                views={['value', 'month', 'date']}
                                className={classNames([classes.datePicker], {
                                    [classes.errorDatePickerField]: errors.operateDate
                                })}
                                maxDate={new Date()}
                                minDate={new Date(installationDate)}
                            />
                        )}
                    />
                    <CheckValidate value={watch('operateDate')} error={errors.operateDate}/>
                </div>
            </div>
          {!isOperateDate && <div className={classes.sectionContainerSys} style={{marginBottom : 0}}>
            <div className={classes.sectionHeaderContainer}>

            </div>
            <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
              <ShowMessage textInfo={'This project will not qualify for labor reimbursement as the PTO date is not within the past 3 months.'}/>
            </div>

          </div>}
            <div className={classes.sectionContainerSys}>
                <div
                    className={classes.sectionHeaderContainer}
                    style={{
                        alignItems: 'flex-start',
                        marginTop: '12px'
                    }}
                >
                    <span className={classes.sectionTitle}>
                        Proof of Permission-to-Operate Date
                    </span>
                </div>
                <div>
                    <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])} style={{marginBottom: 0}}>
                        <ImageUploader
                                files={filesProof}
                                setFiles={setFilesProof}
                        />
                    </div>
                    <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                        <ShowMessage textInfo={'Proof of PTO date is optional, but you will need to provide it to qualify for labor reimbursement when you submit claims.'}/>
                    </div>
                </div>
            </div>
            <p className={classes.requiredText}>* Required</p>
            <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }}/>
            {isEditCompleteProject ? (
                <Box display="flex" justifyContent="flex-end" className={classes.navigationBtnContainer}>
                    <CustomButton className={`${isDuplicateAddress ? 'saveDraftBtnReview' : ''}`}  onClick={handleSubmit} disabled={isDuplicateAddress}>
                        { loading ? <CustomLoading size={16} color="#ffffff"/> : 'Save' }
                    </CustomButton>
                </Box>
            ) : (
                <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
                    <div>
                        {isEnablePreviewButton && (
                            <CustomButton color="secondary" className="saveDraftBtn" onClick={handleGoToReview}>Back to Review</CustomButton>
                        )}
                    </div>
                    <div>
                        <CustomButton color="secondary" className="saveDraftBtn" onClick={handleSaveDraft} disabled={loadingSaveDraft}>
                            { loadingSaveDraft ? <CustomLoading size={16} color="#009EE3" /> : 'Save Draft' }
                        </CustomButton>
                        <CustomButton onClick={next}>Next</CustomButton>
                    </div>
                </Box>
            )}
        </div>
    )
};
