import React from 'react';
import styled from 'styled-components';
import ProjectInfoIcon from '../../../icons/project_info_step1.svg';
import SystemInfoIcon from '../../../icons/system_info_step2.svg';
import PurchasingInfoIcon from '../../../icons/purchasing_info_step3.svg';
import HomeownerInfoIcon from '../../../icons/homeowner_info_step4.svg';
import NoteIcon from '../../../icons/note_step5.svg';
import ReviewInfoIcon from '../../../icons/review_info_step6.svg';

const BarContainer = styled.div`
  padding-top: 30px !important;
  background-color: #F6F6F6;

  @media (min-width: 1245px) {
    padding-top: 47px !important;
  }
`;

const Bar = styled.ul`
  display: flex;
  width: 100%;
  overflow: hidden;
  color: lightgrey;
  margin: auto !important;
  
  @media (min-width: 1245px) {
    width: 72.5%;
    min-width: 650px;
  }
`;

const ProgressBarStep = styled.li`
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
  z-index: 1;
  text-align: center;
  font-family: 'Actual_Light';
  border-bottom: 5px solid ${({ active }) => active ? '#02A3EC': '#F6F6F6'};

  &:hover {
    cursor: pointer;
  }
`;

const IconWrapper = styled.div`
  width: 58.24px;
  height: 47px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  border-radius: 50%;
  margin: 0 auto 20px auto;

  color: ${({ active, theme }) =>
  active ? theme.colors.light : theme.colors.secondaryDark};

  @media (max-width: 780px) {
    margin-bottom: 14px;
    width: 50.4px;
    height: 45px;
  }
`;

const Title = styled.h4`
  font-size: 15px;
  font-family: 'Actual';
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  color: ${({ active }) => active ? '#02A3EC' : '#000'};
  margin-bottom: 21px;
  opacity: ${({ active }) => active ? 1 : 0.3};

  @media (max-width: 780px) {
    font-size: 14px;
    margin-bottom: 12px;
  }

  @media (max-width: 680px) {
    font-size: 13px;
  }

  @media (max-width: 375px) {
    font-size: 12px;
  }
`;

const steps = [
    { step: 0, text: 'Project Information', icon: ProjectInfoIcon },
    { step: 1, text: 'System Information', icon: SystemInfoIcon },
    { step: 2, text: 'Purchasing Information', icon: PurchasingInfoIcon },
    { step: 3, text: 'Project Owner Information', icon: HomeownerInfoIcon },
    { step: 4, text: 'Review', icon: ReviewInfoIcon },
];

export default function Navbar({ currentStep, goToStep }) {
    return (
        <BarContainer>
            <Bar>
                {steps.map(({ step, text, icon }) => (
                    <ProgressBarStep 
                        active={step <= currentStep} 
                        onClick={() => goToStep(step)}
                    >
                        <IconWrapper active={step <= currentStep}>
                            <img src={icon} title={text} />
                        </IconWrapper>
                        <Title active={step <= currentStep}>{text}</Title>
                    </ProgressBarStep>
                ))}
            </Bar>
        </BarContainer>
    )
};
