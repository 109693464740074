import React, { useEffect } from 'react';
import { Box, TextField } from '@material-ui/core';
import useStyles from './useStyles';
import CustomButton from '../../../common/components/button';
import { Controller } from 'react-hook-form';
import CustomDatePicker from '../../projects/components/CustomDatePicker';
import classNames from 'classnames';
import CustomLoading from '../../../common/components/CustomLoading';
import { claimService } from '../../../services';

function PurchaseInfo({ next, goBack, register, control, errors, watch, setValue, handleGoToReview, handleSaveDraft, loading}) {
  const classes = useStyles();
  const projectId = watch('projectSys');

  async function getDetailProject(id) {
    try {
      const info = await claimService.getProjectById(id);
      setValue('distributorName' , info.data.nameOfPurchasingChannel);
    }catch (e) {
    }
  }

  useEffect(() => {
    if(projectId !== 'select') {
      getDetailProject(projectId);
    }
  }, [projectId])

  return(
    <div>
      <div className={classes.stepInfo}>
        <span className={classes.sectionTitleStep}>
          4.  Purchase Info
        </span>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Distributor Name
            <span>*</span>
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="distributorName"
            className={classNames([classes.inputField], {
              [classes.errorInputField]: errors.distributorName
            })}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Distributor City
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="distributorCity"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
             PO Number
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="poNumber"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
            <span className={classes.sectionTitle}>
                Purchase Date
            </span>
        </div>
        <div>
          <Controller
            name="purchaseDate"
            control={control}
            render={(
              { onChange, value }
            ) => (
              <CustomDatePicker
                fullWidth
                size="small"
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                inputVariant="outlined"
                value={value}
                className={classNames([classes.datePicker], {
                  [classes.errorDatePickerField]: errors.installationDate
                })}
                onChange={date => onChange(date)}
                views={['year', 'month', 'date']}
              />
            )}
          />
        </div>
      </div>
      <p className={classes.requiredText} style={{ marginTop: '308px' }}>* Required</p>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }}/>
      <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
        <CustomButton color="secondary" className="saveDraftBtn" onClick={goBack}>Back</CustomButton>
        <div>
          <CustomButton onClick={handleSaveDraft} color="secondary" className="saveDraftBtn" disabled={loading}>
            { loading ? <CustomLoading size={16} color="#009EE3" /> : 'Save Draft' }
          </CustomButton>
          <CustomButton onClick={next}>Next</CustomButton>
        </div>
      </Box>
    </div>
  )
}

export default PurchaseInfo;
