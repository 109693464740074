import * as yup from 'yup';
import _ from 'lodash';

export const schemaProject = yup.object().shape({
  projectAddress: yup.object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .nullable()
    .required('Project is required is required.'),

  //isDuplicateAddress : yup.string().required("Address duplicated"),
  // projectAddress: yup.object()
  //   .shape({
  //     value: yup.string(),
  //     label: yup.string(),
  //   })
  //   .nullable()
  //   .required('This projectAddress2 is required.'),
  projectCity: yup.string().required("City is required"),
  projectState: yup.string().notOneOf(['select'], 'State is required'),
  //projectZipCode: yup.string().required("Zipcode is required").test({ name: 'length', message: 'Zipcode must be 5 digits long', exclusive: true, test: val => val && _.toString(_.trim(val)).length === 5 }),
  installationDate: yup.mixed().nullable().notOneOf([null], 'Installation date is required'),
  operateDate: yup.mixed().nullable().notOneOf([null], 'Permission to operate date is required'),
  //panel

  //mapping
  // moduleBrand -> moduleBrand, modulePowerClass -> volume, moduleModel -> productName, numberOfModules -> numberOfModules
  moduleBrand: yup.string().notOneOf(['select'], 'Panel brand is required'),
  moduleSerialNumber: yup.array().when(['moduleBrand', 'isManualSerialPanelNotMatchDb'], {
    is: (value, isManualSerialPanelNotMatchDb )  => value === 'Q CELLS' && !isManualSerialPanelNotMatchDb,
    then: yup.array().when('isSerialNumber', { is: true, then: yup.array().min(1, 'Serial Number is required') })
  }),
  moduleModel : yup.string().when(['moduleBrand', 'isSerialNumber', 'moduleSerialNumber'], {
    is: (moduleBrand, isSerialNumber, moduleSerialNumber) =>

      ['select','Q CELLS'].includes(moduleBrand) ?
      (moduleBrand === 'Q CELLS' && !isSerialNumber) || (isSerialNumber && moduleSerialNumber.length === 0) : false,
    then: yup.string().notOneOf(['select', ''], 'module Model is required')
  }),
  numberOfModules : yup.string().when(['moduleBrand', 'isSerialNumber', 'moduleSerialNumber'], {
    is: (moduleBrand, isSerialNumber, moduleSerialNumber) =>
      ['select','other'].includes(moduleBrand) ||
      (moduleBrand === 'Q CELLS' && !isSerialNumber) || (isSerialNumber && moduleSerialNumber.length === 0),
    then: yup.string().required("numberOfModules is required")
  }),
  //modulePowerClass -> volume
  modulePowerClass : yup.string().when(['moduleBrand', 'isSerialNumber', 'moduleSerialNumber', 'moduleModel'], {
    is: (moduleBrand, isSerialNumber, moduleSerialNumber, moduleModel) =>
      ['select','other'].includes(moduleBrand) ||
      (moduleBrand === 'Q CELLS' && !isSerialNumber) || (isSerialNumber && moduleSerialNumber.length === 0),
    then: yup.string().test('len', 'Must be exactly 3 characters', val => val.toString().length === 3).required("Power Class is required")
  }),

  nameOfModuleBrandPanelOther : yup.string().when(['moduleBrand'], {
    is : (moduleBrand) => moduleBrand === 'Other',
    then : yup.string().required('nameOfModuleBrandPanelOther is required')
  }),

  // numberOfModules: yup.number().when(['moduleBrand', 'isSerialNumber', 'isManualSerialNumber'], {
  //   is: (moduleBrand, isSerialNumber, isManualSerialNumber) =>
  //     ['select', 'other'].includes(moduleBrand) ||
  //     (moduleBrand === 'qcells' && isSerialNumber && isManualSerialNumber) ||
  //     (moduleBrand === 'qcells' && !isSerialNumber),
  //   then: yup.number().required("Number of modules is required")
  // }),
  //systemSize:  '',


  //battery, batteryProductType -> batteryBrand, modelBattery -> batteryModel, batteryInverter1 -> Battery Energy (kWh), batteryInverter2
  batteryBrand : yup.string().when('battery', { is: true, then: yup.string().notOneOf(['select'], 'Battery product brand is required') }),
  batteryModel : yup.string().when(['battery', 'isEssSerialNumber', 'essSerialNumber'], {
    is : (battery, isEssSerialNumber, essSerialNumber) => battery && (!isEssSerialNumber || (isEssSerialNumber && essSerialNumber.length === 0)),
    then : yup.string().notOneOf(['select'], 'Battery Model is required'),
  }),

  batteryEnergy : yup.string().when(['battery', 'systemType', 'batteryBrand'], {
    is: (battery, systemType, batteryBrand) => battery && ['AC', 'DC'].includes(systemType) && batteryBrand === 'Q CELLS',
    then: yup.string().notOneOf(['select'], 'Battery Energy is required')
  }),
  batteryInverterPower : yup.string().when(['battery', 'systemType', 'batteryBrand','isEssSerialNumber', 'essSerialNumber'], {
    is: (battery, systemType, batteryBrand, isEssSerialNumber, essSerialNumber) => battery && ['AC', 'DC'].includes(systemType) && batteryBrand === 'Q CELLS' && (!isEssSerialNumber || (isEssSerialNumber && essSerialNumber.length === 0)),
    then: yup.string().notOneOf(['select'], 'Battery inverter is required')
  }),
  nameOfModuleBrandBatteryOther : yup.string().when(['batteryBrand'], {
    is : (batteryBrand) => batteryBrand === 'Other',
    then : yup.string().required('nameOfModuleBrandBatteryOther is required')
  }),
  systemType: yup.string().when('battery', { is: true, then: yup.string().notOneOf(['select'], 'System type is required') }),


  // powerClass,
  // volume: yup.number().when(['moduleBrand', 'isSerialNumber', 'isManualSerialNumber'], {
  //   is: (moduleBrand, isSerialNumber, isManualSerialNumber) =>
  //     ['select', 'other'].includes(moduleBrand) ||
  //     (moduleBrand === 'qcells' && isSerialNumber && isManualSerialNumber) ||
  //     (moduleBrand === 'qcells' && !isSerialNumber),
  //   then: yup.number().required("Power Class is required").test('len', 'Must be exactly 3 characters', val => val.toString().length === 3)
  // }),
  // productModel,
  // productName: yup.string().when(['moduleBrand', 'isSerialNumber', 'isManualSerialNumber'], {
  //   is: (moduleBrand, isSerialNumber, isManualSerialNumber) =>
  //     ['select', 'other'].includes(moduleBrand) ||
  //     (moduleBrand === 'qcells' && isSerialNumber && isManualSerialNumber) ||
  //     (moduleBrand === 'qcells' && !isSerialNumber),
  //   then: yup.string().required("Product name is required")
  // }),
  // numberOfPanels,
  // numberOfModules: yup.number().when(['moduleBrand', 'isSerialNumber', 'isManualSerialNumber'], {
  //   is: (moduleBrand, isSerialNumber, isManualSerialNumber) =>
  //     ['select', 'other'].includes(moduleBrand) ||
  //     (moduleBrand === 'qcells' && isSerialNumber && isManualSerialNumber) ||
  //     (moduleBrand === 'qcells' && !isSerialNumber),
  //   then: yup.number().required("Number of modules is required")
  // }),

  //battery
  //batteryProductType: yup.string().when('battery', { is: true, then: yup.string().notOneOf(['select'], 'Battery product brand is required') }),
  // essSerialNumber: yup.array().when('batteryProductType', {
  //     is: value => value === 'qcells',
  //     then: yup.array().when('isSerialNumberBattery', { is: true, then: yup.array().min(1, 'Serial Number is required') })
  // }),
  essSerialNumber: yup.array().when(['battery', 'systemType', 'batteryBrand', 'isEssSerialNumber', 'isManualSerialBatteryNotMatchDb'], {
    is: (battery, systemType, batteryBrand, isEssSerialNumber, isManualSerialBatteryNotMatchDb) => {
      return battery && (systemType === 'DC' || systemType === 'AC') && batteryBrand === 'Q CELLS' && isEssSerialNumber && !isManualSerialBatteryNotMatchDb;
    },
    then: yup.array().min(1, 'Inverter Serial Number is required')
  }),
  nameOfChanelOtherDistributor : yup.string().when(['nameOfPurchasingChannelDistributor'], {
    is : (nameOfPurchasingChannelDistributor) => nameOfPurchasingChannelDistributor !== null && nameOfPurchasingChannelDistributor?.name === 'Other',
    then : yup.string().required('nameOfChanelOtherDistributor is required')
  }),
  //model
  // inverterProductName1: yup.string().when(['battery', 'systemType', 'batteryProductType', 'isEssSerialNumber', 'isManualEssSerialNumber'], {
  //   is: (battery, systemType, batteryProductType, isEssSerialNumber, isManualEssSerialNumber) => {
  //     return battery && systemType === 'DC' && batteryProductType === 'qcells' && isEssSerialNumber && isManualEssSerialNumber;
  //   },
  //   then: yup.string().notOneOf(['select'], 'Product Name is required')
  // }),
  // inverterSize: yup.number().when(['battery', 'systemType', 'batteryProductType', 'isEssSerialNumber', 'isManualEssSerialNumber'], {
  //   is: (battery, systemType, batteryProductType, isEssSerialNumber, isManualEssSerialNumber) => {
  //     return battery && systemType === 'DC' && batteryProductType === 'qcells' && isEssSerialNumber && isManualEssSerialNumber;
  //   },
  //   then: yup.number().required("Inverter Size is required")
  // }),
  //model
  // batteryProductName1: yup.string().when(['battery', 'systemType', 'batteryProductType'], {
  //   is: (battery, systemType, batteryProductType) => battery && (!['AC', 'DC'].includes(systemType) || batteryProductType !== 'qcells'),
  //   then: yup.string().required('Battery product name is required')
  // }),
  //Battery Inverte Power
  // batteryInverter1: yup.string().when(['battery', 'systemType', 'batteryProductType'], {
  //   is: (battery, systemType, batteryProductType) => battery && ['AC', 'DC'].includes(systemType) && batteryProductType === 'qcells',
  //   then: yup.string().notOneOf(['select'], 'Battery inverter is required')
  // }),
  // batteryInverter2: yup.string().when(['battery', 'systemType', 'batteryProductType'], {
  //   is: (battery, systemType, batteryProductType) => battery && (!['AC', 'DC'].includes(systemType) || batteryProductType !== 'qcells'),
  //   then: yup.string().required('Battery inverter is required')
  // }),
  //Battery Energy
  // batteryCapacity1: yup.string().when(['battery', 'systemType', 'batteryProductType'], {
  //   is: (battery, systemType, batteryProductType) => battery && ['AC', 'DC'].includes(systemType) && batteryProductType === 'qcells',
  //   then: yup.string().notOneOf(['select'], 'Battery capacity is required')
  // }),
  // batteryCapacity2: yup.string().when(['battery', 'systemType', 'batteryProductType'], {
  //   is: (battery, systemType, batteryProductType) => battery && (!['AC', 'DC'].includes(systemType) || batteryProductType !== 'qcells'),
  //   then: yup.string().required('Battery capacity is required')
  // }),

  purchasingChannel: yup.string().notOneOf(['select'], 'Purchasing channel is required'),
  nameOfPurchasingChannelDistributor: yup.object().nullable().when('purchasingChannel', { is: value => value === 'distributor', then: yup.object().nullable().notOneOf([null], 'Name of purchasing channel is required') }),
  nameOfPurchasingChannelOther: yup.string().when('purchasingChannel', { is: value => value === 'other', then: yup.string().required('Name of purchasing channel is required') }),
  //nameOfPurchasingChannelSelect: yup.string().when('purchasingChannel', { is: value => value === 'select', then: yup.string().required('Please select Purchasing Channel') }),
  nameOfPurchasingChannelSelect: yup.object().nullable().when('purchasingChannel', { is: value => value === 'select', then: yup.object().nullable().notOneOf([null], 'Name of purchasing channel is required') }),

  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().required('Email is required').matches(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/, 'Invalid email address'),
  phoneNumber: yup.string().required("Contact number is required").matches(/^[0-9]*$/, "This entry can only contain numbers (e.g., 9497774444)."),

});
