import React, { useState, useEffect } from 'react';
import { useLocation, Redirect, useHistory } from 'react-router-dom';
import axios from '../../utils/axios';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useSSOLoginForm from './hooks/useSSOLoginForm';
import { baseUrl } from '../../config';
import { toast } from 'react-toastify';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ForgotForm from './forms/ForgotForm';
import QcellsLogo from '../../icons/login1.png';
import QcellsIcon from '../../icons/login2.png';
import { notiSuccess, notiFailed } from './../../utils/helpers.js';
import './Login.css';
import _ from 'lodash';
import Text from './../../common/components/text';
import CustomLoading from './../../common/components/CustomLoading';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import CustomButton from '../../common/components/button';

function Copyright() {
  const classes = useStyles();

  return (
    <Typography variant="body2" align="center" class={classes.copyright}>
      {/* {'Copyright © '} */}
      <Link color="inherit" href="#">
        {/* Hanwha Q CELLS {new Date().getFullYear()} */}
        <img src={QcellsIcon} alt="" className="Login_Icon" />
      </Link>
    </Typography>
  );
}

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#36ADEF'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#36ADEF'
    },
    '& .MuiOutlinedInput-root': {
      // '& fieldset': {
      //   borderColor: '#36ADEF',
      // },
      // '&:hover fieldset': {
      //   borderColor: '#36ADEF',
      // },
      '&.Mui-focused fieldset': {
        borderColor: '#36ADEF'
      }
    }
  }
})(TextField);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: '50px 0px'
  },
  modal : {
    overflow : 'scroll'
  },
  image: {
    width: '550px',
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    width: '500px',
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    // margin: '28px',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    paddingRight: '15px',
    paddingLeft: '15px'
  },
  submit: {
    backgroundColor: '#009FE3',
    color: '#FFF',
    margin: theme.spacing(3, 0, 2),
    width: '200px',
    height: '47px',
    borderRadius: '30px'
  },
  logo: {
    color: '#009ce2',
    textTransform: 'uppercase',
    fontWeight: 500
  },
  copyright: {
    height: '50px',
    textAlign: 'center',
    lineHeight: '50px',
    backgroundColor: '#8FCDEE',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headline: {
    fontSize: '28px',
    fontWeight: 500
  },
  modalPaper: {
    position: 'absolute',
    width: 800,
    height: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 8, 4),
  },
}));

  const terms = [
    {
      title: '1. Purpose',
      contents: [
        {
          number: '1.1',
          name: 'Hanwha Q CELLS America Inc. or one of its affiliates (“Company,” “us,” or “we”) is the provider of this website as shown in [www.qcellspartner.us] and any other dependent pages that you may be able to access from this Website (collectively, the “Website”). We created these terms of use (the “Terms of Use”) to permit and regulate the use of this Website and any services and functions related thereto. By accessing this Website, Users (defined below) are bound by the terms and conditions set forth. If you do not agree to be bound by the terms and conditions of these Terms of Use, please do not use this Website.',
          subjects: []
        },
        {
          number: '1.2',
          name: 'Unless stated otherwise in a written agreement between the Company and the User, these Terms of Use prevail over any other agreement with respect to any content, materials, or services available from this Website.',
          subjects: []
        }
      ]
    },
    {
      title: '2. Users',
      contents: [
        {
          number: '2.1',
          name: 'This Website may be used by you in an individual capacity or as a representative of a business entity (“Users,” or “you”). This Website is available only to individuals of the age of majority who can form legally binding contracts under applicable law and your use of this Website is your representation that you satisfy this requirement.',
          subjects: []
        }
      ]
    },
    {
      title: '3. Prohibited Uses',
      contents: [
        {
          number: '3.1',
          name: 'Users agree that you will not use this Website:',
          subjects: [
            '- (a) in a manner that is prohibited by any applicable law or regulation, or to facilitate the violation of any applicable law or regulation;',
            '- (b) to invade the privacy of others;',
            '- (c) to violate, plagiarize, or infringe on the intellectual property or contractual rights of any person in connection with any use of this Website; and',
            '- (d) to transmit unlawful, discriminatory or otherwise objectionable material, or to encourage conduct that would give rise to civil liability of any manners.'
          ],
        },
        {
          number: '3.2',
          name: 'Users agree that Users will not,',
          subjects: [
            '- (a) violate, or assist in violating, the security of this Website, whether intentionally, negligently or otherwise;',
            '- (b) impersonate Company personnel or other persons or entities;',
            '- (c) use any electronic mail message, device, software or programming routine that may impair, interfere or impose an unreasonable burden on this Website;',
            '- (d) intercept or expropriate any system, data or information of this Website;',
            '- (e) resell, assign or transfer, in whole or in part, User’s right to use this Website; or',
            '- (f) collect, use, process, sell or disclose any personal or other information of any individual other than in strict compliance with all applicable law and the consents obtained by Users directly from such individual.'
          ],
        },
        {
          number: '3.3',
          name: 'Users agree that the Company may have access to your account and records as reasonably necessary to investigate complaints and maintain this Website.',
          subjects: []
        }
      ]
    },
    {
      title: '4. Limitation of Liability',
      contents: [
        {
          number: '4.1',
          name: 'The use of this Website is at the User’s own discretion and risk. All content on the Website is provided on an “as is” and “as available” basis.',
          subjects: [],
        },
        {
          number: '4.2',
          name: 'To the fullest extent permissible pursuant to applicable law, the Company disclaims all conditions and warranties, express or implied, including, but not limited to, all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement. The Company does not warrant or make any representations regarding the operation or use of the results of the use of the material on the Website in terms of its correctness, accuracy, reliability, effectiveness, or otherwise. The material on this Website or the operation of this Website could include technical inaccuracies or typographical errors, may not be free or viruses or other harmful components, or free of defects or errors and could be inaccurate or become inaccurate as a result of developments occurring after their respective dates. The Company takes no obligation to verify or maintain the currency of such information, and the defects may not be corrected timely. Accordingly, the Company will not be liable for any damages of any kind arising from the use of this Website, including, but not limited to direct, indirect, incidental, punitive, and consequential damages.',
          subjects: [],
        }
      ]
    },
    {
      title: '5. Terms of Use Updates, Changes, and Modification',
      contents: [
        {
          number: '5.1',
          name: 'The Company has the right to amend this Terms of Use at any time by posting the amended Terms of Use on this Website. The amended Terms of Use will be effective on the date specified in the posting. Your use of this Website following the effectiveness of any notice of amendment will indicate your acceptance of the amended Terms of Use.',
          subjects: [],
        }
      ]
    },
    {
      title: '6. Proprietary Materials',
      contents: [
        {
          number: '6.1',
          name: 'This Website contains trademarks, copyrights and other proprietary information and materials. This Website and all such materials are owned or licensed by the Company. Everything on this Website is copyrighted. Users agree not to reproduce, modify, adapt, publish, create derivative works from, sell, distribute, display or otherwise transmit or exploit any of such proprietary materials without the express written permission of the Company to such materials. Users agree not to remove any copyright, trademark, or other proprietary notice or legend contained on this Website or on any materials obtained through this Website.',
          subjects: [],
        }
      ]
    },
    {
      title: '7. Privacy Policy',
      contents: [
        {
          number: '7.1',
          name: 'Users should review our current Privacy Policy which is incorporated in this Terms of Use by reference. We reserve the right to disclose non-personally identifiable information regarding Users to third parties, and any information required to be disclosed by applicable law to government authorities.',
          subjects: [],
        },
        {
          number: '7.2',
          name: 'Users hereby represent and warrant to the Company that (i) Users have obtained consents to Users’ collection, use, processing, sale and/or disclosure of any personal or other information of any individual directly from such individual, (ii) Users’ collection, use, processing, sale and disclosure of such personal or other information of any individual, including, without limitation, disclosure to the Company through the Website or any other channel, was conducted in strict compliance with all applicable law and the consents obtained by Users directly from such individual and (iii) within two (2) business days following notification by the Company, Users shall provide to the Company any records or documentation reasonably requested by the Company to evidence Users’ compliance with the foregoing representations and warranties or any other requirement contained in these Terms of Use, which documentation may include copies of such individual consents. ',
          subjects: [],  
        }
      ]
    },
    {
      title: '8. Governing Law',
      contents: [
        {
          number: '8.1',
          name: 'Any dispute arising in relation to your access and use of this Website shall be governed by the laws of the State of California, without regard to its conflicts of laws principle. Users and the Company consent to the exclusive jurisdiction of the federal or state courts in Orange County, California for any legal actions arising out of or relating to these Terms of Use.',
          subjects: [],
        }
      ]
    },
  ]

  const ModalContainer = styled.div `
    background-color: #ffffff;
    width: 800px;
    margin: auto; 
    margin-top: 50px;

    @media only screen and (max-width: 800px) {
      width: 500px;
    }
    @media only screen and (max-width: 375px) {
      width: 350px;
    }
  `;

  const ModalBody = styled.div `
    margin: auto;
    height: 600px;
    width: 600px;
    overflow-y: scroll;

    @media only screen and (max-width: 800px) {
      height: 500px;
      width: 450px;
    }
    @media only screen and (max-width: 375px) {
      height: 500px;
      width: 300px;
    }
  `;

export default function SSOLogin() {
  const query = useQuery();
  const classes = useStyles();
  const [openForgotDialog, setOpenForgotDialog] = React.useState(false);
  const [forgotType, setForgotType] = React.useState(0);
  const [toggleTextFieldError, setToggleTextFieldError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputsForgotPassword, setInputsForgotPassword] = useState({
    forgotPasswordEmail: '',
    forgotPasswordName: ''
  });
  const [
    toggleTextFieldForgotPasswordError,
    setToggleTextFieldForgotPasswordError
  ] = useState(false);
  const { forgotPasswordEmail, forgotPasswordName } = inputsForgotPassword;
  const [inputsForgotID, setInputsForgotID] = useState({
    forgotIDName: '',
    forgotIDMobile: ''
  });
  const [toggleTextFieldForgotIDError, setToggleTextFieldForgotIDError] = useState(
    false
  );
  const { forgotIDName, forgotIDMobile } = inputsForgotID;
  const [userID, setUserID] = useState(null);
  const [loadingForgot, setLoadingForgot] = useState(false);
  const history = useHistory();
  const { setAuth } = useAuth();
  const [profileActive, setProfileActive] = useState({})
  const [handleModalOpen, setHandleModalOpen] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const userInfo = JSON.parse(localStorage.getItem('qpartner_user'));

  const handleForgotPasswordInputChange = event => {
    event.persist();
    setInputsForgotPassword(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };

  const handleForgotIDInputChange = event => {
    event.persist();
    setInputsForgotID(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };

  const login = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${baseUrl}/login`, inputs);
      setLoading(false);
      localStorage.setItem('qpartner_user', JSON.stringify(data));
      setAuth(data);
      setProfileActive(data?.user)

      if (_.get(data, 'user.primaryProductType') === null) {
        if ([0, 1].includes(_.get(data, 'user.approvalStep'))) {
          notiSuccess('We have a new sign-up form. Please update the new form to complete the Q.PARTNER registration and get a sign-up bonus of $260 in Q.POINTs!');
        } else {
          notiSuccess('Please update your company profile to get access to the platform.');
        }
        history.push('/mypage/member/edit-profile');
      } else if (data.user.agree1Yn === 'N') {
        setHandleModalOpen(true);
        return;
      } else {
        const redirectUrl = query.get('url') || '/dashboard';
        history.push(redirectUrl);
      }
    } catch (error) {
      if (_.get(error, 'data.message') === 'Email or Password is incorrect') {
        setToggleTextFieldError(true);
      }
      let message = _.get(error, 'data.message') || 'Invalid email address or password';
      notiFailed(message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenForgotDialog = type => () => {
    setForgotType(type);
    setOpenForgotDialog(true);
  };

  const handleCloseForgotDialog = () => {
    setInputsForgotPassword({ forgotPasswordEmail: '', forgotPasswordName: '' });
    setInputsForgotID({ forgotIDName: '', forgotIDMobile: '' });
    setToggleTextFieldForgotPasswordError(false);
    setToggleTextFieldForgotIDError(false);
    setUserID(null);
    setOpenForgotDialog(false);
  };

  const handleChangeType = (event, type) => {
    setForgotType(type);
  };

  const handleConfirmDialog = async () => {
    if (forgotType === 1 && userID) {
      return handleCloseForgotDialog();
    }

    setLoadingForgot(true);
    if (forgotType === 0) {
      // Forgot password
      try {
        const { data } = await axios.post(`${baseUrl}/forgotPassWord`, {
          email: forgotPasswordEmail,
          name: forgotPasswordName
        });
        setLoadingForgot(false);
        handleCloseForgotDialog();
        notiSuccess(
          'A temporary password has been sent to your registered email address. After logging in, please update your password.'
        );
      } catch (error) {
        setToggleTextFieldForgotPasswordError(true);
        setLoadingForgot(false);
        notiFailed(`Invalid email address or name`);
      }
    } else {
      try {
        const { data } = await axios.post(`${baseUrl}/forgotId`, {
          mobileNumber: forgotIDMobile,
          name: forgotIDName
        });
        setUserID(data.email);
        setLoadingForgot(false);
        setToggleTextFieldForgotIDError(false);
        // handleCloseForgotDialog();
      } catch (error) {
        setToggleTextFieldForgotIDError(true);
        setLoadingForgot(false);
        notiFailed(`Invalid name or mobile number`);
      }
    }
  };

  const handleAcceptTerms = async () => {
    setHandleModalOpen(false);

    try {
      const {data} = await axios.patch(`/users/${_.get(profileActive, 'id')}`, {
        firstName: _.get(profileActive, 'firstName'),
        lastName: _.get(profileActive, 'lastName'),
        email: _.get(profileActive, 'email'),
        agree1Yn: 'Y'
      });
      if (data.agree1Yn === 'Y') {
        const redirectUrl = query.get('url') || '/dashboard';
        history.push(redirectUrl);
      }
    } catch(err) {
      console.log(err);
    }
  }

  const handleClose = () => {
    localStorage.removeItem('qpartner_user');
    setHandleModalOpen(false);
    window.location.reload();
  }

  const {
    inputs,
    handleLoginInputChange,
    handleSubmit,
    isValidLoginFormInputs
  } = useSSOLoginForm(login);

  // useEffect(() => {
  //   async function getUserProfile() {
  //     const url = query.get('url');

  //     try {
  //       const qpartner_user = JSON.parse(localStorage.getItem('qpartner_user'));
  //       const { token } = qpartner_user;
  //       const { data } = await axios.get(`${baseUrl}/users/profile`, {
  //         params: { token }
  //       });

  //       if (data && url) {
  //         window.location = `${url}?token=${token}`;
  //       }
  //     } catch (err) {
  //       // localStorage.removeItem('qpartner_user');
  //     }
  //   }

  //   getUserProfile();
  // }, []);
  useEffect(() => {
    Object.defineProperty(document, 'referrer', {
      configurable: true,
      get: function() {
        return '';
      }
    });
  }, []);

  // let qpartner_user = JSON.parse(localStorage.getItem('qpartner_user'));
  // if (!_.isEmpty(qpartner_user)) {
  //   return <Redirect to="/dashboard"></Redirect>;
  // }

  return (
    <>
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item component={Paper} elevation={6} square className="Login_Wrapper">
        <div className="Login">
          <div style={{ marginBottom: 15 }}>
            <img
              src={QcellsLogo}
              alt="QCELLS Logo"
              // style={{ height: 110, width: 'auto' }}
              className="Login_Logo"
            />
          </div>
          <Typography component="h1" variant="h5" className="Login_Headline">
            Welcome to your <span class={classes.logo}>Q.Partner</span> Portal
          </Typography>
          <Typography
            component="subtitle1"
            style={{ color: '#000000' }}
            className="Login_SubHeadline"
          >
            Register your products, track your forecasts, earn rewards.
          </Typography>
          <div className="thinkific_login">
            *For the <span className="primaryLight">training portal</span> visitors,
            please click{' '}
            <a href={`${process.env.REACT_APP_THINKIFIC_URL}/users/sign_in`}>here</a>
          </div>

          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <CustomTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              // label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                )
              }}
              placeholder="E-mail address"
              error={toggleTextFieldError}
              onChange={handleLoginInputChange}
              helperText={toggleTextFieldError ? 'Email is incorrect' : ''}
            />
            <CustomTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              // label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                )
              }}
              placeholder="Password"
              error={toggleTextFieldError}
              onChange={handleLoginInputChange}
              helperText={toggleTextFieldError ? 'Password is incorrect' : ''}
            />
            <Box display="flex" justifyContent="flex-end">
              <Box mr={2}>
                <Link
                  variant="body2"
                  style={{
                    color: '#666666',
                    cursor: 'pointer',
                    textDecoration: 'underline'
                  }}
                  onClick={handleOpenForgotDialog(0)}
                >
                  {'Forgot my Password'}
                </Link>
              </Box>
              <Box>
                <Link
                  variant="body2"
                  style={{
                    color: '#666666',
                    cursor: 'pointer',
                    textDecoration: 'underline'
                  }}
                  onClick={handleOpenForgotDialog(1)}
                >
                  {'Forgot my ID'}
                </Link>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={`${classes.submit} login_btn_1 Login_Button`}
                disabled={!isValidLoginFormInputs() || loading}
              >
                {loading ? (
                  <CustomLoading color="#00adef" size={16}></CustomLoading>
                ) : (
                  'Login'
                )}
              </Button>
            </Box>
            <Grid container>
              <Grid item xs>
                <Box
                  display="flex"
                  justifyContent="center"
                  mt={2}
                  style={{ color: '#666666' }}
                >
                  Don't have an account?{' '}
                  <RouterLink
                    to='/register'
                    variant="body2"
                    style={{
                      color: '#666666',
                      cursor: 'pointer',
                      marginLeft: '2px',
                      textDecoration: 'underline'
                    }}
                  >
                    {'Become a Q.PARTNER'}
                  </RouterLink>
                </Box>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box>
          {/* <Copyright /> */}
        </Box>
      </Grid>
      {/* <Grid item xs={false} className={classes.image} /> */}
      <ForgotForm
        userID={userID}
        loadingForgot={loadingForgot}
        open={openForgotDialog}
        handleClose={handleCloseForgotDialog}
        handleConfirm={handleConfirmDialog}
        handleChange={handleChangeType}
        type={forgotType}
        forgotPasswordEmail={forgotPasswordEmail}
        forgotPasswordName={forgotPasswordName}
        toggleTextFieldForgotPasswordError={toggleTextFieldForgotPasswordError}
        handleForgotPasswordInputChange={handleForgotPasswordInputChange}
        toggleTextFieldForgotIDError={toggleTextFieldForgotIDError}
        forgotIDName={forgotIDName}
        forgotIDMobile={forgotIDMobile}
        handleForgotIDInputChange={handleForgotIDInputChange}
      />
    </Grid>
    <Modal
      className={classes.modal}
      open={handleModalOpen}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onClose={handleClose}
    >
      <ModalContainer>
        <Text size="h3" color="primaryLight" style={{paddingTop: '30px', paddingBottom: '10px', textAlign: 'center'}}>TERMS OF USE AGREEMENT</Text>
        <Text size="h4" color="dark" style={{marginBottom: '-5px', textAlign: 'center'}}>We have a new Terms of Use Agreement.</Text>
        <Text size="h4" color="dark" style={{textAlign: 'center', paddingBottom: '20px'}}>Please accept to continue using the Q.PARTNER portal.</Text>
        <ModalBody>
        {terms.map(term => {
          return (
            <>
              <Text size="h4" weight='bold' style={{color: '#666666', marginTop: '20px'}}>
                {term.title}
              </Text>
              {term.contents.map(content => (
                <Grid
                className={'container-term'}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                  <Grid item md={0} lg={0} xs={0} >
                    <Text style={{color: '#666666', marginRight: '10px'}}>
                      {content.number}
                    </Text>
                  </Grid>
                  <Grid item xs={11} md={11} lg={11}>
                    <Text style={{color: '#666666'}}>
                      {content.name}<br></br>
                      {content.subjects.length > 0 ? content.subjects.map(subject => {
                        return (
                          <>
                            {subject}<br></br>
                          </>
                        )
                      }) : false}
                    </Text>
                  </Grid>
              </Grid>
              ))}
            </>
          )
        })}
        </ModalBody>
        <div style={{width: 400, margin: 'auto', paddingTop: '40px'}}>
          <div style={{width: 200, margin: 'auto', paddingBottom: '30px'}}>
            <CustomButton
              onClick={() => handleAcceptTerms()}
            > ACCEPT </CustomButton>
          </div>
        {/* <CustomButton
          style={{marginRight: '10px', marginLeft: '10px'}}
          color='secondary'
          onClick={() => setHandleModalOpen(false)}
        > DECLINE </CustomButton> */}
        </div>
      </ModalContainer>
  </Modal>
  </>
  );
}
