import React from 'react';

export const APIContext = React.createContext(null);

export const APIProvider = ({ children, client }) => {
  return <APIContext.Provider value={client}>{children}</APIContext.Provider>;
};

export const useAPIClient = () => {
  const context = React.useContext(APIContext);

  if (!context) {
    throw new Error('APIContext missing');
  }

  return context;
};
