import React, { useState, useMemo, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core';
// import Accordion from '@material-ui/core/ExpansionPanel';
// import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
// import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import useStyles from './useStyles';
import CustomButton from '../../../common/components/button';
import _ from 'lodash';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { ExcelRenderer } from 'react-excel-renderer';
import axios from '../../../utils/axios';
import config from '../../../config';
import { notiFailed } from '../../../utils/helpers.js';
import CustomLoading from '../../../common/components/CustomLoading';
import NumberFormat from 'react-number-format';
import SwitchButton from './SwitchButton';
import TextButton from '../../../common/components/button/TextButton';
import ProjectAccordion from '../../../config/images/project_accordion.png';
import ProjectAccordionExpand from '../../../config/images/project_accordion_expand.png';
import CheckValidate from '../../../common/components/CheckValidate';
import {useHistory} from 'react-router-dom';
import FormUploadSerialNumberNotMatchDbPanel from './FormUploadSerialNumberNotMatchDbPanel';
import FormUploadSerialNumberNotMatchDbBattery from './FormUploadSerialNumberNotMatchDbBattery';
import ShowMessage from '../../claim/components/ShowMessage';
import PopOver from '../../../config/images/popover_icon.svg';
import NotiSerialNumber from './NotiSerialNumber';

function PanelsNumberFormat(props) {
  return <NumberFormat {...props} allowNegative={false} />;
}

const { baseUrl } = config;
// const batteryInverterPower = [
//   { name: '6kW', value: '6' },
//   { name: '7kW', value: '7' },
//   { name: '7.6kW', value: '7.6' },
//   { name: '8.6kW', value: '8.6' }
// ]

export default function SystemInfo({
  expandAccordion,
  handleChangeAccordion,
  essSerialNumberFile,
  setEssSerialNumberFile,
  productModels,
  essProductModels,
  next,
  goBack,
  register,
  control,
  errors,
  watch,
  setValue,
  serialNumberFile,
  setSerialNumberFile,
  batteryCapacities,
  isEnablePreviewButton,
  handleGoToReview,
  handleSaveDraft,
  loadingSaveDraft,
  clearErrors,
  isEditCompleteProject = false,
  loading = false,
  handleSubmit,
  pvInverterBrands,
  pvInverterModels,
  batteryInverterPower,
  powerClassesModules,
  modelModules,
  brandsPanel,
  brandsBattery,
  batteryModels
}) {
  const classes = useStyles();
  const history = useHistory();
  const [loadingSerialNumber, setLoadingSerialNumber] = useState(false);
  const [loadingEssSerialNumber, setLoadingEssSerialNumber] = useState(false);

  const moduleBrand = watch('moduleBrand');
  const volume = watch('modulePowerClass');
  const moduleModel = watch('moduleModel');
  const isSerialNumber = watch('isSerialNumber');
  const isManualSerialNumber = watch('isManualSerialNumber');
  const moduleSerialNumber = watch('moduleSerialNumber');
  const numberOfModules = watch('numberOfModules');
  const systemType = watch('systemType');
  const isManualSerialBatteryNotMatchDb = watch('isManualSerialBatteryNotMatchDb');
  const isManualSerialPanelNotMatchDb = watch('isManualSerialPanelNotMatchDb');

  const battery = watch('battery');
  const batteryBrand = watch('batteryBrand');
  const batteryModel = watch('batteryModel');
  const isEssSerialNumber = watch('isEssSerialNumber');
  const essSerialNumber = watch('essSerialNumber');
  const isManualEssSerialNumber = watch('isManualEssSerialNumber');

  const pvInverterBrand = watch('pvInverterBrand');

  const rackingBrand = watch('rackingBrand');
  const isValidModulePanel = watch('isValidModulePanel')
  const isDuplicateModulePanel = watch('isDuplicateModulePanel')
  const isDuplicateModulePanelFile = watch('isDuplicateModulePanelFile')

  const isValidModuleBattery = watch('isValidModuleBattery')
  const isDuplicateModuleBattery = watch('isDuplicateModuleBattery')
  const isDuplicateModuleBatteryFile = watch('isDuplicateModuleBatteryFile')


  const isDuplicateAddress = watch('isDuplicateAddress');

  const panelQualified = watch('panelQualified')
  const batteryQualified = watch('batteryQualified')

  const moduleSerialNumberTableData = useMemo(() => {
    // const uniqModuleSerialNumber = _.remove(
    //   _.uniqBy(moduleSerialNumber, 'productName'),
    //   p => p.productName
    // );

    // if(uniqModuleSerialNumber?.length > 0) {
    //   setValue('panelQualified', _.get(uniqModuleSerialNumber, '[0].qualified', false))
    //
    // }

    // return {
    //   productModel: _.get(uniqModuleSerialNumber, '[0].productName', ''),
    //   powerClass: _.get(uniqModuleSerialNumber, '[0].volume', ''),
    //   numberOfPanets: moduleSerialNumber.length,
    //   systemSize:
    //     moduleSerialNumber.length * _.get(uniqModuleSerialNumber, '[0].volume')
    // };
    return {
      productModel: moduleModel,
      powerClass: volume,
      numberOfPanels: moduleSerialNumber.length,
      systemSize: moduleSerialNumber.length * volume
    };
  }, [moduleSerialNumber]);

  const essSerialNumberTableData = useMemo(() => {
    const uniqModuleSerialNumber = _.remove(
      _.uniqBy(essSerialNumber, 'productName'),
      p => p.productName
    );


    // if(uniqModuleSerialNumber?.length > 0) {
    //   setValue('batteryQualified', _.get(uniqModuleSerialNumber, '[0].qualified', false))
    // }


    return {
      productModel: _.get(uniqModuleSerialNumber, '[0].productName', ''),
      inverterSize: 300,
      powerClass: _.get(uniqModuleSerialNumber, '[0].volume')
    };
  }, [essSerialNumber]);

  useEffect(() => {
   
  }, [isEssSerialNumber, isManualEssSerialNumber])

  // useEffect(() => {
  //   if (battery && systemType === 'DC' && batteryBrand === 'Q CELLS') {
  //     setValue('pvInverterBrand', batteryBrand)
  //     setValue('pvInverterModel', batteryModel )
  //   }
  //   if (battery && systemType === 'DC' && batteryBrand !== 'Q CELLS') {
  //     setValue('pvInverterBrand', 'select')
  //     setValue('pvInverterModel', 'select' )
  //   }
  // }, [batteryModel, systemType])

  useEffect(() => {
  }, [batteryModels])
  
  const isNoneQualify = useMemo(() => {
    if(moduleModel !== 'select' && moduleModel !== "" && moduleModel !== null) {
      const infoModule = modelModules.filter(item => (item?.productName === moduleModel))
      if(infoModule?.length > 0 && moduleBrand === 'Q CELLS') {
        setValue('panelQualified',infoModule[0]?.qualified || false)
      }else {
        setValue('panelQualified', false)
      }

      return infoModule[0]?.qualified
    }
    return true
  }, [moduleModel, moduleBrand, modelModules])

  const isPanelNoneQcells = useMemo(() => {
    if(moduleBrand !== 'Q CELLS') {
      setValue('panelNonQcells', true)
    }else {
      setValue('panelNonQcells', false)
    }
  }, [moduleBrand])

  const isBatteryNoneQcells = useMemo(() => {
    if(batteryBrand !== 'Q CELLS') {
      setValue('batteryNonQcells', true)
    }else {
      setValue('batteryNonQcells', false)
    }
  }, [batteryBrand])


  const isNoneQualifyEss = useMemo(() => {
    if(batteryModel !== 'select' && batteryModel !== "" && batteryModel !== null) {
      const infoModuleEss = essProductModels.filter(item => (item?.productName === batteryModel))
      if(infoModuleEss?.length > 0 && batteryBrand === 'Q CELLS') {
        setValue('batteryQualified', infoModuleEss[0]?.qualified || false)
      }else {
        setValue('batteryQualified', false)
      }

      return infoModuleEss[0]?.qualified
    }
    return true
  }, [batteryModel, batteryBrand, essProductModels])

  useEffect(() => {

  }, [isSerialNumber, isManualSerialNumber])

  useEffect(() => {
    if(moduleSerialNumber.length > 0 && moduleModel !== 'select' && moduleModel !== '') {
      moduleSerialNumber.forEach(m => m.productName = moduleModel)
      setValue('moduleSerialNumber', moduleSerialNumber)
    }
  }, [moduleModel])
  const validateSerialNumber = serialNumber => {
    return /(^(AH|AF|QH|QA|BF)(0600|0700|0760|0860)([A-Z])([1-9]|[A-C])(00[1-9]|0[1-9][0-9]|[1-9][0-9][0-9]|[A-Z]0[1-9]|[A-Z][1-9][0-9])(00[1-9]|0[1-9][0-9]|[1-9][0-9][0-9])$)|([0-9]){18}$/.test(serialNumber);
  };

  function renderMessageSNPanel() {
    if(isValidModulePanel && (isDuplicateModulePanelFile || isDuplicateModulePanel)) {
      return <NotiSerialNumber text={'Some of the product serial numbers you uploaded are not recognized as our product and duplicated. Please double-check if the serial numbers are accurate and try again.'}/>
    }

    if(isValidModulePanel) {
      return <NotiSerialNumber text={'Some of the product serial numbers you uploaded are not recognized as our product. Please double-check if the serial numbers are accurate and try again.'}/>
    }
    if(isDuplicateModulePanel || isDuplicateModulePanelFile) {
      return <NotiSerialNumber text={'Some of the product serial numbers you uploaded are duplicated. Please double-check if the serial numbers are accurate and try again.'}/>
    }
  }

  function renderMessageSNBattery() {
    if(isValidModuleBattery && (isDuplicateModuleBattery || isDuplicateModuleBatteryFile)) {
      return <NotiSerialNumber text={'Some of the product serial numbers you uploaded are not recognized as our product and duplicated. Please double-check if the serial numbers are accurate and try again.'}/>
    }
    if(isValidModuleBattery) {
      return <NotiSerialNumber text={'Some of the product serial numbers you uploaded are not recognized as our product. Please double-check if the serial numbers are accurate and try again.'}/>
    }
    if(isDuplicateModuleBattery || isDuplicateModuleBatteryFile) {
      return <NotiSerialNumber text={'Some of the product serial numbers you uploaded are duplicated. Please double-check if the serial numbers are accurate and try again.'}/>
    }
  }

  const readExcelFile = file => {
    return new Promise((resolve, reject) => {
      ExcelRenderer(file, async (err, data) => {
        if (err) {
          return reject(err);
        }

        return resolve(data);
      });
    });
  };

  const handleUploadSerialNumber = async e => {
    setSerialNumberFile(e.target.value);

    if (!_.get(e, 'target.files.length')) {
      return;
    }

    try {
      setLoadingSerialNumber(true);
      const serialNumberData = await readExcelFile(_.get(e, 'target.files[0]'));

      if (serialNumberData.rows.length <= 1) {
        return;
      }

      const firstDataRow = serialNumberData.rows[1];
      let serialNumberRowIndex = 0;
      for (let i = 0; i < firstDataRow.length; i++) {
        if (
          (firstDataRow[i] &&
            String(firstDataRow[i]).replace(/\s/g, '').length === 18) ||
          (firstDataRow[i] &&
            String(firstDataRow[i]).replace(/\s/g, '').length === 14)
        ) {
          serialNumberRowIndex = i;
        }
      }

      const serialNumbers = serialNumberData.rows
        .filter((row, idx) => idx > 0 && row.length && row[serialNumberRowIndex])
        .map(row => String(row[serialNumberRowIndex]).replace(/\s/g, ''))

      const modules = [];
      const invalidModules = [];
      const checkedDuplicates = [];
      const alreadyChecked = [];

      serialNumbers.forEach(serialNumber => {
        if (alreadyChecked.indexOf(serialNumber) > -1) {
          checkedDuplicates.push(serialNumber);
        } else if (serialNumber.length !== 18) {
          invalidModules.push({ serialNumber });
        } else if (
          !validateSerialNumber(serialNumber)
        ) {
          invalidModules.push({ serialNumber });
        } else {
          alreadyChecked.push(serialNumber);
          modules.push({
            serialNumber: serialNumber
          });
        }
      });


      setValue('isValidModulePanel', invalidModules.length > 0)
      setValue('isDuplicateModulePanelFile', checkedDuplicates.length > 0)


      if(invalidModules.length > 0 || checkedDuplicates.length > 0) {
        setValue('moduleSerialNumber', []);
        return
      }

      setValue('moduleSerialNumber', modules)
      // const { data } = await axios.post(
      //   `${baseUrl}/projects/check/serial-numbers`,
      //   serialNumbers
      // );
      //
      //
      // let checkNotMatchDb = [];
      // let checkedDuplicatesProject = []
      // checkedDuplicatesProject = data.filter(item => item.duplicated);
      // checkNotMatchDb = data.filter(item => !item.productName && !item.invalid)
      //
      // setValue('isDuplicateModulePanel', checkedDuplicatesProject.length > 0 )
      //
      //
      // const uniqModuleSerialNumber = _.remove(
      //   _.uniqBy(data, 'productName'),
      //   p => p.productName
      // );
      //
      // if(checkNotMatchDb.length === 0 && checkedDuplicatesProject.length === 0) {
      //   setValue('moduleSerialNumber', data)
      // }else {
      //   setValue('moduleSerialNumber', []);
      // }
      //
      // setValue('isManualSerialPanelNotMatchDb', checkNotMatchDb.length > 0);
      // if (uniqModuleSerialNumber.length > 0) {
      //   setValue('panelQualified', _.get(uniqModuleSerialNumber, '[0].qualified', false))
      //   setValue('isManualSerialNumber', false);
      //   //setValue('isManualSerialPanelNotMatchDb', false);
      //   setValue('modulePowerClass', '');
      //   setValue('moduleModel', 'select');
      //   setValue('numberOfModules', '');
      //   clearErrors('moduleSerialNumber');
      //
      // } else {
      //   //notiFailed(`These products need admin's update. In the meantime, please fill out the following information`)
      //   // setValue('isManualSerialNumber', true);
      //   setValue('panelQualified', false)
      // }
      // if (uniqModuleSerialNumber > 0) {
      //   clearErrors('moduleSerialNumber');
      // }
    } catch (err) {
      notiFailed(`Something happened upload serial number file`);
    } finally {
      setLoadingSerialNumber(false);
    }
  };

  const handleUploadEssSerialNumber = async e => {
    setEssSerialNumberFile(e.target.value);

    if (!_.get(e, 'target.files.length')) {
      return;
    }

    try {
      setLoadingEssSerialNumber(true);
      const serialNumberData = await readExcelFile(_.get(e, 'target.files[0]'));

      if (serialNumberData.rows.length <= 1) {
        return;
      }

      const firstDataRow = serialNumberData.rows[1];
      let serialNumberRowIndex = 0;
      for (let i = 0; i < firstDataRow.length; i++) {
        if (
          (firstDataRow[i] &&
            String(firstDataRow[i]).replace(/\s/g, '').length === 18) ||
          (firstDataRow[i] &&
            String(firstDataRow[i]).replace(/\s/g, '').length === 14)
        ) {
          serialNumberRowIndex = i;
        }
      }

      const serialNumbers = serialNumberData.rows
        .filter((row, idx) => idx > 0 && row.length && row[serialNumberRowIndex])
        .map(row => String(row[serialNumberRowIndex]).replace(/\s/g, ''))

      const modules = [];
      const invalidModules = [];
      const checkedDuplicates = [];
      const alreadyChecked = [];

      serialNumbers.forEach(serialNumber => {
        if (alreadyChecked.indexOf(serialNumber) > -1) {
          checkedDuplicates.push(serialNumber);
        } else if (serialNumber.length !== 14) {
          invalidModules.push({ serialNumber });
        } else if (
          !validateSerialNumber(serialNumber)
        ) {
          invalidModules.push({ serialNumber });
        } else {
          alreadyChecked.push(serialNumber);
          modules.push({
            serialNumber: serialNumber
          });
        }
      });

      // console.table([['invalidModules', invalidModules], ['checkedDuplicates', checkedDuplicates]])


      setValue('isValidModuleBattery', invalidModules.length > 0)
      setValue('isDuplicateModuleBatteryFile', checkedDuplicates.length > 0)


      if(invalidModules.length > 0 || checkedDuplicates.length > 0) {
        setValue('essSerialNumber', []);
        return
      }

      setValue('essSerialNumber', modules);
    //   const { data } = await axios.post(
    //     `${baseUrl}/projects/check/serial-numbers`,
    //     serialNumbers
    //   );
    //
    //   let checkNotMatchDb = [];
    //   let checkedDuplicatesProject = []
    //   checkedDuplicatesProject = data.filter(item => item.duplicated);
    //
    //   checkNotMatchDb = data.filter(item => !item.productName && !item.invalid)
    //   setValue('isDuplicateModuleBattery', checkedDuplicatesProject.length > 0 )
    //
    //   const uniqModuleSerialNumber = _.remove(
    //     _.uniqBy(data, 'productName'),
    //     p => p.productName
    //   );
    //
    //   if(checkNotMatchDb.length === 0 && checkedDuplicatesProject.length === 0) {
    //     setValue('essSerialNumber', data)
    //   }else {
    //     setValue('essSerialNumber', []);
    //   }
    //
    //   setValue('isManualSerialBatteryNotMatchDb', checkNotMatchDb.length > 0 );
    //
    //   setValue('pvInverterModel', essSerialNumberTableData.productModel)
    //   // console.log(uniqModuleSerialNumber, 'sss');
    //   if (uniqModuleSerialNumber.length > 0) {
    //     setValue('isManualEssSerialNumber', false);
    //     setValue('batteryQualified', _.get(uniqModuleSerialNumber, '[0].qualified', false))
    //
    //   } else {
    //     //notiFailed(`These products need admin's update. In the meantime, please fill out the following information`)
    //     // setValue('isManualEssSerialNumber', true);
    //     setValue('batteryQualified', false)
    //     setValue('pvInverterModel', '')
    //
    //
    //   }
    //   if (uniqModuleSerialNumber.length > 0) {
    //     clearErrors('essSerialNumber');
    //   }
    } catch (err) {
      notiFailed(`Something happened upload serial number file`);
    } finally {
      setLoadingEssSerialNumber(false);
    }
  };

  const renderSystemSize = volume !== '' && numberOfModules !== ''
  ? (_.toNumber(volume) * _.toNumber(numberOfModules)) / 1000
  : '';

  return (
    <div>
      <Accordion expanded={expandAccordion.expandPanel} onChange={() => handleChangeAccordion('expandPanel')} className={classes.systemInfoSectionAccordion}>
        <AccordionSummary
          expandIcon={
            expandAccordion.expandPanel ? (
              <img src={ProjectAccordionExpand} />
            ) : (
              <img src={ProjectAccordion} />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p style={{ fontWeight: 500, fontSize: 16 }}>Panel</p>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.sectionContainerSys}>
            <div className={classes.sectionHeaderContainer} style={{ alignItems: 'flex-start', marginTop: '12px' }}>
              <span className={classes.sectionTitle}>
                Brand <span>*</span>
              </span>
            </div>
            <div className={classes.productTypeContainer}>
              <div>
              <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                <Controller
                  name="moduleBrand"
                  control={control}
                  render={({ onChange, value }) => (
                    <select
                      value={value}
                      onChange={(event) => {
                        if(event.target.value === 'Other' || event.target.value !== 'Q CELLS') {
                          setValue('numberOfModules', '')
                          setValue('moduleModel', '')
                          setValue('modulePowerClass', '')
                        }else {
                          setValue('modulePowerClass', 'select')
                          setValue('moduleModel', 'select')
                        }
                        onChange(event.target.value)
                      }}
                      className={classNames([classes.selectField], {
                        [classes.errorSelectField]: errors.moduleBrand
                      })}
                    >
                      <option value={'select'}>Select </option>
                      {brandsPanel?.map(item => (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  )}
                />
                <CheckValidate value={watch('moduleBrand')} error={errors.moduleBrand}/>
                </div>
                {moduleBrand === 'Other' && (
                  <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                    <Controller
                      name="nameOfModuleBrandPanelOther"
                      control={control}
                      as={
                        <TextField
                          placeholder="Brand"
                          fullWidth
                          variant="outlined"
                          className={classes.inputField}
                        />
                      }
                    />
                    <CheckValidate value={watch('nameOfModuleBrandPanelOther')} error={errors.nameOfModuleBrandPanelOther}/>
                  </div>
                )}
              </div>
            </div>
          </div>

          {moduleBrand === 'Q CELLS' && (
            <div className={classes.sectionContainerSys} style={{marginBottom : '-20px'}}>
              <div className={classes.sectionHeaderContainer} style={{ alignItems: 'flex-start', marginTop: '12px' }}>
                <span className={classes.sectionTitle}>
                  Serial Number <span>*</span>
                </span>
              </div>
              <div className={classes.addressSection}>
                <div className={classes.serialNumberContainer}>
                  <div className={classes.serialNumberSwitchContainer}>
                    <span>Without S/N</span>
                    <span className={classes.serialNumberSwitch}>
                      <SwitchButton
                        id="system-info-serial-number"
                        register={register}
                        onChange={(event) => {
                          if(!event.target.checked) {
                            setValue('isManualSerialPanelNotMatchDb', false)
                            setValue('moduleSerialNumber', [])
                            setSerialNumberFile('')
                          }else {
                            setValue('modulePowerClass', '')
                            setValue('moduleModel', 'select');
                            setValue('numberOfModules', '');
                          }
                        }}
                        name="isSerialNumber"
                      />
                    </span>
                    <span>With S/N</span>
                  </div>
                </div>
              </div>

            </div>
          )}

          {moduleBrand === 'Q CELLS' && isSerialNumber && (
            <div className={classes.sectionContainerSys}>
              <div className={classes.sectionHeaderContainer} style={{ alignItems: 'flex-start'}}>
              </div>
              <div className={classes.addressSection} style={{marginBottom: 0}}>
                <div className={classes.serialNumberContainer}>
                  <div className={classes.inputFieldMediumContainer}>
                    <TextField
                      fullWidth
                      disabled
                      variant="outlined"
                      className={classNames([classes.inputField], {
                        [classes.errorInputField]: errors.moduleSerialNumber
                      })}
                      value={serialNumberFile}
                      InputProps={{
                        endAdornment: (
                          <label htmlFor="raised-button-file">
                            <Button
                              variant="raised"
                              component="span"
                              className={classes.chooseFileBtn}
                              disabled={loadingSerialNumber}
                              style={{background: '#000'}}
                            >
                              {loadingSerialNumber ? (
                                <CustomLoading
                                  size={16}
                                  color="#fff"
                                />
                              ) : (
                                'Choose file'
                              )}
                            </Button>
                          </label>
                        )
                      }}
                    />
                    <input
                      name="moduleSerialNumber"
                      accept=".xls,.xlsx"
                      style={{
                        display: 'none'
                      }}
                      id="raised-button-file"
                      type="file"
                      onChange={handleUploadSerialNumber}
                    />
                  </div>
                </div>
                <div>
                  <CheckValidate value={watch('moduleSerialNumber')} error={errors.moduleSerialNumber}/>
                </div>
                <div>
                  <div>
                    <a
                      style={{ textDecoration: 'none' }}
                      href={config.urlTemplateSerialNumber}
                      target="_blank"
                    >
                      <TextButton className={classes.downloadTemplate}>
                        Download Template
                      </TextButton>
                    </a>
                  </div>
                  {renderMessageSNPanel()}
                  {/*{isManualSerialPanelNotMatchDb && <div className={classes.notiBox}>*/}
                  {/*  <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>*/}
                  {/*  <p>*/}
                  {/*    These products will need to be reviewed by Q CELLS. In the meantime, please fill out the information below. Once verified, Product Information fields will be updated.*/}
                  {/*  </p>*/}
                  {/*</div>}*/}
                  {/**
                   related to bubble info when with S/N
                  */}
                  {/*{isSerialNumber && !isDuplicateModulePanel && !isManualSerialPanelNotMatchDb && <div className={classes.notiBox}>*/}
                  {/*  <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>*/}
                  {/*  <p>*/}
                  {/*    /!* By uploading product serial numbers right now,*/}
                  {/*    you will not need to re-register the serial numbers to earn your rewards.*/}
                  {/*    If uploading via web: Use the provided template when uploading your product serial numbers. *!/*/}
                  {/*    Register your product serial number to qualify for rewards. Use the provided template when uploading your products. <br />*/}
                  {/*    *Learn more about products qualifying for rewards &nbsp;*/}
                  {/*    <span style={{textTransform: 'uppercase', cursor: 'pointer'}}>*/}
                  {/*      <em style={{color: '#009EE3', textDecoration: 'underline'}} onClick={() => history.push({*/}
                  {/*        pathname: '/rewards/qpoint-introduction',*/}
                  {/*        state: {*/}
                  {/*          isScrollToQcellsProductsQualify: true*/}
                  {/*        }*/}
                  {/*      })}>HERE</em>*/}
                  {/*    </span>*/}
                  {/*  </p>*/}
                  {/*</div>}*/}
                </div>

              </div>
            </div>
          )}
          {/**
           related to bubble product info when serial uploaded
           */}
          {moduleBrand === 'Q CELLS' && isSerialNumber && (
            // <div className={classes.sectionContainerSys}>
            //   <div className={classes.sectionHeaderContainer} style={{ alignItems: 'flex-start', marginTop: '12px' }}>
            //     <span className={classes.sectionTitle}>
            //
            //     </span>
            //   </div>
            //   <div className={classes.addressSection}>
            //     <div className={classes.serialNumberContainer}>
            //       {isSerialNumber && !watch('isManualSerialPanelNotMatchDb') && (
            //         <>
            //           <div className={classes.inputFieldMediumContainer}>
            //             <div className={classes.serialNumberDataTable} style={{marginTop: 0}}>
            //               <div className={classes.inputFieldDisabled}>
            //                 <h4>Power Class (W)</h4>
            //                 <p>
            //                   <NumberFormat
            //                     value={moduleSerialNumberTableData.powerClass}
            //                     displayType={'text'}
            //                     thousandSeparator={true}
            //                     suffix=" W"
            //                   />
            //                 </p>
            //               </div>
            //               <div className={classes.inputFieldDisabled}>
            //                 <h4>Model</h4>
            //                 <p>{moduleSerialNumberTableData.productModel}</p>
            //               </div>
            //               <div className={classes.inputFieldDisabled}>
            //                 <h4>Number of Panels</h4>
            //                 <p>
            //                   {moduleSerialNumberTableData.numberOfPanets > 0 && (
            //                     <NumberFormat
            //                       value={moduleSerialNumberTableData.numberOfPanets}
            //                       displayType={'text'}
            //                       thousandSeparator={true}
            //                       suffix="pcs"
            //                     />
            //                   )}
            //                 </p>
            //               </div>
            //               <div className={classes.inputFieldDisabled}>
            //                 <h4>System Size (kW)</h4>
            //                 <p>
            //                   <NumberFormat
            //                     value={moduleSerialNumberTableData.systemSize / 1000}
            //                     displayType={'text'}
            //                     thousandSeparator={true}
            //                     suffix=" kW"
            //                   />
            //                 </p>
            //               </div>
            //             </div>
            //           </div>
            //           {moduleSerialNumberTableData.systemSize / 1000 > 25 &&
            //             <ShowMessage textInfo={'System size should be less than or equal to 25kW to qualify for labor reimbursement.'}/>
            //           }
            //           {moduleSerialNumber?.length > 0 && !panelQualified && (
            //             <div className={classes.notiBox}>
            //               <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>
            //               <p>
            //                 Please note that this product does not qualify for labor cost reimbursement. Please visit {' '}
            //                 <span style={{textTransform: 'uppercase'}}>
            //                   <em style={{color: '#009EE3', cursor: 'pointer', textDecoration: 'underline'}}
            //                       onClick={() => history.push({
            //                       pathname: '/claim/how-it-works',
            //                       state: {
            //                         isScrollToLaborReimbursement: true
            //                       }
            //                     })}
            //                   > HOW CLAIM WORKS </em>
            //                 </span>
            //                 {' '} to learn more about labor reimbursement policy.
            //               </p>
            //             </div>
            //           )}
            //         </>
            //       )}
            //
            //       {/*{!isSerialNumber && (*/}
            //       {/*  <div className={classes.notiBox}>*/}
            //       {/*    <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>*/}
            //       {/*    <p>*/}
            //       {/*      /!* You can always submit your product serial numbers at a later time to receive your rewards.*/}
            //       {/*      Please visit: *!/*/}
            //       {/*      Register your product serial number to qualify for rewards. Use the provided template when uploading your products. <br />*/}
            //       {/*      *Learn more about products qualifying for rewards &nbsp;*/}
            //       {/*      <span style={{textTransform: 'uppercase', cursor: 'pointer'}}>*/}
            //       {/*        <em style={{color: '#009EE3', textDecoration: 'underline'}} onClick={() => history.push({*/}
            //       {/*          pathname: '/rewards/qpoint-introduction',*/}
            //       {/*          state: {*/}
            //       {/*            isScrollToQcellsProductsQualify: true*/}
            //       {/*          }*/}
            //       {/*        })}>HERE</em>*/}
            //       {/*      </span>*/}
            //       {/*      /!* to check the products qualifying for rewards *!/*/}
            //       {/*    </p>*/}
            //       {/*  </div>*/}
            //       {/*)}*/}
            //     </div>
            //   </div>
            //
            // </div>
            <div className={classes.sectionContainerSys}>
              <div className={classes.sectionHeaderContainer} style={{ alignItems: 'flex-start', marginTop: '12px' }}>
                <span className={classes.sectionTitle}>
                  Product <br /> Information <span>*</span>
                </span>
              </div>
              <div className={`${classes.productInfoTable} ${classes.serialNumberManualDataTable} ${classes.productInfoDataTable}`}>
                {
                  <div>
                    <h4 className={classes.sectionTitle}>
                      Power Class (W) <span>*</span>
                    </h4>
                    <p>
                      <Controller
                        name="modulePowerClass"
                        control={control}
                        as={
                          <TextField
                            placeholder="Power Class (W)"
                            fullWidth
                            variant="outlined"
                            className={classNames([classes.inputField], {
                              [classes.errorInputField]: errors.modulePowerClass
                            })}
                            InputProps={{
                              inputComponent: PanelsNumberFormat
                            }}
                            inputProps={{
                              maxLength: 3
                            }}
                          />
                        }
                      />
                    </p>
                    <CheckValidate value={watch('modulePowerClass')} error={errors.modulePowerClass}/>
                  </div>
                }

                <div style={{alignItems: 'flex-start'}}>
                  <h4 className={classes.sectionTitle}>
                    Model <span>*</span>
                  </h4>
                  <div>
                    <Controller
                      name="moduleModel"
                      control={control}
                      as={
                        <select
                          placeholder="Model"
                          className={classNames([classes.selectField], {
                            [classes.errorSelectField]: errors.moduleModel
                          })}
                        >
                          <option value={'select'}>Select </option>
                          {modelModules?.map(item => (
                            <option key={item.id} value={item.productName}>
                              {item.productName}
                            </option>
                          ))}
                        </select>
                      }
                    />
                    {isNoneQualify === false && (
                      <div className={classes.notiBox}>
                        <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>
                        <span style={{color: '#4f4f4f'}}>
                          Please note that this product does not qualify for labor cost reimbursement. Please visit {' '}
                          <span style={{textTransform: 'uppercase'}}>
                            <em style={{color: '#009EE3', cursor: 'pointer', textDecoration: 'underline'}} onClick={() => history.push({
                              pathname: '/claim/how-it-works',
                              state: {
                                isScrollToLaborReimbursement: true
                              }
                            })}> HOW CLAIM WORKS </em>
                          </span>
                          {' '} to learn more about labor reimbursement policy.
                        </span>
                      </div>
                    )}
                  </div>
                  <CheckValidate value={watch('moduleModel')} error={errors.moduleModel}/>
                </div>

                <div>
                  <h4 className={classes.sectionTitle}>
                    Number of Panels
                  </h4>
                  <div>
                    <TextField
                      disabled
                      fullWidth
                      variant="outlined"
                      className={`${classes.inputField} ${classes.inputFieldDisabled}`}
                      InputProps={{
                        endAdornment: <span>pcs</span>,
                        inputComponent: PanelsNumberFormat
                      }}
                      value={moduleSerialNumberTableData.numberOfPanels}
                    />
                  </div>
                </div>

                <div style={{alignItems: 'flex-start'}}>
                  <h4 className={classes.sectionTitle}>
                    System Size (kW)
                  </h4>
                  <div>
                    <TextField
                      fullWidth
                      disabled
                      variant="outlined"
                      className={`${classes.inputField} ${classes.inputFieldDisabled}`}
                      // value={
                      //   volume !== '' && numberOfModules !== ''
                      //     ? (_.toNumber(volume) * _.toNumber(numberOfModules)) / 1000
                      //     : ''
                      // }
                      value={moduleSerialNumberTableData.systemSize / 1000}
                      InputProps={{
                        endAdornment: <span>kW</span>,
                        inputComponent: PanelsNumberFormat
                      }}
                    />
                    {moduleSerialNumberTableData.systemSize / 1000 > 25 &&
                      <ShowMessage textInfo={'System size should be less than or equal to 25kW to qualify for labor reimbursement.'}/>
                    }
                  </div>
                </div>
              </div>
            </div>
          )}



          {moduleBrand === 'Q CELLS' && isSerialNumber && watch('isManualSerialPanelNotMatchDb') && (
            <FormUploadSerialNumberNotMatchDbPanel isNoneQualify={isNoneQualify} powerClassesModules={powerClassesModules} errors={errors} watch={watch} control={control}/>

          )}

          {((moduleBrand !== 'Q CELLS') ||
            (moduleBrand === 'Q CELLS' && !isSerialNumber && !watch('isManualSerialPanelNotMatchDb'))) && (
            <div className={classes.sectionContainerSys}>
              <div className={classes.sectionHeaderContainer} style={{ alignItems: 'flex-start', marginTop: '12px' }}>
                <span className={classes.sectionTitle}>
                  Product <br /> Information <span>*</span>
                </span>
              </div>
              <div className={`${classes.productInfoTable} ${classes.serialNumberManualDataTable} ${classes.productInfoDataTable}`}>
                {
                  /**
                   * related to power class select option field
                   */
                  // ['select', 'Q CELLS'].includes(moduleBrand) ?
                  // <div>
                  //   <h4 className={classes.sectionTitle}>
                  //     Power Class (W) <span>*</span>
                  //   </h4>
                  //   <p>
                  //     <Controller
                  //       name="modulePowerClass"
                  //       control={control}
                  //       render={({onChange, value}) => (
                  //         <select
                  //           value={value}
                  //           onChange={(event) => {
                  //             setValue('moduleModel', 'select')
                  //             onChange(event.target.value)
                  //           }}
                  //           className={classNames([classes.selectField], {
                  //             [classes.errorSelectField]: errors.modulePowerClass
                  //           })}
                  //         >
                  //           <option value={'select'}>Select </option>
                  //           {powerClassesModules?.map(item => (
                  //             <option key={item.id} value={item.volume}>
                  //               {item.volume}
                  //             </option>
                  //           ))}
                  //         </select>)
                  //       }
                  //     />
                  //   </p>
                  //   <CheckValidate value={watch('modulePowerClass')} error={errors.modulePowerClass}/>
                  // </div>
                  // :
                  <div>
                    <h4 className={classes.sectionTitle}>
                      Power Class (W) <span>*</span>
                    </h4>
                    <p>
                      <Controller
                        name="modulePowerClass"
                        control={control}
                        as={
                          <TextField
                            placeholder="Power Class (W)"
                            fullWidth
                            variant="outlined"
                            className={classNames([classes.inputField], {
                              [classes.errorInputField]: errors.modulePowerClass
                            })}
                            InputProps={{
                              inputComponent: PanelsNumberFormat
                            }}
                            inputProps={{
                              maxLength: 3
                            }}
                          />
                        }
                      />
                    </p>
                    <CheckValidate value={watch('modulePowerClass')} error={errors.modulePowerClass}/>
                  </div>
                }

                {['Q CELLS', 'select'].includes(moduleBrand) ?
                  <div style={{alignItems: 'flex-start'}}>
                    <h4 className={classes.sectionTitle}>
                      Model <span>*</span>
                    </h4>
                    <div>
                      <Controller
                        name="moduleModel"
                        control={control}
                        as={
                          <select
                            placeholder="Model"
                            className={classNames([classes.selectField], {
                              [classes.errorSelectField]: errors.moduleModel
                            })}
                          >
                            <option value={'select'}>Select </option>
                            {modelModules?.map(item => (
                              <option key={item.id} value={item.productName}>
                                {item.productName}
                              </option>
                            ))}
                          </select>
                        }
                      />
                      {isNoneQualify === false && (
                        <div className={classes.notiBox}>
                          <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>
                          <span style={{color: '#4f4f4f'}}>
                            Please note that this product does not qualify for labor cost reimbursement. Please visit {' '}
                            <span style={{textTransform: 'uppercase'}}>
                              <em style={{color: '#009EE3', cursor: 'pointer', textDecoration: 'underline'}} onClick={() => history.push({
                                pathname: '/claim/how-it-works',
                                state: {
                                  isScrollToLaborReimbursement: true
                                }
                              })}> HOW CLAIM WORKS </em>
                            </span>
                            {' '} to learn more about labor reimbursement policy.
                          </span>
                        </div>
                      )}
                    </div>
                    <CheckValidate value={watch('moduleModel')} error={errors.moduleModel}/>
                  </div>
                :
                  <div style={{alignItems: 'flex-start'}}>
                    <h4 className={classes.sectionTitle}>
                      Model
                    </h4>
                    <p>
                      <Controller
                        name="moduleModel"
                        control={control}
                        as={
                          <TextField
                            placeholder="Model"
                            fullWidth
                            variant="outlined"
                            className={classes.inputField}
                          />
                        }
                      />
                    </p>
                  </div>
                }

                <div>
                  <h4 className={classes.sectionTitle}>
                    Number of Panels <span>*</span>
                  </h4>
                  <p>
                    <Controller
                      name="numberOfModules"
                      control={control}
                      as={
                        <TextField
                          placeholder="Number of Panels"
                          fullWidth
                          variant="outlined"
                          className={classNames([classes.inputField], {
                            [classes.errorInputField]: errors.numberOfModules
                          })}
                          InputProps={{
                            endAdornment: <span>pcs</span>,
                            inputComponent: PanelsNumberFormat
                          }}
                        />
                      }
                    />
                  </p>
                  <CheckValidate value={watch('numberOfModules')} error={errors.numberOfModules}/>
                </div>

                <div style={{alignItems: 'flex-start'}}>
                  <h4 className={classes.sectionTitle}>
                    System Size (kW)
                  </h4>
                  <div>
                    <TextField
                      fullWidth
                      disabled
                      variant="outlined"
                      className={`${classes.inputField} ${classes.inputFieldDisabled}`}
                      // value={
                      //   volume !== '' && numberOfModules !== ''
                      //     ? (_.toNumber(volume) * _.toNumber(numberOfModules)) / 1000
                      //     : ''
                      // }
                      value={renderSystemSize}
                      InputProps={{
                        endAdornment: <span>kW</span>,
                        inputComponent: PanelsNumberFormat
                      }}
                    />
                    {renderSystemSize > 25 && 
                      <ShowMessage textInfo={'System size should be less than or equal to 25kW to qualify for labor reimbursement.'}/>
                    }
                  </div>
                  <CheckValidate value={watch('numberOfModules')} error={errors.numberOfModules}/>
                </div>
              </div>

            </div>
          )}

        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expandAccordion.expandBattery} onChange={() => handleChangeAccordion('expandBattery')} className={classes.systemInfoSectionAccordion}>
        <AccordionSummary
          expandIcon={
            expandAccordion.expandBattery ? (
              <img src={ProjectAccordionExpand} />
            ) : (
              <img src={ProjectAccordion} />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p style={{ fontWeight: 500, fontSize: 16 }}>Battery</p>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.sectionContainerSys}>
            <div className={classes.sectionHeaderContainer} style={{ alignItems: 'flex-start', marginTop: '12px' }}>
              <span className={classes.sectionTitle}>
                Battery <span>*</span>
              </span>
            </div>
            <div className={classes.batteryContainer}>
              <p>Does the system have battery?</p>
              <div className={classes.serialNumberSwitchContainer}>
                <span>No</span>
                <span className={classes.serialNumberSwitch}>
                  <SwitchButton
                    id="system-info-battery"
                    name="battery"
                    register={register}
                  />
                </span>
                <span>Yes</span>
              </div>
              {!battery && (
                <ShowMessage textInfo={'To qualify for labor reimbursement for Q CELLS battery, submitting the information here is mandatory.'}/>

              )}
              {battery && (
                <>
                  <div
                    className={classes.separator}
                    style={{ marginTop: '20px', marginBottom: '22px' }}
                  />
                  <div className={classes.batterySectionContainer}>
                    <div
                      className={classes.sectionHeaderContainer}
                      style={{
                        alignItems: 'flex-start',
                        marginTop: '12px'
                      }}
                    >
                      <span className={classes.sectionTitle}>
                        System Type <span>*</span>
                      </span>
                    </div>
                    <div className={classes.inputFieldMediumContainer}>
                      <Controller
                        name="systemType"
                        control={control}
                        render={({ onChange, value }) => (
                          <select
                            value={value}
                            onChange={(event) => {
                              if(batteryBrand === 'Q CELLS' && event.target.value === 'DC' ) {
                                setValue('batteryModel', '')
                                setValue('pvInverterBrand', 'Q CELLS')
                              }else {
                                setValue('pvInverterBrand', 'select')
                                setValue('pvInverterModel', 'select')
                              }
                              onChange(event.target.value)
                            }}
                            placeholder="System Type"
                            className={classNames([classes.selectField], {
                              [classes.errorSelectField]: errors.systemType
                            })}
                          >
                            <option value={'select'}>Select</option>
                            <option value={'AC'}>AC</option>
                            <option value={'DC'}>DC</option>
                          </select>
                        )}
                      />
                    </div>
                    <CheckValidate value={watch('systemType')} error={errors.systemType}/>
                  </div>
                  <div className={classes.batterySectionContainer}>
                    <div className={classes.sectionHeaderContainer} style={{alignItems: 'flex-start'}}>
                      <span className={classes.sectionTitle}>
                        Brand <span>*</span>
                      </span>
                    </div>
                    <div>
                      <div className={classes.inputFieldMediumContainer}>
                        <Controller
                          name="batteryBrand"
                          control={control}
                          render={({ onChange, value }) => (
                            <select
                              value={value}
                              onChange={(event) => {
                                if(event.target.value === 'Other') {
                                  setValue('batteryModel', '')
                                }
                                if(systemType === 'DC' && event.target.value === 'Q CELLS') {
                                  setValue('batteryModel', '')
                                  setValue('pvInverterBrand', 'Q CELLS')
                                }else {
                                  setValue('pvInverterBrand', 'select')
                                  setValue('pvInverterModel', 'select')
                                }
                                onChange(event.target.value)
                              }}
                              className={classes.selectField}
                            >
                              <option value={'select'}>Select </option>
                              {brandsBattery?.map(item => (
                                <option key={item.id} value={item.name}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          )}
                        />

                      </div>
                    </div>
                    <CheckValidate value={watch('batteryBrand')} error={errors.batteryBrand}/>
                  </div>
                  {batteryBrand === 'Other' && <div className={classes.batterySectionContainer}>
                    <div className={classes.sectionHeaderContainer} style={{alignItems: 'flex-start'}}>

                    </div>
                    <div>
                      <div className={classes.inputFieldMediumContainer}>
                        <Controller
                          name="nameOfModuleBrandBatteryOther"
                          control={control}
                          as={
                            <TextField
                              placeholder="Brand"
                              fullWidth
                              variant="outlined"
                              className={classes.inputField}
                            />
                          }
                        />

                      </div>

                    </div>
                    <CheckValidate value={watch('nameOfModuleBrandBatteryOther')} error={errors.nameOfModuleBrandBatteryOther}/>
                  </div>}

                  {['AC', 'DC'].includes(systemType) &&
                    batteryBrand === 'Q CELLS' && (
                      <div className={classes.batterySectionContainer} style={{marginBottom : '-20px'}}>
                        <div className={classes.sectionHeaderContainer} style={{ alignItems: 'flex-start', marginTop: '12px' }}>
                          <span className={classes.sectionTitle}>
                            Battery Inverter Serial Number <span>*</span>
                          </span>
                        </div>
                        <div>
                          <div className={classes.serialNumberContainer}>
                            <div className={classes.serialNumberSwitchContainer}>
                              <span>Without S/N</span>
                              <span className={classes.serialNumberSwitch}>
                                <SwitchButton
                                  onChange={(event) => {
                                    if(!event.target.checked) {
                                      setValue('isManualSerialBatteryNotMatchDb', false)
                                      setValue('essSerialNumber',[])
                                      setValue('batteryModel', 'select');
                                      setValue('batteryInverterPower', 'select');
                                    }
                                  }}
                                  id="battery-serial-number"
                                  register={register}
                                  name="isEssSerialNumber"
                                />
                              </span>
                              <span>With S/N</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  {['AC', 'DC'].includes(systemType) &&
                  batteryBrand === 'Q CELLS' && (
                    <div className={classes.batterySectionContainer}>
                      <div className={classes.sectionHeaderContainer} style={{ alignItems: 'flex-start', marginTop: '12px' }}>
                          <span className={classes.sectionTitle}>

                          </span>
                      </div>
                      <div>
                        <div className={classes.serialNumberContainer}>
                          {isEssSerialNumber && (
                            <>
                              <div className={classes.inputFieldMediumContainer}>
                                <TextField
                                  fullWidth
                                  disabled
                                  variant="outlined"
                                  className={classNames([classes.inputField], {
                                    [classes.errorInputField]:
                                    errors.moduleSerialNumber
                                  })}
                                  value={essSerialNumberFile}
                                  InputProps={{
                                    endAdornment: (
                                      <label htmlFor="raised-button-file-ess">
                                        <Button
                                          variant="raised"
                                          component="span"
                                          className={classes.chooseFileBtn}
                                          disabled={loadingSerialNumber}
                                          style={{background: '#000'}}
                                        >
                                          {loadingEssSerialNumber ? (
                                            <CustomLoading
                                              size={16}
                                              color="#fff"
                                            />
                                          ) : (
                                            'Choose file'
                                          )}
                                        </Button>
                                      </label>
                                    )
                                  }}
                                />
                                <input
                                  name="essSerialNumber"
                                  accept=".xls,.xlsx"
                                  style={{
                                    display: 'none'
                                  }}
                                  id="raised-button-file-ess"
                                  type="file"
                                  onChange={handleUploadEssSerialNumber}
                                />
                              </div>
                              <div>
                                <a
                                  style={{ textDecoration: 'none' }}
                                  href={config.urlTemplateSerialNumber}
                                  target="_blank"
                                >
                                  <TextButton className={classes.downloadTemplate}>
                                    Download Template
                                  </TextButton>
                                </a>
                              </div>
                              {renderMessageSNBattery()}
                              {/*{*/}
                              {/*  isManualSerialBatteryNotMatchDb && <div className={classes.notiBox}>*/}
                              {/*    <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>*/}
                              {/*    <p>*/}
                              {/*      These products need admin's update. In the meantime, please fill out the following information*/}
                              {/*    </p>*/}
                              {/*  </div>*/}
                              {/*}*/}
                              {/*{isEssSerialNumber && !isDuplicateModuleBattery && !isManualEssSerialNumber && !isManualSerialBatteryNotMatchDb && <div className={classes.notiBox}>*/}
                              {/*  <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>*/}
                              {/*  <p>*/}
                              {/*    /!* By uploading product serial numbers right now,*/}
                              {/*    you will not need to re-register the serial numbers to earn your rewards.*/}
                              {/*    If uploading via web: Use the provided template when uploading your product serial numbers. *!/*/}
                              {/*    Register your product serial number to qualify for rewards. Use the provided template when uploading your products. <br />*/}
                              {/*    *Learn more about products qualifying for rewards &nbsp;*/}
                              {/*    <span style={{textTransform: 'uppercase', cursor: 'pointer'}}>*/}
                              {/*      <em style={{color: '#009EE3', textDecoration: 'underline'}} onClick={() => history.push({*/}
                              {/*        pathname: '/rewards/qpoint-introduction',*/}
                              {/*        state: {*/}
                              {/*          isScrollToQcellsProductsQualify: true*/}
                              {/*        }*/}
                              {/*      })}>HERE*/}
                              {/*      </em>*/}
                              {/*    </span>*/}
                              {/*  </p>*/}
                              {/*</div>}*/}
                            </>
                          )}
                          {/*{isEssSerialNumber && !isManualEssSerialNumber && !isManualSerialBatteryNotMatchDb && (*/}
                          {/*  <>*/}
                          {/*    <div className={classes.inputFieldMediumContainer}>*/}
                          {/*      <div className={classes.serialNumberDataTable}>*/}
                          {/*        <div className={classes.inputFieldDisabled}>*/}
                          {/*          <h4>Model</h4>*/}
                          {/*          <p>{essSerialNumberTableData.productModel}</p>*/}
                          {/*        </div>*/}
                          {/*        <div className={classes.inputFieldDisabled}>*/}
                          {/*          <h4>Battery Inverter Power(kW)</h4>*/}
                          {/*          <p>*/}
                          {/*            <NumberFormat*/}
                          {/*              value={essSerialNumberTableData.powerClass / 1000}*/}
                          {/*              displayType={'text'}*/}
                          {/*              thousandSeparator={true}*/}
                          {/*              suffix="kW"*/}
                          {/*            />*/}
                          {/*          </p>*/}
                          {/*        </div>*/}
                          {/*      </div>*/}
                          {/*    </div>*/}
                          {/*    {essSerialNumber?.length > 0 && !batteryQualified && (*/}
                          {/*      <div className={classes.notiBox}>*/}
                          {/*        <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>*/}
                          {/*        <p>*/}
                          {/*          Please note that this product does not qualify for labor cost reimbursement. Please visit {' '}*/}
                          {/*          <span style={{textTransform: 'uppercase'}}>*/}
                          {/*              <em style={{color: '#009EE3', cursor: 'pointer', textDecoration: 'underline'}} onClick={() => history.push({*/}
                          {/*                pathname: '/claim/how-it-works',*/}
                          {/*                state: {*/}
                          {/*                  isScrollToLaborReimbursement: true*/}
                          {/*                }*/}
                          {/*              })}> HOW CLAIM WORKS </em>*/}
                          {/*            </span>*/}
                          {/*          {' '} to learn more about labor reimbursement policy.*/}
                          {/*        </p>*/}
                          {/*      </div>*/}
                          {/*    )}*/}
                          {/*  </>*/}
                          {/*)}*/}

                          {/*{!isEssSerialNumber && (*/}
                          {/*  <div className={classes.notiBox} style={{marginTop: 0}}>*/}
                          {/*    <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>*/}
                          {/*    <p>*/}
                          {/*      Register your product serial number to qualify for rewards. Use the provided template when uploading your products. <br />*/}
                          {/*      *Learn more about products qualifying for rewards &nbsp;*/}
                          {/*      /!* You can always submit your product serial numbers at a later time to receive your rewards.*/}
                          {/*      Please visit: *!/*/}
                          {/*      <span style={{textTransform: 'uppercase', cursor: 'pointer'}}>*/}
                          {/*          <em style={{color: '#009EE3', textDecoration: 'underline'}} onClick={() => history.push({*/}
                          {/*            pathname: '/rewards/qpoint-introduction',*/}
                          {/*            state: {*/}
                          {/*              isScrollToQcellsProductsQualify: true*/}
                          {/*            }*/}
                          {/*          })}>HERE</em>*/}
                          {/*        </span>*/}
                          {/*      /!* to check the products qualifying for rewards *!/*/}
                          {/*    </p>*/}
                          {/*  </div>*/}
                          {/*)}*/}
                        </div>
                      </div>
                      {isEssSerialNumber && <CheckValidate value={watch('essSerialNumber')} error={errors.essSerialNumber}/>}
                    </div>
                  )}

                  {batteryBrand === 'Q CELLS' && isEssSerialNumber && isManualSerialBatteryNotMatchDb && (
                    <FormUploadSerialNumberNotMatchDbBattery isNoneQualifyEss={isNoneQualifyEss}  batteryInverterPower={batteryInverterPower} essProductModels={essProductModels} errors={errors} watch={watch} control={control}/>
                  )}
                  {((batteryBrand !== 'Q CELLS' || batteryBrand === 'select') ||
                  // (batteryBrand === 'Q CELLS' &&
                  // isEssSerialNumber &&
                  // isManualEssSerialNumber) ||
                  // (batteryBrand === 'Q CELLS' && !isEssSerialNumber))
                  (batteryBrand === 'Q CELLS'))
                    && (
                  <div>
                    {batteryBrand === 'Other' ?
                      <div className={classes.batterySectionContainer}>
                        <div className={classes.sectionHeaderContainer} style={{alignItems: 'flex-start'}}>
                          <span className={classes.sectionTitle}>
                            Model <span>*</span>
                          </span>
                        </div>
                        <div className={classes.inputFieldMediumContainer}>
                          <Controller
                            name="batteryModel"
                            control={control}
                            as={
                              <TextField
                                placeholder="Model"
                                fullWidth
                                variant="outlined"
                                className={classNames([classes.inputField], {
                                  [classes.errorInputField]: errors.batteryModel
                                })}
                              />
                            }
                          />
                        </div>
                        <CheckValidate value={watch('batteryModel')} error={errors.batteryModel}/>
                      </div>
                    :
                    <>
                      {batteryBrand === 'Q CELLS' ?
                        <div className={classes.batterySectionContainer}>
                          <div className={classes.sectionHeaderContainer} style={{alignItems: 'flex-start'}}>
                            <span className={classes.sectionTitle}>
                              Model <span>*</span>
                            </span>
                          </div>
                          <div className={classes.inputFieldMediumContainer}>
                            <Controller
                              name="batteryModel"
                              control={control}
                              render={({onChange, value}) => (
                                <select
                                  value={value}
                                  // placeholder="Battery Capacity"
                                  className={classNames([classes.selectField], {
                                    [classes.errorSelectField]: errors.batteryModel
                                  })}
                                  onChange={(event) => {
                                    if(systemType === 'DC' && pvInverterBrand === 'Q CELLS') {
                                      setValue('pvInverterModel', event.target.value)
                                    }
                                    // setValue('batteryModel', event.target.value)
                                    onChange(event.target.value)
                                  }}
                                >
                                  <option value={'select'}>Select </option>
                                  {essProductModels?.map(item => (
                                    <option key={item.id} value={item.productName}>
                                      {item.productName}
                                    </option>
                                  ))}
                                </select>
                              )}
                            />
                            {!isNoneQualifyEss && (
                              <div className={classes.notiBox}>
                                <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>
                                <p>
                                Please note that this product does not qualify for labor cost reimbursement. Please visit {' '}
                                  <span style={{textTransform: 'uppercase'}}>
                                    <em style={{color: '#009EE3', cursor: 'pointer', textDecoration: 'underline'}} onClick={() => history.push({
                                      pathname: '/claim/how-it-works',
                                      state: {
                                        isScrollToLaborReimbursement: true
                                      }
                                    })}> HOW CLAIM WORKS </em>
                                  </span>
                                  {' '} to learn more about labor reimbursement policy.
                                </p>
                              </div>
                            )}
                          </div>
                          <CheckValidate value={watch('batteryModel')} error={errors.batteryModel}/>
                        </div>
                        :
                        <div className={classes.batterySectionContainer}>
                          <div className={classes.sectionHeaderContainer} style={{alignItems: 'flex-start'}}>
                            <span className={classes.sectionTitle}>
                              Model <span>*</span>
                            </span>
                          </div>
                          <div className={classes.inputFieldMediumContainer}>
                            <Controller
                              name="batteryModel"
                              control={control}
                              as={
                                <select
                                  // placeholder="Battery Capacity"
                                  className={classNames([classes.selectField], {
                                    [classes.errorSelectField]: errors.batteryModel
                                  })}
                                >
                                  <option value={'select'}>Select </option>
                                  {batteryModels?.length > 0 && batteryModels.map(item => (
                                    <option key={item.id} value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              }
                            />
                          </div>
                          <CheckValidate value={watch('batteryModel')} error={errors.batteryModel}/>
                        </div>
                      }
                    </>
                    }

                    {['AC', 'DC'].includes(systemType) && ['select', 'Q CELLS'].includes(batteryBrand) && (
                    // batteryBrand !== 'other' && (
                      <div className={classes.batterySectionContainer}>
                        <div className={classes.sectionHeaderContainer}>
                          <span className={classes.sectionTitle}>
                            Battery Inverter <br />
                            Power(kW) <span>*</span>
                          </span>
                        </div>
                        <div className={classes.inputFieldMediumContainer}>
                          {['AC', 'DC'].includes(systemType) &&
                          batteryBrand === 'Q CELLS' ? (
                            <Controller
                              name="batteryInverterPower"
                              control={control}
                              as={
                                <select
                                  placeholder="Battery Capacity"
                                  className={classNames([classes.selectField], {
                                    [classes.errorSelectField]: errors.batteryInverterPower
                                  })}
                                >
                                  <option value={'select'}>Select </option>
                                  {batteryInverterPower?.map(item => (
                                    <option key={item.id} value={Number(item.volume)/1000}>
                                      {Number(item.volume)/1000} {' '} kW
                                    </option>
                                  ))}
                                </select>
                              }
                            />
                          ) : (
                            <Controller
                              name="batteryInverter2"
                              control={control}
                              as={
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  className={classNames([classes.inputField], [classes.inputFieldDisabled], {
                                    [classes.errorInputField]: errors.batteryInverter2
                                  })}
                                  inputRef={register}
                                  disabled
                                  InputProps={{
                                    inputComponent: PanelsNumberFormat
                                  }}
                                />
                              }
                            />
                          )}

                        </div>
                        <CheckValidate value={watch('batteryInverterPower')} error={errors.batteryInverterPower}/>
                      </div>
                    )}
                  </div>
                  )}
                  {['select', 'Q CELLS'].includes(batteryBrand) && (
                    <div className={classes.batterySectionContainer}>
                      <div className={classes.sectionHeaderContainer}>
                        <span className={classes.sectionTitle}>
                          Battery <br />
                          Energy (kWh) <span>*</span>
                        </span>
                      </div>
                      <div className={classes.inputFieldMediumContainer}>
                        {['AC', 'DC'].includes(systemType) ? (
                          <Controller
                            name="batteryEnergy"
                            control={control}
                            as={
                              <select
                                placeholder="Battery Capacity"
                                className={classNames([classes.selectField], {
                                  [classes.errorSelectField]: errors.batteryEnergy
                                })}
                              >
                                <option value={'select'}>Select </option>
                                {batteryCapacities?.map(bc => (
                                  <option key={bc.id} value={bc.value}>
                                    {`${bc.value} kWh`}
                                  </option>
                                ))}
                              </select>
                            }
                          />
                        ) : (
                          <Controller
                            name="batteryCapacity2"
                            control={control}
                            as={
                              <TextField
                                fullWidth
                                variant="outlined"
                                className={classNames([classes.inputField], {
                                  [classes.errorInputField]: errors.batteryCapacity2
                                })}
                                inputRef={register}
                                InputProps={{
                                  inputComponent: PanelsNumberFormat
                                }}
                              />
                            }
                          />
                        )}
                      </div>
                      <CheckValidate value={watch('batteryEnergy')} error={errors.batteryEnergy}/>
                    </div>
                  )}
                  <div
                    className={classes.separator}
                    style={{ marginTop: '22px', marginBottom: 0 }}
                  />
                </>
              )}
            </div>
            {/*{isEssSerialNumber && <div className={classes.batteryContainer}>*/}
            {/*  <div style={{marginTop : 120}}>*/}
            {/*    <div style={{height : 44}} className={classes.batterySectionContainer}>*/}
            {/*      <CheckValidate value={watch('systemType')} error={errors.systemType}/>*/}
            {/*    </div>*/}
            {/*    <div style={{height : 44}} className={classes.batterySectionContainer}>*/}
            {/*      <CheckValidate value={watch('batteryBrand')} error={errors.batteryBrand}/>*/}
            {/*    </div>*/}
            {/*    <div style={{height : 44}} className={classes.batterySectionContainer}>*/}
            {/*      <CheckValidate value={watch('batteryModel')} error={errors.batteryModel}/>*/}
            {/*    </div>*/}
            {/*    <div style={{height : 44}} className={classes.batterySectionContainer}>*/}
            {/*      <CheckValidate value={watch('batteryInverterPower')} error={errors.batteryInverterPower}/>*/}
            {/*    </div>*/}
            {/*  </div>*/}

            {/*</div>}*/}

          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expandAccordion.expandInverter} onChange={() => handleChangeAccordion('expandInverter')} className={classes.systemInfoSectionAccordion}>
        <AccordionSummary
          expandIcon={
            expandAccordion.expandInverter ? (
              <img src={ProjectAccordionExpand} />
            ) : (
              <img src={ProjectAccordion} />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p style={{ fontWeight: 500, fontSize: 16 }}>PV Inverter</p>
        </AccordionSummary>
        <AccordionDetails>
          {/* {(!battery || (battery && systemType === 'select')) && ( */}
          <>
            <div className={classes.sectionContainerSys}>
              <div className={classes.sectionHeaderContainer} style={{alignItems: 'flex-start'}}>
                <span className={classes.sectionTitle}>Brand</span>
              </div>
              {battery && systemType === 'DC' && batteryBrand === 'Q CELLS' ? 
                <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                  <TextField
                    name="pvInverterBrand"
                    control={control}
                    fullWidth
                    inputRef={register}
                    disabled
                    variant="outlined"
                    className={`${classes.inputField} ${classes.inputFieldDisabled}`}
                    value={'Q CELLS'}
                  />
                </div> :
                <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                  <Controller
                    name="pvInverterBrand"
                    control={control}
                    render={({ onChange, value }) => (
                      <select
                        value={value}
                        onChange={(event) => {
                          if(event.target.value === 'Other') {
                            setValue('pvInverterModel', '')
                          }else {
                            setValue('nameRackingBrandOther', '')
                          }
                          if(event.target.value === 'select') {
                            setValue('pvInverterModel', 'select')
                          }
                          onChange(event.target.value)
                        }}
                        className={classNames([classes.selectField], {
                          //[classes.errorSelectField]: errors.batteryCapacity1
                        })}
                      >
                        <option value={'select'}>Select </option>
                        {pvInverterBrands?.length > 0 && pvInverterBrands?.map(item => (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  <div />
                  {pvInverterBrand === 'Other' && (
                    <Controller
                      name="namePvInverterBrandOther"
                      control={control}
                      style={{marginTop: 22}}
                      as={
                        <TextField
                          placeholder="Brand"
                          fullWidth
                          variant="outlined"
                          className={classes.inputField}
                        />
                      }
                    />
                  )}
                </div> 
              }
            </div>
            {battery && systemType === 'DC' && batteryBrand === 'Q CELLS' ? 
            <div className={classes.sectionContainerSys}>
              <div className={classes.sectionHeaderContainer}>
                <span className={classes.sectionTitle}>Model</span>
              </div>
              <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                <TextField
                  name="pvInverterModel"
                  control={control}
                  fullWidth
                  inputRef={register}
                  disabled
                  variant="outlined"
                  className={`${classes.inputField} ${classes.inputFieldDisabled}`}
                  value={isEssSerialNumber && !isManualEssSerialNumber ? essSerialNumberTableData.productModel : batteryModel === 'select' ? '' : batteryModel}
                />
                </div>
              </div>
            :
              pvInverterBrand !== 'Other' ?
              <div className={classes.sectionContainerSys}>
                <div className={classes.sectionHeaderContainer}>
                  <span className={classes.sectionTitle}>Model</span>
                </div>
                <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                  <Controller
                    name="pvInverterModel"
                    control={control}
                    as={
                      <select
                        className={classNames([classes.selectField], {
                          //[classes.errorSelectField]: errors.batteryCapacity1
                        })}
                      >
                        <option value={'select'}>Select </option>
                        {pvInverterModels?.length > 0 && pvInverterModels.map(item => (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    }
                  />
                  <div />
                  
                </div>
              </div>
            :
              <div className={classes.sectionContainerSys}>
                <div className={classes.sectionHeaderContainer}>
                  <span className={classes.sectionTitle}>Model</span>
                </div>
                <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                  <Controller
                    name="pvInverterModel"
                    control={control}
                    as={
                      <TextField
                        placeholder="Model"
                        fullWidth
                        variant="outlined"
                        className={classes.inputField}
                      />
                    }
                  />
                </div>
            </div>
          }
          </>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expandAccordion.expandRacking} onChange={() => handleChangeAccordion('expandRacking')} className={classes.systemInfoSectionAccordion}>
        <AccordionSummary
          expandIcon={
            expandAccordion.expandRacking ? (
              <img src={ProjectAccordionExpand} />
            ) : (
              <img src={ProjectAccordion} />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p style={{ fontWeight: 500, fontSize: 16 }}>Racking</p>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.sectionContainerSys}>
            <div className={classes.sectionHeaderContainer} style={{alignItems: 'flex-start'}}>
              <span className={classes.sectionTitle}>Brand</span>
            </div>
            <div>
              <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                <Controller
                  name="rackingBrand"
                  control={control}
                  as={
                    <select
                      // placeholder="Battery Capacity"
                      className={classNames([classes.selectField], {
                        //[classes.errorSelectField]: errors.batteryCapacity1
                      })}
                    >
                      <option value={'select'}>Select </option>
                      <option value={'SnapNrack'}>SnapNrack</option>
                      <option value={'Unirac'}>Unirac</option>
                      <option value={'Ironridge'}>Ironridge</option>
                      <option value={'other'}>Other</option>
                    </select>
                  }
                />
                <div />
                {rackingBrand === 'other' && (
                  <Controller
                    name="nameRackingBrandOther"
                    control={control}
                    style={{marginTop: 22}}
                    as={
                      <TextField
                        placeholder="Brand"
                        fullWidth
                        variant="outlined"
                        className={classes.inputField}
                      />
                    }
                  />
                )}
              </div>
            <div />
          </div>
          </div>

          <div className={classes.sectionContainerSys}>
            <div className={classes.sectionHeaderContainer}>
              <span className={classes.sectionTitle}>Model</span>
            </div>
            <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
              <Controller
                  name="rackingModel"
                  control={control}
                  as={
                    <TextField
                      placeholder="Model"
                      fullWidth
                      variant="outlined"
                      className={classes.inputField}
                    />
                  }
                />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <p className={classes.requiredText}>* Required</p>
      <div
        className={classes.separator}
        style={{ marginTop: '10px', marginBottom: '35px' }}
      />
      {isEditCompleteProject ? (
        <Box
          display="flex"
          justifyContent="flex-end"
          className={classes.navigationBtnContainer}
        >
          <CustomButton className={`${isDuplicateAddress ? 'saveDraftBtnReview' : ''}`} onClick={handleSubmit} disabled={isDuplicateAddress}>
            {loading ? (
              <CustomLoading size={16} color="#ffffff"></CustomLoading>
            ) : (
              'Save'
            )}
          </CustomButton>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.navigationBtnContainer}
        >
          {isEnablePreviewButton ? (
            <CustomButton
              color="secondary"
              className="saveDraftBtn"
              onClick={handleGoToReview}
            >
              Back to Review
            </CustomButton>
          ) : (
            <CustomButton
              color="secondary"
              className="saveDraftBtn"
              onClick={goBack}
            >
              Back
            </CustomButton>
          )}
          <div>
            <CustomButton
              color="secondary"
              className="saveDraftBtn"
              onClick={handleSaveDraft}
              disabled={loadingSaveDraft}
            >
              {loadingSaveDraft ? (
                <CustomLoading size={16} color="#009EE3"></CustomLoading>
              ) : (
                'Save Draft'
              )}
            </CustomButton>
            <CustomButton onClick={next}>Next</CustomButton>
          </div>
        </Box>
      )}
    </div>
  );
}
