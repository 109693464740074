import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Grid, Modal, Paper, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Text from '../../common/components/text';
import CustomButton from '../../common/components/button';

const useStyles = makeStyles(theme => ({
  modal: {
      width: '40rem',
      height: '50%',
      top: '30px',
      margin: 'auto',
      "& .MuiPaper-root": {
          overflowX: 'hidden'
      },
      [theme.breakpoints.down('md')]: {
          width: '98%',
          overflowY: 'auto'
      }
  },
  header: {
      fontSize: '40px',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px !important'
      }
  }
}));

const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
}) => {
  const classes = useStyles();
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  }; 

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname, lastLocation.state);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal onClose={closeModal} open={modalVisible} className={classes.modal}>
            <Paper>
                <Grid container justify="flex-end">
                    <IconButton onClick={closeModal}>
                        <CloseIcon fontSize="default" />
                    </IconButton>
                </Grid>
                <Text
                    style={{ textAlign: 'center', paddingBottom: '30px' }}
                    color="dark"
                    size="h3"
                    className={classes.header}
                >
                    Changes you made may not be saved.
                </Text>

                <Grid
                    container
                    justify="center"
                    style={{ paddingBottom: '50px' }}
                >
                    <Grid item style={{ marginRight: '10px' }}>
                        <CustomButton
                            color="secondary"
                            onClick={closeModal}
                        >
                          Stay on Page
                        </CustomButton>
                    </Grid>
                    <Grid item>
                        <CustomButton
                            color="primary"
                            onClick={handleConfirmNavigationClick}
                        >
                          Proceed
                        </CustomButton>
                    </Grid>
                </Grid>
            </Paper>
      </Modal>
    </>
  );
};

export default RouteLeavingGuard;