import React from 'react';
import styled from 'styled-components';
import Text from '../text';

const SmallText = styled.span`
  font-size: calc(${({ size, theme }) => theme.fontSizes[size]} - 5px);
`;

const Price = ({
  children,
  currency = 'Q',
  size = 'h5',
  color = 'secondaryDark',
  currencyClassName,
  className,
  ...props
}) => (
  <Text className={className} color={color} size={size} weight="medium" {...props}>
    {Number(children).toLocaleString()}{' '}
    {currency}
    {/*<Text className={className} color={color} size={size} {...props}>*/}
    {/*  */}
    {/*</Text>*/}
  </Text>
);

export default Price;
