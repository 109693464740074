import React from 'react';
import './index.css';
function CardMobileHomePage({title, content, url}) {
  return(
    <div className={'wrap-card-mb-home'}>
      <div className="title-mb-card">
        {title}
      </div>
      <div className="content-mb-card">
        <div className="text-content-mb">
          {content}
        </div>
        <img className={'img-mb-card'} src={url} alt={'logo'}/>
      </div>
    </div>
  )
}

export default CardMobileHomePage;
