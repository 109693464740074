import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {Paper, Tabs, Tab} from '@material-ui/core';

const Image = css`
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: ${props => props.backgroundPosition};
  background-size: ${props => props.backgroundSize};
  background-repeat: no-repeat;
  opacity: 1;
  z-index: -1;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  height: ${props => props.type==='form' ? '145px' : '360px'};
  flex-direction: column;
  justify-content: center;
  align-items: ${props => props.type==='form' ? 'flex-start' : 'center'};
  // width: ${props => props.type==='form' ? '90%' : '100%'};
  padding: ${props => props.type ==='form' && '80px'};
  margin: auto;
  /* height: ${({ height }) => height}px; */
  color: ${({ theme }) => theme.colors.light};
  background-color: ${props => props.status==='finish' ? '#E2F3FA' : 'rgba(0, 0, 0, `${({ scrimOpacity }) => scrimOpacity}`)'};
  z-index: 1;
  &::after {
    ${Image}
  }
  h1 {
    text-transform: ${props => props.type==='form' ? '145px' : '360px'};;
  }
  @media only screen and (max-width: 480px) {
    padding: ${props => props.type ==='form' && '15px'};
  }
`;

const TabsContainer = styled.div `
  width: 80%;
  height: 60px;
  background: #fff;
  position: absolute;
  top: 301px;
  left: 50%;
  transform: translate(-50%, 0);

  @media only screen and (max-width: 1200px) {
    width: 95vw;
    height: 50%;
    top: 328px;
  }
  @media only screen and (max-width: 600px) {
    width: 95vw;
    height: 50%;
    top: 147px;
  }
`;

const DataHubTabs = styled.div `
  border-bottom: solid #009DDD;
  width: 50vw;
  text-align: center;
  font-size: 16px;
  text-transform: none;
  padding: 14px; 
  color: #009DDD;
  background-color: #fff;
`;

const FaqTabsContainer = styled.div `
  width: 80%;
  height: 60px;
  background: #fff;
  position: absolute;
  top: 301px;
  left: 50%;
  transform: translate(-50%, 0);

  @media only screen and (max-width: 1200px) {
    width: 90vw;
    /* height: 50%; */
    top: 328px;
  }
  @media only screen and (max-width: 600px) {
    width: 95vw;
    height: 50%;
    top: 147px;
  }
`;

const StyledTab = styled.div `
  font-family: Actual, TradeGothic, "Malgun Gothic", "맑은 고딕", "Apple Gothic", arial, helvetica, sans-serif;
  text-align: center;
  width: 50vw;
  height: 60px;
  border-bottom: ${props => props.theme.active === props.theme.currentTab ? '2px solid #009DDD' : '1px solid #eeeeee'};
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  color: ${props => props.theme.active === props.theme.currentTab ? '#009ee3' : '#cccccc' };
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
   
  :not(:first-child):before {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 1px;
    height: 17px;
    background: red;
    content: "";
    background: #E0DFE0;
  }

  :hover {
    color: ${props => props.theme.active === props.theme.currentTab ? '#009DDD' : '#000'};
  }

  /* :after {
    display: block;
    content: '';
    border-bottom: ${props => props.theme.active === props.theme.currentTab ? false : 'solid 1px #000'};  
    transform: scaleX(0);  
    padding-top: 22px;
    transition: transform .5s ease-in-out;
  } */

  :hover:after {
    transform: scaleX(1.1);
  }

  @media only screen and (max-width: 1200px) {
    padding: 5px;
    font-size: 10px;
  }

  @media only screen and (max-width: 600px) {
    padding: 5px;
    font-size: 8px;
  }
`;

// const RequiredSymbol = styled.span `
//   position: absolute;
//   bottom: 23px;
//   width: 1px;
//   height: 17px;
//   background: red;
//   content: "";
//   z-index: 990;
//   background: #E0DFE0;
// `;

const Banner = ({
  style,
  className,
  imageUrl,
  // height = 360,
  height,
  backgroundSize = 'cover',
  backgroundPosition = 'center',
  scrimOpacity = 0.8,
  children,
  tabs,
  tabsFaq,
  handleClick,
  activeTab,
  type,
  status,
}) => {
  const [width, setWindowWidth] = useState(0);

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () =>
      window.removeEventListener('resize',updateDimensions);
   }, [])
   const updateDimensions = () => {
     const width = window.innerWidth
     setWindowWidth(width)
   }

  return (
  <Container
    type={type}
    style={style}
    height={height}
    className={className}
    imageUrl={imageUrl}
    backgroundSize={backgroundSize}
    backgroundPosition={backgroundPosition}
    scrimOpacity={scrimOpacity}
    status={status}
  >
    {children}
    {tabs && width > 480 ?
    <TabsContainer>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        aria-label="disabled tabs example"
        variant="fullWidth"
        centered
      >
        <StyledTab theme={{active: activeTab, currentTab: 'download-center'}} onClick={() => handleClick('download-center')}>Download Center</StyledTab>
        <StyledTab theme={{active: activeTab, currentTab: 'video-content'}} onClick={() => handleClick('video-content')}>Video Content</StyledTab>
      </Tabs>
    </TabsContainer> : false}

    {tabsFaq && width > 375 ?
      <FaqTabsContainer>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
          variant="fullWidth"
          centered
        >
          <StyledTab theme={{active: activeTab, currentTab: '1'}} onClick={() => handleClick('1')}>
            {/* <RequiredSymbol style={{left: '239px'}}/> */}
            ACCOUNT
          </StyledTab>
          {/*<StyledTab theme={{active: activeTab, currentTab: '2'}} onClick={() => handleClick('2')}>*/}
          {/*  /!* <RequiredSymbol style={{left: '239px'}}/> *!/*/}
          {/*  Q.POINT*/}
          {/*</StyledTab>*/}
          {/*<StyledTab theme={{active: activeTab, currentTab: '3'}} onClick={() => handleClick('3')}>*/}
          {/*  /!* <RequiredSymbol style={{left: '480px'}}/> *!/*/}
          {/*  REWARDS*/}
          {/*</StyledTab>*/}
          {/*<StyledTab theme={{active: activeTab, currentTab: '4'}} onClick={() => handleClick('4')}>*/}
          {/*  /!* <RequiredSymbol style={{left: '720px'}}/> *!/*/}
          {/*  PRODUCT ORDER*/}
          {/*</StyledTab>*/}
          <StyledTab theme={{active: activeTab, currentTab: '5'}} onClick={() => handleClick('5')}>
            {/* <RequiredSymbol style={{left: '960px'}}/> */}
            TECHNICAL SUPPORT
          </StyledTab>
        </Tabs>
      </FaqTabsContainer>
      : false
    }

  </Container>
)};

Banner.propTypes = {
  imageUrl: PropTypes.string,
  height: PropTypes.number,
  backgroundSize: PropTypes.string,
  backgroundPosition: PropTypes.string,
  type: PropTypes.string,
};

export default Banner;
