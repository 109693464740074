import ReactExport from 'react-data-export';
import React from 'react';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export const ExcelCustomComponent = props => {
  const { filename, data, id, isBorder } = props;

  const formatDataTemplate = () => {
    if (!data.length) return [
      {
        columns: [],
        data
      }
    ];
    return [
      {
        columns: Object.keys(data[0]).map(key => ({ title: key })),
        data: data.map(item => {
          if (isBorder) {
            return Object.keys(item).map(key => ({
              value: item[key] || item[key] === 0 ? item[key] + '' : '',
              style: {
                border: {
                  top: { style: 'thin', color: '#000000' },
                  right: { style: 'thin', color: '#000000' },
                  bottom: { style: 'thin', color: '#000000' },
                  left: { style: 'thin', color: '#000000' }
                }
              }
            }));
          }

          return Object.keys(item).map(key => ({
            value: item[key] || item[key] === 0 ? item[key] + '' : ''
          }));
        })
      }
    ];
  };
  return (
    <span style={{ display: 'none' }}>
      <ExcelFile
        filename={filename}
        element={<div id={id}/>}
      >
        <ExcelSheet name={filename} dataSet={formatDataTemplate()} />
      </ExcelFile>
    </span>

  );
};
