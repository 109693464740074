import React, { useState } from 'react';
import useStyles from './useStyles';
import Moment from 'react-moment';
import _ from 'lodash';
import IconEdit from '../../../icons/edit_icon.svg';
import { Button, Grid, IconButton, Modal, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Text from '../../../common/components/text';
import CustomButton from '../../../common/components/button';

function ViewProjectDetailPanel({project, handleSaveDraft}) {
  const classes = useStyles();
  const [modalVisible, setModalVisible] = useState(false);

  function renderProductName(productName, serialNumbers) {
    if(productName !== 'select' && productName !== '' && productName !== null) {
      return productName
    }else {
      const moduleSN = _.filter(_.get(project, 'serialNumbers'), s => s.type === 'modules');
      return  moduleSN?.length > 0 ? moduleSN[0]?.productName : ''
    }
  }

  function renderPowerClass() {
    if(project?.modulePowerClass !== 'select' && project?.modulePowerClass !== '' && project?.modulePowerClass !== null) {
      return project?.modulePowerClass || ''
    }else {
      return project?.serialNumbers?.length > 0 && project?.serialNumbers[0].volume
    }
  }

  const renderAddress = (array = []) => {
    const newArray = _.remove(array, value => !!value || value === 'select');
    return newArray.length > 0 ? _.join(newArray, ', ') : '';
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return(
    <div className={classes.wrapUser}>
      <div className={classes.reviewSectionContainerCLaimInfoDetail}>
        <div className={classes.reviewSectionContentProjectDetailInfo}>
          <h6>Project Details</h6>
        </div>
        <div>

            <Button onClick={() => setModalVisible(true)} className={classes.editBtn}>
              <span>Edit</span>
              <img src={IconEdit} title='Edit'/>
            </Button>

        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentProjectDetail}>
          <h6>Project Name</h6>
          <p>
            {project?.projectName}
          </p>
        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentProjectDetail}>
          <h6>Address</h6>
          <p>{renderAddress([project?.projectAddress, project?.projectAddress2, project?.projectCity, project?.projectState, project?.projectZipCode])}</p>
        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentProjectDetail}>
          <h6>Installation Date</h6>
          <p>{project?.installationDate && <Moment format="L">{project.installationDate}</Moment>}</p>
        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentProjectDetail}>
          <h6>Permission-to Operate Date</h6>
          <p>{project?.operateDate && <Moment format="L">{project?.operateDate}</Moment>}</p>
        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentProjectDetail}>
          <h6> System Size (kW)</h6>
          <p>{project.systemSize} kW</p>
        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentProjectDetail}>
          <h6>Product Name</h6>
          <p>{renderProductName(project?.moduleModel, project?.serialNumbers)}</p>
        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentProjectDetail}>
          <h6>Power Class of Module</h6>
          <p>{renderPowerClass()}</p>
        </div>
      </div>
      <Modal onClose={closeModal} open={modalVisible} className={classes.modal}>
        <Paper>
          <Grid container justify="flex-end">
            <IconButton onClick={closeModal}>
              <CloseIcon fontSize="default" />
            </IconButton>
          </Grid>
          <Text
            style={{ textAlign: 'center', paddingBottom: '30px' }}
            color="dark"
            size="h3"
            className={classes.header}
          >
            This will direct to the Project Details page. Your claim submission will be automatically saved.
          </Text>

          <Grid
            container
            justify="center"
            style={{ paddingBottom: '50px' }}
          >
            <Grid item style={{ marginRight: '10px' }}>
              <CustomButton
                color="secondary"
                onClick={closeModal}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item>
              <a onClick={() => {
                setModalVisible(false)
                handleSaveDraft()}} target={'_blank'} href={`/projects/${project.id}/0`}>
              <CustomButton
                color="primary"
              >
                Proceed
              </CustomButton>
              </a>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </div>
  )
}

export default ViewProjectDetailPanel;
