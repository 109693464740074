import { makeStyles } from '@material-ui/core';
import IconSelect from '../../../icons/project_arrow.svg';
import RadioIcon from '../../../config/images/bg_radio.svg';
import RadioOnIcon from '../../../config/images/bg_radio_on.svg';
import CheckBoxOn from '../../../config/images/bg_checkbox_on.svg';

const useStyles = makeStyles(theme => ({

  wrapSerialNumber : {
    width : '49%',
    height : 43,
    borderRadius : '3px',
    border : '1px solid #80808073',
    display : 'flex',
    justifyContent : 'space-between',
    padding: 10,
    marginBottom : 2
  },

  errorReactSelect: {
    '& .select__control': {
      borderColor: '#EB5757',
      boxShadow: 'none'
    },
    '& .select__control:hover': {
      borderColor: 'red !important'
    }
  },

  btnDisible : {
    maxWidth: '95px',
    height: '35px',
    width : 95,
    marginLeft : 10,
    backgroundColor: '#000000',
    fontFamily: 'Actual',
    fontStyle: 'normal',
    fontSize: '12px',
    textTransform: 'capitalize',
    borderRadius: '3px',
    fontWeight: 400,
    '& span': {
      display: 'inline-block',
    },
    '& img': {
      display: 'inline',
      marginLeft: '5px'
    },
    '&:hover': {
      backgroundColor: '#002D56'
    },
    color : 'white !important',
    background : 'gray !important'
  },

  sectionContainerProject : {
    display: 'grid',
    gridTemplateColumns: '2.1fr 10fr',
    marginBottom: '15px',
    gridColumnGap: '22px',
    [theme.breakpoints.down('md')] : {
      marginTop : '10px',
      gridTemplateColumns : 'auto',
      width : '50%',
      gridColumnGap : 0
    },
    [theme.breakpoints.down('xs')] : {
      marginTop : '10px',
      gridTemplateColumns : 'auto',
      gridColumnGap : 0,
      width : '100%'
    }
  },

  sectionContainerSys : {
    display: 'grid',
    gridTemplateColumns: '2.1fr 5fr 5fr',
    marginBottom: '15px',
    gridColumnGap: '22px',
    [theme.breakpoints.down('md')] : {
      marginTop : '10px',
      gridTemplateColumns : 'auto',
      width : '50%',
      gridColumnGap : 0
    },
    [theme.breakpoints.down('xs')] : {
      marginTop : '10px',
      gridTemplateColumns : 'auto',
      gridColumnGap : 0,
      width : '100%'
    }

  },
  sectionContainerProjectDetail: {
    display: 'grid',
    marginBottom: '15px',
    gridColumnGap: '22px',
    gridTemplateColumns: '2.1fr 10fr',
  },
  sectionProjectDetail: {
    display: 'grid',
    marginBottom: '15px',
    gridColumnGap: '22px',
    gridTemplateColumns: '10fr',
  },
  sectionContainerProjectManual : {
    display: 'grid',
    // gridTemplateColumns: '1fr 2fr 1fr',
    gridTemplateColumns: '2.1fr 3.6fr 6fr',
    marginBottom: '20px',
    gridColumnGap: '22px',
    [theme.breakpoints.down('md')] : {
      marginTop : '10px',
      gridTemplateColumns : 'auto',
      width : '50%',
      gridColumnGap : 0
    },
    [theme.breakpoints.down('xs')] : {
      marginTop : '10px',
      gridTemplateColumns : 'auto',
      gridColumnGap : 0,
      width : '100%'
    }
  },

  sectionContainer: {
    display: 'grid',
    gridTemplateColumns: '2.1fr 5fr 5fr',
    marginBottom: '22px',
    gridColumnGap: '22px',
    [theme.breakpoints.down('md')] : {
      marginTop : '10px',
      gridTemplateColumns : 'auto',
      width : '50%',
      gridColumnGap : 0
    },
    [theme.breakpoints.down('xs')] : {
      marginTop : '10px',
      gridTemplateColumns : 'auto',
      gridColumnGap : 0,
      width : '100%'
    }

  },

  sectionHeaderContainerContact : {

  },
  textBol : {
    color : '#28a3ec',
    textTransform : 'uppercase',
    textDecoration : 'underline'
  },
  wrapContent : {
    width: '52%',
    [theme.breakpoints.only('xs')] : {
      width : '100%'
    }
  },
  wrapLeft : {
    display : 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.only('xs')] : {
      alignItems : 'center'
    }
  },
  wrapNotification : {
    height : 50,
    background : 'linear-gradient(90deg, #02A3EC 0%, #007DD8 100%)',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    alignItems: 'center',
    [theme.breakpoints.only('sm')] : {
      display : 'none'
    },
    [theme.breakpoints.only('xs')] : {
      height : 90
    }
  },

  hiddenNoti : {
    display : 'none'
  },
  Noti : {
    fontWeight: 'bold'
  },
  subClaim : {
    fontWeight : 400,
    fontSize : 14,
    color : '#4F4F4F',
    // width: 484,
    margin : '0 auto',
    textAlign: 'center',
    [theme.breakpoints.only('xs')] : {
      marginTop : 10
    }
    // lineHeight : 22
  },
  wrapTextNoti : {
    color: 'white',
    marginLeft: 17.5
  },

  wrapSub : {
    width: 484,
    textAlign: 'center'
  },
  btnPro : {
    width: 100,
    background : '#2badef',
    borderRadius : 4,
    height: 40,
    color : 'white',
    cursor: 'pointer',
    border : 'none',
    [theme.breakpoints.between('xs', 'md')] : {
      height : 40,
      marginTop : 10
    }
  },
  overlay: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: 'opacity 0.5s ease',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '5px'
  },
  images: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    // margin: '-15px 0 0 -15px',
    width: 'calc(100% - 20px)',
    '& > div': {
      margin: '15px 0 0 15px',
      position: 'relative'
    },
    '& > div:hover div[role=overlay]': {
      opacity: 1
    }
  },
  image: {
    height: '70px',
    width: '70px',
    borderRadius: '5px'
  },
  wrapNavMobile : {
    "@media (min-width: 1366px)": {
      display : 'none'
    },
    height : 55
  },

  textIntro : {
    marginTop : 10,
    fontSize : 15,
    color : '#000000',
    fontWeight : 300
  },

  sectionContainerAddress : {
    display: 'grid',
    gridTemplateColumns: '2.1fr 5fr 5fr',
    marginBottom: '22px',
    gridColumnGap: '22px',
    [theme.breakpoints.between('xs', 'md')] : {
      marginTop : '10px',
      gridTemplateColumns : 'auto',
      gridColumnGap : 0
    }
  },

  wrapItemIssue : {
    display : 'flex',
    [theme.breakpoints.between('xs', 'md')] : {
      flexDirection : 'column'
    }

  },

  wrapUploadIssue : {
    width : '100%'
  },

  stepInfo : {
    marginTop:30,
    marginBottom : 20,
    "@media (min-width: 1366px)": {
      display : 'none'
    },
    [theme.breakpoints.only('xs')] : {
      marginTop : 20
    }
  },
  sectionTitle: {
    position: 'relative',
    fontFamily: 'Actual',
    color: '#4F4F4F',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '15px',
    lineHeight: '20px',
    '& span': {
      color: '#EB5757'
    },
    [theme.breakpoints.between('xs', 'md')] : {
      marginBottom : '10px'
    }
  },

  wrapUpload : {
    // gridColumn: 'span 2',
    // width : '49%',
    [theme.breakpoints.only('xs')] : {
      width : '100%'
    }
  },

  wrapMessageUpdate : {
    backgroundColor: '#FFF6F6',
    marginTop: 8,
    display : 'flex',
    padding : 11,
    alignItems : 'flex-start',
    border: '1px solid #DCE1E1',
    borderRadius: 3,
    color: '#4f4f4f'
  },

  wrapMessageLine : {
    backgroundColor: '#F6FAFD',
    marginTop: 8,
    display : 'flex',
    padding : 11,
    alignItems : 'flex-start',
    border: '1px solid #DCE1E1',
    borderRadius: 3,
    color: '#4f4f4f'
  },
  wrapMessageLineSpec : {
    backgroundColor: '#F6FAFD',
    marginTop: 20,
    display : 'flex',
    padding : 11,
    alignItems : 'flex-start',
    border: '1px solid #DCE1E1',
    borderRadius: 3,
    color: '#4f4f4f'
  },

  text1Update : {
    fontWeight : '400 !important',
  },
  text2Update : {
    color : '#dc7979'
  },

  modal: {
    width: '40rem',
    height: '50%',
    top: '30px',
    margin: 'auto',
    "& .MuiPaper-root": {
      overflowX: 'hidden'
    },
    [theme.breakpoints.down('md')]: {
      width: '98%',
      overflowY: 'auto'
    }
  },

  wrapLine : {
    '& li' : {
      fontSize : 15,
      lineHeight : '19px',
      fontWeight : 300
    }
  },

  wrapMessage : {
    // height : 58,
    backgroundColor: '#F6FAFD',
    marginTop: 8,
    display : 'flex',
    padding : 11,
    alignItems : 'flex-start',
    border: '1px solid #DCE1E1',
    borderRadius: 3,
    color: '#4f4f4f'
  },
  leftMessage : {

  },
  rightMessage : {

  },

  wrapMessagaeSpecial : {
    height : 89,
    backgroundColor : '#F6FAFD',
    marginTop : 9,
    padding : 14,
    display : 'flex'
  },
  wrapMessageDownload : {
    height : 78,
    backgroundColor : '#F6FAFD',
    marginTop : 9,
    padding : 14,
    display : 'flex'
  },
  choseTypeProject : {
    marginBottom : 25
  },
  boldInfo : {
    color : '#009EE3',
    textDecoration : 'underline',
    cursor: 'pointer',
    textDecorationColor : '#009EE3'
  },
  infoTextMessage : {
    fontWeight : 300,
    fontSize : 15,
    // lineHeight : '18px'
  },
  sectionTitleStep : {
    position: 'relative',
    fontFamily: 'Actual',
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '20px',
    textTransform : 'uppercase',
    '& span': {
      color: '#EB5757'
    },
    [theme.breakpoints.between('xs', 'md')] : {
      marginBottom : '10px'
    }
  },


  textArea: {
    // gridColumn: "span 2",
  },
  inputTextArea : {
    width : '100%',
    height: '180px !important',
    padding: '15px',
    resize: 'none',
    border: '1px solid #C4C4C4',
    [theme.breakpoints.between('xs', 'md')] : {
      width : '100% !important'
    }
  },

  inputTextAreaError : {
    border: '1px solid red'
  },

  inputField: {
    '& .MuiOutlinedInput-notchedOutline': {
      transition: 'background 0.3s, border 0.3s',
      borderColor: '#C4C4C4'
    },
    '& .MuiInputBase-input': {
      color: '#333333',
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#BDBDBD',
      opacity: 1
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      fontSize: '14px',
      color: '#333333',
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '17.5px'
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 15px'
    },

    '& .MuiInputBase-input.Mui-disabled' : {
      color : '#a1a1a1 !important'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: '#C4C4C4 !important'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000 !important',
      borderWidth: '1px'
    }
  },
  sectionHeaderContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.between('xs', 'md')] : {
      width : 'auto',
      height : 'auto'
    }
  },

  wrapItemSerial : {
    display : 'flex',
    flexWrap : 'wrap',
    justifyContent : 'space-between'
  },

  wrapSectionImage : {
    display : 'flex',
    [theme.breakpoints.only('xs')] : {
      flexDirection: 'column'
    }
  },

  wrapItemImageIssue : {
    width: '100%',
    display: 'grid',
    marginBottom: '22px',
    gridColumnGap: '22px',
    gridTemplateColumns: '2.1fr 5fr 0fr',
    [theme.breakpoints.only('xs')] : {
      gridColumnGap : 'auto',
      gridTemplateColumns : 'auto'
    }
  },
  wrapUploadEx : {
    // width : '95%',
    width: '100%',
    [theme.breakpoints.only('xs')] : {
      width : '100%'
    }
  },

  sectionHeaderContainerCustom : {
    display: 'flex',
    alignItems: 'center',
    width : 100,
    height : 43,
    [theme.breakpoints.between('xs', 'md')] : {
      width : 'auto',
      height : 'auto'
    }
  },

  wrapInfoIssue : {
    width : '100%'
  },

  addressSection: {
    gridColumn: 'span 2'
  },
  addressSection1: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: '21px',
    marginBottom: '22px',

    [theme.breakpoints.down('xs')] : {
      gridTemplateColumns : 'auto',
      gridRowGap : '15px',
      marginBottom: '15px'
    }

  },
  addressSection2: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridColumnGap: '20px',
    [theme.breakpoints.down('xs')] : {
      gridTemplateColumns : 'auto',
      gridRowGap : '15px'
    }
  },

  bgRegion : {
    background : '#80808047 !important'
  },

  selectField: {
    width: '100%',
    height: '44px',
    padding: '0 20px 0 15px',
    background: `#fff url(${IconSelect}) right center /50px 8px no-repeat`,
    border: '1px solid #C4C4C4',
    boxSizing: 'border-box',
    color: '#333333',
    fontFamily: 'Actual',
    fontSize: '14px',
    fontWeight: '400',
    fontStyle: 'normal',
    verticalAlign: 'top',
    lineHeight: '44px',
    transition: 'background 0.3s, border 0.3s',
    '-moz-appearance': 'none',
    '-webkit-appearance': 'none',
    appearance: 'none',
    '&:focus': {
      outlineStyle: 'none',
      borderColor: '#000'
    },
    '&::-ms-expand': {
      display: 'none'
    }
  },
  picturePreview: {
    width: '100%',
    height: '160px',
    border: '1px solid #C4C4C4',
    '& img': {
      width: '100%',
      height: '100%'
    }
  },
  chooseFileBtn: {
    width: '95px',
    boxSizing: 'border-box',
    fontSize: '12px',
    lineHeight: '40px',
    padding: '2px 10px',
    borderRadius: '3px',
    backgroundColor: '#02A3EC',
    textTransform: 'none',
    fontFamily: 'Actual',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#FFFFFF',
    height: '35px',
    '&:hover': {
      backgroundColor: '#02A3EC'
    }
  },
  pictureContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 95px',
    gridColumnGap: '17px'
  },
  requiredText: {
    fontFamily: 'Actual',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '15px',
    color: '#EB5757',
    marginTop: '75px'
  },
  separator: {
    height: '1px',
    maxHeight: '1px',
    width: '100%',
    backgroundColor: '#000',
    opacity: 0.1,
    marginTop: '25px',
    marginBottom: '25px',
    gridColumn: 'span 2'
  },
  wrapClaim : {
    width: '80%',
    margin: '10px auto 20px auto',
    //display: 'grid',

  },
  separatorInfo : {
    height: '1px',
    maxHeight: '1px',
    width: '100%',
    backgroundColor: '#000',
    opacity: 0.1,
    marginTop: '25px',
    gridColumn: 'span 2'
  },
  navigationBtnContainer: {
    '& button.MuiButtonBase-root': {
      minWidth: '183px !important',
      padding: '13.5px 2rem !important',
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      color: '#fff'
    },
    '& button.MuiButtonBase-root.saveDraftBtnReview': {
      backgroundColor: '#D3D3D3',
      borderColor: '#8C8C8C',
      color: '#8C8C8C',
      marginRight: '20px',
      [theme.breakpoints.down(700)]: {
        marginRight: '10px',
        width: '100px',
        padding: '13.5px 0 !important',
      },
      [theme.breakpoints.down(365)]: {
        marginRight: '3px'
      }
    },
    '& button.MuiButtonBase-root.saveDraftBtn': {
      backgroundColor: '#ffffff',
      borderColor: '#8C8C8C',
      color: '#8C8C8C',
      marginRight: '20px',
      [theme.breakpoints.down(700)]: {
        marginRight: '10px',
        width: '100px',
        padding: '13.5px 0 !important',
      },
      [theme.breakpoints.down(365)]: {
        marginRight: '3px'
      }
    },
    '& button.MuiButtonBase-root.saveDraftBtn:hover': {
      backgroundColor: '#D3D3D3',
      color: '#ffffff'
    },


    [theme.breakpoints.down(1245)]: {
      '& button.MuiButtonBase-root': {
        minWidth: '140px !important'
      }
    },
    [theme.breakpoints.down(700)]: {
      '& button.MuiButtonBase-root': {
        minWidth: '100px !important'
      }
    },
    [theme.breakpoints.down(365)]: {
      '& button.MuiButtonBase-root': {
        minWidth: '70px !important'
      }
    }
  },
  reviewSectionHeaderSmallScreen: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '15px',
    marginBottom: '10px',
    '& h5': {
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px'
    },
    [theme.breakpoints.down(700)]: {
      marginLeft: 0
    }
  },
  productTypeContainer: {
    gridColumn: 'span 2'
  },
  productTypeSection1: {
    width: '50%',
    marginBottom: '22px'
  },
  productTypeSection2: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridColumnGap: '20px'
  },
  serialNumberContainer: {
    width: '50%',
    marginBottom: '22px',
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '5px'
    },
    [theme.breakpoints.between('xs', 'md')] : {
      width : '100%'
    }
  },
  radio: {
    background: `url(${RadioIcon}) center /100% 100% no-repeat`,
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    transition: 'background 0.4s'
  },
  checkBoxOn: {
    background: `url(${CheckBoxOn}) center /100% 100% no-repeat`,
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    transition: 'background 0.4s'
  },
  radioOn: {
    background: `url(${RadioOnIcon}) center /100% 100% no-repeat`,
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    transition: 'background 0.4s'
  },
  batteryContainer: {
    '& .MuiTypography-body1': {
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '20px',
      color: '#000000',
      marginLeft: '2px',
      marginRight: '17px'
    }
  },
  contactNumberContainer: {
    '& .MuiTypography-body1': {
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '20px',
      color: '#000000',
      marginLeft: '2px',
      marginRight: '20px'
    },
    '& .MuiTypography-body1:last-child': {
      marginRight: 0
    }
  },
  noteContainer: {
    gridColumn: 'span 2'
  },
  note: {
    width: '100%',
    padding: '10px 12px',
    height: '359px !important',
    borderColor: '#C4C4C4',
    transition: 'border 0.3s',
    '&:focus': {
      borderColor: '#000000'
    }
  },
  notiBox: {
    width: '100%',
    padding: '10px',
    // textAlign: 'justify',
    backgroundColor: '#F6FAFD',
    border: '1px solid #DCE1E1',
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '22px',
    fontWeight: 300,
    '& p': {
        fontSize: '15px',
        color: '#4F4F4F',
        fontFamily: 'Actual'
      }
    },
    downloadTemplate: {
      color: '#009EE3 !important',
      marginTop: '8px'
    },
  reviewInfoContainer: {
    width: '80%',
    margin: '0px auto 51px auto',
    [theme.breakpoints.only('xs')] : {
      width : 'auto',
      margin : 0,
      padding : '15px 15px'
    }
  },

  headerClaim : {
    display : 'flex',
    justifyContent : 'space-between',
    marginBottom : 30
  },

  showDraft : {
    height : 55,
    background : '#F6FAFD',
    width : '100%'
  },
  draftInfo : {
    height : 55,
    background : '#F6FAFD',
    width : '100%',
    display : 'flex',
    justifyContent : 'space-between',
    alignItems : 'center',
    padding : 10,
    marginBottom : 20,
    border: '1px solid #E0E0E0'
  },

  leftDraft : {
    display : 'flex'
  },

  leftClaim : {

  },
  rightClaim : {
    textAlign: 'right'
  },

  claimInfo : {
    fontWeight : 400,
    fontSize : 32,
    lineHeight : '40px',
    marginBottom : 3
  },

  titleClaim : {
    fontSize : 20,
    lineHeight : '25px',
    fontWeight : 400,
    textTransform: 'capitalize'
  },
  textTypeClaim : {
    fontSize : 15,
    fontWeight : 300,
    lineHeight : '18.75px'
  },


  typeclaimInfo : {
    fontSize : 15,
    fontWeight : 300,
    lineHeight : '18.75px',
    color : '#009EE3'
  },

  statusClaim : {
    fontSize : 15,
    fontWeight : 300,
    lineHeight : '18.75px',
    marginTop: 7,
    display: 'flex',
    alignItems:'center',
    // display: 'grid',
    // /* margin-bottom: 22px; */
    // gridColumnGap: '8px',
    // gridTemplateColumns: '0.7fr 1fr 0.5fr'
  },

  submited : {
    fontSize : 15,
    fontWeight : 300,
    lineHeight : '18.75px',
  },


  reviewDetail : {
    width: '60%',
    margin: '0px auto 51px auto',
    padding: '38px 42px 20px 42px',
    background : 'white',
    [theme.breakpoints.only('xs')] : {
      width : 'auto',
      margin : 0,
      padding : '15px 15px'
    }
  },
  bgDetail : {
    background : '#E5E5E5',
    // marginTop : '-18px',
    paddingTop : 26,
    paddingBottom :20
  },
  wrapLoading : {

  },
  loadingEdit: {
    display : 'flex',
    justifyContent : 'center',
    padding :10
  },
  bannerDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
    margin: '0 auto',
    position: 'relative',
    marginBottom: 25,
    '& .breadcrumb2.form': {
      top: '18px',
      right: 0,
      width: 'fit-content'
    }
  },

  reviewSectionContainerClaim : {
    display: 'grid',
    gridTemplateColumns: '0fr 1fr',
    // gridGap: '41px',
    alignItems: 'center',
    '& h5': {
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  reviewSectionsContainer: {
    marginTop: '31px',
    wordBreak: 'break-word',
    width: '100%',
    margin: 'auto',
    // marginTop: 25
    // '& > div:last-child > div': {
    //     border: 'none',
    // }
  },

  wrapUser : {
    marginBottom: '31px',
    wordBreak: 'break-word',
    width: '100%',
    margin: 'auto',
  },

  reviewSectionContainerCLaimInfoDetail : {
    display : 'flex',
    justifyContent : 'space-between',
    alignItems : 'center',
    borderBottom : '1px solid #80808033'
  },

  reviewTitleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #cfcfcf',
    paddingBottom: '4px',
    '& h5' : {
      fontWeight : 500,
      color: '#4F4F4F',
      lineHeight: '35px'
    }
  },

  // reviewSectionsContainer: {
  //   marginBottom: '16px',
  //   wordBreak: 'break-word'
  // },
  reviewSectionContainerCLaimInfo : {
    display : 'grid',
    alignItems : 'center',
    gridTemplateColumns : '0fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  reviewSectionContentProjectDetailInfo : {
    display: 'grid',
    gridTemplateColumns: '4fr 6fr',
    minHeight: '50px',
    padding: '10px 0px',
    alignItems: 'center',
    gridColumn: 2,
    gridGap: '20px',
    fontFamily: 'Actual',
    '& h6': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      color: '#4F4F4F',
      marginLeft: '15px'
    },
    '& p': {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      color: '#000000'
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 1,
      gridTemplateColumns: 'repeat(2, 1fr)',
      '& h6': {
        marginLeft: '0px'
      },
      display : 'initial'
    }
  },
  reviewSectionContentProjectDetail : {
    display: 'grid',
    gridTemplateColumns: '3fr 6fr',
    minHeight: '50px',
    padding: '10px 0px',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, .1)',
    gridColumn: 2,
    gridGap: '20px',
    fontFamily: 'Actual',
    '& h6': {
      fontStyle: 'normal',
      fontWeight:'300',
      fontSize: '15px',
      color: '#4F4F4F',
      marginLeft: '15px'
    },
    '& p': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '15px',
      color: '#000000'
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 1,
      gridTemplateColumns: 'repeat(2, 1fr)',
      '& h6': {
        marginLeft: '0px'
      },
      display : 'initial'
    }
  },
  reviewSectionContentClaim: {
    display: 'grid',
    gridTemplateColumns: '1.5fr 6fr',
    minHeight: '50px',
    padding: '10px 0px',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, .1)',
    gridColumn: 2,
    gridGap: '88px',
    fontFamily: 'Actual',
    gridTemplateColumns: '3fr 6fr',
    gridColumnGap: '5px',
    '& h6': {
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '15px',
      color: '#4F4F4F',
      marginLeft: '15px'
    },
    '& p': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '15px',
      color: '#000000'
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 1,
      gridTemplateColumns: 'repeat(2, 1fr)',
      '& h6': {
        marginLeft: '0px'
      },
      display : 'initial'
    }
  },
  reviewSectionContent: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    minHeight: '50px',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, .1)',
    gridColumn: 2,
    gridGap: '88px',
    padding : '10px 0px 10px 0px',
    fontFamily: 'Actual',
    '& h6': {
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '15px',
      color: '#4F4F4F',
      marginLeft: '15px'
    },
    '& p': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '15px',
      color: '#000000'
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 1,
      gridTemplateColumns: 'repeat(2, 1fr)',
      '& h6': {
        marginLeft: '0px'
      },
      display : 'initial'
    }
  },
  multiSelect: {
    '& .select__control': {
      borderRadius: 0,
      padding: '4px',
      transition: 'borderColor 0.4s',
      minHeight: '46px',
      borderColor: '#C4C4C4',
      paddingLeft: '7px'
    },
    '& .select__control:hover': {
      borderColor: '#000',
      boxShadow: 'none'
    },
    '& .select__control.select__control--is-focused': {
      borderColor: '#000',
      boxShadow: 'none'
    },
    '& .select__multi-value__remove:hover': {
      backgroundColor: '#00adef',
      color: '#fff'
    }
  },
  reviewSectionContentImage: {
    // display: 'grid',
    // gridTemplateColumns: '1fr 2fr',
    minHeight: '50px',
    paddingTop: '10px',
    alignItems: 'center',
    //borderBottom: '1px solid rgba(0, 0, 0, .1)',
    gridColumn: 2,
    gridGap: '88px',
    fontFamily: 'Actual',
    '& h6': {
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '14px',
      color: '#4F4F4F',
      marginLeft: '15px'
    },
    '& p': {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      color: '#000000'
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 1,
      gridTemplateColumns: 'repeat(2, 1fr)',
      '& h6': {
        marginLeft: '0px'
      },
      display : 'initial'
    }
  },
  loadingIndicator: {
    textAlign: 'center',
    marginTop: '100px'
  },
  editBtn: {
    maxWidth: '95px',
    height: '35px',
    width : 95,
    marginLeft : 10,
    backgroundColor: '#000000',
    color: '#fff',
    fontFamily: 'Actual',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    textTransform: 'capitalize',
    borderRadius: '3px',
    fontWeight: 400, 
    '& span': {
      display: 'inline-block',
    },
    '& img': {
      display: 'inline',
      marginLeft: '5px'
    },
    '&:hover': {
      backgroundColor: '#002D56'
    },
    // [theme.breakpoints.only('xs')] : {
    //   display : 'none'
    // }
  },
  reviewNoteSection: {
    alignItems: 'flex-start',
    '& h4': {
      marginTop: '9px'
    },
    '& button': {
      marginTop: '9px'
    }
  },
  reviewNoteContent: {
    paddingTop: '13px',
    paddingBottom: '13px',
    alignItems: 'flex-start'
  },
  serialNumberContentContainer: {
    gridColumn: 'span 2'
  },
  serialNumberTable: {
    '& .MuiTableRow-head': {
      backgroundColor: '#0F2848',
      border: '1px solid #0F2848',
    },
    '& .MuiTableCell-head': {
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '15px',
      lineHeight: '28px',
      color: '#FFFFFF'
    },
    '& .MuiTableBody-root': {
      border: '1px solid #C4C4C4'
    },
    '& .MuiTableCell-body': {
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '28px',
      color: '#000000'
    },
    '& .MuiTableFooter-root': {
      borderLeft: '1px solid #C4C4C4',
      borderRight: '1px solid #C4C4C4'
    },
    '& .MuiTableCell-root': {
      borderBottomColor: '#C4C4C4'
    },
    '& .MuiTableCell-footer': {
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '28px',
      color: '#000000'
    }
  },
  batterySerialNumberContainer: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '5px'
    }
  },
  errorInputField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#EB5757 !important'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#EB5757 !important',
      borderWidth: '1px'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#EB5757 !important',
      borderWidth: '1px'
    }
  },
  errorSelectField: {
    borderColor: '#EB5757'
  },
  datePicker: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: '#C4C4C4 !important'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000 !important',
      borderWidth: '1px'
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 15px',
      fontSize: '14px'
    }
  },
  errorDatePickerField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#EB5757 !important'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#EB5757 !important'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#EB5757 !important'
    }
  },
}));

export default useStyles;
