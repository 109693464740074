import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useAuth } from '../../../contexts/auth';
import UserInfo from '../components/UserInfo';
import IssuePV from '../components/IssuePV';
import PurchaseInfoPv from '../components/PurchaseInfoPv';
import styled from 'styled-components';
import IntroductionClaim from '../components/IntroductionClaim';
import ProgressBar from '../components/ProgressBar';
import { convertArrayClaim, notiFailed, notiSuccess } from '../../../utils/helpers';
import ReviewPv from '../components/ReviewPv';
import { claimService, projectService } from '../../../services';
import { useHistory, useLocation } from 'react-router-dom';
import { keyPv } from '../components/keyPV';
import { schemaPV } from '../components/schemaPv';
import { defaultStatePv } from '../components/defaultStatePv';
import Notification from '../components/Notification';
import config from '../../../config';
import moment from 'moment';
import useExitPrompt from '../../../hooks/useExitPrompt';
import Evidence from '../components/Evidence';
import { keyInfo } from '../components/keyInfo';


const ContainerClaimFormPv = styled.div `
    width: 80%;
    margin: 36px auto 70px auto;
    display: grid;
    @media (max-width: 1366px) {
      grid-template-columns: auto;
      grid-column-gap: 0px;
      width: 90%;
      /* margin: 50px auto 30px; */
    }
`


function PvPage({
                  listSerial, setListSerial, errorInfo, triggerInfo, saleRegions, id, condition,
                  claimType, fileInvoiceForLaborReimbursement, setFileInvoiceForLaborReimbursement, submitterEmail, checkedEmail,
                  serials, setSerials, fileProofOfPermissionToOperateDate, setFileProofOfPermissionToOperateDate, listProductPti,
                  register, handleSubmit, errors, control, watch, setValue, getValues, formState,  clearErrors, trigger,
                  fileProofOfPermission, setFileProofOfPermission, filePictureOfAffectedModule, setFilePictureOfAffectedModule,
                  filePictureOfVoc, setFilePictureOfVoc, filePictureOfFront, setFilePictureOfFront,
                  filePictureOfBack, setFilePictureOfBack, filePictureCloseUp, setFilePictureCloseUp,
                  filePictureSitePlan, setFilePictureSitePlan, filePictureOfOverall, setFilePictureOfOverall,
                  filePictureOfWireManagement, setFilePictureOfWireManagement, filePictureProofDelivery, setFilePictureProofDelivery,
                  filePictureImmediately, setFilePictureImmediately, filePictureOfVmpp, setFilePictureOfVmpp,
                  filePictureIr, setFilePictureIr, filePictureInVoice, setFilePictureInVoice,
                  fileBankAccountInfo, setFileBankAccountInfo, keyUpload, setKeyUpload, distributors,
                  filePermissionOfDate, setFilePermissionOfDate, setFileLaborWarranty, fileLaborWarranty, urlImageProof, setFilesProof,
                  goBackStepDefault, handleSetStep, claimInfoName, projects, step}) {

  const user = useAuth();
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const [isDraft, setIsDraft] = useState(true);
  const dataPv = watch();
  const claimTypeInfo = watch('claimType');

  useEffect(() => {
    {step && 
      setCurrentStep(parseInt(step));
    }
  }, [step])

  useEffect(() => {
    setValue('submitterEmail', submitterEmail);
    setValue('checkedEmail', checkedEmail);

  }, [submitterEmail, checkedEmail])

  const projectId = watch('typeProjectName');

  // useEffect(() => {
  //   if (projectId !== '' && projectId !== undefined) {
  //     //getDetailProject(projectId?.id);
  //   }
  // }, [projectId?.id]);

  useEffect(() => {
    handleSetStep(currentStep)
    if(currentStep === 4) {
      trigger(keyPv).then(r => console.log(r));
      triggerInfo(keyInfo).then(r => console.log(r));
    }
  },[currentStep])


  useEffect(() => {
    filePictureOfAffectedModule.length > 0 ? setValue('pictureOfModuleLabel', 'done') : setValue('pictureOfModuleLabel', '');
    filePictureOfAffectedModule.length > 0 && clearErrors('pictureOfModuleLabel')
    filePictureOfFront.length > 0 ? setValue('pictureOfTheFrontSize', 'done') : setValue('pictureOfTheFrontSize', '');
    filePictureOfFront.length > 0 && clearErrors('pictureOfTheFrontSize');
    filePictureOfBack.length > 0 ? setValue('pictureOfTheBackSize', 'done') : setValue('pictureOfTheBackSize', '');
    filePictureOfBack.length > 0 && clearErrors('pictureOfTheBackSize');
    filePictureOfVoc.length > 0 ? setValue('pictureOfVocReading', 'done') : setValue('pictureOfVocReading', '');
    filePictureOfVoc.length > 0 && clearErrors('pictureOfVocReading');
    filePictureCloseUp.length > 0 ? setValue('closeUpPictureOfPhysicalDefect', 'done') : setValue('closeUpPictureOfPhysicalDefect', '');
    filePictureCloseUp.length > 0 && clearErrors('closeUpPictureOfPhysicalDefect');
    filePictureSitePlan.length > 0 ? setValue('siteModuleLayoutPlan', 'done') : setValue('siteModuleLayoutPlan', '');
    filePictureSitePlan.length > 0 && clearErrors('siteModuleLayoutPlan');
    filePictureOfOverall.length > 0 ? setValue('pictureOfOverallRoofArea', 'done') : setValue('pictureOfOverallRoofArea', '');
    filePictureOfOverall.length > 0 && clearErrors('pictureOfOverallRoofArea');
    filePictureOfWireManagement.length > 0 ? setValue('pictureOfWireManagement', 'done') : setValue('pictureOfWireManagement', '');
    filePictureOfWireManagement.length > 0 && clearErrors('pictureOfWireManagement')
    filePictureProofDelivery.length > 0 ? setValue('proofOfDeliveryDocument', 'done') : setValue('proofOfDeliveryDocument', '');
    filePictureProofDelivery.length > 0 && clearErrors('proofOfDeliveryDocument');
    filePictureImmediately.length > 0 ? setValue('pictureOfModuleImmediately', 'done') : setValue('pictureOfModuleImmediately', '');
    filePictureImmediately.length > 0 && clearErrors('pictureOfModuleImmediately')
    filePictureInVoice.length > 0 ? setValue('coppyOfInvoice', 'done') : setValue('coppyOfInvoice', '');
    filePictureInVoice.length > 0 && clearErrors('coppyOfInvoice');
    fileProofOfPermissionToOperateDate.length > 0 ? setValue('proofOfPermissionToOperateDate', 'done') : setValue('proofOfPermissionToOperateDate', '');
    fileProofOfPermissionToOperateDate.length > 0 && clearErrors('proofOfPermissionToOperateDate')
    fileInvoiceForLaborReimbursement.length > 0 ? setValue('invoiceForLaborReimbursement', 'done') : setValue('invoiceForLaborReimbursement', '')
    fileInvoiceForLaborReimbursement.length > 0 && clearErrors('invoiceForLaborReimbursement')
    fileBankAccountInfo.length > 0 ? setValue('infoBank' , 'done') : setValue('infoBank', '');
    fileBankAccountInfo.length > 0 && clearErrors('infoBank')
    filePictureOfVmpp.length > 0 ? setValue('pictureOfVmpp', 'done') : setValue('pictureOfVmpp', '');
    filePictureOfVmpp.length > 0 && clearErrors('pictureOfVmpp')
    filePictureIr.length > 0 ? setValue('irPicture', 'done') : setValue('irPicture', '');
    filePictureIr.length > 0 && clearErrors('irPicture')
    filePermissionOfDate.length > 0 ? setValue('isUploadProofDate', 'done') : setValue('isUploadProofDate', '');
    filePermissionOfDate.length > 0 && clearErrors('isUploadProofDate')

  }, [filePictureOfFront, filePictureOfVoc, filePictureOfBack, filePictureCloseUp, filePictureSitePlan, filePictureOfOverall, filePictureOfWireManagement, filePictureProofDelivery, filePictureImmediately, fileBankAccountInfo, filePictureIr, filePictureInVoice, fileProofOfPermissionToOperateDate, fileInvoiceForLaborReimbursement, filePictureOfAffectedModule, filePictureOfVmpp, filePermissionOfDate])

  useEffect(() => {

    getProfile()


  }, [])

  async function getProfile() {
    const info = await projectService.getProfile();
    setValue('installerCity', _.get(info.data, 'city'))
    setValue('installerAddress1', _.get(info.data, 'address'))
    setValue('installerState', _.get(info.data, 'state'))
    setValue('installerZipcode', _.get(info.data, 'zipCode'))
    setValue('installerAddress2', _.get(info.data, 'addressCont'))
    setValue('installerEmail', _.get(info.data, 'email'))
    setValue('installerName', _.get(info.data, 'fullName'))
    setValue('companyName', _.get(info.data, 'companyName'))
    setValue('installerContact', _.get(info.data, 'fullName'))
    //setValue('installerPhone', _.get(info.data, 'officeNumber') || _.get(info.data, 'mobileNumber') )
    setValue('installerPhone', !!_.get(info.data, 'officeNumber') ? _.get(info.data, 'officeNumber') : _.get(info.data, 'mobileNumber') )
  }



  const goBack = () => setCurrentStep(currentStep - 1);
  const next = () => setCurrentStep(currentStep + 1);
  const nextStep = () => {
    goBackStepDefault();
  }

  const goToStep = step => setCurrentStep(step);


  const showErrorMessage = (errors, errorKeys = []) => {
    let message = '';

    for (let i = 0; i < errorKeys.length; i++) {
      if (errors[errorKeys[i]]) {
        message = errors[errorKeys[i]].message;
        break;
      }
    }

    return notiFailed(message);
  };

  async function handleSaveDraft() {
    const currentData = getValues();
    let data = _.pickBy(currentData, value => {
      if (value === 'select') {
        return false;
      }

      if (!value) {
        return false;
      }

      if (_.get(value, 'length') === 0) {
        return false;
      }

      return true;
    });

    try {

      setLoading(true);
      let objKey = {};
      if(objKey !== '') {
        objKey = {
          keyUpload
        }
      }

      const arrayImage = convertArrayClaim(
        filePictureOfAffectedModule, filePictureOfFront, filePictureOfBack,
        filePictureOfVoc, filePictureCloseUp, filePictureSitePlan,
        filePictureOfOverall, filePictureOfWireManagement, filePictureProofDelivery,
        filePictureImmediately, filePictureInVoice, fileProofOfPermissionToOperateDate,
        fileInvoiceForLaborReimbursement, fileBankAccountInfo, filePermissionOfDate, filePictureOfVmpp, filePictureIr
      )


      let attachments = {}

      if(arrayImage.length > 0) {
        attachments = {
          attachments : arrayImage.toString() || ''
        }
      }

      let projectId = {}

      if(data.projectInfo !== null) {
        projectId = {
          projectId : data.projectInfo?.id
        }
      }

      let idClaim = {}

      if(id !== '') {
        idClaim = {
          id
        }
      }



      const makePayload = {
        ...data,
        ...objKey,
        ...attachments,
        ...idClaim,
        type : '1',
        title: data.projectNameSys || '',
        typeClaim : 'pv',
        ...projectId,
        qualifyLaborWarranty : data.qualifyLaborWarranty  || false,
        projectInfo : data.projectInfo
      }
      const info = await claimService.saveDraftClaim(makePayload);

      notiSuccess('Claim PV draft successfully!');
      history.push({
        pathname: `/claim/edit/1/1/${info.data.id}`,
        state: {
          disablePrompt: true,
          isPickClaimType : true
        }
      });

    }catch (e) {

    }finally {
      setLoading(false);
    }

  }

  // const renderNameOfPurchasingChannel = () => {
  //   const purchaseChannel = getValues('purchasingChannel');
  //   switch (purchaseChannel) {
  //     case 'qcells':
  //       return 'Q CELLS';
  //     case 'distributor':
  //       return _.get(dataPv, 'nameOfPurchasingChannelDistributor.name');
  //     case 'other':
  //       return getValues('nameOfPurchasingChannelOther');
  //     default:
  //       return '';
  //   }
  // };


  const renderPurchasingChannel = purchasingChannelValue => {
    switch (purchasingChannelValue) {
      case 'qcells':
        return 'Q CELLS';
      case 'distributor':
        return 'Distributor';
      case 'other':
        return 'Other';
      default:
        return '';
    }
  };

  const renderNameOfPurchasingChannel = () => {
    const purchaseChannel = getValues('purchasingChannel');
    switch (purchaseChannel) {
      case 'qcells':
        return 'Q CELLS';
      case 'distributor':
        return _.get(dataPv, 'nameOfPurchasingChannelDistributor.name') + '' + `${_.get(dataPv, 'nameOfPurchasingChannelDistributor.name') === "Other" ? `-${_.get(dataPv, 'nameOfChanelOtherDistributor', '')}` : ''}`;
      case 'other':
        return getValues('nameOfPurchasingChannelOther');
      default:
        return '';
    }
  };

  const renderSerialNumber = () => {
    const choseTypeSerialUpload = getValues('choseTypeSerialUpload')

    if(choseTypeSerialUpload === '1') {
      return getValues('serialNumbers')?.map(item => item.serialNumber).toString()
    }

    if(choseTypeSerialUpload === '2') {
      return getValues('listSerialPick')?.map(item => item.serialNumber).toString()
    }
  }

  const renderCommissionDate = () => {
    let commissionDate = "";

    if(dataPv.projectInfo !== null) {
      commissionDate = moment(_.get(dataPv, 'projectInfo.operateDate', '')).format('YYYY-MM-DD')
    }else if(dataPv.choseTypeProject === '2') {
      commissionDate = moment(dataPv.projectPermissionDate).format('YYYY-MM-DD')
    }

    return commissionDate
  }


  function renderRegion(value) {
    const actions = {
      'United States' : 1,
      'Canada' : 2,
      'Latin America' : 3
    }

    return actions[value] || 1
  }

  function renderQuanlityPanel() {
    const choseTypeSerialUpload = getValues('choseTypeSerialUpload')

    if(choseTypeSerialUpload === '1') {
      return getValues('serialNumbers').length
    }

    if(choseTypeSerialUpload === '2') {
      return getValues('listSerialPick')?.length
    }
  }

  function renderLaborStatus(laborReimbursement, laborWarranty, choseTypeProject, chooseRefund, panelQualified) {

    if(choseTypeProject === '2') {
      return 'No'
    }

    if(laborReimbursement && laborWarranty && chooseRefund === '1') {
      return 'No'
    }

    if(!panelQualified) {
      return 'No'
    }

    if((!laborReimbursement && laborWarranty) || !laborReimbursement ) {
      return 'No'
    }

    return 'Yes'
  }

  const onSubmit = async () => {
    const currentData = getValues();
    let data = _.pickBy(currentData, value => {
      if (value === 'select') {
        return false;
      }

      if (!value) {
        return false;
      }

      if (_.get(value, 'length') === 0) {
        return false;
      }

      return true;
    });

    //make data call api submit

    const arrayImage = convertArrayClaim(
      filePictureOfAffectedModule, filePictureOfFront, filePictureOfBack,
      filePictureOfVoc, filePictureCloseUp, filePictureSitePlan,
      filePictureOfOverall, filePictureOfWireManagement, filePictureProofDelivery,
      filePictureImmediately, filePictureInVoice, fileProofOfPermissionToOperateDate,
      fileInvoiceForLaborReimbursement, fileBankAccountInfo, filePermissionOfDate,
      filePictureOfVmpp, filePictureIr
    )

    let attachments = {}

    if(arrayImage.length > 0) {
      attachments = {
        attachments : arrayImage.toString() || ''
      }
    }

    let projectId = {}

    if(data.projectInfo !== null) {
      projectId = {
        projectId : data.projectInfo?.id
      }
    }

    let idClaim = {}

    if(id !== '') {
      idClaim = {
        id
      }
    }

    const itemProduct = listProductPti.filter(item => item.name === data.projectProductName)

    try {

      const payloadPv = {
        ...data,
        ...idClaim,
        type: 1,
        folderId: keyUpload,
        title: renderLaborStatus(_.get(data, 'laborReimbursement', false), _.get(data, 'laborWarranty', false), data.choseTypeProject, data.chooseRefund, _.get(data, 'panelQualified', false)) === 'Yes' ? 'LW qualifying' : 'LW not qualifying',
        created: moment().format('YYYY-MM-DD'),
        submitterEmail: data.checkedEmail ? data.submitterEmail : '',
        description: data.description || '',
        projectAddress: data.choseTypeProject === '1' ? data.projectAddress : data.projectAddressManual?.label,
        customerName: data.projectNameSys || '',
        region: renderRegion(data.regionPv) || 1,
        distributorName: renderNameOfPurchasingChannel() || '',
        distributorContact: "",
        distributorCity: renderNameOfPurchasingChannel() || '',
        distributorPhone: data.phoneNumber || '',
        distributorEmail: data.emailPurchase || '',
        distributorAddress: data.installerAddress1 || "",
        powerClass: getValues('projectPowerClass') || '',
        claimType: data.claimType || '',
        productname: itemProduct.length > 0 ? itemProduct[0].id : '' ,
        quantity: renderQuanlityPanel() || 0,
        systemSize: data.choseTypeProject === '1' ? data.totalPvPowerInstalled : data.projectSystemSize || '',
        submitterAddress: data.installerAddress1 || '',
        submitterCompany: data.companyName || '',
        serials: renderSerialNumber(),
        poNumber: data.poNumber,
        systemtype: data.systemTypePv || '',
        installerName: data.companyName || '',
        installerAddress: data.installerCity || '',
        installerContact: data.installerContact || '',
        installerEmail: data.installerEmail || '',
        installerPhone: data.installerPhone || '',
        installerCity : data.installerCity || '',
        loginPortal: "",
        loginId: "",
        purchaseDate: data.purchaseDate ? moment(data.purchaseDate).format('YYYY-MM-DD') : '',
        installationDate: data.projectInstallationDate ? moment(data.projectInstallationDate).format('YYYY-MM-DD') : '',
        commissionDate: renderCommissionDate() || '',
        qualifyLaborWarranty : data.qualifyLaborWarranty  || false,
        project: data.projectNameSys || '',
        defectDate : data.defectDate ? moment(data.defectDate).format('YYYY-MM-DD') : '',
        nameOfChanelOtherDistributor : _.get(data, 'nameOfPurchasingChannelDistributor.name') === 'Other' && data.purchasingChannel === 'distributor' ? data.nameOfChanelOtherDistributor : '',
        ...attachments,
        ...projectId,
      }

      setLoading(true);


      const info = await claimService.saveClaim(payloadPv)
      notiSuccess('Claim Submitted Successfully!')
      history.push({
        pathname: '/claim/history',
        state: {
          disablePrompt: true
        }
      })

    }catch (e) {
      notiFailed('Claim failed!')
    }finally {
      setLoading(false)
    }




  }

  const onSubmitFailed = async errors => {
    showErrorMessage(errors, keyPv)
  };

  const handleGoToReview = () => {
    handleSubmit(() => setCurrentStep(4), onSubmitFailed)();
  };

  const renderStepPv = () => {

    switch(currentStep) {
      case 1:
        return (
          <IssuePV
            goBack={goBack}
            next={next}
            condition={condition}
            setListSerial={setListSerial}
            listSerial={listSerial}
            nextStep={nextStep}
            watch={watch}
            register={register}
            control={control}
            serials={serials}
            setSerials={setSerials}
            errors={errors}
            saleRegions={saleRegions}
            distributors={distributors}
            loading={loading}
            getValues={getValues}
            handleSaveDraft={handleSaveDraft}
            text={'Different from the above E-mail?'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            clearErrors={clearErrors}
            fileProofOfPermissionToOperateDate={fileProofOfPermissionToOperateDate}
            setFileProofOfPermissionToOperateDate={setFileProofOfPermissionToOperateDate}
            filePermissionOfDate={filePermissionOfDate}
            setFilePermissionOfDate={setFilePermissionOfDate}
            filePictureOfAffectedModule={filePictureOfAffectedModule}
            setFilePictureOfAffectedModule={setFilePictureOfAffectedModule}
            projects={projects}
            setValue={setValue}
            urlImageProof={urlImageProof}
            setFilesProof={setFilesProof}
          />
        )
      ;
       // i a san su wu lieu chi ba chieu su

      case 2:
          return (
            <Evidence
              goBack={goBack}
              next={next}
              handleSaveDraft={handleSaveDraft}
              loading={loading}
              keyUpload={keyUpload}
              setKeyUpload={setKeyUpload}
              // fileProofOfPermission={fileProofOfPermission}
              setFileProofOfPermission={setFileProofOfPermission}
              filePictureOfVoc={filePictureOfVoc}
              setFilePictureOfVoc={setFilePictureOfVoc}
              filePictureOfFront={filePictureOfFront}
              setFilePictureOfFront={setFilePictureOfFront}
              filePictureOfBack={filePictureOfBack}
              setFilePictureOfBack={setFilePictureOfBack}
              filePictureCloseUp={filePictureCloseUp}
              setFilePictureCloseUp={setFilePictureCloseUp}
              filePictureSitePlan={filePictureSitePlan}
              setFilePictureSitePlan={setFilePictureSitePlan}
              filePictureOfOverall={filePictureOfOverall}
              setFilePictureOfOverall={setFilePictureOfOverall}
              filePictureOfWireManagement={filePictureOfWireManagement}
              setFilePictureOfWireManagement={setFilePictureOfWireManagement}
              filePictureProofDelivery={filePictureProofDelivery}
              setFilePictureProofDelivery={setFilePictureProofDelivery}
              filePictureImmediately={filePictureImmediately}
              setFilePictureImmediately={setFilePictureImmediately}
              filePictureOfVmpp={filePictureOfVmpp}
              setFilePictureOfVmpp={setFilePictureOfVmpp}
              filePictureIr={filePictureIr}
              setFilePictureIr={setFilePictureIr}
              register={register}
              control={control}
              setValue={setValue}
              watch={watch}
              errors={errors}
              clearErrors={clearErrors}
              type={2}
            />
          )
      case 3:
          return (
            <PurchaseInfoPv
              goBack={goBack}
              next={next}
              handleSaveDraft={handleSaveDraft}
              loading={loading}
              register={register}
              control={control}
              distributors={distributors}
              watch={watch}
              errors={errors}
              setValue={setValue}
              keyUpload={keyUpload}
              setKeyUpload={setKeyUpload}
              fileInvoiceForLaborReimbursement={fileInvoiceForLaborReimbursement}
              setFileInvoiceForLaborReimbursement={setFileInvoiceForLaborReimbursement}
              setFilePictureInVoice={setFilePictureInVoice}
              filePictureInVoice={filePictureInVoice}
              fileLaborWarranty={fileLaborWarranty}
              setFileLaborWarranty={setFileLaborWarranty}
              clearErrors={clearErrors}
              handleGoToReview={handleGoToReview}
              fileBankAccountInfo={fileBankAccountInfo}
              setFileBankAccountInfo={setFileBankAccountInfo}
            />
          )
      case 4:
        return (
          <ReviewPv
            loading={loading}
            goBack={goBack}
            goToStep={goToStep}
            nextStep={nextStep}
            getValues={getValues}
            type={2}
            watch={watch}
            errors={errors}
            errorInfo={errorInfo}
            urlBankAccountInfo={fileBankAccountInfo}
            urlInvoiceForLaborReimbursement={fileInvoiceForLaborReimbursement}
            urlImageProofOfPermisstion={filePermissionOfDate}
            urlImageAffectModule={filePictureOfAffectedModule}
            urlImageOfVoc={filePictureOfVoc}
            urlImageOfFont={filePictureOfFront}
            urlImageOfBack={filePictureOfBack}
            urlImageClose={filePictureCloseUp}
            urlImageLayoutPlan={filePictureSitePlan}
            urlImageOverall={filePictureOfOverall}
            urlImageWireManagement={filePictureOfWireManagement}
            urlImageDelivery={filePictureProofDelivery}
            urlImageImmed={filePictureImmediately}
            urlImageVmpp={filePictureOfVmpp}
            urlImageIr={filePictureIr}
            urlImageInvoice={filePictureInVoice}
            isDraft={isDraft}
            disabled={!formState?.isDirty || !formState?.isValid}
            urlImageProof={urlImageProof}
            setFilesProof={setFilesProof}
            handleSubmit={handleSubmit(onSubmit, onSubmitFailed)}
          />
        )
      default:
        return (
          <IssuePV
            goBack={goBack}
            next={next}
            watch={watch}
            register={register}
            control={control}
            errors={errors}
            loading={loading}
            getValues={getValues}
            handleSaveDraft={handleSaveDraft}
            text={'Different from the above E-mail?'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            clearErrors={clearErrors}
            filePermissionOfDate={filePermissionOfDate}
            setFilePermissionOfDate={setFilePermissionOfDate}
            filePictureOfAffectedModule={filePictureOfAffectedModule}
            setFilePictureOfAffectedModule={setFilePictureOfAffectedModule}
            projects={projects}
            setValue={setValue}
          />
        )
    }
  }

  return(
    <div>
      <ProgressBar currentStep={currentStep} goToStep={goToStep} isEnableClick={true} type={2}/>
      <ContainerClaimFormPv>
        {renderStepPv()}
      </ContainerClaimFormPv>
    </div>
  )
}

export default PvPage;
