import React, { useRef, useState, useEffect } from "react";
import Async from "react-select/async";
import IconSelect from '../../../icons/project_arrow.svg';
import classNames from 'classnames';
import useStyles from './useStyles';
import ReactSelect from 'react-select';

const ReactSelectDropdownIndicator = () => {
  return (
    <div style={{ marginRight: '15px' }}>
      {/*<img src={IconSelect} width="12"/>*/}
    </div>
  )
};

const MySelect = ({
                    label,
                    options,
                    asyncSelect,
                    loadOptions,
                    components,
                    placeholder,
                    valueAddress,
                    fixedHeight,
                    onSelect,
                    ...props
                  }) => {
  const ref = useRef();
  const asyncRef = useRef();

  const classes = useStyles();

  const [fieldValue, setFieldValue] = useState();
  const [openOption, setOpenOption] = useState(false);

  useEffect(() => {
    setFieldValue(props.value)
  }, [props?.value?.label])

  // Gets called when an option is chosen or created.
  const onChange = value => {
    setFieldValue(value);
    if (onSelect) onSelect(value);
  };

  // Toggle menu by presence of input.
  // Reset value if input is emptied.
  const onInputChange = (value, context) => {
    if (context && context.action === 'input-change') {
      setOpenOption(true);
    } else if (context && context.action === 'menu-close') {
      setOpenOption(false);
    }
    if (!value && context.action === "input-change") {
      onChange(null);
    }
  };

  // Restore input value.
  const onFocus = () => {
    if (fieldValue) {
      asyncRef.current.state.inputValue = fieldValue.label;
      asyncRef.current.select.state.inputValue = fieldValue.label;
      asyncRef.current.handleInputChange(fieldValue.label);
    }
  };

  // Close menu when pressing enter. Submit does not work on mobile.
  const onKeyDown = event => {
    if (event.keyCode === 13) {
      onMenuClose();
    }
  };

  // Blur select element to trigger onFocus on next click.
  const onMenuClose = () => {
    asyncRef.current.select.blur();
  };

  return (
    <>
      {/*<div ref={ref} />*/}
      <Async
        ref={asyncRef}
        {...props}
        value={fieldValue}
        className={classNames([classes.multiSelect], {
        })}
        loadOptions={loadOptions ? loadOptions : () => null}
        placeholder={placeholder ? placeholder : "Street Address"}
        searchPromptText="Start Typing"
        autoload
        isSearchable={true}
        searchable
        cacheOptions
        isClearable
        defaultMenuIsOpen={false}
        menuIsOpen={openOption}
        // Hook into events to make the editing work.
        onChange={onChange}
        onInputChange={onInputChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: ReactSelectDropdownIndicator
        }}
        styles={{
          placeholder: provided => ({
            ...provided,
            color: '#C4C4C4'
          }),
          singleValue: provided => ({
            ...provided,
            fontFamily: 'Actual',
            fontSize: '14px',
            color: '#333333',
            fontWeight: 400,
            fontStyle: 'normal'
          })
        }}
        onMenuClose={onMenuClose}
      />
    </>
  );
};

export default MySelect;
