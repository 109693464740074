import React from 'react';
import { Switch, Route } from 'react-router-dom';
// import AuthRoute from '../AuthRoute';
import Account from './account';

export default function Modules() {
  return (
    // <AuthRoute path="/support/faq">
      <Switch>
        <Route
          exact
          path="/support/faq/:faqTypeId"
          component={Account}
        />
      </Switch>
    // </AuthRoute>
  );
}
