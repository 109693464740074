import React, { useEffect, useState } from 'react';
import { Box, TextareaAutosize, Radio, RadioGroup, TextField, FormControl, FormControlLabel, Checkbox, Button, Menu, MenuItem } from '@material-ui/core';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import CustomButton from '../../../common/components/button';
import useStyles from './useStyles';
import axios from '../../../utils/axios';
import { notiFailed } from '../../../utils/helpers';
import config from '../../../config';
import {ViewList} from '@material-ui/icons';
import CustomLoading from '../../../common/components/CustomLoading';
import NumberFormat from "react-number-format";
import CheckValidate from '../../../common/components/CheckValidate';
import CustomDatePicker from '../../projects/components/CustomDatePicker';
import Popover from '../../../common/components/Popover';
import ImageUploader from '../components/UploadImage';
import _ from 'lodash';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable'
import IconSelect from '../../../icons/arrow.svg';
import MessageSpecial from './MessageSpecial';
import ViewProjectDetail from './ViewProjectDetail';
import IconEdit from '../../../icons/edit_icon.svg';
import ProjectManualEss from './ProjectManualEss';
import ShowMessage from './ShowMessage';
import OpenImageIcon from '../../../config/images/open_image_icon.svg';
import CardSerialNumber from './CardSerialNumber';
import UploadFileExcel from './UploadFIleExcel';
import UploadFileExcelPv from './UploadFileExcelPv';
import ProjectManualPanel from './ProjectManualPv';
import TextButton from '../../../common/components/button/TextButton';
import ViewProjectDetailPanel from './ViewProjectDetailPanel';
import ImageModal from '../../projects/components/ImageModal';
import ShowMessageLine from './ShowMessageLine';
import { projectService } from '../../../services';
import MessageShowProject from './MessageShowProject';
import MultiUploader from "./UploadMultiType";


const ReactSelectDropdownIndicator = () => {
  return (
    <div style={{ marginRight: '15px' }}>
      <img src={IconSelect} width="14"/>
    </div>
  )
}
const { baseUrl } = config;

function ZipCodeNumberFormat(props) {
  return (
    <NumberFormat format="#####" {...props} />
  )
}

function IssuePv({next, register, watch ,control, errors, handleSaveDraft, handleGoToReview, goBack ,setValue, loading, getValues, text, saleRegions,
  filePictureOfAffectedModule, setFilePictureOfAffectedModule, goBackInfo, nextStep, distributors, setFilesProof, urlImageProof, setListSerial, listSerial, condition,
  filePermissionOfDate, setFilePermissionOfDate, setSerials, serials, fileProofOfPermissionToOperateDate, setFileProofOfPermissionToOperateDate,
  keyUpload, setKeyUpload, clearErrors, projects}) {
  const classes = useStyles();

  // const [saleRegions, setSaleRegions] = useState([]);
  const [projectActive, setProjectActive] = useState({});
  // const [urlImageProof, setFilesProof] = useState([]);
  const [modalUrl, setModalUrl] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [listSerialPick , setListSerialPick] = useState([]);
  const projectInfo = watch('projectInfo');
  const choseTypeProject = watch('choseTypeProject');
  const choseTypeSerialUpload = watch('choseTypeSerialUpload');
  const showMess = watch('showMess');
  const typePv = watch('type')
  const serialNumbers = watch('serialNumbers');
  const validateProofOfDate = watch('validateProofOfDate')
  const listSerialPickData = watch('listSerialPick');
  const region = watch('region');
  const isInValidUpload = watch('isInValidUpload');
  const isDuplicateUpload = watch('isDuplicateUpload');
  const isDuplicateOtherProject = watch('isDuplicateOtherProject');



  const isUploadSerialNumber = watch('isUploadSerialNumber')

  useEffect(() => {
    serialNumbers?.length > 0 ? setValue('isserialNumber', 'done') : setValue('isserialNumber', '')
  }, [serialNumbers?.length])

  const renderAddress = (array = []) => {
    const newArray = _.remove(array, value => !!value || value === 'select');
    return newArray.length > 0 ? _.join(newArray, ', ') : '';
  };


  async function getDetailProjectActive(id) {
    const info = await projectService.getDetailProject(id);

    let projectInfo = info?.data
    setProjectActive(projectInfo)

    setValue('projectSystemType', projectInfo?.systemType);
    setValue('projectNameSys', projectInfo?.projectName);
    setValue('projectAddress', renderAddress([projectInfo?.projectAddress, projectInfo?.projectAddress2, projectInfo?.projectCity, projectInfo?.projectState, projectInfo?.projectZipCode]));
    setValue('systemType', projectInfo?.systemType);
    setValue('projectInstallationDate', projectInfo?.installationDate);
    setValue('projectPermissionDate', projectInfo?.operateDate);
    setValue('region', _.get(projectInfo, 'region', 'US'));
    //setValue('laborReimbursement', _.get(projectInfo, 'laborReimbursement', false))
    setValue('laborWarranty', _.get(projectInfo, 'qualifyLaborWarranty', false))
    setValue('panelLaborReimbursement', _.get(projectInfo, 'panelLaborReimbursement', false))
    setValue('batteryLaborReimbursement', _.get(projectInfo, 'batteryLaborReimbursement', false))
    setValue('panelQualified', _.get(projectInfo, 'panelQualified', false))

    if(_.get(projectInfo, 'panelLaborReimbursement', false)) {
      setValue('laborReimbursement', true)
    }

    if(_.get(projectInfo, 'panelLaborReimbursement', false) && _.get(projectInfo, 'qualifyLaborWarranty', false)) {
      setValue('validateProofOfDate', true)
    }

    if(_.get(projectInfo, 'region', 'select') !== null && _.get(projectInfo, 'region', 'select') !== '') {
      setValue('regionPv', _.get(projectInfo, 'region', 'select'));
      clearErrors('regionPv')
    } else {
      setValue('regionPv', 'select')
    }
    setValue('totalPvPowerInstalled', projectInfo?.systemSize);
    if(projectInfo?.moduleModel !== 'select' && projectInfo?.moduleModel !== '' && projectInfo?.moduleModel !== null) {
      setValue('projectProductName', projectInfo?.moduleModel)
    }else {
      setValue('projectProductName', projectInfo?.serialNumbers?.length > 0 && projectInfo?.serialNumbers[0].productName || '');
    }

    if(projectInfo?.modulePowerClass !== 'select' && projectInfo?.modulePowerClass !== '' && projectInfo?.modulePowerClass !== null) {
      setValue('projectPowerClass', projectInfo?.modulePowerClass)
    }else {
      setValue('projectPowerClass', projectInfo?.serialNumbers?.length > 0 && projectInfo?.serialNumbers[0].volume);
    }

    setValue('projectInverterSerialNumber', projectInfo?.serialNumbers?.length > 0 && projectInfo?.serialNumbers[0].serialNumber || '');

    const moduleSN = _.filter(_.get(projectInfo, 'serialNumbers'), s => s.type === 'modules');
    setListSerial(moduleSN)

    setValue('choseTypeSerialUpload', moduleSN.length === 0 ? '2' : '1')
    //setValue('projectInfo', projectInfo);
    //setValue('serialNumbers', []);
    setValue('purchasingChannel', projectInfo?.purchasingChannel ? projectInfo?.purchasingChannel : 'select');
    if (projectInfo?.purchasingChannel === 'distributor') {
      const distributorInfo = distributors.find(d => d.name === projectInfo?.nameOfPurchasingChannel)
      setValue('nameOfPurchasingChannelDistributor', distributorInfo);
      if(_.get(distributorInfo, 'name') === 'Other') {
        setValue('nameOfChanelOtherDistributor', projectInfo?.nameOfChanelOtherDistributor)
      }

    } else if (projectInfo?.purchasingChannel === 'other') {
      setValue('nameOfPurchasingChannelOther', projectInfo?.nameOfPurchasingChannel);
    }

    clearErrors('region')

    //setValue('projectPowerClass', projectId?)
    if (_.trim(_.get(projectInfo, 'proofOfOperateDate'))) {
      setFilesProof(_.map(_.split(_.get(projectInfo, 'proofOfOperateDate'), '|'), p => ({ url: p })));
      setValue('validateProofOfDate', false)
    }else {
      setFilesProof([]);
      //setValue('validateProofOfDate', true)
    }
  }

  useEffect(() => {
    if(projectInfo !== null && projectInfo !== undefined) {
      getDetailProjectActive(projectInfo?.id)
    }
  }, [projectInfo?.id])

  function handleRemoveSerialNumber(serial) {
    const listSerialDone = listSerialPickData.filter(item => {
      return item.serialNumber !== serial
    })

    setListSerialPick(listSerialDone);
    setValue('listSerialPick', listSerialDone)
    // setValue('serialNumbers', listSerialDone);
  }

  /**
   * case manual
   *  -> upload -> lay serial number all o excel
   *  -> case project
   *  -> user -> pick project -> serial A, serial B -> upload serial B
   *
   * */

  useEffect(() => {
    if(choseTypeProject === '1' && serials?.length > 0) {
      if(_.map(listSerial, 'serialNumber').length === 0) {
        setValue('listSerialPick', serials)
      }else {
        const listSerialValid = serials?.filter(item => _.map(listSerial, 'serialNumber').includes(item.serialNumber))
        setListSerialPick(listSerialValid)
        setValue('listSerialPick', listSerialValid)
      }

    }


    serials?.length > 0 && choseTypeProject === '2' && setValue('listSerialPick', serials);

    //listSerial?.length === 0 ? setValue('choseTypeSerialUpload', '2') : setValue('choseTypeSerialUpload', '1')


  },[serials, listSerial])


  useEffect(() => {
    listSerialPickData && listSerialPickData?.length > 0 ? setValue('isUploadSerialNumber' , 'done') : setValue('isUploadSerialNumber', '')
  }, [listSerialPickData?.length])


  function renderValidateProject() {
    switch (choseTypeProject) {
      case '1':
        return(
          <CheckValidate value={watch('projectInfo')} error={errors.projectInfo}/>
        )
      case '2':
        return (
          <>

          </>
        )
    }
  }

  function renderValidateFileSerial() {
    switch (choseTypeSerialUpload) {
      case '1':
        return(
          <CheckValidate value={watch('serialNumbers')} error={errors.serialNumbers}/>
        )
      case '2':
        return (
          <CheckValidate value={watch('isUploadSerialNumber')} error={errors.isUploadSerialNumber}/>
        )
    }
  }

  function handleChange({selectedOption}) {
    // console.log(selectedOption, 'selecyOOOO')
  };


  function renderMessage() {
    if(isInValidUpload && (isDuplicateUpload || isDuplicateOtherProject)) {
      return <ShowMessage textInfo={'The serial number you registered for this project were verified as invalid and duplicate. To continue with this claim, please double-check the serial number of the affected modules and upload them here.'}/>
    }
    if(isInValidUpload) {
      return <ShowMessage textInfo={'The serial number you registered for this project were verified as invalid. To continue with this claim, please double-check the serial number of the affected modules and upload them here'}/>
    }

    if(isDuplicateUpload || isDuplicateOtherProject) {
      return <ShowMessage textInfo={'The serial number you registered for this project were verified as duplicate. To continue with this claim, please double-check the serial number of the affected modules and upload them here'}/>
    }
  }

  return(
    <div>
      <h1 style={{fontWeight: 500, fontSize: 16}}>Issue</h1>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }} />
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
            <span className={classes.sectionTitle}>
                Defect Discovery Date
            </span>
        </div>
        <div>
          <Controller
            name="defectDate"
            control={control}
            render={(
              { onChange, value }
            ) => (
              <CustomDatePicker
                fullWidth
                size="small"
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                inputVariant="outlined"
                value={value}
                onChange={date => onChange(date)}
                className={classNames([classes.datePicker], {
                  [classes.errorDatePickerField]: errors.defectDate
                })}
                views={['year', 'month', 'date']}
                maxDate={new Date()}
              />
            )}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
            Description of <br />Your Issue  <span>*</span>
          </span>
        </div>
        <div className={classes.textArea}>
          <Controller
            name="description"
            control={control}
            render={(
              { onChange, value }
            ) => (
              <TextareaAutosize
                name="description"
                onChange={(value) => onChange(value)}
                aria-label="minimum height"
                value={value}
                className={classNames([classes.inputTextArea], {
                  [classes.inputTextAreaError]: errors.description
                })} placeholder="Description"/>
            )}
          />
        </div>
        <CheckValidate value={watch('description')} error={errors.description}/>
      </div>

      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }} />
      <div className={classes.sectionContainerSys}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Project <span>*</span>
          </span>
        </div>
        <div>
          <Controller
            name="choseTypeProject"
            control={control}
            as={
              <RadioGroup aria-label="position">
                <FormControlLabel
                  value="1"
                  control={<Radio icon={<div className={classes.radio}/>} checkedIcon={<div className={classes.radioOn}/>} />}
                  label="Search for Project"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio onChange={() => {
                    setValue('projectId', null)
                    setValue('projectSystemType', 'select');
                    setValue('projectNameSys', '');
                    setValue('projectAddressManual', '');
                    setValue('projectSystemSize', '');
                    setValue('systemType', 'select');
                    setValue('projectInstallationDate', null);
                    setValue('projectPermissionDate', null);
                    setValue('totalPvPowerInstalled', '');
                    setValue('projectProductName', '');
                    setValue('projectInverterSerialNumber', '');
                    setValue('projectPowerClass', '');
                    setValue('projectInfo', null);
                    setValue('serialNumbers', []);
                    setValue('regionPv', 'select');
                    setValue('validateProofOfDate', false)
                    setFilesProof([]);
                    setListSerial([]);
                    setFilePermissionOfDate([])
                  }
                  } icon={<div className={classes.radio}/>} checkedIcon={<div className={classes.radioOn}/>} />}
                  label="Manual Input"
                  labelPlacement="end"
                />
              </RadioGroup>
            }
          />
        </div>
        <div>
          {renderValidateProject()}
        </div>
      </div>

      {choseTypeProject === '1' && <div className={classes.sectionContainerSys}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          <Controller
            name="projectInfo"
            key="name-project-claim"
            control={control}
            as={
              <ReactSelect
                getOptionLabel={option => _.trim(option.projectAddress)}
                getOptionValue={option => _.trim(option.id)}
                isSearchable
                isClearable
                isMulti={false}
                options={projects}
                className={classNames([classes.multiSelect], {
                  [classes.errorReactSelect]: errors.nameOfPurchasingChannelDistributor
                })}
                classNamePrefix="select"
                placeholder="Search by Address"
                defaultMenuIsOpen={false}
                onChange={handleChange}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: ReactSelectDropdownIndicator
                }}
                styles={{
                  placeholder: provided => ({
                    ...provided,
                    color: '#C4C4C4'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    fontFamily: 'Actual',
                    fontSize: '14px',
                    color: '#333333',
                    fontWeight: 400,
                    fontStyle: 'normal'
                  })
                }}
              />
            }
          />
        </div>
      </div>}
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          {showMess && <div style={{marginBottom : 10}}>
            <MessageShowProject id={_.get(condition, 'id')} type={1}/>
          </div>}
          <MessageSpecial/>
        </div>
      </div>


      {choseTypeProject === '1' && projectInfo !== null && <div className={classes.sectionContainerSys}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          <ViewProjectDetailPanel handleSaveDraft={handleSaveDraft} project={projectActive}/>
        </div>

      </div>}
      {choseTypeProject === '2' && <div className={classes.sectionContainerProjectDetail}>
          <div/>
          <ProjectManualPanel saleRegions={saleRegions} setValue={setValue} control={control} watch={watch} errors={errors} register={register}/>
      </div>}

      <div className={classes.sectionContainerSys}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Affected Module Serial Number(s) <span>*</span>
          </span>
        </div>
        <div>
          <Controller
            name="choseTypeSerialUpload"
            control={control}
            as={
              <RadioGroup aria-label="position">
                {listSerial.length > 0 && <FormControlLabel
                  value="1"
                  control={<Radio onChange={() => {
                    setListSerialPick([]);
                    setValue('serialNumbers', [])
                    setValue('isInValidUpload', false)
                    setValue('isDuplicateUpload', false)
                    setValue('isDuplicateOtherProject', false)
                  }
                  } icon={<div className={classes.radio}/>} checkedIcon={<div className={classes.radioOn}/>} />}
                  label="Search and select"
                  labelPlacement="end"
                />}
                <FormControlLabel
                  value="2"
                  control={<Radio onChange={() => {
                    setValue('serialNumbers', []);
                  }
                  } icon={<div className={classes.radio}/>} checkedIcon={<div className={classes.radioOn}/>} />}
                  label="Upload Excel File"
                  labelPlacement="end"
                />
              </RadioGroup>
            }
          />
        </div>
        <div>
          {renderValidateFileSerial()}
        </div>
      </div>
      {choseTypeSerialUpload === '1' && <div className={classes.sectionContainerSys}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          <Controller
            name="serialNumbers"
            key="name-project-claim"
            control={control}
            as={
              <ReactSelect
                getOptionLabel={option => _.trim(option.serialNumber)}
                getOptionValue={option => _.trim(option.serialNumber)}
                isSearchable
                isClearable
                isMulti
                options={listSerial}
                className={classNames([classes.multiSelect], {
                  [classes.errorReactSelect]: errors.serialNumbers
                })}
                classNamePrefix="select"
                placeholder="Search and select by..."
                defaultMenuIsOpen={false}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: ReactSelectDropdownIndicator
                }}
                styles={{
                  placeholder: provided => ({
                    ...provided,
                    color: '#C4C4C4'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    fontFamily: 'Actual',
                    fontSize: '14px',
                    color: '#333333',
                    fontWeight: 400,
                    fontStyle: 'normal'
                  })
                }}
              />
            }
          />
        </div>
      </div>}
      {choseTypeSerialUpload === '2' &&
      <div className={classes.sectionContainer} style={{marginBottom : 10}}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          <UploadFileExcelPv
            setSerials={setSerials}
            setValue={setValue}
          />

          <div>
            <a
              style={{ textDecoration: 'none' }}
              href={config.urlTemplateSerialNumber}
            >
              <TextButton className={classes.downloadTemplate}>
                Download Template
              </TextButton>
            </a>
          </div>
        </div>

      </div>}

      <div className={classes.sectionContainer} style={{marginBottom : 0}}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          {renderMessage()}
        </div>
      </div>


      {choseTypeSerialUpload === '2' && <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}/>
        <div className={classes.wrapItemSerial}>
          {listSerialPickData?.map((item, index) => {
            return <CardSerialNumber key={index} handleRemoveSerialNumber={handleRemoveSerialNumber} serial={item.serialNumber}/>
          })}

        </div>

      </div>}




      {((choseTypeProject === '1' && projectInfo !== null) || (choseTypeProject === '2')) && <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
          Proof of <br /> Permission-to- <br />Operate Date
          </span>
        </div>
        <div>
          {choseTypeProject === '1' && projectInfo !== null && urlImageProof?.length > 0 && <div className={classes.reviewSectionContentImage}>
            <div className={classes.images}>
              { urlImageProof?.map(p => (
                <div key={p.url}>
                  <img src={p.url} className={classes.image} />
                  <div className={classes.overlay} role="overlay">
                    <img
                      src={OpenImageIcon}
                      onClick={() => {
                        setModalUrl(p.url);
                        setOpenModal(true);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>}
          {((choseTypeProject === '1' && urlImageProof?.length === 0 && projectInfo !== null) || (choseTypeProject === '2')) && <MultiUploader
            files={filePermissionOfDate}
            setFiles={setFilePermissionOfDate}
            fileName={'proof_of_permission_to_operate_date'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            errors={errors.isUploadProofDate}
            clearErrors={clearErrors}
            name={'proof_of_permission_to_operate_date'}
          /> }
          {urlImageProof.length === 0 && <ShowMessage textInfo={'Attach the copy of the document from the county after the system inspection is completed.'}/>}
        </div>
        <div>
          {validateProofOfDate && <CheckValidate value={watch('isUploadProofDate')} error={errors.isUploadProofDate}/>}
        </div>
      </div>}



      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
            <span className={classes.sectionTitle}>
              Picture of <br />Affected Module <br /> Label(s)
              <span>*</span> <br />
            </span>
        </div>
        <div>
          <div className={classes.wrapUploadEx}>
            <MultiUploader
              files={filePictureOfAffectedModule}
              setFiles={setFilePictureOfAffectedModule}
              fileName={'picture_of_affected_module_labels'}
              keyUpload={keyUpload}
              setKeyUpload={setKeyUpload}
              errors={errors.pictureOfModuleLabel}
              name={'pictureOfModuleLabel'}
              clearErrors={clearErrors}
            />
          </div>
          <div>
            <ShowMessageLine
              content2={'The serial number label can be found on the back side of the panel or on the side frame of the panel.'}
              content3={'Please make sure the serial numbers and other details are legible in the picture.'}
            />
          </div>
        </div>
        <CheckValidate value={watch('pictureOfModuleLabel')} error={errors.pictureOfModuleLabel}/>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
            Region<span>*</span>
          </span>
        </div>
        <div>

          <Controller
            name="regionPv"
            control={control}
            as={
              <select
                disabled={projectInfo !== null && choseTypeProject === '1'}
                className={classNames([classes.selectField],[projectInfo !== null && choseTypeProject === '1' && classes.bgRegion], {
                  [classes.errorSelectField]: errors.region
                })}
              >
                <option value={'select'}>Please Select</option>
                <option value={'United States'}>United States</option>
                <option value={'Canada'}>Canada</option>
                <option value={'Latin America'}>Latin America</option>
              </select>
            }
          />

        </div>
        <CheckValidate value={watch('regionPv')} error={errors.regionPv}/>
      </div>


      <ImageModal
        open={openModal}
        url={modalUrl}
        name={modalUrl ? modalUrl.substring(modalUrl.lastIndexOf('/') + 1) : ''}
        handleClose={() => {
          setOpenModal(false);
          setModalUrl('');
        }}
      />

      <p className={classes.requiredText}>* Required</p>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }}/>
      <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
        <div>
          <CustomButton color="secondary" className="saveDraftBtn" onClick={nextStep}>Back</CustomButton>
        </div>
        <div>
          <CustomButton onClick={handleSaveDraft} color="secondary" className="saveDraftBtn" disabled={loading}>
            { loading ? <CustomLoading size={16} color="#009EE3" /> : 'Save Draft' }
          </CustomButton>
          <CustomButton onClick={next}>Next</CustomButton>
        </div>
      </Box>
    </div>
  )
}

export default IssuePv;
