import styled from 'styled-components';
import {
  lightThemeColor,
  primaryLightThemeColor,
  secondaryLightThemeColor,
  secondaryDarkThemeColor
} from '../../../utils/colors';
import Text from '../text';
import React, { useState } from 'react';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
import RightArrowIcon from '@material-ui/icons/ChevronRight';
import defaultImage from './../../../icons/product_default_image.png';
import _ from 'lodash';

const RecommendedText = styled(Text)`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: ${primaryLightThemeColor};
  color: ${lightThemeColor};
  padding: 0.25rem;
`;


const AnhDTProductImages = props => {
  const [index, set_index] = useState(0);
  const [noimage, set_noimage] = useState(true);
  let currentImage = props.images[index];

  if (
    _.isEmpty(_.get(props, 'images[0]')) &&
    _.isEmpty(_.get(props, 'images[0].imageUrl'))
  ) {
    return (
      <div className={`wr-images ${props.soldOut ? 'soldout' : ''}`}>
        <img className="image1" src={defaultImage} alt="" />
      </div>
    );
  } else {
    let src = _.get(currentImage, 'imageUrl');
    if (src.indexOf('http') == 0) {
    } else {
      src = `${process.env.REACT_APP_QPARTNER_LEGACY_IMAGE_BASE_URL}${src}`;
    }
    return (
      <div className={`wr-images ${props.soldOut ? 'soldout' : ''}`}>
        {props.aurora && <div className={'new-aurora'}>NEW</div>}
        <img className="image1" src={src} alt="" />
        {props.images.length >= 2 && (
          <React.Fragment>
            <div
              onClick={e => {
                e.stopPropagation();
                if (index >= 1) {
                  set_index(index - 1);
                }
              }}
              className={`btn6 prev-btn ${index <= 0 ? 'disabled-btn' : ''}`}
            >
              <LeftArrowIcon></LeftArrowIcon>
            </div>
            <div
              onClick={e => {
                e.stopPropagation();
                if (index < props.images.length - 1) {
                  set_index(index + 1);
                }
              }}
              className={`btn6 next-btn ${
                index >= props.images.length - 1 ? 'disabled-btn' : ''
              }`}
            >
              <RightArrowIcon></RightArrowIcon>
            </div>
          </React.Fragment>
        )}
        {props.recommended && <RecommendedText>Recommended</RecommendedText>}
      </div>
    );
  }
};

export default AnhDTProductImages;
