import React, { useEffect, useState } from 'react';
import Banner from '../../common/components/Banner';
import RegistrationBanner from '../../config/images/registration-banner.jpg';
import BreadCrumb2 from '../../common/components/BreadCrumb2';
import { useAuth } from '../../contexts/auth';
import { makeStyles } from '@material-ui/core';
import ReviewInfo from './components/ReviewInfo';
import SystemInfo from './components/SystemInfo';
// import UserInfo from './components/UserInfo';
import ClaimInfo from './components/ClaimInfo';
import Issue from './components/Issue';
import SoftwareVersion from './components/SoftwareVersion';
import FieldDetail from './components/FieldDetail';
import NavBar from './components/NavBar';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import './index.css';
import PurchaseInfo from './components/PurchaseInfo';
import { notiFailed } from '../../utils/helpers';
import _ from 'lodash';
import IssuePV from './components/IssuePV';
import PurchaseInfoPv from './components/PurchaseInfoPv';
import styled from 'styled-components';
import RouteLeavingGuard from '../registration/RouteLeavingGuard';
import useExitPrompt from '../../hooks/useExitPrompt';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  containerClaim: {
    width: '80%',
    margin: '60px auto 70px auto',
    display: 'grid',
    gridTemplateColumns: '260px auto',
    gridColumnGap: '71px'
  },
  [theme.breakpoints.between('xs', 'md')] : {
    width: '90%',
    gridTemplateColumns: 'auto',
    gridColumnGap: '0px'
  }
}));

const ContainerClaimForm = styled.div `
    width: 80%;
    margin: 36px auto 70px auto;
    display: grid;
    grid-template-columns: 260px auto;
    grid-column-gap: 71px;
    
    @media (max-width: 1366px) {
      grid-template-columns: auto;
      grid-column-gap: 0px;
      width: 90%;
    }
`

const schemaEss = yup.object().shape({
  title : yup.string().required('Title is required'),
  projectAddress: yup.string().required("Address is required"),
  projectAddress2: yup.string().required("Address 2 is required"),
  projectCity: yup.string().required("City is required"),
  projectState: yup.string().notOneOf(['select'], 'State is required'),
  projectZipCode: yup.string().required("Zipcode is required"),
  contactName : yup.string().required('Contact Name is required'),
  email: yup.string().required('Email is required').matches(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/, 'Invalid email address'),
  phoneNumber: yup.string().required("Contact number is required").matches(/^[0-9]*$/, "This entry can only contain numbers (e.g., 9497774444)."),
  contactEmail : yup.string().required('Contact Email is required'),
  claimType: yup.string().notOneOf(['select'], 'Claim  type is required'),
  description : yup.string().required('Description of your issue'),
  otherEvidence : yup.string().required('Other evidence is required'),
  project : yup.string().notOneOf(['Please select'], 'Project  type is required'),
  projectName : yup.string().required('Project Name is required'),
  systemType: yup.string().required('System Type is required'),
  inverterSerialNumber : yup.string().required('Inverter serial number is required'),
  name : yup.string().required('Name is required'),
  inverterPower : yup.string().required('Inverter power is required'),
  pocketWifi : yup.string().required('Pocket WiFi SW is required'),
  batteriesSerialNumber : yup.string().required('Batteries Serial Number'),
  batteryType : yup.string().required('Battery Type is required'),
  numberOfBatteries : yup.string().required('Number of batteries is required'),
  totalPvPowerInstalled : yup.string().required('Total PV Power installed is required'),
  distributorName : yup.string().required('Distributor Name is required')
  // installationDate: yup.mixed().nullable().notOneOf([null], 'Installation date is required'),
  // moduleProductType: yup.string().notOneOf(['select'], 'Product type is required'),
  // moduleSerialNumber: yup.array().min(1, 'Serial Number is required'),
  // systemType: yup.string().when('battery', { is: value => value === '1', then: yup.string().notOneOf(['select'], 'System type is required') }),
  // batteryProductType: yup.string().when('battery', { is: value => value === '1', then: yup.string().notOneOf(['select'], 'Battery product type is required') }),
  // batteryCapacity: yup.string().when('battery', {
  //   is: value => value === '1',
  //   then: yup.string().when('moduleProductType', {
  //     is: value => value === 'qcells',
  //     then: yup.string().notOneOf(['select'], 'Battery capacity is required')
  //   })
  // }),
  // purchasingChannel: yup.string().notOneOf(['select'], 'Purchasing channel is required'),
  // nameOfPurchasingChannelDistributor: yup.string().when('purchasingChannel', { is: value => value === 'distributor', then: yup.string().notOneOf(['select'], 'Name of purchasing channel is required') }),
  // nameOfPurchasingChannelOther: yup.string().when('purchasingChannel', { is: value => value === 'other', then: yup.string().required('Name of purchasing channel is required') }),
  // installationZipCode: yup.string().required("Installation zipcode is required"),
  // firstName: yup.string().required('First Name is required'),
  // lastName: yup.string().required('Last Name is required'),

});

const schemaPV = yup.object().shape({

})

function EditClaim() {
  const classes = useStyles();
  const user = useAuth();
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(0);
  const [isReview, setIsReview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schema, setChema] = useState(schemaEss);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  const { register, handleSubmit, errors, control, watch, setValue, getValues, formState } = useForm({
    resolver : yupResolver(schemaEss),
    shouldUnregister: false,
    shouldFocusError: false,
    enableReinitialize: true,
    defaultValues: {
      projectState: 'select',
      installationDate: null,
      claimType : 'select',
      operateDate: null,
      selectedContactNumber: '1',
      purchasingChannel: 'select',
      nameOfPurchasingChannelDistributor: 'select',
      moduleProductType: 'select',
      battery: '1',
      systemType: 'select',
      batteryProductType: 'select',
      batteryCapacity: 'select',
      moduleSerialNumber: [],
      email : _.get(user, 'email'),
      installer_name : _.get(user , 'fullName'),
      installer_city : 'city1',
      installer_address1 : 'address1',
      installer_address2 : 'address2',
      installer_state : 'Alaska',
      installer_zipcode : '2313213',
      installer_contact : 'hyd',
      installer_phone : '',
      installer_email : '',
      project : 'select',
      project_name : '',
      project_address : '',
      // installation_date : '',
      system_type : '',
      product_name : ''

    }
  });

  useEffect(() => {
    setValue('installer_city', _.get(user, 'infoUser.city'))
    setValue('installer_address1', _.get(user, 'infoUser.address'))
    setValue('installer_state', _.get(user, 'infoUser.state'))
    setValue('installer_zipcode', _.get(user, 'infoUser.zipCode'))
    setValue('installer_phone', _.get(user, 'infoUser.officeNumber') || _.get(user, 'infoUser.mobileNumber') )


  }, [_.get(user, 'infoUser.city')])

  const claimTypeInfo = watch('claimType');



  useEffect(() => {
    claimTypeInfo !== 2 ? setChema(schemaEss) : setChema(schemaPV)
  }, [claimTypeInfo])

  const showErrorMessage = (errors, errorKeys = []) => {
    let message = '';

    for (let i = 0; i < errorKeys.length; i++) {
      if (errors[errorKeys[i]]) {
        message = errors[errorKeys[i]].message;
        break;
      }
    }

    return notiFailed(message);
  };

  const onSubmitFailed = async errors => {
    showErrorMessage(errors, ['projectAddress', 'projectAddress2', 'projectCity', 'projectState',
      'projectZipCode', 'installationDate', 'moduleProductType', 'moduleSerialNumber', 'systemType',
      'batteryProductType', 'batteryCapacity', 'purchasingChannel', 'nameOfPurchasingChannelDistributor',
      'nameOfPurchasingChannelOther', 'installationZipCode', 'firstName', 'lastName', 'email', 'phoneNumber'])
  };


  const goBack = () => setCurrentStep(currentStep - 1);
  const next = () => setCurrentStep(currentStep + 1);
  const handleGoToReview = () => {
    handleSubmit(() => setCurrentStep(7), onSubmitFailed)();
  };

  const onSubmit = async data => {

  }
  const renderStepClaim = (type) => {

    switch(currentStep) {
      case 0:
        // return (
        //   <UserInfo
        //     goBack={goBack}
        //     next={next}
        //     register={register}
        //     control={control}
        //     errors={errors}
        //   />
        // )
      case 1:
        return (
          <ClaimInfo
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
            type={parseInt(claimTypeInfo)}
          />
        )
      case 2:
        if(type !== 2) {
          return (
            <Issue
              goBack={goBack}
              next={next}
              register={register}
              control={control}
              errors={errors}
              type={claimTypeInfo}
            />
          )
        }else {
          return (
            <IssuePV
              goBack={goBack}
              next={next}
              register={register}
              control={control}
              errors={errors}
              type={claimTypeInfo}
            />
          )
        }

      case 3:
        if(type !== 2) {
          return (
            <SystemInfo
              goBack={goBack}
              next={next}
              register={register}
              control={control}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
          )
        }else {
          return (
            <PurchaseInfoPv
              goBack={goBack}
              next={next}
              register={register}
              control={control}
              errors={errors}
            />
          )
        }
      case 4:
        return (
          <SoftwareVersion
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
          />
        )
      case 5:
        return (
          <FieldDetail
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
          />
        )
      case 6:
        return (
          <PurchaseInfo
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
            handleGoToReview={handleGoToReview}
            watch={watch}
          />
        )
      default:
        return (
          <div>sss</div>
        )
    }
  }

  const goToStep = step => setCurrentStep(step);

  return(
    <>
      <Banner
        imageUrl={RegistrationBanner}
        className="OrderHistory_Banner banner100"
      >
        <h1 className="title_animation OrderHistory_Title">Project Registration</h1>
        <BreadCrumb2 items={['Project Registration']}/>
      </Banner>
      {!isReview ? (
        <ContainerClaimForm>
          <NavBar currentStep={currentStep} goToStep={goToStep} type={parseInt(claimTypeInfo)} />
          {renderStepClaim(parseInt(claimTypeInfo))}
        </ContainerClaimForm>

      ) : (
        <ReviewInfo
          loading={loading}
          goBack={goBack}
          goToStep={goToStep}
          getValues={getValues}
          handleSubmit={handleSubmit(onSubmit, onSubmitFailed)}
        />
      )}
    </>
  )
}


export default EditClaim;

/**
 * claim/1, claim/2, claim/3, claim/4, claim/5, claim/6, claim/7
 *
 *
 *
 * */
