import React, {useState} from 'react';
import ExpandIcon from '../../../icons/expand-icon.png';
import ExpandIconHover from '../../../icons/expand-icon-hover.png';
import { Grid, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Text from '../../../common/components/text';

export default function Card({ cardTitle, children, icon, image, link, className }) {
  let history = useHistory();
  const [ isHover, setIsHover ] = useState(false)

  const directToLink = () => {
    if(link.indexOf('http') === 0){
      window.location.href = link
    }else{
      history.push(link);
    }
  };

  return (
    <div>
      <Grid
        onClick={directToLink}
        container
        justify="space-between"
        alignItems="center"
      >
        <Text
          className={className}
          color="dark"
          style={{
            fontSize: '17px',
            textAlign: 'left',
            marginBottom: '20px',
            cursor: 'pointer'
          }}
          weight="regular"
        >
          {cardTitle}
        </Text>

        {icon ? (
          <img
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={directToLink}
            src={isHover ? ExpandIconHover : ExpandIcon}
            style={{ marginTop: '-20px', cursor: 'pointer', padding: '7px', marginRight: '-7px' }}
          />
        ) : (
          false
        )}
      </Grid>

      {image ? (
        <img src={image} alt="placeholder" style={{ maxHeight: '200px' }} />
      ) : (
        false
      )}
      {children}
    </div>
  );
}
