import React, { useState, useEffect, useRef, useMemo } from 'react';
import BreadCrumb2 from '../../common/components/BreadCrumb2';
import Banner from '../../common/components/Banner';
import RegistrationBanner from '../../config/images/registration-banner.jpg';
import { makeStyles } from '@material-ui/core';
import { useAuth } from '../../contexts/auth';
import Navbar from './components/Navbar';
import ProjectInfo from './components/ProjectInfo';
import SystemInfo from './components/SystemInfo';
import PurchasingInfo from './components/PurchasingInfo';
import HomeOwnerInfo from './components/HomeOwnerInfo';
import Note from './components/Note';
import ReviewInfo from './components/ReviewInfo';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from '../../utils/axios';
import config from '../../config';
import { notiFailed, notiLabor, notiSuccess } from '../../utils/helpers.js';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import CustomLoading from '../../common/components/CustomLoading';
import { projectService } from '../../services';
import {schemaProject} from './components/schemaProject';
import {initialState} from './components/initialStateProject';
import {keyProject} from './components/keyProject';
import Notification from './components/Notification';
import BannerProjectRes from '../claim/components/BannerProjectRes';

const useStyles = makeStyles(theme => ({
    container: {
        width: '72.5%',
        margin: '60px auto 70px auto',
        [theme.breakpoints.down(1245)]: {
            width: '90%',
            marginTop: '30px'
        }
    },
    loadingEdit: {
      display : 'flex',
      justifyContent : 'center',
      padding :10
    },
    loadingIndicator: {
        textAlign: 'center',
        marginTop: '100px'
    }
}));


const { baseUrl } = config;

export default function ProjectRegistration() {
    const classes = useStyles();
    const user = useAuth();
    const history = useHistory();
    const { projectId, step } = useParams();
    const [currentStep, setCurrentStep] = useState(0);
    const [serialNumberFile, setSerialNumberFile] = useState('');
    const [essSerialNumberFile, setEssSerialNumberFile] = useState('');
    const [batteryCapacities, setBatteryCapacities] = useState([]);
    const [saleRegions, setSaleRegions] = useState([]);
    const [distributors, setDistributors] = useState([]);
    const [files, setFiles] = useState([]);
    const [filesProject, setFilesProject] = useState([]);
    const [filesProof, setFilesProof] = useState([]);
    const [productModels, setProductModels] = useState([]);
    const [essProductModels, setEssProductModels] = useState([]);
    const [powerClassesModules, setPowerClassesModules] = useState([]);
    const [modelModules, setModelModules] = useState([]);
    const [brandsPanel, setBrandsPanel] = useState([]);
    const [brandsBattery, setBrandsBattery] = useState([]);
    const [batteryModels, setBatteryModels] = useState([]);    const [isEnablePreviewButton, setEnablePreviewButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSaveDraft, setLoadingSaveDraft] = useState(false);
    const [pvInverterBrands, setPvInverterBrands] = useState([]);
    const [pvInverterModels, setPvInverterModels] = useState([]);
    const [rackingBrands, setRackingBrands] = useState([]);
    const [rackingModels, setRackingModels] = useState([]);
    const [batteryInverterPower, setBatteryInverterPower] = useState([]);
    const [loadingDetail, setLoadingDetail] = useState(false);
    const [expandAccordion, setExpandAccordion] = useState({
        expandPanel: true,
        expandBattery: true,
        expandInverter: false,
        expandRacking: false
    });
    const firstRender = useRef(true);
    const { register, handleSubmit, errors, control, watch, trigger, setValue, getValues, clearErrors } = useForm({
        resolver : yupResolver(schemaProject),
        mode: "onChange",
        reValidateMode: "onChange",
        shouldUnregister: false,
        shouldFocusError: false,
        defaultValues: initialState
    });

    const isSerialNumber = watch('isSerialNumber');
    const moduleSerialList = watch('moduleSerialNumber');
    const volume = watch('modulePowerClass');
    const numberOfModules = watch('numberOfModules');
    const isManualSerialPanelNotMatchDb = watch('isManualSerialPanelNotMatchDb');
    const isDuplicateAddress = watch('isDuplicateAddress')
    const infoProject = watch();

    const batteryBrand = watch('batteryBrand');
    const modelBattery = watch('batteryModel');
    const essSerial = watch('essSerialNumber');
    const isEssSerialNumber = watch('isEssSerialNumber');

    const pvInverterBrand = watch('pvInverterBrand');

    const goBack = () => setCurrentStep(currentStep - 1);
    const next = () => setCurrentStep(currentStep + 1);
    const moduleSerial = useMemo(() => {
        const uniqModuleSerialNumber = _.remove(
          _.uniqBy(moduleSerialList, 'productName'),
          p => p.productName
        );

        return uniqModuleSerialNumber
    }, [moduleSerialList]);


    const handleGoToReview = () => {
        setCurrentStep(4);
    };

    // useEffect(() => {
    //     isDuplicateAddress && notiFailed('This address is already used in another project.')
    // }, [isDuplicateAddress])

    const renderStep = () => {
        switch(currentStep) {
            case 0:
                return (
                    <ProjectInfo
                        goBack={goBack}
                        next={next}
                        register={register}
                        control={control}
                        errors={errors}
                        isEnablePreviewButton={isEnablePreviewButton}
                        handleGoToReview={handleGoToReview}
                        saleRegions={saleRegions}
                        handleSaveDraft={handleSaveDraft}
                        loadingSaveDraft={loadingSaveDraft}
                        isEditCompleteProject={true}
                        loading={loading}
                        setValue={setValue}
                        filesProject={filesProject}
                        filesProof={filesProof}
                        setFilesProject={setFilesProject}
                        setFilesProof={setFilesProof}
                        handleSubmit={handleSubmit(onSubmit, onSubmitFailed)}
                        files={files}
                        setFiles={setFiles}
                        clearErrors={clearErrors}
                        watch={watch}
                    />
                )
            case 1:
                return (
                    <SystemInfo
                        goBack={goBack}
                        next={next}
                        register={register}
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        errors={errors}
                        serialNumberFile={serialNumberFile}
                        batteryCapacities={batteryCapacities}
                        setSerialNumberFile={setSerialNumberFile}
                        isEnablePreviewButton={isEnablePreviewButton}
                        handleGoToReview={handleGoToReview}
                        handleSaveDraft={handleSaveDraft}
                        loadingSaveDraft={loadingSaveDraft}
                        clearErrors={clearErrors}
                        isEditCompleteProject={true}
                        loading={loading}
                        handleSubmit={handleSubmit(onSubmit, onSubmitFailed)}
                        productModels={productModels}
                        essProductModels={essProductModels}
                        pvInverterBrands={pvInverterBrands}
                        pvInverterModels={pvInverterModels}
                        rackingBrands={rackingBrands}
                        rackingModels={rackingModels}
                        batteryInverterPower={batteryInverterPower}
                        essSerialNumberFile={essSerialNumberFile}
                        setEssSerialNumberFile={setEssSerialNumberFile}
                        expandAccordion={expandAccordion}
                        handleChangeAccordion={handleChangeAccordion}
                        modelModules={modelModules}
                        powerClassesModules={powerClassesModules}
                        batteryModels={batteryModels}
                        brandsPanel={brandsPanel}
                        brandsBattery={brandsBattery}
                    />
                )
            case 2:
                return (
                    <PurchasingInfo
                        goBack={goBack}
                        next={next}
                        register={register}
                        control={control}
                        watch={watch}
                        errors={errors}
                        isEnablePreviewButton={isEnablePreviewButton}
                        handleGoToReview={handleGoToReview}
                        distributors={distributors}
                        handleSaveDraft={handleSaveDraft}
                        loadingSaveDraft={loadingSaveDraft}
                        isEditCompleteProject={true}
                        loading={loading}
                        handleSubmit={handleSubmit(onSubmit, onSubmitFailed)}
                    />
                )
            case 3:
                return (
                    <HomeOwnerInfo
                        goBack={goBack}
                        next={next}
                        register={register}
                        control={control}
                        watch={watch}
                        errors={errors}
                        isEnablePreviewButton={isEnablePreviewButton}
                        handleGoToReview={handleGoToReview}
                        handleSaveDraft={handleSaveDraft}
                        loadingSaveDraft={loadingSaveDraft}
                        isEditCompleteProject={true}
                        loading={loading}
                        handleSubmit={handleSubmit(onSubmit, onSubmitFailed)}
                    />
                )
            case 4:
                return (
                    <>
                        {loadingDetail ? (
                            <div className={classes.loadingIndicator}>
                                <CustomLoading size={40} color="#00adef"/>
                            </div>
                        ) : (
                            <ReviewInfo
                                loading={loading}
                                goBack={goBack}
                                goToStep={goToStep}
                                watch={watch}
                                errors={errors}
                                control={control}
                                handleSubmit={handleSubmit(onSubmit, onSubmitFailed)}
                                isEditCompleteProject={true}
                                filesProof={filesProof}
                                goBackToMyProject={goBackToMyProject}
                            />
                        )}
                    </>
                )
            default:
                return (
                    <ProjectInfo
                        goBack={goBack}
                        next={next}
                        register={register}
                        control={control}
                        errors={errors}
                        isEnablePreviewButton={isEnablePreviewButton}
                        handleGoToReview={handleGoToReview}
                        saleRegions={saleRegions}
                        handleSaveDraft={handleSaveDraft}
                        loadingSaveDraft={loadingSaveDraft}
                        isEditCompleteProject={true}
                        loading={loading}
                        handleSubmit={handleSubmit(onSubmit, onSubmitFailed)}
                        files={files}
                        setFiles={setFiles}
                        watch={watch}
                    />
                )
        }
    }

    const goToStep = step => setCurrentStep(step);

    // useEffect(() => {
    //     setCurrentStep(step);
    // }, [step])

    useEffect(() => {
        if(modelBattery !== 'select') {
            getBatteryInverterPower(modelBattery);
        }
    }, [modelBattery])

    useEffect(() => {
        setValue('projectPictures', filesProject);
    }, [filesProject]);


    useEffect(() => {
        setValue('projectPictureProf', filesProof);
    }, [filesProof]);

    useEffect(() => {
        getModuleModels();
        getProjectDetail();
        getSaleRegions();
        getBatteryCapacities();
        // getProductModels();
        getEssProductModels();
        getBrandsSystemInfo(1);
        getBrandsSystemInfo(2);
        getBrandsSystemInfo(3, true);
        // getPowerClass('modules');
    }, []);

    useEffect(() => {
        if(batteryBrand !== 'select') {
            const brandsBatteryId = brandsBattery?.find(item => item.name === batteryBrand)?.id
            getBatteryModels(brandsBatteryId);
        }
    }, [batteryBrand])

    useEffect(() => {
        if(pvInverterBrand !== 'select') {
            const pvInverterBrandsId = pvInverterBrands?.find(item => item.name === pvInverterBrand)?.id
            getPvInverterModels(pvInverterBrandsId);
        }
    }, [pvInverterBrand])

    useEffect(() => {
        if (currentStep === 4) {
            setEnablePreviewButton(true);
            trigger(keyProject).then(r => console.log(r));
        }
    }, [currentStep]);

    useEffect(() => {
        scrollToTop();
    },[currentStep])

    useEffect(() => {
        scrollToTop();
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        setCurrentStep(parseInt(step));
    }, [step])

    // useEffect(() => {
    //     if(volume !== '') {
    //         getModelsByPowerClass('modules', volume);
    //     }
    // }, [volume])

    useEffect(() => {
        setValue('projectPictures', files);
    }, [files]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        if (currentStep === 4) {
            if(!getQualifyLaborWarranty(infoProject)) {
                notiLabor(`This project will not qualify for labor reimbursement. Please review the reasons on this page.`);
            }
        }
    }, [currentStep]);

    const handleSaveDraft = async () => {
        const currentData = getValues();
        const data = _.pickBy(currentData, value => {
            if (value === 'select') {
                return false;
            }

            if (!value) {
                return false;
            }

            if (_.get(value, 'length') === 0) {
                return false;
            }

            return true;
        });

        const formData = new FormData();

        const fields = ['projectName', 'projectAddress2', 'projectCity',
        'projectState', 'projectZipCode', 'moduleProductType', 'rackingProductType',
        'rackingProductName', 'purchasingChannel', 'firstName', 'lastName',
        'email', 'phoneNumber', 'note'];

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            const fieldVal = data[field];
            if (fieldVal) {
                formData.append(field, fieldVal);
            }
        }

        if(data.projectAddress) {
            formData.append('projectAddress', data.projectAddress)
        }

        if (_.get(data, 'projectPictures.length') > 0) {
            formData.append('projectImages', _.join(_.map(_.filter(_.get(data, 'projectPictures'), p => p.url), p => p.url), '|'));
        } else {
            formData.append('projectImages', '');
        }

        if (_.get(data, 'projectPictureProf.length') > 0) {
            formData.append('proofOfOperateDate', _.join(_.map(_.filter(_.get(data, 'projectPictureProf'), p => p.url), p => p.url), '|'));
        }else {
            formData.append('proofOfOperateDate', '');
        }

        if (data.installationDate) {
            formData.append('installationDate', moment(data.installationDate).format('YYYY-MM-DD'));
        }

        if (data.operateDate) {
            formData.append('operateDate',  moment(data.operateDate).format('YYYY-MM-DD'));
        }

        let serialNumbers = [];
        if (data.moduleProductType === 'Q CELLS' && data.isSerialNumber
        && _.get(data, 'moduleSerialNumber.length') && !data.isManualSerialNumber) {
            for (let i = 0; i < data.moduleSerialNumber.length; i++) {
                const current = data.moduleSerialNumber[i];
                const serialNumber = {
                    type: 'modules',
                    serialNumber: current.serialNumber || current?.serial_number,
                    productType: data.moduleProductType,
                    productName: data.moduleModel,
                    volume: data.modulePowerClass,
                    source: 'web'
                };
                serialNumbers.push(serialNumber);
            }
        }
        if (data.battery && ['AC', 'DC'].includes(data.systemType) && data.batteryProductType === 'Q CELLS'
         && _.get(data, 'essSerialNumber.length') > 0) {
            for (let i = 0; i < data.essSerialNumber.length; i++) {
                const current = data.essSerialNumber[i];
                const serialNumber = {
                    type: 'inverter',
                    serialNumber: current.serialNumber || current?.serial_number,
                    productName: data.batteryModel,
                    source: 'web'
                };
                serialNumbers.push(serialNumber);
            }
        }
        formData.append('serialNumbers', JSON.stringify(serialNumbers));
        formData.append('manualSerialNumber', !!currentData.isManualSerialNumber);
        formData.append('manualInverter', !!currentData.isManualEssSerialNumber);

        // const isManualProductInfo = ['select', 'other'].includes(currentData.moduleProductType) ||
        // (currentData.moduleProductType === 'Q CELLS' && data.isSerialNumber && currentData.isManualSerialNumber) ||
        // (currentData.moduleProductType === 'Q CELLS' && !data.isSerialNumber);

        const isManualProductInfo = (data.moduleBrand === 'select'|| data.moduleBrand !== 'Q CELLS') ||
        (data.moduleBrand === 'Q CELLS' && data.isSerialNumber && data.isManualSerialNumber) ||
        (data.moduleBrand === 'Q CELLS' && !data.isSerialNumber);

        if (isManualProductInfo && data.productName) {
            formData.append('productName', data.productName);
        }
        if (isManualProductInfo && data.volume) {
            formData.append('volume', data.volume);
        }
        if (isManualProductInfo && data.numberOfModules) {
            formData.append('numberOfModules', data.numberOfModules);
        }

        const isBattery = data.battery;
        formData.append('battery', !!isBattery);
        if (isBattery && data.systemType) {
            formData.append('systemType', data.systemType);
        }
        if (isBattery && data.batteryProductType) {
            formData.append('batteryProductType', data.batteryProductType);
        }
        const isSelectBatteryCapacity = ['AC', 'DC'].includes(data.systemType) && data.batteryProductType === 'Q CELLS';
        if (isBattery && isSelectBatteryCapacity) {
            if (data.batteryCapacity1) {
                formData.append('batteryCapacity', data.batteryCapacity1);
            }
        }
        if (isBattery && !isSelectBatteryCapacity) {
            if (data.batteryCapacity2) {
                formData.append('batteryCapacity', data.batteryCapacity2);
            }
            if (data.batteryProductName1) {
                formData.append('batteryProductName', data.batteryProductName1);
            }
        }

        if (!isBattery || (isBattery && data.systemType === 'select')) {
            if (data.inverterProductBrand) {
                formData.append('inverterProductType', data.inverterProductBrand);
            }
            if (data.inverterProductName) {
                formData.append('inverterProductName', data.inverterProductName);
            }
        }

        if (isBattery && data.systemType === 'AC') {
            if (data.pvInverterProductBrand) {
                formData.append('pvProductType', data.pvInverterProductBrand);
            }
            if (data.pvInverterProductName) {
                formData.append('pvProductName', data.pvInverterProductName);
            }
        }
        const batteryModel = data?.batteryBrand === 'Q CELLS' ? (isEssSerialNumber ? _.get(essSerial, '[0].productName') : data.batteryModel) : data.batteryModel;
        const batteryInverterPower = data?.batteryBrand === 'Q CELLS' ? (isEssSerialNumber ? _.get(essSerial, '[0].volume') : data.batteryInverterPower) : null;
        //const batteryEnergy = data?.batteryBrand === 'Q CELLS' ? data.batteryEnergy : null;

        !!batteryModel && formData.append('batteryModel', batteryModel);
        !!batteryInverterPower && formData.append('batteryInverterPower', batteryInverterPower);
        if (isBattery && isSelectBatteryCapacity) {
            if (data.batteryEnergy) {
                formData.append('batteryEnergy', data.batteryEnergy);
            }
        }
        //!!batteryEnergy && formData.append('batteryEnergy', batteryEnergy);

        data.pvInverterBrand && formData.append('pvInverterBrand', data.pvInverterBrand);
        data.pvInverterModel && formData.append('pvInverterModel', data.pvInverterModel);
        data.rackingBrand && formData.append('rackingBrand', data.rackingBrand);
        data.rackingModel && formData.append('rackingModel', data.rackingModel);

        if (data.purchasingChannel) {
            let nameOfPurchasingChannel = '';
            switch(data.purchasingChannel) {
                case 'distributor':
                    nameOfPurchasingChannel = _.get(data, 'nameOfPurchasingChannelDistributor.name');
                    break;
                case 'other':
                    nameOfPurchasingChannel = data.nameOfPurchasingChannelOther;
                    break;
                case 'qcells':
                default:
                    nameOfPurchasingChannel = 'Q CELLS';
                    break;
            }

            if (nameOfPurchasingChannel) {
                formData.append('nameOfPurchasingChannel', nameOfPurchasingChannel);
            }
        }

        formData.append('isMobileNumber', data.selectedContactNumber === '2');
        formData.append('isDraft', true);
        formData.append('id', _.toNumber(projectId));
        formData.append('source', 'web');
        formData.append('qualifyLaborWarranty', getQualifyLaborWarranty(data));
        formData.append('systemSize', getSystemSize(data));

        try {
            setLoadingSaveDraft(true);
            await axios.post(`${baseUrl}/projects/save`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            notiSuccess('Save draft project successfully!');
        } catch (err) {
            notiFailed(`There was a problem when edit draft project ${err.message}`);
        } finally {
            setLoadingSaveDraft(false);
        }
    };

    const onSubmit = async () => {
        const currentData = getValues();
        const data = _.pickBy(currentData, value => {
            if (value === 'select') {
                return false;
            }

            if (!value) {
                return false;
            }

            if (_.get(value, 'length') === 0) {
                return false;
            }

            return true;
        });
        const formData = new FormData();
        formData.append('isManualSerialPanelNotMatchDb', _.get(data, 'isManualSerialPanelNotMatchDb', false))
        formData.append('isManualSerialBatteryNotMatchDb', _.get(data, 'isManualSerialBatteryNotMatchDb', false))
        formData.append('projectName', getProjectName(infoProject));
        formData.append('projectAddress', _.get(data.projectAddress, 'label', ''));
        formData.append('latitude', data.latitude);
        formData.append('region', data.region);
        // formData.append('note', data.note);
        formData.append('longitude', data.longitude);
        formData.append('projectAddress2', data.projectAddress2);
        formData.append('projectCity', data.projectCity);
        formData.append('projectState', data.projectState);
        formData.append('projectZipCode', data.projectZipCode);
        if (_.get(data, 'projectPictures.length') > 0) {
            formData.append('projectImages', _.join(_.map(_.filter(_.get(data, 'projectPictures'), p => p.url), p => p.url), '|'));
        }else {
            formData.append('projectImages', '');
        }

        if (_.get(data, 'projectPictureProf.length') > 0) {
            formData.append('proofOfOperateDate', _.join(_.map(_.filter(_.get(data, 'projectPictureProf'), p => p.url), p => p.url), '|'));
        }else {
            formData.append('proofOfOperateDate', '');
        }
        formData.append('installationDate', moment(data.installationDate).format('YYYY-MM-DD'));
        if (data.operateDate) {
            formData.append('operateDate',  moment(data.operateDate).format('YYYY-MM-DD'));
        }

        formData.append('moduleBrand', data.moduleBrand);
        let serialNumbers = [];
        if (data.moduleBrand === 'Q CELLS' && data.isSerialNumber
          && _.get(data, 'moduleSerialNumber.length') > 0) {
            for (let i = 0; i < data.moduleSerialNumber.length; i++) {
                const current = data.moduleSerialNumber[i];
                const serialNumber = {
                    type: 'modules',
                    serialNumber: current?.serialNumber || current?.serial_number || '',
                    productType: data?.moduleBrand || '',
                    productName: data?.moduleModel || '',
                    volume: data?.modulePowerClass || '',
                    source: 'web'
                };
                serialNumbers.push(serialNumber);
            }
        }
        if (data.battery && ['AC', 'DC'].includes(data.systemType) && data.batteryBrand === 'Q CELLS'
          && _.get(data, 'essSerialNumber.length') > 0) {
            for (let i = 0; i < data.essSerialNumber.length; i++) {
                const current = data.essSerialNumber[i];
                const serialNumber = {
                    type: 'inverter',
                    serialNumber: current?.serialNumber || current?.serial_number || '',
                    productName: data?.batteryModel || '',
                    source: 'web'
                };
                serialNumbers.push(serialNumber);
            }
        }
        formData.append('serialNumbers', JSON.stringify(serialNumbers));
        formData.append('manualSerialNumber', !!data.isManualSerialNumber);
        formData.append('manualInverter', !!data.isManualEssSerialNumber);

        const isManualProductInfo = (data.moduleBrand === 'select'|| data.moduleBrand !== 'Q CELLS') ||
          (data.moduleBrand === 'Q CELLS' && data.isSerialNumber && data.isManualSerialNumber) ||
          (data.moduleBrand === 'Q CELLS' && !data.isSerialNumber);

        if(data.moduleModel) {
            formData.append('moduleModel', data.moduleModel);
        }

        if(data.moduleBrand === 'Other') {
            formData.append('nameOfModuleBrandPanelOther', data.nameOfModuleBrandPanelOther)
        }

        if(data.modulePowerClass) {
            formData.append('modulePowerClass', data.modulePowerClass);
        }

        if(data.numberOfModules) {
            formData.append('numberOfModules', data.numberOfModules);
        }

        const isBattery = data.battery;
        formData.append('battery', !!isBattery);
        if (isBattery) {
            formData.append('systemType', data.systemType);
            formData.append('batteryBrand', data.batteryBrand);
        }
        const isSelectBatteryCapacity = ['AC', 'DC'].includes(data.systemType) && data.batteryBrand === 'Q CELLS';
        if (isBattery && isSelectBatteryCapacity) {
            if (data.batteryCapacity1) {
                formData.append('batteryCapacity', data.batteryCapacity1);
            }
            if (data.batteryEnergy && data.batteryEnergy !== 'select' && isBattery) {
                formData.append('batteryEnergy', data.batteryEnergy);
            }
        }
        if (isBattery && !isSelectBatteryCapacity) {
            if (data.batteryCapacity2) {
                formData.append('batteryCapacity', data.batteryCapacity2);
            }
            if (data.batteryProductName1) {
                formData.append('batteryProductName', data.batteryProductName1);
            }
        }

        formData.append('isEssSerialNumber', isEssSerialNumber)
        let batteryInverterPower = null;

        const batteryModel = data?.batteryBrand === 'Q CELLS' ? (isEssSerialNumber ? _.get(essSerial, '[0].productName') : data.batteryModel) : data.batteryModel;
        //const batteryInverterPower = data?.batteryBrand === 'Q CELLS' ? (isEssSerialNumber ? _.get(essSerial, '[0].volume') : data.batteryInverterPower) : null;
        if(data?.batteryBrand === 'Q CELLS') {
            batteryInverterPower =  _.get(essSerial, '[0].volume', data.batteryInverterPower)
        }

        // if (isBattery && isSelectBatteryCapacity) {
        //     if (data.batteryEnergy) {
        //         formData.append('batteryEnergy', data.batteryEnergy);
        //     }
        // }

        //const batteryEnergy = data?.batteryBrand === 'Q CELLS' ? data.batteryEnergy : null;

        if(batteryModel && batteryModel !== 'select' && isBattery) {
            formData.append('batteryModel', batteryModel);
        }
        if(data.batteryBrand === 'Other') {
            formData.append('nameOfModuleBrandBatteryOther', data.nameOfModuleBrandBatteryOther)
        }
        // if(batteryEnergy) {
        //     batteryEnergy && formData.append('batteryEnergy', batteryEnergy);
        // }

        if(batteryInverterPower && batteryInverterPower !== 'select' && isBattery) {
            formData.append('batteryInverterPower', batteryInverterPower);
        }

        //!!batteryModel && formData.append('batteryModel', batteryModel);
        //formData.append('batteryInverterPower', batteryInverterPower);
        //!!batteryEnergy && formData.append('batteryEnergy', batteryEnergy);

        data.pvInverterBrand && formData.append('pvInverterBrand', data.pvInverterBrand);
        if(data.pvInverterBrand === 'Other') {
            formData.append('namePvInverterBrandOther', data.namePvInverterBrandOther)
        }
        data.pvInverterModel && formData.append('pvInverterModel', data.pvInverterModel);

        data.rackingBrand && formData.append('rackingBrand', data.rackingBrand);        if(data.rackingBrand === 'other') {
            formData.append('nameRackingBrandOther', data.nameRackingBrandOther)
        }
        data.rackingModel && formData.append('rackingModel', data.rackingModel);


        if (!isBattery || (isBattery && data.systemType === 'select')) {
            if (data.inverterProductBrand) {
                formData.append('inverterProductType', data.inverterProductBrand);
            }
            if (data.inverterProductName) {
                formData.append('inverterProductName', data.inverterProductName);
            }
        }

        if (isBattery && data.systemType === 'AC') {
            if (data.pvInverterProductBrand) {
                formData.append('pvProductType', data.pvInverterProductBrand);
            }
            if (data.pvInverterProductName) {
                formData.append('pvProductName', data.pvInverterProductName);
            }
        }


        formData.append('purchasingChannel', data.purchasingChannel);
        let nameOfPurchasingChannel = '';
        switch(data.purchasingChannel) {
            case 'distributor':
                nameOfPurchasingChannel = _.get(data, 'nameOfPurchasingChannelDistributor.name');
                break;
            case 'other':
                nameOfPurchasingChannel = data.nameOfPurchasingChannelOther;
                break;
            case 'qcells':
            default:
                nameOfPurchasingChannel = 'Q CELLS';
                break;
        }
        formData.append('nameOfPurchasingChannel', nameOfPurchasingChannel);
        nameOfPurchasingChannel === 'Other' && data.purchasingChannel === 'distributor' && formData.append('nameOfChanelOtherDistributor', data.nameOfChanelOtherDistributor || '');


        formData.append('panelQualified', _.get(data, 'panelQualified', false))
        formData.append('batteryQualified', _.get(data, 'batteryQualified', false))
        formData.append('panelNonQcells', _.get(data, 'panelNonQcells', false))
        formData.append('batteryNonQcells', _.get(data, 'batteryNonQcells', false))

        formData.append('firstName', data.firstName);
        formData.append('lastName', data.lastName);
        formData.append('email', data.email);
        formData.append('phoneNumber', data.phoneNumber);
        formData.append('isMobileNumber', data.selectedContactNumber === '2');

        if (data.note) {
            formData.append('note', data.note);
        }
        formData.append('isDraft', false);
        formData.append('source', 'web');
        formData.append('qualifyLaborWarranty', getQualifyLaborWarranty(data));
        formData.append('systemSize', getSystemSize(data));


        formData.append('id', _.toNumber(projectId));

        try {
            setLoading(true);
            setLoadingDetail(true);
            const info = await axios.post(`${baseUrl}/projects/save`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            //setCurrentStep(4);
            history.push({
                pathname : '/projects/myprojects',
                id : info.data.id
            })
            notiSuccess('Edit project successfully!');
        } catch (err) {
            notiFailed(`There was a problem when edit project ${err.message}`);
        } finally {
            setLoading(false);
            setLoadingDetail(false);
        }
        // console.log(formData.get('serialNumbers'));
    };

    const onSubmitFailed = async errors => {
        showErrorMessage(errors, keyProject)
    };

    const getBatteryCapacities = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/projects/battery-capacities`);
            setBatteryCapacities(data);
        } catch (err) {
            notiFailed(`Something happened getting battery capacities`);
        }
    };

    const getSaleRegions = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/companies/salesregions/search`);
            setSaleRegions(data);
        } catch (err) {
            notiFailed(`Something happened getting sale regions`);
        }
    };

    const getDistributors = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/distributors`);
            setDistributors(data);
        } catch (err) {
            notiFailed(`Something happened getting distributors`);
        }
    };

    const getProjectDetail = async () => {
        try {
            setLoadingDetail(true);
            const response = await axios.get(`${baseUrl}/distributors`);
            const distributors = response.data;
            setDistributors(distributors);
            const { data } = await axios.get(`${baseUrl}/projects/detail`, { params: { id: _.toNumber(projectId) } });
            setValue('projectName', data.projectName);
            setValue('region', data.region);
            !!data.projectAddress && setValue('projectAddress', {label : data.projectAddress, value : data.projectAddress});
            setValue('projectAddress2', data.projectAddress2);
            setValue('projectCity', data.projectCity);
            setValue('projectState', data.projectState ? data.projectState : 'select');
            setValue('projectZipCode', data.projectZipCode);
            setValue('latitude', data.latitude);
            setValue('longitude', data.longitude);
            setValue('isManualSerialPanelNotMatchDb', _.get(data, 'isManualSerialPanelNotMatchDb', false));
            setValue('isManualSerialBatteryNotMatchDb', _.get(data, 'isManualSerialBatteryNotMatchDb', false));

            if (_.trim(_.get(data, 'projectImages'))) {
                setFilesProject(_.map(_.split(_.get(data, 'projectImages'), '|'), p => ({ url: p })));
            }

            if (_.trim(_.get(data, 'proofOfOperateDate'))) {
                setFilesProof(_.map(_.split(_.get(data, 'proofOfOperateDate'), '|'), p => ({ url: p })));
            }
            const gap = moment().utcOffset() * -1;
            setValue('installationDate', moment(data.installationDate).add(gap, 'minutes'));
            setValue('operateDate', moment(data.operateDate).add(gap, 'minutes'));
            setValue('moduleBrand', data.moduleBrand ? data.moduleBrand : 'select');

            /**
             * system information
             */


            setValue('moduleBrand', data.moduleBrand ? data.moduleBrand : 'select');
            const isManualSerialNumber = data.manualSerialNumber;
            setValue('isManualSerialNumber', isManualSerialNumber);
            const moduleSN = _.filter(_.get(data, 'serialNumbers'), s => s.type === 'modules');
            if (moduleSN && moduleSN.length > 0) {
                setValue('moduleSerialNumber', moduleSN);
                setValue('isSerialNumber', true);
                // if(_.get(data, 'isManualSerialPanelNotMatchDb', false)) {
                setValue('moduleBrand', data.moduleBrand ? data.moduleBrand : 'select');
                setValue('modulePowerClass' , data.modulePowerClass ? data.modulePowerClass : '');
                setValue('moduleModel', data.moduleModel ? data.moduleModel : 'select');
                setValue('numberOfModules', data.numberOfModules ? data.numberOfModules : '');
                setValue('productName', data.productName);
                //setValue('volume', data.volume);
                // }
            } else {
                setValue('moduleSerialNumber', []);
                !_.get(data, 'isManualSerialPanelNotMatchDb', false) && setValue('isSerialNumber', false);
                setValue('moduleBrand', data.moduleBrand ? data.moduleBrand : 'select');
                setValue('modulePowerClass' , data.modulePowerClass ? data.modulePowerClass : '');
                setValue('moduleModel', data.moduleModel ? data.moduleModel : ( data.moduleBrand === 'Q CELLS' ? 'select' : ''));
                setValue('numberOfModules', data.numberOfModules ? data.numberOfModules : '');
                setValue('productName', data.productName);
                //setValue('volume', data.volume);
                setValue('numberOfModules', data.numberOfModules);
            }
            if(data.moduleBrand === 'Other') {
                setValue('nameOfModuleBrandPanelOther', data.nameOfModuleBrandPanelOther)
            }
            if(data.batteryBrand === 'Other') {
                setValue('nameOfModuleBrandBatteryOther', data.nameOfModuleBrandBatteryOther);
            }
            setValue('isEssSerialNumber', data.isEssSerialNumber);
            setValue('battery', !!data.battery);
            setValue('systemType', data.systemType ? data.systemType : 'select');
            setValue('batteryBrand', data.batteryBrand ? data.batteryBrand : 'select');
            setValue('batteryModel', data.batteryModel ? data.batteryModel : 'select');
            setValue('batteryEnergy', data.batteryEnergy ? data.batteryEnergy.toFixed(1) : 'select');
            setValue('batteryInverterPower', data.batteryInverterPower ? data.batteryInverterPower : 'select');
            const isSelectBatteryCapacity = ['AC', 'DC'].includes(data.systemType) && data.batteryBrand === 'Q CELLS';
            if (data.battery && isSelectBatteryCapacity) {
                setValue('batteryCapacity1', data.batteryCapacity ? data.batteryCapacity : 'select');
            }
            if (data.battery && !isSelectBatteryCapacity) {
                setValue('batteryCapacity2', data.batteryCapacity);
                setValue('batteryProductName1', data.batteryProductName);
            }
            const inverterSN = _.filter(_.get(data, 'serialNumbers'), s => s.type === 'inverter');
            const isManualEssSerialNumber = data.manualInverter;
            setValue('isManualEssSerialNumber', !!isManualEssSerialNumber);
            if (data.battery && ['DC', 'AC'].includes(data.systemType) && data.batteryBrand === 'Q CELLS' && inverterSN && inverterSN.length > 0) {
                setValue('essSerialNumber', inverterSN);
                setValue('isEssSerialNumber', true);
            } else {
                setValue('essSerialNumber', []);
                !_.get(data, 'isManualSerialBatteryNotMatchDb', false) && setValue('isEssSerialNumber', false);
            }

            if (!data.battery || (data.battery && data.systemType === 'select')) {
                if (data.inverterProductType) {
                    setValue('inverterProductBrand', data.inverterProductType);
                }
                if (data.inverterProductName) {
                    setValue('inverterProductName', data.inverterProductName);
                }
            }

            if (data.battery && data.systemType === 'AC') {
                if (data.pvProductType) {
                    setValue('pvInverterProductBrand', data.pvProductType);
                }
                if (data.pvProductName) {
                    setValue('pvInverterProductName', data.pvProductName);
                }
            }

            setValue('pvInverterBrand', data.pvInverterBrand ? data.pvInverterBrand : 'select');

            if(data.pvInverterBrand === 'Other') {
                setValue('namePvInverterBrandOther', data.namePvInverterBrandOther)
            }

            setValue('pvInverterModel', data.pvInverterModel ? data.pvInverterModel : 'select');
            setValue('rackingBrand', data.rackingBrand ? data.rackingBrand : 'select');

            if(data.rackingBrand === 'other') {
                setValue('nameRackingBrandOther', data.nameRackingBrandOther)
            }

            setValue('rackingModel', data.rackingModel ? data.rackingModel : '');


            setValue('purchasingChannel', data.purchasingChannel ? data.purchasingChannel : 'select');
            if (data.purchasingChannel === 'distributor') {
                setValue('nameOfPurchasingChannelDistributor', distributors.find(d => d.name === data.nameOfPurchasingChannel));
                data.nameOfChanelOtherDistributor !== null && setValue('nameOfChanelOtherDistributor', data.nameOfChanelOtherDistributor);
            } else if (data.purchasingChannel === 'other') {
                setValue('nameOfPurchasingChannelOther', data.nameOfPurchasingChannel);
            }



            setValue('panelQualified', data.panelQualified);
            setValue('batteryQualified', data.batteryQualified);
            setValue('panelNonQcells', data.panelNonQcells);
            setValue('batteryNonQcells', data.batteryNonQcells);

            setValue('firstName', data.firstName);
            setValue('lastName', data.lastName);
            setValue('email', data.email);
            setValue('phoneNumber', data.phoneNumber);
            setValue('selectedContactNumber', data.isMobileNumber ? '2' : '1');
            setValue('note', data.note);
            setValue('createdAt', data.createdAt);

        } catch (err) {
            notiFailed(`Something happened getting project detail`);
        }finally {
            setLoadingDetail(false)
            trigger(keyProject).then(r => console.log(r));
        }
    };

    const showErrorMessage = (errors, errorKeys = []) => {
        let message = '';

        for (let i = 0; i < errorKeys.length; i++) {
            if (errors[errorKeys[i]]) {
                message = errors[errorKeys[i]].message;
                break;
            }
        }

        return notiFailed(message);
    };

    const goBackToMyProject = () => {
        history.push('/projects/myprojects');
    };

    const getProductModels = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/projects/product-name/modules`);
            setProductModels(data);
        } catch (err) {
            notiFailed(`Something happened getting product models`);
        }
    };

    const getEssProductModels = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/projects/product-name/ess`);
            setEssProductModels(data);
        } catch (err) {
            notiFailed(`Something happened getting product ess models`);
        }
    };

    const getProjectName = data => {
        if (_.trim(data.projectName)) {
            return _.trim(data.projectName);
        }

        const firstName = data.firstName;
        const lastName = data.lastName;
        const city = data.projectCity;
        let systemSize = null;

        if (data.moduleBrand === 'Q CELLS' && data.isSerialNumber && !data.isManualSerialNumber) {
            const uniqModuleSerialNumber = _.remove(_.uniqBy(_.get(data, 'moduleSerialNumber'), 'productName'), p => p.productName);
            systemSize = _.get(data, 'moduleSerialNumber.length') * _.get(uniqModuleSerialNumber, '[0].volume', data.modulePowerClass);
        } else {
            systemSize = volume * numberOfModules;
        }

        if (data.moduleBrand !== 'Q CELLS') {
            systemSize = volume * numberOfModules;
        }


        if (systemSize) {
            systemSize = `${systemSize / 1000} kW`;
        }

        return _.join(_.remove([_.trim(`${firstName || ''} ${lastName || ''}`), systemSize, city], value => !!value), ', ');
    };

    const getQualifyLaborWarranty = data => {
        let systemSize = null;
        const operateDate = _.get(data, 'operateDate');
        const volume = watch('modulePowerClass');
        const numberOfModules = watch('numberOfModules');
        const panelQualified  = watch('panelQualified');
        const batteryQualified = watch('batteryQualified');
        const panelNonQcells  = watch('panelNonQcells');
        const batteryNonQcells = watch('batteryNonQcells')

        const uniqModuleSerialNumber = _.remove(_.uniqBy(_.get(data, 'moduleSerialNumber'), 'productName'), p => p.productName);
        if(uniqModuleSerialNumber?.length > 0) {
            systemSize = (_.get(data, 'moduleSerialNumber.length') * data.modulePowerClass) / 1000;
        }else {
            systemSize = (volume !== '' && numberOfModules !== '')
              ? (_.toNumber(volume) * _.toNumber(numberOfModules)) / 1000
              : ''
        }




        if(_.get(data, 'operateDate') && !!systemSize) {
            const perateDateI = moment(_.get(data, 'operateDate')).add(3, 'M').format('YYYY-MM-DD');
            const dateNow = moment().format('YYYY-MM-DD')
            let laborQcell = false;
            let laborQuanlify = false;

            if(!panelNonQcells === true || !batteryNonQcells === true) {
                laborQcell = true
            }

            if(panelQualified === true || batteryQualified === true) {
                laborQuanlify = true
            }


            return moment(perateDateI).isSameOrAfter(dateNow) && systemSize <= 25 && laborQcell && laborQuanlify
        }


        return false;
    };

    const getSystemSize = data => {
        const sysSize = data?.moduleBrand !== 'Q CELLS' && volume !== '' && numberOfModules !== ''
          ? (_.toNumber(volume) * _.toNumber(numberOfModules)) / 1000
          : (!isSerialNumber ? (_.toNumber(volume) * _.toNumber(numberOfModules)) / 1000 : (_.toNumber(_.get(moduleSerial, '[0].volume', volume)) * (isManualSerialPanelNotMatchDb ? numberOfModules : moduleSerialList.length)) / 1000)

        return sysSize || 0
    };

    const handleChangeAccordion = key => {
        setExpandAccordion({
            ...expandAccordion,
            [key]: !expandAccordion[key]
        })
    };

    async function getPowerClass(productType) {
        try {
          const { data } = await projectService.getPowerClass(productType);
          productType === 'modules' && setPowerClassesModules(data)
        } catch (error) {
          console.log(error);
        }
    }

    async function getModelsByPowerClass(productType, volume) {
        try {
          const { data } = await projectService.getModelsByPowerClass(productType, volume);
          setModelModules(data)
        } catch (error) {
          console.log(error);
        }
    }

    async function getModuleModels() {
        try {
            const { data } = projectService.getQCellsModelModules();
            setModelModules(data);
        } catch (error) {
            console.log(error);
        }
    }

    async function getBrandsSystemInfo(productType, withoutQcells) {
        try {
          const { data } = await projectService.getBrandsSystemInfo(productType, withoutQcells);
          productType === 1 && setBrandsPanel(data);
          productType === 2 && setBrandsBattery(data);
          productType === 3 && withoutQcells === true && setPvInverterBrands(data);
        } catch (error) {
          console.log(error);
        }
    }

    async function getBatteryModels(manufacturerId) {
        try {
          const { data } = await projectService.getModelsSystemInfo(manufacturerId);
          setBatteryModels(data)
        } catch (error) {
          console.log(error);
        }
    }

    async function getPvInverterModels(manufacturerId) {
        try {
          const { data } = await projectService.getModelsSystemInfo(manufacturerId);
          setPvInverterModels(data)
        } catch (error) {
          console.log(error);
        }
    }

    async function getBatteryInverterPower(productName) {
        try {
            const { data } = await projectService.getBatteryInverterPower(productName);
            setBatteryInverterPower(data)
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Notification />
            <BannerProjectRes step={currentStep} title={'Project Registration'} sub_title={['SALES TOOLS', 'PROJECT MANAGEMENT', 'PROJECT REGISTRATION']}/>

            <div>
                <Navbar currentStep={currentStep} goToStep={goToStep} />
                {loadingDetail ?
                  <div className={classes.loadingEdit}>
                    <CustomLoading size={40} color="#00adef"/>
                    </div> :
                  <div className={classes.container}>
                    {renderStep()}
                </div>}

            </div>
        </>
    )
}
