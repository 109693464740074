import { makeStyles } from '@material-ui/core';
import IconSelect from '../../../icons/project_arrow.svg';
import RadioIcon from '../../../config/images/bg_radio.svg';
import RadioOnIcon from '../../../config/images/bg_radio_on.svg';

const useStyles = makeStyles(theme => ({
    wrapDetailProject : {
      padding : '10px 38px'
    },
    headerProject : {
        display: 'flex',
        justifyContent : 'space-between'
    },
    wrapPaid : {
        display : 'flex',
        alignItems : 'center',
    //  height : 70,
        padding: '8px 0'

    },
    wSpan : {
        width : 65
    },
    wrapMessage : {
        // height : 58,
        backgroundColor: '#F6FAFD',
        //marginTop: 8,
        width : '100%',
        display : 'flex',
        padding : 11,
        alignItems : 'flex-start',
        border: '1px solid #DCE1E1',
        borderRadius: 3,
        // marginLeft : 10,
        color: '#4f4f4f'
    },
    wrapListAddress : {
      width : '100%',
      height : 250,
      overflowY : 'scroll',
      borderRadius : 5,
      position : 'absolute',
      background : 'whitesmoke',
        padding : 5,
        zIndex : 10
    },
    itemAddress : {
      height : 40,
        color : '#000000',
        fontWeight : 300,
        fontSize : 12,
        padding : 10,
      '&:hover' : {
          backgroundColor : '#004dff4d'
      }
    },
    draftInfo : {
        display : 'flex',
        justifyContent: 'space-between',
        height: 55,
        backgroundColor : '#F6FAFD',
        padding : 10,
        marginTop : 17,
        border: '1px solid #E0E0E0'
    },

    rightDraft : {

    },

    leftDraft : {
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center'
    },

    leftHeaderProject :{

    },
    rightHeaderProject : {

    },
    subInfo : {
        fontWeight: 300,
        fontSize : 12,
        lineHeight : '22px'
    },
    titleProject : {
        fontWeight : 400,
        fontSize : 32,
        lineHeight : '40px'
    },
    statusProject : {
      fontWeight: 400,
      fontSize : 15,
      lineHeight : '15px'
    },
    viewClaim : {
      fontWeight: 400,
      fontSize : 15,
      textTransform : 'uppercase',
      textDecoration : 'underline',
      color: '#009EE3',
      cursor : 'pointer',
      textDecorationColor : '#009EE3'
    },
    subTitleProject : {
        fontWeight: 400,
        fontSize : 10,
        lineHeight : '12.5px',
        textTransform : 'uppercase'
    },
    sectionContainer: {
        display: 'grid',
        gridTemplateColumns: '2.1fr 5fr 5fr',
        marginBottom: '22px',
        gridColumnGap: '22px',
        [theme.breakpoints.down(1245)]: {
            gridTemplateColumns: 'auto'
        }
    },
    iconLabor : {
      position : 'absolute',
      top : -10,
      left : -10
    },
    wrapProjectInfo : {
      backgroundColor : 'white',
      padding : '10px 10px',
      width : 178
    },
    infoTxt : {
      marginTop : 10
    },
    wrapLeftPro : {
      display : 'flex',
      // justifyContent : 'center',
      padding : 10,
      width: '65%',
    //   marginRight: 50
    },
    wrapRightPr : {
      padding : 10,
      width: '35%'
    },
    infoProjectsCard : {
      display : 'flex',
      height: 124,
      width: 650,
      // justifyContent : 'space-between'
    },
    sectionContainerSys : {
        display: 'grid',
        gridTemplateColumns: '1.4fr 6fr 3fr',
        marginBottom: '22px',
        gridColumnGap: '22px',
        [theme.breakpoints.down(1245)]: {
            gridTemplateColumns: 'auto'
        }
    },
    sectionTitle: {
        position: 'relative',
        fontFamily: 'Actual',
        color: '#4F4F4F',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '15px',
        lineHeight: '20px',
        '& span': {
            color: '#EB5757'
        },
        [theme.breakpoints.down(1245)]: {
            marginBottom: '10px'
        }
    },
    mutiInput : {
      '& .MuiAutocomplete-inputRoot' : {
          padding : 5
      },
      '& .MuiFormControl-marginNormal': {
          marginTop : 0,
          marginBottom : 0
      }
    },
    inputField: {
        '& .MuiOutlinedInput-notchedOutline': {
          transition: 'background 0.3s, border 0.3s',
          borderColor: '#C4C4C4'
        },
        '& .MuiInputBase-input': {
          color: '#333333',
        },
        '& .MuiInputBase-input::placeholder': {
          color: '#BDBDBD',
          opacity: 1
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: 0,
          fontSize: '14px',
          color: '#333333',
          fontFamily: 'Actual',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '17.5px'
        },
        '& .MuiOutlinedInput-input': {
          padding: '14px 15px'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: '#C4C4C4 !important'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#000 !important',
          borderWidth: '1px'
        }
    },
    wrapLeft : {
        display : 'flex',
        alignItems: 'flex-end'
    },
    wrapNotification : {
        height : 50,
        background : 'linear-gradient(90deg, #02A3EC 0%, #007DD8 100%)',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 10,
        alignItems: 'center',
        [theme.breakpoints.only('sm')] : {
            display : 'none'
        }
    },
    hiddenNoti : {
        display : 'none'
    },
    Noti : {
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    textHere : {
        color : '#009EE3',
        textDecoration : 'underline',
        cursor: 'pointer',
        textDecorationColor : '#009EE3'
    },
    boldInfo : {
        color : '#009EE3',
        textDecoration : 'underline',
        cursor: 'pointer',
        textDecorationColor : '#009EE3'
    },
    wrapTextNoti : {
        color: 'white',
        marginLeft: 17.5
    },
    wrapSub : {
        width: 484,
        textAlign: 'center'
    },
    sectionHeaderContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '22px'
    },
    addressSection: {
        display: 'grid',
        marginBottom: '22px',
        gridColumnGap: '22px',
        gridTemplateColumns: '3.4fr 1fr',
        [theme.breakpoints.down(700)]: {
            gridTemplateColumns: 'auto',
            gridRowGap: '15px'
        }
    },
    sectionAddressContainer: {
        display: 'grid',
        marginBottom: '22px',
        gridColumnGap: '22px',
        gridTemplateColumns: '2.1fr 5fr 3fr',
        [theme.breakpoints.down(1245)]: {
            gridTemplateColumns: 'auto'
        }
    },
    addressSection1: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: '21px',
        marginBottom: '22px',
        [theme.breakpoints.down(700)]: {
            gridTemplateColumns: 'auto',
            gridRowGap: '15px',
            marginBottom: '15px'
        }
    },
    addressSection2: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridColumnGap: '20px',
        [theme.breakpoints.down(700)]: {
            gridTemplateColumns: 'auto',
            gridRowGap: '15px'
        }
    },
    selectField: {
        width: '100%',
        height: '44px',
        padding: '0 20px 0 15px',
        background: `#fff url(${IconSelect}) right center /50px 8px no-repeat`,
        border: '1px solid #C4C4C4',
        boxSizing: 'border-box',
        color: '#333333',
        fontFamily: 'Actual',
        fontSize: '14px',
        fontWeight: '400',
        fontStyle: 'normal',
        verticalAlign: 'top',
        lineHeight: '44px',
        transition: 'background 0.3s, border 0.3s',
        '-moz-appearance': 'none',
        '-webkit-appearance': 'none',
        appearance: 'none',
        '&:focus': {
          outlineStyle: 'none',
          borderColor: '#000'
        },
        '&::-ms-expand': {
          display: 'none'
        }
    },
    picturePreview: {
        width: '100%',
        height: '220px',
        border: '1px solid #C4C4C4',
        '& img': {
            width: '100%',
            height: '100%'
        }
    },
    picturePreviewOn: {
        height: 'auto'
    },
    chooseFileBtn: {
        width: '95px',
        boxSizing: 'border-box',
        fontSize: '12px',
        lineHeight: '40px',
        padding: '2px 10px',
        borderRadius: '3px',
        backgroundColor: '#02A3EC',
        textTransform: 'none',
        fontFamily: 'Actual',
        fontStyle: 'normal',
        fontWeight: 'normal',
        color: '#FFFFFF',
        height: '35px',
        '&:hover': {
            backgroundColor: '#02A3EC'
        }
    },
    pictureContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto 95px',
        gridColumnGap: '17px'
    },
    requiredText: {
        fontFamily: 'Actual',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '15px',
        color: '#EB5757',
        marginTop: '75px'
    },
    separator: {
        height: '1px',
        maxHeight: '1px',
        width: '100%',
        backgroundColor: '#000',
        opacity: 0.1,
        marginTop: '25px',
        marginBottom: '25px',
        gridColumn: 'span 2'
    },
    navigationBtnContainer: {
        '& button.MuiButtonBase-root': {
            minWidth: '183px !important',
            padding: '13.5px 2rem !important',
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '15px',
            color: '#fff',
            [theme.breakpoints.down(700)]: {
                padding: '13.5px 1rem !important',
            }
        },
        '& button.MuiButtonBase-root.saveDraftBtn': {
            backgroundColor: '#ffffff',
            borderColor: '#8C8C8C',
            color: '#8C8C8C',
            marginRight: '20px',
            [theme.breakpoints.down(700)]: {
               marginRight: '10px',
               width: '100px',
               padding: '13.5px 0 !important',
            },
            [theme.breakpoints.down(365)]: {
                marginRight: '3px'
            }
        },
        '& button.MuiButtonBase-root.saveDraftBtnReview': {
            backgroundColor: '#D3D3D3',
            borderColor: '#8C8C8C',
            color: '#8C8C8C',
            marginRight: '20px',
            [theme.breakpoints.down(700)]: {
                marginRight: '10px',
                width: '100px',
                padding: '13.5px 0 !important',
            },
            [theme.breakpoints.down(365)]: {
                marginRight: '3px'
            }
        },
        '& button.MuiButtonBase-root.saveDraftBtn:hover': {
            backgroundColor: '#D3D3D3',
            color: '#ffffff'
        },
        [theme.breakpoints.down(1245)]: {
            '& button.MuiButtonBase-root': {
                minWidth: '140px !important'
            }
        },
        [theme.breakpoints.down(700)]: {
            '& button.MuiButtonBase-root': {
                minWidth: '100px !important'
            }
        },
        [theme.breakpoints.down(365)]: {
            '& button.MuiButtonBase-root': {
                minWidth: '70px !important'
            }
        }
    },
    productTypeSection1: {
        marginBottom: '22px'
    },
    productTypeSection2: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridColumnGap: '20px'
    },
    serialNumberContainer: {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '5px'
        }
    },
    radio: {
        background: `url(${RadioIcon}) center /100% 100% no-repeat`,
        width: '20px',
        height: '20px',
        lineHeight: '20px',
        transition: 'background 0.4s'
    },
    radioOn: {
        background: `url(${RadioOnIcon}) center /100% 100% no-repeat`,
        width: '20px',
        height: '20px',
        lineHeight: '20px',
        transition: 'background 0.4s'
    },
    batteryContainer: {
        '& > p': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            color: '#000000',
            marginBottom: '15px'
        },
        '& .MuiTypography-body1': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '15px',
            lineHeight: '20px',
            color: '#000000',
            marginLeft: '2px',
            marginRight: '17px'
        }
    },
    contactNumberContainer: {
        '& .MuiTypography-body1': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '15px',
            lineHeight: '20px',
            color: '#000000',
            marginLeft: '2px',
            marginRight: '20px'
        },
        '& .MuiTypography-body1:last-child': {
            marginRight: 0
        },
        [theme.breakpoints.down(700)]: {
            '& .MuiFormControlLabel-root:last-child': {
                marginRight: 0
            }
        }
    },
    noteContainer: {
        gridColumn: 'span 2'
    },
    note: {
        width: '100%',
        padding: '10px 12px',
        height: '138px !important',
        borderColor: '#C4C4C4',
        transition: 'border 0.3s',
        '&:focus': {
            borderColor: '#000000'
        },
        '&::-webkit-input-placeholder': {
            color: '#bdbdbd'
        }
    },
    reviewInfoContainer: {
        width: '100%',
        margin: '70px auto 51px auto',
    },
    reviewSectionContainer: {
        '& h5': {
          fontFamily: 'Actual',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '16px',
        },
        [theme.breakpoints.down(1245)]: {
          gridTemplateColumns: '1fr'
        }
    },
    reviewTitleSection: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid rgba(0, 0, 0, .1)',
        paddingBottom: '5px',   
        alignItems: 'center',
    },
    reviewSectionsContainer: {
        marginBottom: '31px',
        wordBreak: 'break-word',
        width: '80%',
        margin: 'auto',
        // '& > div:last-child > div': {
        //     border: 'none',
        // }
    },
    reviewSectionsContainerDetailProject : {
        marginTop: '30px',
        // marginTop: 36,
        wordBreak: 'break-word',
        width: '100%',
        margin: 'auto',
    },
    btnDelProject : {
        height : 35,
        width : 105,
        color : '#EB5757',
        border : '1px solid red',
        borderRadius : '3px',
        backgroundColor : 'white',
        margin: '15px 0 21px',
        cursor: 'pointer'
    },

    wrapListClaim : {
        display : 'flex'
    },

    itemClaim : {
        color : '#12a5e5',
        textDecoration : 'underline',
        marginRight : '10px',
        textDecorationColor : '#12a5e5',
        cursor : 'pointer'
    },

    dateInfo : {
        display : 'flex',
        justifyContent : 'flex-end',
        alignItems: 'center',
        // paddingBottom : 10,
        minHeight: '50px',
        borderBottom: '1px solid rgba(0, 0, 0, .1)',
        fontFamily: 'Actual',
    },

    reviewSectionContent: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        minHeight: '50px',
        alignItems: 'center',
        borderBottom: '1px solid rgba(0, 0, 0, .1)',
        gridColumn: 2,
        gridGap: '88px',
        paddingLeft: '20px',
        fontFamily: 'Actual',
        '& h6': {
          fontStyle: 'normal',
          fontWeight: '300',
          fontSize: '15px',
          color: '#4F4F4F',
        //   marginLeft: '15px'
        },
        '& p': {
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '15px',
          color: '#000000'
        },
        [theme.breakpoints.down(1245)]: {
          gridColumn: 1,
          gridTemplateColumns: 'repeat(2, 1fr)'
        },
        [theme.breakpoints.down(700)]: {
            gridTemplateColumns: 'auto',
            gridGap: '5px',
            '& h6': {
                marginLeft: 0
            },
            '& p': {
                fontSize: '15px !important'
            },
            paddingTop: '10px',
            paddingBottom: '10px'
        }
    },
    editBtn: {
        maxWidth: '85px',
        width: '85px',
        height: '35px',
        backgroundColor: '#000',
        color: '#fff',
        fontFamily: 'Actual',
        fontStyle: 'normal',
        fontSize: '12px',
        textTransform: 'capitalize',
        borderRadius: '3px',
        '& span': {
          display: 'inline-block',
        },
        '& img': {
          display: 'inline',
          marginLeft: '5px'
        },
        '&:hover': {
          backgroundColor: '#002D56'
        }
    },
    reviewNoteSection: {
        padding: '20px 25px',
        minHeight: '200px',
        background: '#F6F6F6',
        border: '1px solid #E6E6E6',
        boxSizing: 'border-box',
        fontSize: '15px'
    },
    serialNumberTable: {
        '& .MuiTableRow-head': {
            backgroundColor: '#0F2848',
            border: '1px solid #0F2848',
        },
        '& .MuiTableCell-head': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '15px',
            lineHeight: '28px',
            color: '#FFFFFF'
        },
        '& .MuiTableBody-root': {
            border: '1px solid #C4C4C4'
        },
        '& .MuiTableCell-body': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '15px',
            lineHeight: '28px',
            color: '#000000'
        },
        '& .MuiTableFooter-root': {
            borderLeft: '1px solid #C4C4C4',
            borderRight: '1px solid #C4C4C4'
        },
        '& .MuiTableCell-root': {
            borderBottomColor: '#C4C4C4'
        },
        '& .MuiTableCell-footer': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '15px',
            lineHeight: '28px',
            color: '#000000'
        }
    },
    batterySerialNumberContainer: {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '5px'
        },
        [theme.breakpoints.down(1245)]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridColumnGap: '22px'
        },
        [theme.breakpoints.down(700)]: {
            gridTemplateColumns: 'auto'
        }
    },
    errorInputField: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EB5757 !important'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EB5757 !important',
            borderWidth: '1px'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EB5757 !important',
            borderWidth: '1px'
        }
    },
    errorSelectField: {
        borderColor: '#EB5757'
    },
    datePicker: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 0
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: '#C4C4C4 !important'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000 !important',
            borderWidth: '1px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '14px 15px',
            fontSize: '14px'
        }
    },
    errorDatePickerField: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EB5757 !important'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EB5757 !important'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EB5757 !important'
        }
    },
    loadingIndicator: {
        textAlign: 'center'
    },
    loadingPro : {
      display : 'flex',
      justifyContent : 'center',
      padding : 10
    },
    multiSelect: {
        '& .select__control': {
            borderRadius: 0,
            padding: '4px',
            transition: 'border 0.4s',
            minHeight: '46px',
            borderColor: '#C4C4C4',
            paddingLeft: '7px'
        },
        '& .select__control:hover': {
          borderColor: '#000',
          boxShadow: 'none'
        },
        '& .select__control.select__control--is-focused': {
          borderColor: '#000',
          boxShadow: 'none'
        },
        '& .select__multi-value__remove:hover': {
            backgroundColor: '#00adef',
            color: '#fff'
        },
        '& .css-yk16xz-control': {
           height : '44px', borderRadius: 0
        },
        '& .css-1pahdxg-control' : {
            height : '44px', borderRadius: 0
        }
    },

    errorReactSelect: {
        '& .select__control': {
            borderColor: '#EB5757',
            boxShadow: 'none'
        },
        '& .select__control:hover': {
            borderColor: '#EB5757 !important'
        },
        '& .css-yk16xz-control': {
            borderColor: '#EB5757',
            boxShadow: 'none'
        },
        '& .css-yk16xz-control:hover': {
            borderColor: '#EB5757 !important'
        },
        '& .css-1pahdxg-control:hover' : {
            borderColor: '#EB5757 !important'
        }
    },
    images: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        margin: '-15px 0 0 -15px',
        width: 'calc(100% - 20px)',
        '& > div': {
            margin: '15px 0 0 15px',
            position: 'relative'
        },
        '& > div:hover div[role=overlay]': {
            opacity: 1
        },
        [theme.breakpoints.down(700)]: {
            width: '100%'
        }
    },
    image: {
        height: '70px',
        width: '70px',
        borderRadius: '5px'
    },
    overlay: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        background: 'rgba(0, 0, 0, 0.5)',
        width: '100%',
        height: '100%',
        opacity: 0,
        transition: 'opacity 0.5s ease',
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: '5px'
    },
    modal: {
        width: '610px',
        minHeight: '425px',
        top: '50px !important',
        margin: 'auto',
        "& .MuiPaper-root": {
            overflowX: 'hidden'
        },
        "& .MuiPaper-rounded": {
            borderRadius: 0
        },
        '& span': {
            position: 'absolute',
            right: '15px',
            top: '15px'
        },
        '& span:hover': {
            cursor: 'pointer'
        },
        [theme.breakpoints.down(650)]: {
            width: '90%'
        }
    },
    modalContent: {
        position: 'relative',
        margin: '22px 30px 30px 30px',
        '& p': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '15px',
            lineHeight: '19px',
            color: '#000000',
            marginBottom: '20px'
        },
        '& div img': {
            width: '100%',
            height: 'auto'
        }
    },
    serialNumberSwitchContainer: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px'
        },
        marginBottom: '13px'
    },
    serialNumberSwitch: {
        marginLeft: '12px',
        marginRight: '12px'
    },
    downloadTemplate: {
        color: '#009EE3 !important',
        marginTop: '8px'
    },
    serialNumberDataTable: {
        border: '1px solid #C3C3C3',
        borderRadius: '5px',
        marginTop: '25px',
        '& > div': {
            display: 'flex',
            minHeight: '42px',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            paddingLeft: '18px',
            paddingRight: '18px'
        },
        '& > div:first-child' : {
            borderRadius: '5px 5px 0 0'
        },
        '& > div:last-child': {
            borderBottom: 0,
            borderRadius: '0 0 5px 5px'
        },
        '& > div h4': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '15px',
            color: '#000000'
        },
        '& > div p': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '15px',
            color: '#000000'
        }
    },
    serialNumberManualDataTable: {
        '& > div p': {
            minWidth: '225px'
        },
        '& > div': {
            minHeight: '60px',
        }
    },
    batterySectionContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr',
        gridColumnGap: '20px',
        marginBottom: '22px',
        [theme.breakpoints.down(1245)]: {
            gridTemplateColumns: 'auto'
        }
    },
    productInfoDataTable: {
        marginTop: '10px',
        [theme.breakpoints.down(1245)]: {
            width: 'fit-content',
            '& h4': {
                marginRight: '20px'
            }
        },
        [theme.breakpoints.down(700)]: {
            width: '100%',
            '& > div': {
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                paddingTop: '18px',
                paddingBottom: '18px',
                '& p': {
                    width: '100%',
                    '& span': {
                        fontSize: '15px'
                    }
                }
            }
        }
    },
    inputFieldMediumContainer: {
        [theme.breakpoints.down(1245)]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridColumnGap: '22px'
        },
        [theme.breakpoints.down(700)]: {
            display: 'grid',
            gridTemplateColumns: 'auto',
            gridColumnGap: '22px'
        }
    },
    wrapSearch : {
        height : 5,
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: '22px',
        [theme.breakpoints.down(1245)]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: '22px'
        },
        [theme.breakpoints.down(700)]: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        gridColumnGap: '22px'
} ,
      position : 'relative'
    },
    makeStyle : {
      width : '73.9%',
    },
    inputFieldSerialNumberMediumContainer: {
        [theme.breakpoints.down(1245)]: {
            width: '85%'
        },
        [theme.breakpoints.down(700)]: {
            width: '100%'
        }
    },
    manualSerialNumberMediumContainer: {
        [theme.breakpoints.down(1245)]: {
            width: 'fit-content',
            '& h4': {
                marginRight: '20px'
            }
        },
        [theme.breakpoints.down(700)]: {
            width: '100%',
            '& > div': {
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                paddingTop: '18px',
                paddingBottom: '18px',
                '& p': {
                    width: '100%',
                    '& span': {
                        fontSize: '15px'
                    }
                }
            }
        }
    },
    inputFieldHomeownerMediumContainer: {
        gridColumn: 'span 2',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: '22px',
        [theme.breakpoints.down(700)]: {
            gridTemplateColumns: 'auto',
            gridRowGap: '15px'
        }
    },
    reviewSectionHeaderSmallScreen: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // marginLeft: '15px',
        // marginBottom: '10px',
        '& h5': {
          fontFamily: 'Actual',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '16px'
        },
        [theme.breakpoints.down(700)]: {
            marginLeft: 0
        }
    },
    systemInfoSectionAccordion: {
        boxShadow: 'none',
        marginBottom: 30,
        '& .MuiAccordionSummary-root': {
            padding: 0,
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            marginTop: '26px'
        },
        '& .MuiAccordionSummary-content': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '20px',
            lineHeight: '25px',
            color: '#4F4F4F'
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '48px'
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0'
        },
        '& .MuiAccordionDetails-root': {
            padding: '30px 0',
            display: 'block'
        },
        '&.MuiAccordion-root.Mui-expanded': {
            margin: 0
        },
        '&.MuiAccordion-root::before': {
            height: 0
        },
        '& .MuiAccordion-root': {
            marginTop: '20px'
        },
        '& .MuiExpansionPanelDetails-root' : {
            display : 'block !important',
            marginTop: 30,
            padding: 0
        },
        '& .MuiExpansionPanelSummary-content.Mui-expanded' : {
            margin: '12px 0',
        },
        ' & .MuiExpansionPanelSummary-root': {
            padding: 0,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
        },
        '& .MuiIconButton-edgeEnd': {
            margin: 0,
        },
        '& .MuiIconButton-root': {
            padding: 0,
        },
        '& .MuiExpansionPanel-root::before': {
            height: '0 !important',
        },
        '& .MuiExpansionPanelSummary-root.Mui-expanded': {
            minHeight: 48
        },
        '&.MuiExpansionPanel-root:before': {
            display: 'none',
        }
    },
    notiBox: {
        width: '100%',
        padding: '10px',
        // textAlign: 'justify',
        backgroundColor: '#F6FAFD',
        border: '1px solid #DCE1E1',
        borderRadius: '3px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginTop: '22px',
        fontWeight: 300,
        fontSize: 15,
        '& p': {
            fontSize: '15px',
            color: '#4F4F4F',
            fontFamily: 'Actual'
        }
    },
    notiBoxSN: {
        width: '100%',
        padding: '10px',
        // textAlign: 'justify',
        backgroundColor: '#F6FAFD',
        border: '1px solid #DCE1E1',
        borderRadius: '3px',
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: '22px',
        fontWeight: 300,
        fontSize: 15,
        '& p': {
            fontSize: '15px',
            color: '#4F4F4F',
            fontFamily: 'Actual'
        }
    },
    inputFieldDisabled: {
        backgroundColor: '#EEEEEE',
        borderColor: '#C4C4C4'
    },
    productInfoTable: {
        '& > div': {
            minHeight: '42px',
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'grid',
            gridColumnGap : '20px',
            gridTemplateColumns: '1fr 2.2fr 1fr',
            marginBottom: '22px',
            [theme.breakpoints.down(700)]: {
                gridTemplateColumns: '1fr',
                gridRowGap: '15px'
            }

        },
        '& > div:last-child': {
            borderBottom: 0
        },
        '& > div h4': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '15px',
            color: '#4f4f4f'
        },
        '& > div p': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '15px',
            color: '#000000'
        }
    }
}));

export default useStyles;
