import React from 'react';
import styled from 'styled-components';

const Grid = styled('section')`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  grid-template-rows: repeat(${({ rows }) => rows}, 1fr) auto;
  grid-column-gap: 1.125rem;
  grid-row-gap: 2.25rem;
`;

export default function Products({
  style,
  productCount = 0,
  columns = 6,
  className,
  children
}) {
  return (
    <Grid
      style={style}
      className={className}
      columns={columns}
      rows={Math.ceil(productCount / columns)}
    >
      {children}
    </Grid>
  );
}
