import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Popover, Switch, makeStyles, TextField, InputAdornment, IconButton, TableContainer, Grid, Table, TableCell, TableHead, TableRow, TableBody, Checkbox, Box, Modal, Paper, useMediaQuery, Drawer, Button, Backdrop } from '@material-ui/core';
import BreadCrumb2 from '../../common/components/BreadCrumb2';
import Banner from '../../common/components/Banner';
import RegistrationBanner from '../../config/images/registration-banner.jpg';
import { useAuth } from '../../contexts/auth';
import SearchIcon from '../../config/images/search_icon.svg';
import DatePickerCustom from './../../common/components/DatePicker';
import ClearIcon from '@material-ui/icons/Clear';
import CustomButton from '../../common/components/button';
import CheckBox from '../../config/images/bg_checkbox.svg';
import CheckBoxOn from '../../config/images/bg_checkbox_on.svg';
import axios from '../../utils/axios';
import config from '../../config';
import { notiFailed, convertHTMLEntity } from './../../utils/helpers.js';
import Moment from 'react-moment';
import CustomLoading from '../../common/components/CustomLoading';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import Text from '../../common/components/text';
import CloseIcon from '@material-ui/icons/Close';
import { ExcelCustomComponent } from '../../utils/components/excel-custom';
import { useTheme, withStyles } from '@material-ui/core/styles';
import CloseDrawerIcon from '../../config/images/close_drawer_icon.svg';
import FilterIcon from '../../config/images/filter_icon.svg';
// import IconMap from '../../icons/icon_map.svg';
import IconDownload from '../../icons/icon_download.svg';
import { Link } from 'react-router-dom';
import { claimService, dashboardService, projectService } from '../../services';
import  ImageLabor from '../../icons/blueLabor.svg';
import  DefaultProjectImg  from '../../icons/defaultProjectImg.png'
import SwitchButton from './components/SwitchButton'
import CardProject from './components/CardProject';
import ViewProject from './components/ViewMapProject';
import classNames from 'classnames';
import { event } from 'react-ga';
import './components/index.css';
import NumberFormat from "react-number-format";
import ReviewInfo from './components/ReviewInfo';
import DetailProject from './components/DetailProject';
import IconClose from '../../config/images/closeModal.svg';
import CustomizedSwitches from './components/SwichButonCustome';
import IconActionMore from '../../icons/iconActionMore.svg';
import {useLocation} from "react-router-dom";
import { getDetailProject } from '../../services/project';

const AntSwitch = withStyles((theme) => ({
    root: {
      width: 30,
      height: 18,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: '#4F4F4F',
        '&$checked': {
          transform: 'translateX(12px)',
          color: '#009EE3',
          '& + $track': {
            opacity: 1,
            backgroundColor: theme.palette.common.white,
            borderColor: 'theme.palette.primary.main',
          },
        },
      },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

const useStyles = makeStyles(theme => ({
    banner: {
        width: '90%',
        margin: '30px auto 0',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            width: '90%'
        },
        '& .breadcrumb2.form': {
            right: 0,
        },
    },
    container: {
        width: '90%',
        margin: '0 auto 70px',
        [theme.breakpoints.down('md')]: {
            width: '90%'
        }
    },
    separator: {
        height: '1px',
        maxHeight: '1px',
        width: '100%',
        backgroundColor: '#000',
        opacity: 0.1,
        gridColumn: 'span 2'
    },
    buttonSavePopover: {
        background: '#000',
        color: '#fff',
        borderRadius: '3px',
        textTransform: 'capitalize',
        '&:hover': {
            background: '#000',
            color: '#fff',
        }
    },

  buttonSaveDisible : {
    background: '#777',
    color: '#fff',
    borderRadius: '3px',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#000',
      color: '#fff',
    }
  },
    dashboard: {
        textDecoration: 'underline',
        color: '#000',
        '& > span': {
            marginRight: '25px',
            cursor : 'pointer'
        }
    },
    selectAll : {
      marginLeft : 20,
      display : 'flex',
      alignItems : 'center'
    },
    iconLabor: {
        position: 'absolute',
        width: '56px',
        height: '56px',
        top: '-15px',
        left: '-15px',
        zIndex: '2',
    },
    containerPopover: {
        padding: '12px 16px',
        '& .MuiOutlinedInput-input': {
            padding: '13px 14px',
        }

    },
    title: {
        fontFamily: 'Actual',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '36px',
        lineHeight: '42px',
        color: '#000000'
    },
    actionContainer: {
        display: 'flex',
        marginTop: '20px',
        marginBottom: '20px',
        justifyContent: 'space-between'
    },
    infoProjects: {
        display: 'flex',
        padding: '20px 0',
    },
    inputField: {
        '& .MuiOutlinedInput-notchedOutline': {
          transition: 'background 0.3s, border 0.3s',
          borderColor: '#C4C4C4'
        },
        '& .MuiInputBase-input': {
          color: '#333333',
        },
        '& .MuiInputBase-input::placeholder': {
          color: '#BDBDBD',
          opacity: 1
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '43px',
          fontSize: '14px',
          color: '#333333',
          fontFamily: 'Actual',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '17.5px'
        },
        '& .MuiOutlinedInput-input': {
          padding: '14px 15px'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: '#C4C4C4 !important'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#000 !important',
          borderWidth: '1px'
        }
    },
    datePicker: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '3px'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: '#C4C4C4 !important'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000 !important',
            borderWidth: '1px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '14px 15px',
            fontSize: '14px'
        }
    },
    flexCenterContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    showMapButton: {
        display: 'flex',
        // background: '#4F4F4F',
        color: '#fff',
        borderRadius: '43px',
        padding: '10px',
        alignItems: 'center',
        marginRight: '9px',
        // height: '100%',
        height: 45
    },
    downloadButton: {
        border: '1px solid #8C8C8C',
        boxSizing: 'border-box',
        background: '#fff',
        borderRadius: '100px',
        height: '45px',
        fontFamily: 'Actual',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '15px',
        lineHeight: '19px',
        textAlign: 'center',
        color: '#8C8C8C',
        textTransform: 'none',
        // width: '100%',
        width: 'max-content',
        // maxWidth: '150px',
        padding: '9px 15px',
        // [theme.breakpoints.down('md')]: {
        //     maxWidth: '140px',
        //     minWidth: '120px !important'
        // },
        [theme.breakpoints.down(650)]: {
            height: '45px'
        },
        [theme.breakpoints.down(340)]: {
            maxWidth: '130px',
            minWidth: '120px !important'
        }
   },
    downloadButtonActive : {
      border: '1px solid #269ee3',
      boxSizing: 'border-box',
      background: '#fff',
      borderRadius: '100px',
      height: '45px',
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '19px',
      textAlign: 'center',
      color: '#269ee3',
      textTransform: 'capitalize',
      // width: '100%',
      width: 'max-content',
      // maxWidth: '150px',
      padding: '9px 15px',
      // [theme.breakpoints.down('md')]: {
      //     maxWidth: '140px',
      //     minWidth: '120px !important'
      // },
      [theme.breakpoints.down(650)]: {
        height: '45px'
      },
      [theme.breakpoints.down(340)]: {
        maxWidth: '130px',
        minWidth: '120px !important'
      }
    },
   viewMoreButton: {
        border: '1px solid #8C8C8C',
        boxSizing: 'border-box',
        borderRadius: '100px',
        fontFamily: 'Actual',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '15px',
        lineHeight: '19px',
        textAlign: 'center',
        color: '#8C8C8C',
        textTransform: 'capitalize',
        width: '100%',
        maxWidth: '185px',
        height: '45px',
        padding: '0 !important',
        [theme.breakpoints.down(650)]: {
            maxWidth: '100px',
            minWidth: '100px !important'
        }
   },
   deleteButton: {
        border: '1px solid #8C8C8C',
        boxSizing: 'border-box',
        borderRadius: '100px',
        fontFamily: 'Actual',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '15px',
        lineHeight: '19px',
        textAlign: 'center',
        color: '#8C8C8C',
        textTransform: 'capitalize',
        width: '100%',
        maxWidth: '120px',
        minWidth: '100px !important',
        height: '45px',
        padding: '0 !important',
        [theme.breakpoints.down(650)]: {
            maxWidth: '100px',
            minWidth: '100px !important'
        }
   },
   projectsTable: {
        '& .MuiTableRow-head': {
            backgroundColor: '#0F2848',
            border: '1px solid #0F2848',
        },
        '& .MuiTableCell-head': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '15px',
            lineHeight: '28px',
            color: '#FFFFFF'
        },
        '& .MuiTableBody-root .MuiTableRow-root:nth-child(2n)': {
            backgroundColor: '#F6F6F6',
            borderBottom: '1px #DCDCDC solid'
        },
        '& .MuiTableCell-body': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: '300',
            fontSize: '15px',
            lineHeight: '28px',
            color: '#000000',
            paddingTop: 0,
            paddingBottom: 0,
            padding: '20px'
        },
        '& .MuiTableFooter-root': {
            borderLeft: '1px solid #C4C4C4',
            borderRight: '1px solid #C4C4C4'
        },
        '& .MuiTableCell-root': {
            borderBottomColor: '#C4C4C4',
            whiteSpace: 'nowrap',
            borderBottom: 'none',
        },
        '& .MuiTableRow-hover': {
            cursor: 'pointer'
        },
        '& .MuiTableCell-sizeSmall:last-child': {
            paddingRight: '20px'
        }
   },
   checkBox: {
    background: `url(${CheckBox}) center /100% 100% no-repeat`,
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    transition: 'background 0.4s'
  },
  checkBoxOn: {
    background: `url(${CheckBoxOn}) center /100% 100% no-repeat`,
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    transition: 'background 0.4s'
  },
  actionBottomContainer: {
    marginTop: '45px'
  },
  deleteButtonText: {
    fontFamily: 'Actual',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '15px !important',
    lineHeight: '45px !important',
    textAlign: 'center',
    color: '#000000',
    opacity: '0.7',
    marginRight: '10px',
    [theme.breakpoints.down(340)]: {
        fontSize: '13px !important',
    }
  },
  loadingEmptyRow: {
      height: '400px',
      border: '1px solid #C4C4C4',
      '& .MuiBox-root': {
          height: '320px',
          backgroundColor: 'rgb(247, 247, 247)',
      }
  },
  loadingEmtyRowMap : {
    height: '680px',
    border: '1px solid #C4C4C4',
    '& .MuiBox-root': {
      height: '320px',
      backgroundColor: 'rgb(247, 247, 247)',
      marginRight: '6px',
      marginLeft: '6px'
    }
  },
  modal: {
    width: '40rem',
    height: '50%',
    top: '30px',
    margin: 'auto',
    "& .MuiPaper-root": {
        overflowX: 'hidden'
    },
    [theme.breakpoints.down('md')]: {
        width: '98%',
        overflowY: 'auto'
    }
  },
  modalDetail: {
    width: '50%',
    height: '100%',
    top: '30px',
    // margin: 'auto',
    // position : 'absolute !important',
    left : '50% !important',
    overflowY : 'scroll',
    "& .MuiPaper-root": {
      overflowX: 'hidden'
    },
    [theme.breakpoints.down('md')]: {
      width: '98%',
      overflowY: 'auto'
    }
  },
  header: {
    [theme.breakpoints.down('md')]: {
        fontSize: '20px !important'
    }
  },
  paper: {
    textAlign: 'center',
    backgroundColor: '#ffffff',
    color: '#8C8C8C'
  },
  clearIconBtn: {
    padding: 0,
    position: 'absolute',
    top: '22px',
    right: '22px',
    [theme.breakpoints.down('md')]: {
        top: '15px',
        right: '15px',
    }
  },
  drawer: {
      '& .MuiDrawer-paper': {
        width: 'calc(100% - 60px)',
        position: 'unset',
        boxShadow: '-1px 3px 15px rgba(0, 0, 0, 0.2)'
      },
      '& .closeBtnContainer': {
        position: 'relative'
      },
      '& .closeBtn': {
          position: 'absolute',
          right: 0,
          width: '60px',
          height: '60px',
          minWidth: '60px',
          backgroundColor: '#fff',
          borderRadius: 0
      },
      '& .filterFields': {
          margin: '40px 20px'
      },
      '& .filterFields h5': {
        fontFamily: 'Actual',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '15px',
        textTransform: 'uppercase',
        color: '#009EE3',
        marginBottom: '20px'
      },
      '& .filterField': {
          marginBottom: '22px'
      }
  },
  actionMore: {
    textAlign: 'end',
    '& .MuiButton-text': {
        padding: 0,
    },
    '& .MuiButton-root': {
        minWidth: 'fit-content',
        textTransform: 'capitalize'
    }
  },
  buttonActionMore: {
    cursor: 'pointer',
    width: '148px',
    padding: '7px 0 7px 14px',
    fontSize: '15px',
    '&:first-child': {
        borderBottom: '1px solid #e0e0e0'
    }
  },
  excerptName: {
    fontSize: '17px',
    fontWeight: '400',
    display: 'block',
    width: '300px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}));

const { baseUrl } = config;
const PAGE_SIZE = 25;

function FormatSys(props) {
  return <NumberFormat {...props} allowNegative={false} />;
}

export default function MyProjects() {
    const user = useAuth();
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const [projects, setProjects] = useState([]);
    const [size, setSize] = useState(PAGE_SIZE);
    const [total, setTotal] = useState(0);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [installationDate, setInstallationDate] = useState(null);
    const [operateDate, setOperateDate] = useState(null);
    const [openDetail, setOpenDetail] = useState(false);
    const [projectActive, setProjectActive] = useState(null);
    const [distributors, setDistributors] = useState([]);
    const [totalDraft, setTotalDraft] = useState(0);
    const [totalComplete, setTotalComplete] = useState(0);
    const [listProjectFilter, setListProjectFilter] = useState([]);
    const [rangeInstallDate, setRangeInstallDate] = useState({
      startDate : null,
      endDate : null
    })
    const [rangeSystemSize, setRangeSystemSize] = useState({
      startSys : null,
      endSys : null
    })

    const [battery, setBattery] = useState(false);
    const [activeIdButton, setActiveIdButton] = useState(null);
    const [isSaveInstallDate, setSaveInstallDate] = useState(false);
    const [isSaveSys, setSaveSys] = useState(false);
    const [isSaveBattery, setSaveBattery] = useState(false);


    const selectedProjects = useMemo(() => projects.filter(p => p.isSelected).map(p => p.id), [projects]);
    const typingTimeoutRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [openDel, setOpenDel] = useState(false);
    const [loadingDeleteProject, setLoadingDeleteProject] = useState(false);
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [dashboardProjects, setDashboardProjects] = useState([]);
    const [showFilterDate, setShowFilterDate] = useState(false);
    const [showFilterSize, setShowFilterSize] = useState(false);
    const [showFilterBattery, setShowFilterBattery] = useState(false);
    const [showActionMore, setShowActionMore] = useState(false);
    const isLowerMediumSize = useMediaQuery(theme.breakpoints.down('md'));
    const isLowerSmallSize = useMediaQuery('(max-width: 650px)');
    const [showMap, setShowMap] = useState(false);
    const [idActive, setIdActive]  = useState(1);

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
      }
    // let query = useQuery();
    // let filter2 = query.get("filter");
    let pathname  = useLocation();

    useEffect(() => {
      if(rangeSystemSize.endSys !== null && rangeSystemSize.endSys !== null && parseInt(rangeSystemSize.endSys) > parseInt(rangeSystemSize.endSys)) {
        notiFailed('End System Size should be greater than start System Size')
      }
    }, [rangeSystemSize])

    useEffect(() => {
      let payloadDraft = {}
      if(_.get(pathname, 'state.isDraft')) {
        payloadDraft = { isDraft : true}
        setIdActive(3)
      }
      let payload = {...filters, ...payloadDraft}
      getProjects(payload);
      getDashboardProjects();
      getDistributor();
    }, []);

    useEffect(() => {
      if(!!pathname?.id) {
        getDetailProject(pathname.id);
      }
    },[pathname]);

    useEffect(() => {
      if(idActive === 2) {
        let dataComp = projects.filter(item => !item.isDraft)
        setListProjectFilter(dataComp);
      }

      if(idActive === 3) {
        let dataDraft = projects.filter(item => item.isDraft)
        setListProjectFilter(dataDraft);
      }
    }, [idActive, projects])

    async function getDetailProject(id) {
      projectService.getDetailProject(id).then(res => {
        setProjectActive(res.data)
        setOpenDetail(true)
      });

    }

    const handleClickFilter = (event, filter, projectActive) => {
      event.stopPropagation();
        switch (filter) {
            case 'date':
                setShowFilterDate(event.currentTarget);
                setActiveIdButton(1)
                break;
            case 'size':
                setShowFilterSize(event.currentTarget);
                setActiveIdButton(2)
                break;
            case 'battery':
                setShowFilterBattery(event.currentTarget);
                setActiveIdButton(3)
                break;
            case 'action':
                setProjectActive(projectActive)
                setShowActionMore(event.currentTarget);
        }
    };

    function handleCloseModalDetail() {
        setOpenDetail(false);
    }
    const handleCloseFilter = (filter) => {
        setShowFilterDate(false);
        switch (filter) {
            case 'date':
                setShowFilterDate(false);
                !isSaveInstallDate && setRangeInstallDate({
                  startDate: null,
                  endDate: null
                })
                break;
            case 'size':
                setShowFilterSize(false);
                !isSaveSys && setRangeSystemSize({
                  startSys: '',
                  endSys: ''
                })
                break;
            case 'battery':
                setShowFilterBattery(false);
                !isSaveBattery && setBattery(false)
                break;
            case 'action':
                setShowActionMore(false);
        }
    };
    const getProjects = async (filters = {}) => {
        try {
            setLoading(true);
            const { data } = await axios.get(`${baseUrl}/projects/search`, { params: filters });
            setProjects(data.data.map(p => ({ ...p, installationDate: p.installationDate ? moment.utc(p.installationDate) : null , operateDate: p.operateDate ? moment.utc(p.operateDate) : null, isSelected: false })));
            setTotal(data.total);
            setTotalComplete(data.submiited)
            setTotalDraft(data.draft)

            return Promise.resolve();
        } catch (err) {
            notiFailed(`Something happened getting projects`);
            return Promise.reject();
        } finally {
            setLoading(false);
        }
    };

    async function getDistributor() {
      const res = await claimService.getDistributor()
      setDistributors(res.data);
    }

    const renderProjectName = p => {
        if (p.projectName) {
            return p.projectName;
        }

        if (p.firstName && p.lastName) {
            return `${p.firstName} ${p.lastName}`;
        } else if (p.firstName) {
            return p.firstName;
        } else if (p.lastName) {
            return p.lastName;
        }

        if (p.projectCity) {
            return p.projectCity;
        }

        return null;
    };

    const viewMore = async () => {
        const newSize = size + PAGE_SIZE;
        const filter = {
            ...filters,
            size: newSize
        }

        try {
            setLoadingMore(true);
            await getProjects(filter);
            setSize(newSize);
            setFilters(filter);
        } catch (err) {}
        finally {
            setLoadingMore(false);
        }
    };


    const handleSelectInstallationEndDate = (date) => {
      setRangeInstallDate(prevState => ({
        ...prevState,
        endDate: date
      }))
    }

    const clearInstallationDate = async () => {
      setRangeInstallDate({
        startDate: null,
        endDate : null
      })
      setSaveInstallDate(false);
      const rawPayload = {
        startInstallationDate : null,
        endInstallationDate : null,
        minSystemSize : rangeSystemSize.startSys,
        maxSystemSize : rangeSystemSize.endSys,
        battery: battery ? battery : '',
        ...filters,
      }

      const payload  = clean(rawPayload);

      try {
        await getProjects(payload);
      } catch (err) {}

    }

    const handleSelectInstallationStartDate = async (date) => {
        setRangeInstallDate(prevState => ({
          ...prevState,
          startDate: date
        }))
    };

    const handleSelectOperateDate = async date => {
        setOperateDate(date);

        const filter = { ...filters, size: PAGE_SIZE };

        if (date) {
            filter.operateDate = moment(date).format('YYYY-MM-DD');
        } else {
            delete filter.operateDate;
        }

        try {
            await getProjects(filter);
            setSize(PAGE_SIZE);
            setFilters(filter);
        } catch (err) {}
    };

    const handleSearchProject = async (type) => {
      setIdActive(1)
      switch (type) {
        case 'date' :
          setSaveInstallDate(true)
          break;
        case 'range':
          setSaveSys(true)
          break
        case 'battery':
          setSaveBattery(true)
          break

      }

      setShowFilterDate(false)
      setShowFilterSize(false);
      setShowFilterBattery(false);
      const rawPayload = {
        startInstallationDate : rangeInstallDate.startDate ? moment(rangeInstallDate.startDate).format('YYYY-MM-DD') : null,
        endInstallationDate : rangeInstallDate.endDate ? moment(rangeInstallDate.endDate).format('YYYY-MM-DD') : null,
        minSystemSize : rangeSystemSize.startSys,
        maxSystemSize : rangeSystemSize.endSys,
        battery : battery ? battery : '',
        ...filters,
      }

      const payload  = clean(rawPayload);


      try {
        await getProjects(payload);
      } catch (err) {}
    }

    function clearInfoBattery() {
      setBattery(false);
      setSaveBattery(false);

      const rawPayload = {
        startInstallationDate : rangeInstallDate.startDate ? moment(rangeInstallDate.startDate).format('YYYY-MM-DD') : null,
        endInstallationDate : rangeInstallDate.endDate ? moment(rangeInstallDate.endDate).format('YYYY-MM-DD') : null,
        minSystemSize : rangeSystemSize.startSys,
        maxSystemSize : rangeSystemSize.endSys,
        battery : battery ? battery : '',
        ...filters,
      }

      const payload  = clean(rawPayload);

      try {
        getProjects(payload);
      } catch (err) {}
    }

    function clean(obj) {
    for (let propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj
  }

    const handleSearchText = async text => {
        setIdActive(1)
        clearTimeout(typingTimeoutRef.current);
        setSearchText(text);
        const filter = {
            ...filters,
            size: PAGE_SIZE,
            keyword: text
        };

        typingTimeoutRef.current = setTimeout(async () => {
            try {
                await getProjects(filter);
                setSize(PAGE_SIZE);
                setFilters(filter);
            } catch (err) {}
        }, 300);
    };

    const handleSelectProject = (id, checked) => {
        const newProjects = projects.map(p => {
            if (p.id === id) {
                return {
                    ...p,
                    isSelected: checked
                };
            }

            return p;
        });
        setProjects(newProjects);
    };

    function handleClearSys() {
      setRangeSystemSize({
        startSys: null,
        endSys: null
      })
      setSaveSys(false)
      const rawPayload = {
        startInstallationDate : rangeInstallDate.startDate ? moment(rangeInstallDate.startDate).format('YYYY-MM-DD') : null,
        endInstallationDate : rangeInstallDate.endDate ? moment(rangeInstallDate.endDate).format('YYYY-MM-DD') : null,
        minSystemSize : rangeSystemSize.startSys,
        maxSystemSize : rangeSystemSize.endSys,
        battery: battery ? battery : '',
        ...filters,
      }

      const payload  = clean(rawPayload);

      try {
        getProjects(payload);
      } catch (err) {}
    }

    function handleChangeMinSys(event) {
      const minSys = event.target.value;
      if(rangeSystemSize.endSys !== null && parseInt(minSys) > parseInt(rangeSystemSize.endSys)) {
        notiFailed('End System Size should be greater than start System Size')
      }
      setRangeSystemSize(prevState => ({
        ...prevState,
        startSys : minSys
      }))
    }

    function handleChangeMaxSys(event) {
      const maxSys = event.target.value;
      if(rangeSystemSize.startSys !== null && parseInt(rangeSystemSize.startSys) > parseInt(maxSys)) {
        notiFailed('End System Size should be greater than start System Size')
      }
      setRangeSystemSize(prevState => ({
        ...prevState,
        endSys: maxSys
      }))
    }

    function handleChangeStatusBattery(event) {
      const battery = event.target.checked;
      setBattery(battery);
    }

    const handleClose = () => {
        setOpen(false);
    };

    async function getDashboardProjects() {
        try {
            const { data } = await dashboardService.getDashboardProjects();
            setDashboardProjects(data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleDeleteProjects = async () => {
        try {
            setLoadingDeleteProject(true);
            await axios.delete(`${baseUrl}/projects/delete`, { data: [_.get(projectActive, 'id')] });
            getProjects(filters);
            handleClose();
        } catch (err) {
            notiFailed(`Something happened delete projects`);
        } finally {
            setLoadingDeleteProject(false);
            setOpenDetail(false);
        }
    };

    const getSystemSize = product => {
        let systemSize = null;

        if (product.moduleProductType === 'other') {
            systemSize = product.volume * product.numberOfModules;
        }

        if (product.moduleProductType === 'qcells' && product.isSerialNumber) {
            if (product.isManualSerialNumber) {
                systemSize = product.volume * product.numberOfPanels;
            } else {
                const moduleSerialNumber = _.filter(_.get(product, 'serialNumbers'), p => p.type === 'modules');
                if (_.get(moduleSerialNumber, 'length')) {
                    const uniqModuleSerialNumber = _.remove(_.uniqBy(moduleSerialNumber, 'productName'), p => p.productName);
                    systemSize = _.get(moduleSerialNumber, 'length') * _.get(uniqModuleSerialNumber, '[0].volume');
                }
            }
        }

        return systemSize ? `${systemSize / 1000} kW` : '';
    };

    const exportProjects = async () => {
        document.getElementById('projects-data-sheet').click();
    };

    const getProjectsDataSheet = projects => {
        if (!projects.length) {
            return [{
                'Draft date': '',
                'Registered date': '',
                'Update date': '',
                'Company id': '',
                'Company name': '',
                'Project name': '',
                'Project address1': '',
                'Project address2': '',
                City: '',
                State: '',
                'Zip code': '',
                'System size': '',
                'Module brand': '',
                'Module type': '',
                'Module power class': '',
                'Module q\'ty': '',
                Battery: '',
                'System type': '',
                'Battery brand': '',
                'Battery type': '',
                'Battery capacity': '',
                'Installation date': '',
                'Name of purchasing channel': '',
                'Registered by': '',
                'Labor reimbursement': '',
                Registered: '',
                'First saved source': '',
                'First registered source': '',
                'Last updated source': '',
                'Module upload source': '',
                'Inverter upload source': ''
            }];
        }

        function renderModuleBrand(moduleBrand) {
            switch (moduleBrand) {
              case 'qcells':
                return 'Q CELLS'
              case 'other':
                return  'Not Q CELLS'
              default:
                return ''
            }
        }

        return projects.map(p => ({
            'Draft date': p.draftedAt ? moment(p.draftedAt).format('MM/DD/YYYY') : '',
            'Registered date': p.createdAt ? moment(p.createdAt).format('MM/DD/YYYY') : '',
            'Update date': p.updatedAt ? moment(p.updatedAt).format('MM/DD/YYYY') : '',
            'Company id': _.get(p, 'user.companyId'),
            'Company name': convertHTMLEntity(_.get(p, 'user.companyName')),
            'Project name': renderProjectName(p),
            'Project address1': p.projectAddress,
            'Project address2': p.projectAddress2,
            City: p.projectCity,
            State: p.projectState,
            'Zip code': p.projectZipCode,
            'System size': p.systemSize,
            'Module brand': renderModuleBrand(p.moduleBrand),
            'Module type': '',
            'Module power class': p.modulePowerClass,
            'Module q\'ty': p.numberOfModules,
            Battery: p.battery ? 'Y' : 'N',
            'System type': p.systemType,
            'Battery brand': p.batteryBrand === 'qcells' ? 'Q CELLS'
                : p.batteryBrand === 'other'
                ? 'Not Q CELLS' : '',
            'Battery type': '',
            'Battery capacity': (p.batteryInverterPower === 'select' || p.batteryInverterPower === '') ? '' : p.batteryInverterPower,
            'Installation date': p.installationDate ? moment(p.installationDate).format('MM/DD/YYYY') : '',
            'Name of purchasing channel': p.nameOfPurchasingChannel,
            'Registered by': _.trim(`${_.get(p, 'user.firstName') || ''} ${_.get(p, 'user.lastName') || ''}`),
            'Labor reimbursement': p.qualifyLaborWarranty ? 'Y' : 'N',
            Registered: '',
            'First saved source': p.firstSavedSource,
            'First registered source': p.firstRegisteredSource,
            'Last updated source': p.source,
            'Module upload source': p.moduleModel === 'select' ? '' : p.moduleModel,
            'Inverter upload source': p.batteryModel === 'select' ? '' : p.batteryModel
        }));
    };

    const projectsDataSheet = useMemo(() => getProjectsDataSheet(projects), [projects]);

    function handleReset() {
      setIdActive(1);
      setRangeInstallDate({
        startDate: null,
        endDate: null
      })
      setRangeSystemSize({
        startSys: '',
        endSys: ''
      })

      setBattery(false)
      setActiveIdButton(null)
      setSaveInstallDate(false)
      setSaveBattery(false)
      setSaveSys(false)



      try {
        getProjects({});
      } catch (err) {}


    }

    function handleActive(id) {
      setIdActive(id)
    }

    function suggestType(p) {
      //case 1 : b-1) Q CELLS module only, no ESS - with S/N (valid or awaiting update) (panelNonQcells : false & panelQualified : true)
      const panelQualified  = p.panelQualified;
      const batteryQualified = p.batteryQualified;
      const panelNonQcells  = p.panelNonQcells;
      const batteryNonQcells = p.batteryNonQcells;

      let type = 'select';



      if(!panelNonQcells && !batteryNonQcells) {
        type = 'select'
      }else if(!panelNonQcells) {
        type = '1'
      }else if(!batteryNonQcells) {
        type = '2'
      }




      return type



      /**
       * fine 1 : (only) panelNonQcells : false & panelQualified : true -> type 1 (2) -> []
       * fine 2 : (only) batteryNonQcells : false & batteryQualified : true -> type 2 (1) ->  []
       * fine 3 : fine 1 + fine 2 -> type : 'select'
       *
       *
       *
       *
       * */

    }

    const downloadWarranty = async (event, projectId) => {
      event.stopPropagation()
      axios.get(`${baseUrl}/projects/warranty/download/${projectId}`, { responseType: 'blob' })
        .then(res => {
          console.log(res);
          const fileUrl = window.URL.createObjectURL(new Blob([res.data], { type: 'application/octet-stream'}));
          const link = document.createElement('a');
          link.href = fileUrl;
          link.setAttribute('download', 'certificates.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
        }).catch(err => notiFailed(`There was a problem download`))
    }

    function renderButtonSubmit(p) {
      const panelQualified  = p.panelQualified;
      const batteryQualified = p.batteryQualified;
      const panelNonQcells  = p.panelNonQcells;
      const batteryNonQcells = p.batteryNonQcells;

      let laborQcell = false;
      let laborQuanlify = false;

      if(!panelNonQcells === true || !batteryNonQcells === true) {
        laborQcell = true
      }

      if(panelQualified === true || batteryQualified === true) {
        laborQuanlify = true
      }

      return(
        <>
          {!p.isDraft && laborQcell ? (
            <>
              <span onClick={(event) => {
                event.stopPropagation()
                if(p.battery) {
                  history.push({
                    pathname : `/claim/info`,
                    state : {
                      battery : true,
                      type : suggestType(p),
                      id : p.id,
                      // projectInfo : p
                    }
                  })
                }else {
                  history.push({
                    pathname : `/claim/info`,
                    state : {
                      battery : false,
                      type : suggestType(p),
                      id : p.id,
                      // projectInfo : p
                    }
                  })
                }
              }} className="title_animation2" style={{ textTransform: 'uppercase', color: '#009EE3', fontWeight: '400' }}>submit a claim</span><br/>
              {
                (!p.batteryNonQcells || !p.panelNonQcells) && (p.moduleModel?.includes('+') || p.batteryModel?.includes('+')) ?
                  (
                    <>
                      <span className="title_animation2" style={{ textTransform: 'uppercase', color: '#009EE3', fontWeight: '400' }} onClick={(event) => { downloadWarranty(event, p.id) }}>
                        DOWNLOAD HOMEOWNER WARRANTY CERTIFICATE
                      </span>
                    </>
                  ) : null
              }
            </>
          ) : null}
        </>
      )
    }

    function renderListProject(projects, showMap) {
      return(
        <>
          {projects.length > 0 && !showMap  ? (
            <>
              {
                projects.map(p => (
                  <TableRow
                    key={p.id}
                    hover
                    onClick={() => {
                      setProjectActive(p)
                      setOpenDetail(true)
                    }
                    }
                  >
                    <TableCell>
                      <div className={classes.infoProjects}>
                        <div style={{position:'relative'}}>
                          <img src={_.split(p.projectImages, '|')[0]||DefaultProjectImg} style={{width: '156px', height: '80px'}} />
                          {p.qualifyLaborWarranty && (<img className={classes.iconLabor} src={ImageLabor} />) }
                        </div>
                        <div style={{fontSize: '15px', marginLeft: '15px'}}>
                          <p className={classes.excerptName}>{renderProjectName(p)}</p>
                          <p style={{lineHeight: '20px'}}>City: {p.projectCity}</p>
                          <p style={{lineHeight: '20px'}}>System size: {p.systemSize ? <> {p.systemSize} kW </> : null} </p>
                          <p style={{lineHeight: '20px'}}>Installation Date: {p.installationDate && <Moment format="L">{p.installationDate}</Moment>}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {!p.qualifyLaborWarranty ? (p.isDraft ? '---' : 'No') : 'Yes'}
                    </TableCell>
                    <TableCell>
                      {!p.isDraft && p.installationDate ? (
                        <>
                          Completed on <Moment format="L">{p.operateDate}</Moment>
                        </>
                      ) : 'Draft'
                      } <br />
                      {renderButtonSubmit(p)}
                    </TableCell>
                    <TableCell style={{textAlign: 'end'}} className={classes.actionMore}>
                      {/* <Checkbox
                                                        size="medium"
                                                        icon={<div className={classes.checkBox}></div>}
                                                        checkedIcon={<div className={classes.checkBoxOn}></div>}
                                                        onClick={e => e.stopPropagation()}
                                                        checked={p.isSelected}
                                                        onChange={e => handleSelectProject(p.id, e.target.checked)}
                                                    /> */}
                      <Button style={{padding: '7px 0 7px 15px'}} onClick={(event) => handleClickFilter(event, 'action', p)}><img src={IconActionMore} /></Button>
                      <Popover
                        anchorEl={showActionMore}
                        // id={id}
                        open={showActionMore}
                        // anchorEl={anchorEl}
                        onClose={(event) => {
                          event.stopPropagation();
                          handleCloseFilter('action')
                        }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        style={{width:'148px'}}
                      >
                        <div
                          className={classes.buttonActionMore}
                          onClick={(event) => {
                            //setProjectActive(p)
                            event.stopPropagation();
                            history.push({
                              pathname : `/projects/${projectActive?.id}/0`
                            })
                            // setOpenDetail(true)
                            setShowActionMore(false);

                          }}>
                          Edit
                        </div>
                        <div onClick={(event) => {
                          event.stopPropagation();
                          if(projectActive.claimSubmitted > 0 ) {
                            setOpenDel(true)
                          }else {
                            setOpen(true);
                          }

                          setShowActionMore(false)
                        }} className={classes.buttonActionMore}>Delete</div>
                      </Popover>
                    </TableCell>
                  </TableRow>
                ))
              }
            </>
          ) : (
            <TableRow className={showMap ? classes.loadingEmtyRowMap : classes.loadingEmptyRow}>
              <TableCell colSpan={7}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <p>
                    {loading ? 'Loading...' : `${showMap ? '' : 'Adjust your filters for more results.'}`}
                  </p>
                </Box>
              </TableCell>
            </TableRow>
          )}</>
      )
    }

    function renderListProjectFilter(projects, showMap) {
      return(
        <>
          {projects.length > 0 && !showMap  ? (
            <>
              {
                projects.map(p => (
                  <TableRow
                    key={p.id}
                    hover
                    onClick={() => {
                      setProjectActive(p)
                      setOpenDetail(true)
                    }
                    }
                  >
                    <TableCell>
                      <div className={classes.infoProjects}>
                        <div style={{position:'relative'}}>
                          <img src={_.split(p.projectImages, '|')[0]||DefaultProjectImg} style={{width: '156px', height: '80px'}} />
                          {p.qualifyLaborWarranty && (<img className={classes.iconLabor} src={ImageLabor} />) }
                        </div>
                        <div style={{fontSize: '15px', marginLeft: '15px'}}>
                          <p className={classes.excerptName}>{renderProjectName(p)}</p>
                          <p style={{lineHeight: '20px'}}>City: {p.projectCity}</p>
                          <p style={{lineHeight: '20px'}}>System size: {p.systemSize ? <> {p.systemSize} kW </> : null} </p>
                          <p style={{lineHeight: '20px'}}>Installation Date: {p.installationDate && <Moment format="L">{p.installationDate}</Moment>}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {!p.qualifyLaborWarranty ? (p.isDraft ? '---' : 'No') : 'Yes'}
                    </TableCell>
                    <TableCell>
                      {!p.isDraft && p.installationDate ? (
                        <>
                          Completed on <Moment format="L">{p.operateDate}</Moment>
                        </>
                      ) : 'Draft'
                      } <br />
                      {renderButtonSubmit(p)}
                    </TableCell>
                    <TableCell style={{textAlign: 'end'}} className={classes.actionMore}>
                      {/* <Checkbox
                                                        size="medium"
                                                        icon={<div className={classes.checkBox}></div>}
                                                        checkedIcon={<div className={classes.checkBoxOn}></div>}
                                                        onClick={e => e.stopPropagation()}
                                                        checked={p.isSelected}
                                                        onChange={e => handleSelectProject(p.id, e.target.checked)}
                                                    /> */}
                      <Button style={{padding: '7px 0 7px 15px'}} onClick={(event) => handleClickFilter(event, 'action', p)}><img src={IconActionMore} /></Button>
                      <Popover
                        anchorEl={showActionMore}
                        // id={id}
                        open={showActionMore}
                        // anchorEl={anchorEl}
                        onClose={(event) => {
                          event.stopPropagation();
                          handleCloseFilter('action')
                        }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        style={{width:'148px'}}
                      >
                        <div
                          className={classes.buttonActionMore}
                          onClick={(event) => {
                            //setProjectActive(p)
                            event.stopPropagation();
                            history.push({
                              pathname : `/projects/${projectActive?.id}/0`
                            })
                            // setOpenDetail(true)
                            setShowActionMore(false);

                          }}>
                          Edit
                        </div>
                        <div onClick={(event) => {
                          event.stopPropagation();
                          if(projectActive.claimSubmitted > 0 ) {
                            setOpenDel(true)
                          }else {
                            setOpen(true);
                          }

                          setShowActionMore(false)
                        }} className={classes.buttonActionMore}>Delete</div>
                      </Popover>
                    </TableCell>
                  </TableRow>
                ))
              }
            </>
          ) : (
            <TableRow className={showMap ? classes.loadingEmtyRowMap : classes.loadingEmptyRow}>
              <TableCell colSpan={7}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <p>
                    {loading ? 'Loading...' : `${showMap ? '' : 'Adjust your filters for more results.'}`}
                  </p>
                </Box>
              </TableCell>
            </TableRow>
          )}</>
      )
    }

    return (
        <>
            <div className={classes.banner}>
                <BreadCrumb2 type={'form'} items={[`${user.firstName} ${user.lastName}`, 'My Projects']}/>
                <h1 style={{ fontSize: 36, color: '#000', fontWeight: 400 }}>Projects</h1>
                <p className={classes.dashboard}>
                    <span onClick={() => handleActive(1)} className={idActive === 1 && 'active-draft'}>
                        ({total}) All
                    </span>
                    <span onClick={() => handleActive(2)} className={idActive === 2 && 'active-draft'}>
                        ({totalComplete}) Completed
                    </span>
                    <span onClick={() => handleActive(3)} className={idActive === 3 && 'active-draft'}>
                        ({totalDraft}) Drafts
                    </span>
                </p>
            </div>

            <div className={classes.container}>
                {/* <h1 className={classes.title}>Projects</h1> */}

                {!isLowerSmallSize ? (
                    <div container spacing={isLowerMediumSize ? 1 : 3} className={classes.actionContainer}>
                        <div style={{display: 'flex'}}>
                            <div>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Search"
                                    className={classes.inputField}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <img src={SearchIcon} style={{opacity: 0.5, height: 24, width: 24}}/>
                                            </InputAdornment>
                                    }}
                                    value={searchText}
                                    onChange={e => handleSearchText(e.target.value)}
                                />
                            </div>
                            <div style={{marginLeft: '8px'}}>
                                <Button className={isSaveInstallDate ? classes.downloadButtonActive : classes.downloadButton} onClick={(event) => handleClickFilter(event, 'date')}>Installation Date</Button>
                                <Popover
                                    anchorEl={showFilterDate}
                                    // id={id}
                                    open={showFilterDate}
                                    // anchorEl={anchorEl}
                                    onClose={() => handleCloseFilter('date')}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                        transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <div className={classes.containerPopover}>
                                        <p style={{marginBottom: '10px'}}>Installation Date</p>
                                        <div style={{display: 'flex',alignItems: 'center'}}>
                                            <DatePickerCustom
                                                fullWidth
                                                style={{borderRadius: '50px'}}
                                                variant="inline"
                                                name={"startDate"}
                                                format="MM/dd/yyyy"
                                                inputVariant="outlined"
                                                placeholder="mm/dd/yyyy"
                                                views={['year', 'month', 'date']}
                                                value={rangeInstallDate.startDate}
                                                className={classes.datePicker}
                                                maxDate={new Date()}
                                                onChange={handleSelectInstallationStartDate}
                                            />
                                            <span item sm={1} style={{padding: '0 15px'}}>-</span>
                                            <DatePickerCustom
                                                fullWidth
                                                style={{borderRadius: '50px'}}
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                inputVariant="outlined"
                                                name={"endDate"}
                                                placeholder="mm/dd/yyyy"
                                                views={['year', 'month', 'date']}
                                                maxDate={new Date()}
                                                minDate={new Date(rangeInstallDate.startDate)}
                                                value={rangeInstallDate.endDate}
                                                className={classes.datePicker}
                                                onChange={handleSelectInstallationEndDate}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.separator} />
                                    <div className={classNames([classes.containerPopover], [classes.flexCenterContainer])}>
                                        <p onClick={clearInstallationDate} style={{textDecoration: 'underline', cursor: 'pointer'}}>Clear</p>
                                        <Button onClick={() => handleSearchProject('date')} className={classes.buttonSavePopover}>Save</Button>
                                    </div>
                                </Popover>
                            </div>
                            <div style={{marginLeft: '8px'}}>
                                <Button className={isSaveSys ? classes.downloadButtonActive : classes.downloadButton} onClick={(event) => handleClickFilter(event, 'size')}>System Size</Button>
                                <Popover
                                    anchorEl={showFilterSize}
                                    // id={id}
                                    open={showFilterSize}
                                    // anchorEl={anchorEl}
                                    onClose={() => handleCloseFilter('size')}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                        transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <div className={classes.containerPopover}>
                                        <p style={{marginBottom: '10px'}}>System Size</p>
                                        <div style={{display: 'flex',alignItems: 'center'}}>
                                            <TextField
                                                placeholder="kW"
                                                fullWidth
                                                InputProps={{
                                                  inputComponent: FormatSys
                                                }}
                                                onChange={handleChangeMinSys}
                                                value={rangeSystemSize.startSys}
                                                variant="outlined"
                                                style={{width: '80px', textAlign: 'center', height: '45px'}}
                                            />
                                            <span item sm={1} style={{padding: '0 15px'}}>~</span>
                                            <TextField
                                                placeholder="kW"
                                                fullWidth
                                                InputProps={{
                                                  inputComponent: FormatSys,
                                                  //inputProps: { min: rangeSystemSize.startSys, max: 10 }
                                                }}
                                                onChange={handleChangeMaxSys}
                                                variant="outlined"
                                                value={rangeSystemSize.endSys}
                                                min={rangeSystemSize.startSys}
                                                style={{width: '80px', textAlign: 'center', height: '45px'}}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.separator} />
                                    <div className={classNames([classes.containerPopover], [classes.flexCenterContainer])}>
                                        <p onClick={handleClearSys} style={{textDecoration: 'underline', cursor: 'pointer'}}>Clear</p>
                                        <Button disabled={(rangeSystemSize.endSys === null || rangeSystemSize.endSys === null || parseInt(rangeSystemSize.startSys) > parseInt(rangeSystemSize.endSys) )} onClick={() => handleSearchProject('range')} className={(rangeSystemSize.endSys === null || rangeSystemSize.endSys === null || parseInt(rangeSystemSize.startSys) > parseInt(rangeSystemSize.endSys) ) ? classes.buttonSaveDisible : classes.buttonSavePopover}>Save</Button>
                                    </div>
                                </Popover>
                            </div>
                            <div style={{marginLeft: '8px'}}>
                                <Button className={isSaveBattery ? classes.downloadButtonActive : classes.downloadButton} onClick={(event) => handleClickFilter(event, 'battery')}>Battery</Button>
                                <Popover
                                    anchorEl={showFilterBattery}
                                    // id={id}
                                    open={showFilterBattery}
                                    // anchorEl={anchorEl}
                                    onClose={() => handleCloseFilter('battery')}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                        transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    style={{width:'203px'}}
                                >
                                    <div className={classes.containerPopover}>
                                        <p style={{marginBottom: '10px'}}>Battery</p>
                                        <div className={classes.flexCenterContainer} style={{paddingRight: '37px'}}>
                                            <span style={{marginRight : 15}}>No</span> <CustomizedSwitches battery={battery} handleChangeValue={handleChangeStatusBattery}/> <span>Yes</span>
                                        </div>
                                    </div>
                                    <div className={classes.separator} />
                                    <div className={classNames([classes.containerPopover], [classes.flexCenterContainer])}>
                                        <p onClick={clearInfoBattery} style={{textDecoration: 'underline', cursor: 'pointer'}}>Clear</p>
                                        <Button onClick={() => handleSearchProject('battery')} className={classes.buttonSavePopover}>Save</Button>
                                    </div>
                                </Popover>
                            </div>
                            <div className={classes.selectAll}>
                              <p onClick={handleReset} className={classes.dashboard}>
                                <span style={{fontSize : 13}}>Clear All</span>
                              </p>
                            </div>
                        </div>

                        <div className={classes.flexCenterContainer}>
                            <div className={classes.showMapButton} style={{background: showMap ? '#009EE3' : '#4F4F4F', width: 136}}>
                                <AntSwitch onChange={(event) => setShowMap(event.target.checked)}/>
                                <span style={{marginLeft: '10px', fontSize: '15px'}} >
                                    {showMap ? 'Hide Map' : 'Show Map'}
                                </span>
                            </div>
                            <Button
                                className={classes.downloadButton}
                                // onClick={history.push('/projects/project-registration')}
                            >
                                <Link style={{color: '#8C8C8C'}} to={'/projects/project-registration'}>Register a Project</Link>
                            </Button>
                        </div>
                    </div>
                ) : (
                    <Grid container className={classes.actionContainer}>
                        <Drawer
                            className={classes.drawer}
                            open={isDrawerOpen}
                            PaperProps={{ style: { position: 'absolute' } }}
                            BackdropProps={{ style: { position: 'absolute' } }}
                            ModalProps={{
                                style: { position: 'absolute' }
                            }}
                        >
                            <div className="closeBtnContainer">
                                <Button className="closeBtn" onClick={() => setDrawerOpen(false)}>
                                    <img src={CloseDrawerIcon} />
                                </Button>
                            </div>
                            <div className="filterFields">
                                <h5>Filter & Sort</h5>
                                <div className="filterField">
                                    <TextField
                                        fullWidth
                                        style={{borderRadius: '43px'}}
                                        variant="outlined"
                                        placeholder="Search"
                                        className={classes.inputField}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <img src={SearchIcon} />
                                                </InputAdornment>
                                        }}
                                        value={searchText}
                                        onChange={e => handleSearchText(e.target.value)}
                                    />
                                </div>
                                <div className="filterField" style={{ position: 'relative' }}>
                                    <DatePickerCustom
                                        fullWidth
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        inputVariant="outlined"
                                        placeholder="Installation Date"
                                        views={['year', 'month', 'date']}
                                        value={installationDate}
                                        className={classes.datePicker}
                                        // onChange={handleSelectInstallationDate}
                                    />
                                    <IconButton
                                        className={classes.clearIconBtn}
                                        style={{ top: '10px' }}
                                        edge="end"
                                        size="small"
                                        disabled={!installationDate}
                                        // onClick={() => handleSelectInstallationDate(null)}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </div>
                                <div className="filterField" style={{ position: 'relative' }}>
                                    <DatePickerCustom
                                        fullWidth
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        inputVariant="outlined"
                                        placeholder="Operate Date"
                                        views={['year', 'month', 'date']}
                                        value={operateDate}
                                        className={classes.datePicker}
                                        onChange={handleSelectOperateDate}
                                    />
                                    <IconButton
                                        className={classes.clearIconBtn}
                                        style={{ top: '10px' }}
                                        edge="end"
                                        size="small"
                                        disabled={!operateDate}
                                        onClick={() => handleSelectOperateDate(null)}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </Drawer>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-start">
                                <CustomButton
                                    color="secondary"
                                    className={classes.downloadButton}
                                    onClick={() => setDrawerOpen(true)}
                                >
                                    <span style={{ marginRight: '9px' }}><img src={FilterIcon} /></span> Filter & Sort
                                </CustomButton>
                            </Box>
                        </Grid>
                        <Grid item xs={6} className={classes.flexCenterContainer}>
                            <CustomButton
                                color="primary"
                                className={classes.downloadButton}
                                onClick={exportProjects}
                            >
                                <img style={{marginRight: 8}} src={IconDownload} /> Download
                            </CustomButton>
                            <ExcelCustomComponent
                                filename={`projects${new Date()}`}
                                id="projects-data-sheet"
                                data={projectsDataSheet}
                            />
                        </Grid>
                    </Grid>
                )}

                <TableContainer>
                    <Table className={classes.projectsTable} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>My Projects</TableCell>
                                <TableCell  width="20%">
                                    <div>
                                    Qualifies for Labor Reimbursement
                                    </div>
                                </TableCell>
                                <TableCell>{!showMap ? 'Status' : 'Map'}</TableCell>
                                <TableCell>
                                    {/*<div style={{display: 'flex', cursor: 'pointer', justifyContent: 'flex-end'}} onClick={exportProjects}>*/}
                                    {/*    <img style={{marginRight: 8}} src={IconDownload}/> Download*/}
                                    {/*</div>*/}
                                    {/*<ExcelCustomComponent*/}
                                    {/*    filename={`projects${new Date()}`}*/}
                                    {/*    id="projects-data-sheet"*/}
                                    {/*    data={projectsDataSheet}*/}
                                    {/*/>*/}
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody style={{position : 'relative'}}>
                          {showMap && <ViewProject  projects={idActive === 1 ? projects : listProjectFilter}/>}
                          {idActive === 1 ? renderListProject(projects, showMap) : renderListProjectFilter(listProjectFilter, showMap)}


                        </TableBody>
                    </Table>
                </TableContainer>

                <Grid
                    container
                    justify="center"
                    className={classes.actionBottomContainer}
                >
                    {!isLowerMediumSize && <Grid item lg={4}/>}
                    {!showMap && <Grid item lg={4} xs={6}>
                        <Box display="flex" justifyContent={isLowerMediumSize ? "flex-start" : "center"}>
                            {projects.length < total && (
                                <CustomButton
                                    color="secondary"
                                    className={classes.viewMoreButton}
                                    onClick={viewMore}
                                    disabled={loadingMore}
                                >
                                    { loadingMore ? <CustomLoading size={16} color="#009EE3"/> : 'View More' }
                                </CustomButton>
                            )}
                        </Box>
                    </Grid>}
                  {!isLowerSmallSize && <Grid item lg={4} xs={6}/>}
                </Grid>
            </div>

          <Modal open={openDetail} onClose={handleCloseModalDetail} className={classes.modalDetail}>
            <Paper>
              <Grid container justify="flex-end">
                <IconButton onClick={() => setOpenDetail(false)}>
                  <img style={{height : 25}} src={IconClose}/>
                </IconButton>
              </Grid>
              <DetailProject setOpenDel={setOpenDel} setOpen={setOpen} distributors={distributors} loading={loadingDeleteProject} handleDelete={handleDeleteProjects} data={projectActive}/>

            </Paper>
          </Modal>

          <Modal onClose={() => setOpenDel(false)} open={openDel} className={classes.modal}>
            <Paper>
              <Grid container justify="flex-end">
                <IconButton onClick={() => setOpenDel(false)}>
                  <CloseIcon fontSize="default" />
                </IconButton>
              </Grid>
              <Text
                style={{ textAlign: 'center', paddingBottom: '30px' }}
                color="dark"
                size="h2"
                className={classes.header}
              >
                Cannot Delete

              </Text>
              <Text
                style={{ textAlign: 'center', paddingBottom: '30px' }}
                color="dark"
                size="h4"
                className={classes.header}
              >
                Cannot delete a project that already has a claim.

              </Text>

              <Grid
                container
                justify="center"
                style={{ paddingBottom: '50px' }}
              >
                <Grid item>
                  <CustomButton
                    color="secondary"
                    onClick={() => setOpenDel(false)}
                  >
                    I Understand
                  </CustomButton>
                </Grid>
              </Grid>
            </Paper>
          </Modal>

            <Modal onClose={handleClose} open={open} className={classes.modal}>
                <Paper>
                    <Grid container justify="flex-end">
                        <IconButton onClick={handleClose}>
                            <CloseIcon fontSize="default" />
                        </IconButton>
                    </Grid>
                    <Text
                        style={{ textAlign: 'center', paddingBottom: '30px' }}
                        color="dark"
                        size="h2"
                        className={classes.header}
                    >
                      Are you sure you want to delete this project?
                    </Text>

                    <Grid
                        container
                        justify="center"
                        style={{ paddingBottom: '50px' }}
                    >
                        <Grid item style={{ marginRight: '10px' }}>
                            <CustomButton
                                color="secondary"
                                onClick={handleClose}
                            >
                                No
                            </CustomButton>
                        </Grid>
                        <Grid item>
                            <CustomButton
                                color="primary"
                                onClick={handleDeleteProjects}
                                disabled={loadingDeleteProject}
                            >
                                { loadingDeleteProject ? <CustomLoading size={16} color="#fff" /> : 'Yes' }
                            </CustomButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </>
    )
}
