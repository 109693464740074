import axios from '../utils/axios';
import config from '../config';

function getNewLatest() {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/notice/search`,
    params : {
      limit : 1,
      size : 1
    }
  }

  return axios(request);
}


export {getNewLatest};
