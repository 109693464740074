import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonBase from '@material-ui/core/ButtonBase';

const StyledButton = styled(ButtonBase)(
  ({ theme: { colors }, color }) => `
  min-width: 158px;
  font-size: 1rem;
  line-height: 1rem;
  padding: 1rem 2rem;
  border-radius: 100px;
  transition: background-color 175ms ease-out;
  background-color: ${color === 'primary' ? colors.primaryLight : colors.light};
  color: ${color === 'primary' ? colors.light : colors.secondaryDark};
  border: 1px solid
    ${color === 'primary' ? 'transparent' : colors.secondaryDark};

  &:hover {
    background-color: ${
      color === 'primary'
        ? colors.primaryDark
        : color === 'disabled'
        ? '#ffffff'
        : colors.secondaryLight
    };
  }
`
);

/**
 * @name Button
 * @description Rounded Button component
 * @param {string} color The color theme of the button. Can either be "primary" or "secondary"
 * @param {node} children The content inside the button. ie "Add to Cart"
 * @extends {ButtonBase}
 */
const Button = ({ style, color = 'primary', children, ...props }) => {
  return (
    <StyledButton style={style} color={color} {...props}>
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'disabled']),
  children: PropTypes.node.isRequired,
  ...ButtonBase.propTypes
};

export default Button;
