import React, { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import { Box, Button, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from '@material-ui/core';
import useStyles from './useStyles';
import CustomButton from '../../../common/components/button';
import CustomDatePicker from '../../projects/components/CustomDatePicker';
import { claimService, projectService } from '../../../services';
import CustomLoading from '../../../common/components/CustomLoading';
import ReactSelect from 'react-select';
import IconSelect from '../../../icons/arrow.svg';
import CreatableSelect from 'react-select/creatable';
import {useHistory} from 'react-router-dom';
import PopoverIcon from '../../../config/images/editPr.svg';
import MessageSpecial from './MessageSpecial';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import ViewProjectDetail from './ViewProjectDetail';
import CheckValidate from '../../../common/components/CheckValidate';
import IconEdit from '../../../icons/edit_icon.svg';
import ShowMessage from './ShowMessage';
import TitleProject from './TitleProject';
import FieldDetail from './FieldDetail';
import ProjectManualEss from './ProjectManualEss';
import OpenImageIcon from '../../../config/images/open_image_icon.svg';
import ImageUploader from './UploadImage';
import ImageModal from '../../projects/components/ImageModal';
import MessageShowProject from './MessageShowProject';
import UploadFileExcel from './UploadFIleExcel';
import config from '../../../config';
import TextButton from '../../../common/components/button/TextButton';
import CardSerialNumber from './CardSerialNumber';
import MultiUploader from "./UploadMultiType";
const ReactSelectDropdownIndicator = () => {
  return (
    <div style={{ marginRight: '15px' }}>
      <img src={IconSelect} width="14"/>
    </div>
  )
}

function SystemInfo({ next, goBack ,register, control, errors, distributors ,keyUpload, setKeyUpload , setListSerial, listSerial, setSerials, serials,
                      filePermissionOfDate, setFilePermissionOfDate ,watch, setValue, clearErrors, loading, handleSaveDraft,
                      projects, saleRegions, urlImageProof, setFilesProof, condition}) {


  const [projectActive, setProjectActive] = useState({});
  const [modalUrl, setModalUrl] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const projectInfo = watch('projectInfo');
  const choseTypeProject = watch('choseTypeProject');
  const systemType = watch('projectSystemType');
  const showMess = watch('showMess');
  const choseTypeSerialUpload = watch('choseTypeSerialUpload');
  const [listSerialPick , setListSerialPick] = useState([]);
  const listSerialPickData = watch('listSerialPick');
  const validateProofOfDate = watch('validateProofOfDate');
  const isInValidUpload = watch('isInValidUpload');
  const isDuplicateUpload = watch('isDuplicateUpload');
  const isDuplicateOtherProject = watch('isDuplicateOtherProject');




  const history = useHistory();




  useEffect(() => {

  }, [choseTypeProject])


  const renderAddress = (array = []) => {
    const newArray = _.remove(array, value => !!value || value === 'select');
    return newArray.length > 0 ? _.join(newArray, ', ') : '';
  };


  async function getDetailProjectActive(id) {
    const info = await projectService.getDetailProject(id);

    let projectInfo = info?.data
    setProjectActive(projectInfo)

    setValue('projectSystemType', projectInfo?.systemType ? projectInfo?.systemType : 'select' );
    setValue('projectNameSys', projectInfo?.projectName);
    setValue('projectAddress', renderAddress([projectInfo?.projectAddress, projectInfo?.projectAddress2, projectInfo?.projectCity, projectInfo?.projectState, projectInfo?.projectZipCode]));
    setValue('systemType', projectInfo?.systemType);
    setValue('projectInstallationDate', projectInfo?.installationDate);
    //setValue('projectNumberOfPanel', 2);
    setValue('projectPermissionDate', projectInfo?.operateDate);
    setValue('totalPvPowerInstalled', projectInfo?.systemSize);
    setValue('laborWarranty', _.get(projectInfo, 'qualifyLaborWarranty', false))
    //setValue('projectInverterPower', projectInfo?.systemSize);
    //setValue('laborReimbursement', _.get(projectInfo, 'laborReimbursement', false))

    setValue('panelLaborReimbursement', _.get(projectInfo, 'panelLaborReimbursement', false))
    setValue('batteryLaborReimbursement', _.get(projectInfo, 'batteryLaborReimbursement', false))
    setValue('batteryQualified', _.get(projectInfo, 'batteryQualified', false))
    if(_.get(projectInfo, 'batteryLaborReimbursement', false)) {
      setValue('laborReimbursement', true)
    }

    if(_.get( projectInfo, 'batteryLaborReimbursement', false) && _.get(projectInfo, 'qualifyLaborWarranty', false)) {
      setValue('validateProofOfDate', true)
    }

    const uniqModuleSerialNumber = _.filter(_.get(projectInfo, 'serialNumbers'), s => s.type === 'inverter');

    //const moduleSN = _.filter(_.get(projectInfo, 'serialNumbers'), s => s.type === 'modules');
    setListSerial(uniqModuleSerialNumber)

    setValue('choseTypeSerialUpload', uniqModuleSerialNumber.length === 0 ? '2' : '1')

    if(uniqModuleSerialNumber.length > 0) {
      setValue('projectProductName', uniqModuleSerialNumber?.length > 0 ? uniqModuleSerialNumber[0].productName : '');
      setValue('projectInverterSerialNumber', uniqModuleSerialNumber.length > 0 ? uniqModuleSerialNumber[0].serialNumber : '');
      setValue('projectInverterPower',uniqModuleSerialNumber?.length > 0 ? (uniqModuleSerialNumber[0].volume / 1000) : '' );
    }else {
      setValue('projectInverterPower', projectInfo?.batteryInverterPower)
      setValue('projectInverterSerialNumber', '')
      setValue('projectProductName', projectInfo?.batteryModel)

    }





    // if(projectInfo?.batteryInverterPower !== 'select' && projectInfo?.batteryInverterPower !== '' && projectInfo?.batteryInverterPower !== null) {
    //   setValue('projectInverterPower', projectInfo?.batteryInverterPower)
    // }
    //setValue('projectProductName', projectInfo?.moduleModel || projectInfo?.serialNumbers?.length > 0 && projectInfo?.serialNumbers[0].productName);

    // setValue('projectPowerClass', projectInfo?.serialNumbers?.length > 0 && projectInfo?.serialNumbers[0].volume);
    //setValue('projectInverterPower', projectId?.systemSize);
    //setValue('projectInfo', projectInfo);
    setValue('purchasingChannel', projectInfo?.purchasingChannel ? projectInfo?.purchasingChannel : 'select');
    if (projectInfo?.purchasingChannel === 'distributor') {
      const distributorInfo = distributors.find(d => d.name === projectInfo?.nameOfPurchasingChannel)
      setValue('nameOfPurchasingChannelDistributor', distributorInfo);
      if(_.get(distributorInfo, 'name') === 'Other') {
        setValue('nameOfChanelOtherDistributor', projectInfo?.nameOfChanelOtherDistributor)
      }

    } else if (projectInfo?.purchasingChannel === 'other') {
      setValue('nameOfPurchasingChannelOther', projectInfo?.nameOfPurchasingChannel);
    }

    //setValue('projectPowerClass', projectId?)
    if (_.trim(_.get(projectInfo, 'proofOfOperateDate'))) {
      setFilesProof(_.map(_.split(_.get(projectInfo, 'proofOfOperateDate'), '|'), p => ({ url: p })));
      setValue('validateProofOfDate', false)
    }else {
      setFilesProof([])
      //setValue('validateProofOfDate', true)
    }

  }

  useEffect(() => {
    if(projectInfo !== null && projectInfo !== undefined) {
      //setProjectActive(projectInfo)
      getDetailProjectActive(projectInfo?.id)

    }
  }, [projectInfo?.id])

  useEffect(() => {
    if(choseTypeProject === '1' && serials?.length > 0) {
      if(_.map(listSerial, 'serialNumber').length === 0) {
        setValue('listSerialPick', serials)
      }else {
        const listSerialValid = serials?.filter(item => _.map(listSerial, 'serialNumber').includes(item.serialNumber))
        setListSerialPick(listSerialValid)
        setValue('listSerialPick', listSerialValid)
      }

    }


    serials?.length > 0 && choseTypeProject === '2' && setValue('listSerialPick', serials);
    //listSerial.length === 0 ? setValue('choseTypeSerialUpload', '2') : setValue('choseTypeSerialUpload', '1')


  },[serials, listSerial])

  function renderValidateFileSerial() {
    switch (choseTypeSerialUpload) {
      case '1':
        return(
          <CheckValidate value={watch('serialNumbers')} error={errors.serialNumbers}/>
        )
      case '2':
        return (
          <CheckValidate value={watch('isUploadSerialNumber')} error={errors.isUploadSerialNumber}/>
        )
    }
  }

  useEffect(() => {
    listSerialPickData && listSerialPickData?.length > 0 ? setValue('isUploadSerialNumber' , 'done') : setValue('isUploadSerialNumber', '')  }, [listSerialPickData?.length])

  function handleRemoveSerialNumber(serial) {
    const listSerialDone = listSerialPickData.filter(item => {
      return item.serialNumber !== serial
    })

    setListSerialPick(listSerialDone);
    setValue('listSerialPick', listSerialDone)
    // setValue('serialNumbers', listSerialDone);
  }

  function renderMessage() {
    if(isInValidUpload && (isDuplicateUpload || isDuplicateOtherProject)) {
      return <ShowMessage textInfo={'The serial number you registered for this project were verified as invalid and duplicate. To continue with this claim, please double-check the serial number of the affected modules and upload them here.'}/>
    }
    if(isInValidUpload) {
      return <ShowMessage textInfo={'The serial number you registered for this project were verified as invalid. To continue with this claim, please double-check the serial number of the affected modules and upload them here'}/>
    }

    if(isDuplicateUpload || isDuplicateOtherProject) {
      return <ShowMessage textInfo={'The serial number you registered for this project were verified as duplicate. To continue with this claim, please double-check the serial number of the affected modules and upload them here'}/>
    }
  }




  const classes = useStyles();
  return(
    <div>
      <div className={classes.stepInfo}>
        <span className={classes.sectionTitleStep}>
          System info
        </span>
      </div>
      <TitleProject title={'System info'}/>
      <div className={classes.sectionContainerSys}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Project
          </span>
        </div>
        <div>
          <Controller
            name="choseTypeProject"
            control={control}
            as={
              <RadioGroup aria-label="position">
                <FormControlLabel
                  value="1"
                  control={<Radio onChange={() => {
                    setValue('projectSystemType', 'select');
                    setValue('projectNameSys', '');
                    setValue('projectAddress', '');
                    setValue('nameProject', '');
                    setValue('systemType', 'select');
                    setValue('projectInstallationDate', null);
                    setValue('projectPermissionDate', null);
                    setValue('totalPvPowerInstalled', '');
                    setValue('projectProductName', 'select');
                    setValue('projectInverterSerialNumber', '');
                    setValue('projectInverterPower', 'select');
                    setValue('projectPowerClass', '');
                    setFilePermissionOfDate([])
                  }
                  } icon={<div className={classes.radio}/>} checkedIcon={<div className={classes.radioOn}/>} />}
                  label="Search for Project"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio onChange={() => {
                    setValue('projectInfo', null)
                    setValue('projectSystemType', 'select');
                    setValue('projectNameSys', '');
                    setValue('projectAddress', '');
                    setValue('nameProject', '');
                    setValue('systemType', 'select');
                    setValue('projectInstallationDate', null);
                    setValue('projectPermissionDate', null);
                    setValue('totalPvPowerInstalled', '');
                    setValue('projectProductName', 'select');
                    setValue('projectInverterSerialNumber', '');
                    setValue('projectPowerClass', '');
                    setValue('projectStateManual', null)
                    setValue('projectInverterPower', 'select')
                    setValue('validateProofOfDate', true)
                    setFilePermissionOfDate([])
                    setFilesProof([]);
                  }
                  } icon={<div className={classes.radio}/>} checkedIcon={<div className={classes.radioOn}/>} />}
                  label="Manual Input"
                  labelPlacement="end"
                />
              </RadioGroup>
            }
          />
        </div>
        <div>
          {choseTypeProject === '1' && <CheckValidate value={watch('projectInfo')} error={errors.projectInfo}/>}
        </div>
      </div>
      {choseTypeProject === '1' && <div className={classes.sectionContainerSys}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          <Controller
            name="projectInfo"
            key="name-project-claim"
            control={control}
            as={
              <ReactSelect
                getOptionLabel={option => _.trim(option.projectAddress)}
                getOptionValue={option => _.trim(option.id)}
                isSearchable
                isClearable
                isMulti={false}
                options={projects}
                className={classNames([classes.multiSelect], {
                  [classes.errorReactSelect]: errors.nameOfPurchasingChannelDistributor
                })}
                classNamePrefix="select"
                placeholder="Search by Address"
                defaultMenuIsOpen={false}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: ReactSelectDropdownIndicator
                }}
                styles={{
                  placeholder: provided => ({
                    ...provided,
                    color: '#C4C4C4'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    fontFamily: 'Actual',
                    fontSize: '14px',
                    color: '#333333',
                    fontWeight: 400,
                    fontStyle: 'normal'
                  })
                }}
              />
            }
          />
        </div>
      </div>}
      {showMess && <div className={classes.sectionContainerSys}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          <MessageShowProject type={2} id={_.get(condition, 'id')}/>
        </div>
      </div>}
      <div className={classes.sectionContainerSys}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          <MessageSpecial/>
        </div>
      </div>

      {choseTypeProject === '1' && projectInfo !== null && <div className={classes.sectionContainerSys}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          <ViewProjectDetail handleSaveDraft={handleSaveDraft} project={projectActive}/>
        </div>

      </div>}
      {choseTypeProject === '2' && <div className={classes.sectionContainerProject}>
          <div/>
          <ProjectManualEss saleRegions={saleRegions} setValue={setValue} control={control} watch={watch} errors={errors} register={register}/>
      </div>}

      <div className={classes.sectionContainerSys}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Q.HOME Inverter Serial Number <span>*</span>
          </span>
        </div>
        <div>
          <Controller
            name="choseTypeSerialUpload"
            control={control}
            as={
              <RadioGroup aria-label="position">
                {listSerial.length > 0 && <FormControlLabel
                  value="1"
                  control={<Radio onChange={() => {
                    setListSerialPick([]);
                    setValue('serialNumbers', [])
                  }
                  } icon={<div className={classes.radio}/>} checkedIcon={<div className={classes.radioOn}/>} />}
                  label="Search and select"
                  labelPlacement="end"
                />}
                <FormControlLabel
                  value="2"
                  control={<Radio onChange={() => {
                    setValue('serialNumbers', []);
                  }
                  } icon={<div className={classes.radio}/>} checkedIcon={<div className={classes.radioOn}/>} />}
                  label="Upload Excel File"
                  labelPlacement="end"
                />
              </RadioGroup>
            }
          />
        </div>
        <div>
          {renderValidateFileSerial()}
        </div>
      </div>
      {choseTypeSerialUpload === '1' && <div className={classes.sectionContainerSys}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          <Controller
            name="serialNumbers"
            key="name-project-claim"
            control={control}
            as={
              <ReactSelect
                getOptionLabel={option => _.trim(option.serialNumber)}
                getOptionValue={option => _.trim(option.serialNumber)}
                isSearchable
                isClearable
                isMulti
                options={listSerial}
                className={classNames([classes.multiSelect], {
                  [classes.errorReactSelect]: errors.nameOfPurchasingChannelDistributor
                })}
                classNamePrefix="select"
                placeholder="Search and select by..."
                defaultMenuIsOpen={false}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: ReactSelectDropdownIndicator
                }}
                styles={{
                  placeholder: provided => ({
                    ...provided,
                    color: '#C4C4C4'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    fontFamily: 'Actual',
                    fontSize: '14px',
                    color: '#333333',
                    fontWeight: 400,
                    fontStyle: 'normal'
                  })
                }}
              />
            }
          />
        </div>
      </div>}
      {choseTypeSerialUpload === '2' &&
      <div className={classes.sectionContainer} style={{marginBottom : 10}}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          <UploadFileExcel
            setSerials={setSerials}
            setValue={setValue}
          />
          <div>
            <a
              style={{ textDecoration: 'none' }}
              href={config.urlTemplateSerialNumber}
            >
              <TextButton className={classes.downloadTemplate}>
                Download Template
              </TextButton>
            </a>
          </div>
        </div>

      </div>}

      <div className={classes.sectionContainer} style={{marginBottom : 0}}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          {renderMessage()}
        </div>
      </div>

      {choseTypeSerialUpload === '2' && <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}/>
        <div className={classes.wrapItemSerial}>
          {listSerialPickData?.map((item, index) => {
            return <CardSerialNumber key={index} handleRemoveSerialNumber={handleRemoveSerialNumber} serial={item.serialNumber}/>
          })}

        </div>

      </div>}

      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          {/*{choseTypeSerialUpload === '1' && <ShowMessage textInfo={'Select affected module serial numbers from the list'}/>}*/}
          {/*{choseTypeSerialUpload === '2' && <ShowMessage textInfo={'Upload an Excel file with affected module serial numbers.'}/>}*/}
        </div>
      </div>

      {((choseTypeProject === '1' && projectInfo !== null) || (choseTypeProject === '2')) && <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
          Proof of <br /> Permission-to- <br />Operate Date
          </span>
        </div>
        <div>
          {choseTypeProject === '1' && projectInfo !== null && urlImageProof?.length > 0 && <div className={classes.reviewSectionContentImage}>
            <div className={classes.images}>
              { urlImageProof?.map(p => (
                <div key={p.url}>
                  <img src={p.url} className={classes.image} />
                  <div className={classes.overlay} role="overlay">
                    <img
                      src={OpenImageIcon}
                      onClick={() => {
                        setModalUrl(p.url);
                        setOpenModal(true);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>}
          {((choseTypeProject === '1' && urlImageProof?.length === 0 && projectInfo !== null) || (choseTypeProject === '2')) && <MultiUploader
            files={filePermissionOfDate}
            setFiles={setFilePermissionOfDate}
            fileName={'proof_of_permission_to_operate_date.jpg'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            clearErrors={clearErrors}
            errors={errors.isUploadProofDate}
            name={'proof_of_permission_to_operate_date'}
          /> }
          {urlImageProof.length === 0 && <ShowMessage textInfo={'Attach the copy of the document from the county after the system inspection is completed.'}/>}
        </div>
        <div>
          {validateProofOfDate && <CheckValidate value={watch('isUploadProofDate')} error={errors.isUploadProofDate}/> }
        </div>
      </div>}



      <TitleProject title={'Additional Project Details'}/>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Pocket WiFi SW #
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="pocketWifiSys"
            className={classNames([classes.inputField], {
              [classes.errorInputField]: errors.pocketWifiSys
            })}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Battery Serial Number
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="batterySerialNumber"
            className={classNames([classes.inputField], {
              [classes.errorInputField]: errors.batterySerialNumber
            })}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Battery Type
          </span>
        </div>
        <div>
          <Controller
            name="batteryType"
            control={control}
            as={
              <select
                placeholder="System Type"
                className={classNames([classes.selectField], {
                  [classes.errorSelectField]: errors.batteryType
                })}
              >
                <option value={'select'}>System Type</option>
                <option value={'T63'}>T63</option>
                <option value={'T45'}>T45</option>
              </select>
            }
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Number of Batteries
            <span>*</span>
          </span>
        </div>
        <div>
          <Controller
            name="numberOfBattery"
            control={control}
            as={
              <select
                placeholder="System Type"
                className={classNames([classes.selectField], {
                  [classes.errorSelectField]: errors.numberOfBattery
                })}
              >
                <option value={'select'}>Please Select</option>
                <option value={'1'}>1</option>
                <option value={'2'}>2</option>
                <option value={'3'}>3</option>
              </select>
            }
          />
        </div>
        <div>
          <CheckValidate value={watch('numberOfBattery')} error={errors.numberOfBattery}/>
        </div>
      </div>
      <TitleProject title={'Software Version'}/>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              DSP1/Master SW Version
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="dsp1SwVersion"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              ARM SW Version
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="armSwVersion"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
             Battery Master SW Version
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="batterySwVersion"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <TitleProject title={'Field Details'}/>
      {systemType === 'DC' && <>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              PV1 String Voltage
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="pv1"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              PV2 String Voltage
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="pv2"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              PV3 String Voltage
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="pv3"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              PV4 String Voltage
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="pv4"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      </>}
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
             Load L1 to N Voltage
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="loadL1Voltage"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Load L2 to N Voltage
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="loadL2Voltage"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Critical Loads connected
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="criticalLoadsConnected"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Total PV Power installed
            <span>*</span>
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="totalPvPowerInstalled"
            className={classNames([classes.inputField], {
              [classes.errorInputField]: errors.totalPvPowerInstalled
            })}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Operational mode use
          </span>
        </div>
        <div>
          <Controller
            name="operationalModeUse"
            control={control}
            as={
              <select
                placeholder="Purchasing Channel"
                className={classNames([classes.selectField], {
                  //[classes.errorSelectField]: errors.purchasingChannel
                })}
              >
                <option value={'select'}>Please select</option>
                <option value={1}>Self use</option>
                <option value={2}>Time of use</option>
                <option value={3}>Backup mode</option>
                <option value={4}>Other</option>
              </select>
            }
          />
        </div>
      </div>

      <ImageModal
        open={openModal}
        url={modalUrl}
        name={modalUrl ? modalUrl.substring(modalUrl.lastIndexOf('/') + 1) : ''}
        handleClose={() => {
          setOpenModal(false);
          setModalUrl('');
        }}
      />
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }}/>
      <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
        <CustomButton color="secondary" className="saveDraftBtn" onClick={goBack}>Back</CustomButton>
        <div>
          <CustomButton onClick={handleSaveDraft} color="secondary" className="saveDraftBtn" disabled={loading}>
            { loading ? <CustomLoading size={16} color="#009EE3" /> : 'Save Draft' }
          </CustomButton>
          <CustomButton onClick={next}>Next</CustomButton>
        </div>
      </Box>
    </div>
  )
}

export default SystemInfo;
