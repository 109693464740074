import { useMediaQuery, useTheme } from '@material-ui/core';

export default function useBreakpoints() {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  // using mobile first. order matters here. start largest to smallest.
  const breakpoints = [
    {
      name: 'xl',
      value: xl
    },
    {
      name: 'lg',
      value: lg
    },
    {
      name: 'md',
      value: md
    },
    {
      name: 'sm',
      value: sm
    }
  ];

  for (let breakpoint of breakpoints) {
    if (breakpoint.value) {
      return breakpoint.name;
    }
  }

  return 'xs';
}
