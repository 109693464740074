import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useAuth } from '../../../contexts/auth';
import UserInfo from '../components/UserInfo';
import IssuePV from '../components/IssuePV';
import PurchaseInfoPv from '../components/PurchaseInfoPv';
import styled from 'styled-components';
import IntroductionClaim from '../components/IntroductionClaim';
import ProgressBar from '../components/ProgressBar';
import { convertArrayClaim, notiFailed, notiSuccess } from '../../../utils/helpers';
import ReviewPv from '../components/ReviewPv';
import { claimService } from '../../../services';
import { useHistory, useParams } from 'react-router-dom';
import { keyPv } from '../components/keyPV';
import { defaultStatePv } from '../components/defaultStatePv';
import { schemaPV } from '../components/schemaPv';
import Notification from '../components/Notification';
import config from '../../../config';
import moment from 'moment';
import CustomLoading from '../../../common/components/CustomLoading';
import useStyles from '../components/useStyles';


const ContainerClaimFormPv = styled.div `
    width: 80%;
    margin: 36px auto 70px auto;
    display: grid;
    //grid-template-columns: 260px auto;
    //grid-column-gap: 71px;
    
    @media (max-width: 1366px) {
      grid-template-columns: auto;
      grid-column-gap: 0px;
      width: 90%;
      /* margin: 50px auto 30px; */
    }
`



function EditClaimPV() {

  const user = useAuth();
  const history = useHistory();
  const classes = useStyles();
  const {id} = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fileProofOfPermission, setFileProofOfPermission] = useState([]);
  const [filePictureOfAffectedModule, setFilePictureOfAffectedModule] = useState([]);
  const [filePictureOfVoc, setFilePictureOfVoc] = useState([]);
  const [filePictureOfFront, setFilePictureOfFront] = useState([]);
  const [filePictureOfBack, setFilePictureOfBack] = useState([]);
  const [filePictureCloseUp, setFilePictureCloseUp] = useState([]);
  const [filePictureSitePlan, setFilePictureSitePlan] = useState([]);
  const [filePictureOfOverall, setFilePictureOfOverall] = useState([]);
  const [filePictureOfWireManagement, setFilePictureOfWireManagement] = useState([]);
  const [filePictureProofDelivery, setFilePictureProofDelivery] = useState([]);
  const [filePictureImmediately, setFilePictureImmediately] = useState([]);
  const [filePictureOfVmpp, setFilePictureOfVmpp] = useState([]);
  const [filePictureIr, setFilePictureIr] = useState([]);
  const [filePictureInVoice, setFilePictureInVoice] = useState([])
  const [keyUpload, setKeyUpload] = useState('');
  const [isProjectId, setIsProjectId ] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(true);
  const [isDraft, setIsDraft] = useState(true);
  const [isEnablePreviewButton, setEnablePreviewButton] = useState(false);

  const { register, handleSubmit, errors, control, watch, setValue, getValues, formState, clearErrors } = useForm({
    resolver : yupResolver(schemaPV),
    shouldUnregister: false,
    shouldFocusError: false,
    enableReinitialize: true,
    defaultValues: {
      installerEmail : _.get(user, 'email'),
      installerName : _.get(user , 'fullName'),
      installerContact : _.get(user , 'fullName'),
      installerCity : '',
      installerAddress1 : '',
      installerAddress2 : '',
      installerState : 'select',
      installerZipcode : '',
      checkedEmail : false,


      infoClaimName : 'PV',
      info_claim_type : 'select',
      ...defaultStatePv,
      installation_date_pv : null,
      comission_date_pv : null,
      affected_module_serial_number : 'select',
      region_pv : 'select',
      site_module_layout_pv : '',
      inverter_type_pv : 'select',
      purchase_date_info : null,
      chosePermissionDate : '1',
      choseAffectedSerialNumber : '1',
      serials : '',
      choseWebAddress : '1'
    }
  });

  const projectId = watch('typeProjectName');

  useEffect(() => {
    if (projectId !== '' && projectId !== undefined) {
      getDetailProject(projectId?.id);
    }
  }, [projectId?.id]);

  useEffect(() => {
    getDetailClaimPv()
  }, [id])

  useEffect(() => {
    scrollToTop();
  }, [])

  useEffect(() => {
    if (currentStep === 3) {
      setEnablePreviewButton(true);

    }
  }, [currentStep]);

  useEffect(() => {
    scrollToTop();
  },[currentStep])

  const scrollToTop = () => {
    window.scrollTo({
      top: 410,
      behavior: "smooth"
    });
  };

  function setGroup(url) {

    let typeGroup = '';

    if(/^.*permission.jpg.*$/.test(url)) {
      typeGroup = 1
    }

    if(/^.*affectedmodule.jpg.*$/.test(url)) {
      typeGroup = 2
    }

    if(/^.*voc.jpg.*$/.test(url)) {
      typeGroup = 3
    }

    if(/^.*pictureback.jpg.*$/.test(url)) {
      typeGroup = 4
    }

    if(/^.*pictureclose.jpg.*$/.test(url)) {
      typeGroup = 5
    }

    if(/^.*picturesiteplan.jpg.*$/.test(url)) {
      typeGroup = 6
    }

    if(/^.*pictureoverall.jpg.*$/.test(url)) {
      typeGroup = 7
    }

    if(/^.*picturewire.jpg.*$/.test(url)) {
      typeGroup = 8
    }

    if(/^.*picturedelivery.jpg.*$/.test(url)) {
      typeGroup = 9
    }

    if(/^.*pictureimmedi.jpg.*$/.test(url)) {
      typeGroup = 10
    }


    if(/^.*picturevmpp.jpg.*$/.test(url)) {
      typeGroup = 11
    }

    if(/^.*pictureir.jpg.*$/.test(url)) {
      typeGroup = 12
    }

    if(/^.*pictureinvoice.jpg.*$/.test(url)) {
      typeGroup = 13
    }

    if(/^.*picturefront.jpg.*$/.test(url)) {
      typeGroup = 14
    }

    const actions = {
      1 : 'permission',
      2 : 'affected',
      3 : 'voc',
      4 : 'back',
      5 : 'close',
      6 : 'plan',
      7 : 'rall',
      8 : 'wire',
      9 : 'very',
      10 : 'medi',
      11 : 'vmpp',
      12 : 'ir',
      13 : 'voice',
      14 : 'front',
      'default' : 'errorLog'
    }
    return actions[typeGroup] || actions['default'];
  }

  function suggestionsImage(strImage) {
    const arrayImage = strImage.split(',').map(item => {
      return {
        url : item,
        group : setGroup(item)
      }
    });

    return _.groupBy(arrayImage, 'group');

  }


  async function getDetailClaimPv() {
    try {
      const info = await claimService.getDetailClaim(id)
      setValue('installerName', _.get(info.data, 'draftData.installerName', ''))
      setValue('installerAddress1', _.get(info.data, 'draftData.installerAddress1', ''))
      setValue('installerAddress2', _.get(info.data, 'draftData.installerAddress2', ''))
      setValue('installerCity', _.get(info.data, 'draftData.installerCity', ''))
      setValue('installerState', _.get(info.data, 'draftData.installerState', ''))
      setValue('installerContact', _.get(info.data, 'draftData.installerContact', ''))
      setValue('installerZipcode', _.get(info.data, 'draftData.installerZipcode', ''))
      setValue('installerEmail', _.get(info.data, 'draftData.installerEmail', ''))
      setValue('installerPhone', _.get(info.data, 'draftData.installerPhone', ''))
      setValue('submitterEmail', _.get(info.data, 'draftData.submitterEmail', ''))
      setValue('checkedEmail', _.get(info.data, 'draftData.checkedEmail', false))

      setValue('claimType', _.get(info.data, 'draftData.claimType', '1'))
      setValue('defectDate', _.get(info.data, 'draftData.defectDate', null))
      setValue('description', info.data.description)
      setValue('typeProjectName', _.get(info.data, 'draftData.typeProjectName'))
      setValue('title', info.data.title)
      setValue('customerAddress', info.data.customerAddress)
      setValue('installationDate', _.get(info.data, 'draftData.installationDate', null))
      setValue('systemSize', _.get(info.data, 'draftData.systemSize', ''))
      setValue('productName', _.get(info.data, 'draftData.productName', ''))
      setValue('powerClass', _.get(info.data, 'draftData.powerClass', ''))
      setValue('permissionDate', _.get(info.data, 'draftData.permissionDate', null))
      setValue('chosePermissionDate', _.get(info.data, 'draftData.chosePermissionDate', '1'))
      setValue('choseAffectedSerialNumber', _.get(info.data, 'draftData.choseAffectedSerialNumber', '1'))
      setValue('serials', _.get(info.data, 'draftData.serials', ''))

      setValue('region', _.get(info.data, 'draftData.region', ''))
      setValue('systemType', _.get(info.data, 'draftData.systemType', ''))
      setValue('choseWebAddress', _.get(info.data, 'draftData.choseWebAddress', ''))
      setValue('webAddress', _.get(info.data, 'draftData.webAddress', ''))

      setValue('pictureOfModuleLabel', _.get(info.data, 'draftData.pictureOfModuleLabel', ''))
      setValue('pictureOfTheBackSize', _.get(info.data, 'draftData.pictureOfTheBackSize', ''))
      setValue('closeUpPictureOfPhysicalDefect', _.get(info.data, 'draftData.closeUpPictureOfPhysicalDefect', ''))
      setValue('siteModuleLayoutPlan', _.get(info.data, 'draftData.siteModuleLayoutPlan', ''))
      setValue('pictureOfOverallRoofArea', _.get(info.data, 'draftData.pictureOfOverallRoofArea', ''))
      setValue('pictureOfWireManagement', _.get(info.data, 'draftData.pictureOfWireManagement', ''))
      setValue('proofOfDeliveryDocument', _.get(info.data, 'draftData.proofOfDeliveryDocument', ''))
      setValue('pictureOfModuleImmediately', _.get(info.data, 'draftData.pictureOfModuleImmediately', ''))
      setValue('pictureOfVmpp', _.get(info.data, 'draftData.pictureOfVmpp', ''))
      setValue('irPicture', _.get(info.data, 'draftData.irPicture', ''))

      setValue('nameOfPurchasingChannel', _.get(info.data, 'draftData.nameOfPurchasingChannel', ''))
      setValue('purchasingChannelContactName', _.get(info.data, 'draftData.purchasingChannelContactName', ''))
      setValue('emailPurchase', _.get(info.data, 'draftData.emailPurchase', ''))
      setValue('contactNumber', _.get(info.data, 'draftData.contactNumber', ''))
      setValue('qCellSales', _.get(info.data, 'draftData.qCellSales', ''))
      setValue('poNumber', _.get(info.data, 'draftData.poNumber', ''))
      setValue('purchaseDate', _.get(info.data, 'draftData.purchaseDate', null))
      setValue('typeOfPurchasingChannel', _.get(info.data, 'draftData.typeOfPurchasingChannel', ''))

      setKeyUpload(_.get(info.data, 'draftData.keyUpload', ''))
      !info.data.isDraft && setCurrentStep( 3);
      setIsDraft(info.data.isDraft)

      const infoImage = suggestionsImage(_.get(info.data, 'draftData.attachments'))
      setFileProofOfPermission(infoImage?.permission || [])
      setFilePictureOfAffectedModule(infoImage?.affected || [])
      setFilePictureOfVoc(infoImage?.voc || [])
      setFilePictureOfFront(infoImage?.front || [])
      setFilePictureOfBack(infoImage?.back || [])
      setFilePictureCloseUp(infoImage?.close || [])
      setFilePictureSitePlan(infoImage?.plan || [])
      setFilePictureOfOverall(infoImage?.rall || [])
      setFilePictureOfWireManagement(infoImage?.wire || [])
      setFilePictureProofDelivery(infoImage?.very || [])
      setFilePictureImmediately(infoImage?.medi || [])
      setFilePictureOfVmpp(infoImage?.vmpp || [])
      setFilePictureIr(infoImage?.ir || [])
      setFilePictureInVoice(infoImage?.voice || [])
      setIsProjectId(true)

    }catch (e) {
      console.log(e)
    }finally {
      setLoadingDetail(false)
    }
  }


  async function getDetailProject(id) {
    try {
      const info = await claimService.getProjectById(id);
      setValue('title', info.data.projectName);
      setValue('customerAddress', info.data.projectAddress);
      setValue('installationDate', info.data.installationDate);
      //setValue('system_type_pv', info.data.systemType);

      const powerClass = info.data?.serialNumbers?.length > 0 && info.data?.serialNumbers[0];

      setValue('productName', info.data.productName);
      setValue('powerClass', powerClass.volume);
      if(info.data.systemSize !== null) {
        setValue('systemSize', info.data.systemSize / 1000)
      }else {
        setValue('systemSize', '')
      }
      setValue('nameOfPurchasingChannel', info.data.nameOfPurchasingChannel);
      setValue('typeOfPurchasingChannel', info.data.purchasingChannel);
      const listSerial = info.data.serialNumbers.map(item => {
        return item.serialNumber
      })
      setValue('serials' , listSerial.toString());


    } catch (e) {

    }
  }

  useEffect(() => {
    filePictureOfAffectedModule.length > 0 ? setValue('pictureOfModuleLabel', 'done') : setValue('pictureOfModuleLabel', '');
    filePictureOfFront.length > 0 ? setValue('pictureOfTheFrontSize', 'done') : setValue('pictureOfTheFrontSize', '');
    filePictureOfBack.length > 0 ? setValue('pictureOfTheBackSize', 'done') : setValue('pictureOfTheBackSize', '');
    filePictureCloseUp.length > 0 ? setValue('closeUpPictureOfPhysicalDefect', 'done') : setValue('closeUpPictureOfPhysicalDefect', '');
    filePictureSitePlan.length > 0 ? setValue('siteModuleLayoutPlan', 'done') : setValue('siteModuleLayoutPlan', '');
    filePictureOfOverall.length > 0 ? setValue('pictureOfOverallRoofArea', 'done') : setValue('pictureOfOverallRoofArea', '');
    filePictureOfWireManagement.length > 0 ? setValue('pictureOfWireManagement', 'done') : setValue('pictureOfWireManagement', '');
    filePictureProofDelivery.length > 0 ? setValue('proofOfDeliveryDocument', 'done') : setValue('proofOfDeliveryDocument', '');
    filePictureImmediately.length > 0 ? setValue('pictureOfModuleImmediately', 'done') : setValue('pictureOfModuleImmediately', '');
    filePictureOfVmpp.length > 0 ? setValue('pictureOfVmpp', 'done') : setValue('pictureOfVmpp', '');
    filePictureIr.length > 0 ? setValue('irPicture', 'done') : setValue('irPicture', '')
    filePictureInVoice.length > 0 ? setValue('coppyOfInvoice', 'done') : setValue('coppyOfInvoice', '');

  }, [filePictureOfFront.length, filePictureOfBack.length, filePictureCloseUp.length, filePictureSitePlan.length, filePictureOfOverall.length, filePictureOfWireManagement.length, filePictureProofDelivery.length, filePictureImmediately.length, filePictureOfVmpp, filePictureIr.length, filePictureInVoice.length])


  const goBack = () => setCurrentStep(currentStep - 1);
  const next = () => setCurrentStep(currentStep + 1);
  const goToStep = step => setCurrentStep(step);

  const showErrorMessage = (errors, errorKeys = []) => {
    let message = '';

    for (let i = 0; i < errorKeys.length; i++) {
      if (errors[errorKeys[i]]) {
        message = errors[errorKeys[i]].message;
        break;
      }
    }

    return notiFailed(message);
  };

  async function handleSaveDraft() {
    const currentData = getValues();
    let data = _.pickBy(currentData, value => {
      if (value === 'select') {
        return false;
      }

      if (!value) {
        return false;
      }

      if (_.get(value, 'length') === 0) {
        return false;
      }

      return true;
    });

    let objKey = {};
    if(objKey !== '') {
      objKey = {
        keyUpload
      }
    }

    const arrayImage = convertArrayClaim(fileProofOfPermission, filePictureOfAffectedModule, filePictureOfVoc ,filePictureOfFront, filePictureOfBack, filePictureCloseUp, filePictureSitePlan, filePictureOfOverall, filePictureOfWireManagement, filePictureProofDelivery, filePictureImmediately, filePictureOfVmpp, filePictureIr, filePictureInVoice)

    let attachments = {}

    if(arrayImage.length > 0) {
      attachments = {
        attachments : arrayImage.toString() || ''
      }
    }

    const makePayload = {
      ...data,
      ...attachments,
      ...objKey,
      id,
      typeClaim: 'pv',
    }

    try {
      setLoading(true);
      const info = await claimService.saveDraftClaim(makePayload);
      notiSuccess('Claim PV update draft successfully!');
      history.push({
        pathname: `/claim/pv/${info.data.id}`,
        state: {
          disablePrompt: true
        }
      });

    }catch (e) {

    }finally {
      setLoading(false);
    }

  }

  const onSubmit = async () => {
    const currentData = getValues();
    let data = _.pickBy(currentData, value => {
      if (value === 'select') {
        return false;
      }

      if (!value) {
        return false;
      }

      if (_.get(value, 'length') === 0) {
        return false;
      }

      return true;
    });

    //make data call api submit

    const arrayImage = convertArrayClaim(fileProofOfPermission, filePictureOfAffectedModule, filePictureOfVoc ,filePictureOfFront, filePictureOfBack, filePictureCloseUp, filePictureSitePlan, filePictureOfOverall, filePictureOfWireManagement, filePictureProofDelivery, filePictureImmediately, filePictureOfVmpp, filePictureIr, filePictureInVoice)

    let attachments = {}

    if(arrayImage.length > 0) {
      attachments = {
        attachments : arrayImage.toString() || ''
      }
    }

    try {
      const makePayload = {
        token : config.tokenClaim,
        ...data,
        id,
        created : moment().format('YYYY-MM-DD HH:mm:ss'),
        installerAddress : data.installerAddress1,
        projectAddress: data.installerAddress1 + data.installerAddress2,
        customerName: data.installerName,
        region : 1,
        distributorName : '',
        distributorContact : '',
        distributorPhone : '',
        distributorEmail : '',
        distributorAddress: '',
        distributorCity : '',
        quantity : 2,
        submitterAddress : '',
        submitterCompany : '',
        submitterEmail : data.submitterEmail || '',
        loginPortal:'',
        loginId:'',
        commissionDate : moment(data.commissionDate).format('YYYY-MM-DD') || '',
        purchaseDate : moment(data.purchaseDate).format('YYYY-MM-DD') || '',
        installationDate : moment(data.installationDate).format('YYYY-MM-DD') || '',
        defectDate : moment(data.defectDate).format('YYYY-MM-DD') || '',
        evidence : '123',
        productname : data.productName || '',
        folderId : keyUpload,
        systemtype : data.systemType,
        project : data.title,
        permissionDate : moment(data.permissionDate).format('YYYY-MM-DD') || '',
        typeClaim : 'pv',
        ...attachments
      }

      setLoading(true);


      await claimService.saveClaim(makePayload)
      notiSuccess('Claim Submitted Successfully!')
      history.push({
        pathname: '/claim/history',
        state: {
          disablePrompt: true
        }
      })

    }catch (e) {
      notiFailed('Claim failed!')
    }finally {
      setLoading(false)
    }


  }

  const onSubmitFailed = async errors => {
    showErrorMessage(errors, keyPv)
  };

  const handleGoToReview = () => {
    setCurrentStep(3);
    //handleSubmit(() => setCurrentStep(4), onSubmitFailed)();
  };

  const renderStepPv = () => {

    switch(currentStep) {
      case 0:
        return (
          <UserInfo
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
            loading={loading}
            isEnablePreviewButton={isEnablePreviewButton}
            handleGoToReview={handleGoToReview}
            getValues={getValues}
            handleSaveDraft={handleSaveDraft}
            text={'Different from the above E-mail?'}
          />
        );
      case 1:
        return (
          <IssuePV
            goBack={goBack}
            next={next}
            handleSaveDraft={handleSaveDraft}
            loading={loading}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            fileProofOfPermission={fileProofOfPermission}
            setFileProofOfPermission={setFileProofOfPermission}
            filePictureOfAffectedModule={filePictureOfAffectedModule}
            setFilePictureOfAffectedModule={setFilePictureOfAffectedModule}
            filePictureOfVoc={filePictureOfVoc}
            setFilePictureOfVoc={setFilePictureOfVoc}
            filePictureOfFront={filePictureOfFront}
            setFilePictureOfFront={setFilePictureOfFront}
            filePictureOfBack={filePictureOfBack}
            setFilePictureOfBack={setFilePictureOfBack}
            filePictureCloseUp={filePictureCloseUp}
            setFilePictureCloseUp={setFilePictureCloseUp}
            filePictureSitePlan={filePictureSitePlan}
            setFilePictureSitePlan={setFilePictureSitePlan}
            filePictureOfOverall={filePictureOfOverall}
            setFilePictureOfOverall={setFilePictureOfOverall}
            filePictureOfWireManagement={filePictureOfWireManagement}
            setFilePictureOfWireManagement={setFilePictureOfWireManagement}
            filePictureProofDelivery={filePictureProofDelivery}
            setFilePictureProofDelivery={setFilePictureProofDelivery}
            filePictureImmediately={filePictureImmediately}
            setFilePictureImmediately={setFilePictureImmediately}
            filePictureOfVmpp={filePictureOfVmpp}
            setFilePictureOfVmpp={setFilePictureOfVmpp}
            filePictureIr={filePictureIr}
            setFilePictureIr={setFilePictureIr}
            register={register}
            control={control}
            setValue={setValue}
            watch={watch}
            errors={errors}
            clearErrors={clearErrors}
            type={2}
          />
        )
      case 2:
        return (
          <PurchaseInfoPv
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            setFilePictureInVoice={setFilePictureInVoice}
            filePictureInVoice={filePictureInVoice}
            clearErrors={clearErrors}
            handleSaveDraft={handleSaveDraft}
            loading={loading}
            handleGoToReview={handleGoToReview}
          />
        )
      case 3:
        return (
          <ReviewPv
            loading={loading}
            goBack={goBack}
            goToStep={goToStep}
            getValues={getValues}
            type={2}
            watch={watch}
            urlImageProofOfPermisstion={fileProofOfPermission}
            urlImageAffectModule={filePictureOfAffectedModule}
            urlImageOfVoc={filePictureOfVoc}
            urlImageOfFont={filePictureOfFront}
            urlImageOfBack={filePictureOfBack}
            urlImageClose={filePictureCloseUp}
            urlImageLayoutPlan={filePictureSitePlan}
            urlImageOverall={filePictureOfOverall}
            urlImageWireManagement={filePictureOfWireManagement}
            urlImageDelivery={filePictureProofDelivery}
            urlImageImmed={filePictureImmediately}
            urlImageVmpp={filePictureOfVmpp}
            urlImageIr={filePictureIr}
            urlImageInvoice={filePictureInVoice}
            isDraft={isDraft}
            handleSubmit={handleSubmit(onSubmit, onSubmitFailed)}
          />
        )
      default:
        return (
          <div>Claim Form</div>
        )
    }
  }

  return(
    <div>
      <Notification/>
      <IntroductionClaim title={'PV Claim Submission'} sub_title={['SUPPORT', 'CLAIM', 'PV Claim Submission']}/>
      <ProgressBar currentStep={currentStep} goToStep={goToStep} isEnableClick={isDraft} type={2}/>
      {loadingDetail ?
        <div className={classes.loadingIndicator}>
          <CustomLoading size={40} color="#00adef"/>
        </div>:
        <ContainerClaimFormPv>
          {renderStepPv()}
        </ContainerClaimFormPv>
      }

    </div>
  )
}

export default EditClaimPV;
