import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import DatePickerIcon from '../../../icons/date-picker-icon.svg';

const datePickerTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: '#00ADEF'
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: '#00ADEF'
        }
      },
      MuiPickersMonth: {
        monthSelected: {
          color: '#00ADEF'
        }
      },
      MuiPickersYear: {
        yearSelected: {
          color: '#00ADEF'
        }
      }
    },
});

const CustomDatePicker = props => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={datePickerTheme}>
                <KeyboardDatePicker autoOk={true} {...props} InputProps={{ readOnly: true }} className={props.className} keyboardIcon={<img src={DatePickerIcon} />}/>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
}

export default CustomDatePicker;