import React, { useState } from 'react';
import { IconButton, Popover, makeStyles } from '@material-ui/core';
import PopoverIcon from '../../config/images/popover_icon.svg'

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none'
    },
    poppaper: {
        padding: theme.spacing(1),
        backgroundColor: '#F6FAFD',
        boxShadow: 'none',
        boder: '1px solid #DCE1E1',
        color: '#4F4F4F',
        '& p': {
            fontSize: '15px',
            fontWeight: 300
        },
        '& ul': {
            fontSize: '15px',
            fontWeight: 300
        }
    }
}));

export default function CustomPopover({ children, width }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <IconButton
                size="small"
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                <img src={PopoverIcon} style={{opacity: 0.7}} />
            </IconButton>
            <Popover
                disableScrollLock={true}
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.poppaper
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {children}
            </Popover>
        </>
    )
}