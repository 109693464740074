import React from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

const datePickerTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: '#00ADEF'
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: '#00ADEF'
        }
      },
      MuiPickersMonth: {
        monthSelected: {
          color: '#00ADEF'
        }
      },
      MuiPickersYear: {
        yearSelected: {
          color: '#00ADEF'
        }
      }
    },
});

const DatePickerCustom = props => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={datePickerTheme}>
                <DatePicker autoOk={true} {...props} />
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
}

export default DatePickerCustom;