import React from 'react';
import { Box, TextField, RadioGroup, FormControlLabel, Radio, TextareaAutosize } from '@material-ui/core';
import useStyles from './useStyles';
import CustomButton from '../../../common/components/button';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import CustomLoading from '../../../common/components/CustomLoading';
import NumberFormat from 'react-number-format';
import CheckValidate from '../../../common/components/CheckValidate';
import Popover from '../../../common/components/Popover';

export default function HomeOwnerInfo({ watch, next, goBack, register, control, errors, isEnablePreviewButton, handleGoToReview, handleSaveDraft, loadingSaveDraft, isEditCompleteProject = false, loading = false, handleSubmit }) {
    const classes = useStyles();
    const isDuplicateAddress = watch('isDuplicateAddress');

    return (
        <div>
            <h1 style={{fontWeight: 500, fontSize: 16}}>
              Project Owner Information {' '}
              <Popover>
                <p>
                Please provide your customer’s information. We will send out a product warranty <br />certificate to the provided e-mail address. <br />
                E.g., If it is a residential project, please input the homeowner’s information.
                </p>
              </Popover>
            </h1>
            <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }} />
            <div className={classes.sectionContainerSys}>
                <div className={classes.sectionHeaderContainer} style={{alignItems: 'flex-start'}}>
                    <span className={classes.sectionTitle}>
                        Project Owner
                        <span>*</span>
                    </span>
                </div>
                <div>
                    <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])} style={{marginBottom: 22}}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="firstName"
                            placeholder="First Name"
                            className={classNames([classes.inputField], {
                                [classes.errorInputField]: errors.firstName
                            })}
                            inputRef={register}
                        />
                        <CheckValidate value={watch('firstName')} error={errors.firstName}/>
                    </div>
                    <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="lastName"
                            placeholder="Last Name"
                            className={classNames([classes.inputField], {
                                [classes.errorInputField]: errors.lastName
                            })}
                            inputRef={register}
                        />
                        <CheckValidate value={watch('lastName')} error={errors.lastName}/>
                    </div>
                </div>
            </div>
            <div className={classes.sectionContainerSys}>
                <div className={classes.sectionHeaderContainer}>
                    <span className={classes.sectionTitle}>
                        E-mail {' '}
                        <span>*</span>
                    </span>
                </div>
                <div className={classNames([classes.inputFieldMediumContainer],[classes.addressSection])}>
                    <TextField
                        fullWidth
                        placeholder="email@email.com"
                        variant="outlined"
                        name="email"
                        inputRef={register}
                        className={classNames([classes.inputField], {
                            [classes.errorInputField]: errors.email
                        })}
                    />
                    <CheckValidate value={watch('email')} error={errors.email}/>
                </div>
            </div>
            <div className={classes.sectionContainerSys}>
                <div className={classes.sectionHeaderContainer} style={{alignItems: 'flex-start'}}>
                    <span className={classes.sectionTitle}>
                        Contact Number {' '}
                        <span>*</span>
                    </span>
                </div>
                <div>
                    <div className={classNames([classes.inputFieldMediumContainer],[classes.addressSection])}>
                        <Controller
                            name="phoneNumber"
                            rules={{
                                required: true
                            }}
                            control={control}
                            as={
                                <TextField
                                    fullWidth
                                    placeholder="xxx-xxx-xxx"
                                    variant="outlined"
                                    className={classNames([classes.inputField], {
                                        [classes.errorInputField]: errors.phoneNumber
                                    })}
                                    InputProps={{
                                        inputComponent: NumberFormat
                                    }}
                                />
                            }
                        />
                        <CheckValidate value={watch('phoneNumber')} error={errors.phoneNumber}/>
                    </div>
                    <div className={classes.contactNumberContainer} style={{marginTop: '20px'}}>
                        <Controller
                        name="selectedContactNumber"
                        control={control}
                        as={
                            <RadioGroup row aria-label="position">
                                <FormControlLabel
                                    value="1"
                                    control={<Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />}
                                    label="Mobile"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />}
                                    label="Home/Office"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        }
                        />
                    </div>
                </div>
            </div>
            <div className={classes.sectionContainerSys}>
                <div className={classes.sectionHeaderContainer} style={{alignItems: 'flex-start'}}>
                    <span className={classes.sectionTitle}>
                        Notes
                    </span>
                </div>
                <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                    <Controller
                        name="note"
                        control={control}
                        as={
                            <TextareaAutosize
                                rowsMin={5}
                                className={classes.note}
                                placeholder="Notes"
                                style={{resize: 'none'}}
                            />
                        }
                    />
                </div>
            </div>

            <p
                className={classes.requiredText}
                // style={{ marginTop: '341px' }}
            >* Required</p>
            <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }}/>
            {isEditCompleteProject ? (
                <Box display="flex" justifyContent="flex-end" className={classes.navigationBtnContainer}>
                    <CustomButton className={`${isDuplicateAddress ? 'saveDraftBtnReview' : ''}`} onClick={handleSubmit} disabled={isDuplicateAddress}>
                        { loading ? <CustomLoading size={16} color="#ffffff"/> : 'Save' }
                    </CustomButton>
                </Box>
            ) : (
                <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
                    {isEnablePreviewButton ? (
                        <CustomButton color="secondary" className="saveDraftBtn" onClick={handleGoToReview}>Back to Review</CustomButton>
                    ) : (
                        <CustomButton color="secondary" className="saveDraftBtn" onClick={goBack}>Back</CustomButton>
                    )}
                    <div>
                        <CustomButton color="secondary" className="saveDraftBtn" onClick={handleSaveDraft} disabled={loadingSaveDraft}>
                            { loadingSaveDraft ? <CustomLoading size={16} color="#009EE3" ></CustomLoading> : 'Save Draft' }
                        </CustomButton>
                        <CustomButton onClick={next}>Next</CustomButton>
                    </div>
                </Box>
            )}
        </div>
    )
}
