import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonBase from '@material-ui/core/ButtonBase';

const StyledButton = styled(ButtonBase)(
  ({ theme: { colors }, selected, square }) => `
  font-size: 1rem;
  line-height: 1rem;
  padding: 1rem;
  width: 110px;
  margin: 10px;
  border-radius: ${square ? '0px' : '5px'};
  transition: background-color 175ms ease-out;
  background-color: ${selected ? '#e5f3fb' : colors.light};
  color: ${selected ? colors.primaryLight : colors.secondaryDark};
  border: 1px solid
    ${selected ? colors.primaryLight : colors.secondaryDark};

  &:hover {
    background-color: ${square ? '#fff' : '#e5f3fb'};
    color: ${colors.primaryLight};
    border-color: ${square ? '#000 !important' : colors.primaryLight};
  }
`
);

/**
 * @name MonthButton
 * @description square button
 * @param {Boolean} selected The color can be changed by passing a boolean value of selected
 * @param {node} children The content inside the button. ie "June"
 * @extends {ButtonBase}
 */
const MonthButton = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

MonthButton.propTypes = {
  selected: PropTypes.bool,
  square: PropTypes.bool,
  children: PropTypes.node.isRequired,
  ...ButtonBase.propTypes
};

export default MonthButton;
