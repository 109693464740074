import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { useAPIClient } from './api';

const Context = React.createContext();

export const AuthContext = ({ children }) => {
  const [auth, setAuth] = useState(JSON.parse(localStorage.getItem('qpartner_user')));
  const [profile, setProfile] = useState({});
  const [infoUser, setInfoUser] = useState({});
  const [authenticated, setAuthenticated] = useState(!!(auth && auth.token));
  const apiClient = useAPIClient();

  const [user_qpoint, set_user_qpoint] = useState(_.get(auth, 'qpoint'));
  useEffect(() => {
    let qpartner_user = Object.assign(
      {},
      JSON.parse(localStorage.getItem('qpartner_user'))
    );
    qpartner_user.qpoint = user_qpoint;
    localStorage.setItem('qpartner_user', JSON.stringify(qpartner_user));
  }, [user_qpoint]);

  const checkAuthenticated = async () => {
    try {
      const profile = await apiClient.getInfoProfile(_.get(auth, 'user.id'));
      setProfile(profile.data);
      setAuthenticated(true);
    } catch (err) {
      setAuthenticated(false);
      setAuth({});
      localStorage.removeItem('qpartner_user');
      console.log(err);
    }
  };

  const getInfoDetailUser = async () => {
    try {
      const infoUser = await apiClient.getUserProfile(_.get(auth, 'user.id'))
      setInfoUser(infoUser.data);
    }catch (e) {
      console.log(e);
    }


  }

  useEffect(() => {
    checkAuthenticated();
    getInfoDetailUser();
  }, [_.get(auth, 'user.id')]);

  useEffect(() => {
    setAuthenticated(!!(auth && auth.token));
  }, [auth]);

  // list page not need login
  let pageNotNeedLogin = [
    '/register',
    '/sso/login',
    '/policy',
    '/terms',
    '/rewards/shop',
    '/about/program-introduction',
    '/rewards/qpoint-introduction',
    '/salestools/designtool',
    '/',
    '/sitemap',
    '/qpartner/nda',
    '/salestools/opensolar',
    '/salestools/aurorasolar',
    '/salestools/designtool',
    '/',
    '/sitemap',
    '/salestools/data-hub/video-content',
    '/salestools/data-hub/download-center',
    '/support/faq/1',
    '/support/contact-us',
    '/notice/506',
    '/notice',
    '/projects/project-introduction',
    '/claim/how-it-works',
    '/ccpa-privacy-notice',
  ];
  if (!authenticated && !pageNotNeedLogin.includes(window.location.pathname) && !window.location.pathname.includes('/rewards/shop') && !window.location.pathname.includes('/claim/how-it-works')) {
    // alert('Please try again after logging in.')
    alert('Please try again after logging in.')
    window.location.href = `/sso/login?url=${window.location.pathname}`;
  }

  return (
    <Context.Provider
      value={{ ...auth?.user, authenticated, qpoints: user_qpoint, set_user_qpoint, profile, setAuth, auth, infoUser, setProfile }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAuth = () => useContext(Context);
