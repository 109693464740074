import React from 'react';
import ImageUploader from './UploadImage';
import useStyles from './useStyles';
import { Box } from '@material-ui/core';
import CustomButton from '../../../common/components/button';
import CustomLoading from '../../../common/components/CustomLoading';


function LaborWarranty({ next, goBack, setFileLaborWarranty, fileLaborWarranty, errors, setKeyUpload, keyUpload, clearErrors, handleSaveDraft, loading}) {
  const classes = useStyles();

  return(
    <div>
      <div className={classes.sectionContainer}>
        <div
          className={classes.sectionHeaderContainer}
          style={{
            alignItems: 'flex-start',
            marginTop: '12px'
          }}
        >
                    <span className={classes.sectionTitle}>
                      Invoice for Labor Cost Reimbursement
                    </span>
        </div>
        <div className={classes.wrapUpload}>
          <ImageUploader
            files={fileLaborWarranty}
            setFiles={setFileLaborWarranty}
            fileName={'warranty.jpg'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            clearErrors={clearErrors}
            name={'warranty'}
          />
        </div>
      </div>
      <p className={classes.requiredText} style={{ marginTop: '200px' }}>* Required</p>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }}/>
      <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
        <CustomButton color="secondary" className="saveDraftBtn" onClick={goBack}>Back</CustomButton>
        <div>
          <CustomButton onClick={handleSaveDraft} color="secondary" className="saveDraftBtn" disabled={loading}>
            { loading ? <CustomLoading size={16} color="#009EE3" /> : 'Save Draft' }
          </CustomButton>
          <CustomButton onClick={next}>Next</CustomButton>
        </div>
      </Box>
    </div>
  )
}

export default LaborWarranty;
