import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import UserInfo from '../components/UserInfo';
import Issue from '../components/Issue';
import SystemInfo from '../components/SystemInfo';
import SoftwareVersion from '../components/SoftwareVersion';
import FieldDetail from '../components/FieldDetail';
import PurchaseInfo from '../components/PurchaseInfo';
import { notiFailed, notiSuccess } from '../../../utils/helpers';
import _ from 'lodash';
import { useAuth } from '../../../contexts/auth';
import IntroductionClaim from '../components/IntroductionClaim';
import ProgressBar from '../components/ProgressBar';
import styled from 'styled-components';
import ReviewEss from '../components/ReviewEss';
import { claimService, projectService } from '../../../services';
import { useHistory } from 'react-router-dom';
import LaborWarranty from '../components/LaborWarranty';
import { keyEss } from '../components/keyEss';
import { defaultStateEss } from '../components/defaultStateEss';
import { schemaEss } from '../components/schemaEss';
import Notification from '../components/Notification';
import InformationClaimType from '../components/InformationClaimType';
import PurchaseDetail from '../components/PurchaseDetail';
import { keyProject } from '../../projects/components/keyProject';
import moment from 'moment';
import { keyInfo } from '../components/keyInfo';

const ContainerClaimFormEss = styled.div `
    width: 80%;
    margin: 36px auto 70px auto;
    display: grid;
    
    @media (max-width: 1366px) {
      grid-template-columns: auto;
      grid-column-gap: 0px;
      width: 90%;
      /* margin: 50px auto 30px; */
    }
`

function EssPage({ register, handleSubmit, infoClaimName ,errors, control, watch, trigger ,setValue, getValues, formState, clearErrors, listProductPti, errorInfo, triggerInfo,
                   listSerial, setListSerial, serials, setSerials,
                   fileLaborWarranty, fileInfoBank, fileInvoiceLabor, setFileInvoiceLabor, setFileInfoBank, setFileLaborWarranty, saleRegions, id, urlImageProof, setFilesProof,
                   keyUpload, setKeyUpload, fileErrorLog, setFileErrorLog, fileEvidence, setFileEvidence, setFilePermissionOfDate, filePermissionOfDate, condition,
                   goBackStepDefault, handleSetStep ,claimInfoName, projects, submitterEmail, checkedEmail, mainPurchasingChannel, distributors}) {

  const user = useAuth();
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const projectId = watch('projectSys');
  const data = watch();
  const projectSystemType = watch('projectSystemType');

  // useEffect(() => {
  //   setValue('claimType', infoClaimName)
  // }, [infoClaimName])


  useEffect(() => {
    getProfile()
  }, [])

  async function getProfile() {
    const info = await projectService.getProfile();
    setValue('installerCity', _.get(info.data, 'city'))
    setValue('installerAddress1', _.get(info.data, 'address'))
    setValue('installerState', _.get(info.data, 'state'))
    setValue('installerZipcode', _.get(info.data, 'zipCode'))
    setValue('installerAddress2', _.get(info.data, 'addressCont'))
    setValue('installerEmail', _.get(info.data, 'email'))
    setValue('installerName', _.get(info.data, 'fullName'))
    setValue('installerContact', _.get(info.data, 'fullName'))
    setValue('companyName', _.get(info.data, 'companyName'))
    //setValue('installerPhone', _.get(info.data, 'officeNumber') || _.get(info.data, 'mobileNumber') )
    setValue('installerPhone', !!_.get(info.data, 'officeNumber') ? _.get(info.data, 'officeNumber') : _.get(info.data, 'mobileNumber') )
  }

  useEffect(() => {
    setValue('submitterEmail', submitterEmail)
  }, [claimInfoName])

  useEffect(() => {
    if(currentStep === 4) {
      trigger(keyEss).then(r => console.log(r));
      triggerInfo(keyInfo).then(r => console.log(r));
    }
  }, [currentStep])


  useEffect(() => {
    fileErrorLog.length > 0 ? setValue('errorLog','done') : setValue('errorLog', '')
    fileErrorLog.length > 0 && clearErrors('errorLog')
  }, [fileErrorLog])

  useEffect(() => {
    fileInvoiceLabor.length > 0 ? setValue('invoiceForLaborCost', 'done') : setValue('invoiceForLaborCost', '');
    fileInvoiceLabor.length > 0 && clearErrors('invoiceForLaborCost')
    filePermissionOfDate.length > 0 ? setValue('isUploadProofDate', 'done') : setValue('isUploadProofDate', '');
    filePermissionOfDate.length > 0 && clearErrors('isUploadProofDate')

  }, [fileInvoiceLabor, filePermissionOfDate])



  useEffect(() => {
    fileInfoBank.length > 0 ? setValue('infoBank', 'done') : setValue('infoBank', '');
    fileInfoBank.length > 0 && clearErrors('infoBank')
  }, [fileInfoBank])

  useEffect(() => {
    handleSetStep(currentStep)
  }, [currentStep])

  async function getDetailProject(id) {
    try {
      const info = await claimService.getProjectById(id);
      setValue('projectNameSys' , info.data.projectName);
      setValue('addressSys' , info.data.projectAddress);
      setValue('installationDateSys' , info.data.installationDate);
      setValue('systemTypeSys' , info.data.systemType);
      setValue('productNameSys' , info.data.rackingProductName);
      setValue('distributorName' , info.data.nameOfPurchasingChannel);
      setValue('totalPvPowerInstalled', info.data.systemSize);
      const listSerial = info.data.serialNumbers.map(item => {
        return item.serialNumber
      })
      setValue('inverterSerialNumberSys' , listSerial.toString());

      clearErrors('projectNameSys')
      clearErrors('addressSys')
      clearErrors('installationDateSys')
      clearErrors('systemTypeSys')
      clearErrors('productNameSys')
      clearErrors('inverterSerialNumberSys')

    }catch (e) {

    }
  }

  useEffect(() => {
    if(projectId !== 'select') {
      getDetailProject(projectId);
    }
  }, [projectId])


  const showErrorMessage = (errors, errorKeys = []) => {
    let message = '';

    for (let i = 0; i < errorKeys.length; i++) {
      if (errors[errorKeys[i]]) {
        message = errors[errorKeys[i]].message;
        break;
      }
    }

    return notiFailed(message);
  };

  const onSubmitFailed = async errors => {
    showErrorMessage(errors, keyEss)
  };

  const goBack = () => setCurrentStep(currentStep - 1);
  const next = () => setCurrentStep(currentStep + 1);
  const handleGoToReview = () => {
    handleSubmit(() => setCurrentStep(6), onSubmitFailed)();
  };

  async function handleSaveDraft() {
    const currentData = getValues();
    let data = _.pickBy(currentData, value => {
      if (value === 'select') {
        return false;
      }

      if (!value) {
        return false;
      }

      if (_.get(value, 'length') === 0) {
        return false;
      }

      return true;
    });





    try {
      setLoading(true);
      let objKey = {};
      if(objKey !== '') {
        objKey = {
          keyUpload
        }
      }

      const attackError = fileErrorLog.length > 0 && fileErrorLog.map(item => {
        return item.url
      }, []);

      const attackEvidence = fileEvidence.length > 0 && fileEvidence.map(item => {
        return item.url
      }, []);

      const attackInvoice = fileInvoiceLabor.length > 0 && fileInvoiceLabor.map(item => {
        return item.url
      })

      const attackOfDate = filePermissionOfDate.length > 0 && filePermissionOfDate.map(item => {
        return item.url
      })

      const attackBank = fileInfoBank.length > 0 && fileInfoBank.map(item => {
        return item.url
      })

      let combineFile = [];

      attackError.length > 0 && combineFile.push(attackError);
      attackEvidence.length > 0 && combineFile.push(attackEvidence);
      attackOfDate.length > 0 && combineFile.push(attackOfDate);
      attackInvoice.length > 0 && combineFile.push(attackInvoice);
      attackBank.length > 0 && combineFile.push(attackBank);

      let attachments = {}

      if(combineFile.length > 0) {
        attachments = {
          attachments : combineFile.toString() || ''
        }
      }

      let projectId = {}

      if(data.projectInfo !== null) {
        projectId = {
          projectId : data.projectInfo?.id
        }
      }

      let idClaim = {}

      if(id !== '') {
        idClaim = {
          id
        }
      }

      const infoSub = {...data, ...objKey, ...attachments, type  : '2' ,projectInfo : data.projectInfo, ...projectId, ...idClaim, title: data.projectNameSys || '' };


      const info = await claimService.saveDraftClaim(infoSub);
      notiSuccess('Claim ESS draft successfully!');
      history.push({
        pathname: `/claim/edit/2/1/${info.data.id}`,
        state: {
          disablePrompt: true,
          isPickClaimType: true
        }
      });

    }catch (e) {
      notiFailed(`Claim ESS draft failed!`)
    }finally {
      setLoading(false);
    }

  }

  const renderNameOfPurchasingChannel = () => {
    const purchaseChannel = getValues('purchasingChannel');
    switch (purchaseChannel) {
      case 'qcells':
        return 'Q CELLS';
      case 'distributor':
        return _.get(data, 'nameOfPurchasingChannelDistributor.name') + '' + `${_.get(data, 'nameOfPurchasingChannelDistributor.name') === "Other" ? `-${_.get(data, 'nameOfChanelOtherDistributor', '')}` : ''}`;
      case 'other':
        return getValues('nameOfPurchasingChannelOther');
      default:
        return '';
    }
  };

  const renderCommissionDate = () => {
    let commissionDate = "";

    if(data.projectInfo !== null) {
      commissionDate = moment(_.get(data, 'projectInfo.operateDate', '')).format('YYYY-MM-DD')
    }else if(data.choseTypeProject === '2') {
      commissionDate = moment(data.projectPermissionDate).format('YYYY-MM-DD')
    }

    return commissionDate
  }

  const renderSerialNumber = () => {
    const choseTypeSerialUpload = getValues('choseTypeSerialUpload')

    if(choseTypeSerialUpload === '1') {
      return getValues('serialNumbers')?.map(item => item.serialNumber).toString()
    }

    if(choseTypeSerialUpload === '2') {
      return getValues('listSerialPick')?.map(item => item.serialNumber).toString()
    }
  }

  function renderLaborStatus(laborReimbursement, laborWarranty, choseTypeProject, chooseRefund, batteryQualified) {

    if(choseTypeProject === '2') {
      return 'No'
    }

    if(laborReimbursement && laborWarranty && chooseRefund === '1') {
      return 'No'
    }

    if(!batteryQualified) {
      return 'No'
    }

    if((!laborReimbursement && laborWarranty) || !laborReimbursement ) {
      return 'No'
    }

    return 'Yes'
  }

  const onSubmit = async () => {

    const currentData = getValues();
    let data = _.pickBy(currentData, value => {
      if (value === 'select') {
        return false;
      }

      if (!value) {
        return false;
      }

      if (_.get(value, 'length') === 0) {
        return false;
      }

      return true;
    });

    try {
      setLoading(true);
      let objKey = {};
      if(objKey !== '') {
        objKey = {
          keyUpload
        }
      }

      const attackError = fileErrorLog.length > 0 && fileErrorLog.map(item => {
        return item.url
      }, []);

      const attackEvidence = fileEvidence.length > 0 && fileEvidence.map(item => {
        return item.url
      }, []);

      const attackInvoice = fileInvoiceLabor.length > 0 && fileInvoiceLabor.map(item => {
        return item.url
      })

      const attackOfDate = filePermissionOfDate.length > 0 && filePermissionOfDate.map(item => {
        return item.url
      })

      const attackBank = fileInfoBank.length > 0 && fileInfoBank.map(item => {
        return item.url
      })

      //if manual -> push image in array


      let combineFile = [];

      attackError.length > 0 && combineFile.push(attackError);
      attackEvidence.length > 0 && combineFile.push(attackEvidence);
      attackInvoice.length > 0 && combineFile.push(attackInvoice);
      attackOfDate.length > 0 && combineFile.push(attackOfDate);
      attackBank.length > 0 && combineFile.push(attackBank);

      let attachments = {}

      if(combineFile.length > 0) {
        attachments = {
          attachments : combineFile.toString() || ''
        }
      }

      let distributorName = '' ;
      if (data.purchasingChannel === 'distributor') {
        distributorName = data.nameOfPurchasingChannelDistributor?.name
      } else if (data.purchasingChannel === 'other') {
        distributorName = data.nameOfPurchasingChannel
      }

      const itemProduct = listProductPti.filter(item => item.name === data.projectProductName)

      let projectId = {}

      if(data.projectInfo !== null) {
        projectId = {
          projectId : data.projectInfo?.id
        }
      }

      let idClaim = {}

      if(id !== '') {
        idClaim = {
          id
        }
      }


      const makePayload = {
        type: 2,
        folderId: keyUpload,
        title: renderLaborStatus(_.get(data, 'laborReimbursement', false), _.get(data, 'laborWarranty', false), data.choseTypeProject, data.chooseRefund, _.get(data, 'batteryQualified', false)) === 'Yes' ? 'LW qualifying' : 'LW not qualifying',
        ...projectId,
        created: moment().format('YYYY-MM-DD'),
        submitterEmail: data.submitterEmail || '',
        description: data.description || '',
        projectAddress: data.projectAddress || '',
        customerName: data.projectNameSys || '',
        distributorName: renderNameOfPurchasingChannel()  || '',
        distributorAddress: data.installerAddress1 || "",
        claimType: data.claimType || '',
        operationMode: data.operationalModeUse || '',
        productname: itemProduct.length > 0 ? itemProduct[0].id : '',
        quantity: data.numberOfBattery || 0,
        systemSize: data.totalPvPowerInstalled || 0,
        inverterSn: data.projectInverterSerialNumber || '1',
        batterySn: data.batterySerialNumber || '',
        dspMaster: data.dspMaster || '',
        dspMaster2: "1",
        manager: "1",
        batMaster: data.batteryMaster || 'master',
        batSlave: "1",
        pv1: data.pv1 || '1',
        pv2: data.pv2 || '2',
        pv3: data.pv3 || '3',
        pv4: data.pv4 || '4',
        ac1:  data.loadL1Voltage || '1',
        ac2: data.loadL2Voltage || '2',
        criticalLoadsConnected: data.criticalLoadsConnected || '',
        serials: renderSerialNumber(),
        poNumber: data.poNumber || '',
        systemtype: projectSystemType === 'select' ? '' : projectSystemType,
        installerName: data.companyName || '',
        installerContact: data.installerContact || '',
        installerAddress: data.installerCity || '',
        installerEmail: data.installerEmail || '',
        installerPhone: data.installerPhone || '',
        purchaseDate: data.purchaseDate ? moment(data.purchaseDate).format('YYY-MM-DD') : '',
        installationDate:  moment(data.projectInstallationDate).format('YYYY-MM-DD') || '',
        project: data.projectNameSys || '',
        commissionDate: renderCommissionDate() || '',
        nameOfChanelOtherDistributor : _.get(data, 'nameOfPurchasingChannelDistributor.name') === 'Other' && data.purchasingChannel === 'distributor' ? data.nameOfChanelOtherDistributor : '',
        ...attachments
      }


      const infoSub = {...data, ...makePayload, ...idClaim};

      const info = await claimService.saveClaim(infoSub);

      notiSuccess('Claim Submitted Successfully');
      history.push({
        pathname: `/claim/history`,
        state: {
          disablePrompt: true
        }
      });

    }catch (e) {
      notiFailed(`Claim ESS submitted failed!`)
    }finally {
      setLoading(false);
    }



  }

  const goToStep = step => setCurrentStep(step);

  function goBackStep() {
    goBackStepDefault(data)
  }


  const renderStepClaimEss = () => {

    switch(currentStep) {

      case 1:
        return (
          <Issue
            goBack={goBackStep}
            next={next}
            register={register}
            control={control}
            errors={errors}
            type={1}
            setValue={setValue}
            clearErrors={clearErrors}
            loading={loading}
            watch={watch}
            handleSaveDraft={handleSaveDraft}
            fileErrorLog={fileErrorLog}
            setFileErrorLog={setFileErrorLog}
            fileEvidence={fileEvidence}
            setFileEvidence={setFileEvidence}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
          />
        )

      case 2:
        return (
          <SystemInfo
            goBack={goBack}
            next={next}
            condition={condition}
            register={register}
            control={control}
            saleRegions={saleRegions}
            errors={errors}
            watch={watch}
            urlImageProof={urlImageProof}
            setFilesProof={setFilesProof}
            filePermissionOfDate={filePermissionOfDate}
            setFilePermissionOfDate={setFilePermissionOfDate}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            setValue={setValue}
            handleSaveDraft={handleSaveDraft}
            loading={loading}
            listSerial={listSerial}
            setListSerial={setListSerial}
            serials={serials}
            clearErrors={clearErrors}
            setSerials={setSerials}
            projects={projects}
            distributors={distributors}
          />
        )
      case 3:
        return (
          <PurchaseDetail
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
            distributors={distributors}
            mainPurchasingChannel={mainPurchasingChannel}
            fileLaborWarranty={fileLaborWarranty}
            fileInfoBank={fileInfoBank}
            fileInvoiceLabor={fileInvoiceLabor}
            setFileInvoiceLabor={setFileInvoiceLabor}
            setFileInfoBank={setFileInfoBank}
            setFileLaborWarranty={setFileLaborWarranty}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            watch={watch}
            setValue={setValue}
            handleSaveDraft={handleSaveDraft}
            loading={loading}
            clearErrors={clearErrors}
          />
        )

      case 4:
        return (
          <ReviewEss
            loading={loading}
            goBack={goBack}
            goBackStep={goBackStep}
            goToStep={goToStep}
            getValues={getValues}
            type={1}
            watch={watch}
            errors={errors}
            disabled={!formState?.isDirty || !formState?.isValid}
            urlErrors={fileErrorLog}
            urlEvidence={fileEvidence}
            urlLaborWarranty={fileLaborWarranty}
            urlInfoBank={fileInfoBank}
            errorInfo={errorInfo}
            urlImageProofOfPermisstion={filePermissionOfDate}
            urlImageProof={urlImageProof}
            urlInvoiceLabor={fileInvoiceLabor}
            handleSubmit={handleSubmit(onSubmit, onSubmitFailed)}
          />
        )
      default:
        return (
          <Issue
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
            type={1}
            setValue={setValue}
            clearErrors={clearErrors}
            loading={loading}
            watch={watch}
            handleSaveDraft={handleSaveDraft}
            fileErrorLog={fileErrorLog}
            setFileErrorLog={setFileErrorLog}
            fileEvidence={fileEvidence}
            setFileEvidence={setFileEvidence}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
          />
        )
    }
  }

  return(
    <div>
      {currentStep >= 1 && <ProgressBar currentStep={currentStep} goToStep={goToStep} isEnableClick={true} type={1}/>}
      <ContainerClaimFormEss>
        {renderStepClaimEss()}
      </ContainerClaimFormEss>
    </div>
  )
}

export default EssPage;
