import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  InputAdornment,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography
} from '@material-ui/core';
// New code
import Logo from '../config/images/logo.png';
import Text from './components/text';

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

function Copyright() {
  return (
    <Typography variant="body2" align="center" style={{ color: '#ffffff' }}>
      {'Copyright © '}
      <Link to="/register" color="inherit" target="_blank">
        Hanwha Qcells
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

// New code
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  root: {
    // height: '50vh',
    width: '60vw'
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper2: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  margin: {
    marginTop: '10px'
  }
}));

export default function LoginModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let history = useHistory();

  const handleOpen = () => {
    history.push(`/sso/login?url=/dashboard`);
    // setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <a href="#" onClick={handleOpen}>
        LOGIN
      </a>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper2}>
            <Grid container component="main" className={classes.root}>
              <CssBaseline />

              <Grid
                item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={6}
                square
              >
                <div className={classes.paper2}>
                  <img
                    src={Logo}
                    alt="QCELLS Logo"
                    style={{ maxHeight: '50px', width: '80px' }}
                  ></img>
                  <Typography component="h1" variant="h5">
                    {'Welcome to your  '}
                    <Link
                      to="/register"
                      style={{ color: '#00ADEF' }}
                      target="_blank"
                    >
                      Q.Partner
                    </Link>
                    {' Portal'}
                  </Typography>
                  <p style={{ color: '#8C8C8C' }}>
                    Register your products, track your forecasts, earn rewards.
                  </p>
                  <form className={classes.form} noValidate>
                    <TextField
                      fullWidth
                      placeholder="Email"
                      id="filled-start-adornment"
                      className={clsx(classes.margin, classes.textField)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlineIcon fontSize="small" />
                          </InputAdornment>
                        )
                      }}
                      variant="outlined"
                    />
                    <TextField
                      fullWidth
                      type="password"
                      placeholder="Password"
                      id="filled-start-adornment"
                      className={clsx(classes.margin, classes.textField)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlinedIcon fontSize="small" />
                          </InputAdornment>
                        )
                      }}
                      variant="outlined"
                    />
                    <Grid
                      container
                      justify="flex-end"
                      spacing={1}
                      style={{ marginTop: '10px' }}
                    >
                      <Grid item>
                        <Link href="#" variant="body2" style={{ color: '#8C8C8C' }}>
                          Forgot my Password
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link href="#" variant="body2" style={{ color: '#8C8C8C' }}>
                          Forgot my ID
                        </Link>
                      </Grid>
                    </Grid>
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      style={{ backgroundColor: '#00ADEF', color: '#ffffff' }}
                      className={classes.submit}
                    >
                      Login
                    </Button>
                    <Grid container>
                      <Grid item>
                        <a
                          href={`${process.env.REACT_APP_QPARTNER_LEGACY_BASE_URL}/members/join.fos`}
                        >
                          <Link
                            href="#"
                            variant="body2"
                            style={{ color: '#8C8C8C' }}
                          >
                            {"Don't have an account? Become a Q.Partner"}
                          </Link>
                        </a>
                      </Grid>
                    </Grid>
                  </form>
                </div>
                <Box pt={2} pb={2} style={{ backgroundColor: '#8FCDF0' }}>
                  <Copyright />
                </Box>
              </Grid>
              <Grid item xs={false} sm={4} md={7} className={classes.image} />
            </Grid>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}
