import React from 'react';
import PropTypes from 'prop-types';
// <h1 style={{ fontSize: '48px', fontWeight: 600 }}>Rewards History</h1>

const BreadCrumb2 = ({ items, type = '' }) => (
	<nav className={`breadcrumb2 ${type === 'form' && 'form'}`}>
		<div className={`breadcrumb_cont2`}>
			<ul>
				<li className="li home">HOME</li>
				{items.map((item, index) => (
					<li key={item} className={`li ${type === 'form' && 'form'}`} dangerouslySetInnerHTML={{__html: item}} />
				))}
			</ul>
		</div>
	</nav>
);

BreadCrumb2.propTypes = {
	items: PropTypes.any.isRequired
};

export default BreadCrumb2;
