module.exports = {
  baseUrl: process.env.REACT_APP_API_BASE_URL || 'https://api-stg.qcellspartner.us',
  portalId : process.env.REACT_APP_PORTAL_ID || '3858309',
  formId : process.env.REACT_APP_FORM_ID || '5f06493f-5c33-41a6-8b11-3829f7add5a9',
  urlTools : process.env.REACT_APP_URL_TOOLS || 'https://tools.usps.com/go/TrackConfirmAction_input',
  urlUps : process.env.REACT_APP_URL_UPS || 'https://www.ups.com/track?loc=en_US',
  urlFeDex : process.env.REACT_APP_URL_FEDEX || 'https://www.fedex.com/en-us/tracking.html?action=track',
  urlTemplateSerialNumber : process.env.REACT_APP_URL_SERIAL_NUMBER || 'https://qpartner-template.s3.amazonaws.com/installer-serial-number-upload-template.xlsx',
  urlBox1 : process.env.REACT_APP_BOX1 || 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621245947077-img_mc_box04.jpeg',
  urlBox2 : process.env.REACT_APP_BOX2 || 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621245947388-img_mc_box05.jpeg',
  urlBox3 : process.env.REACT_APP_BOX3 || 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621245947485-img_mc_box02.jpeg',
  urlBox4 : process.env.REACT_APP_BOX3 || 'https://qpartner.s3.us-west-1.amazonaws.com/media/2022072122490-img_mc_box06.jpg',
  urlBox5 : process.env.REACT_APP_BOX3 || 'https://qpartner.s3.us-west-1.amazonaws.com/media/2022072122491-img_mc_box07.jpg',
  urlIconActive : process.env.REACT_APP_ICON_CRASH || 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621245947582-ico_mc_login_pagn05.png',
  urlIconCrash : process.env.REACT_APP_ICON_CRASH_ACTIVE || 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621245947587-ico_mc_login_pagn05_on.png',
  urlIconOfflineActive : process.env.REACT_APP_ICON_OFFLINE || 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621245947588-ico_mc_login_pagn03.png',
  urlIconOffline : process.env.REACT_APP_ICON_OFFLINE_ACTIVE || 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621245947597-ico_mc_login_pagn03_on.png',
  urlIconDataHubActive : process.env.REACT_APP_ICON_DATA_HUB || 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621245947598-ico_mc_login_pagn06.png',
  urlIconDataHub : process.env.REACT_APP_ICON_DATA_HUB_ACTIVE || 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621245947598-ico_mc_login_pagn06_on.png',
  urlBanner1 : process.env.REACT_APP_BANNER1 || 'https://qpartner.s3.amazonaws.com/media/1621247014208-popupessreward.png',
  urlBanner2 : process.env.REACT_APP_BANNER2 || 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621247014541-popupdashboard.jpeg',
  urlLink1 : process.env.REACT_APP_LINK1 || 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621265428682-1.png',
  urlLink2 : process.env.REACT_APP_LINK2 || 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621265428683-2.png',
  urlLink3 : process.env.REACT_APP_LINK3 || 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621265428684-3.png',
  idNews : process.env.REACT_APP_ID || 210,
  urlNoImage : process.env.REACT_APP_NO_IMAGE || 'https://qpartner.s3.us-west-1.amazonaws.com/media/1625801435183-no_image.jpeg',
  tokenClaim : process.env.REACT_APP_TOKEN_CLAIM || 'XSNyt50JalI0B78eAL3M2M2d',
  urlDefaultExcel : process.env.REACT_APP_EX || 'https://qpartner.s3.us-west-1.amazonaws.com/claims/bae904b0-2b3f-447f-a2a8-148e7f97d9a2/1626144485641_evidence.jpg',
  urlGoogleMap : process.env.REACT_URL_GOOGLE_MAP || 'https://maps.googleapis.com/maps/api/place/textsearch/json',
  idNewsWithoutPromoCode : process.env.REACT_APP_ID || 506,
  idNewsWithPromoCode : process.env.REACT_APP_ID || 507,
  keyGoogleMap : process.env.REACT_GOOGLE_MAP_KEY || 'AIzaSyAUgXlWmmy0kenfG_8PxzmP3-Qz5MjBxvc' || 'AIzaSyBw1eVJHt7x4Xjkkf_mSPnh5hFqtwisEEo',
  keyMap : process.env.REACT_KEY_MAP || 'AIzaSyAUgXlWmmy0kenfG_8PxzmP3-Qz5MjBxvc'
};
