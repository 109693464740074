import React from 'react';
import useStyles from './useStyles';
import PopOver from '../../../config/images/popover_icon.svg';



function ShowMessagePaid({textInfo}) {

  const classes = useStyles();

  return(
    <div className={classes.wrapMessage}>
      <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/> <em className={classes.infoTextMessage}>{textInfo}</em>
    </div>
  )
}

export default ShowMessagePaid;
