export const keyPv = [
  'installerName' , 'installerAddress1' , 'installerAddress2', 'installerCity', 'installerState', 'installerZipcode', 'installerContact' , 'installerEmail', 'installerPhone',
  'claimType', 'type', 'projectInfo', 'projectNameSys','projectAddress', 'projectInstallationDate', 'purchasingChannel', 'nameOfPurchasingChannelQcells', 'nameOfPurchasingChannelDistributor',
  'nameOfPurchasingChannelOther', 'nameOfPurchasingChannelSelect', 'projectAddressManual', 'pictureOfVmpp', 'irPicture', 'systemTypePv', 'isUploadProofDate',
  'projectPermissionDate', 'projectProductName' , 'projectInverterPower', 'serialNumbers', 'isUploadSerialNumber', 'invoiceForLaborReimbursement', 'infoBank', 'projectPowerClass',

  'description', 'title', 'customerAddress', 'installationDate', 'systemSize', 'productName', 'powerClass', 'submitterEmail', 'nameOfChanelOtherDistributor',
  'serials', 'regionPv', 'webAddress', 'pictureOfModuleLabel', 'pictureOfTheFrontSize', 'pictureOfTheBackSize',
  'closeUpPictureOfPhysicalDefect', 'siteModuleLayoutPlan', 'pictureOfOverallRoofArea', 'pictureOfWireManagement', 'proofOfDeliveryDocument',
  'pictureOfModuleImmediately','pictureOfVmpp' ,'irPicture', 'pictureOfVocReading',

  'nameOfPurchasingChannel', 'purchasingChannelContactName', 'emailPurchase', 'poNumber', 'coppyOfInvoice',

]
