import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import IconEdit from '../../../icons/edit_icon.svg';
import useStyles from './useStyles';
import _ from 'lodash';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import {useHistory} from 'react-router-dom';
import { projectService } from '../../../services';
import { decodeStringHtml } from '../../../utils/helpers';

function ViewUser({getValues, watch, text, control, register, errors, clearErrors, setValueEss, setValuePv, setValue}) {

  const [profile, setProfile] = useState({});
  const classes = useStyles();
  const checkedEmail = watch('checkedEmail');
  const history = useHistory();
  useEffect(() => {
    getProfile()
  }, [])

  async function getProfile() {
    const info = await projectService.getProfile();
    setValue('installerCity', _.get(info.data, 'city'))
    setValue('installerAddress1', _.get(info.data, 'address'))
    setValue('companyName', _.get(info.data, 'companyName'))
    setValue('installerState', _.get(info.data, 'state'))
    setValue('installerZipcode', _.get(info.data, 'zipCode'))
    setValue('installerAddress2', _.get(info.data, 'addressCont'))
    setValue('installerEmail', _.get(info.data, 'email'))
    setValue('installerName', _.get(info.data, 'fullName'))
    setValue('installerContact', _.get(info.data, 'fullName'))
    setValue('installerPhone', !!_.get(info.data, 'officeNumber') ? _.get(info.data, 'officeNumber') : _.get(info.data, 'mobileNumber') )
    setProfile(info.data)
  }


  const renderAddress = (array = []) => {
    const newArray = _.remove(array, value => !!value || value === 'select');
    return newArray.length > 0 ? _.join(newArray, ', ') : '';
  };

  useEffect(() => {
    setValueEss('checkedEmail', checkedEmail)
    setValuePv('checkedEmail', checkedEmail)
  }, [checkedEmail])

  return(
    <div className={classes.wrapUser}>
      <div className={classes.reviewSectionContainerCLaimInfoDetail}>
        <div className={classes.reviewSectionContentProjectDetailInfo}>
          <h6>User Info</h6>
        </div>
        <div>
          <Button onClick={() => window.open('/mypage/member/edit-profile', "_blank")} className={classes.editBtn}>
            <span>Edit</span>
            <img src={IconEdit} title='Edit'/>
          </Button>
        </div>

      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentClaim}>
          <h6>Company Name</h6>
          <p>{decodeStringHtml(_.get(profile, 'companyName'))}</p>
        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentClaim}>
          <h6>Address</h6>
          <p>
            {renderAddress([
              _.get(profile, 'address'),
              _.get(profile, 'addressCont'),
              _.get(profile, 'city'),
              _.get(profile, 'state'),
              _.get(profile, 'zipCode')
            ])}
          </p>
        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div
          style={{
            paddingTop: '13px',
            paddingBottom: '13px'
          }}
          className={classes.reviewSectionContentClaim}
        >
          <h6>Contact Name</h6>
          <p>
            {_.get(profile, 'fullName')}
          </p>
        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentClaim}>
          <h6>Email</h6>
          <p>
            {_.get(profile, 'email')}
          </p>
        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentClaim}>
          <h6>Phone Number</h6>
          <p>
            {!!_.get(profile, 'officeNumber') ? _.get(profile, 'officeNumber') : _.get(profile, 'mobileNumber')}
          </p>
        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentClaim} style={{alignItems: 'baseline'}}>
          <h6>Contact E-Mail</h6>
          <div>
            <FormControlLabel
              style={{ marginLeft: '-9px' }}
              control={
                <Controller
                  name={'checkedEmail'}
                  control={control}
                  render={(props) => (
                    <Checkbox
                      color={'primary'}
                      {...props}
                      checked={props.value}
                      onChange={(e) => {
                        if(!e.target.checked) {
                          clearErrors('submitterEmail')
                        }
                        props.onChange(e.target.checked)
                      }}
                    />
                  )}
                />
              }
              name={`checkedEmail`}
              inputRef={register}
              label={
                <p
                  style={{
                    fontFamily: 'Actual',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontSize: '15px',
                    lineHeight: '20px',
                    marginLeft: '2px'
                  }}
                >
                  {text}
                </p>
              }
            />
            {checkedEmail && <TextField
              fullWidth
              variant="outlined"
              name="submitterEmail"
              placeholder={'email@email.com'}
              className={classNames([classes.inputField], {
                [classes.errorInputField]: errors.submitterEmail
              })}
              inputRef={register}
            />}

          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewUser;
