import React from 'react';
import useStyles from './useStyles';
import PopOver from '../../../config/images/popover_icon.svg';
import {useHistory} from 'react-router-dom';



function ShowMessageMyProject() {

  const classes = useStyles();
  const history = useHistory();

  return(
    <div className={classes.wrapMessage}>
      <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/> <em className={classes.infoTextMessage}>This address is already used in another project. Please register a new project here, or go to <em onClick={() => history.push('/projects/myprojects')} className={classes.boldInfo}>MY PROJECT</em> to edit the existing project information.</em>
    </div>
  )
}

export default ShowMessageMyProject;
