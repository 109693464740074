import React, {useState, useEffect, useMemo} from 'react';
import Banner from '../../../common/components/Banner';
import Text from '../../../common/components/text';
import BannerImage from '../../../config/images/visual_area02_1.jpg';
import TitleAnimation from './../../../common/components/TitleAnimation';
import BreadCrumb2 from './../../../common/components/BreadCrumb2';
import Card from '../components/card';
import axios from 'axios';
import {baseUrl} from '../../../config/index';
import { useAuth } from '../../../contexts/auth';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import {Grid, TextField, MenuItem, withStyles} from '@material-ui/core';
import Pagination from '../../notice/components/Pagination';
import MenuIcon from '@material-ui/icons/Menu';
import {Link} from 'react-router-dom';
import { sendEventForDataHub, ACTION_FILTER_VIDEO_CONTENT } from './../../../utils/gaHelpers';
import DownloadIcon from '../../../config/images/ico_download_down.png';
import CardMobileDataHub from '../components/CardMobileDataHub';

const useStyles = makeStyles({
  root: {
    borderRadius: 0,
    color: '#fff'
  }
});

const Container = styled.div `
  width: 1200px;
  margin: auto;
  margin-top: 40px; 
  padding-bottom: 100px;
  
  @media only screen and (min-width: 480px) and (max-width: 1199px) {
    width: 95%;
  } 

  @media only screen and (max-width: 375px) {
    width: 300px;
  }
`;

const CardContainer = styled.div `
  width: 1300px;
  margin-left: -20px;
  
  @media only screen and (min-width: 480px) and (max-width: 1199px) {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media only screen and (max-width: 375px) {
    width: 300px;
    margin-left: 30px;
  }
`;

const MobileMenu = styled.div `
  width: 100%;
  height: 50px;
  text-align: center;
  background-color: #029ee3;
  cursor: pointer;
  position: absolute;
  z-index: 99;

  @media only screen and (min-width: 480px) {
    display: none;
  }
`

const SubMenu = styled.nav `
  display: ${props => props.mobileMenuOpen ? '' : 'none'};
  transition: all .5s;
`;

const StyledLi = styled.li `
  width: 90vw;
  background-color: #fff;
  height: 50px;
  padding-top: 14px;
  margin: auto;
  border: 1px #cccccc solid;
  border-top: none;
  z-index: 99;
  position: relative;
`;

export default function VideoContent() {
  const classes = useStyles();
  const user = useAuth();
  const [categories, setCategories] = useState([{id: 0, name: 'All'}]);
  const [videoContentData, setVideoContentData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [limit] = useState(8);
  const [offset, setOffset] = useState(0);
  const [filters] = useState({ limit, offset });
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();
  const [totalData, setTotalData] = useState();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [width, setWindowWidth] = useState(0);
  const [tab, setTab] = useState(1);

  const handleMenuClick = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  };

  const handleClick = (link) => {
    window.location = `/salestools/data-hub/${link}`
  }

  const getCategories = async () => {
    const {data} = await axios.get(`${baseUrl}/academy/types?categoryId=1`);

    setCategories([...categories, ...data]);
  }

  const getVideoContentData = async ({filters = {}}) => {
    const {data} = await axios.get(`${baseUrl}/academy/search?limit=${limit}&offset=${offset}`, {params: filters});
    setVideoContentData(data.data)
    setCount(Math.ceil(data.total / limit))
    setTotalData(data.total);

  }

  const handleChange = async (e) => {
    if (!user.authenticated) {
      alert('Please try again after logging in.');
      window.location.href = `/sso/login?url=${window.location.pathname}`;
    }
    sendEventForDataHub(ACTION_FILTER_VIDEO_CONTENT);
    setSelectedCategory(e.target.value);

    if (e.target.value === 0) {
      const {data} = await axios.get(`${baseUrl}/academy/search?limit=${limit}&offset=${offset}`);

      setCount(Math.ceil(data.total / limit))
      setVideoContentData(data.data)
    } else {
      const {data} = await axios.get(`${baseUrl}/academy/search?limit=${limit}&offset=${offset}&academyTypeId=${e.target.value}`);

      setCount(Math.ceil(data.total / limit))
      setVideoContentData(data.data)
    }
  };

  const handlePageChange = (e, currentPage) => {
    if (!user.authenticated) {
      alert('Please try again after logging in.');
      window.location.href = `/sso/login?url=${window.location.pathname}`;
    }
    setCategories([{id: 0, name: 'All'}]);
    setCurrentPage(currentPage);
    const newOffset = limit * currentPage - limit;
    setOffset(newOffset);
    const filter = {
      ...filters,
      offset: newOffset
    };
    getVideoContentData(filter);
  }

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () =>
      window.removeEventListener('resize', updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  function handleActiveTab(tab) {
    setTab(tab);
  }

  useEffect(()=> {
    getCategories();
    getVideoContentData(filters);
  }, [currentPage])

  return (
    <section
      style={{
        backgroundColor: 'var(--light-color, #ffffff)',
        position: 'relative'
      }}
    >
      <BreadCrumb2 items={['Sales Tools', 'Data Hub']}/>
      <Banner imageUrl={BannerImage} className="banner100" activeTab='video-content' tabs handleClick={handleClick}>
        <TitleAnimation>Data Hub</TitleAnimation>
      </Banner>
      <MobileMenu onClick={handleMenuClick}>
        <MenuIcon fontSize="40px" style={{marginTop: '10px', color: '#fff'}} />
      <SubMenu mobileMenuOpen={mobileMenuOpen} className="desktop1" style={{paddingTop: '10px'}}>
        <div className="nav_left">
          <ul className="submenu1">
            <StyledLi>
              <Link to="/salestools/data-hub/download-center">DOWNLOAD CENTER</Link>
            </StyledLi>
            <StyledLi>
              <Link to="/salestools/data-hub/video-content"><span className={'text-download-center-video'}>VIDEO CONTENT</span></Link>
            </StyledLi>

          </ul>
        </div>
      </SubMenu>
    </MobileMenu>
      {width > 480 && <Container>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Text size="h3" weight="medium" style={{color: '#000', marginTop: '20px', lineHeight: '26px', marginBottom: '0px', fontFamily: 'Actual, TradeGothic, "Malgun Gothic", "맑은 고딕", "Apple Gothic", arial, helvetica, sans-serif'}}>VIDEO CONTENT</Text>
        <TextField
        variant="outlined"
        size="small"
        InputProps={{ inputProps: { style: { padding: 10, borderRadius: 0, fontSize: '14px', borderColor: '#000', ":hover": {borderColor: '#000'} }, }, style: { borderRadius: 0, fontSize: '14px', borderColor: '#000' }, }}
        fullWidth
          id="standard-select-currency"
          select
          style={{width: '200px'}}
          value={selectedCategory}
          onChange={(e) => handleChange(e)}
        >
        {categories.map(category => {
          return (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          )
        })}
        </TextField>
      </Grid>
      <CardContainer>
        {videoContentData.length > 0 ? videoContentData.map(product => {
          return (
            <Card
              key={product.id}
              id={product.id}
              checked={product.checked}
              image={product.thumbnail.assetUrl}
              title={product.title}
              content={product.content}
              fileType={product.fileType}
              fileSize={product.fileSize}
              updatedAt={product.updatedAt}
              category={product.categoryName}
              language={product.language}
              detailCategory="video-content"
            />
          )
        }):
        <div
          style={{
            width: '90%',
            margin: 'auto',
            textAlign: 'center',
            paddingTop: '100px',
            paddingBottom: '100px',
            marginTop: '40px',
            marginBottom: '20px',
            borderTop: '2px solid #EEEEEE',
            borderBottom: '2px solid #EEEEEE'
          }}
        >
          <Text color="secondaryDark">
            No item is registered.
          </Text>
        </div>
        }
      </CardContainer>
    </Container>}
      {width <= 480 && <div className={'wrap-sub-tittle'}>
        <div className="title-download-center">
          <span>VIDEO CONTENT</span>
        </div>

        <div className="wrap-gird-info">

          <div className="wrap-action-list">
            <img onClick={() => handleActiveTab(1)} style={{ marginRight: 10 }} width={26} height={23}
                 src={tab === 1 ? 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621823039718-btn_thumb_list_on.png': 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621823039715-btn_thumb_list.png'}/>
            <img onClick={() => handleActiveTab(2)} width={26} height={23}
                 src={tab === 2 ? 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621823039719-btn_thumb_card_on.png' : 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621823039719-btn_thumb_card.png'}/>

          </div>

          <TextField
            variant="outlined"
            size="small"
            InputProps={{ inputProps: { style: { padding: 10, borderRadius: 0, fontSize: '14px', borderColor: '#000', ":hover": {borderColor: '#000'} }, }, style: { borderRadius: 0, fontSize: '14px', borderColor: '#000' }, }}
            fullWidth
            id="standard-select-currency"
            select
            style={{width: '200px'}}
            value={selectedCategory}
            onChange={(e) => handleChange(e)}
          >
            {categories.map(category => {
              return (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              )
            })}
          </TextField>

        </div>
        {width <= 480 && <div className="wrap-card-data-hub">
          {videoContentData?.map(product => {
            return <CardMobileDataHub type={2}  key={product.id} product={product}  id={product.id} tab={tab}/>
          })}
          {videoContentData?.length === 0 && <div className={'no-item'}>
            <span>No item is registered.</span>
          </div>}
        </div>}
      </div>}
    {videoContentData.length > 0 ? <Grid
      container
      justify="center"
      item
      xs={12}
      style={{ paddingBottom: '30px' }}
      alignItems="center"
      spacing={3}
    >
      {/* <Pagination page={currentPage} onChange={handlePageChange} count={count} variant="outlined" shape="rounded" /> */}
      <Pagination
        uploadsPerPage={8}
        totalUploads={totalData}
        pagenumber={currentPage}
        handlePageChange={handlePageChange}
      />
    </Grid> : false}

    </section>
  )
}
