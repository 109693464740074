import React, { useEffect, useState } from 'react';
import Banner from '../../../common/components/Banner';
import Text from '../../../common/components/text';
import BannerImage from '../../../config/images/visual_area02_1.jpg';
import TitleAnimation from './../../../common/components/TitleAnimation';
import BreadCrumb2 from './../../../common/components/BreadCrumb2';
import Card from '../components/card';
import axios from 'axios';
import { baseUrl } from '../../../config/index';
import styled from 'styled-components';
import DownloadIcon from '../../../config/images/ico_download_down.png';
import Pagination from '../../notice/components/Pagination';
import CheckBox from '../../../config/images/bg_checkbox.png';
import CheckBoxOn from '../../../config/images/bg_checkbox_on.png';
import { useAuth } from '../../../contexts/auth';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import './index.css';
import { Grid } from '@material-ui/core';
import { convertHTMLEntity, notiFailed } from '../../../utils/helpers';
import {
  ACTION_DOWNLOAD_SELECTED,
  ACTION_SEARCH_DOWNLOAD_CENTER,
  sendEventForDataHub
} from './../../../utils/gaHelpers';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardMobileDataHub from '../components/CardMobileDataHub';

const StyledButton = styled.button`
    min-width: 120px;
    border-radius: 20px;
    background: #666;
    color: #fff;
    transition: all 0.4s;
    border: none;
    display: inline-block;
    position: relative;
    height: 40px;
    padding: 0 28px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    :hover {
      background: #444;
      cursor: pointer;
    }
    
    @media only screen and (max-width: 480px) {
      margin: 0 auto;
      margin-top: 25px;
      margin-bottom: 40px;
    }
`;

const StyledDownloadButton = styled.button`
    min-width: 228px;
    height: 40px;
    background: #666;
    color: #fff;
    transition: all 0.4s;
    border: none;
    display: inline-block;
    position: relative;
    padding: 0 28px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    text-align: right;
    font-family: Actual, TradeGothic, "Malgun Gothic", "맑은 고딕", "Apple Gothic", arial, helvetica, sans-serif;
    
    :hover {
      background: #444;
      cursor: pointer;
    }

    @media only screen and (max-width: 1200px) {
      margin: 60px auto;
    }
  
    @media only screen and (max-width: 800px) {
      margin: 60px auto;
    }

    @media only screen and (max-width: 600px) {
      margin: 60px auto;
    }
`;

const StyledCategoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0px;
  column-gap: 40px;
  grid-auto-rows: minMax(5px, auto);
  height: 130px;
  margin-bottom: -23px;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minMax(5px, auto);
    grid-gap: 10px;
    column-gap: 40px;
  }

  @media only screen and (max-width: 800px) {
    margin-left: -40px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minMax(5px, auto);
    grid-gap: 10px;
    column-gap: 40px;
  }

  @media only screen and (max-width: 600px) {
    margin-left: -40px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minMax(5px, auto);
    grid-gap: 10px;
    column-gap: 10px;
  }
  
  @media only screen and (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minMax(1px, auto);
    height: 220px;
    margin :0 auto;
  }

  @media only screen and (max-width: 375px) {
    //margin-left: -10px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minMax(1px, auto);
    height: 220px;
    margin :0 auto;
    padding: 10px 20px;
  }
`;

const CardContainer = styled.div`
  width: 1300px;
  margin-left: -20px;
  
  @media only screen and (min-width: 480px) and (max-width: 1199px) {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media only screen and (max-width: 375px) {
    width: 300px;
    margin-left: 65px;
  }
`;

const MobileMenu = styled.div`
  width: 100%;
  height: 50px;
  text-align: center;
  background-color: #029ee3;
  cursor: pointer;
  position: absolute;
  z-index: 99;

  @media only screen and (min-width: 480px) {
    display: none;
  }
`;

const SubMenu = styled.nav`
  display: ${props => props.mobileMenuOpen ? '' : 'none'};
  transition: all .5s;
`;

const StyledLi = styled.li`
  width: 90vw;
  background-color: #fff;
  height: 50px;
  padding-top: 14px;
  margin: auto;
  border: 1px #cccccc solid;
  border-top : none;
  z-index: 99;
  position: relative;
`;

export default function DownloadCenter() {
  const user = useAuth();
  const [categories, setCategories] = useState([]);
  const [downloadCenterData, setDownloadCenterData] = useState([]);
  const [checked, setChecked] = useState([0]);
  const [limit] = useState(8);
  const [offset, setOffset] = useState(0);
  const [filters] = useState({ limit, offset });
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();
  const [selectedCards, setSelectedCards] = useState([]);
  const [totalData, setTotalData] = useState();
  const [width, setWindowWidth] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isShowTitle, setIsShowTitle] = useState(false);
  const [isRow, setIsRow] = useState(false);
  const [tab, setTab] = useState(1);

  const handleMenuClick = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleSelectedCard = (detailId) => {
    if (selectedCards.includes(detailId)) {
      let filteredChecked = selectedCards.filter(detail => detail !== detailId);
      setSelectedCards(filteredChecked);
    } else if (detailId !== 0) {
      let filteredChecked = selectedCards.filter(detail => detail !== 0);
      setSelectedCards([...filteredChecked, detailId]);
    } else {
      setSelectedCards([...selectedCards, detailId]);
    }
  };

  const handleClick = (link) => {
    window.location = `/salestools/data-hub/${link}`;
  };

  const getCategories = async () => {
    const { data } = await axios.get(`${baseUrl}/data-hub/categories`);

    setCategories(data);
  };

  const getCategoriesById = async (filters = {}) => {
    const { data } = await axios.get(`${baseUrl}/data-hub/search`, { params: filters });

    setCount(Math.ceil(data.total / limit));
    setDownloadCenterData(data.data);
    setTotalData(data.total);
  };

  const handleCheck = (categoryId) => {
    if (categoryId === 0) {
      setChecked([0]);
    } else if (checked.includes(categoryId)) {
      let filteredChecked = checked.filter(check => check !== categoryId || check === 0);
      if (filteredChecked.length) {
        setChecked(filteredChecked);
      } else {
        setChecked([0]);
      }
    } else if (categoryId !== 0) {
      let filteredChecked = checked.filter(check => check !== 0);
      setChecked([...filteredChecked, categoryId]);
    } else {
      setChecked([...checked, categoryId]);
    }
  };

  const filterSearch = async () => {
    if (!user.authenticated) {
      alert('Please try again after logging in.');
      window.location.href = `/sso/login?url=${window.location.pathname}`;
    }
    sendEventForDataHub(ACTION_SEARCH_DOWNLOAD_CENTER);
    if (checked.length === 0 || checked[0] === 0) {
      const { data } = await axios.get(`${baseUrl}/data-hub/search?limit=${limit}&offset=${offset}`);

      setCount(Math.ceil(data.total / limit));
      setDownloadCenterData(data.data);
      setTotalData(data.total);
    } else {
      const { data } = await axios.get(`${baseUrl}/data-hub/search?limit=${limit}&categoryId=${checked.join('-')}&offset=${offset}`);
      setCount(Math.ceil(data.total / limit));
      setTotalData(data.total);
      setDownloadCenterData(data.data);
    }

    setIsShowTitle(!isShowTitle)
  };

  const downloadAll = async files => {
    if (files.length === 0) return;
    const file = files.pop();
    const instance = axios.create();
    delete instance.defaults.headers.common["Authorization"];

    const { data } = await instance.get(file.url, { responseType: 'blob' });
    const fileUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', file.name.substr(file.name.lastIndexOf('/') + 1));
    document.body.appendChild(link);
    link.click();
    link.remove();

    setTimeout(function() {
      downloadAll(files);
    }, 1000);

  };

  const handleDownload = () => {
    if (!user.authenticated) {
      alert('Please try again after logging in.');
      return window.location.href = `/sso/login?url=${window.location.pathname}`;
    }

    sendEventForDataHub(ACTION_DOWNLOAD_SELECTED);

    const files = downloadCenterData.filter(d => selectedCards.includes(d.id)
      && _.get(d, 'attachment.assetUrl') && _.get(d, 'attachment.assetKey'))
      .map(d => ({ url: _.get(d, 'attachment.assetUrl'), name: _.get(d, 'attachment.assetKey') }));

    downloadAll(files);
  };

  const handlePageChange = (e, currentPage) => {
    if (!user.authenticated) {
      alert('Please try again after logging in.');
      window.location.href = `/sso/login?url=${window.location.pathname}`;
    }
    setCurrentPage(currentPage);
    const newOffset = limit * currentPage - limit;
    setOffset(newOffset);
    const filter = {
      ...filters,
      offset: newOffset,
      categoryId: checked.length === 1 && checked[0] === 0 ? '' : checked.join('-')
    };
    getCategoriesById(filter);
  }

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () =>
      window.removeEventListener('resize', updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  function handleActiveTab(tab) {
    setTab(tab);
  }

  useEffect(() => {
    getCategories();
    getCategoriesById(filters);
  }, []);

  useEffect(() => {
    if (width < 480) {
      setIsShowTitle(!isShowTitle);
    } else {
      setIsShowTitle(true);
    }
  }, [width]);

  return (
    <section
      style={{
        backgroundColor: 'var(--light-color, #ffffff)',
        position: 'relative'
      }}
    >
      <BreadCrumb2 items={['Sales Tools', 'Data Hub']}/>
      <Banner imageUrl={BannerImage} className="banner100" activeTab="download-center" tabs handleClick={handleClick}>
        <TitleAnimation>
          DATA HUB
        </TitleAnimation>
      </Banner>
      <MobileMenu onClick={handleMenuClick}>
        <MenuIcon fontSize="40px" style={{ marginTop: '10px', color: '#fff' }}/>
        <SubMenu mobileMenuOpen={mobileMenuOpen} className="desktop1" style={{ paddingTop: '10px' }}>
          <div className="nav_left">
            <ul className="submenu1">
              <StyledLi>
                <Link to="/salestools/data-hub/download-center"><span className={'text-download-center'}>DOWNLOAD CENTER</span></Link>
              </StyledLi>
              <StyledLi>
                <Link to="/salestools/data-hub/video-content">VIDEO CONTENT</Link>
              </StyledLi>
            </ul>
          </div>
        </SubMenu>
      </MobileMenu>
      <div className={'wrap-info-data-hub'}>
        {width > 480 &&
        <Text size="h3" weight="medium" style={{ marginTop: '60px', marginBottom: '30px' }}>DOWNLOAD CENTER</Text>}

        {isShowTitle && <Grid container className={'info-header-data-hub'}>
          <Grid container item xs={12}>
            <Text className={'text-category'}>Category</Text>
          </Grid>
          <StyledCategoryContainer>
            <Grid item style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
              {checked.includes(0) ?
                <img src={CheckBoxOn}
                     style={{ display: 'inline-block', color: '#009DDD', float: 'left', cursor: 'pointer' }}
                     onClick={() => handleCheck(0)}/> :
                <img src={CheckBox}
                     style={{ display: 'inline-block', color: '#009DDD', float: 'left', cursor: 'pointer' }}
                     onClick={() => handleCheck(0)}/>
              }
              {/* <Checkbox color="primary" checked={checked.includes(0)} onChange={() => handleCheck(0)} style={checked.includes(0) ? {display: 'inline-block', color: '#009DDD', float:'left'} : {display: 'inline-block', float: 'left'}} /> */}
              <Text weight="light" style={{ color: '#666666', marginTop: '-2px', paddingLeft: '30px' }}>All</Text>
            </Grid>
            {categories.map(category => {
              return (
                <Grid item style={{ whiteSpace: 'nowrap' }}>
                  {checked.includes(category.id) ?
                    <img src={CheckBoxOn}
                         style={{ display: 'inline-block', color: '#009DDD', float: 'left', cursor: 'pointer' }}
                         onClick={() => handleCheck(category.id)}/> :
                    <img src={CheckBox}
                         style={{ display: 'inline-block', color: '#009DDD', float: 'left', cursor: 'pointer' }}
                         onClick={() => handleCheck(category.id)}/>
                  }
                  {/* <Checkbox color="primary" checked={checked.includes(category.id)} onChange={() => handleCheck(category.id)} style={checked.includes(category.id) ? {display: 'inline-block',float:'left', color: '#009DDD'} : {display: 'inline-block',float:'left'}} /> */}
                  <Text weight="light" style={{
                    color: '#666666',
                    marginTop: '-2px',
                    paddingLeft: '30px',
                    fontSize: '16px'
                  }}>{category.name}</Text>
                </Grid>
              );
            })}


          </StyledCategoryContainer>
          {width <= 480 &&
          <Grid container item xs={12}><StyledButton onClick={filterSearch}>SEARCH</StyledButton></Grid>}
        </Grid>}

        {width > 480 &&
        <div className={'search-data-hub'}>
          <StyledButton onClick={filterSearch}>SEARCH</StyledButton>
        </div>}


        {width > 480 && <CardContainer>
          {downloadCenterData.length > 0 ? downloadCenterData.map(product => {
              return (
                <Card
                  key={product.id}
                  id={product.id}
                  product={product}
                  checked={product.checked}
                  image={_.get(product, 'image.assetUrl')}
                  title={convertHTMLEntity(product.title)}
                  content={product.content}
                  fileType={product.fileType}
                  fileSize={product.fileSize}
                  updatedAt={product.updatedAt}
                  category={product.categoryName}
                  language={product.language}
                  handleSelectedCard={handleSelectedCard}
                  detailCategory="download-center"
                  selectedCards={selectedCards}
                />
              );
            }) :
            <div
              style={{
                width: '90%',
                margin: 'auto',
                textAlign: 'center',
                paddingTop: '100px',
                paddingBottom: '100px',
                marginTop: '40px',
                marginBottom: '20px',
                borderTop: '2px solid #EEEEEE',
                borderBottom: '2px solid #EEEEEE'
              }}
            >
              <Text color="secondaryDark">
                No item is registered.
              </Text>
            </div>
          }
        </CardContainer>}

      </div>
      {width <= 480 && !isShowTitle && <div className="show-title-cate">Category</div>}
      {width <= 480 && !isShowTitle && <div onClick={() => setIsShowTitle(!isShowTitle)} className="show-title-list">
        <span className={'text-show'}>
          OPEN
          <img width={15} height={10} src={'https://qpartner.s3.us-west-1.amazonaws.com/media/1621853130195-ico_prod_search.png'}/>
        </span>
      </div>}
      {width <= 480 && isShowTitle && <div onClick={() => setIsShowTitle(!isShowTitle)} className="show-title-list">
        <span className={'text-show-close'}>
          CLOSE
          <img width={15} height={10} src={'https://qpartner.s3.us-west-1.amazonaws.com/media/1621853130195-ico_prod_search.png'}/>
        </span>
      </div>}

      {width <= 480 && <div className="wrap-sub-tittle">
        <div className="title-download-center">
          <span>DOWNLOAD CENTER</span>
        </div>

        <div className="wrap-gird-info">

          <div className="wrap-action-list">
            <img onClick={() => handleActiveTab(1)} style={{ marginRight: 10 }} width={26} height={23}
                 src={tab === 1 ? 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621823039718-btn_thumb_list_on.png': 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621823039715-btn_thumb_list.png'}/>
            <img onClick={() => handleActiveTab(2)} width={26} height={23}
                 src={tab === 2 ? 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621823039719-btn_thumb_card_on.png' : 'https://qpartner.s3.us-west-1.amazonaws.com/media/1621823039719-btn_thumb_card.png'}/>

          </div>
          <div className="btn-download">
            <button onClick={() => handleDownload()} className={'btn-select'}>
              <img src={DownloadIcon} style={{ position: 'absolute', top: 12, left: 9 }}/>
              Download Selected
            </button>
          </div>
        </div>
        {width <= 480 && <div className="wrap-card-data-hub">
          {downloadCenterData?.map(product => {
            return <CardMobileDataHub type={1} handleSelectedCard={handleSelectedCard} key={product.id} product={product} selectedCards={selectedCards} id={product.id} tab={tab}/>
          })}
          {downloadCenterData?.length === -0 && <div className={'no-item'}>
            <span>No item is registered.</span>
          </div>}
        </div>}
      </div>}






      {downloadCenterData.length > 0 ?
        <div className={'wrap-data-hub-pag'}>
          <Grid contianer>
            <Grid
              container
              item
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
              spacing={width >= 376 ? 3 : 1}
              style={{ paddingTop: '10px', textAlign: 'center', paddingBottom: '45px' }}
            >
              {/* <Pagination page={currentPage} onChange={handlePageChange} count={count} variant="outlined" shape="rounded" /> */}
              <Pagination
                uploadsPerPage={8}
                totalUploads={totalData}
                pagenumber={currentPage}
                handlePageChange={handlePageChange}
              />
            </Grid>
            {width > 480 && <Grid
              container
              item
              xs={12}
              direction="row"
              justify="flex-end"
              alignItems="center"
              style={{ marginTop: '-60px', paddingBottom: '100px', textAlign: 'center' }}
            >
              <StyledDownloadButton onClick={() => handleDownload()}><img src={DownloadIcon}
                                                                          style={{ position: 'absolute', top: 12 }}/>Download
                Selected</StyledDownloadButton>
            </Grid>}
          </Grid>
        </div>
        :
        false}
    </section>
  );
}
