import React from 'react';
import Popover from '../../../common/components/Popover';

function PopoverDetail() {

  return(
    <Popover>
      <p>Our claim process: Submitted {'>'} In Review {'>'} Approved {'>'} Processing {'>'} Closed</p>
      <p>*If <span style={{fontWeight: 400}}>"Requires More Information"</span>, you need to provide more evidence.</p>
      <p>*If <span style={{fontWeight: 400}}>"Remote Support in Progress"</span>, you will be notified when the issue has been resolved. </p>
    </Popover>
  )
}

export default PopoverDetail;
