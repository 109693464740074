import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Step = styled.li`
    font-family: Actual;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
    color: ${({ active }) => active ? '#009EE3': '#000000'};
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    line-height: 45px;
    padding-left: 15px;
    cursor: pointer;

    &:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
`;

const WrapMenu  = styled.ul`
  @media (max-width: 1366px) {
    display: none;
  }
`

const stepsEss = [
  { step: 0, text: '1. USER INFO' },
  { step: 1, text: '2. CLAIM INFO' },
  { step: 2, text: '3. ISSUE' },
  { step: 3, text: '4. SYSTEM INFO' },
  { step: 4, text: '5. SOFTWARE VERSION' },
  { step: 5, text: '6. FIELD DETAILS' },
  { step: 6, text: '7. PURCHASE INFO' },
];

const stepPV = [
  { step: 0, text: '1. USER INFO' },
  { step: 1, text: '2. CLAIM INFO' },
  { step: 2, text: '3. ISSUE' },
  { step: 3, text: '4. PURCHASE INFO' },
]

export default function NavBar({ currentStep, goToStep, type }) {

  const [steps, setStep] = useState(stepsEss);

  useEffect(() => {
    type === 2 ? setStep(stepPV) : setStep(stepsEss);
  }, [type]);


  return (
    <WrapMenu>
      {steps.map(step => (
        <Step key={step.step} active={step.step === currentStep} onClick={() => goToStep(step.step)}>{step.text}</Step>
      ))}
    </WrapMenu>
  )
};
