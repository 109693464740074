import React from 'react';
import SquareButton from '../../../common/components/button/MonthButton';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
import RightArrowIcon from '@material-ui/icons/ChevronRight';
import Text from '../../../common/components/text';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

const Pagination = ({ uploadsPerPage, totalUploads, pagenumber, handlePageChange }) => {
  let pageNumbers = [];
  let pageGroups = {};
  let currentPageGroup = [];
  let pageGroupCount = 0;

  for (let i = 1; i <= Math.ceil(totalUploads / uploadsPerPage); i++) {
    if (i === Math.ceil(totalUploads / uploadsPerPage)) {
      pageNumbers.push(i);
      pageGroupCount += 1;
      pageGroups[pageGroupCount] = pageNumbers;
      pageNumbers = [];
    } else if (pageNumbers.length === 10) {
      pageGroupCount += 1;
      pageGroups[pageGroupCount] = pageNumbers;
      pageNumbers = [];
    }
    pageNumbers.push(i);
    currentPageGroup = pageGroups[1];
  }

  const keys = Object.keys(pageGroups);
  for (const key of keys) {
    if (pageGroups[key].indexOf(+pagenumber) > -1) {
      currentPageGroup = pageGroups[key];
    }
  }

  return (
    <>
      <Grid item>

        <SquareButton
          square
          disabled={+pagenumber === 1}
          style={{ width: '30px', height: '30px', borderColor: '#cccccc' }}
        >
          <Link
            onClick={(e) => handlePageChange(e, pagenumber - 1)}
            style={{ textDecoration: 'none', color: '#8c8c8c' }}
          >
            <LeftArrowIcon/>
          </Link>
        </SquareButton>
      </Grid>
      {
        currentPageGroup?.map(number => (
          <Grid item onClick={(e) => handlePageChange(e, number)} style={{ cursor: 'pointer' }}>
            <li key={number} style={{ listStyle: 'none' }}>

              <Text
                style={{ fontSize: '14px' }}
                weight="regular"
                color={+pagenumber != +number ? '#000' : 'primaryLight'}
              >
                {number}
              </Text>

            </li>
          </Grid>
        ))
      }

      <Grid item>
        <SquareButton
          square
          disabled={pageNumbers[pageNumbers.length - 1] === +pagenumber}
          style={{ width: '30px', height: '30px', borderColor: '#cccccc' }}
        >
          <Link
            onClick={(e) => handlePageChange(e, pagenumber + 1)}
            style={{ textDecoration: 'none', color: '#8c8c8c' }}
          >
            <RightArrowIcon/>
          </Link>
        </SquareButton>
      </Grid>
    </>
  );
};

export default Pagination;
