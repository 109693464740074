import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import BreadCrumb2 from './../../common/components/BreadCrumb2';
import Banner from '../../common/components/Banner';
import MyPageBanner from '../../config/images/my-page-banner.jpg';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../../common/components/text';
import { Table, TableBody, TableRow, TableCell, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import axios from '../../utils/axios';
import config from '../../config';
import { notiFailed, convertHTMLEntity } from './../../utils/helpers.js';
import _ from 'lodash';
import CustomLoading from './../../common/components/CustomLoading';
import FormUpdateSubId from './MyAccountSubId';

const useStyles = makeStyles(theme => ({
  pageContainer: {
    paddingTop: '52px',
    width: '65%',
    margin: '0 auto',
    transition: 'all 0.4s',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      paddingTop: '25px'
    }
  },
  pageTitle: {
    color: '#000',
    fontSize: '26px',
    fontWeight: 500,
    lineHeight: '26px',
    textTransform: 'uppercase',
    marginBottom: '36px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px !important'
    }
  },
  table: {
    border: 'solid #000',
    borderWidth: '1px 0',
    '& td': {
      fontSize: '15px',
      fontFamily: 'Actual_Light',
      color: '#333',
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px'
      }
    },
    '& .MuiTableCell-root': {
      padding: '24px',
      wordBreak: 'break-word'
    }
  },
  editBtn: {
    height: '50px',
    padding: '0 40px',
    display: 'inline-block',
    lineHeight: '50px',
    background: '#666',
    color: '#fff',
    transition: 'all 0.4s',
    fontSize: '16px',
    fontWeight: 500
  },
  loadingIndicator: {
    textAlign: 'center'
  },
  tableCell: {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '50%'
    }
  },
  w9Download: {
    display: 'inline-block',
    position: 'relative',
    transition: 'color 0.4s',
    '&:after': {
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: 0,
      height: '1px',
      background: '#019ee3',
      content: '""',
      transition: 'width 0.6s 0.2s'
    },
    '&:hover': {
      cursor: 'pointer',
      color: '#019ee3'
    },
    '&:hover:after': {
      width: '100%'
    }
  },
  annualText: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    color: '#fff !important',
    backgroundColor: '#717171',
    display: 'inline-block',
    borderRadius: '22px',
    height: '16px',
    textAlign: 'center',
    width: '57px',
    lineHeight: '16px',
    marginLeft: '2px',
    marginRight: '2px'
  }
}));

const { baseUrl } = config;

export default function MyPage() {
  const classes = useStyles();
  const user = useAuth();
  const [userProfile, setUserProfile] = useState({});

  const [loading, setLoading] = useState(false);
  const getUserProfile = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseUrl}/users/profile`);
      setUserProfile(data);
    } catch (err) {
      notiFailed(`Something happened getting user profile`);
    } finally {
      setLoading(false);
    }
  };

  const renderSalesRegion = saleRegion => {
    if (!saleRegion) return;
    let list = saleRegion.split('|');
    let result = '';
    for (let i = 0; i < list.length; i++) {
      const info = list[i].split(':');
      if (i > 0) {
        result += ', ';
      }
      result += info[1].replace('^', ' ');
    }

    return result;
  };

  const renderAddress = (array = []) => {
    const newArray = _.remove(array, value => !!value);
    return newArray.length > 0 ? _.join(newArray, ', ') : '';
  };

  const renderSurvey = () => {
    let survey = null;
    switch (_.get(userProfile, 'survey')) {
      case 7:
        survey = 'Through event';
        break;
      case 2:
        survey = 'Through web ads';
        break;
      case 4:
        survey = 'Through other installers';
        break;
      case 6:
        survey = 'Through Q CELLS';
        break;
      case 1:
        survey = 'Through distribution';
        break;
      case 5:
        survey = 'Other';
        break;
      case 3:
        survey = 'Through social media'
        break;
    }

    const newArray = _.remove([survey, _.get(userProfile, 'surveyText')], value => !!value);
    return newArray.length > 0 ? _.join(newArray, ': ') : '';
  };

  const renderMainBusinessType = () => {
    let mainBusinessType = '';
    switch (userProfile.partnerType) {
    case 1:
      mainBusinessType = 'Contractor/EPC';
      break;
    case 2:
      mainBusinessType = 'Sales Agent';
      break;
    case 3:
      mainBusinessType = 'Distributor';
      break;
    }

    return mainBusinessType;
  };

  const handleDownloadW9Image = async assetUrl => {
    try {
      const instance = axios.create();
      delete instance.defaults.headers.common['Authorization'];
      const { data } = await instance.get(assetUrl, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', assetUrl.substr(assetUrl.lastIndexOf('/') + 1));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      notiFailed(`There was a problem download w9 image ${err.message}`);
    }
  };

  const renderInstallation = type => {
    const installation = _.find(_.get(userProfile, 'primaryProducts'), p => p.productType === type);

    if (installation) {
      return (
        <>
          <span style={{ display: 'block' }}>
            Installation volume (kW/<span className={classes.annualText}>Annual</span>): {_.get(installation, 'installationVolume')}
            {_.get(installation, 'installationVolumeData') 
            && ` - ${_.get(installation, 'installationVolumeData')} ${_.get(installation, 'installationVolumeUnit')}`}
          </span>
          <span style={{ display: 'block' }}>
            Primary products in use: {_.join(_.split(_.get(installation, 'productBrand'), '|'), ', ')}
          </span>
        </>
      )
    }

    return null;
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <>
      <Banner
        imageUrl={MyPageBanner}
        className="OrderHistory_Banner banner100"
      >
        <h1 className="title_animation OrderHistory_Title">My Account</h1>
        <BreadCrumb2 items={[`${user.firstName} ${user.lastName}`, 'My Account']}></BreadCrumb2>
      </Banner>

      <div className={classes.pageContainer}>

        {user.role !== 'Sub' && <>
          <Text className={classes.pageTitle}>
            Profile
          </Text>
          <Table className={classes.table}>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan="2" style={{ borderBottom: 'none' }}>
                    <div className={classes.loadingIndicator}>
                      <CustomLoading size={40} color="#00adef"></CustomLoading>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  <TableRow>
                    <TableCell className={classes.tableCell}>Company</TableCell>
                    <TableCell>{convertHTMLEntity(userProfile.companyName)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Main Business Type</TableCell>
                    <TableCell>{renderMainBusinessType()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Home page URL</TableCell>
                    <TableCell>{userProfile.website}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>{userProfile.fullName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>{userProfile.jobTitle}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>ID (E-mail)</TableCell>
                    <TableCell>{userProfile.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Main Contact Number</TableCell>
                    <TableCell>{userProfile.officeNumber ? userProfile.officeNumber : userProfile.mobileNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Business Address</TableCell>
                    <TableCell>
                      {renderAddress([
                        _.get(userProfile, 'address'),
                        _.get(userProfile, 'addressCont'),
                        _.get(userProfile, 'city'),
                        _.get(userProfile, 'state'),
                        _.get(userProfile, 'zipCode')
                      ])}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Shipping Address</TableCell>
                    <TableCell>
                      {_.get(userProfile, 'shippingAddress') ?
                        renderAddress([
                          _.get(userProfile, 'shippingAddress'),
                          _.get(userProfile, 'shippingAddress2'),
                          _.get(userProfile, 'shippingCity'),
                          _.get(userProfile, 'shippingState'),
                          _.get(userProfile, 'shippingZipCode')
                        ])
                        :
                        renderAddress([
                          _.get(userProfile, 'address'),
                          _.get(userProfile, 'addressCont'),
                          _.get(userProfile, 'city'),
                          _.get(userProfile, 'state'),
                          _.get(userProfile, 'zipCode')
                        ])}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Sales Region(s) - States</TableCell>
                    <TableCell>{renderSalesRegion(userProfile.salesArea)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Main Purchasing Channel (Name of Distributors)</TableCell>
                    <TableCell>{userProfile.purchaseChannel}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Module Installation Information</TableCell>
                    <TableCell>
                      {renderInstallation('module')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Battery Installation Information</TableCell>
                    <TableCell>
                      {renderInstallation('ess')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Tax ID</TableCell>
                    <TableCell>{userProfile.taxId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>W9</TableCell>
                    <TableCell>
                      {userProfile.w9 && (
                        <a className={classes.w9Download}
                           onClick={() => handleDownloadW9Image(userProfile.w9.assetUrl)}>
                          {userProfile.w9.assetUrl.substr(userProfile.w9.assetUrl.lastIndexOf('/') + 1)}
                        </a>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Note/Overview of Company</TableCell>
                    <TableCell>{userProfile.description}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Newsletter subscription</TableCell>
                    <TableCell>{userProfile.receptionYn === 'Y' ? 'Agree' : 'Deny'}</TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
          <Grid
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ textAlign: 'center', marginTop: '40px', marginBottom: '80px' }}
          >
            <Link to="/mypage/member/edit-profile" className={classes.editBtn}>Edit</Link>
          </Grid>
        </>}

        {user.role === 'Sub' && <FormUpdateSubId infoEdit={userProfile}/>}
      </div>
    </>
  );
}
