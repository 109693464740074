import React from 'react';
import PopOver from '../../../config/images/popover_icon.svg';
import useStyles from './useStyles';

function ShowMessageLine({content1, content2, content3}) {
  const classes = useStyles();

  return(
    <div className={classes.wrapMessageLine}>
      <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>
      <ul className={classes.wrapLine}>
        <li>{content1}</li>
        <li style={{paddingLeft: content1 && '10px'}}>{content2}</li>
        <li style={{paddingLeft: content1 && '10px'}}>{content3}</li>
      </ul>
    </div>
  )
}

export default ShowMessageLine;