import React from 'react';
import useStyles from './useStyles';
import PopOver from '../../../config/images/popover_icon.svg';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth';

function MessageShowProject(id, type = 1) {
  const user = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const typeBussiness = user.partnerType;

  return (
    <div className={classes.wrapMessage} style={{ marginTop: 0 }}>
      <img style={{ marginRight: 8, height: 20, opacity: 0.7 }} src={PopOver} />
      {typeBussiness !== 3 ? (
        <em className={classes.infoTextMessage}>
          If you cannot find your project, please check the{' '}
          <em
            onClick={() =>
              history.push({
                pathname: '/projects/myprojects',
                id
              })
            }
            className={classes.boldInfo}
          >
            PROJECT DETAILS
          </em>{' '}
          page if the Qcells product information is registered.
          {/* <em>{type === 1 ? 'panel' : 'battery'}</em>  */}
        </em>
      ) : (
        <em className={classes.infoTextMessage}>
          If you cannot find your project, please check the{' '}
          <em className={classes.boldInfo} style={{ cursor: 'default' }}>
            PROJECT DETAILS
          </em>{' '}
          page if the Qcells product information is registered.
        </em>
      )}
    </div>
  );
}

export default MessageShowProject;
