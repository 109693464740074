import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthRoute from '../AuthRoute';
import ClaimPage from '../claim';
import ClaimHistory from './CaimHistory';
import EditClaim from './EditClaim';
import PvPage from './pages/pv';
import EssPage from './pages/ess';
import EditClaimEss from './pages/EditClaimEss';
import EditClaimPV from './pages/EditClaimPv';
import AbilityFormClaim from './pages/AbilityFormClaim';
import AbilityFormEdit from './pages/AbilityFormClaimEdit';
import HowItWorks from './pages/HowItWorks';
import ClaimDetailPanel from './pages/ClaimDetailPanel';
import ClaimDetailBattery from './pages/ClaimDetailBattery';

export default function ClaimRouter() {
  return (
    <AuthRoute path="/claim">
      <Switch>
        <Route
          exact
          path="/claim/info/pv"
          component={PvPage}
        />
        <Route
          exact
          path="/claim/info/ess"
          component={EssPage}
        />
        <Route
          exact
          path="/claim/detail/:id/1"
          component={ClaimDetailPanel}
        />
        <Route
          exact
          path="/claim/detail/:id/2"
          component={ClaimDetailBattery}
        />
        <Route
          exact
          path="/claim/ess/:id"
          component={EditClaimEss}
        />
        <Route
          exact
          path="/claim/pv/:id"
          component={EditClaimPV}
        />

        <Route
          exact
          path="/claim/edit/:type/:step/:id"
          component={AbilityFormEdit}
        />
        <Route
          exact
          path="/claim/info"
          component={AbilityFormClaim}
        />
        <Route
          exact
          path="/claim/how-it-works"
          component={HowItWorks}
        />
      </Switch>
    </AuthRoute>
  );
}

