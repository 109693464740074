import React, { useEffect, useState } from 'react';
import Banner from '../../common/components/Banner';
import RegistrationBanner from '../../config/images/registration-banner.jpg';
import BreadCrumb2 from '../../common/components/BreadCrumb2';
import { useAuth } from '../../contexts/auth';
import { makeStyles } from '@material-ui/core';
import ReviewInfo from './components/ReviewInfo';
import SystemInfo from './components/SystemInfo';
import UserInfo from './components/UserInfo';
import ClaimInfo from './components/ClaimInfo';
import Issue from './components/Issue';
import SoftwareVersion from './components/SoftwareVersion';
import FieldDetail from './components/FieldDetail';
import NavBar from './components/NavBar';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import './index.css';
import PurchaseInfo from './components/PurchaseInfo';
import { notiFailed } from '../../utils/helpers';
import _ from 'lodash';
import IssuePV from './components/IssuePV';
import PurchaseInfoPv from './components/PurchaseInfoPv';
import styled from 'styled-components';
import RouteLeavingGuard from '../registration/RouteLeavingGuard';
import useExitPrompt from '../../hooks/useExitPrompt';
import {useHistory} from 'react-router-dom';
import { claimService } from '../../services';
import ProgressBar from './components/ProgressBar';

const useStyles = makeStyles(theme => ({
  containerClaim: {
    width: '80%',
    margin: '60px auto 70px auto',
    display: 'grid',
    gridTemplateColumns: '260px auto',
    gridColumnGap: '71px'
  },
  [theme.breakpoints.between('xs', 'md')] : {
    width: '90%',
    gridTemplateColumns: 'auto',
    gridColumnGap: '0px'
  }
}));

const ContainerClaimForm = styled.div `
    width: 80%;
    margin: 36px auto 70px auto;
    display: grid;
    //grid-template-columns: 260px auto;
    //grid-column-gap: 71px;
    
    @media (max-width: 1366px) {
      grid-template-columns: auto;
      grid-column-gap: 0px;
      width: 90%;
    }
`

const schemaEss = yup.object().shape({
  installer_name : yup.string().required('Name is required'),
  installer_address1 : yup.string().required('Address is required'),
  installer_address2 : yup.string().required('Address2 is required'),
  installer_city : yup.string().required('City is required'),
  installer_state : yup.string().notOneOf(['select'], 'State is required'),
  installer_contact_name : yup.string().required('Contact name is required'),
  installer_zipcode : yup.string().required('Zipcode is required'),
  email: yup.string().required('Email is required').matches(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/, 'Invalid email address'),
  installer_phone: yup.string().required("Contact number is required").matches(/^[0-9]*$/, "This entry can only contain numbers (e.g., 9497774444)."),

  claim_type : yup.string().notOneOf(['select'], 'Claim Type is required'),
  info_claim_type : yup.string().notOneOf(['select'], 'Info Claim Type is required'),

  description : yup.string().required('Description of your issue'),
  other_evidence : yup.string().required('Other evidence is required'),

  project_sys : yup.string().notOneOf(['select'], 'Project is required'),
  project_name_sys: yup.string().required('Project Name is required'),
  address_sys : yup.string().required('Address is required'),
  installation_date_sys : yup.mixed().nullable().notOneOf([null], 'Installation date is required'),
  product_name_sys : yup.string().required('Product Name is required'),
  system_type_sys : yup.string().notOneOf(['select'], 'System Type is required'),
  inverter_serial_number_sys : yup.string().required('Inverter serial number is required'),
  name_sys : yup.string().required('Name is required'),
  inverter_power_sys : yup.string().required('Inverter power is required'),
  pocket_wifi_sys : yup.string().required('Pocket WiFi SW is required'),
  battery_serial_number_sys : yup.string().required('Batteries Serial Number is required'),
  battery_type_sys : yup.string().required('Battery Type is required'),
  number_of_battery : yup.string().required('Number of batteries is required'),



  total_pv_power_installed : yup.string().required('Total PV Power installed is required'),
  distributor_name : yup.string().required('Distributor Name is required'),

});

const schemaPV = yup.object().shape({
  installer_name : yup.string().required('Name is required'),
  installer_address1 : yup.string().required('Address is required'),
  installer_address2 : yup.string().required('Address2 is required'),
  installer_city : yup.string().required('City is required'),
  installer_zipcode : yup.string().required('Zipcode is required'),
  installer_state : yup.string().notOneOf(['select'], 'State is required'),
  installer_contact_name : yup.string().required('Contact name is required'),
  email: yup.string().required('Email is required').matches(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/, 'Invalid email address'),
  installer_phone: yup.string().required("Contact number is required").matches(/^[0-9]*$/, "This entry can only contain numbers (e.g., 9497774444)."),

  claim_type : yup.string().notOneOf(['select'], 'Claim Type is required'),
  info_claim_type : yup.string().notOneOf(['select'], 'Info Claim Type is required'),

  description : yup.string().required('Description of your issue is required'),
  type_project_name : yup.string().notOneOf(['select'], 'Project Type is required'),
  project_name_pv : yup.string().required('Project name is required'),
  address_pv : yup.string().required('Address is required'),
  installation_date_pv :yup.mixed().nullable().notOneOf([null], 'Installation date is required'),
  comission_date_pv : yup.mixed().nullable().notOneOf([null], 'Comission date is required'),
  product_name_pv : yup.string().required('Product Name is required'),
  power_class_of_module_pv : yup.string().required('Power class of module is required'),
  affected_module_serial_number : yup.string().notOneOf(['select'], 'Affected module serial numbers is required'),
  region_pv : yup.string().notOneOf(['select'], 'Region is required'),
  web_address_pv : yup.string().required('Web Address is required'),
  login_id_pv : yup.string().required('LogIn Id is required'),
  password_pv : yup.string().required('Password is required'),
  site_module_layout_pv : yup.string().notOneOf(['select'], 'Site module layout plan showing damaged module locations is required'),
  copy_of_pod_pv : yup.string().required('Copy of POD (proof of delivery) doc is required'),
  inverter_type_pv : yup.string().notOneOf(['select'], 'Inverter Type is required'),

  distributor_name_pv : yup.string().required('Distributor Name is required'),
  distributor_city_pv : yup.string().required('Distributor City is required'),
  distributor_contact_name_pv : yup.string().required('Distributor Contact Name is required'),
  distributor_email_pv : yup.string().required('Distributor E-mail is required'),
  distributor_phone_number : yup.string().required('Distributor Phone Number is required'),
  copy_of_invoice_pv : yup.string().required('Copy of Invoice/Receipt is required')

})

const keyEss = ['installer_name', 'installer_address1', 'installer_address2', 'installer_city',
  'installer_state','installer_zipcode', 'installer_contact_name', 'email', 'installer_phone', 'claim_type',
  'info_claim_type', 'description', 'other_evidence', 'project_sys', 'project_name_sys', 'address_sys', 'installation_date_sys' ,'product_name_sys', 'system_type_sys', 'inverter_serial_number_sys',
  'name_sys', 'inverter_power_sys', 'pocket_wifi_sys' ,'battery_serial_number_sys', 'battery_type_sys', 'number_of_battery',
  'total_pv_power_installed', 'distributor_name'];


const keyPv = [
  'installer_name' , 'installer_address1' , 'installer_address2', 'installer_city', 'installer_state', 'installer_zipcode', 'installer_contact_name' , 'email', 'installer_phone',
  'claim_type', 'info_claim_type',

  'description', 'type_project_name', 'project_name_pv', 'address_pv', 'installation_date_pv', 'comission_date_pv', 'product_name_pv', 'power_class_of_module_pv',
  'affected_module_serial_number', 'region_pv', 'web_address_pv', 'login_id_pv', 'password_pv', 'site_module_layout_pv', 'copy_of_pod_pv', 'inverter_type_pv',

  'distributor_name_pv', 'distributor_city_pv', 'distributor_contact_name_pv', 'distributor_email_pv', 'distributor_phone_number', 'copy_of_invoice_pv'

]

const defaultStateEss = {

  description : '',
  error_log : null,
  other_evidence : null,

  project_sys : 'select',
  project_name_sys : '',
  address_sys : '',
  installation_date_sys : null,
  product_name_sys : '',
  system_type_sys : 'select',
  inverter_serial_number_sys : '',
  name_sys : '',
  inverter_power_sys : '',
  pocket_wifi_sys : '',
  battery_serial_number_sys : '',
  battery_type_sys : '',
  number_of_battery : '',

  dsp1_sw_version : '',
  arm_sw_version : '',
  battery_sw_version : '',

  pv1_string_voltage : '',
  pv2_string_voltage : '',
  pv3_string_voltage : '',
  pv4_string_voltage : '',
  load_l1_voltage : '',
  load_l2_voltage : '',
  critical_connected : '',
  total_pv_power_installed : '',
  operational_mode_use : 'select',

  distributor_name : '',
  distributor_city : '',
  po_number : '',
  purchase_date : null

};

const defaultStatePv = {

  purchase_date : null,
  description : '',
  type_project_name : 'select',
  project_name_pv : '',
  address_pv : '',
  installation_date_pv : null,
  comission_date_pv : null,
  system_size : '',
  product_name_pv : '',
  power_class_of_module_pv : '',
  affected_module_serial_number : 'select',
  picture_of_module_label: null,
  region_pv : 'select',
  picture_of_the_front : null,
  picture_of_the_backside : null,
  web_address_pv : '',
  login_id_pv : '',
  password_pv : '',
  picture_of_voc_reading : '',
  picture_of_physical_defect : '',
  site_module_layout_pv : 'select',
  picture_of_overall_roof : '',
  picture_of_wire_management : '',
  copy_of_pod_pv : '',
  picture_of_module_as_it : '',
  inverter_type_pv : 'select',
  picture_of_vmpp : '',
  ir_picture : '',

  distributor_name_pv : '',
  distributor_city_pv : '',
  distributor_contact_name_pv : '',
  distributor_email_pv : '',
  distributor_phone_number : '',
  qcell_sales_representative : '',
  po_number : '',
  purchase_date_info : null,
  copy_of_invoice_pv : null
}


function ClaimPage({type}) {
  const classes = useStyles();
  const user = useAuth();
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(0);
  const [isReview, setIsReview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schema, setChema] = useState(schemaEss);
  const [fileOtherEvidence, setFileEvidence] = useState([]);
  const [fileCopyInvoice, setFileCopyInvoice] = useState(null);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const [keyError, setKeyError] = useState(keyEss);
  const [stateDefault, setStateDefault] = useState(defaultStateEss);

  const [urlImagePictureOfModule, setUrlImagePictureOfModule] = useState(null);
  const [urlImagePictureOfFrontSide, setUrlImagePictureOfFrontSide] = useState(null);
  const [urlImagePictureBackSideOfModule, setUrlImagePictureBackSideOfModule] = useState(null);
  const [urlImagePictureVocReading, setUrlImagePictureVocReading] = useState(null);
  const [urlImagePicturePhysicalDefect, setUrlImagePicturePhysicalDefect] = useState(null);
  const [urlImagePictureOfOverall, setUrlImagePictureOfOverall] = useState(null);
  const [urlImagePictureOfWire, setUrlImagePictureOfWire] = useState(null);
  const [urlImagePictureOfModuleCustomer, setUrlImagePictureOfModuleCustomer] = useState(null);
  const [urlImagePictureOfVmpp, setUrlImagePictureOfVmpp] = useState(null);
  const [urlImagePictureIr, setUrlImagePictureIr] = useState(null);

  const { register, handleSubmit, errors, control, watch, setValue, getValues, formState, clearErrors } = useForm({
    resolver : yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: false,
    enableReinitialize: true,
    defaultValues: {
      email : _.get(user, 'email'),
      installer_name : _.get(user , 'fullName'),
      installer_contact_name : _.get(user , 'fullName'),
      installer_city : '',
      installer_address1 : '',
      installer_address2 : '',
      installer_state : 'select',
      installer_zipcode : '',
      installer_contact : '',
      installer_phone : '',
      checked_email : false,

      claim_type : 'select',
      info_claim_type : 'select',
      ...stateDefault,
      installation_date_pv : null,
      comission_date_pv : null,
      affected_module_serial_number : 'select',
      region_pv : 'select',
      site_module_layout_pv : 'select',
      inverter_type_pv : 'select',
      purchase_date_info : null
    }
  });

  useEffect(() => {
    setValue('installer_city', _.get(user, 'infoUser.city'))
    setValue('installer_address1', _.get(user, 'infoUser.address'))
    setValue('installer_state', _.get(user, 'infoUser.state'))
    setValue('installer_zipcode', _.get(user, 'infoUser.zipCode'))
    setValue('installer_address2', _.get(user, 'infoUser.addressCont'))
    setValue('installer_phone', _.get(user, 'infoUser.officeNumber') || _.get(user, 'infoUser.mobileNumber') )


  }, [_.get(user, 'infoUser.city')])

  useEffect(() => {
    if(type !== 2) {
      setChema(schemaEss)
      setKeyError(keyEss)
      setStateDefault(defaultStateEss)
    }else {
      setChema(schemaPV);
      setKeyError(keyPv);
      setStateDefault(defaultStatePv);
    }
  }, [type])

  const showErrorMessage = (errors, errorKeys = []) => {
    let message = '';

    for (let i = 0; i < errorKeys.length; i++) {
      if (errors[errorKeys[i]]) {
        message = errors[errorKeys[i]].message;
        break;
      }
    }

    return notiFailed(message);
  };

  const onSubmitFailed = async errors => {
    showErrorMessage(errors, keyError)
  };


  const goBack = () => setCurrentStep(currentStep - 1);
  const next = () => setCurrentStep(currentStep + 1);
  const handleGoToReview = () => {
    handleSubmit(() => setIsReview(true), onSubmitFailed)();
  };

  const onSubmit = async data => {
    // console.log(data, 'info');
  }

  const renderStepClaim = (type) => {

    switch(currentStep) {
      case 0:
        return (
          <UserInfo
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
          />
        )
      case 1:
        return (
          <ClaimInfo
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
            type={type}
            setValue={setValue}
          />
        )
      case 2:
        if(type !== 2) {
          return (
            <Issue
              goBack={goBack}
              next={next}
              register={register}
              control={control}
              errors={errors}
              type={type}
              fileOtherEvidence={fileOtherEvidence}
              setFileEvidence={setFileEvidence}
              setValue={setValue}
              clearErrors={clearErrors}
            />
          )
        }else {
          return (
            <IssuePV
              goBack={goBack}
              next={next}
              register={register}
              control={control}
              setValue={setValue}
              watch={watch}
              urlImagePictureVocReading={urlImagePictureVocReading}
              setUrlImagePictureVocReading={setUrlImagePictureVocReading}
              urlImagePictureIr={urlImagePictureIr}
              setUrlImagePictureIr={setUrlImagePictureIr}
              urlImagePictureOfVmpp={urlImagePictureOfVmpp}
              setUrlImagePictureOfVmpp={setUrlImagePictureOfVmpp}
              urlImagePictureOfModuleCustomer={urlImagePictureOfModuleCustomer}
              setUrlImagePictureOfModuleCustomer={setUrlImagePictureOfModuleCustomer}
              urlImagePictureOfWire={urlImagePictureOfWire}
              setUrlImagePictureOfWire={setUrlImagePictureOfWire}
              urlImagePictureOfOverall={urlImagePictureOfOverall}
              setUrlImagePictureOfOverall={setUrlImagePictureOfOverall}
              urlImagePicturePhysicalDefect={urlImagePicturePhysicalDefect}
              setUrlImagePicturePhysicalDefect={setUrlImagePicturePhysicalDefect}
              urlImagePictureBackSideOfModule={urlImagePictureBackSideOfModule}
              setUrlImagePictureBackSideOfModule={setUrlImagePictureBackSideOfModule}
              urlImagePictureOfFrontSide={urlImagePictureOfFrontSide}
              setUrlImagePictureOfFrontSide={setUrlImagePictureOfFrontSide}
              urlImagePictureOfModule={urlImagePictureOfModule}
              setUrlImagePictureOfModule={setUrlImagePictureOfModule}
              errors={errors}
              type={type}
            />
          )
        }

      case 3:
        if(type !== 2) {
          return (
            <SystemInfo
              goBack={goBack}
              next={next}
              register={register}
              control={control}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
          )
        }else {
          return (
            <PurchaseInfoPv
              goBack={goBack}
              next={next}
              register={register}
              control={control}
              fileCopyInvoice={fileCopyInvoice}
              setFileCopyInvoice={setFileCopyInvoice}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              handleGoToReview={handleGoToReview}
            />
          )
        }
      case 4:
        return (
          <SoftwareVersion
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
          />
        )
      case 5:
        return (
          <FieldDetail
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
          />
        )
      case 6:
        return (
          <PurchaseInfo
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
            handleGoToReview={handleGoToReview}
            watch={watch}
          />
        )
      default:
        return (
          <div>sss</div>
        )
    }
  }

  const goToStep = step => setCurrentStep(step);

  return(
    <>
      <Banner
        imageUrl={RegistrationBanner}
        className="OrderHistory_Banner banner100"
      >
        <h1 className="title_animation OrderHistory_Title">claim submission</h1>
        <BreadCrumb2 items={['Project Registration']}/>
      </Banner>
      <ProgressBar currentStep={currentStep} goToStep={goToStep} isEnableClick={true} type={type}/>
      {!isReview ? (
        <ContainerClaimForm>
          {renderStepClaim(type)}
        </ContainerClaimForm>

      ) : (
        <ReviewInfo
          loading={loading}
          urlImagePictureOfModule={urlImagePictureOfModule}
          urlImagePictureOfFrontSide={urlImagePictureOfFrontSide}
          urlImagePictureBackSideOfModule={urlImagePictureBackSideOfModule}
          urlImagePictureVocReading={urlImagePictureVocReading}
          urlImagePicturePhysicalDefect={urlImagePicturePhysicalDefect}
          urlImagePictureOfOverall={urlImagePictureOfOverall}
          urlImagePictureOfWire={urlImagePictureOfWire}
          urlImagePictureOfModuleCustomer={urlImagePictureOfModuleCustomer}
          urlImagePictureOfVmpp={urlImagePictureOfVmpp}
          urlImagePictureIr={urlImagePictureIr}
          goBack={goBack}
          goToStep={goToStep}
          getValues={getValues}
          type={type}
          watch={watch}
          setIsReview={() => setIsReview(false)}
          handleSubmit={handleSubmit(onSubmit, onSubmitFailed)}
        />
      )}
    </>
  )
}


export default ClaimPage;
