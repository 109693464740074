import React from 'react';
import styled from 'styled-components';
import useDidMount from '../../../hooks/useDidMount';
import Text from '../text';

const Button = styled.button`
  ${({ theme, disabled }) => `background: ${theme.colors.light};
  border: 1px solid ${theme.colors.secondaryLight};
  border-radius: 3px;
  font-weight: 500;
  font-size: var(--font-size-4);
  color: ${disabled && theme.colors.secondaryLight};
  cursor: ${!disabled && 'pointer'};

  ${!disabled &&
    `:hover {
    background: ${theme.colors.secondaryLight};
  }`}}`}
`;

const Number = styled(Text)`
  display: inline-block;
  width: ${({ digits }) => digits * 10}px;
  margin: 0 0.5rem;
  font-weight: 500;
  font-size: var(--font-size-4);
`;

/**
 * @name Counter
 * @param value Current counter value
 * @param min minimum counter value
 * @param max Maximum counter value
 * @param defaultValue default value if no value is passed
 * @param onChange Callback function with counter value when it changes
 */
export default function Counter({
  isCallApi,
  style,
  className,
  value,
  min = 0,
  max,
  defaultValue = 0,
  preIncreaseCheck = true,
  onIncrease,
  onDecrease,
  onChange
}) {
  const didMount = useDidMount();
  const [count, setCount] = React.useState(value ?? defaultValue);
  const [loading, set_loading] = React.useState(false);

  React.useEffect(() => {
    if (didMount) {
      // eslint-disable-next-line no-unused-expressions
      onChange?.(count);
    }
  }, [count]); // do not put didMount as dependency

  React.useEffect(() => {
    setCount(value);
  }, [value]);

  const increment = () => {
    if (max === undefined || count < max) {
      if (isCallApi) {
        set_loading(true);
      }
      onIncrease && onIncrease();
      if (isCallApi) {
        setTimeout(() => {
          setCount(prev => prev + 1);
        }, 10);
      } else {
        setCount(prev => prev + 1);
      }
    }
  };

  const decrement = () => {
    if (min === undefined || count > min) {
      if (isCallApi) {
        set_loading(true);
      }
      onDecrease && onDecrease(count);
      if (isCallApi) {
        setTimeout(() => {
          setCount(prev => prev - 1);
        }, 10);
      } else {
        setCount(prev => prev - 1);
      }
    }
  };

  return (
    <div
      style={{ display: 'inline-block', whiteSpace: 'nowrap', ...style }}
      className={className}
    >
      <Button disabled={count === min || loading} onClick={decrement}>
        -
      </Button>
      <Number as="span">{count}</Number>
      <Button
        disabled={count === max || !preIncreaseCheck || loading}
        onClick={increment}
      >
        +
      </Button>
    </div>
  );
}
