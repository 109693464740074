import React from 'react';
import { Switch, Route } from 'react-router-dom';
import DownloadCenter from './pages/downloadCenter';
import VideoContent from './pages/videoContent';
import Detail from './pages/detail';

export default function Modules() {
  return (
      <Switch>
        <Route
          exact
          path="/salestools/data-hub/download-center"
          component={DownloadCenter}
        />
        <Route
          exact
          path="/salestools/data-hub/video-content"
          component={VideoContent}
        />
        <Route
          exact
          path="/salestools/data-hub/:detailCategory/:detailId"
          component={Detail}
        />
      </Switch>
  );
}
