import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonBase from '@material-ui/core/ButtonBase';

const WEIGHT_MAP = {
  light: 100,
  regular: 400,
  medium: 500,
  bold: 700
};

const StyledButton = styled(ButtonBase)(
  ({ theme: { colors } }) => `
  color: #212121;
  text-decoration: underline;
  font-weight: ${({ weight }) =>
    weight in WEIGHT_MAP ? WEIGHT_MAP[weight] : weight};
  &:hover {
    color: ${colors.primaryLight};
  }
`
);

/**
 * @name TextButton
 * @description A plain button with just text
 * @param {node} children The content inside the button. ie "View More"
 * @implements {ButtonBase}
 */
const TextButton = ({ children, weight, ...props }) => {
  return (
    <StyledButton weight={weight} disableRipple disableTouchRipple {...props}>
      {children}
    </StyledButton>
  );
};

TextButton.propTypes = {
  children: PropTypes.node.isRequired,
  ...ButtonBase.propTypes
};

export default TextButton;
