import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ProgressBar from './ProgressBar';
import BreadCrumb2 from './../../common/components/BreadCrumb2';
import Banner from '../../common/components/Banner';
import RegistrationBanner from '../../config/images/registration-banner.jpg';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, TextField, FormControlLabel, Checkbox, Button, Select, MenuItem, Radio, TextareaAutosize, RadioGroup, Modal, Paper, IconButton, Table, TableBody, TableRow, TableCell, InputAdornment } from '@material-ui/core';
import CheckBox from '../../config/images/bg_checkbox.svg';
import CheckBoxOn from '../../config/images/bg_checkbox_on.svg';
import CustomButton from '../../common/components/button';
import config from '../../config';
import axios from '../../utils/axios';
import { notiFailed, notiSuccess, convertHTMLEntity } from './../../utils/helpers.js';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import RadioIcon from '../../config/images/bg_radio.svg';
import RadioOnIcon from '../../config/images/bg_radio_on.svg';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import CustomLoading from '../../common/components/CustomLoading';
import { useHistory, Link, useLocation } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Text from '../../common/components/text';
import RouteLeavingGuard from './RouteLeavingGuard';
import useExitPrompt from './../../hooks/useExitPrompt';
import NumberFormat from 'react-number-format';
import { MODULE_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT, ESS_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT } from '../../utils/constants';
import IconSelect from '../../icons/arrow.svg';
import IconEdit from '../../icons/edit_icon.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import AsyncEmailValidationChecker from './AsyncEmailValidatationChecker';
import AsyncCompanyValidationChecker from './AsyncCompanyValidationChecker';
import { sendEventForRegister, ACTION_SUBMIT_REGISTER } from './../../utils/gaHelpers';

function requiredWith(ref, expectedValue, msg) {
  return this.test({
    name: 'requiredWith',
    exclusive: false,
    message: msg,
    test: function(value) {
      if (this.resolve(ref) === expectedValue) {
          return !!value;
      }

      return true;
    }
  });
}

function validateInstallationVolumeValue(ref, type, msg) {
  return this.test({
    name: 'validateInstallationVolumeValue',
    exclusive: false,
    message: msg,
    test: function(value) {
      const selectedInstallationVolume = this.resolve(ref);
      const installationVolume = this.options.context.primaryProducts[type].find(o => o.id === +selectedInstallationVolume);

      if (installationVolume) {
        if (installationVolume.min && installationVolume.max){
          return value >= installationVolume.min && value <= installationVolume.max;
        } else if (installationVolume.min) {
          return value >= installationVolume.min;
        } else if (installationVolume.max) {
          return value <= installationVolume.max && value >= 0;
        }
      }

      return true;
    }
  });
}

yup.addMethod(yup.mixed, 'requiredWith', requiredWith);
yup.addMethod(yup.mixed, 'validateInstallationVolumeValue', validateInstallationVolumeValue);

const PageContainer = styled.div`
  ${({ theme }) => `
    width: 72.5%;
    margin: auto;

  ${theme.breakpoints.down('md')} {
    width: 90%;  
    margin-bottom: 160px;
  }
  `};
`;

const ReactSelectDropdownIndicator = () => {
  return (
    <div style={{ marginRight: '15px' }}>
      <img src={IconSelect} width="14"/>
    </div>
  )
}

const asyncEmailValidationChecker = new AsyncEmailValidationChecker();
const asyncCompanyValidationChecker = new AsyncCompanyValidationChecker();

const useStyles = makeStyles(theme => ({
  pageSubtitle: {
      textAlign: 'center',
      marginTop: '20px',
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '24px',
      textTransform: 'none',
      fontFamily: 'Actual_Light',
      [theme.breakpoints.down('xs')]: {
          display: 'none'
      }
  },
  icoEs: {
    position: 'relative',
    fontSize: '15px',
    fontFamily: 'Actual_Light',
    color: '#333',
    '&::after': {
        display: 'block',
        position: 'absolute',
        left: '1px',
        bottom: '-4px',
        width: '14px',
        height: '1px',
        background: '#009ee3',
        content: '""'
    }
  },
  inputField: {
    '& .MuiOutlinedInput-notchedOutline': {
      transition: 'background 0.3s, border 0.3s',
      borderColor: '#C4C4C4'
    },
    '& .MuiInputBase-input': {
      color: '#333333',
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#BDBDBD',
      opacity: 1
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      fontSize: '14px',
      color: '#333333',
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '17.5px'
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 15px'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: '#C4C4C4 !important'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000 !important',
      borderWidth: '1px'
    }
  },
  selectField: {
    borderRadius: 0,
    '& .MuiSelect-outlined': {
      borderRadius: 0
    },
    '& .MuiOutlinedInput-input': {
      padding: '13.5px 14px'
    },
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23) !important'
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000 !important',
      borderWidth: '1px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      transition: 'background 0.3s, border 0.3s'
    }
  },
  checkBox: {
    background: `url(${CheckBox}) center /100% 100% no-repeat`,
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    transition: 'background 0.4s'
  },
  checkBoxOn: {
    background: `url(${CheckBoxOn}) center /100% 100% no-repeat`,
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    transition: 'background 0.4s'
  },
  sectionContainer: {
    display: 'grid',
    gridTemplateColumns: '2fr 5fr 5fr',
    gridGap: '25px',
    alignContent: 'center',
    marginBottom: '22px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  sectionHeaderContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  addressSection: {
    gridColumn: 'span 2',
    '& p': {
      fontFamily: 'Actual_Light'
    }
  },
  addressSection1: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '25px',
    marginBottom: '22px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  addressSection2: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '28px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  multiSelect: {
    '& .select__control': {
        borderRadius: 0,
        padding: '4px',
        transition: 'borderColor 0.4s',
        minHeight: '46px',
        borderColor: '#C4C4C4',
        paddingLeft: '7px'
    },
    '& .select__control:hover': {
      borderColor: '#000',
      boxShadow: 'none'
    },
    '& .select__control.select__control--is-focused': {
      borderColor: '#000',
      boxShadow: 'none'
    },
    '& .select__multi-value__remove:hover': {
        backgroundColor: '#00adef',
        color: '#fff'
    }
  },
  w9ImageInput: {
    display: 'none'
  },
  checkDuplicateBtn: {
    width: '105px',
    boxSizing: 'border-box',
    fontSize: '12px',
    lineHeight: '40px',
    padding: '2px 10px',
    borderRadius: '3px',
    backgroundColor: '#02A3EC',
    textTransform: 'none',
    fontFamily: 'Actual',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#FFFFFF',
    margin: '5px',
    height: '35px',
    '&:hover': {
      backgroundColor: '#02A3EC'
    }
  },
  overviewTextarea: {
    width: '100%',
    padding: '10px 12px',
    height: '280px !important'
  },
  selectedMainContactNumber: {
    '& .MuiTypography-body1': {
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#000000',
      marginLeft: '2px',
      marginRight: '17px'
    }
  },
  radio: {
    background: `url(${RadioIcon}) center /100% 100% no-repeat`,
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    transition: 'background 0.4s'
  },
  radioOn: {
    background: `url(${RadioOnIcon}) center /100% 100% no-repeat`,
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    transition: 'background 0.4s'
  },
  icoEsNotRequired: {
    position: 'relative',
    fontFamily: 'Actual',
    color: '#4F4F4F',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    '& span': {
      color: '#EB5757'
    }
  },
  websiteCheckboxText: {
    fontSize: '14px',
    fontFamily: 'Actual',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#000000'
  },
  surveySection: {
    display: 'grid',
    gridTemplateColumns: '2fr 4fr',
    marginTop: '15px',
    '& .MuiTypography-body1': {
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      color: '#000'
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '4fr 8fr'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  agreeBox: {
    marginTop: '30px',
    marginBottom: '30px',
    padding: '36px 40px',
    background: '#F6F6F6',
    '& .MuiTypography-body1': {
        fontSize: '14px',
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 500
    },
    '& .Mui-checked .MuiSvgIcon-root': {
        fill: '#009ee3'
    },
    '& p': {
      fontSize: '14px',
      marginLeft: '10px'
    },
    [theme.breakpoints.down('xs')]: {
        padding: '36px 5px',
        paddingLeft: '10px',
        '& p': {
          marginLeft: 0,
          marginTop: '7px'
        },
        '& .MuiFormControlLabel-root': {
          alignItems: 'flex-start'
        }
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiFormControlLabel-root': {
        marginRight: 0
      }
    }
  },
  agreeBoxLink: {
    color: '#000',
    position: 'relative',
    transition: 'color 0.4s',
    display: 'inline-block',
    '&::before': {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        height: '1px',
        background: '#666',
        content: '""'
    },
    '&::after': {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: 0,
        height: '1px',
        background: '#019ee3',
        content: '""',
        transition: 'width 0.6s 0.2s'
    },
    '&:hover': {
        color: '#019ee3',
        textDecoration: 'none'
    },
    '&:hover::before': {
        width: 0,
        transition: 'width 0.4s'
    },
    '&:hover::after': {
        width: '100%'
    }
  },
  errorInput: {
      '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#EB5757 !important'
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#EB5757 !important',
          borderWidth: '1px'
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#EB5757 !important',
          borderWidth: '1px'
      }
  },
  errorTextarea: {
      border: '1px solid #EB5757'
  },
  errorSelect: {
      '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#EB5757 !important'
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#EB5757 !important',
          borderWidth: '1px'
      },
      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#EB5757 !important',
          borderWidth: '1px'
      }
  },
  errorReactSelect: {
      '& .select__control': {
          borderColor: '#EB5757',
          boxShadow: 'none'
      },
      '& .select__control:hover': {
          borderColor: '#EB5757 !important'
      }
  },
  navigationBtnContainer: {
    '& button.MuiButtonBase-root': {
      minWidth: '188px !important',
      padding: '16.5px 2rem !important',
      marginTop: '10px',
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '18px',
      color: '#fff'
    },
    '& button.MuiButtonBase-root.backBtn': {
      backgroundColor: '#CCCCCC',
      borderColor: '#CCCCCC'
    },
    '& button.MuiButtonBase-root.backBtn:hover': {
      backgroundColor: '#a3a3a3'
    },
    [theme.breakpoints.down('sm')]: {
      '& button.MuiButtonBase-root': {
        minWidth: '110px !important'
      }
    }
  },
  sectionPrimaryProduct: {
    gridColumn: 'span 2',
    '& p': {
      fontSize: '15px',
      fontFamily: 'Actual_Light'
    }
  },
  modulePrimaryProduct: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '10px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  essPrimaryProduct1: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '10px',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  essPrimaryProduct2: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '10px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  modal: {
    width: '40rem',
    height: '50%',
    top: '30px',
    margin: 'auto',
    "& .MuiPaper-root": {
        overflowX: 'hidden'
    },
    [theme.breakpoints.down('md')]: {
        width: '98%',
        overflowY: 'auto'
    }
  },
  inputFieldDisable: {
    background: '#f8f8f8',
    color: '#666'
  },
  infoEs: {
    fontSize: '14px',
    fontFamily: 'Actual_Light',
    position: 'relative',
    '& span': {
        paddingLeft: '17px'
    },
    '& span::before': {
        position: 'absolute',
        left: 0,
        top: '50%',
        width: '12px',
        height: '1px',
        background: '#009ee3',
        content: '""'
    }
  },
  stepContainer: {
    marginTop: '40px',
    marginBottom: '70px'
  },
  sectionShippingAddressHeaderContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '8px'
  },
  helperText: {
    fontSize: '14px',
    fontFamily: 'Actual',
    fontWeight: 400,
    fontStyle: 'normal',
    color: '#000',
    marginTop: '12px'
  },
  accountInfoText: {
    gridColumn: 'span 3',
    fontFamily: 'Actual',
    '& p': {
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: '20px !important',
      lineHeight: '25px'
    }
  },
  table: {
    marginBottom: '30px',
    '& span': {
      fontFamily: 'Actual_Light'
    }
  },
  pageTitle: {
    display: 'inline-block'
  },
  editBtn: {
    maxWidth: '85px',
    height: '35px',
    backgroundColor: '#00ADEF',
    color: '#fff',
    fontFamily: 'Actual',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    textTransform: 'capitalize',
    borderRadius: '3px',
    '& span': {
      display: 'inline-block',
    },
    '& img': {
      display: 'inline',
      marginLeft: '5px'
    },
    '&:hover': {
      backgroundColor: '#002D56'
    }
  },
  moduleInstallationSectionContainer: {
    display: 'grid',
    gridTemplateColumns: '2fr 5fr 5fr',
    gridGap: '25px',
    alignContent: 'center',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  moduleInstallationContentContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gridGap: '10px',
    alignContent: 'center',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  moduleInstallationFieldContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '10px',
    alignContent: 'center',
    marginBottom: '22px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  addMoreProductBtn: {
    textTransform: 'none',
    fontFamily: 'Actual',
    fontSize: '15px',
    paddingLeft: 0,
    paddingRight: 0,
    fontWeight: 400,
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
  essInstallationFieldContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '10px',
    alignContent: 'center',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  customSelectField: {
    width: '100%',
    height: '44px',
    padding: '0 20px 0 15px',
    background: `#fff url(${IconSelect}) right center /50px 8px no-repeat`,
    border: '1px solid #C4C4C4',
    boxSizing: 'border-box',
    color: '#333333',
    fontFamily: 'Actual',
    fontSize: '14px',
    fontWeight: '400',
    fontStyle: 'normal',
    verticalAlign: 'top',
    lineHeight: '44px',
    transition: 'background 0.3s, border 0.3s',
    '-moz-appearance': 'none',
    '-webkit-appearance': 'none',
    appearance: 'none',
    '&:focus': {
      outlineStyle: 'none',
      borderColor: '#000'
    },
    '&::-ms-expand': {
      display: 'none'
    }
  },
  errorCustomSelect: {
    borderColor: '#EB5757'
  },
  italic: {
    fontStyle: 'italic'
  },
  separator: {
    height: '1px',
    maxHeight: '1px',
    width: '100%',
    backgroundColor: '#000',
    opacity: 0.1,
    marginTop: '25px',
    marginBottom: '25px',
    gridColumn: 'span 2'
  },
  requiredText: {
    color: '#EB5757',
    fontFamily: 'Actual',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    marginTop: '25px'
  },
  separatorContainer: {
    display: 'grid',
    gridTemplateColumns: '2fr 5fr 5fr',
  },
  w9Content: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0
    }
  },
  reviewContainer: {
    marginBottom: '76px',
    wordBreak: 'break-word'
  },
  reviewSectionContainer: {
    display: 'grid',
    gridTemplateColumns: '2.5fr 8.5fr 1fr',
    gridGap: '41px',
    alignItems: 'center',
    '& h5': {
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  reviewSectionContent: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    minHeight: '50px',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, .1)',
    gridColumn: 2,
    gridGap: '88px',
    fontFamily: 'DM Sans',
    '& h6': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      color: '#4F4F4F',
      marginLeft: '15px'
    },
    '& p': {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      color: '#000000'
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 1,
      gridTemplateColumns: 'repeat(2, 1fr)'
    }
  },
  reviewSectionHeaderSmallScreen: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '15px',
    marginBottom: '10px',
    '& h5': {
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px'
    }
  },
  agreeNewletter: {
    alignItems: 'flex-start'
  },
  annualText: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    color: '#fff !important',
    backgroundColor: '#717171',
    display: 'inline-block',
    borderRadius: '22px',
    height: '16px',
    textAlign: 'center',
    width: '57px',
    lineHeight: '16px',
    marginLeft: '2px',
    marginRight: '2px'
  }
}));

const companyInfoSchema = yup.object().shape({
  company: yup.string().required('Company is required').test('is-duplicate', 'Company name has been used', async (value, context) => {
    if (asyncCompanyValidationChecker.getCurrentValue() !== value) {
      const result = await asyncCompanyValidationChecker.validateInput(value);
      if (!result.isValid) {
        return context.createError(result.error);
      } else {
        return true;
      }
    } else {
      if (!asyncCompanyValidationChecker.isInputValid()) {
        return context.createError(asyncCompanyValidationChecker.getError());
      } else {
        return true;
      }
    }
  }),
  website: yup.string().when('notWebsite', { is: false, then: yup.string().required('URL is required').matches(/^([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?/, "Please enter the url except for http://. ex) www.qpartner.com") }),
  partnerType: yup.number().notOneOf([-1], 'Main business type is required')
});

const accountInfoSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  title: yup.string().notOneOf(['select'], 'Title is required'),
  email: yup.string().required('Email is required')
        .test('is-duplicate', 'Email has been used', async (value, context) => {
          if (/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(value)) {
            if (asyncEmailValidationChecker.getCurrentValue() !== value) {
              const result = await asyncEmailValidationChecker.validateInput(value);
              if (!result.isValid) {
                return context.createError(result.error);
              } else {
                return true;
              }
            } else {
              if (!asyncEmailValidationChecker.isInputValid()) {
                return context.createError(asyncEmailValidationChecker.getError());
              } else {
                return true;
              }
            }
          } else {
            return context.createError({ message: 'Invalid E-mail address.', type: 'invalid-email' })
          }
        }),
  password: yup.string().required('Password is required.')
      .test({ name: 'is-valid', message: 'Password must be at least 8 characters in combination of letters, numbers, and symbols (0-9, A-Z, at least one symbol)', exclusive: true, test: value => /^(?=.*([a-z]|[A-Z]))(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(value) }),
  confirmPassword: yup.string().required('Passwords do not match').oneOf([yup.ref('password')], 'Passwords do not match'),
  mainContactNumber: yup.string().required("Main contact number is required").matches(/^[0-9]*$/, "This entry can only contain numbers (e.g., 9497774444)."),
  address: yup.string().required("Primary address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().notOneOf(['select'], 'State is required'),
  zipCode: yup.string().required("Zipcode is required"),
  shippingAddress: yup.string().when('hasShippingAddress', { is: true, then: yup.string().required("Primary shipping address is required") }),
  shippingCity: yup.string().when('hasShippingAddress', { is: true, then: yup.string().required("Shipping city is required") }),
  shippingState: yup.string().when('hasShippingAddress', { is: true, then: yup.string().notOneOf(['select'], 'Shipping state is required') }),
  shippingZipCode: yup.string().when('hasShippingAddress', { is: true, then: yup.string().required("Shipping zipcode is required") }),
  otherTitle: yup.string().when('title', { is: 'other', then: yup.string().required('Other Job Title is required.') })
});

const businessInfoSchema = yup.object().shape({
  salesRegion: yup.array().typeError('Sales region is required').test({ name: 'required', test: value => value.length > 0, exclusive: true, message: 'Sales region is required' }),
  purchasingChannelS: yup.object().nullable().notOneOf([null], 'Main purchasing channel is required'),
  purchasingChannelT: yup.string().when('purchasingChannelS', { is: value => _.get(value, 'name') === 'Other', then: yup.string().required('Another purchasing channel is required') }),
  taxId: yup.string().required("Tax ID is required"),
  w9: yup.mixed().test('required', 'W9 is required', value => value.length > 0),
  description: yup.string(),
  installationVolume: yup.string().notOneOf(['select'], 'Installation volume (kW/annual) is required'),
  installationVolumeValue: yup.mixed().when('installationVolume', { is: value => MODULE_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+value),
    then: yup.number().typeError('Installation volume value is required').required('Installation volume value is required')
    .validateInstallationVolumeValue(yup.ref('installationVolume'), 'installationVolumes', 'Value has to be within the selected range') }),
  modulePrimaryProduct: yup.array().when('notPrimaryProduct', {
    is: false,
    then: yup.array().min(1).of(yup.object().shape({ brand: yup.array().typeError('Brand is required').min(1, 'Brand is required') }))
  }),
  essInstallationVolume: yup.string().notOneOf(['select'], 'Installation volume (set/annual) is required'),
  essInstallationVolumeValue: yup.mixed().when('essInstallationVolume', { is: value => ESS_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+value),
    then: yup.number().typeError('Installation volume value is required').required('Installation volume value is required')
    .validateInstallationVolumeValue(yup.ref('essInstallationVolume'), 'essInstallationVolumes', 'Value has to be within the selected range') }),
  essPrimaryProduct: yup.array().when('notEssPrimaryProduct', {
    is: false,
    then: yup.array().min(1).of(yup.object().shape({
      brand: yup.array().typeError('Brand is required').min(1, 'Brand is required')
    }))
  })
});

const lastInfoSchema = yup.object().shape({
  agree1: yup.bool().isTrue('Please agree to the Terms of Use and Privacy Policy.'),
  agree2: yup.bool().isTrue('Please agree to the Confidentiality Agreement.'),
  receptionYn: yup.bool(),
  survey: yup.string().required('Survey is required.'),
  surveyText1: yup.string().requiredWith(yup.ref('survey'), '1', 'Details are required.'),
  surveyText2: yup.string().requiredWith(yup.ref('survey'), '2', 'Details are required.'),
  surveyText3: yup.string().requiredWith(yup.ref('survey'), '3', 'Details are required.'),
  surveyText4: yup.string().requiredWith(yup.ref('survey'), '4', 'Details are required.'),
  surveyText5: yup.string().requiredWith(yup.ref('survey'), '5', 'Details are required.'),
  surveyText6: yup.string().requiredWith(yup.ref('survey'), '6', 'Details are required.'),
  surveyText7: yup.string().requiredWith(yup.ref('survey'), '7', 'Details are required.'),
});

const { baseUrl } = config;

export default function Registration() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [progress, setProgress] = useState(0);
  const [isEnableClickProgressBar, setIsEnableClickProgressBar] = useState(false);
  const [mainPurchasingChannel, setMainPurchasingChannel] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [saleRegions, setSaleRegions] = useState([]);
  const [w9Image, setW9Image] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchCompany, setSearchCompany] = useState({ searchBy: '1', searchText: '', showCtaBtnAndText: false });
  const [loadingCheckDuplicateCompany, setLoadingCheckDuplicateCompany] = useState(false);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const [primaryProducts, setPrimaryProducts] = useState({
    installationVolumes: [],
    productBrands: [],
    essInstallationVolumes: [],
    essProductBrands: []
  });
  const [isOpenPurchaseChannelMenu, setOpenPurchaseChannelMenu] = useState(false);
  const goBack = () => {
    if (isEnableClickProgressBar) {
      if (progress === 1) {
        handleSubmitCompanyInfo(() => setProgress(progress => progress - 1), onSubmitCompanyInfoFailed)();
      } else if (progress === 2) {
        handleSubmitBusinessInfo(() => setProgress(progress => progress - 1), onSubmitBusinessInfoFailed)();
      } else {
        setProgress(progress => progress - 1);
      }
    } else {
      setProgress(progress => progress - 1)
    }
  };

  const {
    register: registerCompanyInfo,
    errors: errorsCompanyInfo,
    handleSubmit: handleSubmitCompanyInfo,
    control: controlCompanyInfo,
    getValues: getValuesCompanyInfo,
    watch: watchCompanyInfo,
    formState: formStateCompanyInfo,
    trigger: triggerCompanyInfo
  } = useForm({
    resolver: yupResolver(companyInfoSchema),
    shouldUnregister: false,
    shouldFocusError: false,
    defaultValues: {
      partnerType: -1
    }
  });

  const {
    register: registerAccountInfo,
    errors: errorsAccountInfo,
    handleSubmit: handleSubmitAccountInfo,
    control: controlAccountInfo,
    getValues: getValuesAccountInfo,
    watch: watchAccountInfo,
    formState: formStateAccountInfo,
    trigger: triggerAccountInfo
  } = useForm({
    resolver: yupResolver(accountInfoSchema),
    shouldFocusError: false,
    shouldUnregister: false,
    defaultValues: {
      title: 'select',
      state: 'select',
      shippingState: 'select',
      selectedMainContactNumber: '1'
    }
  });

  const {
    register: registerBusinessInfo,
    errors: errorsBusinessInfo,
    handleSubmit: handleSubmitBusinessInfo,
    control: controlBusinessInfo,
    getValues: getValuesBusinessInfo,
    watch: watchBusinessInfo,
    formState: formStateBusinessInfo,
    setValue: setValueBusinessInfo,
    trigger: triggerBusinessInfo
  } = useForm({
    resolver: yupResolver(businessInfoSchema),
    shouldFocusError: false,
    shouldUnregister: false,
    defaultValues: {
      primaryProduct: [],
      purchasingChannelS: 'select',
      salesRegion: [],
      moduleMajorCompetitorBrand: 'select',
      powerClass: 'select',
      installationVolume: 'select',
      essMajorCompetitorBrand: 'select',
      productType: 'select',
      inverterSize: 'select',
      batterySize: 'select',
      installationQty: 'select',
      'modulePrimaryProduct[0].brand': 'select',
      modulePrimaryProduct: [{ brand: 'select' }],
      essInstallationVolume: 'select',
      essPrimaryProduct: [{ brand: 'select' }],
    },
    context: { primaryProducts }
  });

  const {
    register: registerLastInfo,
    errors: errorsLastInfo,
    handleSubmit: handleSubmitLastInfo,
    control: controlLastInfo,
    watch: watchLastInfo,
    formState: formStateLastInfo
  } = useForm({
    resolver: yupResolver(lastInfoSchema),
    shouldFocusError: false,
    shouldUnregister: false,
    defaultValues: {
      survey: '7'
    }
  });

  const hasShippingAddress = watchAccountInfo('hasShippingAddress');
  const titleVal = watchAccountInfo('title');
  const notWebsite = watchCompanyInfo('notWebsite');
  const agree1 = watchLastInfo('agree1');
  const agree2 = watchLastInfo('agree2');
  const receptionYn = watchLastInfo('receptionYn');
  const purchasingChannelSVal = watchBusinessInfo('purchasingChannelS');
  const installationVolumeVal = watchBusinessInfo('installationVolume');
  const modulePrimaryProduct = watchBusinessInfo('modulePrimaryProduct');
  const essPrimaryProduct = watchBusinessInfo('essPrimaryProduct');
  const essInstallationVolume = watchBusinessInfo('essInstallationVolume');
  const installationVolumeValue = watchBusinessInfo('installationVolumeValue');
  const essInstallationVolumeValue = watchBusinessInfo('essInstallationVolumeValue');
  const notPrimaryProduct = watchBusinessInfo('notPrimaryProduct');
  const notEssPrimaryProduct = watchBusinessInfo('notEssPrimaryProduct');
  const password = watchAccountInfo('password');

  const getJobTitles = async () => {
    try {
        const { data } = await axios.get(`${baseUrl}/companies/register/job-titles`);
        setJobTitles(data);
    } catch (err) {
        notiFailed(`Something happened getting job titles`);
    }
  };

  const getSaleRegions = async () => {
    try {
        const { data } = await axios.get(`${baseUrl}/companies/salesregions/search`);
        setSaleRegions(data);
    } catch (err) {
        notiFailed(`Something happened getting sale regions`);
    }
  };

  const getMainPurchasingChannel = async () => {
    try {
        const { data } = await axios.get(`${baseUrl}/distributors`);
        setMainPurchasingChannel(data);
    } catch (err) {
        notiFailed(`Something happened getting main purchasing channel`);
    }
  };

  const getPrimaryProduct = async () => {
    try {
      const data = await Promise.all([
        axios.get(`${baseUrl}/companies/register/installation-volumes`, { params: { productType: 1 } }),
        axios.get(`${baseUrl}/companies/register/product-brands`, { params: { productType: 1 } }),
        axios.get(`${baseUrl}/companies/register/installation-volumes`, { params: { productType: 2 } }),
        axios.get(`${baseUrl}/companies/register/product-brands`, { params: { productType: 2 } }),
      ]);
      setPrimaryProducts({
        installationVolumes: data[0].data,
        productBrands: data[1].data.map(b => b.name),
        essInstallationVolumes: data[2].data,
        essProductBrands: data[3].data.map(b => b.name)
      });
    } catch (err) {
      notiFailed(`Something happened getting primary product`);
    }
  };

  const onSubmitCompanyInfo = async (data) => {
    setProgress(progress => progress + 1);
  };

  const onSubmitCompanyInfoFailed = errors => {
    showErrorMessage(errors, ['company', 'partnerType', 'website']);
  };

  const onSubmitAccountInfo = async (data) => {
    setProgress(progress => progress + 1);
  };

  const onSubmitAccountInfoFailed = errors => {
    showErrorMessage(errors, ['firstName', 'lastName', 'title', 'otherTitle', 'email', 'password', 'confirmPassword', 'mainContactNumber',
    'address', 'addressCont', 'city', 'state', 'zipCode', 'shippingAddress', 'shippingAddress2', 'shippingCity',
    'shippingState', 'shippingZipCode']);
  };

  const onSubmitBusinessInfo = async (data) => {
    setProgress(progress => progress + 1);
  };

  const onSubmitBusinessInfoFailed = async (errors) => {
    showErrorMessage(errors, ['salesRegion', 'purchasingChannelS', 'purchasingChannelT', 'installationVolume',
     'installationVolumeValue', 'modulePrimaryProduct', 'essInstallationVolume', 'essInstallationVolumeValue',
     'essPrimaryProduct', 'taxId', 'w9', 'description']);
  };

  const onSubmitLastInfo = async (data) => {
    const companyInfo = getValuesCompanyInfo();
    const accountInfo = getValuesAccountInfo();
    const businessInfo = getValuesBusinessInfo();

    let formData = new FormData();
    formData.append('name', companyInfo.company);
    formData.append('partnerType', companyInfo.partnerType);
    if (!companyInfo.notWebsite) {
        formData.append('website', companyInfo.website);
    }

    formData.append('firstName', accountInfo.firstName);
    formData.append('lastName', accountInfo.lastName);
    if (accountInfo.title !== 'other') {
      formData.append('jobTitle', accountInfo.title);
    } else {
      formData.append('jobTitle', accountInfo.otherTitle);
    }
    formData.append('email', accountInfo.email);
    formData.append('password', accountInfo.password);
    if (accountInfo.selectedMainContactNumber === "1") {
      formData.append('officeNumber', accountInfo.mainContactNumber);
    } else {
      formData.append('mobileNumber', accountInfo.mainContactNumber);
    }
    formData.append('address', accountInfo.address);
    if (accountInfo.addressCont) {
      formData.append('addressCont', accountInfo.addressCont);
    }
    formData.append('city', accountInfo.city);
    formData.append('state', accountInfo.state);
    formData.append('zipCode', accountInfo.zipCode);
    if (accountInfo.hasShippingAddress) {
      formData.append('shippingAddress', accountInfo.shippingAddress);
      if (accountInfo.shippingAddress2) {
        formData.append('shippingAddress2', accountInfo.shippingAddress2);
      }
      formData.append('shippingCity', accountInfo.shippingCity);
      formData.append('shippingState', accountInfo.shippingState);
      formData.append('shippingZipCode', accountInfo.shippingZipCode);
    } else {
      formData.append('shippingAddress', accountInfo.address);
      if (accountInfo.addressCont) {
        formData.append('shippingAddress2', accountInfo.addressCont);
      }
      formData.append('shippingCity', accountInfo.city);
      formData.append('shippingState', accountInfo.state);
      formData.append('shippingZipCode', accountInfo.zipCode);
    }

    formData.append('salesRegion', _.join(businessInfo.salesRegion, '|'));
    if (_.get(businessInfo, 'purchasingChannelS.name') === 'Other') {
      formData.append('purchaseChannel', businessInfo.purchasingChannelT);
    } else {
      formData.append('purchaseChannel', businessInfo.purchasingChannelS.name);
    }
    formData.append('taxId', businessInfo.taxId);
    formData.append('w9', businessInfo.w9[0]);
    if (businessInfo.description) {
      formData.append('description', businessInfo.description);
    }
    const primaryProducts = [];
    for (let mp of modulePrimaryProduct) {
      let newModule = {
        productType: 'module',
        productBrand: _.join(mp.brand, '|'),
        installationVolumeId: +businessInfo.installationVolume
      };

      if (businessInfo.notPrimaryProduct) {
        newModule.productBrand = 'None';
      }

      if (MODULE_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+businessInfo.installationVolume)) {
        newModule.installationVolumeData = businessInfo.installationVolumeValue;
      }

      primaryProducts.push(newModule);
    }
    for (let mp of essPrimaryProduct) {
      let newEss = {
        productType: 'ess',
        productBrand: _.join(mp.brand, '|'),
        installationVolumeId: +businessInfo.essInstallationVolume
      };

      if (businessInfo.notEssPrimaryProduct) {
        newEss.productBrand = 'None';
      }

      if (ESS_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+businessInfo.essInstallationVolume)) {
        newEss.installationVolumeData = businessInfo.essInstallationVolumeValue;
      }

      primaryProducts.push(newEss);
    }
    formData.append('primaryProducts', JSON.stringify(primaryProducts));

    formData.append('survey', data.survey);
    let surveyText = '';
    switch (data.survey) {
        case '1':
            surveyText = data.surveyText1;
            break;
        case '2':
            surveyText = data.surveyText2;
            break;
        case '3':
            surveyText = data.surveyText3;
            break;
        case '4':
            surveyText = data.surveyText4;
            break;
        case '5':
            surveyText = data.surveyText5;
            break;
        case '6':
            surveyText = data.surveyText6;
            break;
        case '7':
            surveyText = data.surveyText7;
            break;
    }
    formData.append('surveyText', surveyText);
    formData.append('receptionYn', data.receptionYn ? 'Y' : 'N');

    try {
        setLoading(true);
        await axios.post(`${baseUrl}/companies/register`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        sendEventForRegister(ACTION_SUBMIT_REGISTER);
        notiSuccess('Thank you for submitting your Q.PARTNER registration! Please allow 1-3 business days for the administrator to review and approve your registration');
        history.push({
          pathname: "/",
          state: {
            disablePrompt: true
          }
        });
    } catch (err) {
        notiFailed(`There was a problem when register user ${err.message}`);
    } finally {
        setLoading(false);
    }
  };

  const onSubmitLastInfoFailed = async (errors) => {
    showErrorMessage(errors, ['survey', 'surveyText1', 'surveyText2', 'surveyText3', 'surveyText4',
    'surveyText5', 'surveyText6', 'surveyText7', 'agree1', 'agree2']);
  };

  const showErrorMessage = (errors, errorKeys = []) => {
    let message = '';

    for (let i = 0; i < errorKeys.length; i++) {
        if (errors[errorKeys[i]]) {
          if (errorKeys[i] === 'modulePrimaryProduct') {
            for (let i = 0; i < errors.modulePrimaryProduct.length; i++) {
              if (errors.modulePrimaryProduct[i]) {
                if (errors.modulePrimaryProduct[i].brand) {
                  return notiFailed(errors.modulePrimaryProduct[i].brand.message);
                }
              }
            }
          } else if (errorKeys[i] === 'essPrimaryProduct') {
            for (let i = 0; i < errors.essPrimaryProduct.length; i++) {
              if (errors.essPrimaryProduct[i]) {
                if (errors.essPrimaryProduct[i].brand) {
                  return notiFailed(errors.essPrimaryProduct[i].brand.message);
                }
              }
            }
          } else if (errorKeys[i] === 'email' && errors[errorKeys[i]].type === 'is-duplicate') {
            message = errors[errorKeys[i]].message.message;
            break;
          } else if (errorKeys[i] === 'company' && errors[errorKeys[i]].type === 'is-duplicate') {
            message = errors[errorKeys[i]].message.message;
            break;
          } else {
            message = errors[errorKeys[i]].message;
            break;
          }
        }
    }

    return notiFailed(message);
  };

  const handleClose = () => {
    setSearchCompany({
      searchBy: '1',
      searchText: '',
      showCtaBtnAndText: false,
      companyName: '',
      userName: '',
      email: ''
    });
    setOpen(false);
  };

  const handleChangeSearchCompany = e => {
    setSearchCompany({
      ...searchCompany,
      [e.target.name]: e.target.value
    })
  };

  const handleCheckDuplicateCompany = async () => {
    if (_.trim(searchCompany.searchText) === '') {
      return notiFailed('Please enter your company.')
    }

    let url = `${baseUrl}/companies/register/check-domain`;
    let params = { domain: searchCompany.searchText };

    if (searchCompany.searchBy === '2') {
      url = `${baseUrl}/companies/register/check-company`;
      params = { name: searchCompany.searchText };
    }

    try {
      setLoadingCheckDuplicateCompany(true);
      const { data } = await axios.get(url, { params });
      if (!data) {
        notiSuccess(`This company ${searchCompany.searchBy === "1" ? 'domain' : 'name'} is available.`);
        setSearchCompany({
          ...searchCompany,
          showCtaBtnAndText: false
        });
      } else {
        setSearchCompany({
          ...searchCompany,
          showCtaBtnAndText: true,
          companyName: data.companyName,
          userName: data.fullName,
          email: data.email
        });
      }
    } catch (err) {
      notiFailed(`There was a problem when check duplicate company ${err.message}`);
    } finally {
      setLoadingCheckDuplicateCompany(false);
    }
  };

  const isShowPreventRedirectPrompt = () => {
    return formStateCompanyInfo.isDirty || formStateAccountInfo.isDirty ||
    formStateBusinessInfo.isDirty || formStateLastInfo.isDirty;
  };

  const checkDuplicateCompany = () => {
    triggerCompanyInfo(['company']);
  };

  const checkDuplicateEmail = () => {
    triggerAccountInfo(['email']);
  };

  const renderAddress = (array = []) => {
    const newArray = _.remove(array, value => !!value || value === 'select');
    return newArray.length > 0 ? _.join(newArray, ', ') : '';
  };

  const renderMainBusinessType = partnerType => {
    let mainBusinessType = '';
    switch (+partnerType) {
    case 1:
      mainBusinessType = 'Contractor/EPC';
      break;
    case 2:
      mainBusinessType = 'Sales Agent';
      break;
    case 3:
      mainBusinessType = 'Distributor';
      break;
    }

    return mainBusinessType;
  };

  const renderSalesRegion = saleRegions => {
    if (!saleRegions.length) return;
    let result = '';
    for (let i = 0; i < saleRegions.length; i++) {
      const info = saleRegions[i].split(':');
      if (i > 0) {
        result += ', ';
      }
      result += info[1].replace('^', ' ');
    }

    return result;
  };

  const goToStep = step => {
    if (progress === 0) {
      handleSubmitAccountInfo(() => setProgress(step), onSubmitAccountInfoFailed)();
    } else if (progress === 1) {
      handleSubmitCompanyInfo(() => setProgress(step), onSubmitCompanyInfoFailed)();
    } else if (progress === 2) {
      handleSubmitBusinessInfo(() => setProgress(step), onSubmitBusinessInfoFailed)();
    } else {
      setProgress(step);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 360,
      behavior: "smooth"
    });
  };

  const renderReviewModuleInformation = () => {
    const installationVolume = primaryProducts.installationVolumes.find(e => e.id === +getValuesBusinessInfo('installationVolume'));
    return (
      <>
        Installation volume (kW/<span className={classes.annualText}>Annual</span>): {_.get(installationVolume, 'name')}
          {MODULE_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+getValuesBusinessInfo('installationVolume'))
          && ` - ${getValuesBusinessInfo('installationVolumeValue')} ${_.get(installationVolume, 'unit')}`}
      </>
    )
  }

  useEffect(() => {
    getMainPurchasingChannel();
    getJobTitles();
    getSaleRegions();
    getPrimaryProduct();
  }, []);

  useEffect(() => {
    if (isShowPreventRedirectPrompt()) {
      if (location.pathname === '/register'){
        setShowExitPrompt(true);
      }
    }
  }, [formStateCompanyInfo.isDirty, formStateAccountInfo.isDirty,
    formStateBusinessInfo.isDirty, formStateLastInfo.isDirty])

  useEffect(() => {
    scrollToTop();
    if (progress === 3) {
      setIsEnableClickProgressBar(true);
    }
  }, [progress]);

  useEffect(() => {
    if (notPrimaryProduct) {
      setValueBusinessInfo('modulePrimaryProduct', [{ brand: 'select' }]);
    }
  }, [notPrimaryProduct]);

  useEffect(() => {
    if (notEssPrimaryProduct) {
      setValueBusinessInfo('essPrimaryProduct', [{ brand: 'select' }]);
    }
  }, [notEssPrimaryProduct]);

  return (
    <>
      <RouteLeavingGuard
           when={
            isShowPreventRedirectPrompt()
           }
           navigate={path => history.push(path)}
           shouldBlockNavigation={location => {
              if (isShowPreventRedirectPrompt()) {
                if (location.pathname !== '/register' && !_.get(location, 'state.disablePrompt')) {
                  return true
                }
              }
              return false
           }}
      />
      <Banner
        imageUrl={RegistrationBanner}
        className="OrderHistory_Banner banner100"
      >
        <h1 className="title_animation OrderHistory_Title">Become a Q.Partner</h1>
        <p className={classes.pageSubtitle}>Q CELLS offers differentiated services for the success of our Q.PARTNERs. <br />Become a Q.PARTNER now and enjoy various benefits.</p>
        <BreadCrumb2 items={['Become a Q.Partner']}></BreadCrumb2>
      </Banner>
      <div style={{ width: '100%' }}>
          <ProgressBar currentStep={progress} goToStep={goToStep} isEnableClick={isEnableClickProgressBar} />
          <PageContainer>
            {progress === 0 && (
              <div className={classes.stepContainer}>
                <div className={classes.sectionContainer} style={{ marginBottom: '33px' }}>
                  <div className={classes.accountInfoText}>
                    <p>*Solar installation companies in the U.S. are eligible for the Q.PARTNER program</p>
                  </div>
                </div>
                <div className={classes.sectionContainer}>
                  <div className={classes.sectionHeaderContainer}>
                    <span className={classes.icoEsNotRequired}>
                      Name {' '}
                      <span>*</span>
                    </span>
                  </div>
                  <div>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="firstName"
                        inputRef={registerAccountInfo}
                        className={classNames([classes.inputField], {
                            [classes.errorInput]: !!errorsAccountInfo.firstName
                        })}
                        placeholder="First Name"
                    />
                  </div>
                  <div>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="lastName"
                        inputRef={registerAccountInfo}
                        className={classNames([classes.inputField], {
                            [classes.errorInput]: !!errorsAccountInfo.lastName
                        })}
                        placeholder="Last Name"
                    />
                  </div>
                </div>
                <div className={classes.sectionContainer}>
                  <div className={classes.sectionHeaderContainer}>
                    <span className={classes.icoEsNotRequired}>
                      Title {' '} <span>*</span>
                    </span>
                  </div>
                  <div>
                    <Controller
                      name="title"
                      rules={{
                          required: true
                      }}
                      control={controlAccountInfo}
                      as={
                          <select
                            className={classNames([classes.customSelectField], {
                              [classes.errorCustomSelect]: !!errorsAccountInfo.title
                            })}
                          >
                            <option value={'select'}>Please select</option>
                            {jobTitles.map(jt => (
                              <option value={jt.name} key={jt.id}>{jt.name}</option>
                            ))}
                            <option value={'other'}>Other</option>
                          </select>
                      }
                    />
                  </div>
                  {titleVal === 'other' && (
                    <div>
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="otherTitle"
                            inputRef={registerAccountInfo}
                            className={classNames([classes.inputField], {
                                [classes.errorInput]: !!errorsAccountInfo.otherTitle
                            })}
                        />
                    </div>
                  )}
                </div>
                <div className={classes.sectionContainer}>
                  <div className={classes.sectionHeaderContainer}>
                    <span className={classes.icoEsNotRequired}>
                      ID (E-mail) {' '} <span>*</span>
                    </span>
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="email"
                      inputRef={registerAccountInfo}
                      className={classNames([classes.inputField], {
                          [classes.errorInput]: !!errorsAccountInfo.email
                      })}
                      onChange={checkDuplicateEmail}
                    />
                    {errorsAccountInfo.email && errorsAccountInfo.email.type === 'is-duplicate' &&
                    <Text size="small" style={{ marginTop: '7px' }}>
                      Your company already has an account created under <span className={classes.italic}>{convertHTMLEntity(errorsAccountInfo.email.message.companyName)} </span>
                      by a primary user <span className={classes.italic}>{errorsAccountInfo.email.message.fullName}</span>, {' '}
                      <span className={classes.italic}>{errorsAccountInfo.email.message.email}</span>.
                      {' '} You can have your primary user easily create a sub-account. {' '}
                      <Text size="small" style={{ display: 'inline-block' }}>
                        <Link to="/support/faq/1" target="_blank" className={classes.agreeBoxLink}>Learn how to create a sub account</Link>
                      </Text>
                    </Text>}
                  </div>
                </div>
                <div className={classes.sectionContainer}>
                  <div className={classes.sectionHeaderContainer}>
                    <span className={classes.icoEsNotRequired}>
                      Password {' '} <span>*</span>
                    </span>
                  </div>
                  <div>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="password"
                        type="password"
                        inputRef={registerAccountInfo}
                        className={classNames([classes.inputField], {
                            [classes.errorInput]: !!errorsAccountInfo.password
                        })}
                        onChange={() => {
                          triggerAccountInfo('password');
                        }}
                        autoComplete="new-password"
                    />
                    {(!password || (errorsAccountInfo.password && errorsAccountInfo.password.type === 'required')) &&
                    <Text size="small" style={{ marginTop: '7px' }}>
                      At least 8 characters in combination of letters, numbers, and symbols (0-9, A-Z, at least one symbol)
                    </Text>}
                    {errorsAccountInfo.password && errorsAccountInfo.password.type === 'is-valid' &&
                    <Text size="small" style={{ marginTop: '7px' }}>
                      {errorsAccountInfo.password.message}
                    </Text>}
                  </div>
                </div>
                <div className={classes.sectionContainer}>
                  <div className={classes.sectionHeaderContainer}>
                    <span className={classes.icoEsNotRequired}>
                      Confirm Password {' '} <span>*</span>
                    </span>
                  </div>
                  <div>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="confirmPassword"
                        type="password"
                        inputRef={registerAccountInfo}
                        className={classNames([classes.inputField], {
                            [classes.errorInput]: !!errorsAccountInfo.confirmPassword
                        })}
                        onChange={() => {
                          triggerAccountInfo('confirmPassword');
                        }}
                    />
                    {errorsAccountInfo.confirmPassword &&
                    <Text size="small" style={{ marginTop: '7px' }}>
                      {errorsAccountInfo.confirmPassword.message}
                    </Text>}
                  </div>
                </div>
                <div className={classes.sectionContainer}>
                  <div className={classes.sectionHeaderContainer}>
                    <span className={classes.icoEsNotRequired}>
                      Main Contact Number {' '} <span>*</span>
                    </span>
                  </div>
                  <div>
                    <Controller
                      name="mainContactNumber"
                      rules={{
                          required: true
                      }}
                      control={controlAccountInfo}
                      as={
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Numbers only (e.g., 9497774444)"
                            className={classNames([classes.inputField], {
                                [classes.errorInput]: !!errorsAccountInfo.mainContactNumber
                            })}
                            InputProps={{
                              inputComponent: NumberFormat
                            }}
                        />
                      }
                    />
                  </div>
                  <div>
                    <Controller
                      name="selectedMainContactNumber"
                      rules={{
                          required: true
                      }}
                      control={controlAccountInfo}
                      as={
                        <RadioGroup row aria-label="position" className={classes.selectedMainContactNumber}>
                          <FormControlLabel
                            value="1"
                            control={<Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />}
                            label="Office Number"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />}
                            label="Mobile Number"
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      }
                    />
                  </div>
                </div>
                <div className={classes.separator}></div>
                <div className={classes.sectionContainer}>
                  <div
                    className={classes.sectionHeaderContainer}
                    style={{
                      alignItems: 'flex-start',
                      marginTop: '12px'
                    }}
                  >
                    <span className={classes.icoEsNotRequired}>
                      Business Address {' '} <span>*</span>
                    </span>
                  </div>
                  <div className={classes.addressSection}>
                    <div className={classes.addressSection1}>
                      <div>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Street address"
                            name="address"
                            inputRef={registerAccountInfo}
                            className={classNames([classes.inputField], {
                                [classes.errorInput]: !!errorsAccountInfo.address
                            })}
                        />
                      </div>
                      <div>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Ste, unit, building, floor, etc"
                            name="addressCont"
                            inputRef={registerAccountInfo}
                            className={classNames([classes.inputField], {
                                [classes.errorInput]: !!errorsAccountInfo.addressCont
                            })}
                        />
                      </div>
                    </div>
                    <div className={classes.addressSection2}>
                      <div>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="City"
                            name="city"
                            inputRef={registerAccountInfo}
                            className={classNames([classes.inputField], {
                                [classes.errorInput]: !!errorsAccountInfo.city
                            })}
                        />
                      </div>
                      <div>
                        <Controller
                            name="state"
                            rules={{
                                required: true
                            }}
                            control={controlAccountInfo}
                            as={
                              <select
                                placeholder="State"
                                className={classNames([classes.customSelectField], {
                                  [classes.errorCustomSelect]: !!errorsAccountInfo.state
                                })}
                              >
                                <option value={'select'}>State</option>
                                {saleRegions.map(pc => {
                                    const [id, value] = pc.split(':');
                                    return (
                                        <option value={value} key={id}>{value}</option>
                                    )
                                })}
                              </select>
                            }
                        />
                      </div>
                      <div>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Zip Code"
                            name="zipCode"
                            inputRef={registerAccountInfo}
                            className={classNames([classes.inputField], {
                                [classes.errorInput]: !!errorsAccountInfo.zipCode
                            })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.separator}></div>
                <div className={classes.sectionContainer}>
                  <div className={classes.sectionShippingAddressHeaderContainer}>
                    <span className={classes.icoEsNotRequired}>Shipping Address</span>
                  </div>
                  <div className={classes.addressSection}>
                    <div style={{ marginBottom: '5px' }}>
                      <FormControlLabel
                        style={{ marginLeft: '-9px' }}
                        control={
                          <Checkbox
                            size="medium"
                            name="hasShippingAddress"
                            inputRef={registerAccountInfo}
                            icon={
                              <div
                                className={classNames('', {
                                  [classes.checkBoxOn]: hasShippingAddress,
                                  [classes.checkBox]: !hasShippingAddress
                                })}></div>}
                            checkedIcon={
                              <div className={classNames('', {
                                [classes.checkBoxOn]: hasShippingAddress,
                                [classes.checkBox]: !hasShippingAddress
                              })}></div>}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontFamily: 'Actual',
                              fontWeight: 400,
                              fontStyle: 'normal',
                              fontSize: '14px',
                              lineHeight: '14.62px',
                              marginLeft: '2px'
                            }}
                          >
                              Different from Business Address?
                          </p>
                        }
                      />
                      <p
                        style={{
                          fontFamily: 'Actual',
                          fontWeight: 400,
                          fontStyle: 'normal',
                          fontSize: '14px',
                          lineHeight: '17.5px',
                          color: '#000',
                          opacity: 0.7,
                          marginBottom: '22px'
                        }}
                      >
                        *Indicate for receiving mail and packages if different from business address.
                      </p>
                    </div>
                    {hasShippingAddress && (
                      <>
                        <div className={classes.addressSection1}>
                            <div>
                              <TextField
                                  fullWidth
                                  variant="outlined"
                                  placeholder="Street address"
                                  name="shippingAddress"
                                  inputRef={registerAccountInfo}
                                  className={classNames([classes.inputField], {
                                      [classes.errorInput]: !!errorsAccountInfo.shippingAddress
                                  })}
                              />
                            </div>
                            <div>
                              <TextField
                                  fullWidth
                                  variant="outlined"
                                  placeholder="Ste, unit, building, floor, etc"
                                  name="shippingAddress2"
                                  inputRef={registerAccountInfo}
                                  className={classNames([classes.inputField], {
                                      [classes.errorInput]: !!errorsAccountInfo.shippingAddress2
                                  })}
                              />
                            </div>
                        </div>
                        <div className={classes.addressSection2}>
                            <div>
                              <TextField
                                  fullWidth
                                  variant="outlined"
                                  placeholder="City"
                                  name="shippingCity"
                                  inputRef={registerAccountInfo}
                                  className={classNames([classes.inputField], {
                                      [classes.errorInput]: !!errorsAccountInfo.shippingCity
                                  })}
                              />
                            </div>
                            <div>
                              <Controller
                                  name="shippingState"
                                  rules={{
                                      required: true
                                  }}
                                  control={controlAccountInfo}
                                  as={
                                    <select
                                      placeholder="State"
                                      className={classNames([classes.customSelectField], {
                                        [classes.errorCustomSelect]: !!errorsAccountInfo.shippingState
                                      })}
                                    >
                                      <option value={'select'}>State</option>
                                      {saleRegions.map(pc => {
                                          const [id, value] = pc.split(':');
                                          return (
                                              <option value={value} key={id}>{value}</option>
                                          )
                                      })}
                                    </select>
                                  }
                              />
                            </div>
                            <div>
                              <TextField
                                  fullWidth
                                  variant="outlined"
                                  placeholder="Zip Code"
                                  name="shippingZipCode"
                                  inputRef={registerAccountInfo}
                                  className={classNames([classes.inputField], {
                                      [classes.errorInput]: !!errorsAccountInfo.shippingZipCode
                                  })}
                              />
                            </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <p className={classes.requiredText}>* Required</p>
                <div className={classes.separator}></div>
                <Box display="flex" justifyContent="flex-end" className={classes.navigationBtnContainer}>
                  <CustomButton onClick={handleSubmitAccountInfo(onSubmitAccountInfo, onSubmitAccountInfoFailed)}>Next</CustomButton>
                </Box>
              </div>
            )}
            {progress === 1 && (
              <div className={classes.stepContainer} style={{ marginTop: '60px' }}>
                <div className={classes.sectionContainer}>
                  <div className={classes.sectionHeaderContainer}>
                    <span className={classes.icoEsNotRequired}>
                      Company {' '} <span>*</span>
                    </span>
                  </div>
                  <div>
                    <TextField
                          fullWidth
                          variant="outlined"
                          name="company"
                          inputRef={registerCompanyInfo}
                          className={classNames([classes.inputField], {
                              [classes.errorInput]: !!errorsCompanyInfo.company
                          })}
                          onChange={checkDuplicateCompany}
                    />
                    {errorsCompanyInfo.company && errorsCompanyInfo.company.type === 'is-duplicate' &&
                    <Text size="small" style={{ marginTop: '7px' }}>
                      Your company already has an account created under <span className={classes.italic}>{convertHTMLEntity(errorsCompanyInfo.company.message.companyName)}</span> {' '}
                      by a primary user <span className={classes.italic}>{errorsCompanyInfo.company.message.fullName}</span>, <span className={classes.italic}>{errorsCompanyInfo.company.message.email}</span>.
                      {' '} You can have your primary user easily create a sub-account. {' '}
                      <Text size="small" style={{ display: 'inline-block' }}>
                        <Link to="/support/faq/1" target="_blank" className={classes.agreeBoxLink}>Learn how to create a sub account</Link>
                      </Text>
                    </Text>}
                  </div>
                </div>
                <div className={classes.sectionContainer}>
                  <div className={classes.sectionHeaderContainer}>
                    <span className={classes.icoEsNotRequired}>
                      Main Business Type  {' '} <span>*</span>
                    </span>
                  </div>
                  <div>
                    <Controller
                        name="partnerType"
                        rules={{
                            required: true
                        }}
                        control={controlCompanyInfo}
                        as={
                          <select
                            placeholder="Please select"
                            defaultValue={-1}
                            className={classNames([classes.customSelectField], {
                              [classes.errorCustomSelect]: !!errorsCompanyInfo.partnerType
                            })}
                          >
                            <option value={-1}>Please select</option>
                            <option value={1}>Contractor/EPC</option>
                            <option value={2}>Sales Agent</option>
                            <option value={3}>Distributor</option>
                          </select>
                        }
                      />
                  </div>
                </div>
                <div className={classes.sectionContainer}>
                  <div className={classes.sectionHeaderContainer}>
                    <span className={classes.icoEsNotRequired}>
                      Home page URL  {' '} <span>*</span>
                    </span>
                  </div>
                  <div>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Please enter the url except for http://."
                        name="website"
                        inputRef={registerCompanyInfo}
                        className={classNames([classes.inputField], {
                            [classes.errorInput]: !!errorsCompanyInfo.website && !notWebsite,
                            [classes.inputFieldDisable]: notWebsite
                        })}
                        disabled={notWebsite}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                        control={
                          <Checkbox
                            name="notWebsite"
                            size="medium"
                            icon={
                              <div
                                className={classNames('', {
                                  [classes.checkBoxOn]: notWebsite,
                                  [classes.checkBox]: !notWebsite
                                })}></div>}
                            checkedIcon={
                              <div className={classNames('', {
                                [classes.checkBoxOn]: notWebsite,
                                [classes.checkBox]: !notWebsite
                              })}></div>}
                            inputRef={registerCompanyInfo}
                          />
                        }
                        label={
                          <p className={classes.websiteCheckboxText}>
                              I don't have a website.
                          </p>
                        }
                    />
                  </div>
                </div>
                <p className={classes.requiredText} style={{ marginTop: '35px' }}>* Required</p>
                <div className={classes.separator} style={{ marginTop: '10px' }}></div>
                <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
                  <CustomButton color="secondary" onClick={goBack} className="backBtn">Back</CustomButton>
                  <CustomButton onClick={handleSubmitCompanyInfo(onSubmitCompanyInfo, onSubmitCompanyInfoFailed)}>Next</CustomButton>
                </Box>
              </div>
            )}
            {progress === 2 && (
              <div className={classes.stepContainer} style={{ marginTop: '60px' }}>
                <div className={classes.sectionContainer}>
                  <div className={classes.sectionHeaderContainer}>
                    <span className={classes.icoEsNotRequired}>
                      Sales Region(s) {' '} <span>*</span>
                    </span>
                  </div>
                  <div>
                    <Controller
                        name="salesRegion"
                        rules={{
                            required: true
                        }}
                        control={controlBusinessInfo}
                        as={
                            <ReactSelect
                                isMulti
                                options={saleRegions}
                                className={classNames([classes.multiSelect], {
                                    [classes.errorReactSelect]: !!errorsBusinessInfo.salesRegion
                                })}
                                classNamePrefix="select"
                                getOptionLabel={option => option.split(':')[1]}
                                getOptionValue={option => option}
                                components={{
                                  IndicatorSeparator: null,
                                  DropdownIndicator: ReactSelectDropdownIndicator
                                }}
                                styles={{
                                  placeholder: provided => ({
                                    ...provided,
                                    color: '#C4C4C4',
                                    fontSize: '14px'
                                  }),
                                  multiValue: provided => ({
                                    ...provided,
                                    backgroundColor: '#333333',
                                    borderRadius: '2px',
                                    color: '#fff'
                                  }),
                                  multiValueLabel: provided => ({
                                    ...provided,
                                    color: '#fff',
                                    fontFamily: 'Actual',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    fontSize: '13px',
                                    lineHeight: '20px',
                                    lineHeight: '21px',
                                    height: '21px',
                                    padding: '.5px'
                                  }),
                                  clearIndicator: provided => ({
                                    ...provided,
                                    color: '#333333'
                                  })
                                }}
                            />
                        }
                    />
                  </div>
                </div>
                <div className={classes.sectionContainer}>
                  <div
                    className={classes.sectionHeaderContainer}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start'
                    }}
                  >
                    <span className={classes.icoEsNotRequired}>
                      Main Purchasing Channel (Name of Distributors) {' '} <span>*</span>
                    </span>
                  </div>
                  <div>
                    <Controller
                        name="purchasingChannelS"
                        rules={{
                            required: true
                        }}
                        control={controlBusinessInfo}
                        as={
                            <ReactSelect
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.name}
                                isSearchable
                                isClearable
                                isMulti={false}
                                options={[...mainPurchasingChannel, { name: 'Other' }]}
                                className={classNames([classes.multiSelect], {
                                    [classes.errorReactSelect]: !!errorsBusinessInfo.purchasingChannelS
                                })}
                                classNamePrefix="select"
                                placeholder="Type in the distributor name"
                                components={{
                                  IndicatorSeparator: null,
                                  DropdownIndicator: ReactSelectDropdownIndicator
                                }}
                                styles={{
                                  placeholder: provided => ({
                                    ...provided,
                                    color: '#C4C4C4'
                                  }),
                                  singleValue: provided => ({
                                    ...provided,
                                    fontFamily: 'Actual',
                                    fontSize: '14px',
                                    color: '#333333',
                                    fontWeight: 400,
                                    fontStyle: 'normal'
                                  })
                                }}
                                defaultMenuIsOpen={false}
                                menuIsOpen={isOpenPurchaseChannelMenu}
                                onInputChange={(_, { action }) => {
                                    if (action === 'input-change') {
                                        setOpenPurchaseChannelMenu(true);
                                    } else if (action === 'menu-close') {
                                        setOpenPurchaseChannelMenu(false);
                                    }
                                }}
                            />
                        }
                    />
                    <p className={classes.helperText}>*If there is no distributor on the list, please type Other.</p>
                  </div>
                  {_.get(purchasingChannelSVal, 'name') === 'Other' &&
                    <div>
                      <TextField
                          fullWidth
                          variant="outlined"
                          name="purchasingChannelT"
                          inputRef={registerBusinessInfo}
                          className={classNames([classes.inputField], {
                              [classes.errorInput]: !!errorsBusinessInfo.purchasingChannelT
                          })}
                      />
                    </div>}
                </div>
                <div className={classes.separatorContainer}>
                  <div
                    className={classes.separator}
                    style={{
                      marginTop: '8px',
                      marginBottom: '25px',
                      gridColumn: 'span 3'
                    }}>
                  </div>
                </div>
                <div className={classes.moduleInstallationSectionContainer}>
                  <div>
                    <span className={classes.icoEsNotRequired}>
                      Module Installation Information {' '} <span>*</span>
                    </span>
                  </div>
                  <div>
                    <div className={classes.moduleInstallationContentContainer}>
                      <div>
                        <span className={classes.icoEsNotRequired}>
                          Installation volume (kW/<span className={classes.annualText}>Annual</span>)
                        </span>
                      </div>
                      <div>
                        <Controller
                            name="installationVolume"
                            rules={{
                                required: true
                            }}
                            control={controlBusinessInfo}
                            render={(
                              { onChange, value }
                            ) => (
                              <select
                                placeholder="Please select"
                                defaultValue={'select'}
                                className={classNames([classes.customSelectField], {
                                  [classes.errorCustomSelect]: !!errorsBusinessInfo.installationVolume
                                })}
                                onChange={(e) => {
                                  onChange(e);
                                  if (installationVolumeValue) {
                                    triggerBusinessInfo('installationVolumeValue');
                                  }
                                }}
                                value={value}
                              >
                                <option value={'select'}>Select...</option>
                                {primaryProducts.installationVolumes.map(m => (
                                  <option key={m.name} value={m.id}>{m.name}</option>
                                ))}
                              </select>
                            )}
                        />
                      </div>
                    </div>
                  </div>
                  {MODULE_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+installationVolumeVal) && (
                    <div>
                      <Controller
                        name="installationVolumeValue"
                        rules={{
                            required: true
                        }}
                        control={controlBusinessInfo}
                        render={(
                          { onChange, value }
                        ) => (
                          <TextField
                              fullWidth
                              variant="outlined"
                              className={classNames([classes.inputField], {
                                  [classes.errorInput]: !!errorsBusinessInfo.installationVolumeValue
                              })}
                              onChange={(e) => {
                                onChange(e);
                                triggerBusinessInfo('installationVolumeValue');
                              }}
                              value={value}
                              InputProps={{
                                endAdornment:
                                  <InputAdornment position="end">
                                    {installationVolumeVal ?
                                    _.get(_.find(primaryProducts.installationVolumes, p => p.id === +installationVolumeVal), 'unit') : ''}
                                  </InputAdornment>,
                                inputComponent: NumberFormat
                              }}
                          />
                        )}
                      />
                      {errorsBusinessInfo.installationVolumeValue &&
                        <Text size="small" style={{ marginTop: '7px' }}>
                          {errorsBusinessInfo.installationVolumeValue.message}
                        </Text>}
                    </div>
                  )}
                </div>
                <div className={classes.moduleInstallationSectionContainer}>
                  <div></div>
                  <div className={classes.moduleInstallationContentContainer}>
                    <div>
                      <span className={classes.icoEsNotRequired}>Primary products in use</span>
                    </div>
                    {modulePrimaryProduct.map((m, idx) => (
                      <div key={m.id} style={{ marginBottom: 0 }}>
                          <Controller
                              name={`modulePrimaryProduct[${idx}].brand`}
                              rules={{
                                  required: true
                              }}
                              control={controlBusinessInfo}
                              as={
                                <ReactSelect
                                    getOptionLabel={option => option}
                                    getOptionValue={option => option}
                                    isSearchable
                                    isClearable
                                    isMulti
                                    options={[...primaryProducts.productBrands]}
                                    className={classNames([classes.multiSelect], {
                                        [classes.errorReactSelect]: !!_.get(errorsBusinessInfo, `modulePrimaryProduct[${idx}].brand`) && !notPrimaryProduct
                                    })}
                                    classNamePrefix="select"
                                    placeholder="Please select"
                                    components={{
                                      IndicatorSeparator: null,
                                      DropdownIndicator: ReactSelectDropdownIndicator
                                    }}
                                    styles={{
                                      placeholder: provided => ({
                                        ...provided,
                                        color: '#C4C4C4',
                                        fontSize: '14px'
                                      }),
                                      multiValue: provided => ({
                                        ...provided,
                                        backgroundColor: '#333333',
                                        borderRadius: '2px',
                                        color: '#fff'
                                      }),
                                      multiValueLabel: provided => ({
                                        ...provided,
                                        color: '#fff',
                                        fontFamily: 'Actual',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: '13px',
                                        lineHeight: '21px',
                                        height: '21px',
                                        padding: '.5px'
                                      }),
                                      clearIndicator: provided => ({
                                        ...provided,
                                        color: '#333333'
                                      })
                                    }}
                                    isDisabled={notPrimaryProduct}
                                />
                              }
                          />
                      </div>
                    ))}
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="notPrimaryProduct"
                          size="medium"
                          icon={
                            <div
                              className={classNames('', {
                                [classes.checkBoxOn]: notPrimaryProduct,
                                [classes.checkBox]: !notPrimaryProduct
                              })}></div>}
                          checkedIcon={
                            <div className={classNames('', {
                              [classes.checkBoxOn]: notPrimaryProduct,
                              [classes.checkBox]: !notPrimaryProduct
                            })}></div>}
                          inputRef={registerBusinessInfo}
                        />
                      }
                      label={
                        <p className={classes.websiteCheckboxText}>
                          None
                        </p>
                      }
                    />
                  </div>
                </div>
                <div className={classes.separatorContainer}>
                  <div
                    className={classes.separator}
                    style={{
                      marginTop: '8px',
                      marginBottom: '25px',
                      gridColumn: 'span 3'
                    }}>
                  </div>
                </div>
                <div className={classes.moduleInstallationSectionContainer}>
                  <div>
                    <span className={classes.icoEsNotRequired}>
                      Battery Installation <br /> Information {' '} <span>*</span>
                    </span>
                  </div>
                  <div>
                    <div className={classes.moduleInstallationContentContainer}>
                      <div>
                        <span className={classes.icoEsNotRequired}>Installation volume (set/<span className={classes.annualText}>Annual</span>)</span>
                      </div>
                      <div>
                        <Controller
                            name="essInstallationVolume"
                            rules={{
                                required: true
                            }}
                            control={controlBusinessInfo}
                            render={(
                              { onChange, value }
                            ) => (
                              <select
                                placeholder="Please select"
                                defaultValue={'select'}
                                className={classNames([classes.customSelectField], {
                                  [classes.errorCustomSelect]: !!errorsBusinessInfo.essInstallationVolume
                                })}
                                onChange={(e) => {
                                  onChange(e);
                                  if (essInstallationVolumeValue) {
                                    triggerBusinessInfo('essInstallationVolumeValue');
                                  }
                                }}
                                value={value}
                              >
                                <option value={'select'}>Select...</option>
                                {primaryProducts.essInstallationVolumes.map(m => (
                                  <option key={m.id} value={m.id}>{m.name}</option>
                                ))}
                              </select>
                            )}
                        />
                      </div>
                    </div>
                  </div>
                  {ESS_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+essInstallationVolume) && (
                    <div>
                      <Controller
                        name="essInstallationVolumeValue"
                        rules={{
                            required: true
                        }}
                        control={controlBusinessInfo}
                        render={(
                          { onChange, value }
                        ) => (
                          <TextField
                              fullWidth
                              variant="outlined"
                              className={classNames([classes.inputField], {
                                  [classes.errorInput]: !!errorsBusinessInfo.essInstallationVolumeValue
                              })}
                              onChange={(e) => {
                                onChange(e);
                                triggerBusinessInfo('essInstallationVolumeValue');
                              }}
                              value={value}
                              InputProps={{
                                endAdornment:
                                  <InputAdornment position="end">
                                    SETS
                                  </InputAdornment>,
                                inputComponent: NumberFormat
                              }}
                          />
                        )}
                      />
                      {errorsBusinessInfo.essInstallationVolumeValue &&
                        <Text size="small" style={{ marginTop: '7px' }}>
                          {errorsBusinessInfo.essInstallationVolumeValue.message}
                        </Text>}
                    </div>
                  )}
                </div>
                <div className={classes.moduleInstallationSectionContainer}>
                  <div></div>
                  <div className={classes.moduleInstallationContentContainer}>
                    <div>
                      <span className={classes.icoEsNotRequired}>Primary products in use</span>
                    </div>
                    {essPrimaryProduct.map((m, idx) => (
                      <div key={m.id} style={{ marginBottom: 0 }}>
                        <Controller
                            name={`essPrimaryProduct[${idx}].brand`}
                            rules={{
                                required: true
                            }}
                            control={controlBusinessInfo}
                            as={
                              <ReactSelect
                                getOptionLabel={option => option}
                                getOptionValue={option => option}
                                isSearchable
                                isClearable
                                isMulti
                                options={[...primaryProducts.essProductBrands]}
                                className={classNames([classes.multiSelect], {
                                    [classes.errorReactSelect]: !!_.get(errorsBusinessInfo, `essPrimaryProduct[${idx}].brand`) && !notEssPrimaryProduct
                                })}
                                classNamePrefix="select"
                                placeholder="Please select"
                                components={{
                                  IndicatorSeparator: null,
                                  DropdownIndicator: ReactSelectDropdownIndicator
                                }}
                                styles={{
                                  placeholder: provided => ({
                                    ...provided,
                                    color: '#C4C4C4',
                                    fontSize: '14px'
                                  }),
                                  multiValue: provided => ({
                                    ...provided,
                                    backgroundColor: '#333333',
                                    borderRadius: '2px',
                                    color: '#fff'
                                  }),
                                  multiValueLabel: provided => ({
                                    ...provided,
                                    color: '#fff',
                                    fontFamily: 'Actual',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    fontSize: '13px',
                                    lineHeight: '20px',
                                    lineHeight: '21px',
                                    height: '21px',
                                    padding: '.5px'
                                  }),
                                  clearIndicator: provided => ({
                                    ...provided,
                                    color: '#333333'
                                  })
                                }}
                                isDisabled={notEssPrimaryProduct}
                              />
                            }
                        />
                      </div>
                    ))}
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="notEssPrimaryProduct"
                          size="medium"
                          icon={
                            <div
                              className={classNames('', {
                                [classes.checkBoxOn]: notEssPrimaryProduct,
                                [classes.checkBox]: !notEssPrimaryProduct
                              })}></div>}
                          checkedIcon={
                            <div className={classNames('', {
                              [classes.checkBoxOn]: notEssPrimaryProduct,
                              [classes.checkBox]: !notEssPrimaryProduct
                            })}></div>}
                          inputRef={registerBusinessInfo}
                        />
                      }
                      label={
                        <p className={classes.websiteCheckboxText}>
                          None
                        </p>
                      }
                    />
                  </div>
                </div>
                <div className={classes.separatorContainer}>
                  <div
                    className={classes.separator}
                    style={{
                      marginTop: '8px',
                      marginBottom: '25px',
                      gridColumn: 'span 3'
                    }}>
                  </div>
                </div>
                <div className={classes.sectionContainer}>
                  <div className={classes.sectionHeaderContainer}>
                    <span className={classes.icoEsNotRequired}>
                      Tax ID {' '} <span>*</span>
                    </span>
                  </div>
                  <div>
                    <Controller
                      name="taxId"
                      control={controlBusinessInfo}
                      render={(
                        { onChange, value},
                        { isDirty }
                      ) => (
                        <TextField
                            fullWidth
                            variant="outlined"
                            className={classNames([classes.inputField], {
                                [classes.errorInput]: !!errorsBusinessInfo.taxId
                            })}
                            value={value}
                            onChange={e => {
                              if (e.target.value === '') {
                                return onChange(e.target.value);
                              }

                              if (/^([0-9]*-?[0-9]{1,}|[0-9]{1,}|[0-9]{1,}-?[0-9]*)$/.test(e.target.value)) {
                                return onChange(e.target.value);
                              }

                              if (!isDirty) {
                                return onChange('');
                              }
                            }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className={classes.sectionContainer}>
                  <div className={classes.sectionHeaderContainer}>
                    <span className={classes.icoEsNotRequired}>
                      W-9 {' '} <span>*</span>
                    </span>
                  </div>
                  <div className={classes.w9Content}>
                    <TextField
                        fullWidth
                        disabled
                        variant="outlined"
                        value={w9Image}
                        className={classNames([classes.inputField], {
                            [classes.errorInput]: !!errorsBusinessInfo.w9
                        })}
                        InputProps={{
                          endAdornment: (
                            <label htmlFor="raised-button-file">
                                <Button variant="raised" component="span" className={classes.checkDuplicateBtn}>Choose file</Button>
                            </label>
                          )
                        }}
                    />
                    <input
                        ref={registerBusinessInfo}
                        name={"w9"}
                        accept=".bmp,.jpg,.png,.gif,.zip,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.hwp,.gul,.pdf,.tif,.tiff,.txt,.eps"
                        className={classes.w9ImageInput}
                        id="raised-button-file"
                        type="file"
                        onChange={e => setW9Image(e.target.value)}
                    />
                    <p className={classes.helperText}>
                      *If you are unable to provide a W-9 form, please upload a blank sheet of paper as we no longer require a W-9 for Q.PARTNER approval.
                    </p>
                    <Text size="small" style={{ marginTop: '7px' }}>
                    </Text>
                  </div>
                </div>
                <div className={classes.sectionContainer}>
                  <div className={classes.sectionHeaderContainer} style={{ alignItems: 'flex-start' }}>
                    <span className={classes.icoEsNotRequired}>Note/Overview of Company</span>
                  </div>
                  <div>
                    <Controller
                        name="description"
                        rules={{
                            required: true
                        }}
                        control={controlBusinessInfo}
                        as={
                            <TextareaAutosize
                                rowsMin={5}
                                className={classNames([classes.overviewTextarea], {
                                    [classes.errorTextarea]: !!errorsBusinessInfo.description
                                })}
                            />
                        }
                    />
                  </div>
                </div>
                <p className={classes.requiredText} style={{ marginTop: '35px' }}>* Required</p>
                <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '25px' }}></div>
                <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
                  <CustomButton color="secondary" onClick={goBack} className="backBtn">Back</CustomButton>
                  <CustomButton onClick={handleSubmitBusinessInfo(onSubmitBusinessInfo, onSubmitBusinessInfoFailed)}>Next</CustomButton>
                </Box>
              </div>
            )}
            {progress === 3 && (
              <div className={classes.stepContainer}>
                <div className={classes.reviewContainer}>
                {isSmallScreen ? (
                    <>
                      <div className={classes.reviewSectionHeaderSmallScreen}>
                        <h5>Account Information</h5>
                        <Button className={classes.editBtn} onClick={() => setProgress(0)}>
                          <span>Edit</span>
                          <img src={IconEdit} title='Edit' />
                        </Button>
                      </div>
                      <div className={classes.reviewSectionContainer}>
                        <div className={classes.reviewSectionContent} style={{ borderTop: '1px solid rgba(0, 0, 0, .1)' }}>
                            <h6>First Name</h6>
                            <p>{getValuesAccountInfo('firstName')}</p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={classes.reviewSectionContainer}>
                      <h5>Account Information</h5>
                      <div className={classes.reviewSectionContent} style={{ borderTop: '1px solid rgba(0, 0, 0, .1)' }}>
                          <h6>First Name</h6>
                          <p>{getValuesAccountInfo('firstName')}</p>
                      </div>
                      <Button className={classes.editBtn} onClick={() => setProgress(0)}>
                        <span>Edit</span>
                        <img src={IconEdit} title='Edit' />
                      </Button>
                    </div>
                  )}
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>Last Name</h6>
                      <p>{getValuesAccountInfo('lastName')}</p>
                    </div>
                  </div>
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>Title</h6>
                      <p>{getValuesAccountInfo('title') === 'other'
                              ? getValuesAccountInfo('otherTitle')
                              : getValuesAccountInfo('title')}</p>
                    </div>
                  </div>
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>ID (E-mail)</h6>
                      <p>{getValuesAccountInfo('email')}</p>
                    </div>
                  </div>
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>Password</h6>
                      <p>{_.repeat('*', _.size(getValuesAccountInfo('password')))}</p>
                    </div>
                  </div>
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>Confirm Password</h6>
                      <p>{_.repeat('*', _.size(getValuesAccountInfo('confirmPassword')))}</p>
                    </div>
                  </div>
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>Main Contact Number</h6>
                      <p>{getValuesAccountInfo('mainContactNumber')}</p>
                    </div>
                  </div>
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>Business Address</h6>
                      <p style={{ marginTop: '15px', marginBottom: '15px' }}>
                        {renderAddress([
                          getValuesAccountInfo('address'),
                          getValuesAccountInfo('addressCont'),
                          getValuesAccountInfo('city'),
                          getValuesAccountInfo('state'),
                          getValuesAccountInfo('zipCode')
                        ])}
                      </p>
                    </div>
                  </div>
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>Shipping Address</h6>
                      <p style={{ marginTop: '15px', marginBottom: '15px' }}>
                        {getValuesAccountInfo('hasShippingAddress') ?
                          renderAddress([
                            getValuesAccountInfo('shippingAddress'),
                            getValuesAccountInfo('shippingAddress2'),
                            getValuesAccountInfo('shippingCity'),
                            getValuesAccountInfo('shippingState'),
                            getValuesAccountInfo('shippingZipCode')
                          ])
                          :
                          renderAddress([
                            getValuesAccountInfo('address'),
                            getValuesAccountInfo('addressCont'),
                            getValuesAccountInfo('city'),
                            getValuesAccountInfo('state'),
                            getValuesAccountInfo('zipCode')
                          ])}
                      </p>
                    </div>
                  </div>
                </div>

                <div className={classes.reviewContainer}>
                  {isSmallScreen ? (
                    <>
                      <div className={classes.reviewSectionHeaderSmallScreen}>
                        <h5>Company Information</h5>
                        <Button className={classes.editBtn} onClick={() => setProgress(1)}>
                          <span>Edit</span>
                          <img src={IconEdit} title='Edit' />
                        </Button>
                      </div>
                      <div className={classes.reviewSectionContainer}>
                        <div className={classes.reviewSectionContent} style={{ borderTop: '1px solid rgba(0, 0, 0, .1)' }}>
                            <h6>Company</h6>
                            <p>{getValuesCompanyInfo('company')}</p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={classes.reviewSectionContainer}>
                      <h5>Company Information</h5>
                      <div className={classes.reviewSectionContent} style={{ borderTop: '1px solid rgba(0, 0, 0, .1)' }}>
                          <h6>Company</h6>
                          <p>{getValuesCompanyInfo('company')}</p>
                      </div>
                      <Button className={classes.editBtn} onClick={() => setProgress(1)}>
                        <span>Edit</span>
                        <img src={IconEdit} title='Edit' />
                      </Button>
                    </div>
                  )}
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>Main Business Type</h6>
                      <p>{renderMainBusinessType(getValuesCompanyInfo('partnerType'))}</p>
                    </div>
                  </div>
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>Home page URL</h6>
                      <p>{!getValuesCompanyInfo('notWebsite') && getValuesCompanyInfo('website')}</p>
                    </div>
                  </div>
                </div>

                <div className={classes.reviewContainer} style={{ marginBottom: '90px' }}>
                {isSmallScreen ? (
                    <>
                      <div className={classes.reviewSectionHeaderSmallScreen}>
                        <h5>Business Information</h5>
                        <Button className={classes.editBtn} onClick={() => setProgress(2)}>
                          <span>Edit</span>
                          <img src={IconEdit} title='Edit' />
                        </Button>
                      </div>
                      <div className={classes.reviewSectionContainer}>
                        <div className={classes.reviewSectionContent} style={{ borderTop: '1px solid rgba(0, 0, 0, .1)' }}>
                            <h6>Sales Region(s) - States</h6>
                            <p>{renderSalesRegion(getValuesBusinessInfo('salesRegion'))}</p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={classes.reviewSectionContainer}>
                      <h5>Business Information</h5>
                      <div className={classes.reviewSectionContent} style={{ borderTop: '1px solid rgba(0, 0, 0, .1)' }}>
                          <h6>Sales Region(s) - States</h6>
                          <p>{renderSalesRegion(getValuesBusinessInfo('salesRegion'))}</p>
                      </div>
                      <Button className={classes.editBtn} onClick={() => setProgress(2)}>
                        <span>Edit</span>
                        <img src={IconEdit} title='Edit' />
                      </Button>
                    </div>
                  )}
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>Main Purchasing Channel (Name of Distributors)</h6>
                      <p>{_.get(getValuesBusinessInfo('purchasingChannelS'), 'name') === 'Other'
                            ? getValuesBusinessInfo('purchasingChannelT')
                            : _.get(getValuesBusinessInfo('purchasingChannelS'), 'name')}</p>
                    </div>
                  </div>
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>Module Installation Information</h6>
                      <p>
                        <span style={{ display: 'block', marginTop: '15px' }}>
                          {renderReviewModuleInformation()}
                        </span>
                        <span style={{ display: 'block', marginBottom: '15px' }}>
                          Primary products in use: {notPrimaryProduct ? 'None' : _.join(_.get(getValuesBusinessInfo('modulePrimaryProduct'), '[0].brand'), ', ')}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>Battery Installation Information</h6>
                      <p>
                        <span style={{ display: 'block', marginTop: '15px' }}>
                          Installation volume (kW/<span className={classes.annualText}>Annual</span>): {' '}
                          {_.get(primaryProducts.essInstallationVolumes.find(e => e.id === +getValuesBusinessInfo('essInstallationVolume')), 'name')}
                          {ESS_INSTALLATION_VOLUME_ID_ADDITIONAL_TEXT.includes(+getValuesBusinessInfo('essInstallationVolume'))
                          && ` - ${getValuesBusinessInfo('essInstallationVolumeValue')} Sets`}
                        </span>
                        <span style={{ display: 'block', marginBottom: '15px' }}>
                          Primary products in use: {notEssPrimaryProduct ? 'None' : _.join(_.get(getValuesBusinessInfo('essPrimaryProduct'), '[0].brand'), ', ')}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>Tax ID</h6>
                      <p>{getValuesBusinessInfo('taxId')}</p>
                    </div>
                  </div>
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>W-9</h6>
                      <p>{_.get(getValuesBusinessInfo('w9'), '[0].name')}</p>
                    </div>
                  </div>
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>Overview of Company/Business</h6>
                      <p style={{ marginTop: '15px', marginBottom: '15px', wordBreak: 'break-all' }}>{getValuesBusinessInfo('description')}</p>
                    </div>
                  </div>
                </div>

                <div className={classes.reviewContainer} style={{ marginBottom: '50px' }}>
                  <div className={classes.reviewSectionContainer} style={{ alignItems: 'flex-start' }}>
                    <div>
                      <h5>
                        How did you hear about the Program?
                        <span className={classes.icoEsNotRequired}>
                          <span>{' '} *</span>
                        </span>
                      </h5>
                    </div>
                    <div className={classes.reviewSectionContent} style={{ borderBottom: 0, gridTemplateColumns: '1fr' }}>
                      <Controller
                          name="survey"
                          rules={{
                              required: true
                          }}
                          control={controlLastInfo}
                          as={
                              <RadioGroup>
                                  <div className={classes.surveySection} style={{ marginTop: 0 }}>
                                      <div>
                                          <FormControlLabel
                                              value="7"
                                              control={
                                                <Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />
                                              }
                                              label="Through event"
                                          />
                                      </div>
                                      <div>
                                          <TextField
                                              fullWidth
                                              variant="outlined"
                                              placeholder="Name of event"
                                              name="surveyText7"
                                              inputRef={registerLastInfo}
                                              className={classNames([classes.inputField], {
                                                  [classes.errorInput]: !!errorsLastInfo.surveyText7
                                              })}
                                          />
                                      </div>
                                  </div>
                                  <div className={classes.surveySection}>
                                      <div>
                                          <FormControlLabel
                                              value="2"
                                              control={
                                                <Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />
                                              }
                                              label="Through web ads"
                                          />
                                      </div>
                                      <div>
                                          <TextField
                                              fullWidth
                                              variant="outlined"
                                              placeholder="Name of media"
                                              name="surveyText2"
                                              inputRef={registerLastInfo}
                                              className={classNames([classes.inputField], {
                                                  [classes.errorInput]: !!errorsLastInfo.surveyText2
                                              })}
                                          />
                                      </div>
                                  </div>
                                  <div className={classes.surveySection}>
                                      <div>
                                          <FormControlLabel
                                              value="3"
                                              control={
                                                <Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />
                                              }
                                              label="Through social media"
                                          />
                                      </div>
                                      <div>
                                          <TextField
                                              fullWidth
                                              variant="outlined"
                                              name="surveyText3"
                                              inputRef={registerLastInfo}
                                              className={classNames([classes.inputField], {
                                                  [classes.errorInput]: !!errorsLastInfo.surveyText3
                                              })}
                                          />
                                      </div>
                                  </div>
                                  <div className={classes.surveySection}>
                                      <div>
                                          <FormControlLabel
                                              value="4"
                                              control={
                                                <Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />
                                              }
                                              label="Through other installers"
                                          />
                                      </div>
                                      <div>
                                          <TextField
                                              fullWidth
                                              variant="outlined"
                                              placeholder="Name of installers"
                                              name="surveyText4"
                                              inputRef={registerLastInfo}
                                              className={classNames([classes.inputField], {
                                                  [classes.errorInput]: !!errorsLastInfo.surveyText4
                                              })}
                                          />
                                      </div>
                                  </div>
                                  <div className={classes.surveySection}>
                                      <div>
                                          <FormControlLabel
                                              value="6"
                                              control={
                                                <Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />
                                              }
                                              label="Through Q CELLS"
                                          />
                                      </div>
                                      <div>
                                          <TextField
                                              fullWidth
                                              variant="outlined"
                                              placeholder="Name of referrer or promotion"
                                              name="surveyText6"
                                              inputRef={registerLastInfo}
                                              className={classNames([classes.inputField], {
                                                  [classes.errorInput]: !!errorsLastInfo.surveyText6
                                              })}
                                          />
                                      </div>
                                  </div>
                                  <div className={classes.surveySection}>
                                      <div>
                                          <FormControlLabel
                                              value="1"
                                              control={
                                                <Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />
                                              }
                                              label="Through distribution"
                                          />
                                      </div>
                                      <div>
                                          <TextField
                                              fullWidth
                                              variant="outlined"
                                              placeholder="Name of company or referrer"
                                              name="surveyText1"
                                              inputRef={registerLastInfo}
                                              className={classNames([classes.inputField], {
                                                  [classes.errorInput]: !!errorsLastInfo.surveyText1
                                              })}
                                          />
                                      </div>
                                  </div>
                                  <div className={classes.surveySection}>
                                      <div>
                                          <FormControlLabel
                                              value="5"
                                              control={
                                                <Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />
                                              }
                                              label="Other"
                                          />
                                      </div>
                                      <div>
                                          <TextField
                                              fullWidth
                                              variant="outlined"
                                              name="surveyText5"
                                              inputRef={registerLastInfo}
                                              className={classNames([classes.inputField], {
                                                  [classes.errorInput]: !!errorsLastInfo.surveyText5
                                              })}
                                          />
                                      </div>
                                  </div>
                              </RadioGroup>
                            }
                        />
                    </div>
                  </div>
                </div>

              <div className={classes.agreeBox}>
                  <Grid container>
                      <Grid item xs={12}>
                          <FormControlLabel control={<Checkbox name="agree1" size="medium" inputRef={registerLastInfo}
                            icon={<div className={classNames('', { [classes.checkBoxOn]: agree1, [classes.checkBox]: !agree1 })}></div>}
                            checkedIcon={<div className={classNames('', { [classes.checkBoxOn]: agree1, [classes.checkBox]: !agree1 })}></div>} />}
                            label={
                              <p>
                                  I have read and accepted <a href="/terms" target="_blank" className={classes.agreeBoxLink}>the Terms of Use</a> and <a href="/policy" target="_blank" className={classes.agreeBoxLink}>the Privacy Policy</a>.
                              </p>
                            } />
                      </Grid>
                      <Grid item xs={12}>
                          <FormControlLabel control={<Checkbox name="agree2" size="medium" inputRef={registerLastInfo}
                            icon={<div className={classNames('', { [classes.checkBoxOn]: agree2, [classes.checkBox]: !agree2 })}></div>}
                            checkedIcon={<div className={classNames('', { [classes.checkBoxOn]: agree2, [classes.checkBox]: !agree2 })}></div>} />}
                            label={
                              <p>
                                  I have read and agreed to <a href="/qpartner/nda" target="_blank" className={classes.agreeBoxLink}>the Confidentiality Agreement</a>.
                              </p>
                          } />
                      </Grid>
                      <Grid item xs={12}>
                          <FormControlLabel control={<Checkbox name="receptionYn" size="medium" inputRef={registerLastInfo}
                            icon={<div className={classNames('', { [classes.checkBoxOn]: receptionYn, [classes.checkBox]: !receptionYn })}></div>}
                            checkedIcon={<div className={classNames('', { [classes.checkBoxOn]: receptionYn, [classes.checkBox]: !receptionYn })}></div>} />}
                            className={classes.agreeNewletter}
                            label={
                              <div style={{ marginTop: '5px' }}>
                                  <p>
                                      Sign up for the Q.PARTNER Newsletter to receive exclusive promotions, latest news, and new product notifications.
                                  </p>
                                  <p>
                                      Stay tuned for exciting updates, including even more ways to get additional benefits.
                                  </p>
                              </div>
                          } />
                      </Grid>
                  </Grid>
              </div>
              <p className={classes.requiredText} style={{ marginTop: '20px' }}>* Required</p>
              <div className={classes.separator} style={{ marginTop: '10px' }}></div>
              <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
                <CustomButton color="secondary" onClick={goBack} className="backBtn">Back</CustomButton>
                <CustomButton onClick={handleSubmitLastInfo(onSubmitLastInfo, onSubmitLastInfoFailed)} disabled={loading}>
                  { loading ? <CustomLoading size={18} color="#fff" ></CustomLoading> : 'Submit' }
                </CustomButton>
              </Box>
              </div>
            )}
          </PageContainer>
      </div>
      <Modal onClose={handleClose} open={open} className={classes.modal}>
        <Paper>
            <Grid container justify="flex-end">
                <IconButton onClick={handleClose}>
                    <CloseIcon fontSize="default" />
                </IconButton>
            </Grid>
            <Text
                style={{ textAlign: 'center', paddingBottom: '30px' }}
                color="dark"
                size="h3"
                className={classes.header}
            >
                Check duplicate by email domain or name
            </Text>

            <Grid
                container
                justify="flex-start"
                style={{ paddingBottom: '50px', width: '80%', margin: 'auto' }}
            >
                <Grid item style={{ marginRight: '10px', marginBottom: '10px' }} xs={12}>
                  <Text
                      color="dark"
                      size="h4"
                      className={classes.header}
                  >
                      Search By
                  </Text>
                  <RadioGroup row aria-label="position" name="searchBy" value={searchCompany.searchBy} onChange={handleChangeSearchCompany}>
                    <FormControlLabel
                        value="1"
                        control={
                          <Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />
                        }
                        label="Email Domain"
                    />
                    <FormControlLabel
                        value="2"
                        control={
                          <Radio icon={<div className={classes.radio}></div>} checkedIcon={<div className={classes.radioOn}></div>} />
                        }
                        label="Name"
                    />
                  </RadioGroup>
                  <Text size="small">
                    *If you have your company domain, we recommend that you check duplicate by your email domain. If you use a generic email domain, please search by your company name.
                  </Text>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                  <Text
                      color="dark"
                      size="h4"
                      className={classes.header}
                  >
                      Company
                  </Text>
                  <TextField
                      fullWidth
                      variant="outlined"
                      name="searchText"
                      className={classes.inputField}
                      value={searchCompany.searchText}
                      onChange={handleChangeSearchCompany}
                  />
                  {searchCompany.showCtaBtnAndText && (
                    <Text size="small" style={{ marginTop: '7px' }}>
                      Your company already has an account created under {searchCompany.companyName} by a primary user {searchCompany.userName}, {searchCompany.email}. You can have your primary user easily create a sub-account. {' '}
                      <Text size="small" style={{ display: 'inline-block' }}>
                        <Link to="/support/faq/1" className={classes.agreeBoxLink}>Learn how to create a sub account</Link>
                      </Text>
                    </Text>
                  )}
                </Grid>
                <Grid item xs={12} container justify="center">
                  <CustomButton onClick={handleCheckDuplicateCompany} disabled={loadingCheckDuplicateCompany}>
                    { loadingCheckDuplicateCompany ? <CustomLoading size={16} color="#fff" ></CustomLoading> : 'Submit' }
                  </CustomButton>
                </Grid>
            </Grid>
        </Paper>
    </Modal>
    </>
  );
}
