import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Issue from '../../../icons/iconIssuePv.svg';
import ClaimInfo from '../../../icons/claim_info.svg';
import Evidence from '../../../icons/issue.svg';
import SystemInfo from '../../../icons/sys_info.svg';
import SoftwareVersion from '../../../icons/software_info.svg';
import FieldDetail from '../../../icons/field_info.svg';
import Purchase from '../../../icons/purchase_info.svg';
import Review from '../../../icons/review.svg';
import Labor from '../../../icons/labor.svg';

const BarContainer = styled.div`
  padding-top: 47px !important;
  background-color: #F6F6F6;

  @media (max-width: 780px) {
    padding-top: 30px !important;
  }
`;

const Bar = styled.ul`
  display: flex;
  width: 90%;
  overflow: hidden;
  color: lightgrey;
  margin: auto !important;
  
  @media (min-width: 1280px) {
    width: 80%;
    min-width: 650px;
  }

  @media (max-width: 780px) {
    width: 100%;
  }
`;

const ProgressBarStep = styled.li`
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
  z-index: 1;
  text-align: center;
  font-family: 'Actual_Light';
  border-bottom: 5px solid ${({ current }) => current ? '#02A3EC': '#F6F6F6'};

  &:hover {
    cursor: ${({ isEnableClick }) => isEnableClick ? 'pointer' : 'default' };
  }
`;

const IconWrapper = styled.div`
  width: 58.24px;
  height: 47px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  border-radius: 50%;
  margin: 0 auto 20px auto;

  color: ${({ current, theme }) =>
  current ? theme.colors.light : theme.colors.secondaryDark};

  @media (max-width: 780px) {
    margin-bottom: 14px;
    width: 50.4px;
    height: 45px;
  }
`;

const Title = styled.h4`
  font-size: 16px;
  font-family: 'Actual';
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  color: ${({ current }) => current ? '#02A3EC' : '#000'};
  margin-bottom: 21px;
  opacity: ${({ current }) => current ? 1 : 0.3};

  @media (max-width: 780px) {
    font-size: 14px;
    margin-bottom: 12px;
  }

  @media (max-width: 680px) {
    font-size: 13px;
  }

  @media (max-width: 375px) {
    font-size: 12px;
  }
`;

const stepsDefaultRegister = [

  { step: 1, text: 'Issue', icon: Issue },
  { step: 2, text: 'System Info', icon: SystemInfo },
  { step: 3, text: 'Purchase Details', icon: Purchase },
  { step: 4, text: 'Review Info', icon: Review },
];

const stepDefaultPV = [
  { step: 1, text: 'Issue', icon: Issue },
  { step: 2, text: 'Evidence', icon: Evidence },
  { step: 3, text: 'Purchase Info', icon: Purchase },
  { step: 4, text: 'Review Info', icon: Review },
]

const ProgressBar = ({ currentStep, goToStep, isEnableClick, type }) => {

  const [steps, setStep] = useState(stepsDefaultRegister);

  const handleGoToStep = step => {
    if (isEnableClick) {
      goToStep(step);
    }
  };

  useEffect(() => {
    if(type !== 1) {
      setStep(stepDefaultPV)
    }
  }, [type])

  return(
    <BarContainer>
      <Bar>
        {steps.map(({ step, text, icon }) => (
          <ProgressBarStep current={step <= currentStep} onClick={() => handleGoToStep(step)} isEnableClick={isEnableClick}>
            <IconWrapper current={step <= currentStep}>
              <img src={icon} title={text} />
            </IconWrapper>
            <Title current={step <= currentStep}>{text}</Title>
          </ProgressBarStep>
        ))}
      </Bar>
    </BarContainer>
  )
}

export default ProgressBar;
