import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import UserInfo from '../components/UserInfo';
import Issue from '../components/Issue';
import SystemInfo from '../components/SystemInfo';
import SoftwareVersion from '../components/SoftwareVersion';
import FieldDetail from '../components/FieldDetail';
import PurchaseInfo from '../components/PurchaseInfo';
import { notiFailed, notiSuccess } from '../../../utils/helpers';
import _ from 'lodash';
import { useAuth } from '../../../contexts/auth';
import IntroductionClaim from '../components/IntroductionClaim';
import ProgressBar from '../components/ProgressBar';
import styled from 'styled-components';
import ReviewEss from '../components/ReviewEss';
import { useParams } from 'react-router-dom';
import { claimService } from '../../../services';
import LaborWarranty from '../components/LaborWarranty';
import CustomLoading from '../../../common/components/CustomLoading';
import useStyles from '../components/useStyles';
import { keyEss } from '../components/keyEss';
import { defaultStateEss } from '../components/defaultStateEss';
import { schemaEss } from '../components/schemaEss';
import Notification from '../components/Notification';

const ContainerClaimFormEss = styled.div `
    width: 80%;
    margin: 36px auto 70px auto;
    display: grid;
    
    @media (max-width: 1366px) {
      grid-template-columns: auto;
      grid-column-gap: 0px;
      width: 90%;
    }
`


function EditClaimEss() {

  const user = useAuth();
  const { id } = useParams();
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [fileErrorLog, setFileErrorLog] = useState([]);
  const [fileEvidence, setFileEvidence] = useState([]);
  const [fileLaborWarranty, setFileLaborWarranty] = useState([]);
  const [keyUpload, setKeyUpload] = useState('');

  const { register, handleSubmit, errors, control, watch, setValue, getValues, formState, clearErrors } = useForm({
    resolver : yupResolver(schemaEss),
    shouldUnregister: false,
    shouldFocusError: false,
    enableReinitialize: true,
    defaultValues: {
      installerEmail : _.get(user, 'email'),
      installerName : _.get(user , 'fullName'),
      installerContact : _.get(user , 'fullName'),
      installerCity : '',
      installerAddress1 : '',
      installerAddress2 : '',
      installerState : 'select',
      installerZipcode : '',
      installerPhone : '',
      checkedEmail : false,

      infoClaimName : 'ESS',
      claimType : 'select',
      ...defaultStateEss,
      installation_date_pv : null,
      comission_date_pv : null,
      affected_module_serial_number : 'select',
      region_pv : 'select',
      site_module_layout_pv : 'select',
      inverter_type_pv : 'select',
      purchase_date_info : null
    }
  });

  const projectId = watch('projectSys');

  async function getDetailProject(id) {
    try {
      const info = await claimService.getProjectById(id);

      info.data.projectName && setValue('projectNameSys' , info.data.projectName);
      info.data.projectAddress && setValue('addressSys' , info.data.projectAddress);
      info.data.installationDate && setValue('installationDateSys' , info.data.installationDate);
      info.data.systemType && setValue('systemTypeSys' , info.data.systemType);
      info.data.rackingProductName && setValue('productNameSys' , info.data.rackingProductName);
      info.data.nameOfPurchasingChannel && setValue('distributorName' , info.data.nameOfPurchasingChannel);
      setValue('totalPvPowerInstalled', info.data.systemSize);
      const listSerial = info.data.serialNumbers.map(item => {
        return item.serialNumber
      })
      setValue('inverterSerialNumberSys' , listSerial.toString());

      clearErrors('projectNameSys')
      clearErrors('addressSys')
      clearErrors('installationDateSys')
      clearErrors('systemTypeSys')
      clearErrors('productNameSys')
      clearErrors('inverterSerialNumberSys')

    }catch (e) {

    }
  }

  useEffect(() => {
    if(projectId !== 'select') {
      getDetailProject(projectId);
    }
  }, [projectId])

  function setGroup(url) {

    let typeGroup = '';

    if(/^.*error_logs.jpg.*$/.test(url)) {
      typeGroup = 1
    }

    if(/^.*other_evidence.jpg.*$/.test(url)) {
      typeGroup = 2
    }

    if(/^.*warranty.jpg.*$/.test(url)) {
      typeGroup = 3
    }



    const actions = {
      1 : 'errorLog',
      2 : 'evidence',
      3 : 'warranty',
      'default' : 'errorLog'
    }
    return actions[typeGroup] || actions['default'];
  }

  function suggestionsImage(strImage) {
    const arrayImage = strImage.split(',').map(item => {
      return {
        url : item,
        group : setGroup(item)
      }
    });

    return _.groupBy(arrayImage, 'group');

  }


  async function getDetailClaim() {
    try {
      setLoadingDetail(true);
      const info = await claimService.getDetailClaim(id)
      setValue('installerCity', _.get(info.data, 'draftData.installerCity'))
      setValue('installerName', _.get(info.data, 'draftData.installerName'))
      setValue('installerAddress1', _.get(info.data, 'draftData.installerAddress1'))
      setValue('installerState', _.get(info.data, 'draftData.installerState'))
      setValue('installerContact', _.get(info.data, 'draftData.installerContact'))
      setValue('installerZipcode', _.get(info.data, 'draftData.installerZipcode'))
      setValue('installerEmail', _.get(info.data, 'draftData.installerEmail'))
      setValue('installerAddress2', _.get(info.data, 'draftData.installerAddress2'))
      setValue('installerPhone', _.get(info.data, 'draftData.installerPhone'))
      setValue('checkedEmail', _.get(info.data, 'draftData.checkedEmail'))
      setValue('submitterEmail', _.get(info.data, 'draftData.submitterEmail'))
      setValue('claimType', _.get(info.data, 'draftData.claimType'))
      setValue('description', _.get(info.data, 'draftData.description'))
      setValue('errorLogName', _.get(info.data, 'draftData.errorLogName'))
      setValue('projectSys', _.get(info.data, 'draftData.projectSys'))
      setValue('inverterPowerSys', _.get(info.data, 'draftData.inverterPowerSys'))
      setValue('batterySerialNumberSys', _.get(info.data, 'draftData.batterySerialNumberSys'))
      setValue('batteryTypeSys', _.get(info.data, 'draftData.batteryTypeSys'))
      setValue('numberOfBattery', _.get(info.data, 'draftData.numberOfBattery'))
      setValue('pocketWifiSys', _.get(info.data, 'draftData.pocketWifiSys'))
      setValue('dsp1SwVersion', _.get(info.data, 'draftData.dsp1SwVersion'))
      setValue('armSwVersion', _.get(info.data, 'draftData.armSwVersion'))
      setValue('batterySwVersion', _.get(info.data, 'draftData.batterySwVersion'))
      setValue('pv1', _.get(info.data, 'draftData.pv1'))
      setValue('pv2', _.get(info.data, 'draftData.pv2'))
      setValue('pv3', _.get(info.data, 'draftData.pv3'))
      setValue('pv4', _.get(info.data, 'draftData.pv4'))
      setValue('loadL1Voltage', _.get(info.data, 'draftData.loadL1Voltage'))
      setValue('loadL2Voltage', _.get(info.data, 'draftData.loadL2Voltage'))
      setValue('criticalConnected', _.get(info.data, 'draftData.criticalConnected'))
      setValue('totalPvPowerInstalled', _.get(info.data, 'draftData.totalPvPowerInstalled'))
      setValue('operationalModeUse', _.get(info.data, 'draftData.operationalModeUse'))

      setValue('distributorName', _.get(info.data, 'draftData.distributorName'))
      setValue('distributorCity', _.get(info.data, 'draftData.distributorCity'))
      setValue('poNumber', _.get(info.data, 'draftData.poNumber'))
      setValue('purchaseDate', _.get(info.data, 'draftData.purchaseDate', null))
      setValue('projectNameSys' , _.get(info.data, 'draftData.projectNameSys', ''));
      setValue('addressSys' , _.get(info.data, 'draftData.addressSys', ''));
      setValue('installationDateSys' , _.get(info.data, 'draftData.installationDateSys', null));
      setValue('systemTypeSys' , _.get(info.data, 'draftData.systemTypeSys', ''));
      setValue('productNameSys' , _.get(info.data, 'draftData.productNameSys', ''));
      setValue('distributorName' , _.get(info.data, 'draftData.distributorName', ''));
      setValue('totalPvPowerInstalled', _.get(info.data, 'draftData.totalPvPowerInstalled', ''));
      setKeyUpload(_.get(info.data, 'draftData.keyUpload', ''))
      const makeData = suggestionsImage(_.get(info.data, 'draftData.attachments'))

      setFileErrorLog(makeData?.errorLog || [])
      setFileEvidence(makeData?.evidence || [])
      setFileLaborWarranty(makeData?.warranty || [])

    }catch (e) {

    }
    finally {
      setLoadingDetail(false);
    }
  }

  useEffect(() => {
    fileEvidence.length > 0 ? setValue('otherEvidence','done') : setValue('otherEvidence', '')
  }, [fileEvidence.length])

  useEffect(() => {
    getDetailClaim()
  }, [id])


  const showErrorMessage = (errors, errorKeys = []) => {
    let message = '';

    for (let i = 0; i < errorKeys.length; i++) {
      if (errors[errorKeys[i]]) {
        message = errors[errorKeys[i]].message;
        break;
      }
    }

    return notiFailed(message);
  };

  const onSubmitFailed = async errors => {
    showErrorMessage(errors, keyEss)
  };

  const goBack = () => setCurrentStep(currentStep - 1);
  const next = () => setCurrentStep(currentStep + 1);
  const handleGoToReview = () => {
    handleSubmit(() => setCurrentStep(6), onSubmitFailed)();
  };

  const onSubmit = async data => {
    const a = {
      projectAddress : '',


    }
  }

  async function handleSaveDraft() {
    const currentData = getValues();
    let data = _.pickBy(currentData, value => {
      if (value === 'select') {
        return false;
      }

      if (!value) {
        return false;
      }

      if (_.get(value, 'length') === 0) {
        return false;
      }

      return true;
    });

    let objKey = {};
    if(objKey !== '') {
      objKey = {
        keyUpload
      }
    }

    const attackError = fileErrorLog.length > 0 && fileErrorLog.map(item => {
      return item.url
    }, []);

    const attackEvidence = fileEvidence.length > 0 && fileEvidence.map(item => {
      return item.url
    }, []);

    const attackLabor = fileLaborWarranty.length > 0 && fileLaborWarranty.map(item => {
      return item.url
    }, [])

    let combineFile = [];



    attackError.length > 0 && combineFile.push(attackError);
    attackEvidence.length > 0 && combineFile.push(attackEvidence);
    attackLabor.length > 0 && combineFile.push(attackLabor);


    let attachments = {}

    if(combineFile.length > 0) {
      attachments = {
        attachments : combineFile.toString() || ''
      }
    }

    const makePayload = {
      ...data,
      id
      , ...objKey,
      ...attachments,
      typeClaim: 'ess',
      isSubmitted: false
    }



    try {
      setLoading(true);
      await claimService.saveDraftClaim(makePayload);
      notiSuccess('Claim ESS draft successfully!');


    }catch (e) {

    }finally {
      setLoading(false);
    }
  }

  const goToStep = step => setCurrentStep(step);


  const renderStepClaimEss = () => {

    switch(currentStep) {
      case 0:
        return (
          <UserInfo
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
            handleSaveDraft={handleSaveDraft}
            setValue={setValue}
            loading={loading}
            getValues={getValues}
            text={'We will e-mail you when your ticket is updated.'}
          />
        )
      case 1:
        return (
          <Issue
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
            type={1}
            setValue={setValue}
            clearErrors={clearErrors}
            loading={loading}
            handleSaveDraft={handleSaveDraft}
            fileErrorLog={fileErrorLog}
            setFileErrorLog={setFileErrorLog}
            fileEvidence={fileEvidence}
            setFileEvidence={setFileEvidence}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
          />
        )

      case 2:
        return (
          <SystemInfo
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            handleSaveDraft={handleSaveDraft}
            loading={loading}
            clearErrors={clearErrors}
          />
        )
      case 3:
        return (
          <SoftwareVersion
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
            handleSaveDraft={handleSaveDraft}
            loading={loading}
          />
        )
      case 4:
        return (
          <FieldDetail
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
            handleSaveDraft={handleSaveDraft}
            loading={loading}
          />
        )
      case 5:
        return (
          <PurchaseInfo
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
            handleGoToReview={handleGoToReview}
            watch={watch}
            handleSaveDraft={handleSaveDraft}
            loading={loading}
          />
        )
      case 6:
        return (
          <LaborWarranty
            goBack={goBack}
            next={next}
            register={register}
            control={control}
            errors={errors}
            type={1}
            setValue={setValue}
            clearErrors={clearErrors}
            loading={loading}
            fileLaborWarranty={fileLaborWarranty}
            setFileLaborWarranty={setFileLaborWarranty}
            handleSaveDraft={handleSaveDraft}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
          />
        )
      case 7:
        return (
          <ReviewEss
            loading={loading}
            goBack={goBack}
            goToStep={goToStep}
            getValues={getValues}
            type={1}
            urlErrors={fileErrorLog}
            urlEvidence={fileEvidence}
            urlLaborWarranty={fileLaborWarranty}
            watch={watch}
          />
        )
      default:
        return (
          <div>Claim Info</div>
        )
    }
  }

  return(
    <div>
      <Notification/>
      <IntroductionClaim title={'ESS Claim Submission'} sub_title={['SUPPORT', 'CLAIM', 'ESS Claim Submission']}/>
      <ProgressBar currentStep={currentStep} goToStep={goToStep} isEnableClick={true} type={1}/>
      {loadingDetail ?
        <div className={classes.loadingIndicator}>
        <CustomLoading size={40} color="#00adef"/>
        </div> :
      <ContainerClaimFormEss>
        {renderStepClaimEss()}
      </ContainerClaimFormEss>}

    </div>
  )
}

export default EditClaimEss;
