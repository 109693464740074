import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, LinearProgress } from '@material-ui/core';
import { useDropzone } from 'react-dropzone'
import axios from '../../../utils/axios';
import config from '../../../config';
import DeleteProjectImageIcon from '../../../config/images/delete_project_image_icon.svg';
import _ from 'lodash';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    helperText: {
        marginTop: '20px',
        marginLeft: '20px',
        marginBottom: '15px',
        fontFamily: 'Actual',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '15px',
        lineHeight: '19px',
        color: '#505050',
        '& span': {
            color: '#009EE3',
            fontWeight: 'bold',
            borderBottom: '1px solid #009EE3'
        }
    },
    images: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        margin: '-15px 0 0 5px',
        width: 'calc(100% - 20px)',
        '& > div': {
            margin: '15px 0 0 15px',
            position: 'relative'
        },
        '& > div:hover div[role=overlay]': {
            opacity: 1
        }
    },
    image: {
        height: '70px',
        width: '70px',
        borderRadius: '5px'
    },
    overlay: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        background: 'rgba(0, 0, 0, 0.5)',
        width: '100%',
        height: '100%',
        opacity: 0,
        transition: 'opacity 0.5s ease',
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: '5px'
    },
    uploadContainer: {
        width: '100%',
        minHeight: '141px',
        border: '1px dashed #C4C4C4',
        cursor: 'pointer',
        paddingBottom: '15px',
        background: '#f7f7f7',
    },
    errorUpload : {
        border: '1px dashed red',
    },
    uploading: {
        width: '70px',
        height: '70px',
        border: '1px dashed #C4C4C4',
        textAlign: 'center',
        padding: '33px 10px'
    },
    progressBar: {
        height: '2px',
        backgroundColor: '#f5f5f5',
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#009EE3'
        }
    }
}));

const SingleFileUploadWithProgress = ({ file, url, onUpload, onDelete }) => {
    const classes = useStyles();
    const [progress, setProgress] = useState(0);

    const uploadFile = (file, onProgress) => {
        const url = `${baseUrl}/mediaLibraries/upload/file-to-s3`;
        const formData = new FormData();
        formData.append('image', file );

        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await axios.post(url, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: function(progressEvent) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        onProgress(percentCompleted);
                    }
                });
                resolve(_.get(data, 'image[0].location'));
            } catch (err) {
                reject(err);
            }
        });
    };

    useEffect(() => {
        if (file) {
            async function upload() {
                const url = await uploadFile(file, setProgress);
                onUpload(file, url);
            }

            upload();
        }
    }, []);

    return (
        <>
            {
                url ? (
                    <div onClick={e => e.stopPropagation()}>
                        <img src={url} className={classes.image} />
                        <div className={classes.overlay} role="overlay">
                            <img src={DeleteProjectImageIcon} onClick={() => onDelete(url)} />
                        </div>
                    </div>
                ) : (
                    <div className={classes.uploading} onClick={e => e.stopPropagation()}>
                        <LinearProgress variant="determinate" value={progress} className={classes.progressBar} />
                    </div>
                )
            }
        </>
    )
};

const { baseUrl } = config;

export default function ImageUploader({ files, setFiles, errors= undefined, limit = 10 }) {
    const classes = useStyles();
    const onDelete = url => {
        setFiles((curr) =>
          curr.filter(file => file.url !== url)
        );
    };

    const onUpload = (file, url) => {
        setFiles((curr) =>
          curr.map((fw) => {
            if (fw.file === file) {
              return { ...fw, url };
            }
            return fw;
          })
        );
    };

    const onDrop = useCallback(acceptedFiles => {
        const acceptedFilesData = acceptedFiles.slice(0, limit - files.length).map(file => ({ file, url: null }));
        setFiles(current => [...current, ...acceptedFilesData]);
    }, [files.length]);

    const {getRootProps, getInputProps} = useDropzone({ onDrop, accept: 'image/*', disabled  : files.length >= limit});

    return (
        <div {...getRootProps({maxFiles : 10})} className={classNames([classes.uploadContainer], {
            [classes.errorUpload]:  errors
        })}>
            <input {...getInputProps()} />
            <p className={classes.helperText}>Drag and drop or click to browse (Multiple upload available)</p>
            <div className={classes.images}>
                {files?.map((file, idx) => (
                    <SingleFileUploadWithProgress
                        key={idx}
                        file={file.file}
                        url={file.url}
                        onUpload={onUpload}
                        onDelete={onDelete}
                    />
                ))}
            </div>
        </div>
    )
}
