import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import BreadCrumb2 from "../../common/components/BreadCrumb2";
import Banner from "../../common/components/Banner";
import BannerImage from "../../config/images/marketing-shop-hero.jpeg";
import TitleAnimation from "../../common/components/TitleAnimation";
import Text from "../../common/components/text";

const StyledLink = styled.a
  `
  color: #8c8c8c;
  text-decoration: underline;
  &:hover {
    color: #00adef;
  }

`;

const TitlePolicy = styled.div`
  font-size: 26px;
  color: #000;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 30px;
  text-transform: uppercase;
  
  @media only screen and (max-width: 1600px) {
      font-size: 26px;
      margin-bottom: 35px;
  }
  
  @media only screen and (min-width: 480px) and (max-width: 789px) {
      font-size: 16px;
      margin-bottom: 0px;
  }
  
  @media only screen and (min-width: 375px) and (max-width: 1599px) {
    font-size: 26px;
    margin-bottom: 35px;
  }  
`

const SubTitlePolicy = styled.div`
  font-size: 20px;
  color: #666;
  font-weight: 600;
  line-height: 24px;
  transition: all 0.4s;
  margin-bottom: 10px;
  
  @media only screen and (max-width: 1600px) {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 0px;
  }
`

const TextPolicy = styled.span`
  font-size: 16px;
  color: #666666;
  
  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`

const TextNumberPolicy = styled.span`
   font-size: 16px;
   color: #666666;
   margin-right: 5px;
   line-height: 22px;
   display: inline;
    
   @media only screen and (max-width: 480px) {
   font-size: 14px;
   color: #666666;
   } 
   
`
export default function CCPAPrivacyNotice() {
  const [width, setWindowWidth] = useState(0);

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () =>
      window.removeEventListener('resize',updateDimensions);
  }, [])
  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }


  return (
    <section
      style={{
        backgroundColor: 'var(--light-color, #ffffff)',
        position: 'relative',
      }}
    >
      <BreadCrumb2 items={['CCPA PRIVACY NOTICE']}/>
      <Banner imageUrl={BannerImage} className="banner100">
        <TitleAnimation>CCPA PRIVACY NOTICE</TitleAnimation>
      </Banner>

      <div className={'wrap-policy'}>
        <TitlePolicy>
          CCPA PRIVACY NOTICE
        </TitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'item-policy'}
        >
          {/*<Grid item xs={width > 480 ? 0 : 1}>*/}
          {/*  <TextNumberPolicy>*/}
          {/*    1.1*/}
          {/*  </TextNumberPolicy>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <TextPolicy>
              Pursuant to the California Consumer Privacy Act of 2018 (“CCPA”), this CCPA Privacy Notice provides California residents with a description of our online and offline practices regarding the collection, use, and disclosure of their Personal Information.  Under the CCPA, “Personal Information” is information that identifies, relates to, describes, or could reasonably be associated or linked with a particular California resident or household.   We are providing this CCPA Privacy Notice in addition to the information contained in our general HQCA Privacy Policy. This notice does not apply to our job applicants, employees, contractors, owners, directors, or officers where the Personal Information we collect about those individuals relates to their current, former, or potential role with us.
            </TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          COLLECTION OF PERSONAL INFORMATION
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'item-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              The following chart details the categories of Personal Information, as listed in the CCPA, we plan to collect, and have collected and disclosed within the preceding 12 months and to which categories of third parties Personal Information was disclosed for our operational business purposes:
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'item-policy'}
        >
          <Grid item xs={12}>
            <Table style={{ backgroundColor: '#ffffff' }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Text color="dark" weight="bold" style={{ textAlign: "center" }}>
                      Categories of Personal Information
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text color="dark" weight="bold" style={{ textAlign: "center" }}>
                      Disclosed to which Categories of Third Parties for Operational Business Purpose
                    </Text>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Text inline={true} style={{ fontWeight: "bold" }}>Identifiers,</Text> such as name, contact information, IP address and other online identifiers.
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Our Affiliates, Service Providers and Business Partners
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Text inline={true} style={{ fontWeight: "bold" }}>Personal information, as defined in the California customer records law,</Text> such as name, contact information and financial information.
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Our Affiliates, Service Providers and Business Partners
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Text inline={true} style={{ fontWeight: "bold" }}>Commercial information,</Text> such as transaction information, purchase history, and system-related information, including energy production data, energy consumption data such as electricity usage, and energy systems functionality data.
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Our Affiliates, Service Providers and Business Partners
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Text inline={true} style={{ fontWeight: "bold" }}>Internet or network activity information,</Text> such as browsing history and interactions with our website.
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Our Affiliates, Service Providers
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Text inline={true} style={{ fontWeight: "bold" }}>Geolocation data,</Text> such as device location and approximate location derived from IP address.
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Our Affiliates, Service Providers and Business Partners
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Text inline={true} style={{ fontWeight: "bold" }}>Audio, electronic, visual, similar information,</Text> such as call recordings.
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Our Affiliates, Service Providers
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Text inline={true} style={{ fontWeight: "bold" }}>Inferences</Text> drawn from any of the Personal Information listed above to create a profile about, for example, a household's energy and appliance usage patterns, preferences and characteristics.
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Our Affiliates, Service Providers
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          WHERE WE OBTAIN PERSONAL INFORMATION
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              We collect this Personal Information from you and from these other types of sources: our affiliates; trusted third-party service providers; business partners that provide integrated products, such as inverters used in our systems; publicly available databases; joint marketing partners, when they share the information with us and the distributor and/or installer from which you purchased one of our products.
            </TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          OUR USE OF PERSONAL INFORMATION
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              We may use this Personal Information to operate, manage, and maintain our business, to provide our products and services, and to accomplish our business purposes and objectives, including, for example, to: develop, improve, repair, and maintain our products and services; personalize, advertise, and market our products and services; conduct research, analytics, and data analysis; maintain our facilities and infrastructure; undertake quality and safety assurance measures; conduct risk and security control and monitoring; detect and prevent fraud; perform identity verification; perform accounting, audit, and other internal functions, such as internal investigations; comply with law, legal process, and internal policies; maintain records; and exercise and defend legal claims.
            </TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          DISCLOSURE OF PERSONAL INFORMATION
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              We share Personal Information with the following categories of third parties:
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              • Affiliates.  We share with our affiliates for the purposes described in this CCPA Privacy Notice.
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              • Business Partners.  We may share Personal Information with our customers such as installers and distributors from which you purchased one of our products, suppliers and other business partners.
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              • Service Providers.  We share with our trusted third-party service providers, to facilitate services they provide to us, such as internet services, website hosting, data analytics, payment processing, order fulfillment, information technology and related infrastructure provision, customer service, email delivery, marketing, auditing, and other services.
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              • Legal Authorities.  We share with public and government authorities, including regulators and law enforcement, to respond to requests, as well as to protect and defend legal rights.
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              • Other Parties in Litigation We share information in the context of litigation discovery and in response to subpoenas and court orders. We do not “sell” and have not “sold” Personal Information for purposes of the CCPA in the last 12 months. We do not sell the Personal Information of minors under 16 years of age.
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
          <Grid item xs={12}>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          REQUESTS & RIGHTS
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              If you are a California resident, you may make the following requests:
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              (1) Request to Know:  You may request that we disclose to you the following information covering the 12 months preceding your request:
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy style={{marginLeft : "16px"}}>
              • The categories of Personal Information we collected about you and the categories of sources from which we collected such Personal Information;
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy style={{marginLeft : "16px"}}>
              • The specific pieces of Personal Information we collected about you;
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy style={{marginLeft : "16px"}}>
              • The business or commercial purpose for collecting  Personal Information about you; and
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy style={{marginLeft : "16px"}}>
              • The categories of Personal Information about you that we otherwise shared or disclosed, and, for each, the categories of third parties with whom we shared or to whom we disclosed such Personal Information (if applicable).
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              (2)	Request to Delete:  You may request that we delete Personal Information we collected from you. To make a Request to Know or a Request to Delete, please contact us at https://qcells.com/us/get-support/contact-us or 1-800-749-5996.  We will verify and respond to your request consistent with applicable law, taking into account the type and sensitivity of the Personal Information subject to the request.  We may need to request additional Personal Information from you, including information you provided to us to set up your account with us, among others, to verify your identity and protect against fraudulent requests.  If you maintain a password-protected account with us, we may verify your identity through our existing authentication practices for your account and require you to re-authenticate yourself before disclosing or deleting your Personal Information.  If you make a Request to Delete, we may ask you to confirm your request before we delete your Personal Information.
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
          <Grid item xs={12}>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          AUTHORIZED AGENTS
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              If you are the authorized agent of a California resident, making a Request to Know or a Request to Delete on behalf of the resident, we may ask you for proof concerning your status as an authorized agent, which may include, as applicable:
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={width > 480 ? 0 : 1}>
            <TextNumberPolicy>
              1.
            </TextNumberPolicy>
          </Grid>
          <Grid item xs={11}>
            <TextPolicy>
              Proof of your registration with the California Secretary of State to conduct business in California;
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={width > 480 ? 0 : 1}>
            <TextNumberPolicy>
              2.
            </TextNumberPolicy>
          </Grid>
          <Grid item xs={11}>
            <TextPolicy>
              Proof of a power of attorney from the resident pursuant to Probate Code sections 4121-4130.
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              If you are an authorized agent and have not provided us with a power of attorney from the resident pursuant to Probate Code sections 4121-4130, we may also require the resident to:
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={width > 480 ? 0 : 1}>
            <TextNumberPolicy>
              1.
            </TextNumberPolicy>
          </Grid>
          <Grid item xs={11}>
            <TextPolicy>
              Verify the resident’s own identity directly with us; or
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          <Grid item xs={width > 480 ? 0 : 1}>
            <TextNumberPolicy>
              2.
            </TextNumberPolicy>
          </Grid>
          <Grid item xs={11}>
            <TextPolicy>
              Directly confirm with us that the resident provided you permission to make the request.
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
          <Grid item xs={12}>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          RIGHT TO NON-DISCRIMINATION
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'item-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              You have the right to be free from unlawful discrimination for exercising your rights under the CCPA.
              Right to Request Content Removal
              If you are a resident of California, under 18, and a registered user of the Services, you may ask us to remove content or information that you have posted to the Services by writing to https://qcells.com/us/get-support/contact-us.  Please note that your request does not ensure complete or comprehensive removal of the content or information, as, for example, some of your content may have been reposted by another user.
            </TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          DO NOT TRACK SIGNALS
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'item-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              We do not currently respond to browser do-not-track signals.
            </TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          CHANGES TO THIS CCPA PRIVACY NOTICE
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'item-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              We may change or update this CCPA Privacy Notice from time to time.  When we do, we will post the revised CCPA Privacy Notice on this page with a new “Last Updated” date. Depending on the nature of the change we make, we may notify you of the change and we may request your consent to the change.
            </TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          CONTACT US
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'item-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              If you have any questions regarding this CCPA Privacy Notice, please contact us at https://qcells.com/us/get-support/contact-us.
            </TextPolicy>
          </Grid>
        </Grid>
      </div>
    </section>
  )
}