import React from 'react';
import useStyles from './useStyles';
import PopOver from '../../../config/images/popover_icon.svg';
import {useHistory} from 'react-router-dom';
import { useAuth } from '../../../contexts/auth';

function MessageSpecial() {
  const user = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const typeBussiness = user.partnerType;

  return(
    <div className={classes.wrapMessage} style={{marginTop: 0}}>
      <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>
      {typeBussiness !== 3 ?
      <em className={classes.infoTextMessage}>
        Haven't registered your project yet? Visit <em onClick={() => history.push('/projects/project-introduction')} className={classes.boldInfo}>PROJECT MANAGEMENT </em> for more information. <br /> *NOTE: Non-registered projects will not qualify for labor cost reimbursement.
      </em> :
      <em className={classes.infoTextMessage}>
        Haven't registered your project yet? Visit <em className={classes.boldInfo} style={{cursor: 'default'}}>PROJECT MANAGEMENT </em> for more information. <br /> *NOTE: Non-registered projects will not qualify for labor cost reimbursement.
      </em> }
    </div>
  )
}

export default MessageSpecial;
