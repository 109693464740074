import React, { useState, useEffect } from 'react';
import OrderHistoryBanner from '../../config/images/order-history-banner.jpeg';
import Banner from '../../common/components/Banner';
import BreadCrumb2 from './../../common/components/BreadCrumb2';
import { useAuth } from '../../contexts/auth';
import {
    Grid,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    IconButton,
    Modal,
    makeStyles,
    withStyles
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Text from '../../common/components/text';
import Button from '../../common/components/button';
import { sendEventForOrderHistory, ACTION_VIEW_CONTACT_US_PAGE } from './../../utils/gaHelpers';
import CloseIcon from '@material-ui/icons/Close';
import CustomButton from '../../common/components/button';
import { notiSuccess, notiFailed } from './../../utils/helpers.js';
import axios from '../../utils/axios';
import config from '../../config';
import _ from 'lodash';
import Moment from 'react-moment';
import moment from 'moment';
import CustomLoading from '../../common/components/CustomLoading';

const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#002D56',
      color: '#ffffff'
    },
    body: {
      fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: '#f7f7f7'
      }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    recurringPaymentLink: {
        color: '#000',
        textDecoration: 'underline',
        display: 'inline-block',
        marginTop: '8px',
        fontSize: '15px',
        fontFamily: 'Actual_Light',
        '&:hover': {
          textDecoration: 'underline'
        }
    },
    activeRecurringPayment: {
        display: 'flex',
        '& p': {
            marginRight: '20px'
        },
        '& button': {
            height: '22px',
            lineHeight: '22px',
            border: 'none',
            width: '50px',
            borderRadius: '5px',
            color: '#fff',
            fontSize: '12px',
            backgroundColor: '#808080',
            '&:hover': {
                cursor: 'pointer'
            }
        }
    },
    modal: {
        width: '40rem',
        height: '50%',
        top: '30px',
        margin: 'auto',
        "& .MuiPaper-root": {
            overflowX: 'hidden'
        },
        [theme.breakpoints.down('md')]: {
            width: '98%',
            overflowY: 'auto'
        }
    },
    header: {
        [theme.breakpoints.down('md')]: {
          fontSize: '20px !important'
        }
    },
    paper: {
        textAlign: 'center',
        backgroundColor: '#ffffff',
        color: '#8C8C8C'
    }
}));

const { baseUrl } = config;

export default function RecurringPayment() {
    const user = useAuth();
    const classes = useStyles();
    const [recurringPayments, setRecurringPayments] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedCancelRecurringPayment, setSelectedCancelRecurringPayment] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingCancel, setLoadingCancel] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setSelectedCancelRecurringPayment(null);
    };

    const handleShowModalCancelRecurringPayment = id => {
        setOpen(true);
        setSelectedCancelRecurringPayment(id);
    };

    const getRecurringPayments = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(`${baseUrl}/orders/recurring/search`);
            setRecurringPayments(data.total);
        } catch (err) {
            notiFailed(`Something happened getting list recurring payments`);
        } finally {
            setLoading(false);
        }
    };

    const handleCancelRecurringPayment = async () => {
        try {
            setLoadingCancel(true);
            await axios.patch(`${baseUrl}/orders/recurring/cancel`, { id: selectedCancelRecurringPayment });
            handleClose();
            notiSuccess('Cancel Success!');
            getRecurringPayments();
        } catch (err) {
            notiFailed(`Something happened cancel recurring payments`);
        } finally {
            setLoadingCancel(false);
        }
    };

    useEffect(() => {
        getRecurringPayments();
    }, []);

    return (
        <>
            <Banner
                imageUrl={OrderHistoryBanner}
                className="OrderHistory_Banner banner100"
            >
                <h1 className="title_animation OrderHistory_Title">Order History</h1>
                <BreadCrumb2 items={[`${user.firstName} ${user.lastName}`, 'Order History']}></BreadCrumb2>
            </Banner>

            <div className="OrderHistory">
                <Grid container style={{ paddingTop: '30px' }}>
                    <Grid
                        container
                        item
                        justify="space-between"
                        alignItems="center"
                        style={{ paddingTop: '30px', paddingBottom: '30px' }}
                    >
                        <Grid item>
                            <Text weight="regular" color="dark" className="OrderHistory_Subtitle">
                                Manage My Recurring Payment
                            </Text>
                        </Grid>
                        <Grid item>
                            <Button
                                color="secondary"
                                component={Link}
                                onClick={() => {
                                    sendEventForOrderHistory(ACTION_VIEW_CONTACT_US_PAGE);
                                    window.location.href = `${process.env.REACT_APP_QPARTNER_LEGACY_BASE_URL}/about/contact.fos`
                                }}
                                style={{ width: '158px', height: '35px' }}
                            >
                                <Text weight="regular" style={{ fontSize: '15px' }}>
                                    Contact Us
                                </Text>
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Link to="/orderhistory" className={classes.recurringPaymentLink}>{`< Back To Orders`}</Link>
                        </Grid>
                    </Grid>
                </Grid>

                <TableContainer
                    component={Paper}
                    style={{ marginTop: '20px', boxShadow: 'none', marginBottom: '50px' }}
                >
                    <Table
                        stickyHeader
                        size="small"
                        aria-label="sticky table"
                        className="OrderHistory_Table"
                    >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <Text weight="light" style={{ fontSize: '15px' }}>
                                        Set up date
                                    </Text>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Text weight="light" style={{ fontSize: '15px' }}>
                                        Item
                                    </Text>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Text weight="light" style={{ fontSize: '15px' }}>
                                        Option
                                    </Text>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Text weight="light" style={{ fontSize: '15px' }}>
                                        Q’ty
                                    </Text>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Text weight="light" style={{ fontSize: '15px' }}>
                                        Purchase frequency
                                    </Text>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Text weight="light" style={{ fontSize: '15px' }}>
                                        Next purchase
                                    </Text>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Text weight="light" style={{ fontSize: '15px' }}>
                                        Status
                                    </Text>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell 
                                        colSpan={7}
                                        style={{
                                            padding: 0,
                                            paddingBottom: '30px'
                                        }}
                                    >
                                        <Paper elevation={0} variant="outlined" className={classes.paper}>
                                            <Banner
                                                style={{
                                                    height: '381px',
                                                    width: '100%',
                                                    backgroundColor: '#ffffff'
                                                }}
                                            >
                                                <Text
                                                    size="h4"
                                                    color="secondaryDark"
                                                    style={{
                                                        backgroundColor: '#f7f7f7',
                                                        width: '96%',
                                                        height: '87%',
                                                        textAlign: 'center',
                                                        paddingTop: '145px'
                                                    }}
                                                >
                                                    Loading...
                                                </Text>
                                            </Banner>
                                        </Paper>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    {recurringPayments.length === 0 ? (
                                        <TableRow>
                                            <TableCell 
                                                colSpan={7}
                                                style={{
                                                    padding: 0,
                                                    paddingBottom: '30px'
                                                }}
                                            >
                                                <Paper elevation={0} variant="outlined" className={classes.paper}>
                                                    <Banner
                                                        style={{
                                                            height: '381px',
                                                            width: '100%',
                                                            backgroundColor: '#ffffff'
                                                        }}
                                                    >
                                                        <Text
                                                            size="h4"
                                                            color="secondaryDark"
                                                            style={{
                                                                backgroundColor: '#f7f7f7',
                                                                width: '96%',
                                                                height: '87%',
                                                                textAlign: 'center',
                                                                paddingTop: '145px'
                                                            }}
                                                        >
                                                            There is no recurring payments.
                                                        </Text>
                                                    </Banner>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    ) : recurringPayments.map(rp => (
                                        <StyledTableRow key={rp.id}>
                                            <TableCell className='cell_order'>
                                                <Text weight="light" style={{ fontSize: '15px' }}>
                                                    <Moment format="L">{rp.createdAt}</Moment>
                                                </Text>
                                            </TableCell>
                                            <TableCell className='cell_order'>
                                                <Text weight="light" style={{ fontSize: '15px' }}>
                                                    {_.get(rp, 'product.product.name')}
                                                </Text>
                                            </TableCell>
                                            <TableCell className='cell_order'>
                                                <Text weight="light" style={{ fontSize: '15px' }}>
                                                    {_.get(rp, 'product.variant.custom')}
                                                </Text>
                                            </TableCell>
                                            <TableCell className='cell_order'>
                                                <Text weight="light" style={{ fontSize: '15px' }}>
                                                    {rp.quantity}
                                                </Text>
                                            </TableCell>
                                            <TableCell className='cell_order'>
                                                <Text weight="light" style={{ fontSize: '15px' }}>
                                                    {rp.purchaseEvery === 1 ? 'Monthly' : 'Annually'}
                                                </Text>
                                            </TableCell>
                                            <TableCell className='cell_order'>
                                                <Text weight="light" style={{ fontSize: '15px' }}>
                                                    <Moment format="L" date={moment(rp.latestPurchase).add(rp.purchaseEvery, 'months')}/>
                                                </Text>
                                            </TableCell>
                                            <TableCell className='cell_order'>
                                                <Text weight="light" style={{ fontSize: '15px' }}>
                                                    {rp.recurringStatus === 'Active' ? (
                                                        <div className={classes.activeRecurringPayment}>
                                                            <p>{rp.recurringStatus}</p>
                                                            <button 
                                                                onClick={() => {
                                                                    if (_.get(user, 'auth.user.role') === 'Sub') {
                                                                        return notiSuccess('Only primary users can cancel orders');
                                                                    }
                                                                    handleShowModalCancelRecurringPayment(rp.id)
                                                                }}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    ) : rp.recurringStatus}
                                                </Text>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <Modal onClose={handleClose} open={open} className={classes.modal}>
                <Paper>
                    <Grid container justify="flex-end">
                        <IconButton onClick={handleClose}>
                            <CloseIcon fontSize="default" />
                        </IconButton>
                    </Grid>
                    <Text
                        style={{ textAlign: 'center', paddingBottom: '30px' }}
                        color="dark"
                        size="h2"
                        className={classes.header}
                    >
                        Are you sure?
                    </Text>

                    <Grid
                        container
                        justify="center"
                        style={{ paddingBottom: '50px' }}
                    >
                        <Grid item style={{ marginRight: '10px' }}>
                            <CustomButton
                                color="secondary"
                                onClick={handleClose}
                            >
                                No
                            </CustomButton>
                        </Grid>
                        <Grid item>
                            <CustomButton
                                color="primary"
                                onClick={handleCancelRecurringPayment}
                                disabled={loadingCancel}
                            >
                                { loadingCancel ? <CustomLoading size={16} color="#fff" /> : 'Yes' }
                            </CustomButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </>
    )
}