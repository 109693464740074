import { useState } from 'react';
import { useAPIClient } from '../../contexts/api';
import { toast } from 'react-toastify';
import { notiSuccess, notiFailed } from './../../utils/helpers.js';
import _ from 'lodash';
const useSendOrder = () => {
  const apiClient = useAPIClient();
  const [orderStatus, setOrderStatus] = useState('submitting');
  const [error, setError] = useState(undefined);
  const [response, setResponse] = useState(undefined);

  const sendOrder = async data => {
    try {
      const res = await apiClient.sendOrder(data);
      setResponse(res.data);
      setOrderStatus('submitted');
      !_.get(res.data, 'products[0].product.custom') ? notiSuccess('Order placed') : notiSuccess('Thank you for order! Your Aurora account executive will be in touch with you soon.')
      return res.data;
    } catch (error) {
      setError(error);
      setOrderStatus('error');

      notiFailed('Unable to place order');
    }
  };

  const addAddress = async data => {
    try {
      const res = await apiClient.addAddress(data);
    } catch (error) {
      alert('Unable to add address');
    }
  };

  return { orderStatus, error, response, sendOrder, addAddress };
};

export default useSendOrder;
