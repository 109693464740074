import React from 'react';
import activeMap from '../../../config/images/activeMap.svg';
import defaultMap from '../../../config/images/defaultMap.svg';

import { IconButton } from '@material-ui/core';
import _ from 'lodash';
import DefaultProjectImg from '../../../icons/defaultProjectImg.png';
import ImageLabor from '../../../icons/blueLabor.svg';
import useStyles from './useStyles';
import Moment from 'react-moment';

function MakerInfoProject({info, index, handlePickProject, active}) {
  const classes = useStyles();

  // const
  function handleClick() {
    handlePickProject(active ? null : info)

  }



  return(
    <div onClick={handleClick}>
      <IconButton
        size="small"
        aria-haspopup="true"
      >
        {active ? <img style={{zIndex : 1000}} src={activeMap} /> : <img src={defaultMap}/>}
      </IconButton>
      {active && <div className={classes.wrapProjectInfo}>
        <div style={{ position: 'relative' }}>
          <img src={_.split(info.projectImages, '|')[0]||DefaultProjectImg} style={{ width: '156px', height: '92px' }}/>
          {info?.qualifyLaborWarranty && <img className={classes.iconLabor} src={ImageLabor}/>}
        </div>
        <div className={classes.infoTxt}>
          <span style={{fontWeight : 'bold'}}>{info?.projectName}</span><br/>
          <span>City: {info?.projectCity}</span><br/>
          <span>System Size: {info?.systemSize} kWh</span><br/>
          <span>Installtion Date : {info?.installationDate && <Moment format="L">{info?.installationDate}</Moment> }</span>

        </div>
      </div>}

    </div>
  )
}

export default MakerInfoProject;
