import React from 'react';
import _ from 'lodash';
import DefaultProjectImg from '../../../icons/defaultProjectImg.png';
import ImageLabor from '../../../icons/blueLabor.svg';
import Moment from 'react-moment';
import useStyles from './useStyles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ContainerPro = styled.div`
  display : flex;
  height: fit-content;
  width: 100%;
  z-index: 22;
  cursor : pointer;
  //justify-content : space-between;
  background-color: ${props => props.active ? '#f6f6f6' : '#FFFFFF'};
  border-bottom: ${props => props.active ? '1px solid #DCDCDC' : 'none'};;
`;

function CardProject({ p, active, handlePickProject, activeId }) {
  const classes = useStyles();

  const renderProjectName = p => {
    if (p.projectName) {
      return p.projectName;
    }

    if (p.firstName && p.lastName) {
      return `${p.firstName} ${p.lastName}`;
    } else if (p.firstName) {
      return p.firstName;
    } else if (p.lastName) {
      return p.lastName;
    }

    if (p.projectCity) {
      return p.projectCity;
    }

    return null;
  };

  function handleClickProject() {
    handlePickProject(activeId ? null : p)
  }

  function suggestType(p) {
    //case 1 : b-1) Q CELLS module only, no ESS - with S/N (valid or awaiting update) (panelNonQcells : false & panelQualified : true)
    const panelQualified  = p.panelQualified;
    const batteryQualified = p.batteryQualified;
    const panelNonQcells  = p.panelNonQcells;
    const batteryNonQcells = p.batteryNonQcells;

    let type = 'select';



    if(!panelNonQcells && !batteryNonQcells) {
      type = 'select'
    }else if(!panelNonQcells) {
      type = '1'
    }else if(!batteryNonQcells) {
      type = '2'
    }




    return type



    /**
     * fine 1 : (only) panelNonQcells : false & panelQualified : true -> type 1 (2) -> []
     * fine 2 : (only) batteryNonQcells : false & batteryQualified : true -> type 2 (1) ->  []
     * fine 3 : fine 1 + fine 2 -> type : 'select'
     *
     *
     *
     *
     * */

  }


  function renderButtonSubmit(p) {

    const panelQualified  = p.panelQualified;
    const batteryQualified = p.batteryQualified;
    const panelNonQcells  = p.panelNonQcells;
    const batteryNonQcells = p.batteryNonQcells;

    let laborQcell = false;
    let laborQuanlify = false;

    if(!panelNonQcells === true || !batteryNonQcells === true) {
      laborQcell = true
    }

    if(panelQualified === true || batteryQualified === true) {
      laborQuanlify = true
    }

    return(
      <>
        <p>{!p.isDraft ? (
          <>
            <p>Completed on <Moment format="L">{p.installationDate}</Moment></p>
            {laborQcell && <span className="title_animation2" style={{ textTransform: 'uppercase' }}><Link
              style={{ color: '#009EE3', fontWeight: '400' }} to={{
              pathname : '/claim/info',
              state : {
                type : suggestType(p),
                //projectInfo : p,
                battery : p.battery,
                id : p.id,
                isPickClaimType : true
              }


            }}>submit a claim</Link></span>}
          </>
        ) : 'Draft'
        }</p>
      </>
    )
  }


  return (
    <ContainerPro onClick={handleClickProject} active={active}>
      <div className={classes.wrapLeftPro}>
        <div style={{ position: 'relative', width: '156px' }}>
          <img src={_.split(p.projectImages, '|')[0] || DefaultProjectImg} style={{ width: '100%', height: '92px' }}/>
          {p.qualifyLaborWarranty && (<img className={classes.iconLabor} src={ImageLabor}/>)}
        </div>
        <div style={{ fontSize: '15px', marginLeft: '15px', width: '60%' }}>
          <p style={{ fontSize: '17px', fontWeight: '400' }}>{renderProjectName(p)}</p>
          <p>City: {p.projectCity}</p>
          <p>Sysyem size: {p.systemSize ? <> {p.systemSize} kWp </> : null} </p>
          <p>Installation Date: {p.installationDate && <Moment format="L">{p.installationDate}</Moment>}</p>
        </div>
      </div>

      <div className={classes.wrapRightPr}>
        {renderButtonSubmit(p)}
      </div>
    </ContainerPro>

  );
}

export default CardProject;
