import React from 'react';
import useStyles from './useStyles';
import PopOver from '../../../config/images/popover_icon.svg';
import {useHistory} from 'react-router-dom';


function ShowMessageNa() {

  const classes = useStyles();
  const history = useHistory();

  return(
    <div className={classes.wrapMessage}>
      <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/> <em className={classes.infoTextMessage}>Only Q CELLS residential products qualify for labor reimbursement. Please check the list <em onClick={() => history.push({
        pathname : '/rewards/qpoint-introduction',
        state: {
          isScrollToQcellsProductsQualify: true
        }
    }) } className={classes.textHere}>here</em></em>
    </div>
  )
}

export default ShowMessageNa;
