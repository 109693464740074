import React, { useEffect, useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import CardProject from './CardProject';
import './index.css';
import MakerInfoProject from './MakerInfoProject';
import config from '../../../config';
import _ from 'lodash';
const AnyReactComponent = ({ text }) => <div>{text}</div>;


function ViewMapProject({projects}) {

  const [idProjectActive, setIdProjectActive] = useState(null)
  const [zoom, setZoom] = useState(4)
  const refMap = useRef(null);
  const [center, setCenter] = useState({ lat: 39.113014, lng: -105.358887 });

  function handlePickProjectActive(p) {
    setIdProjectActive(p);
    setZoom(10)
  }

  useEffect(() => {
    if(idProjectActive !== null) {

      setCenter({
        lat:  _.get(idProjectActive, 'latitude', 40.730610),
        lng: _.get(idProjectActive, 'longitude', -73.935242)
      });
      setZoom(10)
    }
  }, [idProjectActive])



  return(
    <div style={{ height: '100%', width: '100%', position : 'absolute', display : 'flex' }}>
      <div className={'wrap-pro'}>
        {projects.length > 0 && projects.map((p, index) => {
          return <CardProject handlePickProject={handlePickProjectActive} activeId={_.get(idProjectActive, 'id') === p.id} active={index % 2 !== 0} p={p}/>
        })}
      </div>
      <div className={'map-view'}>
        <GoogleMapReact
          ref={refMap}
          center={center}
          zoom={zoom}
          bootstrapURLKeys={{ key: `${config.keyMap}` }}
        >
          {projects?.map((pro , index) => {
            return <MakerInfoProject
              key={index}
              lat={pro.latitude}
              active={_.get(idProjectActive, 'id') === pro.id}
              lng={pro.longitude}
              index={pro.id}
              info={pro}
              handlePickProject={handlePickProjectActive}
              text="My Marker"
            />
          })}
        </GoogleMapReact>
      </div>
    </div>

  )
}

export default ViewMapProject
