export const darkThemeColor = ({ theme }) => theme.colors.dark;

export const lightThemeColor = ({ theme }) => theme.colors.light;

export const primaryDarkThemeColor = ({ theme }) => theme.colors.primaryDark;

export const primaryLightThemeColor = ({ theme }) => theme.colors.primaryLight;

export const secondaryDarkThemeColor = ({ theme }) => theme.colors.secondaryDark;

export const secondaryLightThemeColor = ({ theme }) => theme.colors.secondaryLight;

export const insertAlphaToHexColor = (str, opacity) => {
  const al = Math.round(255 * opacity).toString(16);
  const alpha = al.length === 1 ? `0${al}` : al;
  return str.substr(0, 1) + str.substr(1) + alpha;
};
