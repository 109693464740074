import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/auth';
import { useHistory } from 'react-router-dom';
import InformationClaimType from '../components/InformationClaimType';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaEss } from '../components/schemaEss';
import _ from 'lodash';
import IntroductionClaim from '../components/IntroductionClaim';
import ProgressBar from '../components/ProgressBar';
import Notification from '../components/Notification';
import styled from 'styled-components';
import EssPage from './ess';
import { defaultStateEss } from '../components/defaultStateEss';
import PvPage from './pv';
import { defaultStatePv } from '../components/defaultStatePv';
import { schemaPV } from '../components/schemaPv';
import { claimService, projectService } from '../../../services';
import axios from '../../../utils/axios';
import { notiFailed } from '../../../utils/helpers';
import {baseUrl} from '../../../config';
import PurchaseDetail from '../components/PurchaseDetail';
import {useParams, useLocation} from 'react-router-dom';
import { schemaIn } from '../components/schemaInformation';
import RouteLeavingGuard from '../../registration/RouteLeavingGuard';

function AbilityFormClaim() {
  const user = useAuth();
  const history = useHistory();
  const location = useLocation();
  //const {type} = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [projects, setProject] = useState([]);
  const [isPickClaimType, setIsPickClaimType] = useState(true);
  // const [initialStateDefaultForm, setInitialStateDefaultForm] = useState(defaultStateEss);
  const [mainPurchasingChannel, setMainPurchasingChannel] = useState([]);
  const [schemaClaim, setSchemaClaim] = useState({});
  const [modelModule, setModelModules] = useState([]);
  const [powerClassesModules, setPowerClassesModules] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [fileErrorLog, setFileErrorLog] = useState([]);
  const [fileEvidence, setFileEvidence] = useState([]);
  const [fileLaborWarranty, setFileLaborWarranty] = useState([]);
  const [filePermissionOfDate, setFilePermissionOfDate] = useState([]);
  const [fileInvoiceLabor, setFileInvoiceLabor] = useState([]);
  const [fileInfoBank, setFileInfoBank] = useState([]);
  const [keyUpload, setKeyUpload] = useState('');
  const [saleRegions, setSaleRegions] = useState([]);

  // const [filePermissionOfDate, setFilePermissionOfDate] = useState([]);
  // const [fileLaborWarranty, setFileLaborWarranty] = useState([]);
  const [fileProofOfPermissionToOperateDate, setFileProofOfPermissionToOperateDate] = useState([]);
  const [fileProofOfPermission, setFileProofOfPermission] = useState([]);
  const [filePictureOfAffectedModule, setFilePictureOfAffectedModule] = useState([]);
  const [filePictureOfVoc, setFilePictureOfVoc] = useState([]);
  const [filePictureOfFront, setFilePictureOfFront] = useState([]);
  const [filePictureOfBack, setFilePictureOfBack] = useState([]);
  const [filePictureCloseUp, setFilePictureCloseUp] = useState([]);
  const [filePictureSitePlan, setFilePictureSitePlan] = useState([]);
  const [filePictureOfOverall, setFilePictureOfOverall] = useState([]);
  const [filePictureOfWireManagement, setFilePictureOfWireManagement] = useState([]);
  const [filePictureProofDelivery, setFilePictureProofDelivery] = useState([]);
  const [filePictureImmediately, setFilePictureImmediately] = useState([]);
  const [filePictureOfVmpp, setFilePictureOfVmpp] = useState([]);
  const [filePictureIr, setFilePictureIr] = useState([]);
  const [filePictureInVoice, setFilePictureInVoice] = useState([])
  const [fileBankAccountInfo, setFileBankAccountInfo] = useState([]);
  const [fileInvoiceForLaborReimbursement, setFileInvoiceForLaborReimbursement] = useState([]);
  const [serials, setSerials] = useState([]);
  const [urlImageProof, setFilesProof] = useState([]);
  const [listProductPti, setListProductPti] = useState([]);
  const [condition, setCondition] = useState({});
  const [listSerial, setListSerial] = useState([]);

  const { register, handleSubmit, errors, control, watch, setValue, getValues, formState, clearErrors, trigger } = useForm({
    resolver : yupResolver(schemaIn),
    shouldUnregister: false,
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: false,
    enableReinitialize: true,
    defaultValues: {
      installerEmail : _.get(user, 'email'),
      installerName : _.get(user , 'fullName'),
      installerContact : _.get(user , 'fullName'),
      installerCity : '',
      installerAddress1 : '',
      installerAddress2 : '',
      installerState : 'select',
      installerZipcode : '',
      installerPhone : '',
      checkedEmail : false,
      infoClaimName : 'select',
      claimType : 'select',
      type : 'select'
      }
  });
  const { register : registerPv, handleSubmit : handleSubmitPv, errors : errorPv, control : controlPv, watch : watchPv, setValue : setValuePv, getValues : getValuesPv, formState: formStatePv, trigger: triggerPv ,clearErrors : clearErrorsPv } = useForm({
    resolver : yupResolver(schemaPV),
    mode: "onChange",
    reValidateMode: "onChange",
    shouldUnregister: false,
    shouldFocusError: false,
    enableReinitialize: true,
    defaultValues: {
      installerEmail : _.get(user, 'email'),
      installerName : _.get(user , 'fullName'),
      installerContact : _.get(user , 'fullName'),
      installerCity : '',
      installerAddress1 : '',
      installerAddress2 : '',
      installerState : 'select',
      installerZipcode : '',
      checkedEmail : false,
      infoClaimName : 'PV',
      info_claim_type : 'select',
      projectId : null,
      ...defaultStatePv,
      installation_date_pv : null,
      comission_date_pv : null,
      affected_module_serial_number : 'select',
      region_pv : 'select',
      site_module_layout_pv : '',
      projectSystemSize : '',
      inverter_type_pv : 'select',
      region : '',
      purchase_date_info : null,
      chosePermissionDate : '1',
      choseAffectedSerialNumber : '1',
      serials : [],
      choseWebAddress : '1',
      chooseRefund: '1',
      choseTypeProject : '1',
    }
  });
  const { register : registerEss, handleSubmit : handleSubmitEss, errors : errorEss, control: controlEss, watch : watchEss, trigger : triggerEss ,setValue : setValueEss, getValues : getValuesEss, formState : formStateEss, clearErrors : clearErrorsEss } = useForm({
    resolver : yupResolver(schemaEss),
    mode: "onChange",
    reValidateMode: "onChange",
    shouldUnregister: false,
    shouldFocusError: false,
    enableReinitialize: true,
    defaultValues: {
      installerEmail : _.get(user, 'email'),
      installerName : _.get(user , 'fullName'),
      installerContact : _.get(user , 'fullName'),
      installerCity : '',
      installerAddress1 : '',
      installerAddress2 : '',
      installerState : 'select',
      installerZipcode : '',
      installerPhone : '',
      checkedEmail : false,

      infoClaimName : 'ESS',
      claimType : '2',
      errorLogName : '',
      projectId : null,
      ...defaultStateEss,
      installation_date_pv : null,
      comission_date_pv : null,
      affected_module_serial_number : 'select',
      region_pv : 'select',
      site_module_layout_pv : 'select',
      inverter_type_pv : 'select',
      purchase_date_info : null
    }
  });

  const type = watch('type');
  const claimInfoName = watch('claimType');
  const checkedEmail = watch('checkedEmail');
  const submitterEmail = watch('submitterEmail');
  const pictureOfModuleLabel = watchPv('pictureOfModuleLabel');


  async function getDetailProject(id) {
    const info = await projectService.getDetailProject(id);
    setValueEss('projectInfo', info.data);
    if(_.get( info.data, 'batteryLaborReimbursement', false)) {
      setValueEss('laborReimbursement', true)

    }

    if(_.get( info.data, 'batteryLaborReimbursement', false) && _.get(info.data, 'qualifyLaborWarranty', false)) {
      setValueEss('validateProofOfDate', true)
    }
    //const panelQualified  = watch('panelQualified');
    //         const batteryQualified = watch('batteryQualified');
    //         const panelNonQcells  = watch('panelNonQcells');
    //         const batteryNonQcells = watch('batteryNonQcells')

    setValueEss('laborWarranty', _.get(info.data, 'qualifyLaborWarranty', false))
    setValueEss('batteryQualified', _.get(info.data, 'batteryQualified', false))
    setValuePv('panelQualified', _.get(info.data, 'panelQualified', false))
    //setValueEss('')
    setValuePv('laborWarranty', _.get(info.data, 'qualifyLaborWarranty', false))
    setValuePv('projectInfo', info.data);
    if(_.get(info.data, 'panelLaborReimbursement', false)) {
      setValuePv('laborReimbursement', true)
    }

    if(_.get(info.data, 'panelLaborReimbursement', false) && _.get(info.data, 'qualifyLaborWarranty', false)) {
      setValuePv('validateProofOfDate', true)
    }
  }

  useEffect(() => {


    if(location.state) {
      setValue('type', _.get(location, 'state.type', type).toString());
      setCondition(location.state);
      setIsPickClaimType(_.get(location, 'state.isPickClaimType', true));
      getListProject(location.state);
      if(_.get(location, 'state.id', null) !== null) {
        getDetailProject(_.get(location, 'state.id', null))
      }


    }else {
      setValue('type', type);
      getListProject(location.state);
    }
  }, [location])

  const getSaleRegions = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/companies/salesregions/search`);
      setSaleRegions(data);
    } catch (err) {
      notiFailed(`Something happened getting sale regions`);
    }
  };


  const getMainPurchasingChannel = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/distributors`);
      setMainPurchasingChannel(data);
    } catch (err) {
      notiFailed(`Something happened getting main purchasing channel`);
    }
  };

  const getDistributors = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/distributors`);
      setDistributors(data);
    } catch (err) {
      notiFailed(`Something happened getting distributors`);
    }
  };

  async function getProductPti() {
    try {
      const productPti = await claimService.getProductPti();
      setListProductPti(productPti.data);
    }catch (e) {

    }
  }



  function nextForm() {
    setIsPickClaimType(false);

    // panelQualified : false,
    //   batteryQualified : false,
    //   panelNonQcells : false,
    //   batteryNonQcells : false,

    //case default click menu

    if(type === '1' && _.get(condition, 'id', null) === null) {
      getListProject({
        panelNonQcells : false,
        //panelQualified : true
      })
      return;
    }

    if(type === '2' && _.get(condition, 'id', null) === null) {
      getListProject({
        battery : true,
        batteryNonQcells : false,
        //batteryQualified : true
      })
      return
    }


    //end case default click menu

    if(condition?.type?.toString() === 'select' && type === '1') {
      getListProject({
        panelNonQcells : false
      })
      return
    }

    if(condition?.type?.toString() === 'select' && type === '2') {
      getListProject({
        panelNonQcells : false,
        battery : true
      })
      return
    }





    if(condition?.type?.toString() === '1' && type !== condition?.type?.toString() && _.get(condition, 'id', null) !== null) {
      setProject([]);
      setValueEss('projectInfo', null)
      setValueEss('showMess', true);
      return
    }

    if(condition?.type?.toString() === '2' && type !== condition?.type?.toString() && _.get(condition, 'id', null) !== null) {
      setProject([]);
      setValuePv('projectInfo', null)
      setValuePv('showMess', true);
      return
    }




  }

  function goBackStep() {
    setIsPickClaimType(true);
    getListProject(condition);

  }

  async function getListProject(condition) {
    try {
      const infoProject = await projectService.getListProject(condition);
      setProject(infoProject.data);

    }catch (e) {

    }

  }

  useEffect(() => {
    getMainPurchasingChannel();
    getDistributors();
    getProductPti();
    getSaleRegions();
  }, [])

  useEffect(() => {
    scrollToTop();
  },[currentStep, isPickClaimType])

  useEffect(() => {
    scrollToTop()
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };




  const goToStep = step => setCurrentStep(step);

  function renderAbilityFormClaim() {
    if(isPickClaimType) {
      return(
        <InformationClaimType
          next={nextForm}
          condition={condition}
          register={register}
          setValueEss={setValueEss}
          setValuePv={setValuePv}
          control={control}
          errors={errors}
          setValue={setValue}
          clearErrors={clearErrors}
          watch={watch}
          getValues={getValues}
          text={'Different from the above E-mail?'}
        />
      )
    }

    if(!isPickClaimType && type === '2') {
      return <EssPage
        distributors={distributors}
        register={registerEss}
        infoClaimName={claimInfoName}
        listProductPti={listProductPti}
        handleSubmit={handleSubmitEss}
        errors={errorEss}
        errorInfo={errors}
        triggerInfo={trigger}
        control={controlEss}
        id={''}
        saleRegions={saleRegions}
        watch={watchEss}
        fileEvidence={fileEvidence}
        setFileEvidence={setFileEvidence}
        filePermissionOfDate={filePermissionOfDate}
        setFilePermissionOfDate={setFilePermissionOfDate}
        fileErrorLog={fileErrorLog}
        setFileErrorLog={setFileErrorLog}
        fileLaborWarranty={fileLaborWarranty}
        fileInfoBank={fileInfoBank}
        fileInvoiceLabor={fileInvoiceLabor}
        setFileInvoiceLabor={setFileInvoiceLabor}
        setFileInfoBank={setFileInfoBank}
        setFileLaborWarranty={setFileLaborWarranty}
        keyUpload={keyUpload}
        setKeyUpload={setKeyUpload}
        trigger={triggerEss}
        setValue={setValueEss}
        getValues={getValuesEss}
        formState={formStateEss}
        clearErrors={clearErrorsEss}
        mainPurchasingChannel={mainPurchasingChannel}
        submitterEmail={submitterEmail}
        checkedEmail={checkedEmail}
        claimInfoName={claimInfoName}
        projects={projects}
        urlImageProof={urlImageProof}
        setFilesProof={setFilesProof}
        condition={condition}
        handleSetStep={(step) => setCurrentStep(step)}
        listSerial={listSerial}
        setListSerial={setListSerial}
        serials={serials}
        setSerials={setSerials}
        goBackStepDefault={goBackStep}/>
    }

    if(!isPickClaimType && type === '1') {
      return <PvPage
        trigger={triggerPv}
        listProductPti={listProductPti}
        register={registerPv}
        handleSubmit={handleSubmitPv}
        errors={errorPv}
        id={''}
        condition={condition}
        saleRegions={saleRegions}
        errorInfo={errors}
        triggerInfo={trigger}
        control={controlPv}
        watch={watchPv}
        setValue={setValuePv}
        getValues={getValuesPv}
        serials={serials}
        setSerials={setSerials}
        formState={formStatePv}
        clearErrors={clearErrorsPv}
        urlImageProof={urlImageProof}
        setFilesProof={setFilesProof}
        fileInvoiceForLaborReimbursement={fileInvoiceForLaborReimbursement}
        setFileInvoiceForLaborReimbursement={setFileInvoiceForLaborReimbursement}
        fileProofOfPermissionToOperateDate={fileProofOfPermissionToOperateDate}
        setFileProofOfPermissionToOperateDate={setFileProofOfPermissionToOperateDate}
        filePermissionOfDate={filePermissionOfDate}
        setFilePermissionOfDate={setFilePermissionOfDate}
        setFileLaborWarranty={setFileLaborWarranty}
        fileLaborWarranty={fileLaborWarranty}
        fileProofOfPermission={fileProofOfPermission}
        setFileProofOfPermission={setFileProofOfPermission}
        filePictureOfAffectedModule={filePictureOfAffectedModule}
        setFilePictureOfAffectedModule={setFilePictureOfAffectedModule}
        filePictureOfVoc={filePictureOfVoc}
        setFilePictureOfVoc={setFilePictureOfVoc}
        filePictureOfFront={filePictureOfFront}
        setFilePictureOfFront={setFilePictureOfFront}
        filePictureOfBack={filePictureOfBack}
        setFilePictureOfBack={setFilePictureOfBack}
        filePictureCloseUp={filePictureCloseUp}
        setFilePictureCloseUp={setFilePictureCloseUp}
        filePictureSitePlan={filePictureSitePlan}
        setFilePictureSitePlan={setFilePictureSitePlan}
        filePictureOfOverall={filePictureOfOverall}
        setFilePictureOfOverall={setFilePictureOfOverall}
        filePictureOfWireManagement={filePictureOfWireManagement}
        setFilePictureOfWireManagement={setFilePictureOfWireManagement}
        filePictureProofDelivery={filePictureProofDelivery}
        setFilePictureProofDelivery={setFilePictureProofDelivery}
        filePictureImmediately={filePictureImmediately}
        setFilePictureImmediately={setFilePictureImmediately}
        filePictureOfVmpp={filePictureOfVmpp}
        setFilePictureOfVmpp={setFilePictureOfVmpp}
        filePictureIr={filePictureIr}
        setFilePictureIr={setFilePictureIr}
        filePictureInVoice={filePictureInVoice}
        setFilePictureInVoice={setFilePictureInVoice}
        fileBankAccountInfo={fileBankAccountInfo}
        setFileBankAccountInfo={setFileBankAccountInfo}
        keyUpload={keyUpload}
        setKeyUpload={setKeyUpload}
        distributors={distributors}
        mainPurchasingChannel={mainPurchasingChannel}
        submitterEmail={submitterEmail}
        checkedEmail={checkedEmail}
        claimInfoName={claimInfoName}
        projects={projects}
        handleSetStep={(step) => setCurrentStep(step)}
        goBackStepDefault={goBackStep}
        listSerial={listSerial}
        setListSerial={setListSerial}
      />
    }

    //const [listSerial, setListSerial] = useState([]);



  }


  /**
   *
   * define case view InformationClaimType -> panel -> form ess
   *
   * case claimType -> panel -> form pv (step + form)
   * case claimType -> battery -> form ess (step + form)
   *
   *
   * */


  function suggestCurrentStep(step, status) {
    return (step === 1 && status) ? 0 : step
  }

  return(
    <div>
      <RouteLeavingGuard
        when={
          formState.isDirty || formStatePv.isDirty || formStateEss.isDirty
        }
        navigate={(path,state) => history.push({
          pathname : path,
          state: state,
          id : _.get(condition, 'id')
        })}
        shouldBlockNavigation={location => {

          if (formState.isDirty || formStatePv.isDirty || formStateEss.isDirty) {
            if (location.pathname !== '/claim/info' && !_.get(location, 'state.disablePrompt')) {
              return true
            }
          }
          return false
        }}
      />
      <Notification/>
      {type === '1' ?
        <IntroductionClaim step={suggestCurrentStep(currentStep, isPickClaimType)} tyleClaim={'panel'} title={'Tell us about your panel claim'}  sub_title={['SUPPORT', 'CLAIM SUBMISSION', 'PANEL CLAIM']}/>
       : <IntroductionClaim step={suggestCurrentStep(currentStep, isPickClaimType)} tyleClaim={'battery'} title={'Tell us about your battery claim'} sub_title={['SUPPORT', 'CLAIM SUBMISSION', 'BATTERY CLAIM']}/>}
      {/*{!isPickClaimType && <ProgressBar currentStep={currentStep} goToStep={goToStep} isEnableClick={true} type={1}/>}*/}
        {renderAbilityFormClaim()}
    </div>
  )

}

export default AbilityFormClaim;
