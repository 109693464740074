import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import { Link } from 'react-router-dom';

import Banner from '../../../common/components/Banner';
import Text from '../../../common/components/text';
import Button from '../../../common/components/button';
import OrderDetailsHeader from './components/OrderDetailsHeader';
import LineItem from './components/LineItem';
import OrderSummary from '../order/components/OrderSummary';
import { InputAdornment, TextField, RadioGroup, Radio, FormControlLabel, FormLabel } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { lightThemeColor, primaryLightThemeColor } from '../../../utils/colors';
import BannerImage from '../../../config/images/confirmation.jpg';

import { useAuth } from '../../../contexts/auth';
import useGetOrder from '../../../hooks/api/useGetOrder';
import _ from 'lodash';
import defaultImage from './../../../icons/product_default_image.png';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomLoading from '../../../common/components/CustomLoading';
import CustomButton from '../../../common/components/button';

import axios from '../../../utils/axios';
import { baseUrl } from '../../../config';
import { pubSpotService } from '../../../services';
import { notiFailed, notiSuccess } from '../../../utils/helpers';

const Container = styled.div`
  max-width: 45rem;
  margin: 36px auto 170px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 30px 12px 36px;
  background: ${lightThemeColor};
`;

const BannerIcon = styled(CheckIcon)`
  color: ${primaryLightThemeColor};
  font-size: 42px;
`;
const BannerIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${lightThemeColor};
  width: 54px;
  height: 54px;
  border-radius: 100px;
`;

const ListItems = styled.div`
  margin-bottom: 30px;
`;

const ContinueButton = styled(Button)`
  align-self: center;
  margin-top: 30px;
`;

function checkProductAurora(products) {
  const listAurora = products?.filter((info) => {
    return !!info?.product.custom;
  });

  return listAurora.length > 0;
}

const schema = yup.object().shape({
  last_name: yup.string().required(),
  job_title: yup.string().required()
});


export default function Confirmation(props) {
  const user = useAuth();
  const history = useHistory();

  const { register, errors, handleSubmit, clearErrors, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      last_name: _.get(user, 'profile.lastName')
    },
    shouldFocusError: false
  });
  const { status, data } = useGetOrder(props.match?.params?.orderId);

  const statusAurora = checkProductAurora(data?.products || []);
  const [companyRewards, setCompanyRewards] = useState([]);


  const getRewards = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/companies/${user.companyId}/rewards`
      );
      setCompanyRewards(data.company);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getRewards();
  }, []);



  let shippingTotal = _.get(data, 'shippingTotal');
  const formatDate = str => {
    const event = new Date(str);
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    let formattedDate = `${
      months[event.getMonth()]
    } ${event.getDate()}, ${event.getFullYear()}`;
    return formattedDate;
  };

  return (
    <Container className="wr_order_confirmed">
      {status === 'loaded' && (
        <form>
          <Banner imageUrl={BannerImage} height={240} backgroundPosition="top right">
            <BannerIconContainer className="wr_checked_icon">
              <BannerIcon className="checked_icon"/>
            </BannerIconContainer>
            <Text
              className="label_order_confirmed"
              style={{ fontSize: '34px', fontWeight: '400' }}
              as="h2"
              weight="medium"
            >
              Order Confirmed!
            </Text>
            {statusAurora < 1 ? <>
            <Text>
              <span dangerouslySetInnerHTML={{ __html: `Hi ${user.firstName}, your order has been confirmed!` }}/>
            </Text>
            <Text className="label_we_will_send_you">
              We will send you a confirmation email when your items have shipped.
            </Text>
              </> :
              <>
                <Text>
                  <span>Thank you for your order! </span>
                </Text>
                <Text className="label_we_will_send_you">
                  Your Aurora account executive will be in touch with you soon.
                </Text>
              </>}
          </Banner>
          <Content className="wr_content_order_confirmed">
            <OrderDetailsHeader
              orderDate={formatDate(data.createdAt)}
              orderNumber={data.orderNumber}
            />
            <ListItems>
              {data?.products?.map(product => {
                let src = _.get(
                  product,
                  'product.variantImages[0].imageUrl',
                  _.get(product, 'product.variantImages[0].image_url')
                );
                if (!src) {
                  src = defaultImage;
                } else {
                  if (src.indexOf('http') === 0) {
                  } else {
                    src = `${process.env.REACT_APP_QPARTNER_LEGACY_IMAGE_BASE_URL}${src}`;
                  }
                }

                return (
                  <LineItem
                    product={product}
                    key={product.id}
                    discount={_.get(product, 'product.custom')}
                    codes={product.couponCode}
                    consolidate={_.get(product, 'product.consolidate')}
                    name={_.get(
                      product,
                      'product.product.name',
                      _.get(product, 'product.name')
                    )}
                    color={_.get(product, 'product.color')}
                    size={_.get(product, 'product.size')}
                    quantity={product.quantity}
                    price={product.price}
                    image={src}
                  />
                );
              })}
            </ListItems>
            {!!_.get(data, 'products[0].product.purchaseEvery') && <Grid container justify={'center'} style={{borderBottom : '1px solid #E6E6E6'}}>
              <div style={{display: 'flex', flexDirection : 'column', alignItems : 'center'}}>
                <Text weight={300} style={{textAlign : 'center'}}>
                  <span style={{fontSize : 12}}>Automatic recurring payment has been successfully set up</span>
                </Text>
                <Text weight={300} style={{textAlign : 'center', marginTop : -6}}>
                  <span style={{fontSize : 12}}>for the time period of every <span style={{color : '#00ADEF'}}>{_.get(data, 'products[0].product.purchaseEvery')} {_.get(data, 'products[0].product.purchaseEvery') === 1 ? 'month' : 'months'}</span>.</span>
                </Text>
                <Text weight={300} style={{marginTop : 10, marginBottom : 30, textAlign : 'center'}}>
                  <span style={{fontSize : 12}} >You can manage or cancel your recurring payment at any time on the Order History page.</span>
                </Text>

              </div>
            </Grid>}
            <Grid container justify="flex-end">
              <Grid item xs={12} sm={6}>
                <OrderSummary
                  statusAurora={statusAurora}
                  submittedorder={true}
                  available={companyRewards.availableQpoints + data.total}
                  total={data.total - shippingTotal}
                  shipping={shippingTotal}
                />
              </Grid>
            </Grid>

          </Content>

            <Box display="flex" alignItems="center" justifyContent="center">
              <Link to={{
                pathname: '/rewards/shop',
                state: { refresh: true }
              }}>
                <ContinueButton>Continue Shopping</ContinueButton>
              </Link>
            </Box>
        </form>
      )}
    </Container>
  );
}
