import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import QpartnerLogo from '../../config/images/qpartner-footer-logo.png';
import { AppBar, Toolbar, Grid, Box, IconButton } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import Text from '../components/text';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LinkedInIconImg from '../../icons/btn_footer_sns_linkedin.png';
import YoutubeIconImg from '../../icons/btn_footer_sns_youtube.png';
import FacebookIconImg from '../../icons/btn_footer_sns_facebook.png';
import './index.css';

const StyledAppBar = styled(AppBar)`
  ${({ theme }) => `padding: 10px 20px;
  background-color: #161616;
  box-shadow: none;
  border-bottom: 1px solid #8080803b;`}
`;

const Logo = styled.img`
  height: 18px;
  margin-right: 20px;
  opacity: 1;
`;

const ATag = ({ to, children }) => {
  return (
    <a className="footer_link" href={to}>
      {children}
    </a>
  );
};

const StyledLink = styled(ATag)`
  padding: 0 1rem;
`;

const Copyright = () => (
  <Link to="/register" target="_blank">
    <Text color="secondaryDark" className="Footer_copyright">
      COPYRIGHT&copy;2022 Qcells All Rights Reserved.
    </Text>
  </Link>
);

export default function FooterHomePage({ isHome = false, isTrigger = false }) {
  const match = useMediaQuery('(min-width:711px)');

  let styles = {};

  if (isHome) {
    styles = {
      position: 'fixed',
      bottom: -90,
      backgroundColor: '#161616'
    };
  }

  if (!match) {
    return (
      <Grid
        container
        style={{ backgroundColor: 'var(--light-color, #ffffff)', ...styles }}
      >
        <Grid container item>
          <StyledAppBar
            position="sticky"
            style={{
              borderBottom: 0,
              paddingBottom: 0,
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            <Toolbar component="nav" variant="dense">
              <Grid container justify="space-between">
                <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  alignItems="center"
                  justify="center"
                  className="Footer_links"
                >
                  {[
                    { text: 'About', to: '/program-introduction' },
                    { text: 'Terms of use', to: '/terms' },
                    { text: 'Privacy policy', to: '/privacypolicy' },
                    { text: 'Sitemap', to: '/sitemap' }
                  ].map((link, i) => (
                    <React.Fragment key={`link${i}`}>
                      <StyledLink to={link.to}>
                        <Text
                          color="secondaryDark"
                          style={{
                            letterSpacing: 'normal',
                            textTransform: 'uppercase'
                          }}
                        >
                          {link.text}
                        </Text>
                      </StyledLink>
                      {i < 3 && <li className="bl" />}
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
            </Toolbar>
          </StyledAppBar>
        </Grid>
        <Toolbar
          component="nav"
          variant="dense"
          style={{ color: '#000000', width: '100%' }}
        >
          <Grid container item xs={12} justify="center">
            <Box pb={2} pl={2}>
              <Copyright />
            </Box>
          </Grid>
        </Toolbar>
        <Grid
          container
          xs={12}
          justify="center"
          alignItems="center"
          className="Footer_icons"
          style={{ marginBottom: '10px' }}
        >
          <a
            href="https://www.linkedin.com/company/q-cells-america-incorporated/"
            target="_blank"
            style={{
              width: '28px',
              height: '28px',
              background: '#ccc',
              borderRadius: '100%',
              overflow: 'hidden'
            }}
          >
            <img src={LinkedInIconImg} alt="Linked In" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCvoHvZi_TLGDoXxqmCSK17A"
            target="_blank"
            style={{
              width: '28px',
              height: '28px',
              background: '#ccc',
              borderRadius: '100%',
              overflow: 'hidden',
              marginLeft: '12px'
            }}
          >
            <img src={YoutubeIconImg} alt="Youtube" />
          </a>
          <a
            href="https://www.facebook.com/QCELLSNorthAmerica/"
            target="_blank"
            style={{
              width: '28px',
              height: '28px',
              background: '#ccc',
              borderRadius: '100%',
              overflow: 'hidden',
              marginLeft: '12px'
            }}
          >
            <img src={FacebookIconImg} alt="Facebook" />
          </a>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      className={isTrigger ? 'footer-animation' : ''}
      container
      style={{ backgroundColor: 'var(--light-color, #ffffff)', ...styles }}
    >
      <Grid container item>
        <StyledAppBar position="sticky">
          <Toolbar component="nav" variant="dense">
            <Grid container justify="space-between">
              <Grid
                container
                direction="row"
                item
                xs={10}
                alignItems="center"
                className="Footer_links"
              >
                <Logo src={QpartnerLogo} alt="QCELLS Logo" />
                {[
                  { text: 'About', to: `/about/program-introduction` },
                  { text: 'Contact', to: `/support/contact-us` },
                  { text: 'Terms of use', to: `/terms` },
                  { text: 'Privacy policy', to: `/policy` },
                  { text: 'CCPA privacy notice', to: '/ccpa-privacy-notice'},
                  { text: 'Sitemap', to: '/sitemap' }
                ].map((link, i) => (
                  <StyledLink key={`link${i}`} to={link.to}>
                    <Text
                      color="secondaryDark"
                      style={{
                        // fontSize: 14,
                        letterSpacing: 'normal',
                        textTransform: 'uppercase'
                      }}
                    >
                      {link.text}
                    </Text>
                  </StyledLink>
                ))}
              </Grid>
              <Grid
                container
                item
                xs={2}
                justify="flex-end"
                alignItems="center"
                className="Footer_icons"
              >
                <IconButton
                  style={{ color: '#999' }}
                  onClick={() =>
                    window.open(
                      'https://www.linkedin.com/company/q-cells-america-incorporated/',
                      '_blank'
                    )
                  }
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  style={{ color: '#999' }}
                  onClick={() =>
                    window.open(
                      'https://www.youtube.com/channel/UCvoHvZi_TLGDoXxqmCSK17A',
                      '_blank'
                    )
                  }
                >
                  <YouTubeIcon />
                </IconButton>
                <IconButton
                  style={{ color: '#999' }}
                  onClick={() =>
                    window.open(
                      'https://www.facebook.com/QCELLSNorthAmerica/',
                      '_blank'
                    )
                  }
                >
                  <FacebookIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </StyledAppBar>
      </Grid>
      <Toolbar
        component="nav"
        variant="dense"
        style={{ color: '#000000', width: '100%' }}
      >
        <Grid container item xs={8}>
          <Box pt={2} pb={2} pl={2}>
            <Copyright />
          </Box>
        </Grid>
      </Toolbar>
    </Grid>
  );
}
