import React, { useEffect, useState } from 'react';
import Banner from '../../common/components/Banner';
import BannerImage from '../../config/images/marketing-shop-hero.jpeg';
import Text from '../../common/components/text';
import TitleAnimation from '../../common/components/TitleAnimation';
import BreadCrumb2 from '../../common/components/BreadCrumb2';
import TextButton from '../../common/components/button/TextButton';
import styled from 'styled-components';
import './index.css';
import { Link } from 'react-router-dom';
import {Grid} from '@material-ui/core';

const StyledLink = styled.a
  `
  color: #8c8c8c;
  text-decoration: underline;
  &:hover {
    color: #00adef;
  }

`;

const TitlePolicy = styled.div`
  font-size: 26px;
  color: #000;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 30px;
  text-transform: uppercase;
  
  @media only screen and (max-width: 1600px) {
      font-size: 26px;
      margin-bottom: 35px;
  }
  
  @media only screen and (min-width: 480px) and (max-width: 789px) {
      font-size: 16px;
      margin-bottom: 0px;
  }
  
  @media only screen and (min-width: 375px) and (max-width: 1599px) {
    font-size: 26px;
    margin-bottom: 35px;
  }
  
  
  
`

const SubTitlePolicy = styled.div`
  font-size: 20px;
  color: #666;
  font-weight: 600;
  line-height: 24px;
  transition: all 0.4s;
  margin-bottom: 10px;
  
  @media only screen and (max-width: 1600px) {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 0px;
  }
`

const TextPolicy = styled.span `
  font-size: 16px;
  color: #666666;
  
  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`

const TextNumberPolicy = styled.span `
   font-size: 16px;
   color: #666666;
   margin-right: 5px;
   line-height: 22px;
   display: inline;
    
   @media only screen and (max-width: 480px) {
   font-size: 14px;
   color: #666666;
   } 
   
`

export default function Policy() {

  const [width, setWindowWidth] = useState(0);

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () =>
      window.removeEventListener('resize',updateDimensions);
  }, [])
  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }


  return (
    <section
      style={{
        backgroundColor: 'var(--light-color, #ffffff)',
        position: 'relative',
      }}
    >
      <BreadCrumb2 items={['PRIVACY POLICY']}/>
      <Banner imageUrl={BannerImage} className="banner100">
        <TitleAnimation>PRIVACY POLICY</TitleAnimation>
      </Banner>

      <div className={'wrap-policy'}>
        <TitlePolicy>
          PRIVACY POLICY
        </TitlePolicy>
        {/*<SubTitlePolicy>*/}
        {/*  1. Purpose and Scope*/}
        {/*</SubTitlePolicy>*/}
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'item-policy'}
        >
            {/*<Grid item xs={width > 480 ? 0 : 1}>*/}
            {/*  <TextNumberPolicy>*/}
            {/*    1.1*/}
            {/*  </TextNumberPolicy>*/}
            {/*</Grid>*/}
          <Grid item xs={12}>
            <TextPolicy>
              Hanwha Q CELLS America Inc. and its wholly-owned subsidiaries (collectively, “HQCA,” the “Company,” “we,”
              or “us”) want you to be familiar with how we collect, use, and disclose information. This Privacy Policy
              applies in addition to all privacy-related policies specific to and adopted by a wholly-owned subsidiary
              of HQCA, if any, and describes our practices in connection with information that we collect through:
            </TextPolicy>
          </Grid>
          <Grid item xs={12}>
            <TextPolicy>• Websites operated by us, from which you are accessing this Privacy Policy (the “Websites”);</TextPolicy>
          </Grid>
          <Grid item xs={12}>
            <TextPolicy>• Software applications made available by us for use on or through computers and mobile devices (the “Apps”);</TextPolicy>
          </Grid>
          <Grid item xs={12}>
            <TextPolicy>• Our social media pages and apps that we control, from which you are accessing this Privacy Policy (collectively, our “Social Media Pages”); and</TextPolicy>
          </Grid>
          <Grid item xs={12}>
            <TextPolicy>• HTML-formatted email messages that we send to you that link to this Privacy Policy or other communications with you.
              Collectively, we refer to the Websites, Apps, Social Media Pages, and emails as the “Services.”
              For our California Consumer Privacy Act Notice, please click here.</TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          PERSONAL INFORMATION
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mt-policy'}
        >
          {/*<Grid item xs={width > 480 ? 0 : 1}>*/}
          {/*  <TextNumberPolicy>*/}
          {/*    2.1*/}
          {/*  </TextNumberPolicy>*/}
          {/*  </Grid>*/}
          <Grid item xs={12}>
            <TextPolicy>
              “Personal Information” is information that identifies you as an individual or relates to an identifiable individual. The Services collect Personal Information, including:
            </TextPolicy>
          </Grid>
          <Grid item xs={12}><TextPolicy>• Name</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy>• Business information</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o Title</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o Business name</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o Tax ID</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy>• Contact information (both personal and business), such as </TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o postal address</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o telephone number</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o email address</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy>• Commercial information, such as</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o transaction information</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o purchase history</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o warranty claims</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o HQCA system-related information, including energy production data, energy consumption data such as electricity usage, and energy systems functionality data </TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy>• IP address (from which we may also derive your approximate location)</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy>• User ID and password for our Services</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy>• Audio, electronic, visual, and similar information, such as</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o call recordings</TextPolicy></Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
        </Grid>

        <SubTitlePolicy>
          COLLECTION OF PERSONAL INFORMATION
        </SubTitlePolicy>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>We and our service providers collect Personal Information in a variety of ways, including:</TextPolicy>
          </Grid>
          <Grid item xs={12}>
            <TextPolicy>
              • Through the Services.
              <br></br>
              We collect Personal Information through the Services, for example, when you sign up for a newsletter,
              register an account to access the Services, attend one of our events, contact customer service, or make a
              purchase.
            </TextPolicy>
          </Grid>
          <Grid item xs={12}>
            <TextPolicy>
              • From Other Sources.
              <br></br>
              We receive your Personal Information from other sources, for example:
            </TextPolicy>
          </Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o our affiliates;</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o trusted third-party service providers;</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o the distributor and/or installer from which you purchased one of our products;</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o business partners that provide integrated products, such as inverters used in our systems;</TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o publicly available databases; and </TextPolicy></Grid>
          <Grid item xs={12}><TextPolicy style={{marginLeft : "16px"}}>o joint marketing partners, when they share the information with us.</TextPolicy></Grid>
        </Grid>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={'mr-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>We need to collect Personal Information to provide the requested Services to you.  If you do not provide the information requested, we may not be able to provide the Services. If you disclose any Personal Information relating to other people to us or to our service providers in connection with the Services, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy. </TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          USE OF PERSONAL INFORMATION
        </SubTitlePolicy>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>We and our service providers use Personal Information for the following purposes: </TextPolicy>
          </Grid>
          <Grid item xs={12}>
            <TextPolicy>
              •	Providing the functionality of the Services and fulfilling your requests.
              <br></br>
              To provide the Services’ functionality to you, such as arranging access to your registered account, and providing you with related customer service.
              <br></br>
              To respond to your inquiries and fulfill your requests, when you contact us via one of our online contact forms or otherwise, for example, when you send us questions, suggestions, compliments or complaints, or when you request a quote for or other information about our Services.
              <br></br>
              To complete your transactions, process returns and exchanges, verify your information, and provide you with related customer service.
              <br></br>
              To send administrative information to you, such as changes to our terms, conditions, and policies.
              <br></br>
              To allow you to send messages to another person through the Services if you choose to do so.
            </TextPolicy>
          </Grid>
          <Grid item xs={12}><br></br></Grid>
          <Grid item xs={12}>
            <TextPolicy>
              •	Providing you with our newsletter and/or other marketing materials and facilitating social sharing.
              <br></br>
              To send you marketing related emails, with information about our services, new products and other news about our company.
              <br></br>
              To facilitate social sharing functionality that you choose to use.
            </TextPolicy>
          </Grid>
          <Grid item xs={12}><br></br></Grid>
          <Grid item xs={12}>
            <TextPolicy>
              •	Analyzing Personal Information for business reporting and providing personalized services.
              <br></br>
              To analyze or predict our users’ preferences in order to prepare aggregated trend reports on how our digital content is used, so we can improve our products and/or Services.
              <br></br>
              To better understand your interests and preferences, so that we can personalize our interactions with you and provide you with information and/or offers tailored to your interests.
              <br></br>
              To better understand your preferences so that we can deliver content via our Services that we believe will be relevant and interesting to you.
            </TextPolicy>
          </Grid>
          <Grid item xs={12}><br></br></Grid>
          <Grid item xs={12}>
            <TextPolicy>
              •	Allowing you to participate in promotions.
              <br></br>
              We may offer you the opportunity to participate in a promotion.
              <br></br>
              Some of these promotions have additional rules containing information about how we will use and disclose your Personal Information.  Please read those additional rules before choosing to participate.
            </TextPolicy>
          </Grid>
          <Grid item xs={12}><br></br></Grid>
          <Grid item xs={12}>
            <TextPolicy>
              •	Aggregating and/or anonymizing Personal Information.
              <br></br>
              We may aggregate and/or anonymize Personal Information so that it will no longer be considered Personal Information. We do so to generate other data for our use, which we may use and disclose for any purpose, as it no longer identifies you or any other individual.
            </TextPolicy>
          </Grid>
          <Grid item xs={12}><br></br></Grid>
          <Grid item xs={12}>
            <TextPolicy>
              •	Accomplishing our business purposes.
              <br></br>
              For data analysis, for example, to improve the efficiency of our Services;
              <br></br>
              For audits, to verify that our internal processes function as intended and to address legal, regulatory, or contractual requirements;
              <br></br>
              For fraud prevention and fraud and security monitoring purposes, for example, to detect and prevent cyberattacks or attempts to commit identity theft;
              <br></br>
              For developing new products and services;
              <br></br>
              For enhancing, improving, repairing, maintaining, or modifying our current products and services, as well as undertaking quality and safety assurance measures;
              <br></br>
              For identifying usage trends, for example, understanding which parts of our Services are of most interest to users;
              <br></br>
              For determining the effectiveness of our promotional campaigns, so that we can adapt our campaigns to the needs and interests of our users; and
              <br></br>
              For operating and expanding our business activities, for example, understanding which parts of our Services are of most interest to our users so we can focus our energies on meeting our users’ interests.
            </TextPolicy>
          </Grid>
          <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              className={'mr-policy'}
          >
          </Grid>
        </Grid>

        <SubTitlePolicy>
          DISCLOSURE OF PERSONAL INFORMATION
        </SubTitlePolicy>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>We disclose Personal Information: </TextPolicy>
          </Grid>
          <Grid item xs={12}>
            <TextPolicy>
              •	To our affiliates for the purposes described in this Privacy Policy.
            </TextPolicy>
          </Grid>
          <Grid item xs={12}>
            <TextPolicy>
              •	To our third party service providers, to facilitate services they provide to us.
              <br></br>
              These can include providers of services such as website hosting, data analysis, payment processing, order fulfillment, return authorization, fraud prevention, information technology and related infrastructure provision, customer service, email delivery, auditing, and other services.
            </TextPolicy>
          </Grid>
          <Grid item xs={12}>
            <TextPolicy>
              •	To third parties, such as our business partners, to permit them to send you marketing communications, consistent with your choices.
              <br></br>
              We may share Personal Information with our customers such as installers and distributors from which you purchased one of our products, our suppliers and other business partners.
            </TextPolicy>
          </Grid>
          <Grid item xs={12}>
            <TextPolicy>
              •	By using the Services, you may elect to disclose Personal Information.
              <br></br>
              On message boards, chat, profile pages, blogs, and other services to which you are able to post information and content (including, without limitation, our Social Media Pages).  Please note that any information you post or disclose through these services will become public and may be available to other users and the general public.
            </TextPolicy>
          </Grid>
          <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              className={'mr-policy'}
          >
          </Grid>
        </Grid>

        <SubTitlePolicy>
          OTHER USES AND DISCLOSURES
        </SubTitlePolicy>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>We may also use and disclose Personal Information as we believe to be necessary or appropriate: (a) to comply with applicable law, to respond to requests from public and government authorities, to cooperate with law enforcement, or for other legal reasons; (b) to enforce our terms and conditions; and (c) to protect our rights, privacy, safety or property, and/or that of our affiliates, you, or others. We may use, disclose or transfer your information to a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our assets or stock (including in connection with any bankruptcy or similar proceedings).</TextPolicy>
          </Grid>
          <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              className={'mr-policy'}
          >
          </Grid>
        </Grid>

        <SubTitlePolicy>
          OTHER INFORMATION
        </SubTitlePolicy>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={'mt-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>“Other Information” is any information that does not reveal your specific identity or does not directly relate to an identifiable individual. The Services collect Other Information such as:</TextPolicy>
          </Grid>
          <Grid item xs={12}>
            <TextPolicy>
              •	Browser and device information (including browsing history)
              <br></br>
              •	App and website usage data
              <br></br>
              •	Information collected through cookies, pixel tags and other technologies
              <br></br>
              •	Demographic information and other information provided by you that does not reveal your specific identity
              <br></br>
              •	Information that has been aggregated in a manner such that it no longer reveals your specific identity
            </TextPolicy>
          </Grid>
          <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              className={'mr-policy'}
          >
          </Grid>
        </Grid>

        <SubTitlePolicy>
          COLLECTION OF OTHER INFORMATION
        </SubTitlePolicy>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={'mr-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>We and our service providers may collect Other Information in a variety of ways, including:  </TextPolicy>
          </Grid>
          <Grid item xs={12}>
            <TextPolicy>
              •	Your browser or device.
              <br></br>
              When you download and use the App, we and our service providers may track and collect App usage data, such as the date and time the App on your device accesses our servers and what information and files have been downloaded to the App based on your device number.
            </TextPolicy>
          </Grid>
          <Grid item xs={12}>
            <TextPolicy>
              •	Cookies.
              <br></br>
              Cookies are pieces of information stored directly on the computer that you are using.  Cookies allow us to collect information such as browser type, time spent on the Services, pages visited, language preferences, and other traffic data. We and our service providers use the information for security purposes, to facilitate navigation, to display information more effectively, and to personalize your experience.  We also gather statistical information about use of the Services in order to continually improve their design and functionality, understand how they are used, and assist us with resolving questions regarding them. Cookies further allow us to select which of our advertisements or offers are most likely to appeal to you and display them while you are on the Services.  We may also use cookies or other technologies in online advertising to track responses to our ads.   We do not currently respond to browser do-not-track signals. If you do not want information collected through the use of cookies, most browsers allow you to automatically decline cookies or be given the choice of declining or accepting a particular cookie (or cookies) from a particular website.  You may also wish to refer to http://www.allaboutcookies.org/manage-cookies/index.html. If, however, you do not accept cookies, you may experience some inconvenience in your use of the Services.  You also may not receive advertising or other offers from us that are relevant to your interests and needs.
            </TextPolicy>
          </Grid>
          <Grid item xs={12}>
            <TextPolicy>
              •	Pixel tags and other similar technologies.
              <br></br>
              Pixel tags. Pixel tags (also known as web beacons and clear GIFs) may be used to, among other things, track the actions of users of the Services (including email recipients), measure the success of our marketing campaigns, and compile statistics about usage of the Services and response rates.
              <br></br>
              Analytics. We use Google Analytics, which uses cookies and similar technologies to collect and analyze information about use of the Services and report on activities and trends.  This service may also collect information regarding the use of other websites, apps and online resources.  You can learn about Google’s practices by going to www.google.com/policies/privacy/partners/, and exercise the opt-out provided by Google by downloading the Google Analytics opt-out browser add-on, available at https://tools.google.com/dlpage/gaoptout.
              <br></br>
              Uses and Disclosures of Other Information
              <br></br>
              We may use and disclose Other Information for any purpose, except where we are required to do otherwise under applicable law.  If we are required to treat Other Information as Personal Information under applicable law, we may use and disclose it for the purposes for which we use and disclose Personal Information as detailed in this Policy.  In some instances, we may combine Other Information with Personal Information.  If we do, we will treat the combined information as Personal Information as long as it is combined.
            </TextPolicy>
          </Grid>
        </Grid>
        <SubTitlePolicy>
          SECURITY
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              We seek to use reasonable organizational, technical and administrative measures to protect Personal Information within our organization.  Unfortunately, no data transmission or storage system can be guaranteed to be 100% secure.  If you have reason to believe that your interaction with us is no longer secure, please immediately notify us in accordance with the “Contacting Us” section below.
            </TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          CHOICES AND ACCESS
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              Your choices regarding our use and disclosure of your Personal Information
              <br></br>
              We give you choices regarding our use and disclosure of your Personal Information for marketing purposes.  You may opt out from:
              <br></br>
              (1)	Receiving marketing-related emails from us.  If you no longer want to receive marketing related emails from us on a going-forward basis, you may opt out by clicking on the unsubscribe link at the bottom of the email.
              <br></br>
              (2)	Our sharing of your Personal Information with affiliates for their direct marketing purposes.  If you would prefer that we discontinue sharing your Personal Information on a going-forward basis with our affiliates for their direct marketing purposes, you may opt out of this sharing by contacting us as described in the Contacting Us section below.
              <br></br>
              (3)	Our sharing of your Personal Information with unaffiliated third parties for their direct marketing purposes.  We may share your Personal Information with third parties, for example, if you request to be in contact with an installer.  If you would prefer that we discontinue sharing your Personal Information on a going-forward basis with unaffiliated third parties for their direct marketing purposes, you may opt out of this sharing by contacting us as described in the Contacting Us section below.
              <br></br>
              We will try to comply with your request(s) as soon as reasonably practicable.  Please note that if you opt out of receiving marketing related emails from us, we may still send you important administrative messages, from which you cannot opt out.
              <br></br>
              How you can access, change, or delete your Personal Information
              <br></br>
              If you would like to request to access, correct, update, or delete Personal Information, you may contact us in accordance with the “Contacting Us” section below.  We will respond to your request consistent with applicable law.  If you are a California resident, please refer to our CCPA Privacy Notice at https://qcellspartner.us/ccpa-privacy-notice for more information about the requests you may make under the CCPA.
              <br></br>
              In your request, please make clear what Personal Information you would like to have changed or whether you would like to have your Personal Information suppressed from our database.  For your protection, we may only implement requests with respect to the Personal Information associated with the particular email address that you use to send us your request, and we may need to verify your identity before implementing your request.  We will try to comply with your request as soon as reasonably practicable.
              <br></br>
              Please note that we may need to retain certain information for recordkeeping purposes and/or to complete any transactions that you began prior to requesting a change or deletion (e.g., when you make a purchase or enter a promotion, you may not be able to change or delete the Personal Information provided until after the completion of such purchase or promotion).
            </TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          THIRD PARTY SERVICES
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              This Privacy Policy does not address, and we are not responsible for, the privacy, information, or other practices of any third parties, including any third party operating any website or service to which the Services link.  The inclusion of a link on the Services does not imply endorsement of the linked site or service by us or by our affiliates.
              <br></br>
              In addition, we are not responsible for the information collection, use, disclosure, or security policies or practices of other organizations, such as Facebook, Apple, Google, Microsoft, RIM, or any other app developer, app provider, social media platform provider, operating system provider, wireless service provider, or device manufacturer, including with respect to any Personal Information you disclose to other organizations through or in connection with the Apps or our Social Media Pages.
            </TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          THIRD PARTY ADVERTISING
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              We use third-party advertising companies to serve advertisements regarding goods and services that may be of interest to you when you access and use the Services and other websites or online services.
              <br></br>
              •	You may receive advertisements based on information relating to your access to and use of the Services and other websites or online services on any of your devices, as well as on information received from third parties.  These companies place or recognize a unique cookie on your browser (including through the use of pixel tags).  They also use these technologies, along with information they collect about your online use, to recognize you across the devices you use, such as a mobile phone and a laptop.  If you would like more information about this practice, and to learn how to opt out of it in desktop and mobile browsers on the particular device on which you are accessing this Privacy Policy, please visit http://optout.aboutads.info/#/ and http://optout.networkadvertising.org/#/. You may download the AppChoices app at www.aboutads.info/appchoices to opt out in mobile apps.
            </TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          USE OF SERVICES BY MINORS
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              The Services are not directed to individuals under the age of thirteen (13), and we do not knowingly collect Personal Information from individuals under 13.
            </TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          JURISDICTION AND CROSS-BORDER TRANSFER
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              Your Personal Information may be stored and processed in any country where we have facilities or in which we engage service providers, and by using the Services you understand that your information will be transferred to countries outside of your country of residence, including the United States, which may have data protection rules that are different from those of your country.
            </TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          SENSITIVE INFORMATION
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              Unless we request it, we ask that you not send us, and you not disclose, any sensitive Personal Information (e.g., social security numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, or criminal background) on or through the Services or otherwise to us.
            </TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          THIRD PARTY PAYMENT SERVICE
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              We may use a third party payment service to process payments made through the Services. If you wish to make a payment through the Services, your Personal Information will be collected by such third party and not by us, and will be subject to the third party’s privacy policy, rather than this Privacy Policy.  We have no control over, and are not responsible for, this third party’s collection, use, and disclosure of your Personal Information.
            </TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          UPDATES TO THIS PRIVACY POLICY
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              The “LAST UPDATED” legend at the top of this Privacy Policy indicates when this Privacy Policy was last revised.  Any changes will become effective when we post the revised Privacy Policy on the Services.
            </TextPolicy>
          </Grid>
        </Grid>

        <SubTitlePolicy>
          CONTACTING US
        </SubTitlePolicy>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
          <Grid item xs={12}>
            <TextPolicy>
              If you have any questions about this Privacy Policy, please contact us at https://qcells.com/us/get-support/contact-us or 1-800-749-5996, or:
              <br></br>
              Hanwha Q CELLS America Inc.
              <br></br>
              400 Spectrum Center Drive, Suite 1400
              <br></br>
              Irvine, CA 92618
              <br></br>

              Because email communications are not always secure, please do not include credit card or other sensitive information in your emails to us.
            </TextPolicy>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={'mr-policy'}
        >
        </Grid>
      </div>
    </section>
  )
}
