import React from 'react';
import Banner from '../../common/components/Banner';
import BannerImage from '../../config/images/visual_area01_1.jpg';
import Text from '../../common/components/text';
import TitleAnimation from '../../common/components/TitleAnimation';
import BreadCrumb2 from '../../common/components/BreadCrumb2';
import styled from 'styled-components';
import {Grid} from '@material-ui/core';

const StyledContainer = styled.div `
  width: 1200px;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 70px;
  
  @media only screen and (max-width: 1200px) {
    width: 90vw;
  }
  @media only screen and (max-width: 600px) {
    width: 88vw;
    margin-top: 25px;
  }
`;

const TitleTerm = styled.h3 `
  font-size: 26px;
  font-family: Actual;
  font-weight: bold;
  
  @media only screen and (max-width: 480px) {
  font-size: 16px;
  }
`;

const SubTitleTerm = styled.h3 `
  font-size: 20px;
  font-family: Actual;
  color: #666;
  margin-bottom: 20px;
  font-weight: 600;
  margin-top: 20px;
  line-height: 24px;
  @media only screen and (max-width : 480px) {
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 15px;
  }
`;

const TextContentTerm = styled.span `
  font-size: 16px;
  color: #666;
  font-weight: 400;
  font-family: Actual;
  display: inline;
  
  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

const TextContentTermTitle = styled.p `
  font-size: 16px;
  color: rgb(102, 102, 102);
  font-weight: 400;
  margin-right: 22px;
  font-family: Actual;
  display: inline;
  
  @media only screen and (max-width: 480px) {
    font-size: 14px;
    margin-right: 10px;
  }
`

export default function Terms() {
  const terms = [
    {
      title: '1. Purpose',
      contents: [
        {
          number: '1.1',
          name: 'Hanwha Q CELLS America Inc. or one of its affiliates (“Company,” “us,” or “we”) is the provider of this website as shown in www.qcellspartner.us and any other dependent pages that you may be able to access from this Website (collectively, the “Website”). We created these terms of use (the “Terms of Use”) to permit and regulate the use of this Website and any services and functions related thereto. By accessing this Website, Users (defined below) are bound by the terms and conditions set forth. If you do not agree to be bound by the terms and conditions of these Terms of Use, please do not use this Website.',
          subjects: []
        },
        {
          number: '1.2',
          name: 'Unless stated otherwise in a written agreement between the Company and the User, these Terms of Use prevail over any other agreement with respect to any content, materials, or services available from this Website.',
          subjects: []
        }
      ]
    },
    {
      title: '2. Users',
      contents: [
        {
          number: '2.1',
          name: 'This Website may be used by you in an individual capacity or as a representative of a business entity (“Users,” or “you”). This Website is available only to individuals of the age of majority who can form legally binding contracts under applicable law and your use of this Website is your representation that you satisfy this requirement.',
          subjects: []
        }
      ]
    },
    {
      title: '3. Prohibited Uses',
      contents: [
        {
          number: '3.1',
          name: 'Users agree that you will not use this Website:',
          subjects: [
            '- (a) in a manner that is prohibited by any applicable law or regulation, or to facilitate the violation of any applicable law or regulation;',
            '- (b) to invade the privacy of others;',
            '- (c) to violate, plagiarize, or infringe on the intellectual property or contractual rights of any person in connection with any use of this Website; and',
            '- (d) to transmit unlawful, discriminatory or otherwise objectionable material, or to encourage conduct that would give rise to civil liability of any manners.'
          ],
        },
        {
          number: '3.2',
          name: 'Users agree that Users will not,',
          subjects: [
            '- (a) violate, or assist in violating, the security of this Website, whether intentionally, negligently or otherwise;',
            '- (b) impersonate Company personnel or other persons or entities;',
            '- (c) use any electronic mail message, device, software or programming routine that may impair, interfere or impose an unreasonable burden on this Website;',
            '- (d) intercept or expropriate any system, data or information of this Website;',
            '- (e) resell, assign or transfer, in whole or in part, User’s right to use this Website; or',
            '- (f) collect, use, process, sell or disclose any personal or other information of any individual other than in strict compliance with all applicable law and the consents obtained by Users directly from such individual.'
          ],
        },
        {
          number: '3.3',
          name: 'Users agree that the Company may have access to your account and records as reasonably necessary to investigate complaints and maintain this Website.',
          subjects: []
        }
      ]
    },
    {
      title: '4. Limitation of Liability',
      contents: [
        {
          number: '4.1',
          name: 'The use of this Website is at the User’s own discretion and risk. All content on the Website is provided on an “as is” and “as available” basis.',
          subjects: [],
        },
        {
          number: '4.2',
          name: 'To the fullest extent permissible pursuant to applicable law, the Company disclaims all conditions and warranties, express or implied, including, but not limited to, all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement. The Company does not warrant or make any representations regarding the operation or use of the results of the use of the material on the Website in terms of its correctness, accuracy, reliability, effectiveness, or otherwise. The material on this Website or the operation of this Website could include technical inaccuracies or typographical errors, may not be free or viruses or other harmful components, or free of defects or errors and could be inaccurate or become inaccurate as a result of developments occurring after their respective dates. The Company takes no obligation to verify or maintain the currency of such information, and the defects may not be corrected timely. Accordingly, the Company will not be liable for any damages of any kind arising from the use of this Website, including, but not limited to direct, indirect, incidental, punitive, and consequential damages.',
          subjects: [],
        }
      ]
    },
    {
      title: '5. Terms of Use Updates, Changes, and Modification',
      contents: [
        {
          number: '5.1',
          name: 'The Company has the right to amend these Terms of Use and/or the terms applicable to any program described in or accessible through the Website at any time by posting such amended terms on the Website or as otherwise notified to you. Such amended terms will be effective on the date specified in the posting or as otherwise notified to you. Your use of this Website following the effectiveness of any such amendment will indicate your acceptance of such amended terms.',
          subjects: [],
        }
      ]
    },
    {
      title: '6. Proprietary Materials',
      contents: [
        {
          number: '6.1',
          name: 'This Website contains trademarks, copyrights and other proprietary information and materials. This Website and all such materials are owned or licensed by the Company. Everything on this Website is copyrighted. Users agree not to reproduce, modify, adapt, publish, create derivative works from, sell, distribute, display or otherwise transmit or exploit any of such proprietary materials without the express written permission of the Company to such materials. Users agree not to remove any copyright, trademark, or other proprietary notice or legend contained on this Website or on any materials obtained through this Website.',
          subjects: [],
        }
      ]
    },
    {
      title: '7. Privacy Policy',
      contents: [
        {
          number: '7.1',
          name: 'Users should review our current privacy policy, available at https://qcellspartner.us/policy (the “Privacy Policy”), which is incorporated in these Terms of Use by reference. We reserve the right to disclose non-personally identifiable information regarding Users to third parties, and any information required to be disclosed by applicable law to government authorities.',
          subjects: [],
        },
        {
          number: '7.2',
          name: 'Users hereby represent and warrant to the Company that (i) Users have obtained consents to Users’ collection, use, processing, sale and/or disclosure of any personal or other information of any individual directly from such individual, including in respect of the Company’s use of any such information in accordance with the Privacy Policy, regardless of whether collected  and/or disclosed to the Company before, on or after the effective date of these Terms of Use or the Privacy Policy, (ii) Users’ collection, use, processing, sale and disclosure of such personal or other information of any individual, both before and after the effective date of these Terms of Use or the Privacy Policy, including, without limitation, disclosure to the Company through the Website or any other channel, was conducted in strict compliance with all applicable law and the consents obtained by Users directly from such individual and (iii) within two (2) business days following notification by the Company, Users shall provide to the Company any records or documentation reasonably requested by the Company to evidence Users’ compliance with the foregoing representations and warranties or any other requirement contained in these Terms of Use, which documentation may include copies of such individual consents.',
          subjects: [],  
        }
      ]
    },
    {
      title: '8. Governing Law',
      contents: [
        {
          number: '8.1',
          name: 'Any dispute arising in relation to your access and use of this Website shall be governed by the laws of the State of California, without regard to its conflicts of laws principle. Users and the Company consent to the exclusive jurisdiction of the federal or state courts in Orange County, California for any legal actions arising out of or relating to these Terms of Use.',
          subjects: [],
        }
      ]
    },
  ]

  return (
    <section
      style={{
        backgroundColor: 'var(--light-color, #ffffff)',
        position: 'relative',
      }}
    >
      <BreadCrumb2 items={['Terms of Use Agreement']}/>
      <Banner imageUrl={BannerImage} className="banner100">
        <TitleAnimation>Terms of Use Agreement</TitleAnimation>
      </Banner>

      <StyledContainer>
        <TitleTerm>
          TERMS OF USE AGREEMENT
        </TitleTerm>
        {terms.map(term => {
          return (
            <>
              <SubTitleTerm>
                {term.title}
              </SubTitleTerm>
              {term.contents.map(content => (
                <Grid
                className={'container-term'}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                  <Grid item md={0} lg={0} xs={0} >
                    <TextContentTermTitle>
                      {content.number}
                    </TextContentTermTitle>
                  </Grid>
                  <Grid item xs={11} md={11} lg={11}>
                    <TextContentTerm>
                      {content.name}<br></br>
                      {content.subjects.length > 0 ? content.subjects.map(subject => {
                        return (
                          <>
                            {subject}<br></br>
                          </>
                        )
                      }) : false}
                    </TextContentTerm>
                  </Grid>
              </Grid>
              ))}
            </>
          )
        })}
      </StyledContainer>
    </section>
  )
}
