import React from 'react';
import PopOver from '../../../config/images/popover_icon.svg';
import useStyles from './useStyles';

function ShowMessageUpdate({content1, content2}) {
  const classes = useStyles();

  return(
    <div className={classes.wrapMessageUpdate}>
      <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>
      <ul className={classes.wrapLine}>
        <li className={classes.text1Update}>{content1}</li>
        <li className={classes.text2Update} style={{paddingLeft: content1 && '10px'}}>{content2}</li>
      </ul>
    </div>
  )
}

export default ShowMessageUpdate;