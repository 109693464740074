import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, TextareaAutosize, useMediaQuery } from '@material-ui/core';
import useStyles from './useStyles';
import CustomButton from '../../../common/components/button';
import IconEdit from '../../../icons/edit_icon.svg';
import OpenImageIcon from '../../../config/images/open_image_icon.svg'
import Moment from 'react-moment';
import _ from 'lodash';
import CustomLoading from '../../../common/components/CustomLoading';
import ImageModal from './ImageModal';
// import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import Error from '../../claim/components/Error';
import moment from 'moment';
import MessageReview from './MessageReview';
import { notiFailed } from '../../../utils/helpers';

export default function ReviewInfo({ goBack,errors ,goToStep,filesProof ,goBackToMyProject, watch, control,disabled ,handleSubmit, loading, loadingSaveDraft, handleSaveDraft, isEditCompleteProject = false }) {
    const classes = useStyles();
    const isMediumScreen = useMediaQuery('(max-width:1245px)');
    const [openModal, setOpenModal] = useState(false);
    const [modalUrl, setModalUrl] = useState('');
    const data = watch();


    const moduleSerialList = watch('moduleSerialNumber');
    const isManualSerialPanelNotMatchDb = watch('isManualSerialPanelNotMatchDb');
    const essSerial = watch('essSerialNumber');
    const isSerialNumber = watch('isSerialNumber');
    const isEssSerialNumber = watch('isEssSerialNumber');
    const volume = watch('modulePowerClass');

    const numberOfModules = watch('numberOfModules');
    const isManualSerialNumber = watch('isManualSerialNumber');
    const panelQualified = watch('panelQualified');
    const batteryQualified = watch('batteryQualified');
    const panelNonQcells = watch('panelNonQcells')
    const batteryNonQcells = watch('batteryNonQcells');
    const isDuplicateAddress = watch('isDuplicateAddress');

    let errorDuplicate = isDuplicateAddress ? {message : 'Duplicate Address'} : {};

    // console.table([['panelQualified', panelQualified], ['batteryQualified', batteryQualified], ['panelNonQcells', panelNonQcells], ['batteryNonQcells', batteryNonQcells]])

    const moduleSerial = useMemo(() => {
        if(data.moduleBrand === 'Q CELLS') {
            moduleSerialList.forEach(moduleSerial => {
                moduleSerial['productName'] = data.moduleModel;
                moduleSerial['volume'] = data.modulePowerClass
            })
        }
        const uniqModuleSerialNumber = _.remove(
          _.uniqBy(moduleSerialList, 'productName'),
          p => p.productName
        );

        return uniqModuleSerialNumber
    }, [moduleSerialList]);


    function isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    const renderProjectName = (data) => {
        if (_.trim(data.projectName)) {
            return _.trim(data.projectName);
        }

        const firstName = data.firstName;
        const lastName = data.lastName;

        const city = data.projectCity;
        let systemSize = null;

        if (data.moduleBrand !== 'Q CELLS') {
            systemSize = volume * numberOfModules;
        }

        if(data.moduleBrand === 'Q CELLS' && !data.isSerialNumber) {
            systemSize = volume * numberOfModules;
        }

        if (data.moduleBrand === 'Q CELLS' && data.isSerialNumber) {
            if (data.isManualSerialNumber) {
                systemSize = volume * numberOfModules;
            } else {
                const uniqModuleSerialNumber = _.remove(_.uniqBy(data.moduleSerialNumber, 'productName'), p => p.productName);
                systemSize = _.get(data, 'moduleSerialNumber.length') * _.get(uniqModuleSerialNumber, '[0].volume');
            }
        }

        if (systemSize) {
            systemSize = `${systemSize / 1000} kW`;
        }

        return _.join(_.remove([_.trim(`${firstName || ''} ${lastName || ''}`), systemSize, city], value => !!value), ', ');
    };

    const renderAddress = (array = []) => {
        const newArray = _.remove(array, value => !!value && value !== 'select');
        return newArray.length > 0 ? _.join(newArray, ', ') : '';
    };

    const renderPurchasingChannel = purchasingChannelValue => {
        switch (purchasingChannelValue) {
            case 'qcells':
                return 'Q CELLS';
            case 'distributor':
                return 'Distributor';
            case 'other':
                return 'Other';
            default:
                return 'Q CELLS';
        }
    };

    const renderNameOfPurchasingChannel = () => {
        const purchaseChannel = data.purchasingChannel;
        switch (purchaseChannel) {
            case 'qcells':
                return 'Q CELLS';
            case 'distributor':
                return _.get(data, 'nameOfPurchasingChannelDistributor.name') + '' + (_.get(data, 'nameOfPurchasingChannelDistributor.name') === 'Other' ? `-${data.nameOfChanelOtherDistributor}` : '');
            case 'other':
                return data.nameOfPurchasingChannelOther;
            default:
                return '';
        }
    }


    const renderBatteryModel = (model) => {
        return (
          <>{model === 'select' ? '' : model} <br/>{!batteryQualified && <MessageReview text={'*This product does not qualify for labor cost reimbursement'}/>}</>
        )
    }

    const renderField = fieldName => {
        const fieldValue = data[fieldName];
        if (!!fieldValue && fieldValue !== 'select') {
            return fieldValue;
        }

        return null;
    };

    function renderModuleBrand() {
        if(!errors.moduleBrand) {

            return <p>{`${data.moduleBrand} ${data.moduleBrand === 'Other' ? `-${data.nameOfModuleBrandPanelOther}` : ''}`} <br/> {panelNonQcells && <MessageReview text={'*Project should use Q CELLS residential products only to qualify for labor reimbursement'}/>}</p>

        }else {
            return <p><Error/></p>
        }


    }

    function renderBatteryModule() {
        if(!errors.batteryBrand) {

            return <p>{`${data.batteryBrand}   ${data.batteryBrand === 'Other' ? `-${data.nameOfModuleBrandBatteryOther}` : ''}`} <br/>{batteryNonQcells && <MessageReview text={'*Project should use Q CELLS residential products only to qualify for labor reimbursement'}/>}</p>

        }else {
            return <p><Error/></p>
        }
    }


    function renderSysSize() {
        let systemSize = data?.moduleBrand !== 'Q CELLS' && volume !== '' && numberOfModules !== ''
          ? (_.toNumber(volume) * _.toNumber(numberOfModules)) / 1000
          : (moduleSerial.length === 0 ? ((volume === '' || numberOfModules === '' ? 0  :(_.toNumber(volume) * _.toNumber(numberOfModules)) / 1000)) : (_.toNumber(_.get(moduleSerial, '[0].volume', volume)) * (isManualSerialPanelNotMatchDb ? numberOfModules : moduleSerialList.length)) / 1000);

        return systemSize > 25 && <MessageReview text={'*System size should be less than or equal to 25kW to qualify for labor reimbursement'}/>
    }

    function renderOperaDateError() {
        const perateDateI = moment(_.get(data, 'operateDate')).add(3, 'M').format('YYYY-MM-DD');
        const dateNow = moment().format('YYYY-MM-DD')
        const isOpe = moment(perateDateI).isSameOrAfter(dateNow)
        return (
          <>
              <Moment format="L">{data.operateDate}</Moment>
              <br/>
              {!isOpe && <MessageReview text={'*This project will not qualify for labor reimbursement as the PTO date is not within the past 3 months'} />}
          </>
        )
    }

    function renderModelPanel() {
        return(
          <>{(data?.moduleBrand !== 'Q CELLS') ? (data.moduleModel !== '' ? data.moduleModel : 'N/A' ) : data.moduleModel} <br/> {!panelQualified && <MessageReview text={'*This product does not qualify for labor cost reimbursement'}/>}</>
        )
    }

    function renderModelBattery() {
        return(
          <></>
        )
    }


    return (
        <div className={classes.reviewInfoContainer}>
            <div className={classes.reviewSectionsContainer}>
                <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
                    <h5>Project Information</h5>
                    <Button className={classes.editBtn} onClick={() => goToStep(0)}>
                        <span>Edit</span>
                        <img src={IconEdit} title='Edit' />
                    </Button>
                </div>
                <div className={classes.reviewSectionContainer} style={{ borderTop: '1px solid rgba(0, 0, 0, .1)' }}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Project Name</h6>
                        <p>{renderProjectName(data)}</p>
                    </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Project Address</h6>
                        <p>
                            {!errors?.projectAddress && !isDuplicateAddress  ?
                            renderAddress([
                                data.projectAddress?.label,
                                data.projectAddress2,
                                data.projectCity,
                                data.projectState,
                                data.projectZipCode
                            ])
                            : <span style={{color: '#EB5757'}}>Required field missing</span> }
                        </p>
                        {/* <p>
                        {!errors?.projectAddress ? data.projectAddress?.label : <span style={{color: '#EB5757'}}>Required field missing</span> }
                        </p> */}
                    </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div
                        style={{
                            paddingTop: '13px',
                            paddingBottom: '13px'
                        }}
                        className={classes.reviewSectionContent}
                    >
                        <h6>Project Picture</h6>
                        <div className={classes.images}>
                            {data.projectPictures && data.projectPictures.map(p => (
                                <div key={p.url}>
                                    <img src={p.url} className={classes.image} />
                                    <div className={classes.overlay} role="overlay">
                                        <img
                                            src={OpenImageIcon}
                                            onClick={() => {
                                                setModalUrl(p.url);
                                                setOpenModal(true);
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Installation Date</h6>
                        <p>
                            {data.installationDate && !errors?.installationDate ? <Moment format="L">{data.installationDate}</Moment> : <span style={{color: '#EB5757'}}>Required field missing</span>}
                        </p>
                    </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Permission-to-Operate Date</h6>
                        <p>
                            {data.operateDate && !errors?.operateDate ? renderOperaDateError() : <span style={{color: '#EB5757'}}>Required field missing</span>}
                        </p>
                    </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div
                        style={{
                            paddingTop: '13px',
                            paddingBottom: '13px'
                        }}
                        className={classes.reviewSectionContent}
                    >
                        <h6>Proof of Permission-to-Operate Date</h6>
                        <div className={classes.images}>
                            {filesProof?.length > 0 && filesProof?.map(p => (
                                <div key={p.url}>
                                    <img src={p.url} className={classes.image} />
                                    <div className={classes.overlay} role="overlay">
                                        <img
                                            src={OpenImageIcon}
                                            onClick={() => {
                                                setModalUrl(p.url);
                                                setOpenModal(true);
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.reviewSectionsContainer}>
                <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
                    <h5>System Information</h5>
                    <Button className={classes.editBtn} onClick={() => goToStep(1)}>
                        <span>Edit</span>
                        <img src={IconEdit} title='Edit' />
                    </Button>
                </div>
                <div className={classes.reviewSectionContent} style={{ borderTop: '1px solid rgba(0, 0, 0, .1)', paddingLeft: 0 }}><p style={{ color: '#4f4f4f'}}>Panel</p></div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Brand</h6>
                        {renderModuleBrand()}
                    </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Power Class (W)</h6>
                        <p>{!errors?.modulePowerClass ? (data?.moduleBrand !== 'Q CELLS') ? data.modulePowerClass : _.get(moduleSerial, '[0].volume', data.modulePowerClass) : <span style={{color: '#EB5757'}}>Required field missing</span>} {!errors?.modulePowerClass ? 'W' : ''}</p>
                    </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Model</h6>
                        <p>{!errors?.moduleModel ? renderModelPanel() : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
                    </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Number of Modules</h6>
                        <p>{!errors?.numberOfModules ? (data?.moduleBrand !== 'Q CELLS') ? data.numberOfModules : (isManualSerialPanelNotMatchDb ?  data.numberOfModules : (isSerialNumber ? moduleSerialList?.length : data.numberOfModules )) : <span style={{color: '#EB5757'}}>Required field missing</span>} {!errors?.numberOfModules ? 'pcs' : ''}</p>
                    </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>System Size </h6>
                        <p>{ data?.moduleBrand !== 'Q CELLS' && volume !== '' && numberOfModules !== ''
                          ? (_.toNumber(volume) * _.toNumber(numberOfModules)) / 1000
                          : (moduleSerial.length === 0 ? ((volume === '' || numberOfModules === '' ? 0  :(_.toNumber(volume) * _.toNumber(numberOfModules)) / 1000)) : (_.toNumber(_.get(moduleSerial, '[0].volume', volume)) * (isManualSerialPanelNotMatchDb ? numberOfModules : moduleSerialList.length)) / 1000)} kW <br/>{renderSysSize()}</p>
                    </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <p style={{ color: '#4f4f4f', marginLeft: -20}}>Battery</p>
                        <p>{data?.battery ? 'Yes' : 'No'}</p>
                    </div>
                </div>
                {data?.battery && <>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>System Type</h6>
                        <p>{!errors?.systemType ? renderField('systemType') :<span style={{color: '#EB5757'}}>Required field missing</span> }</p>
                    </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Brand</h6>
                        {renderBatteryModule()}
                    </div>
                </div>

                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Model</h6>
                        <p>{!errors?.batteryModel ? renderBatteryModel(data.batteryModel) : (<span style={{color: '#EB5757'}}>Required field missing</span>)}</p>
                    </div>
                </div>
                {/* {renderField('systemType') === 'AC' && (data.batteryBrand === 'Q CELLS' || data.batteryBrand === 'select') && <div className={classes.reviewSectionContainer}> */}
                {['select', 'Q CELLS'].includes(data.batteryBrand) && <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Battery Inverter Power(kW)</h6>
                        <p>{!errors?.batteryInverterPower ? data?.batteryBrand === 'Q CELLS' ? `${data.batteryInverterPower} kW` : '' : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
                    </div>
                </div>}
                {(data.batteryBrand === 'Q CELLS' || data.batteryBrand === 'select') && <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Battery Energy(kWh)</h6>
                        <p>{!errors?.batteryEnergy ? data?.batteryBrand === 'Q CELLS' ? `${data.batteryEnergy} kWh` : '' : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
                    </div>
                </div>}
                </>}
                <div className={classes.reviewSectionContent} style={{paddingLeft: 0}}><p style={{ color: '#4f4f4f'}}>PV Inverter</p></div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Brand</h6>
                        <p>{data.pvInverterBrand === 'select' ? '' : `${data.pvInverterBrand}  ${data.pvInverterBrand === 'Other' ? `-${data.namePvInverterBrandOther}` : ''}` }</p>
                    </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Model</h6>
                        <p>{data.pvInverterModel === 'select' ? '' : data.pvInverterModel}</p>
                    </div>
                </div>


                <div className={classes.reviewSectionContent} style={{paddingLeft: 0}}><p style={{ color: '#4f4f4f'}}>Racking</p></div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Brand</h6>
                        <p>{data.rackingBrand === 'select' ? '' : `${data.rackingBrand} ${data.rackingBrand === 'other' ? `-${data.nameRackingBrandOther}` : ''}`}</p>
                    </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Model</h6>
                        <p>{data.rackingModel === 'select' ? '' : data.rackingModel}</p>
                    </div>
                </div>
            </div>

            <div className={classes.reviewSectionsContainer}>
                <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
                    <h5>Purchasing Information</h5>
                    <Button className={classes.editBtn} onClick={() => goToStep(2)}>
                        <span>Edit</span>
                        <img src={IconEdit} title='Edit' />
                    </Button>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent} style={{ borderTop: '1px solid rgba(0, 0, 0, .1)' }}>
                        <h6>Purchasing Channel</h6>
                        <p>{!errors?.purchasingChannel ? renderPurchasingChannel(data.purchasingChannel) : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
                    </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Name of Purchasing Channel</h6>
                        <p>{(!errors?.nameOfPurchasingChannelOther && !errors?.nameOfPurchasingChannelDistributor && !errors?.nameOfPurchasingChannelSelect) ? renderNameOfPurchasingChannel() : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>

                    </div>
                </div>
            </div>

            <div className={classes.reviewSectionsContainer}>
                <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
                    <h5>Project Owner Information</h5>
                    <Button className={classes.editBtn} onClick={() => goToStep(3)}>
                        <span>Edit</span>
                        <img src={IconEdit} title='Edit' />
                    </Button>
                </div>
                <div className={classes.reviewSectionContainer} style={{ borderTop: '1px solid rgba(0, 0, 0, .1)' }}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Project Owner</h6>
                        <p>{(!errors?.firstName || !errors?.lastName) ? `${data.firstName} ${data.lastName}` : <span style={{color: '#EB5757'}}>Required field missing</span> }</p>
                    </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>E-mail</h6>
                        <p>{!errors?.email ? data.email : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
                    </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                        <h6>Contact Number</h6>
                        <p>{!errors?.phoneNumber ? data.phoneNumber : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
                    </div>
                </div>
            </div>
            <div className={classNames([classes.reviewSectionsContainer], [classes.reviewNoteSection])}>
                <span style={{fontWeight: '500'}}>Notes: </span> {data.note}
            </div>

            <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }}/>
            {!isEditCompleteProject ? (
                <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
                    <CustomButton color="secondary" className="saveDraftBtn" onClick={goBack}>Back</CustomButton>
                    <div>
                        {/*<CustomButton color="secondary" className="saveDraftBtn" onClick={handleSaveDraft} disabled={loadingSaveDraft}>*/}
                        {/*    { loadingSaveDraft ? <CustomLoading size={16} color="#009EE3"/> : 'Save Draft' }*/}
                        {/*</CustomButton>*/}
                        <CustomButton className={`${!isEmpty({...errors, ...errorDuplicate}) && 'saveDraftBtnReview'}`} onClick={handleSubmit} disabled={!isEmpty({...errors, ...errorDuplicate})}>
                            { loading ? <CustomLoading size={16} color="#fff"/> : 'Submit' }
                        </CustomButton>
                    </div>
                </Box>
            ) : (
                <Box display="flex" justifyContent="flex-start" className={classes.navigationBtnContainer}>
                    <CustomButton color="secondary" className="saveDraftBtn" onClick={goBackToMyProject}>Back</CustomButton>
                </Box>
            )}

            <ImageModal
                open={openModal}
                url={modalUrl}
                name={modalUrl ? modalUrl.substring(modalUrl.lastIndexOf('/') + 1) : ''}
                handleClose={() => {
                    setOpenModal(false);
                    setModalUrl('');
                }}
            />
        </div>
    )
};
