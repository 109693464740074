import React, { useState } from 'react';
import useStyles from './useStyles';
import PopoverIcon from '../../../config/images/notification.svg';
import CloseIcon from '../../../config/images/Close.svg';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

function Notification() {
  const classes = useStyles();
  const [isShow, setIsShow] = useState(true);

  return(
    <div className={classNames([classes.wrapNotification], {
      [classes.hiddenNoti]: isShow ? undefined : 'done'
    })}>
      <div className={classes.wrapLeft}>
        <IconButton
          size="small"
          aria-haspopup="true"
        >
          <img src={PopoverIcon} />
        </IconButton>
        <span className={classes.wrapTextNoti}>
          <span className={classes.Noti}>Register your projects now to gain exclusive benefits! </span> - <span style={{textTransform: 'uppercase', textDecoration: 'underline'}}><Link style={{color: '#fff'}} to="/projects/project-introduction">Learn more about benefits</Link></span>
        </span>
      </div>
      <div>
        <IconButton
          size="small"
          aria-haspopup="true"
          onClick={() => setIsShow(false)}
        >
          <img src={CloseIcon} />
        </IconButton>
      </div>
    </div>
  )
}

export default Notification;
