import React from 'react';
import BreadCrumb2 from './../../common/components/BreadCrumb2';
import Banner from '../../common/components/Banner';
import NonDisclosureAgreementBanner from '../../config/images/non-disclosure-agreement-banner.jpg';
import { makeStyles } from '@material-ui/core';
import Text from '../../common/components/text';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    pageContainer: {
        paddingTop: '30px',
        width: '65%',
        margin: '0 auto',
        transition: 'all 0.4s',
        marginBottom: '20px',
        [theme.breakpoints.down('md')]: {
            width: '90%'
        },
        paddingBottom: '40px'
    },
    pageTitle: {
        color: '#000',
        fontSize: '26px',
        fontWeight: 500,
        lineHeight: '26px',
        textTransform: 'uppercase',
        marginBottom: '40px',
        marginTop: '30px'
    },
    termContent: {
        marginBottom: '40px',
        fontSize: '16px',
        color: '#666',
        fontWeight: '400'
    },
    bannerTitle: {
        '&.title_animation': {
            [theme.breakpoints.down('xs')]: {
                fontSize: '20px !important'
            }
        }
    }
}));

export default function NonDisclosureAgreement() {
    const classes = useStyles();

    return (
        <>
            <Banner
                imageUrl={NonDisclosureAgreementBanner}
                className="OrderHistory_Banner banner100"
            >
                <h1 className={classNames("title_animation", "OrderHistory_Title", [classes.bannerTitle])}>NON-DISCLOSURE AGREEMENT</h1>
                <BreadCrumb2 items={['NON-DISCLOSURE AGREEMENT']}></BreadCrumb2>
            </Banner>

            <div className={classes.pageContainer}>
                <Text className={classes.pageTitle}>
                    NON-DISCLOSURE AGREEMENT
                </Text>

                <Text className={classes.termContent}>[AAA] (the “PARTNER”) and Hanwha Q CELLS America Inc. (“HQC”) have agreed to exchange confidential information in connection with [Q.PARTNERS Program] (the “Transaction”).  As a condition of each party furnishing such confidential information (“Discloser”) to the other party (“Recipient”), Discloser and Recipient hereby agree to the terms and conditions contained in this letter agreement (this “Agreement”).</Text>
                <Text className={classes.termContent}>Recipient shall treat Information (defined below) provided to it by the Discloser as confidential and shall use Information for the sole purpose of evaluating and negotiating the Transaction, all on the following terms and conditions.  “Information” refers to any and all financial, technical, commercial or other information concerning the business and affairs of the Discloser, its affiliates or third parties who deal with the Discloser or its affiliates, or concerning the Transaction (whether prepared by the Discloser, its advisers or otherwise) that is provided to Recipient by or on behalf of the Discloser and is marked as confidential or proprietary or reasonably should be considered as confidential or proprietary, but does not include (a) information which was lawfully in Recipient’s possession prior to the date hereof and obtained without violation of any obligation to the Discloser or its affiliates with respect to such Information; (b) information which is or hereafter becomes generally available to the public other than as a result of a disclosure by Recipient in violation of this Agreement; or (c) information obtained by Recipient from a third party  who is not under an obligation to maintain such information in confidence.  This Agreement applies to Information disclosed by one party to this Agreement to the other party in relation to the Transaction even if such disclosure occurred prior to the date of this Agreement. </Text>
                <Text className={classes.termContent}>Recipient shall disclose Information only to those of its or its affiliate’s directors, advisors, agents, auditors, attorneys, financiers, lenders, and employees (“Representatives”) who has a need to know the Information for purposes of evaluating and negotiating the Transaction and who are obligated to respect the confidentiality of the Information.  Recipient shall advise its Representatives to whom Information is disclosed of its confidential nature and of Recipient’s obligation to treat the Information as confidential in accordance with the terms and conditions set forth herein.</Text>
                <Text className={classes.termContent}>Notwithstanding anything to the contrary contained herein, Recipient shall not be deemed to have breached its obligations under this Agreement if it discloses Information (a) as required in order to comply with the pertinent laws, rules, or regulations, or valid judicial or government orders or (b) to Recipient’s auditors, external counsel or accountants.  Recipient agrees to notify Discloser in writing as early as possible of any such required disclosure described in the preceding subclause (a), and Recipient shall provide Discloser the opportunity to oppose such disclosure or obtain a protective order or similar relief.  Recipient shall cooperate reasonably with Discloser at Discloser’s expense to prevent or minimize such disclosure to the extent legally possible, but Recipient shall not be obligated to bring in its name any suit or seek any protective order.</Text>
                <Text className={classes.termContent}>The written Information, except for that portion of the Information that may be found in analyses, compilations, studies or other documents prepared by Recipient or their Representatives, shall be returned to the Discloser within thirty (30) calendar days upon request of Discloser; provided, however, that Recipient will be permitted to retain one copy of the Information as part of its legal archives.  That portion of the Information that may be found in analyses, compilations, studies, or other documents prepared by Recipient or its Representatives, oral Information and any written Information not so requested and returned shall be held by Recipient and its Representatives and kept subject to the terms and conditions of this Agreement or destroyed.</Text>
                <Text className={classes.termContent}>Either party may notify the other party in writing at any time that it wishes to terminate this Agreement to future disclosures, and such termination shall be effective on the fifteenth day after the giving of such notice.  Two (2) years after such termination, all remaining obligations of the parties under this Agreement shall terminate.</Text>
                <Text className={classes.termContent}>Neither party is obligated by this Agreement or by the exchange of Information to enter into the Transaction.  Neither party acquires by this Agreement or by any course of dealing between the parties any right, title or license to the other party’s Information or other intellectual property of any type (including without limitation patents, patent applications, copyrights, mask works, trademarks, service marks, inventions, discoveries, know-how, designs, software, processes, or trade secrets).</Text>
                <Text className={classes.termContent}>If Discloser identifies in writing to Recipient any special restrictions, such as export restrictions, applicable to particular items of Information disclosed by or on behalf of Discloser, Recipient shall comply with such identified restrictions.</Text>
                <Text className={classes.termContent}>Recipient shall notify Discloser promptly in writing if Recipient becomes aware of any improper disclosure by Recipient or one of its Representatives of Information disclosed by or on behalf of Discloser. </Text>
                <Text className={classes.termContent}>Recipient recognizes that Recipient (including its Representatives)’s violation of any provision of this Agreement will cause irreparable injury to Discloser.  Discloser, therefore shall have the right, in addition to any other remedies available at law, to obtain from a court of competent jurisdiction an injunction to prohibit Recipient from violating the terms of this Agreement, and to indemnify and hold Discloser harmless from any and all damages and costs resulting from any breach of this Agreement by Recipient, including but not limited to, all attorneys fees and court costs.</Text>
                <Text className={classes.termContent}>The terms of this Agreement and the rights and obligations hereunder may be amended or modified only by written agreement signed by both parties. This Agreement is binding upon and shall inure only to the benefit of the parties and their respective successors and permitted assigns.  Neither party may assign any of its rights or obligations under this Agreement without the prior written consent of the other party, except that either party may assign its rights under this Agreement to a person or entity which succeeds to the relevant portion of the assignor’s business.  Upon such assignment, the assignor shall remain responsible for the assignee’s compliance with this Agreement.</Text>
                <Text className={classes.termContent}>This Agreement is binding upon, and inures to the benefit of, the parties’ respective permitted successors and assigns.  All matters arising under or relating to this Agreement will be governed by the laws of the State of California, notwithstanding conflicts of law rules.  Each party consents to the exclusive jurisdiction of the federal or state courts in Orange County, California for any legal actions arising out of or relating to this Agreement. </Text>
            </div>
        </>
    )
}