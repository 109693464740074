import React, {useState, useEffect} from 'react';
import RewardsProgramIcon from '../../../icons/img_about_cont05.png';
import SalesToolsIcon from '../../../icons/img_about_cont08.png';
import AcademyIcon from '../../../icons/img_about_cont02.png';
import AdditionalBenifitsIcon from '../../../icons/img_about_cont06.png';
import ProjectManagementIcon from '../../../icons/iconProjectM.png';
import ClaimsLaborIcon from '../../../icons/iconClaimsLabor.png';
import Text from '../../../common/components/text';
import {Grid, makeStyles} from '@material-ui/core';
import styled from 'styled-components';

const MobileCardContainer = styled.ul `
  padding: 20px;
  position: relative;
  border: solid #eee;
  border-width: 0 1px 1px 1px;
`;

const StyledMobileList = styled.li `
  position: relative;
  width: 100%;
  padding: 15px 15px 40px 15px;
  background: #fff;
  /* border-top: 1px solid #eee; */
  box-sizing: border-box;
  text-align: center;
`

const StyledMobileP = styled.p  `
  margin-top: 22px;
  margin-bottom: 10px;
`;

const StyledCardTitle = styled.p `
  position: relative;
  padding-bottom: 30px;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;

  &:after {
    position: absolute;
    left: calc(50% - 11.5px);
    bottom: 15px;
    width: 23px;
    height: 2px;
    background: #019ee3;
    content: "";
  }

`;

const StyledTitleLine = styled.span `
  position: absolute;
  left: 47%;
  bottom: 130px;
  width: 23px;
  height: 2px;
  background: #019ee3;
  content: "";
`;

const StyledText = styled.p `
  color: #666;
  font-size: 14px;
  line-height: 20px;
`;

const useStyles = makeStyles(theme => ({
  container: {
    width: '1200px', 
    margin: 'auto', 
    marginTop: '40px', 
    marginBottom: '100px', 
    border: '1px solid #eee',
    [theme.breakpoints.down(1200)]: {
      width: '95%'
    }
  }
}));

export default function BenefitsCards() {
  const classes = useStyles();
  const [width, setWindowWidth] = useState(0);

  useEffect(() => { 
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => 
      window.removeEventListener('resize',updateDimensions);
   }, [])
   const updateDimensions = () => {
     const width = window.innerWidth
     setWindowWidth(width)
   }

  return (
    <>
      {width >= 960 ? 
      <Grid container className={classes.container}>
        {/*<Grid item xs={6} style={{height: '265px', border: '1px solid #BDBDBD', display: 'flex', alignItems: 'center'}}>  */}
        {/*  <Grid container style={{justifyContent: 'space-between', padding: '0 40px'}}>*/}
        {/*    <Grid item xs={4}>*/}
        {/*      <img src={RewardsProgramIcon} />*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={8}>*/}
        {/*      <Text style={{fontSize: '20px', fontWeight: '600'}}>REWARD PROGRAM</Text>*/}
        {/*      <p style={{width: '23px', height: '2px', backgroundColor: '#019ee3', marginTop: '15px', marginBottom: '15px'}}></p>*/}
        {/*      <Text style={{fontSize: '14px', fontWeight: '400', lineHeight: '22px', color: '#666666'}}>The reward program offers you financial rewards and promotional items which you can redeem in the Shop.</Text>*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        <Grid item xs={6} style={{height: '265px', display: 'flex', alignItems: 'center'}}>
          <Grid container style={{justifyContent: 'space-between', padding: '0 40px'}}>
            <Grid item xs={4}>
              <img src={SalesToolsIcon} />
            </Grid>
            <Grid item xs={8}>
              <Text style={{fontSize: '20px', fontWeight: '600'}}>RESOURCES</Text>
              <p style={{width: '23px', height: '2px', backgroundColor: '#019ee3', marginTop: '15px', marginBottom: '15px'}}></p>
              <Text style={{fontSize: '14px', fontWeight: '400', lineHeight: '22px', color: '#666666'}}>Access to marketing and technical materials to support your business.</Text>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{backgroundColor: '#E0E0E0', height: '265px', display: 'flex', alignItems: 'center'}}>
          <Grid container style={{justifyContent: 'space-between', padding: '0 40px'}}>
            <Grid item xs={4}>
              <img src={AcademyIcon} />
            </Grid>
            <Grid item xs={8}>
              <Text style={{fontSize: '20px', fontWeight: '600'}}>TRAINING</Text>
              <p style={{width: '23px', height: '2px', backgroundColor: '#019ee3', marginTop: '15px', marginBottom: '15px'}}></p>
              <Text style={{fontSize: '14px', fontWeight: '400', lineHeight: '22px', color: '#666666'}}>We provide online videos and NABCEP content, in addition to on-site training to empower your sales team.</Text>
            </Grid>
          </Grid>
        </Grid>
        {/*<Grid item xs={6} style={{height: '265px', border: '1px solid #BDBDBD', display: 'flex', alignItems: 'center'}}>  */}
        {/*  <Grid container style={{justifyContent: 'space-between', padding: '0 40px'}}>*/}
        {/*    <Grid item xs={4}>*/}
        {/*      <img src={AdditionalBenifitsIcon} />*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={8}>*/}
        {/*      <Text style={{fontSize: '20px', fontWeight: '600'}}>ADDITIONAL BENEFITS</Text>*/}
        {/*      <p style={{width: '23px', height: '2px', backgroundColor: '#019ee3', marginTop: '15px', marginBottom: '15px'}}></p>*/}
        {/*      <Text style={{fontSize: '14px', fontWeight: '400', lineHeight: '22px', color: '#666666'}}>Q.PARTNERs are eligible for additional benefits such as special events and promotions according to purchase volume.</Text>*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        <Grid item xs={6} style={{backgroundColor: '#E0E0E0', height: '265px', border: '1px solid #BDBDBD', display: 'flex', alignItems: 'center'}}>
          <Grid container style={{justifyContent: 'space-between', padding: '0 40px'}}>
            <Grid item xs={4}>
              <img src={ProjectManagementIcon} />
            </Grid>
            <Grid item xs={8}>
              <Text style={{fontSize: '20px', fontWeight: '600'}}>PROJECT MANAGEMENT</Text>
              <p style={{width: '23px', height: '2px', backgroundColor: '#019ee3', marginTop: '15px', marginBottom: '15px'}}></p>
              <Text style={{fontSize: '14px', fontWeight: '400', lineHeight: '22px', color: '#666666'}}>Register your projects to qualify for labor reimbursement and better manage your projects.</Text>
              {/*<Text style={{fontSize: '14px', fontWeight: '600', lineHeight: '22px', color: '#666666'}}>*For Contractor/EPC only</Text>*/}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{height: '265px', display: 'flex', alignItems: 'center'}}>
          <Grid container style={{justifyContent: 'space-between', padding: '0 40px'}}>
            <Grid item xs={4}>
              <img src={ClaimsLaborIcon} />
            </Grid>
            <Grid item xs={8}>
              <Text style={{fontSize: '20px', fontWeight: '600'}}>CLAIMS AND LABOR REIMBURSEMENT</Text>
              <p style={{width: '23px', height: '2px', backgroundColor: '#019ee3', marginTop: '15px', marginBottom: '15px'}}></p>
              <Text style={{fontSize: '14px', fontWeight: '400', lineHeight: '22px', color: '#666666'}}>Process your claims for modules and battery storage systems and qualify for reimbursement.</Text>
              {/*<Text style={{fontSize: '14px', fontWeight: '600', lineHeight: '22px', color: '#666666'}}> *Labor reimbursement for Contractor/EPC only</Text>*/}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
       :
      <div style={{margin: '20px'}}>
      <MobileCardContainer>
        {/*<StyledMobileList>*/}
        {/*  <StyledMobileP>*/}
        {/*    <img src={RewardsProgramIcon} style={{width: '70px', margin: 'auto'}} />*/}
        {/*    <StyledCardTitle>REWARD PROGRAM</StyledCardTitle>*/}
        {/*    <StyledText>The reward program offers you financial rewards and promotional items which you can redeem in the Marketing Shop.</StyledText>*/}
        {/*  </StyledMobileP>*/}
        {/*</StyledMobileList>*/}
      
        <StyledMobileList>
          <StyledMobileP>
            <img src={SalesToolsIcon} style={{width: '70px', margin: 'auto'}} />
            <StyledCardTitle>DATA HUB</StyledCardTitle>
            <StyledText>The Data Hub offers the most recent sales and technical support material to assist your sales efforts.</StyledText>
          </StyledMobileP>
        </StyledMobileList>

        <StyledMobileList style={{backgroundColor: '#E0E0E0'}}>
          <StyledMobileP>
            <img src={AcademyIcon} style={{width: '70px', margin: 'auto'}} />
            <StyledCardTitle>ACADEMY</StyledCardTitle>
            <StyledText>We provide online videos and NABCEP content, in addition to on-site training to empower your sales team.</StyledText>
          </StyledMobileP>
        </StyledMobileList>
      
        {/*<StyledMobileList style={{backgroundColor: '#E0E0E0'}}>*/}
        {/*  <StyledMobileP>*/}
        {/*    <img src={AdditionalBenifitsIcon} style={{width: '70px', margin: 'auto'}} />*/}
        {/*    <StyledCardTitle>ADDITIONAL BENEFITS</StyledCardTitle>*/}
        {/*    <StyledText>Q.PARTNERS are eligible for additional benefits such as special events and promotions according to purchase volume</StyledText>*/}
        {/*  </StyledMobileP>*/}
        {/*</StyledMobileList>*/}

        {/*<StyledMobileList>*/}
        {/*  <StyledMobileP>*/}
        {/*    <img src={RewardsProgramIcon} style={{width: '70px', margin: 'auto'}} />*/}
        {/*    <StyledCardTitle>REWARD PROGRAM</StyledCardTitle>*/}
        {/*    <StyledText>The reward program offers you financial rewards and promotional items which you can redeem in the Marketing Shop.</StyledText>*/}
        {/*  </StyledMobileP>*/}
        {/*</StyledMobileList>*/}
      
        {/*<StyledMobileList style={{backgroundColor: '#E0E0E0'}}>*/}
        {/*  <StyledMobileP>*/}
        {/*    <img src={SalesToolsIcon} style={{width: '70px', margin: 'auto'}} />*/}
        {/*    <StyledCardTitle>DATA HUB</StyledCardTitle>*/}
        {/*    <StyledText>The Data Hub offers the most recent sales and technical support material to assist your sales efforts.</StyledText>*/}
        {/*  </StyledMobileP>*/}
        {/*</StyledMobileList>*/}
      </MobileCardContainer>
      </div>
}
    </>
  )
}