import React from 'react';

function Recurring({ color }) {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 480 480" fill={color}>
      <path d="M394.4,16.9h-70.1c0.4-3.3,0.7-6.7,0.7-10.1c0-4.4-3.5-7.9-7.9-7.9H153c-4.4,0-7.9,3.5-7.9,7.9c0,3.5,0.2,6.9,0.7,10.3h-70
	c-11.6,0-21.1,9.4-21.1,21.1v422.3c0,11.6,9.4,21.1,21.1,21.1h318.6c11.6,0,21.1-9.4,21.1-21.1V38C415.5,26.3,406.1,16.9,394.4,16.9
	z M69.6,38.1c0-3.4,2.8-6.2,6.2-6.2h73.3c10.6,32.2,40.9,55.5,76.5,55.5h18.7c35.7,0,66-23.4,76.6-55.6h73.4c3.4,0,6.2,2.8,6.2,6.2
	v422.5c0,3.4-2.8,6.2-6.2,6.2H75.8c-3.4,0-6.2-2.8-6.2-6.2V38.1z M244.4,72.5h-18.7c-33.4,0-61.8-25.7-65.3-58.7h149.4
	C306.3,46.8,277.9,72.5,244.4,72.5z"/>
      <g>
        <polygon points="305.1,165.8 293.9,156.1 211.1,252 170.7,215.5 160.7,226.5 212.4,273.2 	"/>
        <polygon points="211.1,316 170.7,279.5 160.7,290.5 212.4,337.2 305.1,229.8 293.9,220.1 	"/>
      </g>
    </svg>
  );
}

export default Recurring;
