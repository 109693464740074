export const keyProject = [
  'projectAddress', 'projectAddress2', 'projectCity', 'projectState',
  'projectZipCode', 'installationDate', 'operateDate',
  'moduleBrand', 'moduleModel', 'numberOfModules',
  'moduleSerialNumber', 'productName', 'modulePowerClass',
  'numberOfModules', 'systemType', 'batteryBrand', 'nameOfChanelOtherDistributor', 'nameOfModuleBrandBatteryOther', 'nameOfModuleBrandPanelOther',
  'batteryInverterPower', 'batteryModel',
  'batteryEnergy', 'essSerialNumber', 'inverterSize',
  'purchasingChannel', 'nameOfPurchasingChannelDistributor','nameOfPurchasingChannelSelect',
  'nameOfPurchasingChannelOther', 'firstName', 'lastName', 'email', 'phoneNumber'
]
