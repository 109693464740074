import React, { useState, useEffect, useRef, useMemo } from 'react';
import { makeStyles, TextField, InputAdornment, IconButton, TableContainer, Grid, Table, TableCell, TableHead, TableRow, TableBody, Checkbox, Box, Modal, Paper, useMediaQuery, Drawer, Button, Backdrop } from '@material-ui/core';
import BreadCrumb3 from '../../common/components/BreadCrumb3';
import Banner from '../../common/components/Banner';
import RegistrationBanner from '../../config/images/registration-banner.png';
import RegistrationBanner2 from '../../config/images/registration-banner2.jpg';
import homeIcon from '../../config/images/ico_breadcrumb_home.png';
import arrowIcon from '../../config/images/ico_breadcrumb_bl.png';
import PanelIcon from '../../icons/panel-icon.png';
import { Link } from 'react-router-dom';
import LapTop1 from '../../config/images/laptop-1.png';
import OverlayBattery from '../../config/images/Overlay-Battery.png';
import Screen1 from '../../config/images/screen-1.png';
import Screen2 from '../../config/images/screen-2.jpg';
import OverlayInstallation from '../../config/images/Overlay-Installation Date.png';
import OverlaySystem from '../../config/images/Overlay-System Size.png';
import styled from 'styled-components';
import CustomButton from '../../common/components/button';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import Text from '../../common/components/text';
import TitleAnimation from '../../common/components/TitleAnimation';

const useStyles = makeStyles(theme => ({
    container: {
      width: '100%',
      margin: '0 auto',
      maxWidth: '985px',
      maxHeight: '596'
    },
    divRegister: {
        position: 'relative',
        zIndex: '2',
        width: '87%',
        left: '8%',
        bottom: "54px"
    },
    divScreen1: {
      position: 'absolute',
      zIndex: '2',
      width: '76.14%',
      height: '80.2%',
      left: '12%',
      top: '7%'
    },
    divScreen2: {
      position: 'absolute',
      zIndex: '2',
      width: '75.8%',
      height: '79.9%',
      left: '12.1%',
      top: '7.1%'
    },
    divOverlayBattery: {
      position: 'absolute',
      zIndex: '3',
      width: '20.6%',
      height: '24.8%',
      marginLeft: '81.5%',
      top: '62.5%'
    },
    divOverlayInstallation: {
      position: 'absolute',
      zIndex: '3',
      width: '36.5%',
      height: '26.3%',
      top: '72.5%',
      left: "-6.7%"
    },
    divOverlaySystem: {
      position: 'absolute',
      zIndex: '3',
      width: '20.6%',
      height: '26.2%',
      top: '20.8%',
      left: '-4.7%'
    },
    divLapTop1: {
      width: '100%',
      height: '100%'
    },
    divLapTop2: {
      width: '100%',
      height: '100%'
    },
    img: {
      width: '100%',
      height: '100%'
    },
    nav:{
        display: 'block',
        position: 'absolute',
        right: '110px',
        top: '56px',
        width: '100%',
        zIndex: '10'
    },
    div: {
        position: 'relative',
        margin: '0 auto'
    },
    ul: {
        float: 'right',
    },
    li: {
        position: 'relative',
        width: '14px',
        float: 'left',
        height: '12px',
        padding: '0',
        background: `url(${homeIcon}) no-repeat`,
        textIndent: '-9999px',
        overflow: 'hidden'
    },
    li2: {
        position: 'relative',
        float: 'left',
        paddingLeft: '25px',
        background: `url(${arrowIcon}) 12px center no-repeat`,
        color: '#ffffff',
        fontSize: '12px',
        lineHeight: '12px',
        textTransform: 'uppercase',
        listStyle: 'none'
    },
    h1: {
        fontSize: '54px',
        fontWeight: '500',
        fontFamily: 'Actual',
        fontStyle: 'normal',
        lineHeight: '67px'
    },
    h2: {
        fontFamily: 'DM Sans',
        fontWeight: 'normal',
        fontSize: '14px'
    },
    paper: {
        padding: theme.spacing(2),
        backgroundColor: '#ffffff'
      },
    grid1: {
      
    }
}));

const ImageStack = styled.div`
margin-left: 25px;
margin-right: 25px;
margin-top: 44px;
position: relative;
max-width: 985px;
max-height: 586px;
width: 100%;
::after {
  content: ' ';
  display: table;
  clear: both;
}
`;

const ImageStack2 = styled.div`
position: relative;
max-width: 471px;
max-height: 280px;
width: 100%;
::after {
  content: ' ';
  display: table;
  clear: both;
}
`;

export default function ProjectIntroduction() {
    const history = useHistory();
    const classes = useStyles();
    const [width, setWindowWidth] = useState(0);
    const [innerWidth, setInnerWidth] = useState(null);
    useEffect(() => {
      updateDimensions();
  
      window.addEventListener('resize', updateDimensions);
      return () => window.removeEventListener('resize', updateDimensions);
    }, []);
    const updateDimensions = () => {
      const width = window.innerWidth;
      setWindowWidth(width);
    };
  
    useEffect(() => {
      setInnerWidth(window.innerWidth);
    }, []);
    return (
        <>
            <div>
            <Banner
                imageUrl={RegistrationBanner2}
                className="OrderHistory_Banner banner100"
                scrimOpacity = {1}
            >
              <TitleAnimation>INTRODUCING QCELLS PROJECT MANAGEMENT TOOL</TitleAnimation>
              <BreadCrumb3 items={['Sales Tools', 'Project Management', 'Introduction']} classN = {classes}></BreadCrumb3>
            </Banner>
            <div className = {classes.divRegister}>
            <Paper elevation={0} variant="outlined" className={classes.paper}>
                <Grid container alignItems="center">
                  <Grid className="foo200" item>
                    <img src={PanelIcon} alt="Panel" width="86" height="74" />
                  </Grid>
                  <Grid className="foo201" container item xs={12} sm = {12} md={1300 < width ? 9 : 8} style={{marginLeft: '15px', textAlign: 'left'}}>
                    <Grid item xs={12}>
                      <Text
                        className="center_xs have_you"
                        color="dark"
                        weight="500"
                        style={{ fontSize: '20px' }}
                      >
                        Register your projects and secure labor cost reimbursement benefits
                         of up to $500 per project!*
                      </Text>
                      <Text
                        className="center_xs"
                        color="dark"
                        weight="300"
                        style={{ fontSize: '15px' }}
                      >
                         *Labor costs incurred on a valid Qcells product warranty claim
                      </Text>
                    </Grid>
                  </Grid>
                  <Grid item xs container justify = "flex-end">
                    <Grid item>
                    <CustomButton
                      color="secondary"
                      component={Link}
                      to="/projects/project-registration"
                      // style={{minWidth: 'fit-content', padding: '13px 32px'}}
                      style={{height: 45, padding: '0 31px', textAlign:'center'}}
                    >
                      Register a Project
                    </CustomButton>
                  </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </div>
            </div>

            <Grid container justify = "center" spacing = {2}>
              <Grid item xs = {12}>
                <Text
                    className="center_xs"
                    color="dark"
                    weight="500"
                    style={{ fontSize: '25px', textAlign: 'center', fontFamily: "Actual" }}
                    >
                        Project Management Tool
                </Text>
              </Grid>
              {/*<Grid item xs={12}>*/}
              {/*  <Text*/}
              {/*    className="center_xs"*/}
              {/*    color="dark"*/}
              {/*    weight="300"*/}
              {/*    style={{ fontSize: '15px', textAlign: 'center', fontFamily: "Actual" }}*/}
              {/*  >*/}
              {/*    *This feature is only available for Contractors/EPC.*/}
              {/*  </Text>*/}
              {/*</Grid>*/}
              <Grid item xs = {12}>
                <Text
                  className="center_xs"
                  color="dark"
                  weight="300"
                  style={{ fontSize: '15px', textAlign: 'center', fontFamily: "Actual"}}
                >
                  Qcells offers all of its Q.PARTNERs a project management tool to help track and register their projects.<br/>
                  Once completed, Qcells will issue a warranty certificate to the project owner with key details on their system and warranty information.
                </Text>
              </Grid>

            <ImageStack>
              <div className = {classes.divLapTop1}>
              <img src={LapTop1} className = {classes.img}/>
              </div>
              <div className = {classes.divScreen1}>
              <img src={Screen1} className = {classes.img}/>
              </div>
              <div className = {classes.divOverlayBattery}>
              <img src={OverlayBattery} className = {classes.img}/>
              </div>
              <div className = {classes.divOverlayInstallation}>
              <img src={OverlayInstallation} className = {classes.img}/>
              </div>
              <div className = {classes.divOverlaySystem}>
              <img src={OverlaySystem} className = {classes.img}/>
              </div>
            </ImageStack>
          </Grid>

            <Grid container style = {{background: "#E9F8FF", marginTop: "67px"}}>
              <Grid container xs = {12} style={{ marginLeft: '80px', marginTop: '48px'}}>
                <Grid item xs = {12}>
                <Text
                  color="dark"
                  weight="500"
                  style={{fontSize: '25px' }}
                >
                  Benefits
                </Text>
                </Grid>
                <Grid item container xs = {12} spacing = {4} style = {{marginTop: "18px", marginBottom: "60px"}}>
                  <Grid item container xs = {12} sm = {12} md={760 < width && width < 1200 ? 6 : 3} lg = {3} direction="column" spacing={2}>
                  <Grid item>
                  <Text
                    color="dark"
                    weight="500"
                    style={{fontSize: '18px' }}
                  >
                    Labor Reimbursement
                  </Text>
                  </Grid>
                  <Grid item>
                  <Text
                    color="dark"
                    weight="300"
                    style={{fontSize: '16px' }}
                  >
                    Gain access to exclusive Qcells labor 
                    reimbursement (up to $500 per project) 
                    through registering your residential projects and
                    submitting qualifying warranty claims
                  </Text>
                  </Grid>
                  <Grid item xs container direction = "column-reverse">
                    <Grid item>
                      <em
                        onClick={() => history.push({
                          pathname: '/claim/how-it-works',
                          state: {
                            isScrollToLaborReimbursement: true
                          }
                        })}
                        weight="300"
                        style={{cursor: 'pointer', textDecoration: "underline 1px", textUnderlineOffset: "2px", color: '#009EE3',fontSize: '15px' }}
                      >
                        LEARN MORE
                      </em>
                    </Grid>
                  </Grid>
                  </Grid>

                  <Grid item container xs = {12} sm = {12} md={760 < width && width < 1200 ? 6 : 3} lg = {3} direction="column" spacing={2}>
                  <Grid item>
                  <Text
                    color="dark"
                    weight="500"
                    style={{fontSize: '18px' }}
                  >
                    Homeowner Warranty Certificates
                  </Text>
                  </Grid>
                  <Grid item>
                  <Text
                    color="dark"
                    weight="300"
                    style={{fontSize: '16px' }}
                  >
                    Once you register your project, the
                    homeowner will receive a warranty
                    certificate via email with your company
                    name, product details and warranty
                    information. Certificates are also
                    downloadable in the “my projects”
                    page.
                  </Text>
                  </Grid>
                  </Grid>

                  <Grid item container xs = {12} sm = {12} md={760 < width && width < 1200 ? 6 : 3} lg = {3} direction="column" spacing={2}>
                    <Grid item>
                      <Text
                        color="dark"
                        weight="500"
                        style={{fontSize: '18px' }}
                      >
                        Easy Claims Process
                      </Text>
                    </Grid>
                    <Grid item>
                      <Text
                        color="dark"
                        weight="300"
                        style={{fontSize: '16px' }}
                      >
                        Submit a claim directly via
                        our easy to use claim template,
                        eliminating time-consuming data
                        entry
                      </Text>
                    </Grid>
                  </Grid>

                  <Grid item container xs = {12} sm = {12} md={760 < width && width < 1200 ? 6 : 3} lg = {3} direction="column" spacing={2}>
                  <Grid item>
                  <Text
                    color="dark"
                    weight="500"
                    style={{fontSize: '18px' }}
                  >
                    Record Keeping
                  </Text>
                  </Grid>
                  <Grid item>
                  <Text
                    color="dark"
                    weight="300"
                    style={{fontSize: '16px' }}
                  >
                    Maintain detailed records of all your installed
                    projects and review at anytime through the “my
                    projects” page.
                  </Text>
                  </Grid>
                  <Grid item xs container direction = "column-reverse">
                  <Grid item>
                  <Link to = "/projects/myprojects"  rel="noopener noreferrer">
                    <Text
                      weight="300"
                      style={{textDecoration: "underline 1px", textUnderlineOffset: "2px", color: '#009EE3',fontSize: '15px' }}
                    >
                      MY PROJECTS
                    </Text>
                  </Link>
                  </Grid>
                  </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>

            <Grid container xs = {12} style={{marginBottom: 50}}>
              <Grid item style = {{marginLeft: "80px", marginTop: '36px'}}>
                <ImageStack2>
                  <div className = {classes.divLapTop2}>
                  <img src={LapTop1} className = {classes.img}/>
                  </div>
                  <div className = {classes.divScreen2}>
                  <img src={Screen2} className = {classes.img}/>
                  </div>
                </ImageStack2>
              </Grid>
              <Grid item container xs = {12}  lg direction="column" style = {{marginLeft: "72px", marginTop: '36px'}} spacing = {2}> 
                <Grid item>
                <Text
                  weight="400"
                  style={{fontSize: '25px' }}
                >
                  How does it work?
                </Text>
                </Grid>

                <Grid item container xs = {12} spacing = {10}>            
                  <Grid item container xs = {6} direction = "column" spacing = {2}>
                    <Grid item xs container direction = "column" spacing = {1}>
                      <Grid item>
                      <div>
                        <Text
                          weight="500"
                          style={{ display: 'inline', fontSize: '18px', color: '#009EE3' }}
                        >
                          1.
                        </Text>
                        {' '}
                        <Text
                          color="dark"
                          weight="500"
                          style={{ display: 'inline', fontSize: '18px' }}
                        >
                           Register your project
                        </Text>
                      </div>
                      </Grid>
                      <Grid item>
                        <Text
                          color="dark"
                          weight="300"
                          style={{fontSize: '16px' }}
                        >
                           After installing a system, register the project using 
                           Q.PARTNER Project Management Tool on desktop:
                        </Text>
                      </Grid>
                      <Grid item>
                        <Link to = "/projects/project-registration"  rel="noopener noreferrer">
                          <Text
                            weight="300"
                            style={{textDecoration: "underline 1px", textUnderlineOffset: "2px", color: '#009EE3',fontSize: '16px' }}
                          >
                            • Desktop Registration
                          </Text>
                        </Link>
                      </Grid>
                    </Grid>

                    <Grid item container direction = "column" spacing = {1}>
                      <Grid item>
                      <div>
                        <Text
                          weight="500"
                          style={{ display: 'inline', fontSize: '18px', color: '#009EE3' }}
                        >
                          2.
                        </Text>
                        {' '}
                        <Text
                          color="dark"
                          weight="500"
                          style={{ display: 'inline', fontSize: '18px' }}
                        >
                           Save your progress
                        </Text>
                      </div>
                      </Grid>
                      <Grid item >
                        <Text
                          color="dark"
                          weight="300"
                          style={{fontSize: '16px' }}
                        >
                           When inputting project details, you can always
                           save and return to the registration form in the 
                           future if key project details are pending.
                        </Text>
                      </Grid>
                    </Grid>
                    <Grid item style = {{marginTop: "23px"}}>
                      <CustomButton
                        color="secondary"
                        component={Link}
                        to="/projects/project-registration"
                        style = {{fontSize: "15px", padding: "13px 32px", weight: "400"}}
                         rel="noopener noreferrer"
                      >
                        Register a Project
                      </CustomButton>
                    </Grid>
                  </Grid>

                  <Grid item container xs = {6} direction = "column" justify = "flex-start" spacing = {2}>
                    <Grid item container direction = "column" spacing = {1}>
                      <Grid item>
                      <div>
                        <Text
                          weight="500"
                          style={{ display: 'inline', fontSize: '18px', color: '#009EE3' }}
                        >
                          3.
                        </Text>
                        {' '}
                        <Text
                          color="dark"
                          weight="500"
                          style={{ display: 'inline', fontSize: '18px' }}
                        >
                           Manage your projects
                        </Text>
                      </div>
                      </Grid>
                      <Grid item >
                        <div>
                        <Text
                          color="dark"
                          weight="300"
                          style={{display: 'inline', fontSize: '16px' }}
                        >
                           Manage your projects on the 
                        </Text>
                        {' '}
                        <Link to = "/projects/myprojects"  rel="noopener noreferrer">
                        <Text
                          color="dark"
                          weight="300"
                          style={{textDecoration: "underline 1px", textUnderlineOffset: "2px", display: 'inline', fontSize: '16px', color: '#009EE3' }}
                        >
                           MY PROJECTS
                        </Text>
                        </Link>
                        {' '}
                        <Text
                          color="dark"
                          weight="300"
                          style={{display: 'inline', fontSize: '16px' }}
                        >
                          page and view the homeowner certificates.
                        </Text>
                        </div>
                      </Grid>
                    </Grid>

                    {/*<Grid item container direction = "column" spacing = {1}>*/}
                    {/*  <Grid item>*/}
                    {/*  <div>*/}
                    {/*    <Text*/}
                    {/*      weight="500"*/}
                    {/*      style={{ display: 'inline', fontSize: '18px', color: '#009EE3' }}*/}
                    {/*    >*/}
                    {/*      5.*/}
                    {/*    </Text>*/}
                    {/*    {' '}*/}
                    {/*    <Text*/}
                    {/*      color="dark"*/}
                    {/*      weight="500"*/}
                    {/*      style={{ display: 'inline', fontSize: '18px' }}*/}
                    {/*    >*/}
                    {/*       Receive benefits*/}
                    {/*    </Text>*/}
                    {/*  </div>*/}
                    {/*  </Grid>*/}
                    {/*  <Grid item >*/}
                    {/*    <Text*/}
                    {/*      color="dark"*/}
                    {/*      weight="300"*/}
                    {/*      style={{display: 'inline', fontSize: '16px' }}*/}
                    {/*    >*/}
                    {/*       Qualify for all the added Q.PARTNER-exclusive benefits!*/}
                    {/*    </Text>*/}
                    {/*  </Grid>*/}
                    {/*</Grid>*/}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid container style = {{background: "#222327", marginTop: "37px"}} spacing = {10}>
              <Grid item sm = {5} container direction = "column" spacing = {5} style = {{marginTop: "20px", marginLeft: "128px"}}>
                <Grid item>
                <Text
                  weight="500"
                  style={{fontSize: '25px', color: "#FFFFFF" }}
                >
                    Project Management Tool: Tutorial Video
                </Text>
                </Grid>
                <Grid item style = {{marginBottom: "80px"}}>
                <Text
                  weight="300"
                  style={{fontSize: '15px', color: "#BDBDBD" }}
                >
                    Check out the tutorial video for a walk-through of the Project Management Tool feature.
                </Text>
                </Grid>
              </Grid>
            </Grid> */}
        </> 
    )
}

