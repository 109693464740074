import React from 'react';
import Banner from '../../../common/components/Banner';
import useStyles from './useStyles';
import IconRequiredFields from '../../../icons/requiredFields.svg';
import BreadCrumbClaim from '../../../common/components/BreadCrumbClaim';

function BannerProjectRes({title, step, sub_title}) {
  const classes = useStyles();
  function renderStatus() {
    switch (step) {
      case 4:
        return 'finish'
    }
  }
  
  return(
    <Banner
      className="OrderHistory_Banner banner100"
      height={'150'}
      type={'form'}
      status={renderStatus()}
    >
      {/* <BreadCrumb2 type={'form'} items={[`${user.firstName} ${user.lastName}`, 'Project Registration']}/> */}
      <div className={classes.wrapContent}>
        {renderStatus() !== 'finish' && (
          <>
            <h1 style={{fontSize: 36, color: '#000', fontWeight: 400}}>{title}</h1>
            <p style={{fontSize: 14, color: '#000'}}>Please fill all <img style={{margin: '0 10px', display : 'initial', verticalAlign : 'middle'}} src={IconRequiredFields} /> All other fields are optional.</p>
          </>
        )}
      </div>
      {renderStatus() === 'finish' && 
      <>
        <h1 style={{color : '#009EE3', margin : '0 auto'}} className="OrderHistory_Title">Almost there!</h1>
        <h3 className={classes.subClaim}>Please review the information below to register your project.</h3>
      </>}
      <BreadCrumbClaim type={'form'} items={sub_title}/>
    </Banner>
  )
}

export default BannerProjectRes;
