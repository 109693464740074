import React from 'react';
import { Backdrop, CssBaseline, Fade, Modal, makeStyles } from '@material-ui/core';
import Button from './button';

const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
      width: 400,
      textAlign: 'center',
      [theme.breakpoints.down(400)]: {
        width: '90%'
      }
    }
}));

export default function AccessSubIdModal({ open, handleClose }) {
    const classes = useStyles();

    return (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          closeAfterTransition
          onClose={handleClose}
          BackdropComponent={Backdrop}
          className={classes.modal}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
                <CssBaseline />
                You don't have access right !
                <Button onClick={handleClose} style={{height: 30, width : 50, marginTop: 10, padding: 0}}>Ok</Button>
            </div>
          </Fade>
        </Modal>
    )
};