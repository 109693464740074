import React, { useCallback, useEffect, useState } from 'react';
import {
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  TableContainer,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
  Box,
  MenuItem, useMediaQuery, Drawer, Button
} from '@material-ui/core';
import BreadCrumb2 from '../../common/components/BreadCrumb2';
import Banner from '../../common/components/Banner';
import RegistrationBanner from '../../config/images/registration-banner.jpg';
import { useAuth } from '../../contexts/auth';
import SearchIcon from '../../config/images/search_icon.svg';
import DatePickerCustom from './../../common/components/DatePicker';
import ClearIcon from '@material-ui/icons/Clear';
import CustomButton from '../../common/components/button';
import CheckBox from '../../config/images/bg_checkbox.svg';
import CheckBoxOn from '../../config/images/bg_checkbox_on.svg';
import { claimService, dashboardService } from '../../services';
import CloseDrawerIcon from '../../config/images/close_drawer_icon.svg';
import FilterIcon from '../../config/images/filter_icon.svg';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import CustomLoading from '../../common/components/CustomLoading';
import _ from 'lodash';
import Moment from 'react-moment';
import {useHistory} from 'react-router-dom';
import PopOver from '../../config/images/popover_icon.svg';

const useStyles = makeStyles(theme => ({
  container: {
    width: '90%',
    margin: '0 auto 70px',
    [theme.breakpoints.down('md')]: {
      width: '90%'
    }
  },
  title: {
    fontFamily: 'Actual',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '36px',
    lineHeight: '42px',
    color: '#000000'
  },
  actionContainer: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  inputField: {
    '& .MuiOutlinedInput-notchedOutline': {
      transition: 'background 0.3s, border 0.3s',
      borderColor: '#C4C4C4'
    },
    '& .MuiInputBase-input': {
      color: '#333333',
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#BDBDBD',
      opacity: 1
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '43px',
      fontSize: '14px',
      color: '#333333',
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '17.5px'
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 15px'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: '#C4C4C4 !important'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000 !important',
      borderWidth: '1px'
    }
  },
  datePicker: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '43px'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: '#C4C4C4 !important'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000 !important',
      borderWidth: '1px'
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 15px',
      fontSize: '14px'
    }
  },
  downloadButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  downloadButton: {
    border: '1px solid #8C8C8C',
    boxSizing: 'border-box',
    borderRadius: '100px',
    height: '100%',
    fontFamily: 'Actual',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '19px',
    textAlign: 'center',
    color: '#8C8C8C',
    textTransform: 'capitalize',
    width: '100%',
    maxWidth: '150px',
    padding: '0 !important',
    [theme.breakpoints.down('md')]: {
      maxWidth: '140px',
      minWidth: '120px !important'
    },
    [theme.breakpoints.down(650)]: {
      height: '45px'
    },
    [theme.breakpoints.down(340)]: {
      maxWidth: '130px',
      minWidth: '120px !important'
    }
  },
  viewMoreButton: {
    border: '1px solid #8C8C8C',
    boxSizing: 'border-box',
    borderRadius: '100px',
    fontFamily: 'Actual',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '19px',
    textAlign: 'center',
    color: '#8C8C8C',
    textTransform: 'capitalize',
    width: '100%',
    maxWidth: '185px',
    height: '45px',
    padding: '0 !important',
    [theme.breakpoints.down(650)]: {
      maxWidth: '100px',
      minWidth: '100px !important'
    }
  },
  deleteButton: {
    border: '1px solid #8C8C8C',
    boxSizing: 'border-box',
    borderRadius: '100px',
    fontFamily: 'Actual',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '19px',
    textAlign: 'center',
    color: '#8C8C8C',
    textTransform: 'capitalize',
    width: '100%',
    maxWidth: '120px',
    minWidth: '100px !important',
    height: '45px',
    padding: '0 !important',
    [theme.breakpoints.down(650)]: {
      maxWidth: '100px',
      minWidth: '100px !important'
    }
  },
  projectsTable: {
    '& .MuiTableRow-head': {
      backgroundColor: '#0F2848',
      border: '1px solid #0F2848',
    },
    '& .MuiTableCell-head': {
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '15px',
      lineHeight: '28px',
      color: '#FFFFFF'
    },
    '& .MuiTableBody-root .MuiTableRow-root:nth-child(2n)': {
      backgroundColor: '#F6F6F6'
    },
    '& .MuiTableCell-body': {
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '15px',
      lineHeight: '28px',
      color: '#000000',
      paddingTop: 0,
      paddingBottom: 0
    },
    '& .MuiTableFooter-root': {
      borderLeft: '1px solid #C4C4C4',
      borderRight: '1px solid #C4C4C4'
    },
    '& .MuiTableCell-root': {
      borderBottomColor: '#C4C4C4',
      whiteSpace: 'nowrap',
      padding: '8px 0',
      paddingLeft: 20
    },
    '& .MuiTableCell-root:last-child': {
      paddingRight: 20
    },
    '& .MuiTableRow-hover': {
      cursor: 'pointer'
    }
  },
  checkBox: {
    background: `url(${CheckBox}) center /100% 100% no-repeat`,
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    transition: 'background 0.4s'
  },
  checkBoxOn: {
    background: `url(${CheckBoxOn}) center /100% 100% no-repeat`,
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    transition: 'background 0.4s'
  },
  actionBottomContainer: {
    marginTop: '45px'
  },
  deleteButtonText: {
    fontFamily: 'Actual',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '15px !important',
    lineHeight: '45px !important',
    textAlign: 'center',
    color: '#000000',
    opacity: '0.7',
    marginRight: '10px',
    [theme.breakpoints.down(340)]: {
      fontSize: '13px !important',
    }
  },
  loadingEmptyRow: {
    height: '380px',
    border: '1px solid #C4C4C4',
    '& .MuiBox-root': {
      height: '320px',
      backgroundColor: 'rgb(247, 247, 247)',
      // marginRight: '6px',
      // marginLeft: '6px'
    }
  },
  modal: {
    width: '40rem',
    height: '50%',
    top: '30px',
    margin: 'auto',
    "& .MuiPaper-root": {
      overflowX: 'hidden'
    },
    [theme.breakpoints.down('md')]: {
      width: '98%',
      overflowY: 'auto'
    }
  },
  header: {
    [theme.breakpoints.down('md')]: {
      fontSize: '20px !important'
    }
  },
  paper: {
    textAlign: 'center',
    backgroundColor: '#ffffff',
    color: '#8C8C8C'
  },
  clearIconBtn: {
    padding: 0,
    position: 'absolute',
    top: '22px',
    right: '22px',
    [theme.breakpoints.down('md')]: {
      top: '15px',
      right: '15px',
    }
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: 'calc(100% - 60px)',
      position: 'unset',
      boxShadow: '-1px 3px 15px rgba(0, 0, 0, 0.2)'
    },
    '& .closeBtnContainer': {
      position: 'relative'
    },
    '& .closeBtn': {
      position: 'absolute',
      right: 0,
      width: '60px',
      height: '60px',
      minWidth: '60px',
      backgroundColor: '#fff',
      borderRadius: 0
    },
    '& .filterFields': {
      margin: '40px 20px'
    },
    '& .filterFields h5': {
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '15px',
      textTransform: 'uppercase',
      color: '#009EE3',
      marginBottom: '20px'
    },
    '& .filterField': {
      marginBottom: '22px'
    }
  },
  banner: {
    width: '90%',
    margin: '30px auto 0',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '90%'
    },
    '& .breadcrumb2.form': {
      right: 0,
    },
  },
  dashboard: {
    textDecoration: 'underline',
    color: '#000',
    '& > span': {
      marginRight: '25px',
      cursor : 'pointer'
    }
  },
  selectAll : {
    marginLeft : 20,
    display : 'flex',
    alignItems : 'center',
    height: '100%'
  },
  draftInfo : {
    height : 55,
    background : '#F6FAFD',
    width : '100%',
    display : 'flex',
    justifyContent : 'space-between',
    alignItems : 'center',
    padding : 10,
    marginBottom : 20,
    border: '1px solid #E0E0E0'
  },

  leftDraft : {
    display : 'flex'
  },
}));

const listStatus2 = [
  { name: 'Draft', value: 4 },
  { name: 'Submitted', value: 6 },
  { name: 'In Review', value: 7 },
  { name: 'Approved', value: 2 },
  { name: 'Processing', value: 8 },
  { name: 'Closed/Approved', value: 9 },
  { name: 'Closed/Rejected', value: 3 },
  { name: 'Requires more info', value: 5 },
  { name: 'Remote Support in Progress', value: 10 },
  { name: 'Closed/Resolved', value: 11 }
]

export default function ClaimHistory() {
  const user = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [submittedDate, setSubmittedDate] = useState(null);
  const [textSearch, setTextSearch] = useState('');
  const [claimStatus, setClaimStatus] = useState(null);
  const [claimType, setClaimType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [listClaim, setListClaim] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const isLowerMediumSize = useMediaQuery(theme.breakpoints.down('md'));
  const isLowerSmallSize = useMediaQuery('(max-width: 650px)');
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [listStatus, setListStatus] = useState([]);
  const [dashboardClaims, setDashboardClaims] = useState([]);
  const [idActive, setIdActive]  = useState(1);
  console.log(idActive);

  useEffect(() => {
    getListStatus();
    getDashboardClaims();
  }, [])

  useEffect(() => {

    const makePayload = {
      keyword : textSearch,
      createdAt : submittedDate,
      statusId : claimStatus,
      claimType,
      page,
      size : 25
    };


    if(textSearch === '') {
      delete makePayload.keyword
    }

    if(submittedDate === null) {
      delete makePayload.submittedDate
    }

    if(claimStatus === null) {
      delete makePayload.status
    }

    if(claimType === null) {
      delete makePayload.type
    }

    getListClaimHistory(makePayload);
  }, [submittedDate, textSearch, claimStatus, claimType]);

  async function getListClaimHistory(payload) {
    try {
      setLoading(true);
      const infoClaim = await claimService.getListClaimHistory(payload);
      setListClaim(infoClaim.data.data);
      setTotal(infoClaim?.data.total);
    }catch (e) {
      console.log(e);
    }finally {
      setLoading(false);
      setLoadingMore(false);
    }
  }

  async function getListStatus() {
    const info = await claimService.getListStatusClaim();
    setListStatus(info.data);
  }

  async function getDashboardClaims() {
    try {
      const { data } = await dashboardService.getDashboardClaims();
      setDashboardClaims(data);
    } catch (error) {
      console.log(error);
    }
  }

  function handleChangeDateSubmitted(date) {
    setSubmittedDate(date);
    setPage(1);
  }

  function handleChangeStatus(event) {
    setClaimStatus(event.target.value);
    setPage(1);
  }

  function handleChangeType(event) {
    setClaimType(event.target.value);
    setPage(1);
  }

  async function viewMore() {
    setLoadingMore(true);
    setPage(page + 1);

    const makePayload = {
      keyword : textSearch,
      createdAt : submittedDate,
      statusId : claimStatus,
      claimType,
      page: page + 1,
      size : 25
    };


    if(textSearch === '') {
      delete makePayload.keyword
    }

    if(submittedDate === null) {
      delete makePayload.submittedDate
    }

    if(claimStatus === null) {
      delete makePayload.status
    }

    if(claimType === null) {
      delete makePayload.type
    }

    const info = await claimService.getListClaimHistory(makePayload);
    setListClaim([...listClaim, ...info.data.data]);
    setTotal(info.data.total);
    setLoadingMore(false);
  }

  const debounce = useCallback(
    _.debounce((value) => {
      setTextSearch(value);
    }, 300),
    []
  );

  function handleChangeKeyword(event) {
    debounce(event.target.value);
  }

  function handleCloseDate() {
    setSubmittedDate(null);
    setPage(1);
  }

  function renderStatusName(statusId) {
    const action = {
      1 : 'Submitted',
      2 : 'Requires more info',
      3 : 'In review',
      4 : 'In review',
      8 : 'In review',
      5 : 'Processing',
      11 : 'Approved',
      12 : 'In review',
      6 : 'Closed/Approved',
      7 : 'Closed/Approved',
      9 : 'Closed/Approved',
      10 : 'Closed/Rejected',
      'default' : ''
    }

    return action[statusId] || action['default']
  }

  function renderStatusNameEss(statusId) {
    const action = {
      1 : 'Submitted',
      2 : 'Requires more info',
      3 : 'In review',
      4 : 'In review',
      8 : 'In review',
      5 : 'Processing',
      11 : 'Approved',
      12 : 'In review',
      6 : 'Closed/Approved',
      7 : 'Closed/Approved',
      9 : 'Closed/Approved',
      10 : 'Closed/Rejected',
      14 : 'Remote Support in Progress',
      15 : 'Closed/Resolved',
      'default' : ''
    }

    return action[statusId] || action['default']
  }

  function handleReset() {
    setClaimStatus(null)
    setClaimType(null)
    setSubmittedDate(null)
    try {
      getListClaimHistory({});
    } catch (err) {}
  }

  function handleActive(id) {
    setIdActive(id)
    let payload = {};
    //id === 1 : All
    //id === 2 : Approved
    if(id === 2) {
      payload = {
        statusId: 11
      }
    }

    //id === 3: closed/rejected
    if(id === 3) {
      payload = {
        statusId: 10
      }
    }

    //id ===4 : draft
    if(id === 4) {
      payload = {
        isDraft : true
      }
    }

    //id === 5: pending == Required more info
    if(id === 5) {
      payload = {
        statusId: 2
      }
    }

    //id === 6: submitted
    if(id === 6) {
      payload = {
        statusId: 1
      }
    }

    //id === 7: inreview 
    if(id === 7) {
      payload = {
        statusId: 3,
      }
    }

    //id === 8: Processing
    if(id === 8) {
      payload = {
        statusId: 5
      }
    }

    //id === 9: Closed/Approved
    if(id === 9) {
      payload = {
        statusId: 9,
      }
    }

    if(id === 10) {
      payload = {
        statusId: 14,
      }
    }

    if(id === 11) {
      payload = {
        statusId: 15,
      }
    }

    try {
      getListClaimHistory(payload);
    } catch (err) {

    }
  }


  function renderLaborStatus(laborReimbursement, laborWarranty, choseTypeProject, chooseRefund, type, panelQualified, batteryQualified) {


      if(choseTypeProject === '2') {
        return 'No'
      }

      if(type === 1 && !panelQualified) {
        return 'No'
      }

      if(type === 2 && !batteryQualified) {
        return 'No'
      }

      if(laborReimbursement && laborWarranty && chooseRefund === '1') {
        return 'No'
      }

      if((!laborReimbursement && laborWarranty) || !laborReimbursement) {
        return 'No'
      }

      return 'Yes'
  }

  return (
    <>
      <div className={classes.banner}>
        <BreadCrumb2 type={'form'} items={[`${user.firstName} ${user.lastName}`, 'CLAIM HISTORY']}/>
        <h1 style={{ fontSize: 36, color: '#000', fontWeight: 400 }}>Claim History</h1>
        <p className={classes.dashboard}>
          <span onClick={() => handleActive(1)} className={idActive === 1 && 'active-draft'}>({parseInt(dashboardClaims.isDraft) + parseInt(dashboardClaims.isSubmitted)}){''} All</span>
          <span onClick={() => handleActive(2)} className={idActive === 2 && 'active-draft'}>({parseInt(dashboardClaims["Ready For EO"])}){''} Approved</span>
          <span onClick={() => handleActive(3)} className={idActive === 3 && 'active-draft'}>({parseInt(dashboardClaims["Closed Rejection"])}){''} Rejected</span>
          <span onClick={() => handleActive(4)} className={idActive === 4 && 'active-draft'}>({dashboardClaims.isDraft}){''} Draft</span>
          <span onClick={() => handleActive(5)} className={idActive === 5 && 'active-draft'}>({dashboardClaims.Evidence}){''} Pending</span>
        </p>
        
        {/* {dashboardClaims.Evidence > 0 && 
          <div className={classes.draftInfo} style={{margin:'18px 0'}}>
            <div className={classes.leftDraft}>
              <img style={{marginRight: 8, height : 20}} src={PopOver}/> <em>We require more infomation to process the {dashboardClaims.Evidence} of your claims.</em>
            </div>
            <div className={classes.rightDraft}>
              <CustomButton style={{height : 35}}>
                View pending claims
              </CustomButton>
            </div>
          </div>
        } */}
      </div>
      <div className={classes.container}>
        {!isLowerSmallSize ? (
          <Grid container spacing={3} className={classes.actionContainer}>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search"
                className={classes.inputField}
                onChange={handleChangeKeyword}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <img src={SearchIcon} style={{opacity: 0.5, height: 24, width: 24}} />
                    </InputAdornment>
                }}
              />
            </Grid>
            <Grid item md={2} xs={12} style={{ position: 'relative' }}>
              <DatePickerCustom
                fullWidth
                variant="inline"
                format="MM/dd/yyyy"
                inputVariant="outlined"
                placeholder="Submitted Date"
                maxDate={new Date()}
                views={['year', 'month', 'date']}
                value={submittedDate}
                className={classes.datePicker}
                onChange={handleChangeDateSubmitted}
                // autoOk={true}
              />
              <IconButton
                style={{
                  padding: 0,
                  position: 'absolute',
                  top: '22px',
                  right: '22px'
                }}
                edge="end"
                size="small"
                disabled={!submittedDate}
                onClick={handleCloseDate}
              >
                <ClearIcon/>
              </IconButton>
            </Grid>
            <Grid item md={2} xs={12} style={{ position: 'relative' }}>
              <TextField
                select
                fullWidth
                id="status"
                label="Claim status"
                variant="outlined"
                size="small"
                className={classes.inputField}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }
                }}
                // onChange={handleChangeStatus}
              >
                {listStatus2?.map((item, index) => {
                  return <MenuItem onClick={() => handleActive(item.value)} key={index} value={item.value}>{item.name}</MenuItem>
                })}
              </TextField>
            </Grid>

            <Grid item md={2} xs={12} style={{ position: 'relative' }}>
              <TextField
                select
                fullWidth
                id="status"
                label="Type Claim"
                variant="outlined"
                size="small"
                className={classes.inputField}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }
                }}
                onChange={handleChangeType}
              >
                <MenuItem key={1} value={1}>
                  Battery
                </MenuItem>
                <MenuItem key={2} value={2}>
                  Panel
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} style={{ position: 'relative' }}>
            <div className={classes.selectAll}>
              <p onClick={handleReset} className={classes.dashboard}>
                <span>Clear All</span>
              </p>
            </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container className={classes.actionContainer}>
            <Drawer
              className={classes.drawer}
              open={isDrawerOpen}
              PaperProps={{ style: { position: 'absolute' } }}
              BackdropProps={{ style: { position: 'absolute' } }}
              ModalProps={{
                style: { position: 'absolute' }
              }}
            >
              <div className="closeBtnContainer">
                <Button className="closeBtn" onClick={() => setDrawerOpen(false)}>
                  <img src={CloseDrawerIcon} />
                </Button>
              </div>
              <div className="filterFields">
                <h5>Filter & Sort</h5>
                <div className="filterField">
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search"
                    className={classes.inputField}
                    onChange={handleChangeKeyword}
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <img src={SearchIcon} />
                        </InputAdornment>
                    }}
                  />
                </div>
                <div className="filterField" style={{ position: 'relative' }}>
                  <DatePickerCustom
                    fullWidth
                    variant="inline"
                    format="MM/dd/yyyy"
                    value={submittedDate}
                    onChange={handleChangeDateSubmitted}
                    inputVariant="outlined"
                    placeholder="Submitted Date"
                    views={['year', 'month', 'date']}
                    className={classes.datePicker}

                  />
                  <IconButton
                    className={classes.clearIconBtn}
                    style={{ top: '10px' }}
                    edge="end"
                    size="small"
                    onClick={handleCloseDate}
                  >
                    <ClearIcon />
                  </IconButton>
                </div>
                <div className="filterField" style={{ position: 'relative' }}>
                  <TextField
                    select
                    fullWidth
                    id="status"
                    label="Claim status"
                    variant="outlined"
                    size="small"
                    onChange={handleChangeStatus}
                    className={classes.inputField}
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }
                    }}
                  >
                    {listStatus2?.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                    })}
                  </TextField>
                </div>
                <div className="filterField" style={{ position: 'relative' }}>
                  <TextField
                    select
                    fullWidth
                    id="status"
                    label="Type Claim"
                    variant="outlined"
                    size="small"
                    className={classes.inputField}
                    onChange={handleChangeType}
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }
                    }}
                  >
                    <MenuItem key={1} value={1}>
                      Battery
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      Panel
                    </MenuItem>
                  </TextField>
                </div>
              </div>
            </Drawer>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-start">
                <CustomButton
                  color="secondary"
                  className={classes.downloadButton}
                  onClick={() => setDrawerOpen(true)}
                >
                  <span style={{ marginRight: '9px' }}><img src={FilterIcon} /></span> Filter & Sort
                </CustomButton>
              </Box>
            </Grid>
          </Grid>
        )}

        <TableContainer>
          <Table className={classes.projectsTable} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Submission Date</TableCell>
                <TableCell>Claim Number</TableCell>
                <TableCell>Claim Type</TableCell>
                <TableCell>Project Name</TableCell>
                <TableCell>Qualifies for Labor Reimbursement</TableCell>
                <TableCell>Submitted by</TableCell>
                <TableCell>Claim Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listClaim.length > 0 ? (
                <>
                  {
                    listClaim.map(p => (
                      <TableRow hover 
                        style={{cursor: 'pointer'}} 
                        onClick={() => history.push(`/claim/detail/${p.id}/${Number(_.get(p, 'draftData.type', '1'))}`)}
                      >
                          {/* //Submission Date */}
                        <TableCell>
                          {p.created && <Moment format="L">{p.created}</Moment>}
                        </TableCell>
                        {/* Claim Number */}
                        <TableCell>
                          {p.claimLabel}
                        </TableCell>

                        {/* Claim Type */}
                        <TableCell>
                          {(Number(_.get(p, 'type'))) === 2 ? 'Battery' : 'Panel'}
                        </TableCell>
                        {/* Project Name */}
                        <TableCell>
                          {/* {_.get(p, 'draftData.title', '')} */}
                          {/* {p.projectNameSys} */}
                          {_.get(p, 'draftData.project', '')}
                        </TableCell>
                        {/* Labor Reimbursement */}
                        <TableCell>
                          {_.get(p, 'isDraft', false) ? '----' : renderLaborStatus(_.get(p, 'draftData.laborReimbursement', false), _.get(p, 'draftData.laborWarranty', false), _.get(p, 'draftData.choseTypeProject', '1') , _.get(p, 'draftData.chooseRefund', '1'), _.get(p, 'type', 1), _.get(p, 'draftData.panelQualified', false), _.get(p, 'draftData.batteryQualified', false))}
                        </TableCell>
                        {/* Submitted by */}
                        <TableCell>{_.get(p, 'user.firstName')} {_.get(p, 'user.lastName')}</TableCell>
                        {/* status */}
                        <TableCell>
                          <span style={{marginRight: 6, color: [5, 11, 6, 7 , 9 , 15].includes(p.statusId) ? '#009EE3' : [2,10].includes(p.statusId) ? '#EB5757' : '#000'}}>{!_.get(p, 'isDraft') ? (_.get(p, 'draftData.type', '1') === '1' ? renderStatusName(p.statusId) : renderStatusNameEss(p.statusId)) : 'Draft' }</span>
                        </TableCell>

                      </TableRow>
                    ))
                  }
                </>
              ) : (
                <TableRow className={classes.loadingEmptyRow}>
                  <TableCell colSpan={10}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <p>
                        {loading ? 'Loading...' : 'There is no claim'}
                      </p>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid
          container
          justify="center"
          className={classes.actionBottomContainer}
        >
          <Grid item md={4}/>
          <Grid item md={4}>

            <Box display="flex" justifyContent="center">
              {listClaim.length < total && (
                <CustomButton
                  color="secondary"
                  className={classes.viewMoreButton}
                  onClick={viewMore}
                  disabled={loadingMore}
                >
                  { loadingMore ? <CustomLoading size={16} color="#009EE3"/> : 'View More' }
                </CustomButton>
              )}

            </Box>
          </Grid>
          <Grid item md={4}>

          </Grid>
        </Grid>
      </div>
    </>
  );
}
