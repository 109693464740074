import React, { useEffect, useRef, useState } from 'react';
import 'animate.css/animate.min.css';
import './home.css';
import config from '../../config';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ReactSnapScroll from 'react-snap-scroll';
import ScrollAnimation from 'react-animate-on-scroll';
import { Typography } from '@material-ui/core';
import './index.css';
import CardHomePage from './components/CardHomePage';
import Carousel from 're-carousel';
import IndicatorDots from './components/indicatorDots';


import SOS from './components/SOS';
import moment from 'moment';
import {useHistory, Redirect, Link} from 'react-router-dom';
import Footer from '../../common/footer';
import FooterHomePage from '../../common/FooterHomePage';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ItemMobile from './components/ItemMobile';
import CardMobileHomePage from './components/CardMobileHomePage';
import QFinancePopUpImg from '../../config/images/popupDashboardRight.png';
import BecomeQpartnerPopUpImg from '../../config/images/dashboard_pop-up_360x600_3_Rev2.png';
import ImageBox01 from '../../config/images/img_mc_box01.png';

import EnFinPopUpBanner1 from '../../config/images/EnFin-pop-up-banner_01.png';
import EnFinPopUpBanner2 from '../../config/images/EnFin-pop-up-banner_02.png';
import EnFinPopUpBanner3 from '../../config/images/EnFin-pop-up-banner_03.png';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    textAlign: 'center',
    minHeight: '100vh',
    overflow: 'visible'
  },
  centered: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  margin: {
    // transform : 'translateY(-30px)',
    marginTop : 153,
    zIndex: 100
  },
  image: { width: '100%' },

  wrapCash: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    margin : '30px 0px 30px 0px'
  },
  wrapInfoCash : {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    margin : '30px 0px 30px 0px'
  },
  wrapCashTow : {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    top : -36,
    justifyContent: 'center'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  wrapModal: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    margin: '100px auto 30px auto !important',
    maxWidth: '96% !important',
    width: 600,
  },
  wrapModalDes : {
    display: 'flex',
    position : 'relative'
  },


  close: {
    position: 'absolute',
    width: 30,
    height: 30,
    textAlign: 'center',
    color: 'white',
    cursor: 'pointer',
    fontSize: 25,
    right: 8
  },
  wrapChose: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    background: '#333',
    margin:'0 auto 0 auto',
    // maxWidth : '1000px',
    paddingRight: 5,
    width : '100%',
    zIndex : 100
  },
  wrapChoseMobile : {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    background: '#333',
    margin:'-30px auto 30px auto',
    maxWidth : '96%',
    width : 600
  },
  modalShow: {
    position: 'fixed',
    top: 0,
    zIndex: 100,
    overflowY: 'scroll',
    height: '100%',
    width : '100%'
  },

  modalShowDes : {
    top: '50%',
    left: '50%',
    position: 'fixed',
    marginTop: '-296px',
    marginLeft: '-550px',
    zIndex: 100
  }

}));


export default function LandingPage() {

  const myRef = useRef(null);
  const classes = useStyles();
  const history = useHistory();
  const refContainer = useRef(null);

  const [scrollPos, setScrollPos] = useState(window.pageYOffset);
  const [open, setOpen] = useState(false);
  const [stepLastPage, setStepLastPage] = useState(0);

  const [transition, setTransition] = useState('move-top-bottom');
  const [index, setIndex] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [height, setHeight] = useState(0);
  // On Scroll
  const onScroll = (event) => {
    setScrollPos(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  useEffect(() => {
    setHeight(refContainer.current.clientHeight);
  }, [height])

  function handleUpdateStep(step) {
    setIndex(step);
    setTransition('scale-down-top-bottom');
  }

  function handleCheck(event) {
    if (event.target.checked) {
      localStorage.setItem('isShowModal', moment().format('YYYY-MM-DD'));
    } else {
      localStorage.removeItem('isShowModal');
    }
  }

  const children = [
    <div className="page page-one" key="red">

      <div className={classes.centered}>
        <div className={classes.margin}>
          <ScrollAnimation animateIn='fadeInDown'>
            <span style={{ fontSize: '60px', fontWeight: 500, fontFamily : 'Actual', color: '#fff' }}>
              YOUR RELIABLE PARTNER, Qcells
            </span>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInDown'>
            <p className={'wrap-txt-home'}>
              <span>
                We offer differentiated services for the success of our Q.PARTNERs.
              </span><br/>
              <span>
                Become a Q.PARTNER now and enjoy a one-stop shop with various benefits.
              </span>
            </p>
            <Typography onClick={() => history.push('/register')}
                        style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, marginBottom : 40 }}>
              <p className={'mc_btn_line'} style={{ color: '#00ADEF', fontFamily : 'Actual' }}>JOIN NOW</p>
            </Typography>

          </ScrollAnimation>

        </div>

        {/*<ScrollAnimation animateIn='fadeInDown'>*/}
        <div className={'wrap-card'}>
          <CardHomePage
            title={"PROJECT MANAGER"}
            subTitle={"PROJECT MANAGER"}
            contentTitle={"Manage and register your projects. Qcells will issue warranty certificates to homeowners upon completion"}
            url={ImageBox01}
          />
          {/*<CardHomePage*/}
          {/*  title={'CASH REWARDS'}*/}
          {/*  subTitle={'CASH REWARDS'}*/}
          {/*  contentTitle={'Qcells provides various benefits and services for our Q.PARTNERs'}*/}
          {/*  url={config.urlBox1}*/}
          {/*/>*/}
          {/*/>*/}
          <CardHomePage
            title={'TRAINING'}
            subTitle={'ON/OFFLINE TRAINING'}
            contentTitle={'We offer various training contents to support your sales efforts'}
            url={config.urlBox2}
          />
          <CardHomePage
            title="SALES TOOLS"
            subTitle="SALES TOOLS"
            url={config.urlBox4}
            contentTitle={'We provide various tools and content to support your business'}
          />
          <CardHomePage
            title={"CLAIMS & LABOR"}
            subTitle={"CLAIMS & LABOR"}
            contentTitle={"Submit your claims via the portal and track their status"}
            url={config.urlBox5}
          />
        </div>
        {/*</ScrollAnimation>*/}

      </div>
    </div>,
    <div className="page page-PM">
      <div className={classes.wrapCashTow}>
        <div className={'txt-cs'}>
            <h1 className={'test-text'} style={{
              fontSize: '48px',
              fontWeight: 700,
              color: '#fff',
              textAlign: 'center',
              lineHeight: 'initial'
            }}>
              Project Management
            </h1>
            {/*<p className={'sub-crash'} style={{ color: '#ccc', fontSize: 20, textAlign: 'center', margin: '21px 0 53px' }}>*Available for Contractors/EPC only</p>*/}
          <ScrollAnimation animateIn='fadeInDown'>
            <p className={'sub-crash'} style={{ color: '#ccc', fontSize: 20, textAlign: 'center', marginTop: 0 }}>
            Register your projects using the Q.PARTNER Project Management tool to qualify for labor <br />
            reimbursement, manage your projects and issue warranty certificates to homeowners.
            </p>
          </ScrollAnimation>
        </div>
        <SOS handleChangeStep={handleChangePage} step1={index === 1} step3={index === 2} step4={index === 3} step5={index === 4}/>
      </div>
    </div>,
    // <div className="page page-two" key="orange">
    //   <div className={classes.wrapCashTow}>
    //     <div className={'txt-cs'}>
    //         <h1 className={'test-text'} style={{
    //           fontSize: '48px',
    //           fontWeight: 700,
    //           color: '#fff',
    //           textAlign: 'center',
    //           lineHeight: 'initial'
    //         }}>
    //           Cash Rewards
    //         </h1>
    //         <p className={'sub-crash'} style={{ color: '#ccc', fontSize: 20, textAlign: 'center', margin: '21px 0 53px' }}>*Available for Contractors/EPC only</p>
    //       <ScrollAnimation animateIn='fadeInDown'>
    //         <p className={'sub-crash'} style={{ color: '#ccc', fontSize: 20, textAlign: 'center' }}>
    //           The reward program offers you financial rewards and promotional items which you can redeem in the Shop.
    //         </p>
    //       </ScrollAnimation>
    //     </div>
    //     <SOS handleChangeStep={handleChangePage} step1={index === 1} step2={index === 2} step3={index === 3} step4={index === 4} step5={index === 5}/>
    //   </div>
    // </div>,
    <div className="page page-three" key="yellow">
      <div className={classes.wrapCashTow}>
        <div className={'txt-cs'}>
            <h1 className={'test-text'} style={{
              fontSize: '48px',
              fontWeight: 700,
              color: '#fff',
              textAlign: 'center',
              lineHeight: 'initial'
            }}
            >
              Training
            </h1>
            <p className={'sub-crash'} style={{ color: '#ccc', fontSize: 20 }}>
              Stay up to date with the latest training materials, online videos and NABCEP content.<br/>
              We offer on-site/off-site training upon request.
            </p>
        </div>
        <SOS handleChangeStep={handleChangePage} step1={index === 1} step3={index === 2} step4={index === 3} step5={index === 4}/>
        {/*<ScrollAnimation animateIn='fadeInDown'>*/}
        {/*  <SOS step1={index === 1} step2={index === 2} step3={index === 3} step4={index === 4} step5={index === 5}/>*/}
        {/*</ScrollAnimation>*/}

      </div>
    </div>,
    <div className={`page page-four`} key="green">
      <div className={classes.wrapCashTow}>
        <div className={'txt-cs'}>
            <h1 className='test-text' style={{
              fontSize: '48px',
              fontWeight: 700,
              color: '#fff',
              textAlign: 'center',
              lineHeight: 'initial' }}
            >
              Sales Tools
            </h1>
            <p className={'sub-crash'} style={{ color: '#ccc', fontSize: 20 }}>
              Your one-stop show for all sales, marketing, and technical materials to help your sales team and customers.
            </p>
        </div>
        <SOS handleChangeStep={handleChangePage} step1={index === 1} step3={index === 2} step4={index === 3} step5={index === 4}/>
      </div>
    </div>,
    <div className={`page page-five ${stepLastPage >= 4 ? 'page-four-last' : ''}`} >
      <div className={classes.wrapCashTow}>
        <div className={'txt-cs'}>
            <h1 className='test-text' style={{
              fontSize: '48px',
              fontWeight: 700,
              color: '#fff',
              textAlign: 'center',
              lineHeight: 'initial' }}
            >
              Claims/Labor
            </h1>
            <p className={'sub-crash'} style={{ color: '#ccc', fontSize: 20, lineHeight: 'initial' }}>
              Submit claims seamlessly through the Q.PARTNER Portal. Qualify for labor reimbursement upon registering your projects.
            </p>
            <p className={'sub-crash'} style={{ color: '#ccc', fontSize: 20, textAlign: 'center', margin: '35px 0 14px', lineHeight: 'initial' }}>*Labor reimbursement available for Contractors/EPC only</p>
        </div>
        <SOS handleChangeStep={handleChangePage} step1={index === 1} step3={index === 2} step4={index === 3} step5={index === 4}/>
      </div>
    </div>
  ];

  useEffect(() => {
    const date = localStorage.getItem('isShowModal');

    if (date && moment().isSame(date, 'days')) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, []);

  // useEffect(() => {
  //   console.log('last', stepLastPage);
  // }, [stepLastPage])

  function indexChanged(index) {
    if(index === 5) {
      setStepLastPage(stepLastPage + 1);
    }else if(index !== 5 && stepLastPage >= 2) {
      setStepLastPage(0);
    }
    setIndex(index);
  }

  async function handleChangePage(page) {
    if(index > page) {
      for (let i = 0; i< (index - page); i++) {
        await myRef.current.prev();
      }
    }else {
      for (let i = 0; i < (page - index); i++) {
        await myRef.current.next();
      }
    }

    if(page === 5) {
      setStepLastPage(1)
    }else {
      setStepLastPage(0)
    }
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  async function handleNavigate() {
    await sleep(300);
    history.push('/register')
  }

  async function handleNavigateContact() {
    await sleep(300);
    history.push('/support/contact-us');
  }

  const user = JSON.parse(localStorage.getItem('qpartner_user'));

  return (
    <>
    { user?.user ?
    <div ref={refContainer} className="HomePage">
    <Redirect to="/dashboard" />
    </div>
      :
    <div ref={refContainer} className="HomePage">
      {!isSmallScreen && <>
        <ul className={'menu-li'}>
          <li onClick={() => handleChangePage(0)} className={index === 0 && 'active'}/>
          <li onClick={() => handleChangePage(1)} className={index === 1 && 'active'}/>
          <li onClick={() => handleChangePage(2)} className={index === 2 && 'active'}/>
          <li onClick={() => handleChangePage(3)} className={index === 3 && 'active'}/>
          <li onClick={() => handleChangePage(4)} className={index === 4 && 'active'}/>
          <li onClick={() => handleChangePage(5)} className={index === 5 && 'active'}/>
        </ul>


        <ReactSnapScroll
          ref={myRef}
          start={index}
          transition={transition}
          indexChanged={indexChanged}
        >
          {children.map(child => child)}
        </ReactSnapScroll>
      </>}
      {isSmallScreen && <Carousel loop={true} auto widgets={[IndicatorDots]}>
        <div className={'item-page-one'}>
          <ItemMobile
            step={'one'}
            title={'YOUR RELIABLE PARTNER,'}
            subTitle={'Hanwha Q CELLS'}
            content={'offers differentiated services for the success of our Q.PARTNERs.'}
            subContent={'Become a Q.PARTNER now and enjoy various benefits.'}
          />
        </div>
        {/*<div className={'item-page-two'}>*/}
        {/*  <ItemMobile*/}
        {/*    step={'two'}*/}
        {/*    title={'CASH REWARDS'}*/}
        {/*    content={'Hanwha Q CELLS provides various benefits and services for our Q.PARTNERS.'}*/}
        {/*    subContent={'Receive rewards and extra benefits for purchasing our high-performance solar modules.'}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className={'item-page-three'}>
          <ItemMobile
            step={'three'}
            title={'ON/OFFLINE TRAINING'}
            content={'We offer various training contents to support your sales efforts.'}
            subContent={'Receive training to strengthen your capabilities.'}
          />
        </div>
        <div className={'item-page-four'}>
          <ItemMobile
            step={'four'}
            title={'DATA HUB'}
            content={'We provide various tools and content to support your business.'}
            subContent={'Utilize the Data Hub to get materials that you need.'}
          />
        </div>
      </Carousel>}
      {open && isSmallScreen && <div className={classes.modalShow}>
        <div className={classes.wrapModal}>
          <div onClick={() => setOpen(false)} className="qpclose"/>
          <div className={'wrap-image'}>
            <img style={{backgroundColor: '#ffffff'}} src={EnFinPopUpBanner1} alt="" />
            <a style={{backgroundColor: '#ffffff', display: 'block'}} target="_blank" href="https://partner.enfin.com/pi/s/piloanapplication">
              <img style={{backgroundColor: '#ffffff', paddingTop: '0%'}} src={EnFinPopUpBanner2} alt="" />
            </a>
            <img style={{backgroundColor: '#ffffff'}} src={EnFinPopUpBanner3} alt="" />
          </div>
          <img onClick={() => history.push('/register')} style={{ width: '100%', cursor: 'pointer' }}
               src={config.urlBanner1}/>
          <img onClick={() => history.push('/register')} style={{ width: '100%', cursor: 'pointer' }}
               src={config.urlBanner2}/>
        </div>
        <div className={classes.wrapChoseMobile}>
          <input style={{ cursor: 'pointer' }} type="checkbox" onClick={handleCheck}/>
          <span style={{ color: 'white', fontSize: 16 }}>Don't show again today.</span>
        </div>
      </div>}

      {open && !isSmallScreen &&
        <div className={'modalShowDes'}>
          <div className="overlay-modal" onClick={() => setOpen(false)}/>
          <div className={'wrapModalDes'}>
            <div>
              <div  onClick={() => setOpen(false)} className={'qpclose'}/>
              <div style={{position: 'relative', backgroundColor: '#ffffff'}}>
                {/*<img src={QHomePopUpImg}/>*/}
                {/*<span style={{cursor: 'pointer', position: 'absolute', top: '42%', height: '38px', width: '235px', left: '26%'}} onClick={() => history.push('/notice/506')}/>*/}
                {/*<span style={{cursor: 'pointer', position: 'absolute', bottom: '4%', height: '38px', width: '235px', left: '26%'}} onClick={() => history.push('/rewards/shop')}/>*/}
                <img style={{backgroundColor: '#ffffff'}} src={EnFinPopUpBanner1} alt="" />
                <a style={{backgroundColor: '#ffffff', display: 'block'}} target="_blank" href="https://partner.enfin.com/pi/s/piloanapplication">
                  <img style={{backgroundColor: '#ffffff', paddingTop: '0%'}} src={EnFinPopUpBanner2} alt="" />
                </a>
                <img style={{backgroundColor: '#ffffff'}} src={EnFinPopUpBanner3} alt="" />
              </div>
            </div>
            <div className={classes.wrapChose}>
              <input style={{ cursor: 'pointer' }} type="checkbox" onClick={handleCheck}/>
              <span style={{ color: 'white', fontSize: 16 }}>Don't show again today.</span>
            </div>
          </div>
        </div>
      }

      {isSmallScreen && <div className="wrap-info-mb-login">
        <span className={'mb-title-login'}>LOG IN</span>
        <button onClick={() => history.push('/sso/login?url=/dashboard#')} className={'btn-sso-mb'}>
          <span>Log in</span>
        </button>
        <div className="wrap-content-mb-login">
          <span className={'contact-us-txt'} onClick={handleNavigateContact} style={{ width: '49%' }}>Q.PARTNER Membership Inquiry</span>
          <div className="ver"/>
          <span className={'register-txt'} onClick={handleNavigate} style={{ width: '49%' }}>BECOME A Q.PARTNER</span>
        </div>
      </div>}

      {isSmallScreen && <div className="container-wrap-mobile-sso">
        {/*<CardMobileHomePage*/}
        {/*  title={'Cash Rewards'}*/}
        {/*  url={config.urlLink1}*/}
        {/*  content={'The reward program offers you financial rewards and promotional items which you can redeem in the Marketing Shop.'}*/}
        {/*/>*/}
        <CardMobileHomePage
          title={'Training'}
          url={config.urlLink2}
          content={'We provide online videos and NABCEP content, in addition to on-site training to empower your sales team.'}
        />
        <CardMobileHomePage
          title={'Data Hub'}
          url={config.urlLink3}
          content={'The Data Hub offers the most recent sales and technical support material to assist your sales efforts.'}
        />
      </div>}
      {isSmallScreen && <Footer/>}
      {stepLastPage >= 2 && <FooterHomePage isHome={true} isTrigger={stepLastPage >= 2}/>}
    </div>
    }
    </>
  );
}
