import axios from '../../utils/axios';
import config from '../../config';
import { convertHTMLEntity } from './../../utils/helpers.js';

class AsyncEmailValidationChecker {
    constructor() {
      this.isValid = false;
      this.error = null;
      this.currentValue = null;
    }
  
    isInputValid = () => this.isValid;
  
    getCurrentValue = () => this.currentValue;
  
    getError = () => this.error;
  
    validateInput = async (value) => {
      this.currentValue = value;
      try {
        const { data } = await axios.get(`${config.baseUrl}/companies/register/check-email`, { params: { email: value } });
        const isHaveEmail = !!data;
        if (isHaveEmail) {
          this.error = { 
            type: 'is-duplicate',
            message: { 
              companyName: convertHTMLEntity(data.companyName),
              fullName: data.fullName,
              email: data.email,
              message: `Your company already has an account created under ${convertHTMLEntity(data.companyName)} by a primary user ${data.fullName}, ${data.email}.`,
            } 
          };
          this.isValid = false;
        } else {
          this.error = null;
          this.isValid = true;
        }
      } catch (err) {
        this.isValid = false;
        this.error = { type: 'server', message: 'Internal Server Error' };
      } finally {
        return Promise.resolve({
          isValid: this.isValid,
          currentValue: this.currentValue,
          error: this.error
        });
      }
    }
}

export default AsyncEmailValidationChecker;