import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import BreadCrumb2 from './../../common/components/BreadCrumb2';
import Banner from '../../common/components/Banner';
import MyPageBanner from '../../config/images/my-page-banner.jpg';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../../common/components/text';
import { Table, TableBody, TableRow, TableCell, Grid, Box, TextField, Button, Select, MenuItem, TextareaAutosize } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import ReactSelect from 'react-select';
import config from '../../config';
import axios from '../../utils/axios';
import { notiFailed, notiSuccess } from './../../utils/helpers.js';
import _ from 'lodash';
import CustomLoading from './../../common/components/CustomLoading';

const useStyles = makeStyles(theme => ({
    pageContainer: {
        paddingTop: '52px',
        width: '65%',
        margin: '0 auto',
        transition: 'all 0.4s',
        marginBottom: '20px',
        [theme.breakpoints.down('md')]: {
            width: '90%'
        }
    },
    pageTitle: {
        color: '#000',
        fontSize: '26px',
        fontWeight: 500,
        lineHeight: '26px',
        textTransform: 'uppercase',
        marginBottom: '15px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '18px !important'
        }
    },
    infoEs: {
        fontSize: '14px',
        position: 'relative',
        '& span': {
            paddingLeft: '17px'
        },
        '& span::before': {
            position: 'absolute',
            left: 0,
            top: '50%',
            width: '12px',
            height: '1px',
            background: '#009ee3',
            content: '""'
        }
    },
    table: {
        marginTop: '10px',
        border: 'solid #000',
        borderWidth: '1px 0',
        '& td': {
          fontSize: '15px',
          fontFamily: 'Actual_Light',
          color: '#333'
        },
        '& .MuiTableCell-root': {
          padding: '24px'
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0
        },
        '& .MuiOutlinedInput-input': {
            padding: '13.5px 14px'
        },
        [theme.breakpoints.down('xs')]: {
            '& .MuiTableRow-root': {
                display: 'flex',
                flexDirection: 'column'
            },
            '& .MuiTableCell-root': {
                borderBottom: 0
            },
            '& .MuiTableRow-root td:first-child': {
                paddingBottom: '16px'
            },
            '& .MuiTableRow-root td:last-child': {
                paddingTop: 0
            }
        }
    },
    icoEs: {
        position: 'relative',
        '&::after': {
            display: 'block',
            position: 'absolute',
            left: '1px',
            bottom: '-4px',
            width: '14px',
            height: '1px',
            background: '#009ee3',
            content: '""'
        }
    },
    confirmBtn: {
        background: '#666',
        color: '#fff',
        transition: 'all 0.4s',
        borderRadius: 0,
        textTransform: 'none',
        fontSize: '16px',
        border: '1px solid #666',
        '& span.MuiButton-label': {
            minWidth: '110px',
            height: '38px'
        },
        '&:hover': {
            backgroundColor: '#444'
        }
    },
    cancelBtn: {
        transition: 'all 0.4s',
        borderRadius: 0,
        textTransform: 'none',
        fontSize: '16px',
        border: '1px solid #666',
        color: '#666',
        marginLeft: '10px',
        '& span.MuiButton-label': {
            padding: '5px 30px'
        },
        '&:hover': {
            border: '1px solid #444',
            color: '#444',
            backgroundColor: '#fff'
        }
    },
    menuItem: {
        maxWidth: '100% !important'
    },
    overviewTextarea: {
        width: '100%',
        padding: '10px 12px'
    },
    multiSelect: {
        '& .select__control': {
            borderRadius: 0,
            padding: '4px'
        },
        '& .select__multi-value__remove:hover': {
            backgroundColor: '#00adef',
            color: '#fff'
        }
    },
    tableCell: {
        width: '20%',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));

const { baseUrl } = config;

const schema = yup.object().shape({});

export default function EditCompanyAddtional() {
    const user = useAuth();
    const classes = useStyles();
    const history = useHistory();
    const [saleRegions, setSaleRegions] = useState([]);
    const { register, handleSubmit, watch, errors, setValue, clearErrors, control } = useForm({
        resolver : yupResolver(schema)
    });
    const [loading, setLoading] = useState(false);

    const onSubmit = async data => {
        if(_.trim(_.get(data, 'estYear')) === ''){
            return notiFailed('Please enter the year of establishment.');
        }
    
        if(!/^[0-9]*$/.test(_.trim(_.get(data, 'estYear')))){
            return notiFailed("This entry can only contain numbers. ex) 2017");
        }
        
        if(_.trim(_.get(data, 'salesYear')) === ''){
            return notiFailed("Please enter annual sales.");
        }
            
        if(_.trim(_.get(data, 'numberOfEmployees')) === ''){
            return notiFailed('Please enter number of employees.');
        }
        
        if(!/^[0-9]*$/.test(_.trim(_.get(data, 'numberOfEmployees')))){
            return notiFailed('This entry can only contain numbers.');
        }

        const saleRegion = _.get(data, 'saleRegions');
        if (!saleRegion || saleRegion.length === 0) {
            return notiFailed('Please select region(s).');
        }

        const bodyData = {
            estYear: Number(_.trim(_.get(data, 'estYear'))),
            numberOfEmployees: Number(_.trim(_.get(data, 'numberOfEmployees'))),
            description: _.get(data, 'description'),
            salesYear: _.get(data, 'salesYear'),
            salesArea: _.join(saleRegion, '|')
        };

        try {
            setLoading(true);
            await axios.post(`${baseUrl}/companies/additional`, bodyData);
            notiSuccess('Edit addtional company information successfully.');
        } catch (err) {
            notiFailed(`Something happened edit addtional company information`);
        } finally {
            setLoading(false);
        }
    };

    const getUserProfile = async () => {
        try {
            const response = await axios.get(`${baseUrl}/companies/salesregions/search`);
            setSaleRegions(response.data);
            const { data } = await axios.get(`${baseUrl}/users/profile`);
            setValue('taxId', _.get(data, '[0].taxId'));
            setValue('estYear', _.get(data, '[0].estYear'));
            setValue('salesYear', _.get(data, '[0].salesYear'));
            setValue('numberOfEmployees', _.get(data, '[0].numberOfEmployees'));
            setValue('description', _.get(data, '[0].description'));
            const saleRegion =  _.get(data, '[0].salesArea');
            if (_.trim(saleRegion) === '') {
                setValue('saleRegions', []);
            } else {
                setValue('saleRegions', saleRegion.split('|'));
            }
        } catch (err) {
            notiFailed(`Something happened getting user profile`);
        }
    };

    useEffect(() => {
        getUserProfile();
    }, []);

    return (
        <>
            <Banner
                imageUrl={MyPageBanner}
                className="OrderHistory_Banner banner100"
            >
                <h1 className="title_animation OrderHistory_Title">My Account</h1>
                <BreadCrumb2 items={[`${user.firstName} ${user.lastName}`, 'My Account']}></BreadCrumb2>
            </Banner>

            <div className={classes.pageContainer}>
                <Text className={classes.pageTitle}>
                    Additional Information
                </Text>

                <Box
                    display="flex"
                    justifyContent="flex-end"
                >
                    <p className={classes.infoEs}><span>Required</span></p>
                </Box>

                <Table className={classes.table}>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.tableCell}>
                                <span className={classes.icoEs}>Tax ID</span>
                            </TableCell>
                            <TableCell>
                                <Grid container>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            inputRef={register}
                                            name="taxId"
                                            disabled
                                            style={{
                                                background: '#f8f8f8',
                                                color: '#666'
                                            }}           
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>W-9</span>
                            </TableCell>
                            <TableCell>
                                <Grid container>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            inputRef={register}
                                            name="w9"
                                            disabled
                                            style={{
                                                background: '#f8f8f8',
                                                color: '#666'
                                            }}       
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Year of Establishment</span>
                            </TableCell>
                            <TableCell>
                                <Grid container>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            inputRef={register}
                                            name="estYear"       
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Annual Sales (Previous Year, USD)</span>
                            </TableCell>
                            <TableCell>
                                <Grid container>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            inputRef={register}
                                            name="salesYear"          
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Partner Type</span>
                            </TableCell>
                            <TableCell>
                                <Grid container>
                                    <Grid item md={6} xs={12}>
                                        <Controller
                                            fullWidth
                                            control={control}
                                            name="partnerType"
                                            variant="outlined"
                                            as={
                                                <Select 
                                                    disabled
                                                    style={{
                                                        background: '#f8f8f8',
                                                        color: '#666'
                                                    }}
                                                >
                                                    <MenuItem className={classes.menuItem} value="installer">Installer</MenuItem>
                                                    <MenuItem className={classes.menuItem} value="distributor">Distributor</MenuItem>
                                                </Select>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Number of Employees</span>
                            </TableCell>
                            <TableCell>
                                <Grid container>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            inputRef={register}
                                            name="numberOfEmployees"                      
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span className={classes.icoEs}>Sales Region(s)</span>
                            </TableCell>
                            <TableCell>
                                <Grid container>
                                    <Grid item md={6} xs={12}>
                                        <Controller
                                            as={
                                                <ReactSelect
                                                    isMulti
                                                    options={saleRegions}
                                                    className={classes.multiSelect}
                                                    classNamePrefix="select"
                                                    getOptionLabel={option => option.split(':')[1]}
                                                    getOptionValue={option => option}
                                                />
                                            }
                                            name="saleRegions"
                                            control={control}
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Overview of Company/Business
                            </TableCell>
                            <TableCell>
                                <Grid container>
                                    <Grid item md={6} xs={12}>
                                        <TextareaAutosize 
                                            className={classes.overviewTextarea} 
                                            rowsMin={5}
                                            name="description"
                                            ref={register}
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Grid 
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ textAlign: 'center', marginTop: '40px', marginBottom: '80px' }}
                >
                    <Button className={classes.confirmBtn} onClick={handleSubmit(onSubmit)} disabled={loading}>
                        { loading ? <CustomLoading size={26} color="#fff"></CustomLoading> : 'Confirm' }
                    </Button>
                    <Button className={classes.cancelBtn} onClick={() => history.push('/mypage/member')}>Cancel</Button>
                </Grid>
            </div>
        </>
    )
}