import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  makeStyles,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Grid
} from '@material-ui/core';
import Banner from '../../../common/components/Banner';
import BannerImage from '../../../config/images/visual_area01_5.png';
import BreadCrumb2 from '../../../common/components/BreadCrumb2';
import IconFile from '../../../icons/hiw_file.svg';
import IconClaimStatus from '../../../icons/hiw_claimStatus.svg';
import IconLap from '../../../icons/lap.png';
import PanelIcon from '../../../icons/panel-icon.png';
import CustomButton from '../../../common/components/button';
import { useHistory } from 'react-router';
import Text from '../../../common/components/text';
import Popover from '../../../common/components/Popover';
import _ from 'lodash';
import { useAuth } from '../../../contexts/auth';

const useStyles = makeStyles(theme => ({
  container: {
    width: '85%',
    margin: '40px auto 72px'
  },
  text14n300: {
    fontSize: 14,
    fontWeight: 300
  },
  text14n400: {
    fontSize: 14,
    fontWeight: 400
  },
  text15n300: {
    fontSize: 15,
    fontWeight: 300
  },
  text15n400: {
    fontSize: 15,
    fontWeight: 400
  },
  titleBanner: {
    fontSize: '54px',
    fontWeight: '500',
    fontFamily: 'Actual',
    lineHeight: '68px'
  },
  subTitleBanner: {
    fontSize: '14px',
    fontWeight: 400
  },
  titleSection: {
    fontSize: 25,
    fontWeight: '500',
    lineHeight: '31px',
    marginBottom: 20
  },
  claimBox: {
    border: '1px solid #E0E0E0',
    borderRadius: '2px'
  },
  submitIntro: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    display: 'grid',
    gridColumnGap: '22px',
    gridTemplateColumns: '1fr 15fr 3fr',
    padding: '20px 30px',
    borderBottom: '1px solid #E0E0E0'
  },
  statusIntro: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    display: 'grid',
    gridColumnGap: '22px',
    gridTemplateColumns: '1fr 15fr 3fr',
    padding: '20px 30px'
  },
  statusTableIntro: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    display: 'grid',
    gridColumnGap: '22px',
    gridTemplateColumns: '1fr 18fr',
    padding: '20px 30px',
    '& .MuiTableRow-head': {
      background: '#0F2848'
    },
    '& .MuiTableCell-head': {
      color: '#fff',
      fontSize: 15,
      fontWeight: 300
    },
    '& .MuiTableCell-root': {
      fontFamily: 'Actual'
    },
    '& .MuiTable-root': {
      border: '1px solid #E0E0E0'
    }
  },
  wrapMessage: {
    backgroundColor: '#F6FAFD',
    padding: 11,
    border: '1px solid #DCE1E1',
    color: '#4F4F4F',
    marginTop: 15
  },
  labor: {
    marginTop: 30
  },
  paper: {
    padding: '12px 20px 7px',
    backgroundColor: '#ffffff',
    marginTop: 27,
    ' &.MuiPaper-rounded': {
      borderRadius: 2
    }
  },
  laborBox: {
    border: '1px solid #E0E0E0',
    borderRadius: '2px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '44px 20px 23px 37px',
    marginTop: 11,
    '& >img': {
      height: 258,
      width: 434
    },
    '& p': {
      fontSize: 15,
      fontWeight: 300
    },
    '& .MuiTableRow-head': {
      background: '#0F2848'
    },
    '& .MuiTableCell-head': {
      color: '#fff',
      fontSize: 15,
      fontWeight: 300
    },
    '& .MuiTableCell-root': {
      fontFamily: 'Actual'
    },
    '& .MuiTable-root': {
      border: '1px solid #E0E0E0',
      marginTop: 22,
      borderBottom: 'none'
    }
  },
  laborRight: {
    marginLeft: 45
  },
  requireCell: {
    display: 'flex',
    padding: '25px 20px 19px'
  },
  number: {
    color: '#009EE3',
    opacity: 0.7,
    marginRight: 11,
    '& span': {
      width: 35,
      height: 35,
      fontSize: 15,
      fontWeight: 700,
      background: '#D9F1FC',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
}));

const products = [
  {
    id: 8,
    name: `Q.HOME<sup>+</sup> ESS HYB-G1`
  },
  {
    id: 0,
    name: 'Q.PEAK DUO BLK-G6+/AC'
  },
  {
    id: 3,
    name: 'Q.PEAK DUO ML-G9+ / Q.PEAK DUO BLK ML-G9+'
  },
  {
    id: 9,
    name: 'Q.PEAK DUO-G8+ / Q.PEAK DUO BLK-G8+'
  },
  {
    id: 1,
    name: 'Q.PEAK DUO-G6+ / Q.PEAK DUO BLK-G6+'
  },
  {
    id: 2,
    name: 'Q.PEAK DUO-G7'
  },
  {
    id: 4,
    name: 'Q.PEAK DUO-G5 / Q.PEAK DUO BLK-G5'
  },
  {
    id: 5,
    name: 'Q.PEAK G4.1 / Q.PEAK BLK-G4.1'
  },
  {
    id: 7,
    name: 'Q.PLUS BFR-G4.1'
  }
];

export default function HowItWorks() {
  const location = useLocation();
  const { auth } = useAuth();
  const classes = useStyles();
  const laborRef = useRef();
  const claimRef = useRef();
  const history = useHistory();

  const [width, setWindowWidth] = useState(0);
  // const [innerWidth, setInnerWidth] = useState(null);
  const typeBussiness = _.get(auth, 'user.partnerType');
  useEffect(() => {
    if (_.get(location, 'state.isScrollToLaborReimbursement')) {
      window.scrollTo(0, laborRef.current.offsetTop - 80);
    }
    if (_.get(location, 'state.isScrollToClaim')) {
      window.scrollTo(0, claimRef.current.offsetTop - 100);
    }
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  return (
    <>
      <BreadCrumb2 items={['support', 'claim', 'how it works']}></BreadCrumb2>
      <Banner imageUrl={BannerImage} className="banner100">
        <h1 className={classes.titleBanner}>HOW IT WORKS</h1>
        <p className={classes.subTitleBanner}>
          Learn about product claims and labor reimbursement
        </p>
      </Banner>

      <div className={classes.container} ref={claimRef}>
        <div>
          <h2 className={classes.titleSection}>Claims</h2>
          <div className={classes.claimBox}>
            <div className={classes.submitIntro}>
              <img src={IconFile} />
              <div className={classes.submitContent}>
                <p style={{ fontSize: 20, fontWeight: 500 }}>Submit a Claim</p>
                <p className={classes.text14n300}>
                  Submitting your claims has never been easier! Simply submit a
                  warranty claim by filling out the Claims intake form with all
                  required information, and your claim will be processed.
                </p>
              </div>
              <CustomButton
                color="secondary"
                style={{ height: 45, padding: 0 }}
                onClick={() => history.push('/claim/info')}
              >
                Submit a Claim
              </CustomButton>
            </div>

          </div>
        </div>
        {typeBussiness !== 3 && (
          <div className={classes.labor} ref={laborRef}>
            <h2 className={classes.titleSection}>Labor Reimbursement</h2>
            <p className={classes.text15n300}>
              *This feature is only available for Contractors/EPC.
            </p>
            <div>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <Grid container alignItems="center">
                  <Grid className="foo200" item>
                    <img src={PanelIcon} alt="Panel" width="86" height="74" />
                  </Grid>
                  <Grid
                    className="foo201"
                    container
                    item
                    xs={12}
                    sm={12}
                    md={1300 < width ? 9 : 8}
                    style={{ marginLeft: '15px', textAlign: 'left' }}
                  >
                    <Grid item xs={12}>
                      <Text
                        className="center_xs have_you"
                        color="dark"
                        weight="500"
                        style={{ fontSize: '19px' }}
                      >
                        Register your projects and secure labor cost reimbursement
                        benefits of up to $500 per project!*
                      </Text>
                      <Text
                        className="center_xs"
                        color="dark"
                        weight="300"
                        style={{ fontSize: '15px' }}
                      >
                        *Labor costs incurred on a valid Qcells product warranty
                        claim
                      </Text>
                    </Grid>
                  </Grid>
                  <Grid item xs container justify="flex-end">
                    <Grid item>
                      <CustomButton
                        color="secondary"
                        onClick={() =>
                          history.push('/projects/project-registration')
                        }
                        style={{
                          height: 45,
                          padding: '0 31px',
                          textAlign: 'center'
                        }}
                        // onClick={() => window.open('/projects/project-registration', "_blank")}
                      >
                        Register a Project
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </div>
            <div className={classes.laborBox}>
              <img src={IconLap} />
              <div className={classes.laborRight}>
                <p style={{ color: '#EB5757', fontSize: 20, fontWeight: 500 }}>
                  **IMPORTANT
                </p>
                <div>
                  <p style={{ fontSize: 22, fontWeight: 500 }}>
                    Requirements to Qualify for Labor Cost Reimbursement
                  </p>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Requirements</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.requireCell}>
                            <div className={classes.number}>
                              <span>1.</span>
                            </div>
                            <div>
                              <p>
                                Projects must be registered through the Q.PARTNER
                                Project Management Tool within 3 months from the
                                Permission to Operate (PTO) date.
                              </p>
                              <div className={classes.wrapMessage}>
                                <em className={classes.infoTextMessage}>
                                  <span
                                    style={{ fontWeight: 500, color: '#4f4f4f' }}
                                  >
                                    Example:
                                  </span>{' '}
                                  A system with a PTO date of 1/1/22 would only
                                  qualify for labor cost reimbursement if registered
                                  before 4/1/22.{' '}
                                </em>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            className={classes.requireCell}
                            style={{ alignItems: 'center' }}
                          >
                            <div className={classes.number}>
                              <span>2.</span>
                            </div>
                            <div>
                              <p>A system must be ≤ 25kW.</p>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            className={classes.requireCell}
                            style={{ alignItems: 'center' }}
                          >
                            <div className={classes.number}>
                              <span>3.</span>
                            </div>
                            <div>
                              <p>
                                A project must be using Q CELLS’ residential products
                                only.
                                <Popover>
                                  <Table>
                                    <TableHead
                                      style={{
                                        backgroundColor: '#E6E6E6',
                                        border: '1px solid #e6e6e6'
                                      }}
                                    >
                                      <TableRow className="title100">
                                        <TableCell>
                                          <Text style={{ fontWeight: 700 }}>
                                            Product
                                          </Text>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody className="table_row_wahtqcell">
                                      {products.map(p => (
                                        <>
                                          <TableRow>
                                            <TableCell>
                                              <p
                                                dangerouslySetInnerHTML={{
                                                  __html: p.name
                                                }}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </Popover>
                              </p>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div style={{ marginTop: 33 }}>
                  <p style={{ fontSize: 22, fontWeight: 500 }}>
                    Labor Cost Reimbursement Policy
                  </p>
                  <p className={classes.text15n300}>
                    Properly registered projects are eligible for the labor cost
                    reimbursement benefit on valid warranty claims during the entire
                    warranty period of the relevant product warranty (normally up to
                    25 years).
                  </p>
                  <div className={classes.wrapMessage}>
                    <em
                      className={classes.infoTextMessage}
                      style={{ fontWeight: 400, color: '#4f4f4f' }}
                    >
                      Up to $250 per project per product type on the first valid
                      Qcells product warranty claim (one time only)
                    </em>
                  </div>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Labor Reimbursement Payout Limits</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            className={classes.requireCell}
                            style={{ alignItems: 'center' }}
                          >
                            <div className={classes.number}>
                              <span>1.</span>
                            </div>
                            <div className={classes.text15n300}>
                              <p>
                                <span className={classes.text15n400}>Case 1:</span>{' '}
                                Qcells Panel Only: Up to $250
                              </p>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            className={classes.requireCell}
                            style={{ alignItems: 'center' }}
                          >
                            <div className={classes.number}>
                              <span>2.</span>
                            </div>
                            <div className={classes.text15n300}>
                              <p>
                                <span className={classes.text15n400}>Case 2:</span>{' '}
                                Qcells Battery Only: Up to $250
                              </p>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            className={classes.requireCell}
                            style={{ alignItems: 'center' }}
                          >
                            <div className={classes.number}>
                              <span>3.</span>
                            </div>
                            <div className={classes.text15n300}>
                              <p>
                                <span className={classes.text15n400}>Case 3:</span>{' '}
                                Qcells Panel+Battery: Up to $250 for each product
                                type (up to $500 per project){' '}
                              </p>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
