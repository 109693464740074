import React from 'react';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import CheckValidate from '../../../common/components/CheckValidate';
import { TextField } from '@material-ui/core';
import useStyles from './useStyles';
import NumberFormat from "react-number-format";
import PopOver from '../../../config/images/popover_icon.svg';
import {useHistory} from 'react-router-dom';

function PanelsNumberFormat(props) {
  return <NumberFormat {...props} allowNegative={false} />;
}

function FormUploadSerialNumberNotMatchDbBattery({control, errors, batteryInverterPower, essProductModels, watch, isNoneQualifyEss}) {

  const classes = useStyles();
  const history = useHistory();

  return(
    <>
      <div className={classes.batterySectionContainer}>
        <div className={classes.sectionHeaderContainer} style={{alignItems: 'flex-start'}}>
          <span className={classes.sectionTitle}>
            Model <span>*</span>
          </span>
        </div>
        <div className={classes.inputFieldMediumContainer}>
          <Controller
            name="batteryModel"
            control={control}
            as={
              <select
                // placeholder="Battery Capacity"
                className={classNames([classes.selectField], {
                  [classes.errorSelectField]: errors.batteryModel
                })}
              >
                <option value={'select'}>Select </option>
                {essProductModels?.map(item => (
                  <option key={item.id} value={item.productName}>
                    {item.productName}
                  </option>
                ))}
              </select>
            }
          />

          {!isNoneQualifyEss && (
            <div className={classes.notiBox}>
              <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>
              <p>
                Please note that this product does not qualify for labor cost reimbursement. Please visit {' '}
                <span style={{textTransform: 'uppercase'}}>
                                    <em style={{color: '#009EE3', cursor: 'pointer', textDecoration: 'underline'}} onClick={() => history.push({
                                      pathname: '/claim/how-it-works',
                                      state: {
                                        isScrollToQcellsProductsQualify: true
                                      }
                                    })}> HOW CLAIM WORKS </em>
                                  </span>
                {' '} to learn more about labor reimbursement policy.
              </p>
            </div>
          )}

        </div>
        <CheckValidate value={watch('batteryModel')} error={errors.batteryModel}/>
      </div>
      <div className={classes.batterySectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
            Battery Inverter <br />
            Power(kW) <span>*</span>
          </span>
        </div>
        <div className={classes.inputFieldMediumContainer}>
            <Controller
              name="batteryInverterPower"
              control={control}
              as={
                <select
                  placeholder="Battery Capacity"
                  className={classNames([classes.selectField], {
                    [classes.errorSelectField]: errors.batteryInverterPower
                  })}
                >
                  <option value={'select'}>Select </option>
                  {batteryInverterPower?.map(item => (
                    <option key={item.id} value={Number(item.volume)/1000}>
                      {Number(item.volume)/1000} {' '} kW
                    </option>
                  ))}
                </select>
              }
            />
        </div>
        <CheckValidate value={watch('batteryInverterPower')} error={errors.batteryInverterPower}/>
      </div>
    </>
  )
}

export default FormUploadSerialNumberNotMatchDbBattery;
