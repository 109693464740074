import React, {useState, useEffect} from 'react';
import Banner from '../../common/components/Banner';
import BannerImage from '../../config/images/visual_area01_4.jpg';
import Text from '../../common/components/text';
import TextButton from '../../common/components/button/TextButton';
import TitleAnimation from '../../common/components/TitleAnimation';
import BreadCrumb2 from '../../common/components/BreadCrumb2';
import styled from 'styled-components';
import { useAuth } from '../../contexts/auth';
import { Link } from 'react-router-dom';
import {baseUrl} from '../../config';
import axios from 'axios';
import { notiFailed, notiSuccess } from '../../utils/helpers.js';
import {Grid, TextField, MenuItem} from '@material-ui/core';
import CustomLoading from '../../common/components/CustomLoading';
import MobileCheckBox from '../../config/images/bg_checkbox_big_s.png';
import MobileCheckBoxOn from '../../config/images/bg_checkbox_big.png';
import CheckBox from '../../config/images/bg_checkbox_on.png';
import CheckBoxOn from '../../config/images/bg_checkbox.png';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import SquareButton from '../../common/components/button/MonthButton';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { sendEventForContactUs, ACTION_SEND_CONTACT } from './../../utils/gaHelpers';
import SelectIcon from '../../icons/ico_select.png';

const useStyles = makeStyles(theme => ({
  inputField: {
    '& .MuiOutlinedInput-notchedOutline': {
        transition: 'background 0.3s, border 0.3s'
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: 0
    },
    '& .MuiOutlinedInput-input': {
        padding: '13.5px 14px'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
        borderColor: 'rgba(0, 0, 0, 0.23) !important'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#000 !important',
        borderWidth: '1px'
    }
  },
  inputMessageField: {
    '& .MuiOutlinedInput-notchedOutline': {
        transition: 'background 0.3s, border 0.3s'
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: 0
    },
    '& .MuiOutlinedInput-input': {
        padding: '0px 0px'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
        borderColor: 'rgba(0, 0, 0, 0.23) !important'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#000 !important',
        borderWidth: '1px'
    }
  },
  agreeBoxLink: {
    color: '#000',
    position: 'relative',
    transition: 'color 0.4s',
    '&::before': {
        position: 'absolute',
        left: 0,
        bottom: -2,
        width: '100%',
        height: '1px',
        background: '#666', 
        content: '""'
    },
    '&::after': {
        position: 'absolute',
        left: 0,
        bottom: -2,
        width: 0,
        height: '1px',
        background: '#019ee3',
        content: '""',
        transition: 'width 0.6s 0.2s'
    },
    '&:hover': {
        color: '#019ee3',
        textDecoration: 'none'
    },
    '&:hover::before': {
        width: 0,
        transition: 'width 0.4s'
    },
    '&:hover::after': {
        width: '100%'
    }
  },
  selectBox: {
    marginLeft: '-5px',
    width: '268px',
    margin: '0 10px 0 0',
    color: '#999',
    height: '40px',
    padding: '0 20px 0 12px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
    fontFamily: 'Actual',
    fontSize: '14px',
    fontWeight: 'normal',
    verticalAlign: 'top',
    transition: 'background 0.3s, border 0.3s',
    background: `#fff url(${SelectIcon}) right center /25px 8px no-repeat`,
    '-webkit-appearance': 'none'
},
}))

const StyledButton = styled.button
  `
  width: 117px;
  height: 50px;
  background-color: #666;
  cursor: pointer;
  border: 0px;
  color: #fff;
  
  &:hover {
    background-color: #444444;
  }
`;

const RequiredSymbol = styled.span `
  display: block;
  left: 1px;
  bottom: 0;
  width: ${props => props.requiredWidth > 375 ? '18px' : '23px'};
  height: 1px;
  background: #009ee3;
  content: "";
`;

const RequiredSymbol2 = styled.span `
  position: absolute;
  left: 0;
  top: 50%;
  width: 12px;
  height: 1px;
  background: #009ee3;
  content: "";
`;

const TitleTextDiv = styled.div `
  width: 62.5vw;
  margin: auto;
  border-bottom: 1px solid #000;
  padding-bottom: 30px;

  @media only screen and (max-width: 1300px) {
    width: 90vw;
  }

  @media only screen and (max-width: 1200px) {
    width: 90vw;
  }
  @media only screen and (max-width: 600px) {
    width: 95vw;
  }
  @media only screen and (max-width: 375px) {
    margin-top: -9px;
  }
`;

const FormContainer = styled.div `
  width: 62.5vw; 
  margin: auto;
  border-bottom: 1px solid #000;
  margin-bottom: 20px;

  @media only screen and (max-width: 1300px) {
    width: 90vw;
  }

  @media only screen and (max-width: 1200px) {
    width: 90vw;
  }
  @media only screen and (max-width: 600px) {
    width: 95vw;
  }
`;

const StyledForm = styled.form `
  // width: 90vw; 
  margin: auto;
  // margin-left: -40px;

  @media only screen and (max-width: 1300px) {
    width: 90vw;
    padding: 10px 10px;
  }

  @media only screen and (max-width: 1200px) {
    width: 90vw;
    padding: 10px 10px;
  }

  @media only screen and (max-width: 600px) {
    width: 95vw;
    padding: 10px 10px;
  }
`;

const StyledTermsAndPolicyContainer = styled.div `
  width: 62.5vw; 
  height: 84px;
  margin: auto;
  background-color: #f8f8f8;
  margin-bottom: 40px;
  margin-top: -10px;
  padding-top: 10px;
  padding-bottom: 10px;

  @media only screen and (max-width: 1300px) {
    width: 90vw;
  }
  
  @media only screen and (max-width: 1200px) {
    width: 90vw;
  }
  @media only screen and (max-width: 600px) {
    width: 95vw;
  }
  @media only screen and (max-width: 375px) {
    text-align: center;
    width: 375px; 
    height: 186px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
`;

const StyledTitle = styled.p `
  font-size: 26px !important;
  font-weight: 500;
  padding-top: 20px;

  @media only screen and (max-width: 375px) {
    font-size: 16px !important;
  }
`;

const StyledCategoryText = styled.p `
  font-size: 15px !important;
  font-weight: 300;

  @media only screen and (max-width: 375px) {
    font-size: 14px !important;
    margin-top: 20px;
    color: #333333;
  }
`;

const StyledCheckboxIcon = styled.img `
  background-image: ${props => props.checked ? `url(${CheckBox})` : `url(${CheckBoxOn})`};
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  display: inline-block;
  color: #009DDD;
  float:left;
  cursor: pointer;
  transition: all .3s;
`;

export default function ContactUs() {
  const classes = useStyles();
  const user = useAuth();
  const history = useHistory();
  const location = useLocation();
  const userInfo = user.profile;
  const [formValues, setFormValues] = useState(() => {
    const title = _.get(history, 'location.state.title');
    if (title) {
      history.replace(location.pathname, { title: null })
      return {
        contactCategoryId: 5,
        styleNum: title
      }
    } 

    return {contactCategoryId: 'Please Select'}
  })
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [checked, setChecked] = useState(false);
  const [companyLogo, setCompanyLogo] = useState('');

  const [width, setWindowWidth] = useState(0)
   useEffect(() => { 

     updateDimensions();

     window.addEventListener('resize', updateDimensions);
     return () => 
       window.removeEventListener('resize',updateDimensions);
    }, [])
    const updateDimensions = () => {
      const width = window.innerWidth
      setWindowWidth(width)
    }

  const handleFormInput = (e, key) => {
    if (key === 'exceptTermsAndPolicy') {
      setFormValues({...formValues, [key]: !formValues[key]})
      setChecked(!checked);
    } else if (key === 'companyLogo') { 
      setFormValues({...formValues, [key]: _.get(e, 'target.files[0]') });
      setCompanyLogo(e.target.value);
    } else {
      setFormValues({...formValues, [key]: e.target.value})
    }
  }

  const getContactCategories = async () => {
    const {data} = await axios.get(`${baseUrl}/contact/categories`);
    
    setCategories(data);
  }

  const submitForm = async () => {
    setLoading(true)
    if (formValues.contactCategoryId === 'Please Select') {
      setLoading(false);
      return notiFailed('Please select Type of Inquiry')
    }

    if (!_.trim(formValues.firstName)) {
      setLoading(false);
      return notiFailed('Please enter your name.');
    }

    if (!_.trim(formValues.companyName)) {
      setLoading(false);
      return notiFailed('Please enter your company.');
    }

    if (!_.trim(formValues.email)) {
      setLoading(false);
      return notiFailed('Please enter your email.');
    }

    if (!/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(_.trim(formValues.email))) {
      setLoading(false);
      return notiFailed('Invalid E-mail address.');
    }

    if (!_.trim(formValues.mobileNumber)) {
      setLoading(false);
      return notiFailed('Please enter your phone/mobile number.');
    }

    if(!/^[0-9]*$/.test(_.trim(formValues.mobileNumber))){
      setLoading(false);
      return notiFailed("This entry can only contain numbers (e.g., 9497774444).");
    }

    if (formValues.contactCategoryId === 5) {
      if (!_.trim(formValues.styleNum)) {
        setLoading(false);
        return notiFailed('Please enter product & style.');
      }

      if (!_.trim(formValues.sizesColorsQty)) {
        setLoading(false);
        return notiFailed('Please enter Size/Color/Qty.');
      }

      if (!formValues.companyLogo || !['ai', 'eps'].includes(_.split(companyLogo, '.').pop())) {
        setLoading(false);
        return notiFailed('Please register company logo image with AI/EPS extension only')
      }
    } else {
      if (!_.trim(formValues.message)) {
        setLoading(false);
        return notiFailed('Please enter message.');
      }
    }

    if (!formValues.exceptTermsAndPolicy) {
      setLoading(false);
      return notiFailed('Please check [TERMS and CONDITIONS]');
    }

    const formData = new FormData();
    formData.append('contactCategoryId', formValues.contactCategoryId);
    formData.append('name', _.trim(formValues.firstName));
    formData.append('companyName', _.trim(formValues.companyName));
    formData.append('email', _.trim(formValues.email));
    formData.append('mobileNumber', _.trim(formValues.mobileNumber));
    
    if (formValues.contactCategoryId === 5) {
      formData.append('style', _.trim(formValues.styleNum));
      formData.append('message', _.trim(formValues.sizesColorsQty));
      formData.append('logo', formValues.companyLogo);
    } else {
      formData.append('message', _.trim(formValues.message));
    }

    try {
      sendEventForContactUs(ACTION_SEND_CONTACT);
      await axios.post(`${baseUrl}/contact`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      notiSuccess('Your inquiry has been submitted. A representative will address the inquiry as soon as possible. Have a wonderful day.')
      setLoading(false);
      history.push('/');
    } catch(err) {
      setErrors(err.data.errors.map( error => {
        return error['param']
      }));
      err.data.errors.forEach(err => notiFailed(err.msg))
      setLoading(false);
    }
  }

  useEffect(() => {
    getContactCategories();
  }, []);

  useEffect(() => {
    if (userInfo) {
      setFormValues({...formValues, ...userInfo})
    }
  },[userInfo, errors])

  return (
    <section
      style={{
        backgroundColor: 'var(--light-color, #ffffff)',
        position: 'relative',
      }}
    >
      <BreadCrumb2 items={['Support', 'Contact Us']}></BreadCrumb2>
      <Banner imageUrl={BannerImage} className="banner100">
        <TitleAnimation>CONTACT</TitleAnimation>
      </Banner>
      {width >= 376 ? 
      <>
      <TitleTextDiv>
        <Grid container direction="row" justify="space-between" alignItems="flex-end" style={{marginTop: '40px', marginBottom: '-5px'}}>
        <StyledTitle>
          CONTACT
        </StyledTitle>
        
        <Text size="small" style={{display: 'inline-block', position: 'relative', paddingLeft: '17px', fontSize: '14px', top: '10px'}}>
          <RequiredSymbol2 />Required
        </Text>
      </Grid>
      </TitleTextDiv>
        <FormContainer>
        <StyledForm>
          <Grid container direction="row" justify="space-between" alignItems="center" style={{padding: '6px 100px 11px 60px', borderBottom: '1px solid #f4f4f4'}}>
            <Grid item xs={3}>
            <StyledCategoryText>Inquiry Type</StyledCategoryText>
            <RequiredSymbol requiredWidth={width}/>
            </Grid>
            <Grid item xs={9}>
              <select className={classes.selectBox} onChange={(e) => handleFormInput(e, 'contactCategoryId')}>
              <option value='Please Select'>Please Select</option>
              {categories.map(category => {
                return (  
                  <option key={category.id} value={category.id}>{category.name}</option>
                )
              })}
							</select>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="space-between" alignItems="center" style={{padding: '6px 100px 11px 60px', borderBottom: '1px solid #f4f4f4'}}>
            <Grid item xs={4}>
            <Text weight="light" style={{fontSize: '15px', color: '#000'}}>Name</Text>
            <RequiredSymbol requiredWidth={width}/>
            </Grid>
            <Grid item xs={8}>
            <TextField className={classNames(classes.inputField)} InputProps={{ inputProps: { style: { padding: 12.5, borderRadius: 0, fontSize: '14px', borderColor: '#000', ":hover": {borderColor: '#000'} }, }, style: { borderRadius: 0, fontSize: '14px', borderColor: '#000' }, }} error={errors.includes('name')} disabled={userInfo.firstName} style={userInfo.firstName ? {backgroundColor: '#F8F8F8', width: '268px', marginLeft: '-70px'} : {width: '250px', marginLeft: '-70px'}} variant="outlined" margin="dense" fullWidth value={formValues.firstName} onChange={(e) => handleFormInput(e, 'firstName')}></TextField>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="space-between" alignItems="center" style={{padding: '6px 100px 11px 60px', borderBottom: '1px solid #f4f4f4'}}>
            <Grid item xs={4}>
            <Text weight="light" style={{fontSize: '15px', color: '#000'}}>Company/Organization</Text>
            <RequiredSymbol requiredWidth={width}/>
            </Grid>
            <Grid item xs={8}>
            <TextField className={classNames(classes.inputField)} InputProps={{ inputProps: { style: { padding: 12.5, borderRadius: 0, fontSize: '14px' }, }, style: { borderRadius: 0, fontSize: '14px' }, }} error={errors.includes('companyName')} disabled={userInfo.companyName} style={userInfo.firstName ? {backgroundColor: '#F8F8F8', width: '368px', marginLeft: '-70px'} : {width: '368px', marginLeft: '-70px'}} variant="outlined" margin="dense" fullWidth  value={formValues.companyName} onChange={(e) => handleFormInput(e, 'companyName')}></TextField>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="space-between" alignItems="center" style={{padding: '6px 100px 11px 60px', borderBottom: '1px solid #f4f4f4'}}>
            <Grid item xs={4}>
            <Text weight="light" style={{fontSize: '15px', color: '#000'}}>E-mail</Text>
            <RequiredSymbol requiredWidth={width}/>
            </Grid>
            <Grid item xs={8}>
            <TextField className={classNames(classes.inputField)} InputProps={{ inputProps: { style: { padding: 12.5, borderRadius: 0, fontSize: '14px' }, }, style: { borderRadius: 0, fontSize: '14px' }, }} error={errors.includes('email')} disabled={userInfo.email} style={userInfo.firstName ? {backgroundColor: '#F8F8F8', width: '368px', marginLeft: '-70px'} : {width: '368px', marginLeft: '-70px'}} variant="outlined" margin="dense" fullWidth value={formValues.email} onChange={(e) => handleFormInput(e, 'email')}></TextField>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="space-between" alignItems="center" style={{padding: '6px 100px 11px 60px', borderBottom: '1px solid #f4f4f4'}}>
            <Grid item xs={4}>
            <Text weight="light" style={{fontSize: '15px', color: '#000'}}>Phone/Mobile Number</Text>
            <RequiredSymbol requiredWidth={width}/>
            </Grid>
            <Grid item xs={8}>
            <TextField className={classNames(classes.inputField)} className={classNames(classes.inputField)} InputProps={{ inputProps: { style: { padding: 12.5, borderRadius: 0, fontSize: '14px' }, }, style: { borderRadius: 0, fontSize: '14px' }, }} type="tel" error={errors.includes('mobileNumber')} variant="outlined" margin="dense" color="#000" style={{width: '368px', marginLeft: '-70px'}} fullWidth placeholder="Numbers only (e.g., 9497774444)" value={formValues.mobileNumber} onChange={(e) => handleFormInput(e, 'mobileNumber')}></TextField>
            </Grid>
          </Grid>
          {formValues.contactCategoryId == 5 ? 
          <>
            <Grid container direction="row" justify="space-between" alignItems="center" style={{padding: '6px 100px 11px 60px', borderBottom: '1px solid #f4f4f4'}}>
              <Grid item xs={4}>
              <Text weight="light" style={{fontSize: '15px', color: '#000'}}>Style #</Text>
              <RequiredSymbol requiredWidth={width}/>
              </Grid>
              <Grid item xs={8}>
              <TextField className={classNames(classes.inputField)} className={classNames(classes.inputField)} InputProps={{ inputProps: { style: { padding: 12.5, borderRadius: 0, fontSize: '14px' }, }, style: { borderRadius: 0, fontSize: '14px' }, }} variant="outlined" margin="dense" color="#000" style={{width: '368px', marginLeft: '-70px'}} fullWidth value={formValues.styleNum} onChange={(e) => handleFormInput(e, 'styleNum')}></TextField>
              </Grid>
            </Grid>
            <Grid container direction="row" justify="space-between" alignItems="flex-start" style={{padding: '15px 100px 15px 60px', borderBottom: '1px solid #f4f4f4'}}>
              <Grid item xs={4}>
              <Text weight="light" style={{fontSize: '15px', color: '#000'}}>Size/Color/Qty</Text>
              <RequiredSymbol requiredWidth={width}/>
              </Grid>
              <Grid item xs={8}>
              <TextField helperText="** Minimum order quantity is 50 pc" placeholder="Please indicate sizes, colors, and quantities." className={classNames(classes.inputMessageField)} InputProps={{ inputProps: { style: {padding: '-20px', borderRadius: 0, fontSize: '14px' }, }, style: { padding: '-20px', borderRadius: 0, fontSize: '14px' }, }} style={{width: '808px', marginLeft: '-70px'}} multiline variant="outlined" rows={7} value={formValues.sizesColorsQty} fullWidth onChange={(e) => handleFormInput(e, 'sizesColorsQty')}></TextField>
              </Grid>
            </Grid>
            <Grid container direction="row" justify="space-between" alignItems="center" style={{padding: '6px 100px 11px 60px', borderBottom: '1px solid #f4f4f4'}}>
              <Grid item xs={4}>
              <Text weight="light" style={{fontSize: '15px', color: '#000'}}>Company Logo</Text>
              <RequiredSymbol requiredWidth={width}/>
              </Grid>
              <Grid item xs={8}>
                <TextField disabled placeholder="Upload company logo (ai/eps file only)" className={classNames(classes.inputField)} className={classNames(classes.inputField)} InputProps={{ inputProps: { style: { padding: 12.5, borderRadius: 0, fontSize: '14px' }, }, style: { borderRadius: 0, fontSize: '14px' }, }} variant="outlined" margin="dense" color="#000" style={{width: '368px', marginLeft: '-70px'}} fullWidth value={companyLogo}></TextField>
                <input
                  name={'attachment1'}
                  onChange={(e) => handleFormInput(e, 'companyLogo')}
                  accept=".ai, .eps"
                  style={{display: 'none'}}
                  id="raised-button-file-2"
                  multiple
                  type="file"
                />
                <label htmlFor="raised-button-file-2">
                  <SquareButton variant="raised" component="span" style={{marginTop: '8px', height: '40px', borderRadius: '0px'}}>
                    Search
                  </SquareButton>
                </label>
              </Grid>
            </Grid>
            </> :
            false
          }
          {formValues.contactCategoryId === 5 ? false :
          <Grid container direction="row" justify="space-between" alignItems="flex-start" style={{padding: '15px 100px 15px 60px',}}>
            <Grid item xs={4}>
            <Text weight="light" style={{fontSize: '15px', color: '#000'}}>Your Message</Text>
            <RequiredSymbol requiredWidth={width}/>
            </Grid>
            <Grid item xs={8}>
            <TextField className={classNames(classes.inputMessageField)} InputProps={{ inputProps: { style: {padding: '-20px', borderRadius: 0, fontSize: '14px' }, }, style: { padding: '-20px', borderRadius: 0, fontSize: '14px' }, }} style={{width: '808px', marginLeft: '-70px'}} error={errors.includes('message')} multiline variant="outlined" rows={7} fullWidth onChange={(e) => handleFormInput(e, 'message')}></TextField>
            </Grid>
          </Grid>
          }   
        </StyledForm>
        </FormContainer>
        <StyledTermsAndPolicyContainer>
        <Grid container alignItems="center" style={{padding: '18px 40px'}}>
        <StyledCheckboxIcon checked={checked} onClick={(e) => handleFormInput(e, 'exceptTermsAndPolicy')} />
        <Text weight="light" style={{marginLeft: '10px', fontSize: '16px'}}>I HAVE READ AND ACCEPTED <Link to="/terms" target="_blank" className={classes.agreeBoxLink}>THE TERMS AND CONDITIONS</Link> AND <Link to="/policy" target="_blank" className={classes.agreeBoxLink}>THE PRIVACY POLICY.</Link></Text>
        </Grid>
    </StyledTermsAndPolicyContainer>
    </>
        :
        <>
        <TitleTextDiv>
          <Grid container direction="row" justify="space-between" alignItems="flex-end" style={{marginTop: '28px', marginBottom: '-15px'}}>
          <StyledTitle>
            CONTACT
          </StyledTitle>
          
          <Text size="small" style={{display: 'inline-block', position: 'relative', paddingLeft: '17px', fontSize: '14px', top: '10px'}}>
            <RequiredSymbol2 />Required
          </Text>
        </Grid>
      </TitleTextDiv>
        <div style={{width: '80vw', margin: 'auto'}}>
          <StyledCategoryText>Inquiry Type</StyledCategoryText>
          <RequiredSymbol />
          <TextField style={{marginTop: '10px'}} InputProps={{ inputProps: { style: { padding: 10, borderRadius: 0, fontSize: '14px' }, }, style: { borderRadius: 0, fontSize: '14px' }, }} select variant="outlined" margin="dense" value={formValues.contactCategoryId} fullWidth onChange={(e) => handleFormInput(e, 'contactCategoryId')}>
            <MenuItem value='Please Select'>
              Please Select
            </MenuItem>
            {categories.map(category => {
              return (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              )
            })}
          </TextField>

          <StyledCategoryText>Name</StyledCategoryText>
          <RequiredSymbol />
          <TextField style={{marginTop: '10px'}} InputProps={{ inputProps: { style: { padding: 10, borderRadius: 0, fontSize: '14px', borderColor: '#000', ":hover": {borderColor: '#000'} }, }, style: { borderRadius: 0, fontSize: '14px', borderColor: '#000' }, }} error={errors.includes('name')} disabled={userInfo.firstName} style={userInfo.firstName ? {backgroundColor: '#F8F8F8'} : {}} variant="outlined" margin="dense" fullWidth value={formValues.firstName} onChange={(e) => handleFormInput(e, 'firstName')}></TextField>

          <StyledCategoryText>Company/Organization</StyledCategoryText>
          <RequiredSymbol />
          <TextField style={{marginTop: '10px'}} InputProps={{ inputProps: { style: { padding: 10, borderRadius: 0, fontSize: '14px' }, }, style: { borderRadius: 0, fontSize: '14px' }, }} error={errors.includes('companyName')} disabled={userInfo.companyName} style={userInfo.firstName ? {backgroundColor: '#F8F8F8'} : {}} variant="outlined" margin="dense" fullWidth  value={formValues.companyName} onChange={(e) => handleFormInput(e, 'companyName')}></TextField>

          <StyledCategoryText>E-mail</StyledCategoryText>
          <RequiredSymbol />
          <TextField style={{marginTop: '10px'}} InputProps={{ inputProps: { style: { padding: 10, borderRadius: 0, fontSize: '14px' }, }, style: { borderRadius: 0, fontSize: '14px' }, }} error={errors.includes('email')} disabled={userInfo.email} style={userInfo.firstName ? {backgroundColor: '#F8F8F8'} : {}} variant="outlined" margin="dense" fullWidth value={formValues.email} onChange={(e) => handleFormInput(e, 'email')}></TextField>

          <StyledCategoryText>Phone/Mobile Number</StyledCategoryText>
          <RequiredSymbol />
          <TextField style={{marginTop: '10px'}} InputProps={{ inputProps: { style: { padding: 10, borderRadius: 0, fontSize: '14px' }, }, style: { borderRadius: 0, fontSize: '14px' }, }} type="tel" error={errors.includes('mobileNumber')} variant="outlined" margin="dense" color="#000" fullWidth placeholder="Numbers only (e.g., 9497774444)" value={formValues.mobileNumber} onChange={(e) => handleFormInput(e, 'mobileNumber')}></TextField>
          
          {formValues.contactCategoryId === 5 ? 
          <>
            <StyledCategoryText>Style #</StyledCategoryText>
            <RequiredSymbol />
            <TextField style={{marginTop: '10px'}} InputProps={{ inputProps: { style: { padding: 10, borderRadius: 0, fontSize: '14px' }, }, style: { borderRadius: 0, fontSize: '14px' }, }} variant="outlined" margin="dense" color="#000" fullWidth value={formValues.styleNum} onChange={(e) => handleFormInput(e, 'styleNum')}></TextField>
            
            <StyledCategoryText>Size/Color/Qty</StyledCategoryText>
            <RequiredSymbol />
            <TextField style={{marginTop: '10px'}} InputProps={{ inputProps: { style: { padding: '-20px', borderRadius: 0, fontSize: '14px' }, }, style: { padding: '-20px', borderRadius: 0, fontSize: '14px' }, }} value={formValues.sizesColorsQty} multiline variant="outlined" rows={6} fullWidth onChange={(e) => handleFormInput(e, 'sizesColorsQty')}></TextField>

            <StyledCategoryText>Company Logo</StyledCategoryText>
            <RequiredSymbol />
            <Grid container>
              <Grid item xs={7}>
                <TextField style={{marginTop: '10px'}} InputProps={{ inputProps: { style: { padding: 10, borderRadius: 0, fontSize: '14px' }, }, style: { borderRadius: 0, fontSize: '14px' }, }} variant="outlined" margin="dense" color="#000" fullWidth value={companyLogo}></TextField>
                <input
                  name={'attachment1'}
                  onChange={(e) => handleFormInput(e, 'companyLogo')}
                  accept="image/*"
                  style={{display: 'none'}}
                  id="raised-button-file-2"
                  type="file"
                />
              </Grid>
              <Grid item xs={3}>
                <label htmlFor="raised-button-file-2">
                  <SquareButton variant="raised" component="span" style={{marginTop: '10px', height: '35px', borderRadius: '0px'}}>
                    Search
                  </SquareButton>
                </label>
              </Grid>
            </Grid>
            </> :
            false
          }
          {formValues.contactCategoryId === 5 ? false : <>
          <StyledCategoryText>Your Message</StyledCategoryText>
          <RequiredSymbol />
          <TextField style={{marginTop: '10px'}} InputProps={{ inputProps: { style: { padding: '-20px', borderRadius: 0, fontSize: '14px' }, }, style: { padding: '-20px', borderRadius: 0, fontSize: '14px' }, }} error={errors.includes('message')} multiline variant="outlined" rows={6} fullWidth onChange={(e) => handleFormInput(e, 'message')}></TextField>
          </>
          }
        </div>
        <div style={{width: '95%', margin: 'auto', borderBottom: '1px solid #000', marginTop: '30px'}}></div>
        <StyledTermsAndPolicyContainer>
        <Grid container alignItems="center" style={{padding: '18px 40px 18px 40px'}}>
        {/* <Checkbox checked={formValues.exceptTermsAndPolicy} onChange={(e) => handleFormInput(e, 'exceptTermsAndPolicy')}/> */}
        {formValues.exceptTermsAndPolicy ? 
          <img src={MobileCheckBoxOn} style={{width: '30px', color: '#009DDD', cursor: 'pointer', margin: 'auto', transition: 'opacity 2s ease-in'}} onClick={(e) => handleFormInput(e, 'exceptTermsAndPolicy')} /> :
          <img src={MobileCheckBox} style={{width: '30px', color: '#009DDD', cursor: 'pointer', margin: 'auto', transition: 'opacity 2s ease-in'}} onClick={(e) => handleFormInput(e, 'exceptTermsAndPolicy')} />
        }
        <Text weight="light" style={{marginTop: '30px',fontSize: '16px'}}>I HAVE READ AND ACCEPTED <TextButton component={Link} target="_blank" to='/terms' style={{ color: '#212121', marginBottom: '2px', fontSize: '13px'}}>THE TERMS AND CONDITIONS</TextButton> AND <TextButton component={Link} to='/policy' target="_blank" style={{color: '#212121', marginBottom: '2px', fontSize: '13px'}}>THE PRIVACY POLICY.</TextButton></Text>
        </Grid>
        </StyledTermsAndPolicyContainer>
        </>
    }
      <div style={{textAlign: 'center', margin: 'auto', width: '50vw', marginBottom: '55px'}}>
        <StyledButton style={{fontSize: '16px', fontWeight: '600'}} disabled={loading} onClick={() => submitForm()}>{!loading ? 'Send' : <CustomLoading size={20} color="#fff" /> }</StyledButton>
      </div>
    </section>
  )
}