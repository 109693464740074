import React, {useState, useEffect} from 'react';
import parse from 'html-react-parser'
import {useParams} from 'react-router-dom';
import Banner from '../../common/components/Banner';
import BannerImage from '../../config/images/visual_area01_2.jpg';
import TitleAnimation from '../../common/components/TitleAnimation';
import BreadCrumb2 from '../../common/components/BreadCrumb2';
import Text from '../../common/components/text';
import styled from 'styled-components';
import axios from 'axios';
import {baseUrl} from '../../config/index';
import moment from 'moment';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
import RightArrowIcon from '@material-ui/icons/ChevronRight';
import SquareButton from '../../common/components/button/MonthButton';
import { Link } from 'react-router-dom';
import {Grid} from '@material-ui/core';
import DownloadIcon from '../../config/images/ico_download.png';
import { makeStyles } from '@material-ui/core/styles';
import { notiFailed } from '../../utils/helpers';
import { sendEventForNotice, ACTION_DOWNLOAD_NOTICE_DETAIL } from '../../utils/gaHelpers';
import config from '../../config'
import CustomLoading from '../../common/components/CustomLoading';
import _ from 'lodash';
import './index.css';

const useStyles = makeStyles(theme => ({
  agreeBoxLink: {
    color: '#000',
    position: 'relative',
    transition: 'color 0.4s',
    '&::before': {
        position: 'absolute',
        left: 0,
        bottom: -2,
        height: '1px',
        background: '#666',
        content: '""'
    },
    '&::after': {
        position: 'absolute',
        left: 0,
        bottom: -2,
        width: 0,
        height: '1px',
        background: '#019ee3',
        content: '""',
        transition: 'width 0.6s 0.2s'
    },
    '&:hover': {
        color: '#019ee3',
        textDecoration: 'none'
    },
    '&:hover::before': {
        width: 0,
        transition: 'width 0.4s'
    },
    '&:hover::after': {
        width: '100%'
    }
  },

}))

const StyledButton = styled.button `
  background-color: #666;
  color: #fff;
  cursor: pointer;
  border: 0px;
  width: 110px;
  height: 50px;
  transition: all 0.4s;

  :hover {
    background-color: #444444;
  }

  @media only screen and (max-width: 375px) {
    width: 100px;
    height: 40px;
  }
`;
const StyledButtonPrevious = styled.button `
  background-color: #fff;
  color: #000;
  cursor: pointer;
  border: 0px;
  width: 110px;
  height: 50px;
  margin-right: 10px;
  border: 1px solid #000;

  :hover {
    border: 2px solid #000;
    font-weight: 500;
  }
`;

const Container = styled.div `
  width: 1200px;
  margin: auto;
  margin-top: 40px;

  @media only screen and (max-width: 1200px) {
    width: 90vw;
  }

  @media (max-width: 600px) {
    width: 100vw;
    padding: 20px;
  }
`;

const Content = styled.div `
  margin-left: 0px;
  padding: 40px 60px;
  border-bottom: 1px solid #000;
`;

const StyledTitle = styled.p `
  font-size: 26px !important;
  font-weight: 500;
  padding-top: 20px;

  @media only screen and (max-width: 375px) {
    font-size: 16px !important;
    margin-top: -40px;
    margin-bottom: 10px;
  }
`;

const StyledATag = styled.a `
  color: #666;
  font-size: 16px;
  padding-left: 40px !important;

  :hover {
    color: #009FE2;
  }

  @media only screen and (max-width: 375px) {
    font-size: 12px;
  }
`

export default function News() {
  const classes = useStyles();
  const { newsId } = useParams();
  const [noticeData, setNoticeData] = useState({});
  const [prevDetail, setPrevDetail] = useState();
  const [nextDetail, setNextDetail] = useState();
  const [allNoticeData, setAllNoticeData] = useState([]);
  const [width, setWindowWidth] = useState(0);
  const [loading, setLoading] = useState(false);
  const getNoticeData = async () => {
    setLoading(true)
    try {
      const {data} = await axios.get(`${baseUrl}/notice/detail/${newsId}?view=true`)

      setNoticeData(data);

    }catch (error) {
      console.log(error)
    }finally {
      setLoading(false);
    }

  }

  const getData = async () => {
    const {data} = await axios.get(`${baseUrl}/notice/search?offset=0`)

    const indexOfNextDetail = data.data.findIndex(x => x.id === +newsId) + 1;
    const indexOfPrevDetail = data.data.findIndex(x => x.id === +newsId) - 1;

    setAllNoticeData(data.data);
    setNextDetail(indexOfNextDetail);
    setPrevDetail(indexOfPrevDetail);
  }

  const handleClick = (id) => {
    const detail = allNoticeData[id];
    window.location = `/notice/${detail.id}`
  }

  const productDescHtml = (data) => {
    const parseedData = parse(`${data}`);
    return typeof(parseedData) === 'object' ? parse(`${data}`) : parse(parseedData);
  };

  const handleDownloadAttachment = async (e, url, name) => {
    sendEventForNotice(ACTION_DOWNLOAD_NOTICE_DETAIL);
    e.preventDefault();

    const instance = axios.create();
    delete instance.defaults.headers.common["Authorization"];

    try {
      const response = await instance({ url, method: 'GET', responseType: 'blob' });
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      notiFailed(`There was a problem download attachment ${err.message}`);
    }
  }

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () =>
      window.removeEventListener('resize',updateDimensions);
   }, [])
   const updateDimensions = () => {
     const width = window.innerWidth
     setWindowWidth(width)
   }

  useEffect(()=> {
    getNoticeData();
    getData();
  }, []);

  return (
    <section
      style={{
        backgroundColor: 'var(--light-color, #ffffff)',
        position: 'relative',
        paddingBottom: '100px'
      }}
    >
      <BreadCrumb2 items={['News', 'Notice']}/>
      <Banner imageUrl={BannerImage} className="banner100">
        <TitleAnimation>NOTICE</TitleAnimation>
      </Banner>

      <Container>
        <StyledTitle>NOTICE</StyledTitle>
        {loading ? false :
        <div style={{ marginTop: '40px', backgroundColor: '#F4F4F4', borderTop: '1px solid #000', borderBottom: '1px solid #DDDDDD'}}>
          <div style={{padding: '20px 40px'}}>
          <Text size='h4' weight="medium">{noticeData.title}</Text>
          <Text size="small" weight="light">{moment(noticeData.createdAt).format('MM[-]DD[-]YYYY')}</Text>
          </div>
        </div>
        }
        {loading ? <div style={{width: '100%', margin: 'auto', textAlign: 'center'}}>
          <CustomLoading size={40} color="#000" />
        </div> : false}

        {loading ? false :
        <div style={{ marginTop: '20px', paddingBottom: '40px'}}>
        <Content>
          {noticeData.video ? <iframe height="553" src={noticeData.video} style={{width:"56.25vw", paddingTop: '20px'}} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe> : false}
          {!!_.get(noticeData, 'image.assetUrl', '') && <img style={{marginBottom : 20}} src={_.get(noticeData, 'image.assetUrl', '')} alt=""/>}
          <Text style={{wordBreak : 'break-all'}}>
            {productDescHtml(noticeData.content)}
          </Text>
          {noticeData.attachment1 ? <Link onClick={e => handleDownloadAttachment(e, noticeData.attachment1.assetUrl, noticeData.attachment1.assetKey)} to={noticeData.attachment1.assetUrl} className={classes.agreeBoxLink}><img src={DownloadIcon} style={{position: 'relative', left: 0, top: 15, color: '#000'}} /><span className={'link-down-image'} style={{paddingLeft: 20}}>{noticeData.attachment1.assetKey}</span></Link> : false}
          <br />
          {noticeData.attachment2 ? <Link onClick={e => handleDownloadAttachment(e, noticeData.attachment2.assetUrl, noticeData.attachment2.assetKey)} to={noticeData.attachment2.assetUrl} className={classes.agreeBoxLink}><img src={DownloadIcon} style={{position: 'relative', left: 0, top: 15, color: '#000'}} /><span className={'link-down-image'} style={{paddingLeft: 20}}>{noticeData.attachment2.assetKey}</span></Link> : false }
        </Content>
        </div>
        }
        {loading ? false : <>
        {width >= 376 ? <div style={{ marginTop: '20px'}}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              { prevDetail === -1 ? false : <StyledButtonPrevious style={{fontSize: '16px'}} onClick={() => handleClick(prevDetail)}>Previous</StyledButtonPrevious> }
              { nextDetail > allNoticeData.length - 1 ? false : <StyledButton style={{fontSize: '16px'}} onClick={() => handleClick(nextDetail)}>Next</StyledButton> }
            </Grid>
            <Grid item>
              <StyledButton onClick={() => window.location = '/notice'}>List</StyledButton>
            </Grid>
          </Grid>
        </div> :

        <Grid container justify="space-between">
          <Grid item>
          <SquareButton
            square
            // disabled={+pagenumber === 1}
            style={{ margin: '0px', width: '40px', height: '40px', borderColor: '#cccccc' }}
          >
            <Link
              onClick={() => handleClick(prevDetail)}
              style={{ textDecoration: 'none', color: '#8c8c8c' }}
            >
              <LeftArrowIcon />
            </Link>
          </SquareButton>
      <SquareButton
            square
            // disabled={pageNumbers[pageNumbers.length - 1] === +pagenumber}
            style={{ margin: '0px', width: '40px', height: '40px', borderColor: '#cccccc' }}
          >
            <Link
              onClick={() => handleClick(nextDetail)}
              style={{ textDecoration: 'none', color: '#8c8c8c' }}
            >
              <RightArrowIcon />
            </Link>
          </SquareButton>
          </Grid>
            <Grid item>
              <StyledButton onClick={() => window.location = '/notice'}>List</StyledButton>
            </Grid>
        </Grid>}
        </>
        }

      </Container>

    </section>
  )
}
