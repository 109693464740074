import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// <h1 style={{ fontSize: '48px', fontWeight: 600 }}>Rewards History</h1>

const BreadCrumbClaim = ({ items, type = '' }) => (
  <nav style={{zIndex : 3}} className={`breadcrumb2 ${type === 'form' && 'form'}`}>
    <div className={`breadcrumb_cont23`}>
      <ul>
        <li className="li home">HOME</li>
        {items?.map((item, index) => (
          <li key={index} className={`li ${type === 'form' && 'form'}`} dangerouslySetInnerHTML={{__html: item}} />
        ))}
      </ul>
    </div>
  </nav>
);

BreadCrumbClaim.propTypes = {
  items: PropTypes.any.isRequired
};

export default BreadCrumbClaim;
