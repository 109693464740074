import React from 'react';
import Banner from '../../../common/components/Banner';
import useStyles from './useStyles';
import BreadCrumbClaim from '../../../common/components/BreadCrumbClaim';
import CustomButton from '../../../common/components/button'
import {useHistory} from 'react-router-dom';
import IconArrowLeft from '../../../icons/arrowLeft.svg'
import { useAuth } from '../../../contexts/auth';

function BannerDetail() {
  const classes = useStyles();
  const history = useHistory();
  const user = useAuth();

  return(
    <div className={classes.bannerDetail}
    >
      <CustomButton color="secondary" style={{minWidth: 'auto', background: 'none', width: '78px', height: '39px', zIndex : 4}} className="saveDraftBtn" onClick={() => history.push('/claim/history')}>
        <img src={IconArrowLeft} style={{marginRight: 8}}/> Back</CustomButton>
      <BreadCrumbClaim type={'form'} items={[`${user.firstName} ${user.lastName}`, 'CLAIM HISTORY', 'CLAIM DETAILS']}/>
    </div>
  )
}

export default BannerDetail;
