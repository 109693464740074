export const keyEss = [
  'claimType', 'type',
  'description', 'errorLog', 'nameProject', 'submitterEmail',
  'projectId', 'projectNameSys', 'projectInverterPower', 'isUploadSerialNumber', 'isUploadProofDate',
  'projectAddress', 'projectInstallationDate',
  'projectPermissionDate', 'projectSystemType',
  'projectPowerClass', 'projectProductName',
  'projectNumberOfPanel', 'projectAddressManual', 'projectCityManual', 'projectZipCodeManual', 'projectStateManual',
  'numberOfBattery', 'totalPowerInstaller',
  'invoiceForLaborCost', 'infoBank' , 'purchasingChannel', 'nameOfPurchasingChannelDistributor', 'nameOfChanelOtherDistributor',
  'nameOfPurchasingChannelOther', 'nameOfPurchasingChannelSelect',
];
