import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/auth';
import { useHistory } from 'react-router-dom';
import InformationClaimType from '../components/InformationClaimType';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaEss } from '../components/schemaEss';
import _ from 'lodash';
import IntroductionClaim from '../components/IntroductionClaim';
import ProgressBar from '../components/ProgressBar';
import Notification from '../components/Notification';
import styled from 'styled-components';
import EssPage from './ess';
import { defaultStateEss } from '../components/defaultStateEss';
import PvPage from './pv';
import { defaultStatePv } from '../components/defaultStatePv';
import { schemaPV } from '../components/schemaPv';
import { claimService, projectService } from '../../../services';
import axios from '../../../utils/axios';
import { notiFailed } from '../../../utils/helpers';
import {baseUrl} from '../../../config';
import PurchaseDetail from '../components/PurchaseDetail';
import {useParams, useLocation} from 'react-router-dom';
import { schemaIn } from '../components/schemaInformation';
import CustomLoading from '../../../common/components/CustomLoading';
import useStyles from '../components/useStyles';
import useSWR from 'swr'


function AbilityFormClaimEdit() {
  const user = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const {type, id, step} = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [projects, setProject] = useState([]);
  const [isPickClaimType, setIsPickClaimType] = useState(true);
  const [loadingDetail, setLoadingDetail] = useState(false);
  // const [initialStateDefaultForm, setInitialStateDefaultForm] = useState(defaultStateEss);
  const [mainPurchasingChannel, setMainPurchasingChannel] = useState([]);
  const [schemaClaim, setSchemaClaim] = useState({});
  const [modelModule, setModelModules] = useState([]);
  const [powerClassesModules, setPowerClassesModules] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [fileErrorLog, setFileErrorLog] = useState([]);
  const [fileEvidence, setFileEvidence] = useState([]);
  const [fileLaborWarranty, setFileLaborWarranty] = useState([]);
  const [filePermissionOfDate, setFilePermissionOfDate] = useState([]);
  const [fileInvoiceLabor, setFileInvoiceLabor] = useState([]);
  const [fileInfoBank, setFileInfoBank] = useState([]);
  const [keyUpload, setKeyUpload] = useState('');
  const [saleRegions, setSaleRegions] = useState([]);

  // const [filePermissionOfDate, setFilePermissionOfDate] = useState([]);
  // const [fileLaborWarranty, setFileLaborWarranty] = useState([]);
  const [fileProofOfPermissionToOperateDate, setFileProofOfPermissionToOperateDate] = useState([]);
  const [fileProofOfPermission, setFileProofOfPermission] = useState([]);
  const [filePictureOfAffectedModule, setFilePictureOfAffectedModule] = useState([]);
  const [filePictureOfVoc, setFilePictureOfVoc] = useState([]);
  const [filePictureOfFront, setFilePictureOfFront] = useState([]);
  const [filePictureOfBack, setFilePictureOfBack] = useState([]);
  const [filePictureCloseUp, setFilePictureCloseUp] = useState([]);
  const [filePictureSitePlan, setFilePictureSitePlan] = useState([]);
  const [filePictureOfOverall, setFilePictureOfOverall] = useState([]);
  const [filePictureOfWireManagement, setFilePictureOfWireManagement] = useState([]);
  const [filePictureProofDelivery, setFilePictureProofDelivery] = useState([]);
  const [filePictureImmediately, setFilePictureImmediately] = useState([]);
  const [filePictureOfVmpp, setFilePictureOfVmpp] = useState([]);
  const [filePictureIr, setFilePictureIr] = useState([]);
  const [filePictureInVoice, setFilePictureInVoice] = useState([])
  const [fileBankAccountInfo, setFileBankAccountInfo] = useState([]);
  const [fileInvoiceForLaborReimbursement, setFileInvoiceForLaborReimbursement] = useState([]);
  const [serials, setSerials] = useState([]);
  const [urlImageProof, setFilesProof] = useState([]);
  const [listProductPti, setListProductPti] = useState([]);
  const [listSerial, setListSerial] = useState([]);
  const [loadingDraft, setLoadingDraft] = useState(true);

  const { register, handleSubmit, errors, control, watch, setValue, getValues, formState, clearErrors, trigger } = useForm({
    resolver : yupResolver(schemaIn),
    shouldUnregister: false,
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: false,
    enableReinitialize: true,
    defaultValues: {
      installerEmail : _.get(user, 'email'),
      installerName : _.get(user , 'fullName'),
      installerContact : _.get(user , 'fullName'),
      installerCity : '',
      installerAddress1 : '',
      installerAddress2 : '',
      installerState : 'select',
      installerZipcode : '',
      installerPhone : '',
      checkedEmail : false,
      infoClaimName : 'select',
      claimType : 'select',
    }
  });
  const { register : registerPv, handleSubmit : handleSubmitPv, errors : errorPv, control : controlPv, watch : watchPv, setValue : setValuePv, getValues : getValuesPv, formState: formStatePv, trigger: triggerPv ,clearErrors : clearErrorsPv } = useForm({
    resolver : yupResolver(schemaPV),
    mode: "onChange",
    reValidateMode: "onChange",
    shouldUnregister: false,
    shouldFocusError: false,
    enableReinitialize: true,
    defaultValues: {
      installerEmail : _.get(user, 'email'),
      installerName : _.get(user , 'fullName'),
      installerContact : _.get(user , 'fullName'),
      installerCity : '',
      installerAddress1 : '',
      installerAddress2 : '',
      installerState : 'select',
      installerZipcode : '',
      checkedEmail : false,
      infoClaimName : 'PV',
      info_claim_type : 'select',
      projectId : null,
      ...defaultStatePv,
      installation_date_pv : null,
      comission_date_pv : null,
      affected_module_serial_number : 'select',
      region_pv : 'select',
      site_module_layout_pv : '',
      inverter_type_pv : 'select',
      region : '',
      purchase_date_info : null,
      chosePermissionDate : '1',
      choseAffectedSerialNumber : '1',
      serials : [],
      choseWebAddress : '1',
      chooseRefund: '1',
      choseTypeProject : '1',
    }
  });
  const { register : registerEss, handleSubmit : handleSubmitEss, errors : errorEss, control: controlEss, watch : watchEss, trigger : triggerEss ,setValue : setValueEss, getValues : getValuesEss, formState : formStateEss, clearErrors : clearErrorsEss } = useForm({
    resolver : yupResolver(schemaEss),
    mode: "onChange",
    reValidateMode: "onChange",
    shouldUnregister: false,
    shouldFocusError: false,
    enableReinitialize: true,
    defaultValues: {
      installerEmail : _.get(user, 'email'),
      installerName : _.get(user , 'fullName'),
      installerContact : _.get(user , 'fullName'),
      installerCity : '',
      installerAddress1 : '',
      installerAddress2 : '',
      installerState : 'select',
      installerZipcode : '',
      installerPhone : '',
      checkedEmail : false,

      infoClaimName : 'ESS',
      claimType : '2',
      errorLogName : '',
      projectId : null,
      ...defaultStateEss,
      installation_date_pv : null,
      comission_date_pv : null,
      affected_module_serial_number : 'select',
      region_pv : 'select',
      site_module_layout_pv : 'select',
      inverter_type_pv : 'select',
      purchase_date_info : null
    }
  });

  const claimType = watch('type');
  const claimInfoName = watch('infoClaimName');
  const checkedEmail = watch('checkedEmail');
  const submitterEmail = watch('submitterEmail');
  const pictureOfModuleLabel = watchPv('pictureOfModuleLabel');


  function setGroupPv(url) {

    let typeGroup = '';

    if(/^.*picture_of_affected_module_labels.*$/.test(url)) {
      typeGroup = 1
    }

    if(/^.*picture_of_front_of_module.*$/.test(url)) {
      typeGroup = 2
    }

    if(/^.*picture_of_voc_reading.*$/.test(url)) {
      typeGroup = 3
    }

    if(/^.*close_up_picture_of_physical_defect.*$/.test(url)) {
      typeGroup = 4
    }

    if(/^.*site_module_layout_plan.*$/.test(url)) {
      typeGroup = 5
    }

    if(/^.*picture_of_overall_roof_area.*$/.test(url)) {
      typeGroup = 6
    }

    if(/^.*picture_of_wire_management.*$/.test(url)) {
      typeGroup = 7
    }

    if(/^.*proof_of_delivery_document.*$/.test(url)) {
      typeGroup = 8
    }

    if(/^.*picture_of_module_immediately_upon_receipt.*$/.test(url)) {
      typeGroup = 9
    }

    if(/^.*picture_of_vmpp.*$/.test(url)) {
      typeGroup = 10
    }


    if(/^.*ir_picture.*$/.test(url)) {
      typeGroup = 11
    }

    if(/^.*copy_of_invoicereceipt.*$/.test(url)) {
      typeGroup = 12
    }

    if(/^.*invoice_for_labor_cost_reimbursement.*$/.test(url)) {
      typeGroup = 13
    }

    if(/^.*bank_account_information.*$/.test(url)) {
      typeGroup = 14
    }

    if(/^.*picture_of_back_of_module.*$/.test(url)) {
      typeGroup = 15
    }

    if(/^.*proof_of_permission_to_operate_date.*$/.test(url)) {
      typeGroup = 16
    }


    const actions = {
      1 : 'affectModule',
      2 : 'fontOfModule',
      3 : 'vocReading',
      4 : 'physicalDefect',
      5 : 'sitePlan',
      6 : 'overRall',
      7 : 'wireManagement',
      8 : 'proofDelivery',
      9 : 'immedDiately',
      10 : 'offVmpp',
      11 : 'irPicture',
      12 : 'coppyReceipt',
      13 : 'laborCost',
      14 : 'bankAccount',
      15 : 'backOfModule',
      16 : 'proofOfPermission',
      'default' : 'errorLog'
    }
    return actions[typeGroup] || actions['default'];
  }

  function suggestionsImagePv(strImage) {
    const arrayImage = strImage?.split(',').map(item => {
      return {
        url : item,
        group : setGroupPv(item)
      }
    });

    return _.groupBy(arrayImage, 'group');

  }

  function setGroupEss(url) {
    let typeGroup = '';

    if(/^.*error_logs.*$/.test(url)) {
      typeGroup = 1
    }

    if(/^.*other_evidence.*$/.test(url)) {
      typeGroup = 2
    }

    if(/^.*invoice_for_labor_cost_reimbursement.*$/.test(url)) {
      typeGroup = 3
    }

    if(/^.*bank_account_information.*$/.test(url)) {
      typeGroup = 4
    }

    if(/^.*proof_of_permission_to_operate_date.*$/.test(url)) {
      typeGroup = 5
    }

    const actions = {
      1 : 'errorLogImage',
      2 : 'otherEvidence',
      3 : 'laborCost',
      4 : 'bankAccount',
      5 : 'proofOfPermission',

      'default' : 'errorLog'
    }
    return actions[typeGroup] || actions['default'];

  }
  function suggestionsImageEss(strImage) {
    const arrayImage = strImage?.split(',').map(item => {
      return {
        url : item,
        group : setGroupEss(item)
      }
    });

    return _.groupBy(arrayImage, 'group');
  }


  async function getDetailClaim() {

    const info = await claimService.getDetailClaim(id);
    const { data } = await axios.get(`${baseUrl}/distributors`);

    setDistributors(data);

    setValue('submitterEmail', _.get(info.data, 'draftData.submitterEmail', ''))
    setValue('checkedEmail', _.get(info.data, 'draftData.checkedEmail', false))
    setValue('type', _.get(info.data, 'draftData.type', 'select'))
    setValue('claimType', _.get(info.data, 'draftData.claimType', 'select'))

    if(info.data.type === '2') {
      //ess

      // setValueEss('installerCity', _.get(info.data, 'draftData.installerCity'))
      // setValueEss('installerName', _.get(info.data, 'draftData.installerName'))
      // setValueEss('installerAddress1', _.get(info.data, 'draftData.installerAddress1'))
      // setValueEss('installerState', _.get(info.data, 'draftData.installerState'))
      // setValueEss('installerContact', _.get(info.data, 'draftData.installerContact'))
      // setValueEss('installerZipcode', _.get(info.data, 'draftData.installerZipcode'))
      // setValueEss('installerEmail', _.get(info.data, 'draftData.installerEmail'))
      // setValueEss('installerAddress2', _.get(info.data, 'draftData.installerAddress2'))
      // setValueEss('installerPhone', _.get(info.data, 'draftData.installerPhone'))

      setValueEss('checkedEmail', _.get(info.data, 'draftData.checkedEmail'))
      setValueEss('submitterEmail', _.get(info.data, 'draftData.submitterEmail'))
      setValueEss('claimType', _.get(info.data, 'draftData.claimType', 'select'))
      setValueEss('type', _.get(info.data, 'draftData.type', 'select'))
      setValueEss('description', _.get(info.data, 'draftData.description'))
      setValueEss('errorLogName', _.get(info.data, 'draftData.errorLogName'))
      setValueEss('choseTypeProject', _.get(info.data, 'draftData.choseTypeProject'))
      setValueEss('laborReimbursement', _.get(info.data, 'draftData.laborReimbursement', false))
      // setValuePv('projectId', _.get(info.data, 'draftData.projectInfo', null))
      if(_.get(info.data, 'draftData.choseTypeProject') === '2') {
        setValueEss('projectAddress', _.get(info.data, 'draftData.projectAddress', ''))
        setValueEss('projectAddressManual', _.get(info.data, 'draftData.projectAddressManual', null))
        setValueEss('projectAddress2Manual', _.get(info.data, 'draftData.projectAddress2Manual', null))
        setValueEss('projectInstallationDate', _.get(info.data, 'draftData.projectInstallationDate', null))
        setValueEss('projectProductName', _.get(info.data, 'draftData.projectProductName', 'select'))
        setValueEss('projectSystemType', _.get(info.data, 'draftData.projectSystemType', ''))
        setValueEss('projectInverterSerialNumber', _.get(info.data, 'draftData.projectInverterSerialNumber', ''))
        setValueEss('projectInverterPower', _.get(info.data, 'draftData.projectInverterPower', ''))
      }


      if(_.get(info.data, 'draftData.projectId', null)) {
        const projectData  = await projectService.getDetailProject(_.get(info.data, 'draftData.projectId', null));
        let projectInfo = projectData?.data;
        setValueEss('projectInfo', projectInfo)

        setValueEss('laborWarranty', _.get(projectInfo, 'qualifyLaborWarranty', false))
        //setValue('projectInverterPower', projectInfo?.systemSize);
        //setValue('laborReimbursement', _.get(projectInfo, 'laborReimbursement', false))

        setValueEss('panelLaborReimbursement', _.get(projectInfo, 'panelLaborReimbursement', false))
        setValueEss('batteryLaborReimbursement', _.get(projectInfo, 'batteryLaborReimbursement', false))
        setValueEss('batteryQualified', _.get(projectInfo, 'batteryQualified', false))
        if(_.get(projectInfo, 'batteryLaborReimbursement', false)) {
          setValueEss('laborReimbursement', true)
        }

        if(_.get( projectInfo, 'batteryLaborReimbursement', false) && _.get(projectInfo, 'qualifyLaborWarranty', false)) {
          setValueEss('validateProofOfDate', true)
        }

      }

      const uniqModuleSerialNumber = _.filter(_.get(info.data, 'draftData.projectInfo.serialNumbers'), s => s.type === 'inverter');
      setValueEss('projectSystemType', _.get(info.data, 'draftData.projectSystemType', ''))
      setValueEss('projectInstallationDate', _.get(info.data, 'draftData.projectInstallationDate', ''))

      if(uniqModuleSerialNumber.length > 0) {
        setValueEss('projectProductName', uniqModuleSerialNumber?.length > 0 ? uniqModuleSerialNumber[0].productName : '');
        setValueEss('projectInverterSerialNumber', uniqModuleSerialNumber.length > 0 ? uniqModuleSerialNumber[0].serialNumber : '');
        setValueEss('projectInverterPower',uniqModuleSerialNumber?.length > 0 ? (uniqModuleSerialNumber[0].volume / 1000) : '' );
      }else {
        setValueEss('projectInverterPower', info?.data?.batteryInverterPower)
        setValueEss('projectInverterSerialNumber', '')
        setValueEss('projectProductName', info?.data?.batteryModel)

      }

      setValueEss('purchasingChannel', _.get(info.data, 'draftData.purchasingChannel'));
      if (_.get(info.data, 'draftData.purchasingChannel') === 'distributor') {
        setValueEss('nameOfPurchasingChannelDistributor', _.get(info.data, 'draftData.nameOfPurchasingChannelDistributor'))
        setValueEss('nameOfChanelOtherDistributor', _.get(info.data, 'draftData.nameOfChanelOtherDistributor'));
      } else if (_.get(info.data, 'draftData.purchasingChannel') === 'other') {
        setValueEss('nameOfPurchasingChannelOther', _.get(info.data , 'draftData.nameOfPurchasingChannel'));
      }


      setValueEss('pocketWifiSys', _.get(info.data, 'draftData.pocketWifiSys', null))
      setValueEss('batterySerialNumber', _.get(info.data, 'draftData.batterySerialNumber', ''))
      setValueEss('batteryType', _.get(info.data, 'draftData.batteryType', 'select'))
      setValueEss('numberOfBattery', _.get(info.data, 'draftData.numberOfBattery', 'select'))
      setValueEss('dsp1SwVersion', _.get(info.data, 'draftData.dsp1SwVersion', ''))
      setValueEss('armSwVersion', _.get(info.data, 'draftData.armSwVersion', ''))
      setValueEss('batterySwVersion', _.get(info.data, 'draftData.batterySwVersion', ''))
      setValueEss('criticalLoadsConnected', _.get(info.data, 'draftData.criticalLoadsConnected', ''))
      setValueEss('totalPvPowerInstalled', _.get(info.data, 'draftData.totalPvPowerInstalled', ''))


      setValueEss('projectSys', _.get(info.data, 'draftData.projectSys'))
      setValueEss('inverterPowerSys', _.get(info.data, 'draftData.inverterPowerSys'))
      setValueEss('batterySerialNumberSys', _.get(info.data, 'draftData.batterySerialNumberSys'))
      setValueEss('batteryTypeSys', _.get(info.data, 'draftData.batteryTypeSys'))
      setValueEss('numberOfBattery', _.get(info.data, 'draftData.numberOfBattery'))
      setValueEss('armSwVersion', _.get(info.data, 'draftData.armSwVersion'))
      setValueEss('batterySwVersion', _.get(info.data, 'draftData.batterySwVersion'))
      setValueEss('pv1', _.get(info.data, 'draftData.pv1'))
      setValueEss('pv2', _.get(info.data, 'draftData.pv2'))
      setValueEss('pv3', _.get(info.data, 'draftData.pv3'))
      setValueEss('pv4', _.get(info.data, 'draftData.pv4'))
      setValueEss('loadL1Voltage', _.get(info.data, 'draftData.loadL1Voltage'))
      setValueEss('loadL2Voltage', _.get(info.data, 'draftData.loadL2Voltage'))
      setValueEss('criticalConnected', _.get(info.data, 'draftData.criticalConnected'))
      setValueEss('totalPvPowerInstalled', _.get(info.data, 'draftData.totalPvPowerInstalled'))
      setValueEss('operationalModeUse', _.get(info.data, 'draftData.operationalModeUse'))
      setValueEss('choseTypeSerialUpload', _.get(info.data, 'draftData.choseTypeSerialUpload', '1'))
      setValueEss('serialNumbers', _.get(info.data, 'draftData.serialNumbers', []))
      setSerials(_.get(info.data, 'draftData.listSerialPick', []))
      setValueEss('listSerialPick', _.get(info.data, 'draftData.listSerialPick', []))
      //setListSerial(_.get(info.data, 'draftData.listSerialPick', []));
      const moduleSN = _.filter(_.get(info.data, 'draftData.projectInfo.serialNumbers'), s => s.type === 'inverter');
      setListSerial(moduleSN)

      setValueEss('distributorName', _.get(info.data, 'draftData.distributorName'))
      setValueEss('distributorCity', _.get(info.data, 'draftData.distributorCity'))
      setValueEss('poNumber', _.get(info.data, 'draftData.poNumber'))
      setValueEss('purchaseDate', _.get(info.data, 'draftData.purchaseDate', null))
      setValueEss('projectNameSys' , _.get(info.data, 'draftData.projectNameSys', ''));
      setValueEss('addressSys' , _.get(info.data, 'draftData.addressSys', ''));
      setValueEss('installationDateSys' , _.get(info.data, 'draftData.installationDateSys', null));
      setValueEss('systemTypeSys' , _.get(info.data, 'draftData.systemTypeSys', ''));
      setValueEss('productNameSys' , _.get(info.data, 'draftData.productNameSys', ''));
      setValueEss('distributorName' , _.get(info.data, 'draftData.distributorName', ''));
      setValueEss('totalPvPowerInstalled', _.get(info.data, 'draftData.totalPvPowerInstalled', ''));
      setKeyUpload(_.get(info.data, 'draftData.keyUpload', ''))
      const makeData = suggestionsImageEss(_.get(info.data, 'draftData.attachments'))

      setFileErrorLog(makeData?.errorLogImage || [])
      setFileEvidence(makeData?.otherEvidence || [])
      setFileInvoiceLabor(makeData?.laborCost || [])
      setFileInfoBank(makeData?.bankAccount || [])
      setFilePermissionOfDate(makeData?.proofOfPermission || [])

    }

    if(info.data.type === '1') {
      setValuePv('submitterEmail', _.get(info.data, 'draftData.submitterEmail', ''))
      //setValue('submitterEmail', _.get(info.data, 'draftData.submitterEmail', ''))
      setValuePv('checkedEmail', _.get(info.data, 'draftData.checkedEmail', false))
      //setValue('checkedEmail', _.get(info.data, 'draftData.checkedEmail', false))
      setValuePv('type', _.get(info.data, 'draftData.type', 'select'))
      setValuePv('claimType', _.get(info.data, 'draftData.claimType', 'select'))
      setValuePv('choseTypeProject', _.get(info.data, 'draftData.choseTypeProject', '1'))


      if(_.get(info.data, 'draftData.projectId', null)) {
        const projectData  = await projectService.getDetailProject(_.get(info.data, 'draftData.projectId', null));
        let projectInfo = projectData?.data;
        setValuePv('projectInfo', projectInfo)
        setValuePv('region', _.get(projectInfo, 'region', 'US'));
        //setValue('laborReimbursement', _.get(projectInfo, 'laborReimbursement', false))
        setValuePv('laborWarranty', _.get(projectInfo, 'qualifyLaborWarranty', false))
        setValuePv('panelLaborReimbursement', _.get(projectInfo, 'panelLaborReimbursement', false))
        setValuePv('batteryLaborReimbursement', _.get(projectInfo, 'batteryLaborReimbursement', false))
        setValuePv('panelQualified', _.get(projectInfo, 'panelQualified', false))


        if(_.get(projectInfo, 'panelLaborReimbursement', false)) {
          setValuePv('laborReimbursement', true)
        }

        if(_.get(projectInfo, 'panelLaborReimbursement', false) && _.get(projectInfo, 'qualifyLaborWarranty', false)) {
          setValuePv('validateProofOfDate', true)
        }

      }


      setValuePv('qualifyLaborWarranty', _.get(info.data, 'draftData.qualifyLaborWarranty', false))
      setValuePv('laborReimbursement', _.get(info.data, 'draftData.laborReimbursement', false))
      setValuePv('choseTypeSerialUpload', _.get(info.data, 'draftData.choseTypeSerialUpload', '1'))
      setValuePv('serialNumbers', _.get(info.data, 'draftData.serialNumbers', []))
      _.get(info.data, 'draftData.serialNumbers', []).length === 0 && setValuePv('listSerialPick', _.get(info.data, 'draftData.listSerialPick', []))
      _.get(info.data, 'draftData.choseTypeProject', '1')  === '1'
      && _.get(info.data, 'draftData.choseTypeSerialUpload', '1') === '1' &&
      setSerials(_.get(info.data, 'draftData.serialNumbers', []))
      _.get(info.data, 'draftData.choseTypeProject', '1')  === '1' &&
      _.get(info.data, 'draftData.choseTypeSerialUpload', '1') === '2' &&
      setSerials(_.get(info.data, 'draftData.listSerialPick', []))
      setValuePv('listSerialPick', _.get(info.data, 'draftData.listSerialPick', []))
      //setListSerial(_.get(info.data, 'draftData.listSerialPick', []));
      const moduleSN = _.filter(_.get(info.data, 'draftData.projectInfo.serialNumbers'), s => s.type === 'modules');
      setListSerial(moduleSN)
      //setValue('claimType', _.get(info.data, 'draftData.claimType', '1'))
      setValuePv('defectDate', _.get(info.data, 'draftData.defectDate', null))
      setValuePv('description', info.data.description)
      setValuePv('typeProjectName', _.get(info.data, 'draftData.typeProjectName'))
      //setValue('title', info.data.title)
      setValuePv('customerAddress', info.data.customerAddress)
      setValuePv('installationDate', _.get(info.data, 'draftData.installationDate', null))
      setValuePv('systemSize', _.get(info.data, 'draftData.systemSize', ''))
      setValuePv('productName', _.get(info.data, 'draftData.productName', ''))
      setValuePv('powerClass', _.get(info.data, 'draftData.powerClass', ''))
      setValuePv('permissionDate', _.get(info.data, 'draftData.permissionDate', null))
      setValuePv('chosePermissionDate', _.get(info.data, 'draftData.chosePermissionDate', '1'))
      setValuePv('choseAffectedSerialNumber', _.get(info.data, 'draftData.choseAffectedSerialNumber', '1'))
      setValuePv('serials', _.get(info.data, 'draftData.serials', ''))

      setValuePv('regionPv', _.get(info.data, 'draftData.regionPv', ''))
      setValuePv('systemTypePv', _.get(info.data, 'draftData.systemTypePv', 'select'))
      setValuePv('choseWebAddress', _.get(info.data, 'draftData.choseWebAddress', ''))
      setValuePv('webAddress', _.get(info.data, 'draftData.webAddress', ''))
      setValuePv('emailPurchase', _.get(info.data, 'draftData.emailPurchase', ''))
      setValuePv('phoneNumber', _.get(info.data, 'draftData.phoneNumber', ''))
      setValuePv('selectedContactNumber', _.get(info.data, 'draftData.selectedContactNumber', '1'))
      setValuePv('qCellSales', _.get(info.data, 'draftData.qCellSales', ''))
      setValuePv('poNumber', _.get(info.data, 'draftData.poNumber', ''))
      setValuePv('purchaseDate', _.get(info.data, 'draftData.purchaseDate', null))
      setValuePv('chooseRefund', _.get(info.data, 'draftData.chooseRefund', '1'))
      setValuePv('chooseBankAccInfo', _.get(info.data, 'draftData.chooseBankAccInfo', '1'))


      //manual
      setValuePv('projectNameSys', _.get(info.data, 'draftData.projectNameSys', ''))
      setValuePv('projectAddress', _.get(info.data, 'draftData.projectAddress', ''))
      setValuePv('projectAddressManual', _.get(info.data, 'draftData.projectAddressManual', null))
      setValuePv('projectAddress2Manual', _.get(info.data, 'draftData.projectAddress2Manual', null))
      setValuePv('projectInstallationDate', _.get(info.data, 'draftData.projectInstallationDate', null))
      setValuePv('projectPermissionDate', _.get(info.data, 'draftData.projectPermissionDate', null))
      setValuePv('projectSystemSize', _.get(info.data, 'draftData.projectSystemSize', ''))
      setValuePv('projectProductName', _.get(info.data, 'draftData.projectProductName', 'select'))
      setValuePv('projectPowerClass', _.get(info.data, 'draftData.projectPowerClass', 'select'))






      setValuePv('pictureOfModuleLabel', _.get(info.data, 'draftData.pictureOfModuleLabel', ''))
      setValuePv('pictureOfTheFrontSize', _.get(info.data, 'draftData.pictureOfTheFrontSize', ''))
      setValuePv('pictureOfTheBackSize', _.get(info.data, 'draftData.pictureOfTheBackSize', ''))
      setValuePv('pictureOfVocReading', _.get(info.data, 'draftData.pictureOfVocReading', ''))
      setValuePv('closeUpPictureOfPhysicalDefect', _.get(info.data, 'draftData.closeUpPictureOfPhysicalDefect', ''))
      setValuePv('siteModuleLayoutPlan', _.get(info.data, 'draftData.siteModuleLayoutPlan', ''))
      setValuePv('pictureOfOverallRoofArea', _.get(info.data, 'draftData.pictureOfOverallRoofArea', ''))
      setValuePv('pictureOfWireManagement', _.get(info.data, 'draftData.pictureOfWireManagement', ''))
      setValuePv('proofOfDeliveryDocument', _.get(info.data, 'draftData.proofOfDeliveryDocument', ''))
      setValuePv('pictureOfModuleImmediately', _.get(info.data, 'draftData.pictureOfModuleImmediately', ''))
      setValuePv('coppyOfInvoice', _.get(info.data, 'draftData.coppyOfInvoice', ''))
      setValuePv('proofOfPermissionToOperateDate', _.get(info.data, 'draftData.proofOfPermissionToOperateDate', ''))
      setValuePv('invoiceForLaborReimbursement', _.get(info.data, 'draftData.invoiceForLaborReimbursement', ''))
      setValuePv('infoBank', _.get(info.data, 'draftData.infoBank', ''))

      setValuePv('purchasingChannel', _.get(info.data, 'draftData.purchasingChannel'));
      if (_.get(info.data, 'draftData.purchasingChannel') === 'distributor') {
        setValuePv('nameOfPurchasingChannelDistributor', _.get(info.data, 'draftData.nameOfPurchasingChannelDistributor'))
        setValuePv('nameOfChanelOtherDistributor', _.get(info.data, 'draftData.nameOfChanelOtherDistributor'));
      } else if (_.get(info.data, 'draftData.purchasingChannel') === 'other') {
        setValuePv('nameOfPurchasingChannelOther', _.get(info.data , 'draftData.nameOfPurchasingChannel'));
      }

      //setValue('nameOfPurchasingChannelOther', info.data.nameOfPurchasingChannel);
      //setValuePv('nameOfPurchasingChannel', _.get(info.data, 'draftData.nameOfPurchasingChannel', ''))
      //setValuePv('purchasingChannelContactName', _.get(info.data, 'draftData.purchasingChannelContactName', ''))
      setValuePv('emailPurchase', _.get(info.data, 'draftData.emailPurchase', ''))
      setValuePv('contactNumber', _.get(info.data, 'draftData.contactNumber', ''))
      setValuePv('qCellSales', _.get(info.data, 'draftData.qCellSales', ''))
      setValuePv('poNumber', _.get(info.data, 'draftData.poNumber', ''))
      setValuePv('purchaseDate', _.get(info.data, 'draftData.purchaseDate', null))
      setValuePv('typeOfPurchasingChannel', _.get(info.data, 'draftData.typeOfPurchasingChannel', ''))

      setKeyUpload(_.get(info.data, 'draftData.keyUpload', ''))
      //!info.data.isDraft && setCurrentStep( 3);
      //setIsDraft(info.data.isDraft)

      const infoImage = suggestionsImagePv(_.get(info.data, 'draftData.attachments'))

      setFilePictureOfAffectedModule(infoImage?.affectModule || [])
      setFilePictureOfBack(infoImage?.backOfModule || [])
      setFilePictureOfFront(infoImage?.fontOfModule || [])
      setFilePictureOfVoc(infoImage?.vocReading || [])
      setFilePictureCloseUp(infoImage?.physicalDefect || [])
      setFilePictureSitePlan(infoImage?.sitePlan || [])
      setFilePictureOfOverall(infoImage?.overRall || [])
      setFilePictureOfWireManagement(infoImage?.wireManagement || [])
      setFilePictureProofDelivery(infoImage?.proofDelivery || [])
      setFilePictureImmediately(infoImage?.immedDiately || [])
      setFilePictureOfVmpp(infoImage?.offVmpp || [])
      setFilePictureIr(infoImage?.irPicture || [])
      setFilePictureInVoice(infoImage?.coppyReceipt || [])
      setFileInvoiceForLaborReimbursement(infoImage?.laborCost || [])
      setFileBankAccountInfo(infoImage?.bankAccount || [])
      setFilePermissionOfDate(infoImage?.proofOfPermission || [])

    }

    setLoadingDraft(false);
  }

  useEffect(() => {
    setValue('type', type);
  }, [])

  useEffect(() => {
    setCurrentStep(parseInt(step));
  }, [step])

  useEffect(() => {
    setIsPickClaimType(location.state.isPickClaimType)
    setValue('type', type);
    // setCurrentStep(parseInt(step));
  }, [location])

  useEffect(() => {
    scrollToTop();
  },[currentStep, isPickClaimType])

  useEffect(() => {
    scrollToTop()
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };



  const getMainPurchasingChannel = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/distributors`);
      setMainPurchasingChannel(data);
    } catch (err) {
      notiFailed(`Something happened getting main purchasing channel`);
    }
  };

  const getDistributors = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/distributors`);
      setDistributors(data);
    } catch (err) {
      notiFailed(`Something happened getting distributors`);
    }
  };

  async function getProductPti() {
    try {
      const productPti = await claimService.getProductPti();
      setListProductPti(productPti.data);
    }catch (e) {

    }
  }

  useEffect(() => {
    setValue('installerCity', _.get(user, 'infoUser.city'))
    setValue('installerAddress1', _.get(user, 'infoUser.address'))
    setValue('installerState', _.get(user, 'infoUser.state'))
    setValue('installerZipcode', _.get(user, 'infoUser.zipCode'))
    setValue('installerAddress2', _.get(user, 'infoUser.addressCont'))
    setValue('installerPhone', _.get(user, 'infoUser.officeNumber') || _.get(user, 'infoUser.mobileNumber') )
  }, [_.get(user, 'infoUser.city')])

  function nextForm() {
    setIsPickClaimType(false);

    if(claimType === '1') {

      getListProject({
        panelNonQcells : false,
        //panelQualified : true
      })
      return;
    }

    if(claimType === '2') {
      getListProject({
        battery : true,
        batteryNonQcells : false,
        //batteryQualified : true
      })
      return
    }
  }

  function goBackStep() {
    setIsPickClaimType(true);

  }

  async function getListProject(condition) {
    try {
      const infoProject = await projectService.getListProject(condition);
      setProject(infoProject.data);

    }catch (e) {

    }

  }

  const getSaleRegions = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/companies/salesregions/search`);
      setSaleRegions(data);
    } catch (err) {
      notiFailed(`Something happened getting sale regions`);
    }
  };

  useEffect(() => {

    getMainPurchasingChannel();
    getDistributors();
    getProductPti();
    getDetailClaim();
    getSaleRegions();
    if(type === '1') {

      getListProject({
        panelNonQcells : false,
        //panelQualified : true
      })
      return;
    }

    if(type === '2') {
      getListProject({
        battery : true,
        batteryNonQcells : false,
        //batteryQualified : true
      })
      return
    }
  }, [])




  const goToStep = step => setCurrentStep(step);

  function renderAbilityFormClaim() {
    if(isPickClaimType) {
      return(
        <InformationClaimType
          next={nextForm}
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          watch={watch}
          setValueEss={setValueEss}
          setValuePv={setValuePv}
          getValues={getValues}
          clearErrors={clearErrors}
          text={'Different from the above E-mail?'}
        />
      )
    }

    if(!isPickClaimType && claimType === '2') {
      return <EssPage
        distributors={distributors}
        register={registerEss}
        infoClaimName={claimInfoName}
        listProductPti={listProductPti}
        handleSubmit={handleSubmitEss}
        errors={errorEss}
        id={id}
        control={controlEss}
        errorInfo={errors}
        triggerInfo={trigger}
        watch={watchEss}
        fileEvidence={fileEvidence}
        setFileEvidence={setFileEvidence}
        filePermissionOfDate={filePermissionOfDate}
        setFilePermissionOfDate={setFilePermissionOfDate}
        fileErrorLog={fileErrorLog}
        setFileErrorLog={setFileErrorLog}
        fileLaborWarranty={fileLaborWarranty}
        fileInfoBank={fileInfoBank}
        fileInvoiceLabor={fileInvoiceLabor}
        setFileInvoiceLabor={setFileInvoiceLabor}
        setFileInfoBank={setFileInfoBank}
        setFileLaborWarranty={setFileLaborWarranty}
        keyUpload={keyUpload}
        setKeyUpload={setKeyUpload}
        trigger={triggerEss}
        setValue={setValueEss}
        getValues={getValuesEss}
        formState={formStateEss}
        clearErrors={clearErrorsEss}
        mainPurchasingChannel={mainPurchasingChannel}
        submitterEmail={submitterEmail}
        checkedEmail={checkedEmail}
        claimInfoName={claimInfoName}
        projects={projects}
        saleRegions={saleRegions}
        setFilesProof={setFilesProof}
        urlImageProof={urlImageProof}
        handleSetStep={(step) => setCurrentStep(step)}
        listSerial={listSerial}
        setListSerial={setListSerial}
        serials={serials}
        setSerials={setSerials}
        goBackStepDefault={goBackStep}/>
    }

    if(!isPickClaimType && claimType === '1') {
      return <PvPage
        trigger={triggerPv}
        listProductPti={listProductPti}
        register={registerPv}
        handleSubmit={handleSubmitPv}
        errors={errorPv}
        id={id}
        saleRegions={saleRegions}
        errorInfo={errors}
        triggerInfo={trigger}
        control={controlPv}
        watch={watchPv}
        setValue={setValuePv}
        getValues={getValuesPv}
        serials={serials}
        setSerials={setSerials}
        claimType={claimType}
        formState={formStatePv}
        clearErrors={clearErrorsPv}
        urlImageProof={urlImageProof}
        setFilesProof={setFilesProof}
        fileInvoiceForLaborReimbursement={fileInvoiceForLaborReimbursement}
        setFileInvoiceForLaborReimbursement={setFileInvoiceForLaborReimbursement}
        fileProofOfPermissionToOperateDate={fileProofOfPermissionToOperateDate}
        setFileProofOfPermissionToOperateDate={setFileProofOfPermissionToOperateDate}
        filePermissionOfDate={filePermissionOfDate}
        setFilePermissionOfDate={setFilePermissionOfDate}
        setFileLaborWarranty={setFileLaborWarranty}
        fileLaborWarranty={fileLaborWarranty}
        fileProofOfPermission={fileProofOfPermission}
        setFileProofOfPermission={setFileProofOfPermission}
        filePictureOfAffectedModule={filePictureOfAffectedModule}
        setFilePictureOfAffectedModule={setFilePictureOfAffectedModule}
        filePictureOfVoc={filePictureOfVoc}
        setFilePictureOfVoc={setFilePictureOfVoc}
        filePictureOfFront={filePictureOfFront}
        setFilePictureOfFront={setFilePictureOfFront}
        filePictureOfBack={filePictureOfBack}
        setFilePictureOfBack={setFilePictureOfBack}
        filePictureCloseUp={filePictureCloseUp}
        setFilePictureCloseUp={setFilePictureCloseUp}
        filePictureSitePlan={filePictureSitePlan}
        setFilePictureSitePlan={setFilePictureSitePlan}
        filePictureOfOverall={filePictureOfOverall}
        setFilePictureOfOverall={setFilePictureOfOverall}
        filePictureOfWireManagement={filePictureOfWireManagement}
        setFilePictureOfWireManagement={setFilePictureOfWireManagement}
        filePictureProofDelivery={filePictureProofDelivery}
        setFilePictureProofDelivery={setFilePictureProofDelivery}
        filePictureImmediately={filePictureImmediately}
        setFilePictureImmediately={setFilePictureImmediately}
        filePictureOfVmpp={filePictureOfVmpp}
        setFilePictureOfVmpp={setFilePictureOfVmpp}
        filePictureIr={filePictureIr}
        setFilePictureIr={setFilePictureIr}
        filePictureInVoice={filePictureInVoice}
        setFilePictureInVoice={setFilePictureInVoice}
        fileBankAccountInfo={fileBankAccountInfo}
        setFileBankAccountInfo={setFileBankAccountInfo}
        keyUpload={keyUpload}
        setKeyUpload={setKeyUpload}
        distributors={distributors}
        mainPurchasingChannel={mainPurchasingChannel}
        submitterEmail={submitterEmail}
        checkedEmail={checkedEmail}
        claimInfoName={claimInfoName}
        projects={projects}
        handleSetStep={(step) => setCurrentStep(step)}
        goBackStepDefault={goBackStep}
        listSerial={listSerial}
        setListSerial={setListSerial}
        step={step}
      />
    }



  }


  /**
   *
   * define case view InformationClaimType -> panel -> form ess
   *
   * case claimType -> panel -> form pv (step + form)
   * case claimType -> battery -> form ess (step + form)
   *
   *
   * */


  function suggestCurrentStep(step, status) {
    return (step === 1 && status) ? 0 : step
  }
  return(
    <div>
      <Notification/>
      {claimType === '1' ?
        <IntroductionClaim step={suggestCurrentStep(currentStep, isPickClaimType)} tyleClaim={'panel'} title={'Tell us about your panel claim'}  sub_title={['SUPPORT', 'CLAIM SUBMISSION', 'PANEL CLAIM']}/>
        : <IntroductionClaim step={suggestCurrentStep(currentStep, isPickClaimType)} tyleClaim={'battery'} title={'Tell us about your battery claim'} sub_title={['SUPPORT', 'CLAIM SUBMISSION', 'BATTERY CLAIM']}/>}
      {/*{!isPickClaimType && <ProgressBar currentStep={currentStep} goToStep={goToStep} isEnableClick={true} type={1}/>}*/}
      {loadingDraft ? <div className={classes.loadingEdit}>
        <CustomLoading size={40} color="#00adef"/>
      </div> :
      renderAbilityFormClaim()}
    </div>
  )

}

export default AbilityFormClaimEdit;
