import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import { QueryParamProvider } from 'use-query-params';
import './App.css';
import './media_screen.css';
import "animate.css/animate.min.css";
import Layout from './common/layout';
import config from './config';
import { APIProvider } from './contexts/api';
import { AuthContext } from './contexts/auth';
import { ShoppingCartContext } from './contexts/shoppingCart';
import Checkout from './routes/checkout';
import Dashboard from './routes/dashboard';
// // import LandingPage from './routes/landingPage';
import APIClient from './utils/api';
import SSOLogin from './routes/sso';
import NotFound from './common/notfound';
import Datahub from './routes/dataHub';
import ProgramIntroduction from './routes/programIntroduction'
import ContactUs from './routes/contactUs';
import Terms from './routes/terms';
import Policy from './routes/policy';
import Faq from './routes/faq';
import Notice from './routes/notice';
import Registration from './routes/registration';
import NonDisclosureAgreement from './routes/registration/nonDisclosureAgreement';
import RecurringPayment from './routes/orderHistory/RecurringPayment';
import MyAccount from './routes/myAccount';
import SiteMap from './routes/sitemap';
import LandingPage from './routes/landingPage';

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import SubIdManagement from './routes/subIdManagement';
import Projects from './routes/projects';
import ClaimRouter from './routes/claim/ClaimRouter';
import CCPAPrivacyNotice from "./routes/ccpaprivacynotice";
const history = createBrowserHistory();


const trackingId =
  process.env.REACT_APP_GOOGLE_ANALYTIC_TRACKING_ID || 'UA-145393218-3';
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);
history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

export const theme = {
  colors: {
    dark: '#000000',
    light: '#FFFFFF',
    primaryDark: '#002D56',
    primaryLight: '#00ADEF',
    secondaryDark: '#8C8C8C',
    secondaryLight: '#E6E6E6',
    gray: '#f4f4f4',
    warning: '#e76464',
    process: '#efefef'
  },
  fontSizes: {
    h1: 'var(--font-size-1, 3.375rem)',
    h2: 'var(--font-size-2, 2.25rem)',
    h3: 'var(--font-size-3, 1.5rem)',
    h4: 'var(--font-size-4, 1.125rem)',
    h5: 'var(--font-size-5, 0.9375rem)',
    h6: 'var(--font-size-6, 0.6875rem)',
    small: 'var(--font-size-small, 0.75rem)'
  }
};

const apiClient = new APIClient({ baseUrl: config.baseUrl });

function App() {
  const muiTheme = useTheme();

  return (
    <>
      <StylesProvider injectFirst>
        <CssBaseline />
        <ThemeProvider theme={{ ...muiTheme, ...theme }}>
          <APIProvider client={apiClient}>
            <AuthContext>
              <Router history={history}>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <ToastContainer autoClose={7500} />
                  <ShoppingCartContext>
                    <Layout>
                      <Switch>
                        <Route path="/subidmanagement" component={SubIdManagement} />
                        <Route path="/dashboard" component={Dashboard} />
                        <Route path="/about/program-introduction" component={ProgramIntroduction} />
                        <Route path="/support/faq" component={Faq} />
                        <Route path="/support/contact-us" component={ContactUs} />
                        <Route path="/terms" component={Terms} />
                        <Route path="/policy" component={Policy} />
                        <Route path="/ccpa-privacy-notice" component={CCPAPrivacyNotice} />
                        <Route path="/notice" component={Notice} />
                        <Route path="/salestools/data-hub" component={Datahub} />
                        <Route path="/mypage/member" component={MyAccount} />
                        <Route path="/sitemap" component={SiteMap} />
                        <Route path="/sso/login" component={SSOLogin} />
                        <Route path="/register" component={Registration} />
                        <Route path="/qpartner/nda" component={NonDisclosureAgreement} />
                        <Route
                          path="/academy"
                          component={() => {
                            window.location.href = `${process.env.REACT_APP_QPARTNER_LEGACY_BASE_URL}/sales/academy_list.fos`;
                            return null;
                          }}
                        />
                        <Route path="/recurring-payment" component={RecurringPayment} />
                        <Route path="/projects" component={Projects} />
                        <Route path="/claim" component={ClaimRouter} />
                        <Route path="/" component={LandingPage} />
                        <Route path="*" component={NotFound} />
                      </Switch>
                    </Layout>
                  </ShoppingCartContext>
                </QueryParamProvider>
              </Router>
            </AuthContext>
          </APIProvider>
        </ThemeProvider>
      </StylesProvider>
    </>
  );
}

export default App;
