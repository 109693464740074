import React from 'react';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import useStyles from './useStyles';
import { Box, TextField } from '@material-ui/core';
import CustomButton from '../../../common/components/button';
import CustomLoading from '../../../common/components/CustomLoading';


function SoftwareVersion({ next, goBack, register, control, errors, watch, setValue, handleSaveDraft, loading}) {
  const classes = useStyles();
  return(
    <div>
      <div className={classes.stepInfo}>
        <span className={classes.sectionTitleStep}>
          5. Software version
        </span>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              DSP1/Master SW Version
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="dsp1SwVersion"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              ARM SW Version
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="armSwVersion"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
             Battery Master SW Version
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="batterySwVersion"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>

      <p className={classes.requiredText} style={{ marginTop: '308px' }}>* Required</p>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }}/>
      <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
        <CustomButton color="secondary" className="saveDraftBtn" onClick={goBack}>Back</CustomButton>
        <div>
          <CustomButton onClick={handleSaveDraft} color="secondary" className="saveDraftBtn" disabled={loading}>
            { loading ? <CustomLoading size={16} color="#009EE3" /> : 'Save Draft' }
          </CustomButton>
          <CustomButton onClick={next}>Next</CustomButton>
        </div>
      </Box>
    </div>
  )
}


export default SoftwareVersion;
