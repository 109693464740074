import React, { useEffect, useState } from 'react';
import { Box, TextareaAutosize, Radio, RadioGroup, TextField, FormControl, FormControlLabel, Checkbox, Button, Menu, MenuItem } from '@material-ui/core';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import CustomButton from '../../../common/components/button';
import useStyles from './useStyles';
import axios from '../../../utils/axios';
import { notiFailed } from '../../../utils/helpers';
import config from '../../../config';
import {ViewList} from '@material-ui/icons';
import CustomLoading from '../../../common/components/CustomLoading';
import NumberFormat from "react-number-format";
import CheckValidate from '../../../common/components/CheckValidate';
import CustomDatePicker from '../../projects/components/CustomDatePicker';
import Popover from '../../../common/components/Popover';
import ImageUploader from '../components/UploadImage';
import _ from 'lodash';
import ReactSelect from 'react-select';
import IconSelect from '../../../icons/arrow.svg';
import MessageSpecial from './MessageSpecial';
import ViewProjectDetail from './ViewProjectDetail';
import IconEdit from '../../../icons/edit_icon.svg';
import ProjectManualEss from './ProjectManualEss';
import PopOver from '../../../config/images/popover_icon.svg';

const ReactSelectDropdownIndicator = () => {
  return (
    <div style={{ marginRight: '15px' }}>
      <img src={IconSelect} width="14"/>
    </div>
  )
}
const { baseUrl } = config;

function ZipCodeNumberFormat(props) {
  return (
    <NumberFormat format="#####" {...props} />
  )
}

function UserInfo({next, register, watch ,control, errors, handleSaveDraft, isEnablePreviewButton, handleGoToReview ,setValue, loading, getValues, text, 
  filePictureOfAffectedModule, setFilePictureOfAffectedModule, keyUpload, setKeyUpload, clearErrors, projects}) {
  const classes = useStyles();

  // const [saleRegions, setSaleRegions] = useState([]);
  const [projectActive, setProjectActive] = useState({});
  // const currentData = getValues();
  const projectId = watch('projectId');
  const choseTypeProject = watch('choseTypeProject');

  // useEffect(() => {
  //   getSaleRegions()
  // }, []);

  useEffect(() => {
    if(choseTypeProject === '2') {
      setValue('projectId', null)
    }
  }, [choseTypeProject])

  useEffect(() => {
    if(projectId !== null || projectId !== undefined) {
      setProjectActive(projectId)
    }
  }, [projectId?.id])
  
  // const getSaleRegions = async () => {
  //   try {
  //     const { data } = await axios.get(`${baseUrl}/companies/salesregions/search`);
  //     setSaleRegions(data);
  //   } catch (err) {
  //     notiFailed(`Something happened getting sale regions`);
  //   }
  // };

  return(
    <div>
      <h1 style={{fontWeight: 500, fontSize: 16}}>Issue</h1>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }} />
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
            <span className={classes.sectionTitle}>
                Defect Discovery Date
            </span>
        </div>
        <div>
          <Controller
            name="defectDate"
            control={control}
            render={(
              { onChange, value }
            ) => (
              <CustomDatePicker
                fullWidth
                size="small"
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                inputVariant="outlined"
                value={value}
                onChange={date => onChange(date)}
                className={classNames([classes.datePicker], {
                  [classes.errorDatePickerField]: errors.defectDate
                })}
                views={['year', 'month', 'date']}
              />
            )}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
            Description of <br />Your Issue  <span>*</span>
          </span>
        </div>
        <div className={classes.textArea}>
          <Controller
            name="description"
            control={control}
            render={(
              { onChange, value }
            ) => (
              <TextareaAutosize
                name="description"
                onChange={(value) => onChange(value)}
                aria-label="minimum height"
                value={value}
                className={classNames([classes.inputTextArea], {
                  [classes.inputTextAreaError]: errors.description
                })} placeholder=""/>
            )}
          />
        </div>
        <CheckValidate value={watch('description')} error={errors.description}/>
      </div>

      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }} />
      <div className={classes.sectionContainerSys}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Project
          </span>
        </div>
        <div>
          <Controller
            name="choseTypeProject"
            control={control}
            as={
              <RadioGroup aria-label="position">
                <FormControlLabel
                  value="1"
                  control={<Radio icon={<div className={classes.radio}/>} checkedIcon={<div className={classes.radioOn}/>} />}
                  label="Search for Project"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio icon={<div className={classes.radio}/>} checkedIcon={<div className={classes.radioOn}/>} />}
                  label="Manual Input"
                  labelPlacement="end"
                />
              </RadioGroup>
            }
          />
        </div>
        <div>
          <CheckValidate value={watch('projectId')} error={errors.projectId}/>
        </div>
      </div>
      {choseTypeProject === '1' && <div className={classes.sectionContainerSys}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          <Controller
            name="projectId"
            key="name-project-claim"
            control={control}
            as={
              <ReactSelect
                getOptionLabel={option => _.trim(option.projectAddress)}
                getOptionValue={option => _.trim(option.id)}
                isSearchable
                isClearable
                isMulti={false}
                options={projects}
                className={classNames([classes.multiSelect], {
                  [classes.errorReactSelect]: errors.nameOfPurchasingChannelDistributor
                })}
                classNamePrefix="select"
                placeholder="Search By Address"
                defaultMenuIsOpen={false}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: ReactSelectDropdownIndicator
                }}
                styles={{
                  placeholder: provided => ({
                    ...provided,
                    color: '#C4C4C4'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    fontFamily: 'Actual',
                    fontSize: '14px',
                    color: '#333333',
                    fontWeight: 400,
                    fontStyle: 'normal'
                  })
                }}
              />
            }
          />
        </div>
      </div>}
      <div className={classes.sectionContainerSys}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          <MessageSpecial/>
        </div>
      </div>

      {choseTypeProject === '1' && projectId !== null && <div className={classes.sectionContainerSys}>
        <div className={classes.sectionHeaderContainer}/>
        <div>
          <ViewProjectDetail project={projectActive}/>
        </div>
        <Button className={classes.editBtn}>
          <span>Edit</span>
          <img src={IconEdit} title='Edit'/>
        </Button>
      </div>}
      {/* {choseTypeProject === '2' && <div className={classes.sectionContainerSys}>
          <div/>
          <ProjectManualEss control={control} watch={watch} errors={errors} register={register}/>
      </div>} */}

      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
            <span className={classes.sectionTitle}>
              Picture of <br />Affected Module <br /> Label(s)
              <span>*</span> <br />
              <Popover>
                <p style={{color: '#4F4F4F'}}>Content</p>
              </Popover>
            </span>
        </div>
        <div>
          <div className={classes.wrapUploadEx}>
            <ImageUploader
              files={filePictureOfAffectedModule}
              setFiles={setFilePictureOfAffectedModule}
              fileName={'affectedmodule.jpg'}
              keyUpload={keyUpload}
              setKeyUpload={setKeyUpload}
              errors={errors.pictureOfModuleLabel}
              name={'pictureOfModuleLabel'}
              clearErrors={clearErrors}
            />
          </div>
          <div>
            <div className={classes.notiBox}>
              <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>
              <p>Proof of PTO date is optional, but you will need to provide it to qualify for labor reimbursement when you submit claims.</p>
            </div>
          </div>
        </div>
        <CheckValidate value={watch('pictureOfTheFrontSize')} error={errors.pictureOfTheFrontSize}/>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
            Region<span>*</span>
          </span>
        </div>
        <div>
          <Controller
            name="region"
            control={control}
            as={
              <TextField
                fullWidth
                variant="outlined"
                className={classNames([classes.inputField], [classes.inputFieldDisabled], {
                  [classes.errorInputField]: errors.region
                })}
                inputRef={register}
                disabled
              />
            }
          />
        </div>
        <CheckValidate value={watch('region')} error={errors.region}/>
      </div>

      {/* <div className={classes.stepInfo}>
        <span className={classes.sectionTitleStep}>
          1. User Info
        </span>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Name
            <span>*</span>
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="installerName"
            className={classNames([classes.inputField], {
              [classes.errorInputField]: errors.installerName
            })}
            inputRef={register}
          />
        </div>
        <CheckValidate value={watch('installerName')} error={errors.installerName}/>
      </div>
      <div className={classes.sectionContainerAddress}>
        <div
          className={classes.sectionHeaderContainer}
          style={{
            alignItems: 'flex-start'
          }}
        >
            <span className={classes.sectionTitle}>
                Address {' '}
              <span>*</span>
            </span>
        </div>
        <div className={classes.addressSection}>
          <div className={classes.addressSection1}>
            <div>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Street address"
                name="installerAddress1"
                className={classNames([classes.inputField], {
                  [classes.errorInputField]: errors.installerAddress1
                })}
                inputRef={register}
              />
            </div>
            <div>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Ste, unit, building, floor, etc"
                name="installerAddress2"
                className={classNames([classes.inputField], {
                  [classes.errorInputField]: errors.installerAddress2
                })}
                inputRef={register}
              />
            </div>
          </div>
          <div className={classes.addressSection2}>
            <div>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="City"
                name="installerCity"
                className={classNames([classes.inputField], {
                  [classes.errorInputField]: errors.installerCity
                })}
                inputRef={register}
              />
            </div>
            <div>
              <Controller
                name="installerState"
                control={control}
                as={
                  <select
                    placeholder="State"
                    className={classNames([classes.selectField], {
                      [classes.errorSelectField]: errors.installerState
                    })}
                  >
                    <option value={'select'}>State</option>
                    {saleRegions.map(pc => {
                      const [id, value] = pc.split(':');
                      return (
                        <option value={value} key={id}>{value}</option>
                      )
                    })}
                  </select>
                }
              />
            </div>
            <div>
              <Controller
                name="installerZipcode"
                control={control}
                as={
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Zip Code"
                    className={classNames([classes.inputField], {
                      [classes.errorInputField]: errors.installerZipcode
                    })}
                    InputProps={{
                      inputComponent: ZipCodeNumberFormat
                    }}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Contact Name
              <span>*</span>
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="installerContact"
            className={classNames([classes.inputField], {
              [classes.errorInputField]: errors.installerContact
            })}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              E-mail
            <span>*</span>
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="installerEmail"
            className={classNames([classes.inputField], {
              [classes.errorInputField]: errors.installerEmail
            })}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Phone Number
            <span>*</span>
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="installerPhone"
            className={classNames([classes.inputField], {
              [classes.errorInputField]: errors.installerPhone
            })}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Contact E-Mail
          </span>
        </div>
        <div style={{display: 'flex'}}>
          <TextField
            fullWidth
            variant="outlined"
            name="submitterEmail"
            className={classes.inputField}
            inputRef={register}
          />

        </div>
        <FormControlLabel
          style={{ marginLeft: '-9px' }}
          control={
            <Controller
              name={'checkedEmail'}
              control={control}
              render={(props) => (
                <Checkbox
                  color={'primary'}
                  {...props}
                  checked={props.value}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              )}
            />
          }
          name={`checkedEmail`}
          inputRef={register}
          label={
            <p
              style={{
                fontFamily: 'Actual',
                fontWeight: 400,
                fontStyle: 'normal',
                fontSize: '15px',
                lineHeight: '20px',
                marginLeft: '2px'
              }}
            >
              {text}
            </p>
          }
        />
      </div> */}


      <p className={classes.requiredText}>* Required</p>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }}/>
      <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
        <div>
          {isEnablePreviewButton && (
            <CustomButton color="secondary" className="saveDraftBtn" onClick={handleGoToReview}>Back to Review</CustomButton>
          )}
        </div>
        <div>
          <CustomButton onClick={handleSaveDraft} color="secondary" className="saveDraftBtn" disabled={loading}>
            { loading ? <CustomLoading size={16} color="#009EE3" /> : 'Save Draft' }
          </CustomButton>
          <CustomButton onClick={next}>Next</CustomButton>
        </div>
      </Box>
    </div>
  )
}

export default UserInfo;
