import React, { useMemo, useState } from 'react';
import useStyles from './useStyles';
import Moment from 'react-moment';
import _ from 'lodash';
import { Button, Grid, IconButton, Modal, Paper } from '@material-ui/core';
import IconEdit from '../../../icons/edit_icon.svg';
import CloseIcon from '@material-ui/icons/Close';
import Text from '../../../common/components/text';
import CustomButton from '../../../common/components/button';

function ViewProjectDetail({project, handleSaveDraft}) {
  const classes = useStyles();
  const [modalVisible, setModalVisible] = useState(false);
  const essSerial = _.filter(_.get(project, 'serialNumbers'), s => s.type === 'inverter');

  const renderAddress = (array = []) => {
    const newArray = _.remove(array, value => !!value || value === 'select');
    return newArray.length > 0 ? _.join(newArray, ', ') : '';
  };

  function renderProductName() {
    if(project.batteryModel !== 'select' && project.batteryModel !== '' && project !== null) {
      return project.batteryModel
    }else {
      const inverterSN = _.filter(_.get(project, 'serialNumbers'), s => s.type === 'inverter');
      return inverterSN.length > 0 ? inverterSN[0]?.productName : ''
    }
  }

  function renderSerialNumber() {
    const inverterSN = _.filter(_.get(project, 'serialNumbers'), s => s.type === 'inverter');
    return inverterSN.length > 0 ? inverterSN[0]?.serialNumber : ''
  }

  const essSerialNumberTableData = useMemo(() => {
    const uniqModuleSerialNumber = _.filter(_.get(project, 'serialNumbers'), s => s.type === 'inverter');


    return {
      productModel: _.get(uniqModuleSerialNumber, '[0].productName'),
      inverterSize: 300,
      powerClass: _.get(uniqModuleSerialNumber, '[0].volume', 0)
    };
  }, [essSerial]);

  const closeModal = () => {
    setModalVisible(false);
  };


  function renderInverter() {
    const inverterSN = _.filter(_.get(project, 'serialNumbers'), s => s.type === 'inverter');

    if(inverterSN?.length > 0) {
      return `${essSerialNumberTableData.powerClass / 1000} kWh`
    }else {
      if(_.get(project, 'batteryInverterPower', '') === null) return ''
      return `${_.get(project, 'batteryInverterPower', '')} kWh`
    }

  }

  return(
    <div className={classes.reviewSectionsContainer}>
      <div className={classes.reviewSectionContainerCLaimInfoDetail}>
        <div className={classes.reviewSectionContentProjectDetailInfo}>
          <h6>Project Details</h6>
        </div>
        <div>
          <Button onClick={() => setModalVisible(true)} className={classes.editBtn}>
            <span>Edit</span>
            <img src={IconEdit} title='Edit'/>
          </Button>

        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentProjectDetail}>
          <h6>Project Name</h6>
          <p>
            {project?.projectName}
          </p>
        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentProjectDetail}>
          <h6>Address</h6>
          <p>{renderAddress([project?.projectAddress, project?.projectAddress2, project?.projectCity, project?.projectState, project?.projectZipCode])}</p>
        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentProjectDetail}>
          <h6>Installation Date</h6>
          <p>{project?.installationDate && <Moment format="L">{project.installationDate}</Moment>}</p>
        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentProjectDetail}>
          <h6>Permission-to Operate Date</h6>
          <p>{project?.operateDate && <Moment format="L">{project?.operateDate}</Moment>}</p>
        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentProjectDetail}>
          <h6>Product Name</h6>
          <p>{renderProductName()}</p>
        </div>
      </div>
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentProjectDetail}>
          <h6>System Type</h6>
          <p>{(project?.systemType !== null || project?.systemType !== 'select' || project?.systemType !== '') ? project?.systemType : ''}</p>
        </div>
      </div>
      {/*<div className={classes.reviewSectionContainerCLaimInfo}>*/}
      {/*  <div className={classes.reviewSectionContentProjectDetail}>*/}
      {/*    <h6>Q.HOME Inverter Serial Number</h6>*/}
      {/*    <p>{renderSerialNumber()}</p>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className={classes.reviewSectionContainerCLaimInfo}>
        <div className={classes.reviewSectionContentProjectDetail}>
          <h6>Inverter Power</h6>
          <p>{renderInverter()}</p>
        </div>
      </div>
      <Modal onClose={closeModal} open={modalVisible} className={classes.modal}>
        <Paper>
          <Grid container justify="flex-end">
            <IconButton onClick={closeModal}>
              <CloseIcon fontSize="default" />
            </IconButton>
          </Grid>
          <Text
            style={{ textAlign: 'center', paddingBottom: '30px' }}
            color="dark"
            size="h3"
            className={classes.header}
          >
            This will direct to the Project Details page. Your claim submission will be automatically saved.
          </Text>

          <Grid
            container
            justify="center"
            style={{ paddingBottom: '50px' }}
          >
            <Grid item style={{ marginRight: '10px' }}>
              <CustomButton
                color="secondary"
                onClick={closeModal}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item>
              <a onClick={() => {
                setModalVisible(false)
                handleSaveDraft()}} target={'_blank'} href={`/projects/${project.id}/0`}>
                <CustomButton
                  color="primary"
                >
                  Proceed
                </CustomButton>
              </a>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </div>
  )
}

export default ViewProjectDetail;
