import React from 'react';
import styled from 'styled-components';
import {
  lightThemeColor,
  primaryLightThemeColor,
  secondaryLightThemeColor
} from '../../../utils/colors';
import { numberFormatter } from '../../../utils/formatter';
import Price from '../price';
import Text from '../text';
import Box from '@material-ui/core/Box';
import _ from 'lodash';
import AnhDTProductImages from './AnhDTProductImages.js';

const ProductComponent = styled('section')`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid ${secondaryLightThemeColor};
  cursor: pointer;
  transition: 250ms ease transform;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 3px solid ${primaryLightThemeColor};
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: 100ms ease opacity;
  }

  :hover {
    transform: translateY(-1px);

    &:after {
      opacity: 1;
    }
  }
`;

const SoldOutText = styled(Text)`
  position: absolute;
  top: 0;
  padding: 0.5rem;
  background: ${lightThemeColor};
  width: 94px;
  height: 47px;
`;

const Color = styled('div')`
  width: 1.5rem;
  height: 1.5rem;
  background: ${({ color }) => color};
  border: 0.5px solid ${secondaryLightThemeColor};
`;

function unique(array, property) {
  const result = [];
  const map = new Map();
  for (const item of array) {
    if (!map.has(item[property])) {
      map.set(item[property], true);
      result.push({
        id: item.id,
        ...item
      });
    }
  }
  return result;
}

/**
 * @name Product
 * @param product Product object from server
 * @param onClick Function
 */
export default function PopularProduct({ style, className, product, onClick }) {
  let images;
  if (_.get(product, 'media[0].assetUrl')) {
    images = _.get(product, 'media').map(item => {
      if (!_.get(item, 'imageUrl') && _.get(item, 'assetUrl')) {
        item.imageUrl = item.assetUrl;
      }
      return item;
    });
  } else if (_.get(product, 'variantImages[0].imageUrl')) {
    images = _.get(product, 'variantImages');
  } else if (_.get(product, 'imageproduct[0].imageUrl')) {
    images = _.get(product, 'imageproduct');
  } else {
    images = [{}];
  }

  const variants = _.get(product, 'variants', []).filter(v => !!v.id);

  const uniqueVariants = unique(variants, 'hexCode');
  const stock = variants.reduce((acc, item) => acc + item.stock, 0);
  const isUnlimitedProduct = product.variants.length == 1 && product.variants[0].size === null &&
    product.variants[0].color === null && product.variants[0].unlimited === true
  let soldOut = true;
  if (isUnlimitedProduct) {
    soldOut = false;
  } else if (product.hasOwnProperty('stock')) {
    soldOut = product.stock <= 0;
  } else {
    soldOut = stock <= 0;
  }
  let priceFirstVariant = '';
  if (_.get(product, 'variants[0].qpoints') !== undefined) {
    priceFirstVariant = _.get(product, 'variants[0].qpoints');
  }

  return (
    <ProductComponent
      style={style?.base}
      className={className}
      onClick={() => onClick?.(product)}
    >
      <AnhDTProductImages
        recommended={_.get(product, 'recommended', false)}
        soldOut={soldOut}
        aurora={!!_.get(product, 'variants[0].custom')}
        images={images}
      />

      <section
        style={{ padding: '1.875rem', minHeight: '10.9375rem', ...style?.content }}
      >
        <Text className="product_name" weight="medium">
          {product.name}
        </Text>

        {_.get(product, 'isContact') === true ? (
          <Text
            color="primaryLight"
            size="h4"
            weight="medium"
            style={{
              marginTop: '1.125rem',
              fontSize: '0.97rem',
              marginLeft: '-20px',
              marginRight: '-20px'
            }}
          >
            {_.get(product, 'contactPrices', '')}
          </Text>
        ) : (
          <Price
            style={{ marginTop: '1.125rem' }}
            weight="medium"
            size="h4"
            color="primaryLight"
          >
            {priceFirstVariant !== '' ? priceFirstVariant : _.get(product, 'qpoints')}
          </Price>
        )}

        {_.get(product, 'variants[0].unlimited') !== true && !_.get(product, 'variants[0].custom') && (
          <Text color="secondaryDark">
            Available QTY: {numberFormatter.format(product.stock)}
          </Text>
        )}

        {_.get(uniqueVariants, '[0].hexCode') && (
          <Box
            display="inline-flex"
            justifyContent="center"
            style={{ marginTop: '10px' }}
          >
            {product.colors.map(variant => (
              <Color key={variant.name} color={variant.name} />
            ))}
          </Box>
        )}
      </section>
      {soldOut && (
        <SoldOutText className="" size="h4" weight="bold">
          Sold Out
        </SoldOutText>
      )}
    </ProductComponent>
  );
}
