import React, { useState, useEffect } from 'react';
import Text from '../../common/components/text';
// import TextButton from '../../common/components/button/TextButton';
import CustomButton from '../../common/components/button';
import axios from '../../utils/axios';
import config from '../../config';
import { Link } from 'react-router-dom';
import './components/index.css';
import Box from '@material-ui/core/Box';
import CustomBox from '../../common/components/CustomBox';
import {
  Grid,
  Paper,
  // List,
  // ListItem,
  Modal,
  Backdrop,
  Fade
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import Card from './components/Card';
import Card2 from './components/Card2';
import ProgressBar from './components/ProgressBar';
// import PigIcon from '../../icons/PigIcon';
import Star_icon from '../../icons/star_icon.png';
import CloseIcon from '../../icons/CloseIcon.png';
import letter_icon from '../../icons/letter_icon.png';
import panel_icon from '../../icons/panel_icon.png';
import pig_icon from '../../icons/pig_icon.png';
import PanelIcon from '../../icons/panel-icon.png';
import NewsCardImage from '../../config/images/Q.PARTNER Academy Offline Training_thumbnail 2020-07.png';
import NewsCardImage2 from '../../config/images/Qcells_Q.HOME CORE installation_202301_long.jpg';
import NewsCardImage2_1 from '../../config/images/Qcells_Q.HOME CORE installation_202301_long_cut.png';
import moment from 'moment';
// import Price from '../../common/components/price';
// import Protected from '../../common/components/Protected';
import _ from 'lodash';
import { useAuth } from '../../contexts/auth';
import { notiSuccess, notiFailed } from './../../utils/helpers.js';
import ClearIcon from '@material-ui/icons/Clear';
import layer1 from './../../config/images/layer1_2.png';
import layer2 from './../../config/images/layer2.png';
import layer3 from './../../config/images/forecast-update-popup.jpg';

import auroraPopup1 from './../../config/images/aurora_popup_01.png';
import auroraPopup2 from './../../config/images/aurora_popup_02.png';
import auroraPopup3 from './../../config/images/aurora_popup_03.png';
import auroraPopup4 from './../../config/images/aurora_popup_04.png';

import classNames from 'classnames';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import EnFinPopUpBanner1 from '../../config/images/EnFin-pop-up-banner_01.png';
import EnFinPopUpBanner2 from '../../config/images/EnFin-pop-up-banner_02.png';
import EnFinPopUpBanner3 from '../../config/images/EnFin-pop-up-banner_03.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { setWithExpiry, getWithExpiry } from './../../utils/helpers.js';
import ReactGA from 'react-ga';
import {
  sendEventForDashBoard,
  // ACTION_VIEW_QPOINT_HISTORY,
  ACTION_REGISTER_PRODUCT,
  ACTION_SUBMIT_FORCAST_NEXT_MONTH,
  ACTION_VIEW_CURRENT_MONTH_FORECAST,
  // ACTION_VIEW_ORDER_HISTORY,
  // ACTION_VIEW_MY_ACCOUNT_PAGE,
  // ACTION_VIEW_SUB_ID_PAGE
} from './../../utils/gaHelpers';
import { useHistory } from 'react-router-dom';
import CustomLoading from './../../common/components/CustomLoading';
// import QcellsAuroraImage from './../../config/images/qcells_aurora_image.jpg';
import QcellsAuroraImage from './../../config/images/img3_dashboard.png';
import { AURORA_CATEGORY_KEYWORD } from './../../utils/constants';
import { newService, dashboardService } from '../../services';
import { Bar } from 'react-chartjs-2';
// import { fontSize } from '@material-ui/system';
// import { color } from '@material-ui/system';
import QFinancePopUpImg from '../../config/images/popupDashboardRight.png';

import DataHubImg from '../../icons/img_about_cont08.png'


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '84%',
    margin: 'auto',
    marginTop: '20px'
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  box: {
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    paddingTop: '25px',
    paddingBottom: '31px',
    borderColor: "#E0E0E0"
  },
  headerImage: { position: 'relative', textAlign: 'center', color: 'white' },
  headerImageText: {
    position: 'absolute',
    top: '50%',
    left: '45%'
  }
}));

const StyledButton = styled.button`
    min-width: 120px;
    border-radius: 5px;
    background: #000;
    color: #fff;
    transition: all 0.4s;
    border: none;
    display: inline-block;
    position: relative;
    height: 40px;
    padding: 0 28px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    :hover {
      background: #666;
      cursor: pointer;
    }
`;

const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};

const { baseUrl } = config;

let todaysMonth = moment().month();
let todaysYear = moment().year();

export default function Dashboard() {
  const history = useHistory();
  const userContext = useAuth();
  const classes = useStyles();
  const [user] = useState(JSON.parse(localStorage.getItem('qpartner_user')));
  const [userProfile, setUserProfile] = useState('');
  const [orders, setOrders] = useState([]);
  const [articleCodes, setArticleCodes] = useState([]);
  const [notiModalOpen, setNotiModalOpen] = useState(false);
  const [checkboxDontShow, setCheckboxDontShow] = useState(false);
  const [availableQpoint, setAvailableQPoint] = useState(null);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(1);
  const [forecastConfig, setForecastConfig] = useState({});
  const [isLoadingForecastProgress, setLoadingForecastProgress] = useState(false);
  const [open, setOpen] = useState(false);
  const [infoNewLaTest, setInfoNewLatest] = useState({});
  const [showDontShowOption, setShowDontShowOption] = useState(true);
  const [innerWidth, setInnerWidth] = useState(null);
  const [popupIndex, setPopupIndex] = useState(1);
  const [dashboardProjects, setDashboardProjects] = useState([]);
  const [dashboardClaims, setDashboardClaims] = useState([]);
  const [projectsLMonths, setProjectsLMonths] = useState([]);
  const [showBanner, setShowBanner] = useState(true);
  const [openClaim, setOpenClaim] = useState(null);
  const [pendingClaim, setPendingClaim] = useState(null);
  const typeBussiness = userContext.partnerType;
  const stateChart = {
    labels: projectsLMonths.map(item => moment(item.month).format('MMM')),
    datasets: [
      {
        label: 'Installation ',
        backgroundColor: '#02A3EC',
        data: projectsLMonths.map(item => item.total),
        // data: [800, 92, 36]
      }
    ]
  };

  useEffect(() => {
    setInnerWidth(window.innerWidth);
    getLaTestNew();
    getDashboardProjects();
    getDashboardClaims();
    axios.get(`${baseUrl}/users/profile`)
      .then(res => {
        setUserProfile(res.data);
      })
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleThinkificSSO = async (e, return_url) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const { data } = await axios.post(`${baseUrl}/loginThinkific`);
      const { token } = JSON.parse(localStorage.getItem('qpartner_user'));
      const returnUrl = encodeURIComponent(
        `/pages/academyredirect?qpartnerToken=${token}&rd=${return_url}`
      );
      window.location.href = `${process.env.REACT_APP_THINKIFIC_URL}/api/sso/v2/sso/jwt?jwt=${data.tokenThinkific}&return_to=${returnUrl}&error_url=/404`;
    } catch (err) {
      window.location.href = `${process.env.REACT_APP_THINKIFIC_URL}${return_url}`;
    }
  };

  async function getDashboardProjects() {
    try {
      const { data } = await dashboardService.getDashboardProjects();
      setDashboardProjects(data);
      setProjectsLMonths(data.lastMonths);
    } catch (error) {
      console.log(error);
    }
  }

  async function getDashboardClaims() {
    try {
      const { data } = await dashboardService.getDashboardClaims();
      setDashboardClaims(data);
      let opening = 0;
      let pending = 0;
      opening = parseInt(data["Initiation"]) + parseInt(data.Evidence) + parseInt(data["Validation"])
        + parseInt(data["Remedy Conclusion"]) + parseInt(data["Ready For EO"]) + parseInt(data["Pay Customers"])
        + parseInt(data["Remedy Rejection"]);
      pending = parseInt(data.Evidence);
      setOpenClaim(opening);
      setPendingClaim(pending.toString());
    } catch (error) {
      console.log(error);
    }
  }

  const handleCloseNotiModal = () => {
    setNotiModalOpen(false);
    Object.defineProperty(document, 'referrer', {
      configurable: true,
      get: function() {
        return '';
      }
    });
  };
  const [width, setWindowWidth] = useState(0);

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  useEffect(() => {
    setInnerWidth(window.innerWidth);
    getLaTestNew();
  }, []);

  async function getLaTestNew() {
    const info = await newService.getNewLatest();
    setInfoNewLatest(info?.data?.data[0]);
  }

  const getOrders = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/orders`);
      const filteredOrders = [];
      data.orders.filter(uo => {
        if (uo.userId === userContext.id) {
          filteredOrders.push(uo);
        }
      });
      setOrders(filteredOrders);
    } catch (err) {
      notiFailed(`Something happened getting user orders`);
    }
  };
  const getArticleCodes = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/companies/${userContext.companyId}/forecasts`,
        {
          params: {
            year: todaysYear,
            month: todaysMonth
          }
        }
      );
      setArticleCodes(data);

      let totalVolume = data
        .map(config => {
          return config.forecasts
            .map(forecast => {
              const ac = config.articleCodes.find(
                articleCode => articleCode.id === forecast.articleCodeId
              );
              return forecast.quantity * ac.volume;
            })
            .reduce((a, b) => a + b, 0);
        })
        .reduce((a, b) => a + b, 0);
      setVolume(totalVolume);
    } catch (err) {
      notiFailed(`Something happened getting forecasts`);
    }
  };

  const getAllModulesApprovedWithinCurrentQuarter = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/forecasts/modules`, {
        params: {
          month: todaysMonth,
          year: todaysYear
        }
      });

      let totalModuleApprovedWattageWithinQuarter = data
        .map(ac => +ac.articleCode.volume)
        .reduce((a, b) => a + b, 0);
      setProgress(totalModuleApprovedWattageWithinQuarter);
    } catch (err) {
      console.log(err);
    }
  };

  const getForecastConfig = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/configurations/forecasts`);
      setForecastConfig(data);
    } catch (err) {
      console.log(err);
    }
  };

  //dashboard orders
  const orderHistory = () => {
    let processing = 0;
    let shipped = 0;
    let received = 0;
    let returned = 0;

    orders.forEach(o => {
      // ["Cancel", "Delivered"]
      if (o.orderStatus === 'Processing') {
        processing += 1;
      }
      if (o.orderStatus === 'Order Received') {
        received += 1;
      }
      if (o.orderStatus === 'Return') {
        returned += 1;
      }
      if (o.orderStatus === 'Add Tracking') {
        shipped += 1;
      }
    });
    return (
      <>
        <Grid item>
          <div>
            <Text
              color="dark"
              weight="400"
              style={{ display: 'inline', fontSize: '15px' }}
            >
              {`${received !== 0 ? received : 0} `}
            </Text>
            {' '}
            <Text
              color="dark"
              weight="300"
              style={{ display: 'inline', fontSize: '15px' }}
            >
              Order Received
            </Text>
          </div>
        </Grid>
        <Grid item>
          <div>
            <Text
              color="dark"
              weight="400"
              style={{ display: 'inline', fontSize: '15px' }}
            >
              {`${processing !== 0 ? processing : 0} `}
            </Text>
            {' '}
            <Text
              color="dark"
              weight="300"
              style={{ display: 'inline', fontSize: '15px' }}
            >
              Processing
            </Text>
          </div>
        </Grid>
        <Grid item>
          <div>
            <Text
              color="dark"
              weight="400"
              style={{ display: 'inline', fontSize: '15px' }}
            >
              {`${shipped !== 0 ? shipped : 0} `}
            </Text>
            {' '}
            <Text
              color="dark"
              weight="300"
              style={{ display: 'inline', fontSize: '15px' }}
            >
              Shipped
            </Text>
          </div>
        </Grid>
        <Grid item>
          <div>
            <Text
              color="dark"
              weight="400"
              style={{ display: 'inline', fontSize: '15px' }}
            >
              {`${returned !== 0 ? returned : 0} `}
            </Text>
            {' '}
            <Text
              color="dark"
              weight="300"
              style={{ display: 'inline', fontSize: '15px' }}
            >
              Returned
            </Text>
          </div>
        </Grid>
      </>
    );
  };

  const primaryUser = () => {
    if (userProfile.primaryUser === null) {
      return (
        <Text
          color="dark"
          weight="300"
          style={{ display: 'inline', fontSize: '15px' }}
        >
          {_.get(userContext, 'fullName', '')}
        </Text>
      )
    }
    return (
      <Text
        color="dark"
        weight="300"
        style={{ display: 'inline', fontSize: '15px' }}
      >
        {_.get(userProfile, "primaryUser.fullName", "")}
      </Text>
    )
  }

  const primaryUserID = () => {
    if (userProfile.primaryUser === null) {
      return (
        <Text
          color="dark"
          weight="300"
          style={{ display: 'inline', fontSize: '15px' }}
        >
          {_.get(userContext, 'email', '')}
        </Text>
      )
    }
    return (
      <Text
        color="dark"
        weight="300"
        style={{ display: 'inline', fontSize: '15px' }}
      >
        {_.get(userProfile, "primaryUser.email", "")}
      </Text>
    )
  }

  //dashboard projects
  const projectHistory = () => {
    return (
      <>
        <Text
          weight="light"
          color="secondaryDark"
          style={{
            fontSize: '15px',
            display: 'grid',
            gridTemplateColumns: '24px auto'
          }}
        >
          <Text
            color="primaryLight"
            weight="500"
            style={{ display: 'inline', paddingRight: '10px' }}
          >
            {dashboardProjects.total}
          </Text>
          Registered Projects
        </Text>
        <Text
          weight="light"
          color="secondaryDark"
          style={{
            fontSize: '15px',
            display: 'grid',
            gridTemplateColumns: '24px auto'
          }}
        >
          <Text
            color="primaryLight"
            weight="500"
            style={{ display: 'inline', paddingRight: '10px' }}
          >
            {dashboardProjects.draft}
          </Text>
          Pending Registration Projects
        </Text>
        <div style={{ borderTop: '1px solid #ccc', marginTop: '10px' }}>
          <Bar
            height={170}
            data={stateChart}
            options={{
              title: {
                display: true,
                text: 'Number of installations per month',
                fontSize: 17,
                fontStyle: 'normal',
                fontFamily: 'Actual'
              },
              responsive: true,
              legend: {
                display: false
              },
              scales: {
                xAxes: [
                  {
                    barPercentage: 0.3
                  }
                ],
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                      stepSize: 10
                    }
                  }
                ]
              }
            }}
          />
        </div>
      </>
    );
  };

  //dashboard claims
  const claimHistory = () => {
    return (
      <>
        {Object.entries(dashboardClaims).map((item, index) => (
          <Text
            key={index}
            weight="light"
            color="secondaryDark"
            style={{
              fontSize: '15px',
              display: 'grid',
              gridTemplateColumns: '24px auto'
            }}
          >
            <Text
              color="primaryLight"
              weight="500"
              style={{ display: 'inline', paddingRight: '10px' }}
            >
              {item[1]}
            </Text>
            {item[0]}
          </Text>
        ))}
      </>
    );
  };

  const getAvailableQPoint = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/companies/${userContext.companyId}/reward-master`
      );
      setAvailableQPoint(_.get(data, 'availableQpoints', 0));
    } catch (err) {
      console.log(err);
    }
  };

  const getForecastProgress = async () => {
    try {
      setLoadingForecastProgress(true);
      const allData = await Promise.all([
        axios.get(`${baseUrl}/configurations/forecasts`),
        axios.get(`${baseUrl}/companies/${userContext.companyId}/forecasts`, {
          params: {
            year: todaysYear,
            month: todaysMonth
          }
        }),
        axios.get(`${baseUrl}/forecasts/modules`, {
          params: {
            month: todaysMonth,
            year: todaysYear
          }
        })
      ]);
      setForecastConfig(allData[0].data);
      setArticleCodes(allData[1].data);

      let totalVolume = allData[1].data
        .map(config => {
          return config.forecasts
            .map(forecast => {
              const ac = config.articleCodes.find(
                articleCode => articleCode.id === forecast.articleCodeId
              );
              return forecast.quantity * ac.volume;
            })
            .reduce((a, b) => a + b, 0);
        })
        .reduce((a, b) => a + b, 0);
      setVolume(totalVolume);

      let totalModuleApprovedWattageWithinQuarter = allData[2].data
        .map(ac => +ac.articleCode.volume)
        .reduce((a, b) => a + b, 0);
      setProgress(totalModuleApprovedWattageWithinQuarter);
      setLoadingForecastProgress(false);
    } catch (err) {
      setLoadingForecastProgress(false);
      console.log(err);
    }
  };

  useEffect(() => {
    // getAvailableQPoint();
    // getOrders();
    // getForecastProgress();

    let url = _.get(process.env, 'REACT_APP_QPARTNER_LEGACY_BASE_URL', '')
      .replace('https://', '')
      .replace('http://', '');
    let referrer = document.referrer.replace('https://', '').replace('http://', '');
    if (
      referrer.indexOf(url) == 0 &&
      referrer !== '' &&
      url !== '' &&
      referrer.includes('/members/sso/process.fos?token=')
    ) {
      if (
        getWithExpiry(
          `dontshowagaintoday_${userContext.firstName}_${userContext.lastName}`
        ) === 'true'
      ) {
      } else {
        setNotiModalOpen(true);
      }
    }
  }, []);

  const handleRedirectForecast = () => {
    let currentMonth = moment().month() + 1;
    let currentYear = moment().year();
    return `/rewards/qpoint-forecast/${currentMonth}/${currentYear}`;
  };

  // const handleRedirectShop = async () => {
  //   try {
  //     const { data } = await axios.get(`${baseUrl}/categories`);
  //     const category = data.find(c =>
  //       _.lowerCase(c.name).includes(AURORA_CATEGORY_KEYWORD)
  //     );
  //     history.push(`/rewards/shop?categories=${category.id}`);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  useEffect(() => {
    if (notiModalOpen === false) {
      if (checkboxDontShow === true) {
        var d = new Date();
        var h = d.getHours();
        var m = d.getMinutes();
        var s = d.getSeconds();
        var secondsUntilEndOfDate = 24 * 60 * 60 - h * 60 * 60 - m * 60 - s;
        setWithExpiry(
          `dontshowagaintoday_${userContext.firstName}_${userContext.lastName}`,
          'true',
          secondsUntilEndOfDate * 1000
        );
      }
    }
  }, [notiModalOpen]);

  useEffect(() => {
    const date = localStorage.getItem('isShowModalDashBoard');
    if (date && moment().isSame(date, 'days')) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, []);

  let num = Math.round((100 - (progress / volume) * 100) * 100) / 100;
  let completed = Math.round(100 - num);

  return (
    <>
      <Modal
        // disableScrollLock={true}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="wr_alert_modal"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={innerWidth > 760 ? 'foo48' : 'foo48mobile'} style={{width: "500px !important"}}>
            {popupIndex == 1 && innerWidth < 760 && (
              <div className="prev-btn2" onClick={e => setPopupIndex(0)}>
                <ArrowLeftIcon/>
              </div>
            )}
            {popupIndex == 0 && innerWidth < 760 && (
              <div className="next-btn2" onClick={e => setPopupIndex(1)}>
                <ArrowRightIcon/>
              </div>
            )}
            <div
              className={classNames('wr_popup_image_portal', {
                hidden: popupIndex != 0 && innerWidth < 760
              })}
            >
              <div className="title49">
                <img src={layer1} alt="" />
              </div>

              <div className="wr_go">
                <a
                  onClick={() =>
                    Event(
                      'Dashboard',
                      'View welcome to the new Q.Partner Portal',
                      'welcome_qpartner_portal'
                    )
                  }
                  href={`${process.env.REACT_APP_QPARTNER_LEGACY_BASE_URL}/about/notice_view_portal.fos`}
                >
                  <img src={layer2} alt="" />
                </a>
              </div>
              <div className="footer48">
                <img src={layer3} alt="" />
              </div>
            </div>
            <div
              className={classNames('wr_popup_image_forecast', {
                hidden: popupIndex !== 1 && innerWidth < 760
              })}
            >
              <div className="title49" style={{maxWidth: 'auto'}}>
                <ClearIcon
                  className={'icon-clear'}
                  style={{
                    cursor: 'pointer',
                    fill: 'var(--secondary-dark-color, #ffffff)',
                    position: 'absolute',
                    top: '5px',
                    right : 10,
                    padding: '2px'
                  }}
                  onClick={() => {
                    setNotiModalOpen(false);
                    setOpen(false);
                    Object.defineProperty(document, 'referrer', {
                      configurable: true,
                      get: function() {
                        return '';
                      }
                    });
                  }}
                />
                <img style={{backgroundColor: '#ffffff', width : '100%'}} src={EnFinPopUpBanner1} alt="" />
                <a style={{backgroundColor: '#ffffff'}} target='_blank' href="https://partner.enfin.com/pi/s/piloanapplication">
                  <img style={{backgroundColor: '#ffffff', paddingTop: '0%'}} src={EnFinPopUpBanner2} alt="" />
                </a>
                <img style={{backgroundColor: '#ffffff', width : '100%'}} src={EnFinPopUpBanner3} alt="" />
              </div>
              {/*<div className="title49">*/}
              {/*  <img className={'img-aurora'} src={auroraPopup1} alt="" />*/}
              {/*  <Link to={typeBussiness !== 3 ? "/rewards/shop" : '#'} style={{cursor: typeBussiness === 3 && 'default' }}>*/}
              {/*    <img className={'img-aurora'} src={auroraPopup2} alt="" />*/}
              {/*  </Link>*/}
              {/*  <Link to="/salestools/aurorasolar">*/}
              {/*    <img className={'img-aurora'} style={{marginTop: '-4px'}} src={auroraPopup3} alt="" />*/}
              {/*  </Link>*/}
              {/*</div>*/}

            </div>
            {showDontShowOption ? (
              <div  className="dontopentoday">
                <input
                  style={{ cursor: 'pointer' }}
                  name="checkbox1"
                  id="checkbox1"
                  onChange={event => {
                    if (event.target.checked) {
                      localStorage.setItem(
                        'isShowModalDashBoard',
                        moment().format('YYYY-MM-DD')
                      );
                    } else {
                      localStorage.removeItem('isShowModalDashBoard');
                    }
                  }}
                  type="checkbox"
                />{' '}
                <label htmlFor="checkbox1">Don't show again today.</label>
                <ClearIcon
                  className="clear_dont_show_box"
                  onClick={() => {
                    setCheckboxDontShow(false);
                    setShowDontShowOption(false);
                    setOpen(false);
                  }}
                />
              </div>
            ) : null}
            {/* <ClearIcon
              style={{
                cursor: 'pointer',
                fill: 'var(--secondary-dark-color, #8c8c8c)',
                position: 'absolute',
                top: '5px',
                right: '-200px',
                padding: '2px'
              }}
              onClick={() => {
                setNotiModalOpen(false);
                setOpen(false);
                Object.defineProperty(document, 'referrer', {
                  configurable: true,
                  get: function() {
                    return '';
                  }
                });
              }}
            /> */}
          </div>
        </Fade>
      </Modal>

      <div style={{ backgroundColor: '#f7f7f7', paddingBottom: '11vh' }}>
        {showBanner === true &&
        <Grid
          className="foo108 start1"
          container
          style={{
            fontSize: '15px',
            lineHeight: '24px',
            height: 'fit-content',
            // height: '80px',
            padding: '15px',
            // display: 'flex',
          }}
        >
          <Grid
            className="foo208"
            container
            justify="flex-start"
            alignItems="center"
            style = {{position: "relative"}}
          >
            <Grid item container style = {{position: "relative"}}>
              <img src={Star_icon} alt="Star_icon" width="21" height="21" style = {{position: "absolute", left: "4px"}} />
              <Text
                className="foo206"
                weight= "300"
                style={{ display: 'inline', fontSize: '15px', paddingLeft: "38px", paddingRight: "18px" }}
                color="dark"
              >
                Q CELLS is now offering our&nbsp;
                <span
                    style={{color: '#009EE3', cursor: 'pointer', textDecoration: 'underline'}} 
                    // onClick={() => history.push({
                    //   pathname: '/claim/how-it-works',
                    //   state: {
                    //     isScrollToClaim: true
                    //   }
                    // })}
                    onClick={() => history.push({
                      pathname: '/claim/how-it-works',
                      state: {
                        isScrollToClaim: true
                      }
                    })} 
                  >Q.PARTNERs
                </span>
                &nbsp; up to $500 in labor reimbursement! Register your project and fill out a qualifying claim through the new integrated claims portal.  
              </Text>{' '}
            </Grid>
            <Grid item style = {{position: "absolute", right: "0px"}} onClick={() => {
              setShowBanner(false);
            }}>
              <img src={CloseIcon} alt="Closeicon" width="24" height="24"/>
            </Grid>
          </Grid>
        </Grid>
        }

        <div className={classes.root}>
          <Grid container item xs={12} spacing={2} className='dashboard'>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Paper square elevation={0} variant="outlined" className={classes.paper}>
                  <Grid item container  xs = {12} direction = "row">
                    <Grid item container direction="column" xs style={{ marginLeft: '20px', marginTop: '10px', marginBottom: '10px'}}>
                      <Grid style = {{marginBottom: "5px"}}>
                        <Text
                          color="dark"
                          weight="400"
                          style={{fontSize: '30px' }}
                        >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `Welcome back ${_.get(userContext, 'firstName', '')}! 👋`
                          }}
                        />
                        </Text>
                      </Grid>
                      <Grid className="foo200" container direction="row" spacing = {10} >
                        <Grid item >
                          <div>
                            <Text
                              className="center_xs have_you"
                              color="dark"
                              weight="400"
                              style={{ display: 'inline', fontSize: '15px' }}
                            >
                              Company:
                            </Text>
                            {' '}
                            <Text
                              color="dark"
                              weight="300"
                              style={{ display: 'inline', fontSize: '15px' }}
                            >
                              {_.get(userContext, 'companyName', '')}
                            </Text>
                          </div>
                          <div>
                            <Text
                              className="center_xs"
                              color="dark"
                              weight="400"
                              style={{ display: 'inline', fontSize: '15px' }}
                            >
                              Primary User:
                            </Text>
                            {' '}
                            {primaryUser()}
                          </div>
                          <div>
                            <Text
                              className="center_xs"
                              color="dark"
                              weight="400"
                              style={{ display: 'inline', fontSize: '15px' }}
                            >
                              Primary User ID:
                            </Text>
                            {' '}
                            <Text
                              color="dark"
                              weight="300"
                              style={{ display: 'inline', fontSize: '15px' }}
                            >
                              {primaryUserID()}
                            </Text>
                          </div>
                        </Grid>
                        <Grid item>
                          <div>
                            <Text
                              className="center_xs have_you"
                              color="dark"
                              weight="400"
                              style={{ display: 'inline', fontSize: '15px' }}
                            >
                              User Name:
                            </Text>
                            {' '}
                            <Text
                              color="dark"
                              weight="300"
                              style={{ display: 'inline', fontSize: '15px' }}
                            >
                              {_.get(userContext, 'fullName', '')}
                            </Text>
                          </div>
                          <div>
                            <Text
                              className="center_xs"
                              color="dark"
                              weight="400"
                              style={{ display: 'inline', fontSize: '15px' }}
                            >
                              User ID:
                            </Text>
                            {' '}
                            <Text
                              color="dark"
                              weight="300"
                              style={{ display: 'inline', fontSize: '15px' }}
                            >
                              {_.get(userContext, 'email', '')}
                            </Text>
                          </div>
                          <div>
                            <Text
                              className="center_xs"
                              color="dark"
                              weight="400"
                              style={{ display: 'inline', fontSize: '15px' }}
                            >
                              User Type:
                            </Text>
                            {' '}
                            <Text
                              color="dark"
                              weight="300"
                              style={{ display: 'inline', fontSize: '15px' }}
                            >
                              {_.get(userContext, 'role', '')}
                            </Text>
                            {' '}
                            <Text
                              color="dark"
                              weight="300"
                              style={{ display: 'inline', fontSize: '15px' }}
                            >
                              user
                            </Text>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <StyledButton
                        onClick={() => window.location = '/mypage/member'}>
                        Update Profile</StyledButton>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item container xs = {12} spacing = {0}>
                {typeBussiness !== 3 && 
                // {/* Project */}
                <Grid item xs = {12} sm = {12} md={760 < width && width < 1280 ? 6 : 4} lg = {4}>
                  <Box borderBottom={1} borderRight = {1} borderLeft = {1} className={classes.box}>
                    <Grid container spacing = {3}>
                      <Grid item xs = {3} style={{ marginLeft: '22px'}}>
                        <img className="foo200" src={panel_icon} alt="panel_icon" width="65" height="65"/>
                      </Grid>
                      <Grid item xs={12} sm container direction="column" spacing={2}>
                        <Text
                          color="dark"
                          weight="regular"
                          style={{fontSize: '20px' }}
                        >
                          Projects
                        </Text>
                        <Grid container spacing={5}>
                          <Grid item>
                            <div>
                              <Text
                                color="dark"
                                weight="400"
                                style={{ display: 'inline', fontSize: '15px' }}
                              >
                                {dashboardProjects?.total}
                              </Text>
                              {' '}
                              <Text
                                color="dark"
                                weight="300"
                                style={{ display: 'inline', fontSize: '15px' }}
                              >
                                Completed
                              </Text>
                            </div>
                          </Grid>
                          <Grid item>
                            <div>
                              <Text
                                color="dark"
                                weight="400"
                                style={{ display: 'inline', fontSize: '15px' }}
                              >
                                {dashboardProjects?.draft}
                              </Text>
                              {' '}
                              <Text
                                color="dark"
                                weight="300"
                                style={{ display: 'inline', fontSize: '15px' }}
                              >
                                Drafts
                              </Text>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container direction = "column" style = {{marginTop: "8px"}}>
                          <Grid item>
                            <Link to = {"projects/project-registration"}>
                              <Text
                                weight="400"
                                style={{paddingBottom: "4px", textDecoration: "underline 1px", textUnderlineOffset: "2px", color: '#009EE3',fontSize: '12px' }}
                              >
                                REGISTER NEW PROJECT
                              </Text>
                            </Link>
                          </Grid>
                          <Grid item>
                            <p onClick={() => history.push({
                              pathname: "/projects/myprojects",
                              state: {
                                isDraft: true
                              }
                            })}
                               style={{cursor: 'pointer'}}>
                              <Text
                                weight="400"
                                style={{textDecoration: "underline 1px", textUnderlineOffset: "2px", color: '#009EE3',fontSize: '12px' }}
                              >
                                COMPLETE DRAFT
                              </Text>
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid> }

                {/* Claim */}
                <Grid item xs = {12} sm = {12} md={760 < width && width < 1280 ? typeBussiness === 3 ? 12 : 6 : 4} lg ={typeBussiness === 3 ? 12 : 4}  style={{borderLeft: typeBussiness === 3 && '1px solid #E0E0E0'}}>
                  <CustomBox className = {classes.box} width = {width} type = "1">
                    <Grid container spacing = {3}>
                      <Grid item xs={typeBussiness === 3 ? 2 : 3} md={typeBussiness === 3 ? 1 : 3} style={{ marginLeft: '22px', minWidth: 87}}>
                        <img className="foo200" src={letter_icon} alt="letter_icon" width="65" height="65"/>
                      </Grid>
                      <Grid item xs={12} sm container direction="column" spacing={2}>
                        <Text
                          color="dark"
                          weight="regular"
                          style={{fontSize: '20px' }}
                        >
                          Claims
                        </Text>
                        <Grid container spacing={5}>
                          <Grid item>
                            <div>
                              <Text
                                color="dark"
                                weight="400"
                                style={{ display: 'inline', fontSize: '15px' }}
                              >
                                {openClaim}
                              </Text>
                              {' '}
                              <Text
                                color="dark"
                                weight="300"
                                style={{ display: 'inline', fontSize: '15px' }}
                              >
                                Open
                              </Text>
                            </div>
                          </Grid>
                          <Grid item>
                            <div>
                              <Text
                                color="dark"
                                weight="400"
                                style={{ display: 'inline', fontSize: '15px' }}
                              >
                                {pendingClaim}
                              </Text>
                              {' '}
                              <Text
                                color="dark"
                                weight="300"
                                style={{ display: 'inline', fontSize: '15px' }}
                              >
                                Pending
                              </Text>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container direction = "column" style={{ marginTop: '8px'}}>
                          <Link to = {"claim/history"}>
                            <Text
                              weight="400"
                              style={{paddingBottom: "4px", textDecoration: "underline 1px", textUnderlineOffset: "2px", color: '#009EE3',fontSize: '12px' }}
                            >
                              MANAGE CLAIMS
                            </Text>
                          </Link>
                          <Link to = {"/claim/info"}>
                            <Text
                              weight="400"
                              style={{textDecoration: "underline 1px", textUnderlineOffset: "2px", color: '#009EE3',fontSize: '12px' }}
                            >
                              SUBMIT A CLAIM
                            </Text>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CustomBox>
                </Grid>

                {typeBussiness !== 3 &&
                // {/* Rewards */}
                <Grid item xs = {12} sm = {12} md={760 < width && width < 1280 ? 6 : 4} lg = {4}>
                  <CustomBox className = {classes.box} width = {width} type = "2">
                    <Grid container spacing = {3}>
                      <Grid item xs = {3} style={{ marginLeft: '22px'}}>
                        <img className="foo200" src={DataHubImg} alt="pig_icon" width="65" height="65"/>
                      </Grid>
                      <Grid item xs={12} sm container direction="column" spacing={2}>
                        <Text
                          color="dark"
                          weight="regular"
                          style={{fontSize: '20px' }}
                        >
                          Data Hub
                        </Text>
                        <Grid container direction = "column" style={{ marginTop: '8px'}}>
                          <Link to = {"/salestools/data-hub/download-center"}>
                            <Text
                              weight="400"
                              style={{paddingBottom: "4px", textDecoration: "underline 1px", textUnderlineOffset: "2px", color: '#009EE3',fontSize: '12px' }}
                            >
                              DOWNLOAD CENTER
                            </Text>
                          </Link>
                          <Link to = {"/salestools/data-hub/video-content"}>
                            <Text
                              weight="400"
                              style={{textDecoration: "underline 1px", textUnderlineOffset: "2px", color: '#009EE3',fontSize: '12px' }}
                            >
                              VIDEO CONTENT
                            </Text>
                          </Link>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item>
                            <div>
                              <Text
                                color="dark"
                                weight="400"
                                style={{ display: 'inline', fontSize: '15px', opacity: 0 }}
                              >
                                EMPTY LINE
                              </Text>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CustomBox>
                </Grid>}
              </Grid>
            </Grid>

            <Grid container item xs={12} sm={12} md={760 < width && width < 1150 ? 12 : 12} lg={typeBussiness === 3 ? 12 : 12} className="foo21 order1">
              {/*{typeBussiness !== 3 && */}
              {/*<Grid item xs = {12}>*/}
              {/*  <Paper elevation={0} variant="outlined" square className={classes.paper}>*/}
              {/*    <Grid container direction = "row" alignItems="center" spacing = {2}>*/}
              {/*      <Grid item xs = {1} style = {{marginLeft : "20px"}}>*/}
              {/*        <Text*/}
              {/*          color="dark"*/}
              {/*          weight="regular"*/}
              {/*          style={{fontSize: '20px' }}*/}
              {/*        >*/}
              {/*          Orders*/}
              {/*        </Text>*/}
              {/*      </Grid>*/}
              {/*      <Grid item container xs = {8} spacing = {3} style = {{marginLeft : "10px"}}>*/}
              {/*        {orderHistory()}*/}
              {/*      </Grid>*/}
              {/*      <Grid item xs container justify = "flex-end" >*/}
              {/*        <Grid item style = {{marginRight: "10px"}}>*/}
              {/*          <CustomButton*/}
              {/*            disabled={typeBussiness === 3}*/}
              {/*            component={Link}*/}
              {/*            to = {"/orderhistory"}*/}
              {/*            style={{*/}
              {/*              height: '4px',*/}
              {/*              width: '8px',*/}
              {/*              padding: '1rem 1rem'*/}
              {/*            }}*/}
              {/*            color="secondary"*/}
              {/*          >*/}
              {/*            <Text weight="regular">Manage Orders</Text>*/}
              {/*          </CustomButton>*/}
              {/*        </Grid>*/}
              {/*      </Grid>*/}
              {/*    </Grid>*/}
              {/*  </Paper>*/}
              {/*</Grid>}*/}

              <Grid className="item5 item_training" item xs={12} sm={6} md={6} style={{ marginTop:  typeBussiness !== 3 ? '16px' : 0}}>
                <Link
                  to="/academy"
                  onClick={e => handleThinkificSSO(e, '/collections/ess')}
                >
                  <Paper
                    style={{ height: '100%', position: 'relative' }}
                    elevation={0}
                    disablePadding
                    variant="outlined"
                    className={classes.paper}
                  >
                    <Card
                      className="foo210"
                      cardTitle="Training"
                      onClick={e => handleThinkificSSO(e, '/collections/ess')}
                    >
                      <img
                        src={NewsCardImage2_1}
                        alt="placeholder"
                        style={{
                          width: '100%',
                          height: 'auto',
                          cursor: 'pointer'
                        }}
                      />
                      <Text
                        className="foo209"
                        color="dark"
                        weight="regular"
                        style={{
                          fontSize: '18px',
                          lineHeight: '25px',
                          paddingTop: '22px',
                          paddingBottom: '126px',
                          cursor: 'pointer'
                        }}
                      >
                        Q.HOME CORE ENERGY STORAGE SYSTEM CERTIFICATION COURSE
                      </Text>
                      <Text
                        color="dark"
                        weight="light"
                        style={{
                          lineHeight: '26px',
                          fontSize: '15px',
                          cursor: 'pointer',
                          position:"absolute",
                          bottom: "72px",
                          paddingRight: 16
                        }}
                      >
                        GET CERTIFIED TODAY SO THAT YOU CAN BEGIN INSTALLING the Q.HOME CORE ENERGY STORAGE SYSTEM
                      </Text>
                    </Card>
                    <CustomButton
                      style={{
                        height: '10px',
                        width: '120px',
                        padding: '1rem 1rem',
                        position:"absolute",
                        bottom: "22px"
                      }}
                      color="secondary"
                      onClick={e => handleThinkificSSO(e, '/collections/ess')}
                    >
                      Learn More
                    </CustomButton>
                  </Paper>
                </Link>
              </Grid>

              <Grid className="item5 item_news" item xs={12} sm={6} md={6} style={{ marginTop: typeBussiness !== 3 ? '16px' : 0}}>
                <span onClick = {() => history.push(`/notice/509`)}>
                  <Paper
                    style={{ height: '100%', position: 'relative'}}
                    elevation={0}
                    variant="outlined"
                    className={classes.paper}
                  >
                    <Card
                      className="foo210"
                      cardTitle="News"
                      link={`/notice/${infoNewLaTest?.id}`}
                    >
                      <img
                        src={QcellsAuroraImage}
                        alt="placeholder"
                        style={{
                          width: '100%',
                          height: 'auto',
                          cursor: 'pointer'
                        }}
                      />
                      <Text
                        className="foo209"
                        color="dark"
                        weight="regular"
                        style={{
                          fontSize: '18px',
                          lineHeight: '25px',
                          paddingTop: '15px',
                          paddingBottom: '126px',
                          cursor: 'pointer'
                        }}
                      >
                        Qcells now offering labor reimbursement for qualifying claims!
                      </Text>
                      <Text
                        color="dark"
                        weight="light"
                        style={{
                          lineHeight: '26px',
                          fontSize: '15px',
                          cursor: 'pointer',
                          position:"absolute",
                          bottom: "72px",
                          paddingRight: 16
                        }}
                      >
                        Receive up to $500 per project in labor reimbursement after registering your project and submitting a qualifying claim!
                      </Text>
                    </Card>
                    <CustomButton
                      style={{
                        height: '10px',
                        width: '120px',
                        padding: '1rem 1rem',
                        position:"absolute",
                        bottom: "22px"
                      }}
                      color="secondary"
                      // component={Link}
                      onClick = {() => history.push(`/notice/509`)}
                    >
                      Learn More
                    </CustomButton>
                  </Paper>
                </span>
              </Grid>

            </Grid>

            {/*<Grid item xs={12} sm={12} md={760 < width && width < 1150 ? 12 : 3} lg={3} className='order3'>*/}
            {/*  <Grid item xs={12} lg={12} sm={760 < width && width < 1150 ? 6 : 12}>*/}
            {/*    {typeBussiness === 3 ? */}
            {/*    false :*/}
            {/*    !isLoadingForecastProgress ? (*/}
            {/*      <Paper elevation={0} variant="outlined" className={classes.paper}>*/}
            {/*        <Grid container justify="center">*/}
            {/*          {articleCodes.length === 0 ? (*/}
            {/*            <Grid item container justify="center" spacing = {2}>*/}
            {/*              <Card2*/}
            {/*                className="foo210"*/}
            {/*                cardTitle="Forecast Progress"*/}
            {/*                style = {{ justify: 'center'}}*/}
            {/*                onClick={e => {*/}
            {/*                  e.stopPropagation();*/}
            {/*                  sendEventForDashBoard(*/}
            {/*                    ACTION_VIEW_CURRENT_MONTH_FORECAST*/}
            {/*                  );*/}
            {/*                  history.push(*/}
            {/*                    `/rewards/qpoint-forecast/${moment().month() +*/}
            {/*                    1}/${moment().year()}`*/}
            {/*                  );*/}
            {/*                }}*/}
            {/*              >*/}
            {/*                <Text*/}
            {/*                  color="Dark"*/}
            {/*                  weight="300"*/}
            {/*                  style={{fontSize: '15px', paddingBottom: '10px' , textAlign: 'center' }}*/}
            {/*                >*/}
            {/*                  No forecast submitted*/}
            {/*                </Text>*/}
            {/*              </Card2>*/}
            {/*              { typeBussiness !== 3 && */}
            {/*              <CustomButton*/}
            {/*                style={{*/}
            {/*                  height: '10px',*/}
            {/*                  width: '250px',*/}
            {/*                  padding: '1rem 1rem'*/}
            {/*                }}*/}
            {/*                color="secondary"*/}
            {/*                component={Link}*/}
            {/*                to={() => {*/}
            {/*                  let currentMonth = moment().month();*/}
            {/*                  let currentYear = moment().year();*/}
            {/*                  let nextMonth = currentMonth + 2;*/}

            {/*                  if (nextMonth === 13) {*/}
            {/*                    nextMonth = 1;*/}
            {/*                    currentYear = currentYear + 1;*/}
            {/*                  }*/}

            {/*                  return `/rewards/qpoint-forecast/${nextMonth}/${currentYear}`;*/}
            {/*                }}*/}
            {/*                onClick={() =>*/}
            {/*                  sendEventForDashBoard(*/}
            {/*                    ACTION_SUBMIT_FORCAST_NEXT_MONTH*/}
            {/*                  )*/}
            {/*                }*/}
            {/*              >*/}
            {/*                Submit Forecast*/}
            {/*              </CustomButton> }*/}
            {/*            </Grid>*/}

            {/*          ) : (*/}
            {/*            <Card2*/}
            {/*              cardTitle="Forecast Progress"*/}
            {/*              icon*/}
            {/*              onClick={e => {*/}
            {/*                e.stopPropagation();*/}
            {/*                sendEventForDashBoard(*/}
            {/*                  ACTION_VIEW_CURRENT_MONTH_FORECAST*/}
            {/*                );*/}
            {/*                history.push(*/}
            {/*                  `/rewards/qpoint-forecast/${moment().month() +*/}
            {/*                  1}/${moment().year()}`*/}
            {/*                );*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              <Text weight="light">*/}
            {/*                Total Volume of {moment().format('MMM, YYYY')}*/}
            {/*              </Text>*/}

            {/*              <ProgressBar*/}
            {/*                progress={progress}*/}
            {/*                goal={volume}*/}
            {/*                volume={progress}*/}
            {/*              />*/}

            {/*              {Math.ceil(completed) < 100 ? (*/}
            {/*                <Text*/}
            {/*                  weight="light"*/}
            {/*                  style={{*/}
            {/*                    display: 'inline',*/}
            {/*                    fontSize: '12px'*/}
            {/*                  }}*/}
            {/*                >*/}
            {/*                  You have{' '}*/}
            {/*                  <span style={{ color: '#00ADEF' }}>*/}
            {/*                  {(volume - progress).toLocaleString()} W*/}
            {/*                </span>{' '}*/}
            {/*                  to go until you reach your forecast!*/}
            {/*                </Text>*/}
            {/*              ) : Math.ceil(completed) >= 100 &&*/}
            {/*              Math.ceil(completed) <=*/}
            {/*              100 + forecastConfig.percentage &&*/}
            {/*              progress < forecastConfig.achievedVolume ? (*/}
            {/*                <Text weight="light" style={{ fontSize: '12px' }}>*/}
            {/*                  You met your forecast, congratulations!*/}
            {/*                </Text>*/}
            {/*              ) : Math.ceil(completed) >= 100 &&*/}
            {/*              Math.ceil(completed) <=*/}
            {/*              100 + forecastConfig.percentage &&*/}
            {/*              progress >= forecastConfig.achievedVolume ? (*/}
            {/*                <Text weight="light" style={{ fontSize: '12px' }}>*/}
            {/*                  You met your forecast by 100-*/}
            {/*                  {100 + forecastConfig.percentage}%, congratulations!*/}
            {/*                  Enjoy your extra Q.POINTs.*/}
            {/*                </Text>*/}
            {/*              ) : Math.ceil(completed) > 100 ? (*/}
            {/*                <Text*/}
            {/*                  weight="light"*/}
            {/*                  style={{*/}
            {/*                    display: 'inline',*/}
            {/*                    fontSize: '12px'*/}
            {/*                  }}*/}
            {/*                >*/}
            {/*                  You exceeded your forecast by{' '}*/}
            {/*                  <span style={{ color: '#00ADEF' }}>*/}
            {/*                  {(progress - volume).toLocaleString()} W*/}
            {/*                </span>*/}
            {/*                  ! Set your forecast higher next month!*/}
            {/*                </Text>*/}
            {/*              ) : (*/}
            {/*                <Text weight="light" style={{ fontSize: '12px' }}>*/}
            {/*                  You missed your forecast by{' '}*/}
            {/*                  <span style={{ color: '#00ADEF' }}>*/}
            {/*                  {(volume - progress).toLocaleString()} W*/}
            {/*                </span>*/}
            {/*                  . Try again next month!*/}
            {/*                </Text>*/}
            {/*              )}*/}
            {/*            </Card2>*/}
            {/*          )}*/}
            {/*        </Grid>*/}
            {/*      </Paper>*/}
            {/*    ) : (*/}
            {/*      <Paper elevation={0} variant="outlined" className={classes.paper}>*/}
            {/*        <Card2*/}
            {/*          className="foo210"*/}
            {/*          cardTitle="Forecast Progress"*/}
            {/*          icon={false}*/}
            {/*        >*/}
            {/*          <div*/}
            {/*            style={{*/}
            {/*              display: 'flex',*/}
            {/*              justifyContent: 'center',*/}
            {/*              alignItems: 'center',*/}
            {/*              width: '100%'*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            <CustomLoading size={24} />*/}
            {/*          </div>*/}
            {/*        </Card2>*/}
            {/*      </Paper>*/}
            {/*    )*/}
            {/*    }*/}
            {/*    */}
            {/*  </Grid>*/}
            {/*  {typeBussiness === 3 ? */}
            {/*  false :*/}
            {/*  <Grid style = {{marginTop: "16px"}} item xs={12} lg={12} sm={760 < width && width < 1150 ? 6 : 12}>*/}
            {/*  <Paper elevation={0} variant="outlined" className={classes.paper}>*/}
            {/*    <Grid container justify="center" spacing = {2}>*/}
            {/*      <Grid item>*/}
            {/*        <img src={PanelIcon} alt="Panel" width="77" height="67"/>*/}
            {/*      </Grid>*/}
            {/*      <Grid item xs={12}>*/}
            {/*        <Text*/}
            {/*          className="center_xs have_you"*/}
            {/*          color="dark"*/}
            {/*          weight="medium"*/}
            {/*          style={{ fontSize: '20px', textAlign: 'center' }}*/}
            {/*        >*/}
            {/*          Are you earning your rewards?*/}
            {/*        </Text>*/}
            {/*      </Grid>*/}
            {/*      <Grid item xs={12}>*/}
            {/*        <Text*/}
            {/*          className="center_xs"*/}
            {/*          color="dark"*/}
            {/*          weight="300"*/}
            {/*          style={{ fontSize: '15px', textAlign: 'center' }}*/}
            {/*        >*/}
            {/*          Register your new projects, product orders, or forecast today and start earning Q.POINTs*/}
            {/*        </Text>*/}
            {/*      </Grid>*/}
            {/*      {typeBussiness !== 3 && */}
            {/*      <CustomButton*/}
            {/*        style={{*/}
            {/*          height: '10px',*/}
            {/*          width: '250px',*/}
            {/*          padding: '1rem 1rem'*/}
            {/*        }}*/}
            {/*        color="secondary"*/}
            {/*        component={Link}*/}
            {/*        to="/rewards/qpoint-introduction"*/}
            {/*        onClick={() =>*/}
            {/*          sendEventForDashBoard(ACTION_REGISTER_PRODUCT)*/}
            {/*        }*/}
            {/*      >*/}
            {/*        Learn More*/}
            {/*      </CustomButton>}*/}
            {/*    </Grid>*/}
            {/*  </Paper>*/}
            {/*</Grid>*/}
            {/*  }*/}
            {/*</Grid>*/}
          </Grid>
        </div>
      </div>
    </>
  );
}
