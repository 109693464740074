import * as yup from 'yup';

export const schemaIn = yup.object().shape({
  claimType : yup.string().notOneOf(['select'], 'Claim Type is required'),
  type : yup.string().notOneOf(['select'], 'Value Claim Type is required'),
  submitterEmail: yup.string().when('checkedEmail', {
    is : (checkedEmail) => checkedEmail,
    then : yup.string().matches(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/, 'Invalid email address')
  })
})
