import React, { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAPIClient } from './api';
import { useAuth } from './auth';
import _ from 'lodash';
import { notiSuccess, notiFailed } from './../utils/helpers.js';

const aggregate = orderItems => {
  let listProduct = orderItems.map(item => {
    return item.product;
  });
  let listProductUnique = _.sortedUniqBy(_.sortBy(listProduct, 'id'), 'id');
  let shippingOb = listProductUnique.reduce(
    (acc, curr) => {
      let shipping = 0;
      if (parseFloat(_.get(curr, 'shipping')) > 0) {
        shipping += parseFloat(_.get(curr, 'shipping'));
      }
      return {
        shipping: acc.shipping + shipping
      };
    },
    { shipping: 0 }
  );

  let s2 = orderItems.reduce(
    (acc, curr) => {
      return {
        total: acc.total + curr.variant.qpoints * curr.quantity,
        quantity: acc.quantity + curr.quantity
      };
    },
    { total: 0, quantity: 0 }
  );

  let result = { ...shippingOb, ...s2 };
  return result;
};

const Context = React.createContext(null);

export const ShoppingCartContext = ({ children }) => {
  const apiClient = useAPIClient();
  const user = useAuth();
  const [status, setStatus] = useState('loading');
  const [error, setError] = useState(undefined);
  const [items, setItems] = useState([]);
  const [statusCheckConfirm, setStatusCheckConfirm] = useState(true);
  const [totalAuraInCard, setTotalAuraInCard] = useState(0);
  const [order, setOrder] = useState({ total: 0, shipping: 0 });
  const [numberOfCartItem, set_numberOfCartItem] = useState(null);
  const [statusAura, setStatusAura] = useState('');
  const [statusProduct, setStatusProduct] = useState('');
  const [isPreventReFetchListProduct, set_isPreventReFetchListProduct] = useState(
    null
  );
  const [products, set_products] = useState(undefined);
  const [statusProducts, setStatusProducts] = useState('loading');
  const [productListFirstLoad, setProductListFirstLoad] = useState(true);
  const [companyRewards, setCompanyRewards] = useState({});
  const [countProductInAura, setCountProductInAura] = useState(0);
  const statusCartText = statusAura + statusProduct;

  const actions  = {
    'aura' : true,
    'product' : true,
    'auraproduct' : false,
    'multiauraproduct' : false,
    'default' : false
  }

  let action = actions[statusCartText] || actions['default'], statusCard = action;

  const [
    listCartIdThatQuantityGreaterThanStock,
    set_listCartIdThatQuantityGreaterThanStock
  ] = useState([]);

  useEffect(() => {

  }, [])

  const fetchInit = useCallback(async () => {
    if (user.id) {
      try {
        const d = await apiClient.getCompanyRewards(user.companyId);
        setCompanyRewards(d.data.company);
        const { data } = await apiClient.getCart(user.id);
        const cartItemProductNotDeleted = data.filter(o => !!o.variant);
        const productInAura = data.filter((info) => {
          return !!info?.variant?.custom
        });
        const productNotInAura = data.filter((info) => {
          if(info.product !== null) {
            return !info?.variant?.custom
          }
        });

        if(productInAura.length > 0 && productInAura.length === 1) {
          setStatusAura('aura')
        }else if(productInAura.length > 1) {
          setStatusAura('multiaura')
        }else {
          setStatusAura('');
        }

        // productInAura.length === 1 ? setStatusAura('aura') : setStatusAura('');
        setCountProductInAura(productInAura.length || 0);
        productNotInAura.length > 0 ? setStatusProduct('product') : setStatusProduct('');
        setTotalAuraInCard(productInAura.length);
        set_numberOfCartItem(cartItemProductNotDeleted.length);
        setItems(cartItemProductNotDeleted);
        setOrder(aggregate(cartItemProductNotDeleted));
        setStatus('loaded');
      } catch (error) {
        setError(error);
        setStatus('error');
      }
    }
  }, [apiClient, user.id]);

  const fetch = useCallback(async () => {
    if (user.id) {
      try {
        const { data } = await apiClient.getCart(user.id);
        const cartItemProductNotDeleted = data.filter(o => !!o.variant);
        set_numberOfCartItem(cartItemProductNotDeleted.length);
        setItems(cartItemProductNotDeleted);
        const productInAura = data.filter((info) => {
          return !!info?.variant?.custom
        });
        const productNotInAura = data.filter((info) => {
          if(info.product !== null) {
            return !info?.variant?.custom
          }
        });

        if(productInAura.length > 0 && productInAura.length === 1) {
          setStatusAura('aura')
        }else if(productInAura.length > 1) {
          setStatusAura('multiaura')
        }else {
          setStatusAura('');
        }

        setCountProductInAura(productInAura.length || 0);
        productNotInAura.length > 0 ? setStatusProduct('product') : setStatusProduct('');
        setTotalAuraInCard(productInAura.length);
        setOrder(aggregate(cartItemProductNotDeleted));
        setStatus('loaded');
      } catch (error) {
        setError(error);
        setStatus('error');
      }
    }
  }, [apiClient, user.id]);

  const removeCartProduct = useCallback(
    async item => {
      try {
        await apiClient.removeCartProduct(item.id);
        await fetch();
      } catch (error) {
        setError(error);
        setStatus('error');
      }
    },
    [apiClient, fetch]
  );

  const addCartProduct = useCallback(
    async (item, quantity, variant) => {
      try {
        setStatus('loading');
        await apiClient.addToCart({
          userId: user.id,
          productId: item.id,
          quantity,
          variantId: variant.id
        });
        const d = await apiClient.getCompanyRewards(user.companyId);
        setCompanyRewards(d.data.company);
        await fetch();
        setStatus('loaded');
        notiSuccess('Added product to cart');

        return;
      } catch (error) {
        setError(error);
        setStatus('error');

        notiFailed('Unable to add product to cart');
        return;
      }
    },
    [apiClient, fetch, user.id]
  );

  const updateCartProduct = useCallback(
    async (item, quantity, consolidate, purchase) => {
      try {
        await apiClient.updateCart(item.id, {
          userId: user.id,
          id: item.id,
          variantId: item.variantId,
          quantity,
          purchaseEvery : purchase || null,
          consolidate
        });

        await fetch();
      } catch (error) {
        setError(error);
        setStatus('error');
      }
    },
    [apiClient, fetch, user.id]
  );

  useEffect(() => {
    fetchInit();
  }, [fetchInit]);

  return (
    <Context.Provider
      value={{
        status,
        statusCard,
        error,
        order,
        items,
        addCartProduct,
        updateCartProduct,
        removeCartProduct,
        listCartIdThatQuantityGreaterThanStock,
        set_listCartIdThatQuantityGreaterThanStock,
        numberOfCartItem,
        isPreventReFetchListProduct,
        set_isPreventReFetchListProduct,
        products,
        set_products,
        statusProducts,
        setStatusProducts,
        productListFirstLoad,
        countProductInAura,
        setProductListFirstLoad,
        companyRewards
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useShoppingCart = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('ShoppingCartContext missing');
  }

  return context;
};
