import axios from 'axios';
import configs from '../config';

const axiosInstance = axios;

axiosInstance.interceptors.request.use(request => {
  const user = JSON.parse(localStorage.getItem('qpartner_user'));
  if (user) {
    const { token } = user;
    request.headers['Authorization'] = `Bearer ${token}`;
  }
  return request;
});

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  ({ response, config }) => {
    if (response) {
      if (response.status === 401) {
        if (!localStorage.getItem('qpartner_user') || config.url === '/users/profile') {
          return Promise.reject(response);
        }
        localStorage.removeItem('qpartner_user');
        if (window.location.pathname !== '/sso/login') {
          if (window.location.pathname === '/salestools/opensolar' ||
          window.location.pathname === '/rewards/qpoint-introduction' ||
          window.location.pathname === '/rewards/shop' ||
          window.location.pathname === '/qpartner/nda' ||
          window.location.pathname === '/register' ||
          window.location.pathname === '/salestools/aurorasolar' ||
          window.location.pathname.includes('/rewards/shop') ||
          window.location.pathname === '/terms' ||
          window.location.pathname === '/policy' ||
          window.location.pathname === '/about/program-introduction' ||
          window.location.pathname === '/salestools/data-hub/download-center' ||
          window.location.pathname === '/salestools/data-hub/video-content' ||
          window.location.pathname === '/support/faq/1' ||
          window.location.pathname === '/support/contact-us' ||
          window.location.pathname === '/sitemap' ||
          window.location.pathname === '/' ||
          window.location.pathname === '/ccpa-privacy-notice' ||
          window.location.pathname === `/notice/${configs.idNewsWithoutPromoCode}` ||
          window.location.pathname === '/notice' || window.location.pathname === '/claim/how-it-works' ||  window.location.pathname === '/projects/project-introduction'

          ) {
            return;
          }
          if (
            !document.referrer.includes('http://ec2-18') &&
            !document.referrer.includes('http://3.219')
          ) {
            alert('Please try again logging in.');
            console.log('Please try again after logging in.5');
            window.location.href = `/sso/login?url=${window.location.pathname}`;
          }
        }
      }
      if (response.status === 422) {
        return Promise.reject(response);
      }
      if (response.status === 500) {
        return Promise.reject(response);
      }
    }
  }
);

export default axiosInstance;
