import React from 'react';
import styled from 'styled-components';

const SwitchContainer = styled.div`
    .switch {
        visibility: hidden;
        position: absolute;
        margin-left: -9999px;
    }
      
    .switch + label {
        display: block;
        position: relative;
        cursor: pointer;
        outline: none;
        user-select: none;
    }
      
    .switch--flat + label {
        padding: 2px;
        width: 52px;
        height: 28px;
        background-color: #C8C8C8;
        border-radius: 16px;
        transition: background 0.4s;
    }

    .switch--flat + label:before,
    .switch--flat + label:after {
        display: block;
        position: absolute;
        content: "";
    }

    .switch--flat + label:before {
        top: 1px;
        left: 1px;
        bottom: 1px;
        right: 1px;
        background-color: #C8C8C8;
        border-radius: 60px;
        transition: background 0.4s;
    }

    .switch--flat + label:after {
        top: 2px;
        left: 2px;
        bottom: 2px;
        width: 24px;
        height: 24px;
        background-color: #FFF;
        border-radius: 16px;
        transition: margin 0.4s, background 0.4s;
    }

    .switch--flat:checked + label {
        background-color: #02A3EC;
    }

    .switch--flat:checked + label:before {
        background-color: #02A3EC;
    }

    .switch--flat:checked + label:after {
        margin-left: 24px;
        background-color: #FFF;
    }
`;

export default function SwitchButton({ id, register, ...props }) {
    return (
        <SwitchContainer>
            <input id={id} class="switch switch--flat" type="checkbox" ref={register} {...props} />
            <label for={id}></label>
        </SwitchContainer>
    )
}