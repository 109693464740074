import React, { useState } from 'react';
import styled from 'styled-components';
import Account from '../../icons/account_step1.svg';
import Company from '../../icons/company_step2.svg';
import Business from '../../icons/business_step3.svg';
import Review from '../../icons/review_step4.svg';

const BarContainer = styled.div`
  padding-top: 47px !important;
  background-color: #F6F6F6;

  @media (max-width: 780px) {
    padding-top: 30px !important;
  }
`;

const Bar = styled.ul`
  display: flex;
  width: 90%;
  overflow: hidden;
  color: lightgrey;
  margin: auto !important;
  
  @media (min-width: 1280px) {
    width: 72.5%;
    min-width: 650px;
  }

  @media (max-width: 780px) {
    width: 100%;
  }
`;

const ProgressBarStep = styled.li`
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
  z-index: 1;
  text-align: center;
  font-family: 'Actual_Light';
  border-bottom: 5px solid ${({ current }) => current ? '#02A3EC': '#F6F6F6'};

  &:hover {
    cursor: ${({ isEnableClick }) => isEnableClick ? 'pointer' : 'default' };
  }
`;

const IconWrapper = styled.div`
  width: 58.24px;
  height: 47px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  border-radius: 50%;
  margin: 0 auto 20px auto;

  color: ${({ current, theme }) =>
  current ? theme.colors.light : theme.colors.secondaryDark};

  @media (max-width: 780px) {
    margin-bottom: 14px;
    width: 50.4px;
    height: 45px;
  }
`;

const Title = styled.h4`
  font-size: 16px;
  font-family: 'Actual';
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  color: ${({ current }) => current ? '#02A3EC' : '#000'};
  margin-bottom: 21px;
  opacity: ${({ current }) => current ? 1 : 0.3};

  @media (max-width: 780px) {
    font-size: 14px;
    margin-bottom: 12px;
  }

  @media (max-width: 680px) {
    font-size: 13px;
  }

  @media (max-width: 375px) {
    font-size: 12px;
  }
`;

const stepsDefaultRegister = [
  { step: 0, text: 'Account Information', icon: Account },
  { step: 1, text: 'Company Information', icon: Company },
  { step: 2, text: 'Business Information', icon: Business },
  { step: 3, text: 'Review', icon: Review }
];

const ProgressBar = ({ currentStep, goToStep, isEnableClick }) => {

  const [steps, setStep] = useState(stepsDefaultRegister);

  const handleGoToStep = step => {
    if (isEnableClick) {
      goToStep(step);
    }
  };

  return(
    <BarContainer>
      <Bar>
        {steps.map(({ step, text, icon }) => (
          <ProgressBarStep current={step <= currentStep} onClick={() => handleGoToStep(step)} isEnableClick={isEnableClick}>
            <IconWrapper current={step <= currentStep}>
              <img src={icon} title={text} />
            </IconWrapper>
            <Title current={step <= currentStep}>{text}</Title>
          </ProgressBarStep>
        ))}
      </Bar>
    </BarContainer>
  )
}

export default ProgressBar;
