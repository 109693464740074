import React from 'react';
import PopOver from '../../../config/images/popover_icon.svg';
import useStyles from './useStyles';


function ShowMessageDownLoad() {
  const classes = useStyles();
  return(
    <div className={classes.wrapMessageDownload}>
      <img style={{marginRight: 8, height : 20}} src={PopOver}/>
      <div>
        <em className={classes.infoTextMessage}>. Please use the provided invoice template. <em className={classes.boldInfo}>DOWNLOAD TEMPLATE</em></em><br/>
        <em className={classes.infoTextMessage}>. Labor cost reimbursement up to $250 per project per product type <em className={classes.boldInfo}>LEARN MORE</em></em>
      </div>

    </div>
  )
}

export default ShowMessageDownLoad;