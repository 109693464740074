import React from 'react';
import useStyles from './useStyles';

function TitleProject({title}) {
  const classes = useStyles();
  return(
    <>
      <h1 style={{fontWeight: 500, fontSize: 16}}>{title}</h1>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '25px' }} />
    </>
  )
}

export default TitleProject;
