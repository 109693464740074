import React, { useEffect, useState } from 'react';
import { Box, Button, TextareaAutosize, TextField } from '@material-ui/core';
import classNames from 'classnames';
import useStyles from './useStyles';
import '../index.css';
import CustomButton from '../../../common/components/button';
import { Controller } from 'react-hook-form';
import ImageUploader from '../components/UploadImage';
import ClaimInfo from './ClaimInfo';
import CustomLoading from '../../../common/components/CustomLoading';
import CheckValidate from '../../../common/components/CheckValidate';
import ShowMessage from './ShowMessage';
import MultiUploader from "../components/UploadMultiType";



function Issue({ next, goBack, register, control, errors,setKeyUpload, keyUpload, watch,fileErrorLog, setFileErrorLog, fileEvidence,setValue, type, clearErrors , setFileEvidence, handleSaveDraft, loading}) {
  const classes = useStyles();

  return(
    <div>
      <h1 style={{fontWeight: 500, fontSize: 16}}>Issue</h1>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }} />

      <div className={classes.sectionContainer}>
        <div>
          <span className={classes.sectionTitle}>
              Description of your issue
            <span>*</span>
          </span>
        </div>
        <div className={classes.textArea}>
          <Controller
            name="description"
            control={control}
            render={(
              { onChange, value }
            ) => (
              <TextareaAutosize
                name="description"
                onChange={(value) => onChange(value)}
                aria-label="minimum height"
                value={value}
                className={classNames([classes.inputTextArea], {
                  [classes.inputTextAreaError]: errors.description
                })}  placeholder="" />
            )}
          />

        </div>
        <CheckValidate value={watch('description')} error={errors.description}/>

      </div>
      <div className={classes.sectionContainer}>
        <div
          className={classes.sectionHeaderContainer}
          style={{
            alignItems: 'flex-start',
            marginTop: '12px'
          }}
        >
          <span className={classes.sectionTitle}>
             Error Logs
            <span>*</span>
          </span>
        </div>

        <div className={classes.wrapUpload}>
          <MultiUploader
            files={fileErrorLog}
            setFiles={setFileErrorLog}
            fileName={'error_logs'}
            keyUpload={keyUpload}
            errors={errors?.errorLog}
            setKeyUpload={setKeyUpload}
            clearErrors={clearErrors}
          />
          <ShowMessage textInfo={'You can attach the screenshot of the error logs from "Menu > History > Error Logs", or type in the logs.'}/>
        </div>
        <div>
          <CheckValidate value={watch('errorLog')} error={errors.errorLog}/>
        </div>

      </div>
      <div className={classes.sectionContainer}>
        <div
          className={classes.sectionHeaderContainer}
          style={{
            alignItems: 'flex-start',
            marginTop: '12px'
          }}
        >
          <span className={classes.sectionTitle}>
              Other evidence

          </span>
        </div>
        <div className={classes.wrapUpload}>
          <MultiUploader
            files={fileEvidence}
            setFiles={setFileEvidence}
            fileName={'other_evidence'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            errors={errors.otherEvidence}
            clearErrors={clearErrors}
            name={'otherEvidence'}
          />
          <ShowMessage textInfo={'If there is anything that you think would be helpful for us to review this issue, please add here.'}/>
        </div>
      </div>
      <p className={classes.requiredText} style={{ marginTop: '200px' }}>* Required</p>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }}/>
      <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
        <CustomButton color="secondary" className="saveDraftBtn" onClick={goBack}>Back</CustomButton>
        <div>
          <CustomButton onClick={handleSaveDraft} color="secondary" className="saveDraftBtn" disabled={loading}>
            { loading ? <CustomLoading size={16} color="#009EE3" /> : 'Save Draft' }
          </CustomButton>
          <CustomButton onClick={next}>Next</CustomButton>
        </div>
      </Box>
    </div>
  )
}

export default Issue;
