import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, TextField } from '@material-ui/core';
import { useAuth } from '../../../contexts/auth';
import CustomButton from '../../components/button';
import CustomLoading from '../../components/CustomLoading';
import Box from '@material-ui/core/Box';
import { pubSpotService } from '../../../services';
import { notiFailed, notiSuccess } from '../../../utils/helpers';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../text';
import styled from 'styled-components';
import './index.css';

const RequiredSymbol = styled.span`
  display: block;
  left: 1px;
  bottom: 0;
  width: 14px;
  height: 1px;
  background: #009ee3;
  content: "";
`;

const schema = yup.object().shape({
  // last_name: yup.string().required(),
  job_title: yup.string().required()
});

const useStyles = makeStyles(theme => ({
  resize: {
    fontSize: 15
  },
  root: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: '#bdbdbd !important'
    },
  }
}));

function ContactFormHubSpot({ submitOrder }) {
  const user = useAuth();
  const classes = useStyles();
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [errorJobTitle, setErrorJobTitle] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, errors, handleSubmit, clearErrors, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      last_name: _.get(user, 'profile.lastName')
    },
    shouldFocusError: false
  });

  function decodeStringHtml(string) {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(`<!doctype html><body>${string}`, 'text/html').body.textContent;

    return decodedString || '';
  }


  useEffect(() => {
    errors?.job_title?.message && setErrorJobTitle(true);

  }, [errors]);

  useEffect(() => {
    const value = suggestionName();
    setLastName(value.lastname);
    setFirstName(value.firstname);


  }, []);


  function suggestionName() {

    const fullName = _.get(user, 'profile.fullName');
    const lastName = _.get(user, 'profile.lastName');
    const makeName = fullName.trim().replace(/\s\s+/g, ' ').split(' ');
    return {
      firstname: makeName[0],
      lastname: makeName.length > 1 ? makeName[1] : (!lastName ? makeName[0] : lastName)
    };

  }


  const onSubmit = async info => {
    const payload = {
      company: _.get(user, 'profile.companyName'),
      jobtitle: info.job_title,
      email: _.get(user, 'profile.email'),
      ...suggestionName(),
      phone: _.get(user, 'profile.officeNumber') ? _.get(user, 'profile.officeNumber') : _.get(user, 'profile.mobileNumber')
    };
    setLoading(true);
    submitOrder(payload);

  };


  return (
    <form className={'wrap-form-inquiry'} onSubmit={handleSubmit(onSubmit)}>
      <Grid container style={{ marginTop: 20 }}>
        <em style={{ fontSize: 15, marginBottom: 10 }}>Please submit the following details to apply the discount code to
          your Aurora
          subscription:</em>
        <div className={'form-inquiry'}>
          <Grid container direction="row" justify="space-between" alignItems="center"
                style={{ paddingBottom: '18px' }}>
          </Grid>
          <Grid container direction="row" justify="space-between" alignItems="center"
                style={{ paddingBottom: '18px' }}>
            <Grid item xs={4}>
              <Text size="small" style={{ fontSize: '15px' }}>Company Name</Text>
              <RequiredSymbol/>
            </Grid>
            <Grid item xs={8}>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize,
                  }
                }}
                value={`${decodeStringHtml(_.get(user, 'profile.companyName'))}`}
                disabled={true}
                className={classes.root}
                variant="outlined"
                margin="dense" fullWidth placeholder="Name"/>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="space-between" alignItems="center"
                style={{ paddingBottom: '18px' }}>
            <Grid item xs={4}>
              <Text size="small" style={{ fontSize: '15px' }}>First Name</Text>
              <RequiredSymbol/>
            </Grid>
            <Grid item xs={8}>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize
                  }
                }}
                className={classes.root}
                value={firstName}
                disabled={true}
                variant="outlined" margin="dense" fullWidth
                placeholder="Company"/>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="space-between" alignItems="center"
                style={{ paddingBottom: '18px' }}>
            <Grid item xs={4}>
              <Text size="small" style={{ fontSize: '15px' }}>Last Name</Text>
              <RequiredSymbol/>
            </Grid>
            <Grid item xs={8}>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize
                  }
                }}
                className={classes.root}
                value={lastName} disabled={true} variant="outlined" margin="dense" fullWidth
                placeholder="Last Name"/>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="space-between" alignItems="center"
                style={{ paddingBottom: '18px' }}>
            <Grid item xs={4}>
              <Text size="small" style={{ fontSize: '15px' }}>Email</Text>
              <RequiredSymbol/>
            </Grid>
            <Grid item xs={8}>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize
                  }
                }}
                className={classes.root}
                value={`${_.get(user, 'profile.email')}`} disabled={true} variant="outlined" margin="dense"
                fullWidth placeholder="E-mail"/>
            </Grid>
          </Grid>


          <Grid container direction="row" justify="space-between" alignItems="center"
                style={{ paddingBottom: '18px' }}>
            <Grid item xs={4}>
              <Text size="small" style={{ fontSize: '15px' }}>Phone/Mobile Number</Text>
              <RequiredSymbol/>
            </Grid>
            <Grid item xs={8}>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize
                  }
                }}
                className={classes.root}
                value={`${_.get(user, 'profile.officeNumber') ? _.get(user, 'profile.officeNumber') : _.get(user, 'profile.mobileNumber') }`} disabled={true} variant="outlined"
                margin="dense" fullWidth placeholder="Phone Number"/>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="space-between" alignItems="center"
                style={{ paddingBottom: '18px' }}>
            <Grid item xs={4}>
              <Text size="small" style={{ fontSize: '15px' }}>Job Title</Text>
              <RequiredSymbol/>
            </Grid>
            <Grid item xs={8}>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize
                  }
                }}
                onChange={(event) => {
                  setValue('job_title', event.target.value);
                  if (event.target.value !== '') {
                    setErrorJobTitle(false);
                  }
                }}
                autoFocus={true}
                name={'job_title'}
                inputRef={register}
                label={''}
                variant="outlined"
                margin="dense"
                error={errorJobTitle}
                fullWidth
                helperText={errors.job_title?.message &&
                <p style={{ color: '#EB5757', fontSize: 15 }}>Job Title is required</p>}
                placeholder="Job Title"/>
            </Grid>

          </Grid>
        </div>
        <Grid item xs={12} sm={12} justify-between>
          {(!loading ?
            <Box style={{ marginTop: 10 }} display="flex">
              <input className={'btn-hubspot'} style={{
                width: 200,
                height: 50,
                borderRadius: 50,
                fontSize: 16,
                marginTop: 25,
                background: '#2badef',
                color: 'white',
                cursor: 'pointer',
                border: 'navajowhite'
              }} value="Submit & Confirm Order" type="submit"/>
            </Box> : <Box style={{ marginTop: 10 }} display="flex">
              <CustomButton style={{ height: 50 }} color="secondary">
                <CustomLoading size={20} color="#00adef"/>
              </CustomButton>
            </Box>)
          }

        </Grid>
      </Grid>
    </form>
  );
}

export default ContactFormHubSpot;
