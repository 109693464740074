import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import CheckValidate from '../../../common/components/CheckValidate';
import { TextField } from '@material-ui/core';
import _ from 'lodash';
import useStyles from './useStyles';
import NumberFormat from "react-number-format";
import { projectService } from '../../../services';
import PopOver from '../../../config/images/popover_icon.svg';
import {useHistory} from 'react-router-dom';


function PanelsNumberFormat(props) {
  return <NumberFormat {...props} allowNegative={false} />;
}

function FormUploadSerialNumberNotMatchDbPanel({control, errors, watch, powerClassesModules, isNoneQualify}) {

  const classes = useStyles();
  const history = useHistory();

  const [listModelPanel, setlistModelPanel] = useState([])

  async function getModelsByPowerClass(volume) {
    try {
      const { data } = await projectService.getModelsByPowerClass('modules', volume);

      setlistModelPanel(data)
    } catch (error) {
      console.log(error);
    }
  }



  const numberOfModules = watch('numberOfModules');
  const volume = watch('modulePowerClass')

  useEffect(() => {
    if(volume !== '') {
      getModelsByPowerClass(volume);
    }

  }, [volume])

  return(
    <div className={classes.sectionContainerSys}>
      <div className={classes.sectionHeaderContainer} style={{ alignItems: 'flex-start', marginTop: '12px' }}>
        <span className={classes.sectionTitle}>
          Product Information <span>*</span>
        </span>
      </div>
      <div className={`${classes.productInfoTable} ${classes.serialNumberManualDataTable} ${classes.productInfoDataTable}`}>
        <div>
          <h4 className={classes.sectionTitle}>
            Power class (W) <span>*</span>
          </h4>
          <p>
            <Controller
              name="modulePowerClass"
              control={control}
              as={
                <select
                  placeholder="Power Class"
                  className={classNames([classes.selectField], {
                    [classes.errorSelectField]: errors.modulePowerClass
                  })}
                >
                  <option value={'select'}>Select </option>
                  {powerClassesModules?.map(item => (
                    <option key={item.id} value={item.volume}>
                      {item.volume}
                    </option>
                  ))}
                </select>
              }
            />
          </p>
          <CheckValidate value={watch('modulePowerClass')} error={errors.modulePowerClass}/>
        </div>
        <div style={{alignItems: 'flex-start'}}>
          <h4 className={classes.sectionTitle}>
            Model <span>*</span>
          </h4>
          <div>
            <Controller
              name="moduleModel"
              control={control}
              as={
                <select
                  placeholder="Model"
                  className={classNames([classes.selectField], {
                    [classes.errorSelectField]: errors.moduleModel
                  })}
                >
                  <option value={'select'}>Select </option>
                  {listModelPanel?.map(item => (
                    <option key={item.id} value={item.productName}>
                      {item.productName}
                    </option>
                  ))}
                </select>
              }
            />
            {!isNoneQualify && (
              <div className={classes.notiBox}>
                <img style={{marginRight: 8, height : 20, opacity: 0.7}} src={PopOver}/>
                <span style={{color: '#4f4f4f'}}>
                            Please note that this product does not qualify for labor cost reimbursement. Please visit {' '}
                  <span style={{textTransform: 'uppercase'}}>
                              <em style={{color: '#009EE3', cursor: 'pointer', textDecoration: 'underline'}} onClick={() => history.push({
                                pathname: '/claim/how-it-works',
                                state: {
                                  isScrollToQcellsProductsQualify: true
                                }
                              })}> HOW CLAIM WORKS </em>
                            </span>
                  {' '} to learn more about labor reimbursement policy.
                          </span>
              </div>
            )}

          </div>
          <CheckValidate value={watch('moduleModel')} error={errors.moduleModel}/>
        </div>


        <div>
          <h4 className={classes.sectionTitle}>
            Number of Panels <span>*</span>
          </h4>
          <p>
            <Controller
              name="numberOfModules"
              control={control}
              as={
                <TextField
                  placeholder="Number of Panels"
                  fullWidth
                  variant="outlined"
                  className={classNames([classes.inputField], {
                    [classes.errorInputField]: errors.numberOfModules
                  })}
                  InputProps={{
                    endAdornment: <span>pcs</span>,
                    inputComponent: PanelsNumberFormat
                  }}
                  inputProps={{
                    maxLength: 2
                  }}
                />
              }
            />
          </p>
          <CheckValidate value={watch('numberOfModules')} error={errors.numberOfModules}/>
        </div>
        <div>
          <h4 className={classes.sectionTitle}>
            System Size (kW)
          </h4>
          <p>
            <TextField
              fullWidth
              disabled
              variant="outlined"
              className={`${classes.inputField} ${classes.inputFieldDisabled}`}
              value={
                volume !== '' && numberOfModules !== ''
                  ? (_.toNumber(volume) * _.toNumber(numberOfModules)) / 1000
                  : ''
              }
              InputProps={{
                endAdornment: <span>kW</span>,
                inputComponent: PanelsNumberFormat
              }}
            />
          </p>
          <CheckValidate value={watch('numberOfModules')} error={errors.numberOfModules}/>
        </div>
      </div>

    </div>
  )
}

export default FormUploadSerialNumberNotMatchDbPanel;
