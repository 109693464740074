import React from 'react';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import CustomDatePicker from '../../projects/components/CustomDatePicker';
import CustomButton from '../../../common/components/button';
import CustomLoading from '../../../common/components/CustomLoading';
import TitleProject from './TitleProject';
import useStyles from './useStyles';
import ImageUploader from './UploadImage';
import CheckValidate from '../../../common/components/CheckValidate';
import ShowMessage from './ShowMessage';
import ShowMessageDownLoad from './ShowMessageDownLoad';
import ReactSelect from 'react-select';
import IconSelect from '../../../icons/arrow.svg';
import _ from 'lodash';
import TextButton from '../../../common/components/button/TextButton';
// import MessageRefund from './MessageRefund';
import ShowMessageLineSpec from './ShowMessageLineSpec';
import MultiUploader from "./UploadMultiType";

const ReactSelectDropdownIndicator = () => {
  return (
    <div style={{ marginRight: '15px' }}>
      <img src={IconSelect} width="14" />
    </div>
  );
};

function PurchaseDetail({
  distributors,
  next,
  goBack,
  register,
  control,
  errors,
  watch,
  setValue,
  keyUpload,
  mainPurchasingChannel,
  setKeyUpload,
  handleGoToReview,
  setFileLaborWarranty,
  fileLaborWarranty,
  fileInfoBank,
  setFileInfoBank,
  fileInvoiceLabor,
  setFileInvoiceLabor,
  handleSaveDraft,
  loading,
  clearErrors
}) {
  const classes = useStyles();

  const selectedPurchaseChannel = watch('purchasingChannel');
  const chooseRefund = watch('chooseRefund');
  const chooseBank = watch('chooseBank');
  const nameOfPurchasingChannelDistributor = watch(
    'nameOfPurchasingChannelDistributor'
  );
  const choseTypeProject = watch('choseTypeProject');
  const projectInfo = watch('projectInfo');
  const laborReimbursement = watch('laborReimbursement');
  const laborWarranty = watch('laborWarranty');
  const batteryQualified = watch('batteryQualified');

  const renderCheckValidate = () => {
    switch (selectedPurchaseChannel) {
      case 'qcells':
        return <CheckValidate value={watch('nameOfPurchasingChannelQcells')} />;
      case 'distributor':
        return (
          <CheckValidate
            value={watch('nameOfPurchasingChannelDistributor')}
            error={errors.nameOfPurchasingChannelDistributor}
          />
        );
      case 'other':
        return (
          <CheckValidate
            value={watch('nameOfPurchasingChannelOther')}
            error={errors.nameOfPurchasingChannelOther}
          />
        );
      default:
        return (
          <CheckValidate
            value={watch('nameOfPurchasingChannelSelect')}
            error={errors.nameOfPurchasingChannelSelect}
          />
        );
    }
  };
  const renderNamePurchasingChannel = () => {
    switch (selectedPurchaseChannel) {
      case 'qcells':
        return (
          <TextField
            key="nameOfPurchasingChannelQcells"
            fullWidth
            variant="outlined"
            // className={classes.inputField}
            className={classNames(
              [classes.inputField],
              [classes.inputFieldDisabled]
            )}
            value={'Q CELLS'}
            disabled
          />
        );
      case 'distributor':
        return (
          <Controller
            name="nameOfPurchasingChannelDistributor"
            key="name-purchasing-channel-distributor"
            control={control}
            as={
              <ReactSelect
                getOptionLabel={option => _.trim(option.name)}
                getOptionValue={option => _.trim(option.name)}
                isSearchable
                isClearable
                isMulti={false}
                options={distributors}
                className={classNames([classes.multiSelect], {
                  [classes.errorReactSelect]:
                    errors.nameOfPurchasingChannelDistributor
                })}
                classNamePrefix="select"
                placeholder="Type in the distributor name"
                defaultMenuIsOpen={false}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: ReactSelectDropdownIndicator
                }}
                styles={{
                  placeholder: provided => ({
                    ...provided,
                    color: '#C4C4C4'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    fontFamily: 'Actual',
                    fontSize: '14px',
                    color: '#333333',
                    fontWeight: 400,
                    fontStyle: 'normal'
                  })
                }}
              />
            }
          />
        );
      case 'other':
        return (
          <TextField
            fullWidth
            variant="outlined"
            name="nameOfPurchasingChannelOther"
            key="name-purchasing-channel-other"
            className={classNames([classes.inputField], {
              [classes.errorInputField]: errors.nameOfPurchasingChannelOther
            })}
            inputRef={register}
          />
        );
      default:
        return (
          <select
            name="nameOfPurchasingChannelSelect"
            className={classes.selectField}
            disabled
          >
            <option>Please Select</option>
          </select>
        );
    }
  };

  const renderOtherDistributor = () => {
    if (
      nameOfPurchasingChannelDistributor !== null &&
      nameOfPurchasingChannelDistributor.name === 'Other' &&
      selectedPurchaseChannel === 'distributor'
    ) {
      return (
        <>
          <TextField
            key="nameOfChanelOtherDistributor"
            name={'nameOfChanelOtherDistributor'}
            fullWidth
            inputRef={register}
            variant="outlined"
            className={classNames([classes.inputField])}
          />
        </>
      );
    }
  };

  const renderMessageDistributor = () => {
    if (selectedPurchaseChannel === 'distributor') {
      return (
        <ShowMessage
          textInfo={
            'If you cannot find your distributor from the list, please type Other.'
          }
        />
      );
    }
  };

  const renderValidateDistributorOther = () => {
    if (
      nameOfPurchasingChannelDistributor !== null &&
      nameOfPurchasingChannelDistributor.name === 'Other' &&
      selectedPurchaseChannel === 'distributor'
    ) {
      return (
        <div>
          <CheckValidate
            value={watch('nameOfChanelOtherDistributor')}
            error={errors.nameOfChanelOtherDistributor}
          />
        </div>
      );
    }
  };

  return (
    <div>
      <TitleProject title={'Purchase Info'} />
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
            Purchasing <br /> Channel <span>*</span>
          </span>
        </div>
        <div className={classNames([classes.inputFieldMediumContainer])}>
          <Controller
            name="purchasingChannel"
            control={control}
            as={
              <select
                className={classNames([classes.selectField], {
                  [classes.errorSelectField]: errors.purchasingChannel
                })}
              >
                <option value={'select'}>Please Select</option>
                <option value={'qcells'}>Qcells</option>
                <option value={'distributor'}>Distributor</option>
                <option value={'other'}>Other</option>
              </select>
            }
          />
        </div>
        <div>
          <CheckValidate
            value={watch('purchasingChannel')}
            error={errors.purchasingChannel}
          />
        </div>
      </div>

      <div className={classes.sectionContainer} style={{ marginBottom: 5 }}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
            Name of Channel <span>*</span>
          </span>
        </div>
        <div className={classNames([classes.inputFieldMediumContainer])}>
          {renderNamePurchasingChannel()}
        </div>
        {renderCheckValidate()}
      </div>
      <div className={classes.sectionContainer}>
        <div
          className={classes.sectionHeaderContainer}
          style={{ alignItems: 'flex-start' }}
        >
          <span className={classes.sectionTitle}></span>
        </div>
        <div
          className={classNames([classes.inputFieldMediumContainer])}
          style={{ marginTop: 5 }}
        >
          {renderOtherDistributor()}
          {renderMessageDistributor()}
        </div>
        {renderValidateDistributorOther()}
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>PO Number</span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="poNumber"
            className={classes.inputField}
            inputRef={register}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>Purchase Date</span>
        </div>
        <div>
          <Controller
            name="purchaseDate"
            control={control}
            render={({ onChange, value }) => (
              <CustomDatePicker
                fullWidth
                size="small"
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                inputVariant="outlined"
                value={value}
                className={classNames([classes.datePicker], {
                  //[classes.errorDatePickerField]: errors.installationDate
                })}
                onChange={date => onChange(date)}
                maxDate={new Date()}
                views={['year', 'month', 'date']}
              />
            )}
          />
        </div>
      </div>
      <TitleProject title={'Labor Reimbursement'} />
      {(choseTypeProject === '2' ||
        (!laborWarranty && projectInfo !== null) ||
        (projectInfo !== null && !batteryQualified) ||
        projectInfo === null) && (
        <div className={classes.sectionContainerAddress}>
          <div
            className={classes.sectionHeaderContainer}
            style={{ alignItems: 'flex-start' }}
          ></div>
          <div>
            <ShowMessage
              textInfo={
                'You must select a pre-registered project that qualifies for labor reimbursement in order to submit a request for labor reimbursement.'
              }
            />
          </div>
        </div>
      )}

      {choseTypeProject === '1' &&
        projectInfo !== null &&
        !laborReimbursement &&
        laborWarranty && (
          <div className={classes.sectionContainerAddress}>
            <div
              className={classes.sectionHeaderContainer}
              style={{ alignItems: 'flex-start' }}
            ></div>
            <div>
              <ShowMessage
                textInfo={
                  'One-time reimbursement paid or in process. There will be no more reimbursement.'
                }
              />
            </div>
          </div>
        )}

      {laborReimbursement &&
        laborWarranty &&
        batteryQualified &&
        projectInfo !== null &&
        choseTypeProject !== '2' && (
          <>
            <div className={classes.sectionContainerAddress}>
              <div
                className={classes.sectionHeaderContainer}
                style={{
                  alignItems: 'flex-start'
                }}
              >
                <span className={classes.sectionTitle}>
                  Invoice for Labor <br /> Cost <br /> Reimbursement {''}{' '}
                  <span>*</span>
                </span>
              </div>
              <div>
                <div className={classes.contactNumberContainer}>
                  <Controller
                    name="chooseRefund"
                    control={control}
                    as={
                      <RadioGroup aria-label="position">
                        <FormControlLabel
                          value="1"
                          control={
                            <Radio
                              onChange={() => {
                                setFileInvoiceLabor([]);
                                setFileInfoBank([]);
                              }}
                              icon={<div className={classes.radio} />}
                              checkedIcon={<div className={classes.radioOn} />}
                            />
                          }
                          label="I do not wish to request for labor reimbursement and understand I will not be able to request separately."
                          labelPlacement="end"
                          style={{ marginBottom: 13 }}
                        />
                        <FormControlLabel
                          value="2"
                          control={
                            <Radio
                              icon={<div className={classes.radio} />}
                              checkedIcon={<div className={classes.radioOn} />}
                            />
                          }
                          label={'I would like to request for labor reimbursement'}
                          labelPlacement="end"
                          style={{ marginBottom: chooseRefund === '1' ? 27 : 0 }}
                        />
                      </RadioGroup>
                    }
                  />
                </div>
              </div>
            </div>

            {chooseRefund === '2' && (
              <div className={classes.sectionContainer}>
                <div
                  className={classes.sectionHeaderContainer}
                  style={{ alignItems: 'flex-start', marginTop: '12px' }}
                >
                  <span className={classes.sectionTitle} />
                </div>
                <div className={classes.wrapUpload}>
                  <MultiUploader
                    files={fileInvoiceLabor}
                    setFiles={setFileInvoiceLabor}
                    fileName={'invoice_for_labor_cost_reimbursement'}
                    keyUpload={keyUpload}
                    setKeyUpload={setKeyUpload}
                    clearErrors={clearErrors}
                    name={'invoiceForLaborReimbursement'}
                    errors={errors.invoiceForLaborCost}
                  />
                  <div style={{ marginTop: 10 }}>
                    <ShowMessageLineSpec />
                  </div>
                </div>
                <div>
                  <CheckValidate
                    value={watch('invoiceForLaborCost')}
                    error={errors.invoiceForLaborCost}
                  />
                </div>
              </div>
            )}

            {chooseRefund === '2' && (
              <div>
                <div className={classes.sectionContainerAddress}>
                  <div
                    className={classes.sectionHeaderContainer}
                    style={{
                      alignItems: 'flex-start'
                    }}
                  >
                    <span className={classes.sectionTitle}>
                      Bank Account <br /> Information {''} <span>*</span>
                    </span>
                  </div>
                  <div>
                    <div className={classes.contactNumberContainer}>
                      <Controller
                        name="chooseBank"
                        control={control}
                        as={
                          <RadioGroup aria-label="position">
                            <FormControlLabel
                              value="1"
                              control={
                                <Radio
                                  onChange={() => {
                                    setFileInfoBank([]);
                                  }}
                                  icon={<div className={classes.radio} />}
                                  checkedIcon={<div className={classes.radioOn} />}
                                />
                              }
                              label="I have already provided my bank information to Qcells."
                              labelPlacement="end"
                              style={{ marginBottom: 13 }}
                            />
                            <FormControlLabel
                              value="2"
                              control={
                                <Radio
                                  icon={<div className={classes.radio} />}
                                  checkedIcon={<div className={classes.radioOn} />}
                                />
                              }
                              label="This is my first time requesting for payment to Qcells."
                              labelPlacement="end"
                              style={{ marginBottom: chooseBank === '1' ? 27 : 0 }}
                            />
                          </RadioGroup>
                        }
                      />
                    </div>
                  </div>
                </div>

                {chooseBank === '2' && (
                  <div className={classes.sectionContainer}>
                    <div
                      className={classes.sectionHeaderContainer}
                      style={{ alignItems: 'flex-start', marginTop: '12px' }}
                    >
                      <span className={classes.sectionTitle} />
                    </div>
                    <div className={classes.wrapUpload}>
                      <MultiUploader
                        files={fileInfoBank}
                        setFiles={setFileInfoBank}
                        fileName={'bank_account_information'}
                        keyUpload={keyUpload}
                        setKeyUpload={setKeyUpload}
                        clearErrors={clearErrors}
                        name={'bank'}
                        errors={errors.infoBank}
                      />
                      {/* <div style={{marginTop : 10}}>
                <MessageRefund/>
              </div> */}
                    </div>
                    <div>
                      <CheckValidate
                        value={watch('infoBank')}
                        error={errors.infoBank}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            <div
              className={classes.separator}
              style={{ marginTop: '10px', marginBottom: '35px' }}
            />
          </>
        )}

      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.navigationBtnContainer}
      >
        <CustomButton color="secondary" className="saveDraftBtn" onClick={goBack}>
          Back
        </CustomButton>
        <div>
          <CustomButton
            onClick={handleSaveDraft}
            color="secondary"
            className="saveDraftBtn"
            disabled={loading}
          >
            {loading ? <CustomLoading size={16} color="#009EE3" /> : 'Save Draft'}
          </CustomButton>
          <CustomButton onClick={next}>Next</CustomButton>
        </div>
      </Box>
    </div>
  );
}

export default PurchaseDetail;
