import React, {useState, useEffect} from 'react';
import Banner from '../../common/components/Banner';
import BreadCrumb2 from '../../common/components/BreadCrumb2';
import BannerImage2 from '../../config/images/introducing_program_2.jpg';
import TitleAnimation from '../../common/components/TitleAnimation';
import BenefitsCards from './components/benefitsCards';
import ProgramOverviewCards from './components/programOverviewCards';
import Text from '../../common/components/text'
import { useAuth } from '../../contexts/auth';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const StyledButton = styled.button `
  margin-bottom: 40px;
  border: none;
  background-color: #666;
  text-decoration: none;
  color: #fff;
  height: 50px;
  min-width: 100px;
  font-size: 16px;
  padding: 0 40px;
  cursor: pointer;
  font-weight: 600;

  :hover {
    background: #444;
    cursor: pointer;
  }
`

const StyledSectionTitle = styled.p `
  text-align: center;
  font-size: ${props => props.width >= 376 ? '48px' : '24px'} !important;
  color: #000000;
  line-height: 60px;
  font-weight: 500;
  margin-top: 117px;

  @media (max-width: 960px) {
    margin-top: 30px;
  }
`

export default function ProgramIntroduction() {
  const user = useAuth();
  const [width, setWindowWidth] = useState(0);

  useEffect(() => { 
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => 
      window.removeEventListener('resize',updateDimensions);
   }, [])
   const updateDimensions = () => {
     const width = window.innerWidth
     setWindowWidth(width)
   }
  return (
    <section
      style={{
        backgroundColor: 'var(--light-color, #ffffff)',
        position: 'relative'
      }}
    >
      <BreadCrumb2 items={['About', 'Program Introduction']}></BreadCrumb2>
      <Banner imageUrl={BannerImage2} className="banner100">
        <TitleAnimation>INTRODUCING Q.PARTNER PROGRAM</TitleAnimation>
      </Banner>

      <StyledSectionTitle>PROGRAM OVERVIEW</StyledSectionTitle>
        <ProgramOverviewCards />
      <StyledSectionTitle width={width}>PROGRAM BENEFITS</StyledSectionTitle>
      <BenefitsCards />
      {user.authenticated ? false : 
       <div style={width >= 960 ? {textAlign: 'center', marginTop: '-60px', marginBottom: '60px'} : {textAlign: 'center', marginTop: '30px', marginBottom: '20px'}}>
        <StyledButton onClick={() => window.location = "/register"} className="newsletter-form-button-label">BECOME A Q.PARTNER</StyledButton>
      </div>
      }
    </section>
  )
}