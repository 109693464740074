import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../contexts/auth';
import _ from 'lodash';
import config from '../config';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
//
const AlertThenRedirectToLogin = () => {
  let url = _.get(process.env, 'REACT_APP_QPARTNER_LEGACY_BASE_URL', '')
    .replace('https://', '')
    .replace('http://', '');

  let referrer = document.referrer.replace('https://', '').replace('http://', '');
  if (referrer.indexOf(url) == 0 && referrer !== '' && url !== '') {
  } else {
    alert('Please try again after logging in');
  }

  return <Redirect to={`/sso/login?url=${window.location.pathname}`} />;
};

export default function AuthRoute({ children, ...rest }) {
  const { authenticated } = useAuth();
  
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !!authenticated || window.location.pathname.includes(`/notice/${config.idNewsWithoutPromoCode}`)|| window.location.pathname.includes('/projects/project-introduction') || window.location.pathname.includes('/claim/how-it-works') || window.location.pathname.includes('/notice') ? children : <AlertThenRedirectToLogin />
      }
    />
  );
}
