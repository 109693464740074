import * as _ from 'lodash';
import axios from './axios';

const createQueryParams = params =>
  new URLSearchParams(_.pickBy(params, _.identity));

export default class APIClient {
  constructor({ baseUrl, caller = axios }) {
    caller.defaults.baseURL = baseUrl;
    this.caller = caller;
  }

  async listProducts({
    searchBy = 'name',
    searchFor,
    sort,
    order,
    categories,
    limit,
    offset
  }) {
    let params;
    let newArrivals = false;
    if (sort === 'qpoints') {
      sort = 'products.prices';
    } else if (sort == 'createdAt') {
      sort = '';
      order = '';
      newArrivals = true;
    }

    let queryParams = {};
    if (_.isEmpty(searchFor)) {
      queryParams = {
        site: 'frontend',
        sort,
        order,
        categories,
        limit,
        offset
      };
    } else {
      queryParams = {
        site: 'frontend',
        searchFor,
        searchBy: 'products.name',
        sort,
        order,
        categories,
        limit,
        offset
      };
    }
    if (newArrivals) {
      queryParams.newArrivals = true;
    }

    params = createQueryParams(queryParams);

    return this.caller.get(`/products/all?${params}`);
  }

  async getProduct(id) {
    return this.caller.get(`/products/${id}`);
  }

  async listRelatedProducts({ productId, categoryId }) {
    const params = createQueryParams({ productId, categoryId });
    return this.caller.get(`/products/related?${params}`);
  }

  async listCategories() {
    return this.caller.get(`/categories`);
  }

  async getPopularProducts() {
    return await this.caller.get(`/products/recommended`);
  }

  async addToCart({ productId, quantity, userId, variantId }) {
    return this.caller.post(`/carts`, {
      // productId,
      quantity,
      userId,
      variantId
    });
  }

  async getCart(userId) {
    return await this.caller.get(`/users/${userId}/cart`);
  }

  async updateCart(cartId, data) {
    return await this.caller.patch(`/carts/${cartId}`, data);
  }

  async removeCartProduct(cartId) {
    return await this.caller.delete(`/carts/${cartId}`);
  }

  async getCompanyBranch(companyId) {
    return await this.caller.get(`/companies/${companyId}/branches`);
  }

  async getOrder(orderId) {
    const res = await this.caller.get(`/orders/${orderId}`);
    return res;
  }

  async sendOrder(data) {
    return await this.caller.post(`/orders`, data);
  }

  async addAddress(data) {
    return await this.caller.post(`/branches/addAddress`, data);
  }

  async logout() {
    return await this.caller.post(`/logout`);
  }

  async getUserProfile() {
    return await this.caller.get(`/users/profile`);
  }

  async getCompanyRewards(companyId) {
    return await this.caller.get(`/companies/${companyId}/rewards`);
  }

  async getInfoProfile(id) {
    return await this.caller.get(`/users/${id}`)
  }
}
