import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/auth';
import _ from 'lodash';

const PUBLIC_ROUTES = [
    '/',
    '/register',
    '/login',
    '/sso/login',
    '/rewards/qpoint-introduction',
    '/mypage/member/edit-profile',
    '/about/program-introduction',
    '/salestools/data-hub/download-center',
    '/salestools/data-hub/video-content',
    '/salestools/aurorasolar',
    '/salestools/opensolar',
    '/support/contact-us',
    '/support/faq/1',
    '/terms',
    '/policy',
    '/qpartner/nda',
    '/support/faq/1'
];

export default function useShowUpdateProfileModal() {
  const location = useLocation();
  const history = useHistory();
  const [isShowing, setIsShowing] = useState(false);
  const user = useAuth();

  useEffect(() => {
    if (!_.get(user, 'authenticated') || !!_.get(user, 'primaryProductType')) {
      setIsShowing(false);
    } else {
      if (location.pathname !== '/mypage/member/edit-profile') {
        setIsShowing(true);
      } else {
        setIsShowing(false);
      }
    }
  }, [location.pathname]);

  const handleClose = () => {
    history.push('/mypage/member/edit-profile');
    setIsShowing(false);
  };

  const handleShowing = () => {
    setIsShowing(true);
  };

  return [isShowing, handleClose, handleShowing];
}
