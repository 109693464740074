import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Navigation from '../navigation';
import Footer from '../footer';
import { withRouter, useLocation } from 'react-router-dom';
import UpdateProfileModal from './../components/UpdateProfileModal';
import useShowUpdateProfileModal from './../../hooks/useShowUpdateProfileModal';
import FooterHomePage from '../FooterHomePage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Main = styled.main`
  flex-grow: 1;
  background-color: ${props => {
    if (props.path.indexOf('/checkout/confirmation') === 0) {
      return props.theme.colors.gray;
    } else {
      return props.theme.colors.light;
    }
  }};
`;

const Layout = props => {
  const [isShowing, handleClose, handleShowing] = useShowUpdateProfileModal();
  const [isHome, setIsHome] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if(location.pathname === '/') {
      setIsHome(true)
    }else {
      setIsHome(false);
    }
  }, [location.pathname])

  return (
    <Container>
      <UpdateProfileModal isShowing={isShowing} handleClose={handleClose}/>
      <Navigation showUpdateProfileModal={handleShowing} />
      <Main path={props.location.pathname}>{props.children}</Main>
      {!isHome && <Footer />}
    </Container>
  );
};

export default withRouter(Layout);
