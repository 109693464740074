import React, { useState } from 'react';
import useStyles from './useStyles';
import PopoverIcon from '../../../config/images/notification.svg';
import CloseIcon from '../../../config/images/Close.svg';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';

function Notification() {
  const classes = useStyles();
  const [isShow, setIsShow] = useState(true);

  return(
    <div className={classNames([classes.wrapNotification], {
      [classes.hiddenNoti]: isShow ? undefined : 'done'
    })}>
      <div className={classes.wrapLeft}>
        <IconButton
          size="small"
          aria-haspopup="true"
        >
          <img src={PopoverIcon} />
        </IconButton>
        <span className={classes.wrapTextNoti}>
          <span className={classes.Noti}>CLAIM SUBMISSION</span> - <span> If you’re submitting for labor reimbursement, be prepared to submit your labor invoice at the end of this form.</span>
        </span>
      </div>
      <div>
        <IconButton
          size="small"
          aria-haspopup="true"
          onClick={() => setIsShow(false)}
        >
          <img src={CloseIcon} />
        </IconButton>
      </div>
    </div>
  )
}

export default Notification;
