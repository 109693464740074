import React, {useState} from 'react';
import styled from 'styled-components';
import Img1 from '../../../config/images/img_about_step_list01.jpg';
import Img2 from '../../../config/images/img_about_step_list02.jpg';
import Img3 from '../../../config/images/img_about_step_list03.jpg';
import Img4 from '../../../config/images/img_about_step_list04.png';
import Img1Mobile from '../../../config/images/img_about_step_list01m.PNG';
import Img2Mobile from '../../../config/images/img_about_step_list02m.jpg';
import Img3Mobile from '../../../config/images/img_about_step_list03m.jpg';
import Img4Mobile from '../../../config/images/img_about_step_list04m3.png';
import {makeStyles, useMediaQuery} from '@material-ui/core';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const StyledOl = styled.ol.attrs(props => ({
  currentImage: props.currentImage
})) `
  background-image: ${props => props.currentImage === 1 ? `url(${Img1})` : props.currentImage === 2 ? `url(${Img2})` : props.currentImage === 3 ? `url(${Img3})` : props.currentImage === 4 ? `url(${Img4})` : ``};
  height: 600px;
  width: 1200px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-bottom: 25px;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  transition: all 1s;
`;

const StyledList = styled.li.attrs(props => ({clicked: props.clicked})) `
  float: left;
  margin-left: 0;
  list-style: none;
  transition: all .5s;
  // &:first-child {background-image: ${props => props.clicked === 0 && `url(${Img1Mobile})`}};
  &:first-child  {background-image: ${props => props.clicked === 0 && `url(${Img2Mobile})`}};
  &:nth-child(2) {background-image: ${props => props.clicked === 0 && `url(${Img3Mobile})`}};
  &:nth-child(3) {background-image: ${props => props.clicked === 0 && `url(${Img4Mobile})`}};
  // border: solid;
  // margin: auto;
  /* @media (max-width: 1200px) {
    width: ${props => props.clicked === 0 && '33.33% !important'};
  } */
`;

const StyledA = styled.a `
  width: 100%;
  height: 600px;
  background: none;
  display: block;
  position: relative;
  z-index: 1;
  color: #fff;
`

const StyledI = styled.i `
  font-size: 20px;
  line-height: 30px;
  transform: translate(20px,-76px);
  position: absolute;
  left: -20px;
  top: 245px;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  transition: all 0.4s;
`;

const StyledDl = styled.dl `
  position: absolute;
  left: 50px;
  top: 270px;
  transition: all 0.4s;
  transform: translate(-10px,-38px);
`;

const StyledDt = styled.dt `
  position: relative;
  margin-bottom: 24px;
  padding-bottom: 26px;
  line-height: 24px;
  font-size: 15px;
  font-weight: normal;
`;

const StyledP = styled.p `
  display: block;
  position: relative;
  padding-top: 50px;
`;

const CyanUnderLine = styled.p `
  position: absolute;
  left: 0;
  top: 110px;
  width: 50px;
  height: 2px;
  content: "";
  background: #009ee3;
`;

const Card = styled.li`
  position: relative; float: left; width: 100%; min-width: 265px; 
  &:first-child {margin-left: 0; }
  & div {display: block; position: relative; width: 100%; min-width: 265px; height: 400px; color: #fff; z-index: 1; }
  & i {position: absolute; left: 15px; top: 67px; font-style: normal; font-size: 11px; line-height: 14px; }
  & dl {position: absolute; left: 15px; top: 94px; padding-right: 15px; transition: transform 0.4s; }
  & dt {position: relative; margin-bottom: 17px; padding-bottom: 15px; font-size: 16px; font-weight: 600; line-height: 22px; }
  & dt p {margin-top: 5px; font-size: 13px; font-weight: normal; line-height: 20px; }
  & dt:after {position: absolute; left: 0; bottom: 0; width: 25px; height: 1px; background: #009ee3; content: ""; }	
  & dd {padding-right: 15px; font-size: 13px; line-height: 20px; }
  & dd.mt {margin-top: 10px; }
  & .img {position: absolute; left: 0; top: 0; width: 100%; min-width: 265px; height: 400px; overflow: hidden; transition: all 0.4s; background: #eee; ; }
  & .img img {width: 100%; }
`

const useStyles = makeStyles(theme => ({
  container: {
    width: '1200px', 
    margin: 'auto', 
    [theme.breakpoints.down(1200)]: {
      width: '95%',
      '& ol': {
        width: '100%'
      }
    },
    '& .alice-carousel__dots-item.__active': {
      backgroundColor: '#019ee3 !important'
    },
    '& .alice-carousel__dots-item': {
      width: '5px !important',
      height: '5px !important'
    },
    '& .alice-carousel__wrapper': {
      marginTop: '10px',
      marginBottom: '10px'
    },
    '& li.alice-carousel__stage-item :not(.__cloned)': {
      width: 'auto !important',
      marginRight: '10px'
    }
  },
  sectionTitle: {
    [theme.breakpoints.down(1200)]: {
      width: '100% !important'
    }
  }
}));

export default function ProgramOverviewCards() {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width: 960px)');
  const [clicked, setClicked] = useState(0);

  const itemsLength = [
    {
      number: '01',
      title: 'We provide rewards based on your purchases.',
      subTitle: '',
      content: '',
      image: Img1Mobile
    },
    {
      number: '02',
      title: 'We offer various training contents to support your sales efforts.',
      subTitle: 'Receive training to strengthen your capabilities.',
      content: 'We provide online videos and NABCEP content, in addition to on-site training to empower your sales team.',
      image: Img2Mobile
    },
    {
      number: '03',
      title: 'We provide various tools and content to support your business.',
      subTitle: 'Utilize the Data Hub to get the materials you need and the Project Management tool to organize your projects.',
      content: 'Take advantage of a variety of Qcells digital collateral and effectively manage your projects.',
      image: Img3Mobile
    },
    {
      number: '04',
      title: 'Our streamlined claims process makes it easy to submit product claims.',
      subTitle: 'Submit your claims and qualify for labor cost reimbursement.',
      content: 'Process your claims for PV module and ESS products easily through the claims portal.',
      image: Img4Mobile
    }
  ];

  const items = itemsLength.map((item, index) => {
      const style = { width: 265 };
      return (<Card className="item" style={style}>
        <div>
          <i>{item.number}</i>
          <dl>
            <dt>
              <strong>{item.title}</strong>
              <p>{item.subTitle}</p>
            </dt>
            <dd>{item.content}</dd>
          </dl>
        </div>
				<p class="img"><img src={item.image} alt=""/></p>
      </Card>);
  });

  return (
    <>
      {!isSmallScreen ?
      <div onMouseLeave={() => setClicked(0)} className={classes.container}>
        <StyledOl currentImage={clicked}>
          {/*<StyledList clicked={clicked} onClick={() => setClicked(1)} style={clicked === 0 ? {width: '25%'} : clicked === 1 ? {width: '40%'} : {width: '20%'}} currentImage={clicked}>*/}
          {/*<StyledA>*/}
          {/*  <StyledI style={clicked != 1 && clicked != 0 ? {left: '20px', top: '280px'} : {fontSize: '20px', left: '20px'}}>01</StyledI>*/}
          {/*  <StyledDl>*/}
          {/*    <StyledDt>*/}
          {/*      <p className={classes.sectionTitle} style={clicked != 1 && clicked != 0 ? {width: '200px', fontSize: '15px', fontWeight: 400, lineHeight: '30px', paddingTop: '20px'} : { width: '300px', fontSize: '20px', fontWeight: 600, lineHeight: '30px'}}>*/}
          {/*        We provide rewards based on your <br /> purchases.*/}
          {/*      </p>*/}
          {/*      {clicked != 0 ? false : <CyanUnderLine />}*/}
          {/*      /!*{clicked === 1 || clicked === 0 ? <StyledP style={clicked === 1 ? {paddingTop: '0px'} : {}}>Receive rewards and extra benefits for purchasing our high-performance products.</StyledP> : false}*!/*/}
          {/*    /!*{clicked === 1 ? <CyanUnderLine style={{top: '158px'}} /> : false}*!/*/}
          {/*    /!*{clicked === 1 ? <StyledP style={{paddingTop: '50px',  color: '#fff'}}>The reward program offers you financial rewards and promotional items which you can redeem in the Marketing Shop.</StyledP> : false}*!/*/}
          {/*    </StyledDt>*/}
          {/*  </StyledDl>*/}
          {/*</StyledA>*/}
          {/*</StyledList>*/}

          <StyledList clicked={clicked} onClick={() => setClicked(2)} style={clicked === 0 ? {height: '600px' ,width: '33%'} : clicked === 2 ? {height: '600px', border: '1px solid #666666', width: '50%'} : {height: '600px', width: '25%', border: '1px solid #666666'}} currentImage={clicked}>
          <StyledA>
            <StyledI style={clicked != 2 && clicked != 0 ? {left: '20px', top: '280px'} : {fontSize: '20px', left: '20px'}}>01</StyledI>
            <StyledDl>
              <StyledDt>
                <p className={classes.sectionTitle} style={clicked != 2 && clicked != 0 ? {width: '200px', fontSize: '15px', fontWeight: 400, lineHeight: '30px', paddingTop: '20px'} : { width: '300px', fontSize: '20px', fontWeight: 600, lineHeight: '30px'}}>
                We offer various training contents to support your sales efforts.
                </p>
                {clicked != 0 ? false : <CyanUnderLine />}
                {clicked === 2 || clicked === 0 ? <StyledP style={clicked === 2 ? {paddingTop: '0px'} : {}}>
                Receive training to strengthen your capabilities.
              </StyledP> : false}
              {clicked === 2 ? <CyanUnderLine style={{top: '133px'}} /> : false}
              {clicked === 2 ?
              <StyledP style={{paddingTop: '50px',  color: '#fff'}}>
                We provide online videos and NABCEP content, in addition to on-site training to empower your sales team.</StyledP> : false}
              </StyledDt>
            </StyledDl>
          </StyledA>
          </StyledList>

          <StyledList clicked={clicked} onClick={() => setClicked(3)} style={clicked === 0 ? {width: '33%'} : clicked === 3 ? {width: '50%'} : {width: '25%'}} currentImage={clicked}>
          <StyledA>
          <StyledI style={clicked != 3 && clicked != 0 ? {left: '20px', top: '280px'} : {fontSize: '20px', left: '20px'}}>02</StyledI>
            <StyledDl>
              <StyledDt>
                <p className={classes.sectionTitle} style={clicked != 3 && clicked != 0 ? {width: '200px', fontSize: '15px', fontWeight: 400, lineHeight: '30px', paddingTop: '20px'} : { width: '300px', fontSize: '20px', fontWeight: 600, lineHeight: '30px'}}>
                  Available marketing materials and videos to support your business and staff.
                </p>
                {clicked != 0 ? false : <CyanUnderLine />}
                {clicked === 3 || clicked === 0 ? <StyledP style={clicked === 3 ? {paddingTop: '0px'} : {}}>
                  Browse the available marketing materials to help your team and share with homeowners.
              </StyledP> : false}
              {clicked === 3 ? <CyanUnderLine style={{top: '158px'}} /> : false}
              {clicked === 3 ?
              <StyledP style={{paddingTop: '50px',  color: '#fff'}}>
                Take advantage of a variety of Qcells digital collateral and effectively manage your projects.</StyledP> : false }
              </StyledDt>
            </StyledDl>
          </StyledA>
          </StyledList>

          <StyledList clicked={clicked} onClick={() => setClicked(4)} style={clicked === 0 ? {height: '600px' ,width: '33%'} : clicked === 4 ? {height: '600px', border: '1px solid #666666', width: '50%'} : {height: '600px', width: '25%', border: '1px solid #666666'}} currentImage={clicked}>
          <StyledA>
            <StyledI style={clicked != 4 && clicked != 0 ? {left: '20px', top: '280px'} : {fontSize: '20px', left: '20px'}}>03</StyledI>
            <StyledDl>
              <StyledDt>
                <p className={classes.sectionTitle} style={clicked != 4 && clicked != 0 ? {width: '200px', fontSize: '15px', fontWeight: 400, lineHeight: '30px', paddingTop: '20px'} : { width: '300px', minHeight: '90px', fontSize: '20px', fontWeight: 600, lineHeight: '30px'}}>
                  Register your projects to streamline the claims process.
                </p>
                {clicked != 0 ? false : <CyanUnderLine />}
                {clicked === 4 || clicked === 0 ? <StyledP style={clicked === 4 ? {paddingTop: '0px'} : {}}>
                Submit your claims and qualify for labor cost reimbursement.
              </StyledP> : false}
              {clicked === 4 ? <CyanUnderLine style={{top: '133px'}} /> : false}
              {clicked === 4 ? 
              <StyledP style={{paddingTop: '50px',  color: '#fff'}}>
                Process your claims for PV module and ESS products easily through the claims portal.</StyledP> : false}
              </StyledDt>
            </StyledDl>
          </StyledA>
          </StyledList>
          {/* <img style={{margin: 'auto', cursor: 'pointer'}} src={ImageIdx} /> */}
        </StyledOl>
      </div>
      :
      <div className={classes.container}>
        <AliceCarousel
            autoWidth
            infinite
            mouseTracking
            items={items}
            touchTracking
            disableButtonsControls
            paddingLeft={0}
            keyboardNavigation
        />
      </div>}
    </>
  )
}