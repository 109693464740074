import React, { useEffect, useMemo, useState } from 'react';
import useStyles from './useStyles';
import CustomButton from '../../../common/components/button';
import CustomLoading from '../../../common/components/CustomLoading';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaProject } from './schemaProject';
import { initialState } from './initialStateProject';
import ReviewInfo from './ReviewInfo';
import classNames from 'classnames';
import { Button } from '@material-ui/core';
import IconEdit from '../../../icons/edit_icon.svg';
import OpenImageIcon from '../../../config/images/open_image_icon.svg';
import Moment from 'react-moment';
import { keyProject } from './keyProject';
import {useHistory} from 'react-router-dom';
import PopOver from '../../../config/images/popover_icon.svg';
import PopOverCommon from '../../../common/components/Popover';
import ImageModal from './ImageModal';
import moment from 'moment';
import Error from '../../claim/components/Error';
import MessageReview from './MessageReview';
import ShowMessage from '../../claim/components/ShowMessage';
import ShowMessagePaid from './ShowMessagePaid';
import ShowMessageNa from './ShowMessageNa';


function DetailProject({data, loading, handleDelete, distributors, setOpen, setOpenDel}) {

  const classes = useStyles();
  const history = useHistory();
  const [filesProject, setFilesProject] = useState([]);
  const [filesProof, setFilesProof] = useState([]);
  const [modalUrl, setModalUrl] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isPaid, setPaid] = useState(false);
  const [isPaidPanel, setIsPaidPanel] = useState(false);
  const [isPaidEss, setIsPaidEss] = useState(false);

  const { register, handleSubmit, errors, control, watch ,setValue, trigger ,getValues, clearErrors, formState } = useForm({
    resolver : yupResolver(schemaProject),
    mode: "onChange",
    enableReinitialize: true,
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldUnregister: false,
    shouldFocusError: false,

    defaultValues: initialState
  });

  const dataPro = watch();
  const moduleSerialList = watch('moduleSerialNumber');
  const essSerial = watch('essSerialNumber');
  const isSerialNumber = watch('isSerialNumber');
  const isEssSerialNumber = watch('isEssSerialNumber');
  const isManualSerialPanelNotMatchDb = watch('isManualSerialPanelNotMatchDb');
  const projectAddress = getValues();
  const panelQualified = watch('panelQualified');
  const panelNonQcells = watch('panelNonQcells');
  const batteryNonQcells = watch('batteryNonQcells');
  const batteryQualified = watch('batteryQualified');

  const numberOfModules = watch('numberOfModules');

  const moduleSerial = useMemo(() => {
    const uniqModuleSerialNumber = _.remove(
      _.uniqBy(moduleSerialList, 'productName'),
      p => p.productName
    );

    return uniqModuleSerialNumber
  }, [moduleSerialList]);


  useEffect(() => {
    setValue('projectName', data.projectName);
    setValue('projectAddress', {label : data.projectAddress?.label , value : data.projectAddress?.value});
    setValue('projectAddress2', data.projectAddress2);
    setValue('projectCity', data.projectCity);
    setValue('projectState', data.projectState ? data.projectState : 'select');
    setValue('projectZipCode', data.projectZipCode);
    if (_.trim(_.get(data, 'projectImages'))) {
      setFilesProject(_.map(_.split(_.get(data, 'projectImages'), '|'), p => ({ url: p })));
    }

    if (_.trim(_.get(data, 'proofOfOperateDate'))) {
      setFilesProof(_.map(_.split(_.get(data, 'proofOfOperateDate'), '|'), p => ({ url: p })));
    }

    setValue('installationDate', data.installationDate);
    setValue('operateDate', data.operateDate);
    setValue('moduleBrand', data.moduleBrand ? data.moduleBrand : 'select');

    /**
     * system information
     */


    setValue('moduleBrand', data.moduleBrand ? data.moduleBrand : 'select');
    const isManualSerialNumber = data.manualSerialNumber;
    setValue('isManualSerialNumber', !!isManualSerialNumber);
    const moduleSN = _.filter(_.get(data, 'serialNumbers'), s => s.type === 'modules');
    setValue('isManualSerialPanelNotMatchDb', _.get(data, 'isManualSerialPanelNotMatchDb', false));
    setValue('isManualSerialBatteryNotMatchDb', _.get(data, 'isManualSerialBatteryNotMatchDb', false));
    if (moduleSN && moduleSN.length > 0) {
      setValue('moduleSerialNumber', moduleSN);
      setValue('isSerialNumber', true);
      if(_.get(data, 'isManualSerialPanelNotMatchDb', false)) {
        setValue('moduleBrand', data.moduleBrand ? data.moduleBrand : 'select');
        setValue('modulePowerClass' , data.modulePowerClass ? data.modulePowerClass : '');
        setValue('moduleModel', data.moduleModel ? data.moduleModel : 'select');
        setValue('numberOfModules', data.numberOfModules ? data.numberOfModules : '');
        setValue('productName', data.productName);
        //setValue('volume', data.volume);
        setValue('numberOfModules', data.numberOfModules);
      }
    } else {
      setValue('moduleSerialNumber', []);
      setValue('isSerialNumber', false);
      setValue('moduleBrand', data.moduleBrand ? data.moduleBrand : 'select');
      setValue('modulePowerClass' , data.modulePowerClass ? data.modulePowerClass : '');
      setValue('moduleModel', data.moduleModel ? data.moduleModel : ( data.moduleBrand === 'Q CELLS' ? 'select' : ''));
      setValue('numberOfModules', data.numberOfModules ? data.numberOfModules : '');
      setValue('productName', data.productName);
      //setValue('volume', data.volume);
      setValue('numberOfModules', data.numberOfModules);
    }

    if(data.moduleBrand === 'Other') {
      setValue('nameOfModuleBrandPanelOther', data.nameOfModuleBrandPanelOther)
    }
    if(data.batteryBrand === 'Other') {
      setValue('nameOfModuleBrandBatteryOther', data.nameOfModuleBrandBatteryOther);
    }


    setValue('isEssSerialNumber', data.isEssSerialNumber);
    setValue('battery', !!data.battery);
    setValue('systemType', data.systemType ? data.systemType : 'select');
    setValue('batteryBrand', data.batteryBrand ? data.batteryBrand : 'select');
    setValue('batteryModel', data.batteryModel ? data.batteryModel : 'select');
    setValue('batteryEnergy', data.batteryEnergy ? data.batteryEnergy : 'select');
    setValue('batteryInverterPower', data.batteryInverterPower ? data.batteryInverterPower : 'select');

    const isSelectBatteryCapacity = ['AC', 'DC'].includes(data.systemType) && data.batteryBrand === 'Q CELLS';
    if (data.battery && isSelectBatteryCapacity) {
      setValue('batteryCapacity1', data.batteryCapacity ? data.batteryCapacity : 'select');
    }
    if (data.battery && !isSelectBatteryCapacity) {
      setValue('batteryCapacity2', data.batteryCapacity);
      setValue('batteryProductName1', data.batteryProductName);
    }
    const inverterSN = _.filter(_.get(data, 'serialNumbers'), s => s.type === 'inverter');
    const isManualEssSerialNumber = data.manualInverter;
    setValue('isManualEssSerialNumber', !!isManualEssSerialNumber);
    if (data.battery && ['DC', 'AC'].includes(data.systemType) && data.batteryBrand === 'Q CELLS' && inverterSN && inverterSN.length > 0) {
      setValue('essSerialNumber', inverterSN);
      setValue('isEssSerialNumber', true);
    } else {
      setValue('essSerialNumber', []);
      setValue('isEssSerialNumber', false);
    }

    if (!data.battery || (data.battery && data.systemType === 'select')) {
      if (data.inverterProductType) {
        setValue('inverterProductBrand', data.inverterProductType);
      }
      if (data.inverterProductName) {
        setValue('inverterProductName', data.inverterProductName);
      }
    }

    if (data.battery && data.systemType === 'AC') {
      if (data.pvProductType) {
        setValue('pvInverterProductBrand', data.pvProductType);
      }
      if (data.pvProductName) {
        setValue('pvInverterProductName', data.pvProductName);
      }
    }

    setValue('pvInverterBrand', data.pvInverterBrand ? data.pvInverterBrand : 'select');
    setValue('pvInverterModel', data.pvInverterModel ? data.pvInverterModel : 'select');
    setValue('rackingBrand', data.rackingBrand ? data.rackingBrand : 'select');
    setValue('rackingModel', data.rackingModel ? data.rackingModel : '');

    setValue('purchasingChannel', data.purchasingChannel ? data.purchasingChannel : 'select');
    if (data.purchasingChannel === 'distributor') {
      setValue('nameOfPurchasingChannelDistributor', distributors.find(d => d.name === data.nameOfPurchasingChannel));
    } else if (data.purchasingChannel === 'other') {
      setValue('nameOfPurchasingChannelOther', data.nameOfPurchasingChannel);
    }

    setValue('panelQualified', data.panelQualified);
    setValue('batteryQualified', data.batteryQualified);
    setValue('panelNonQcells', data.panelNonQcells);
    setValue('batteryNonQcells', data.batteryNonQcells);


    /**
     * define
     * countSubmit : 0,
     * countDraft : 0,
     *
     * */

    /**
     * case 1 : submited to claim countSubmit = 0 && countDraft = 0
     * case 2 : countiene complte countDraft > 0 @@ countSubmit = 0
     * case 3 : countSubmit > 0 && countDraft > 0
     *
     * */
    setValue('systemSize', data.systemSize);
    setValue('firstName', data.firstName);
    setValue('lastName', data.lastName);
    setValue('email', data.email);
    setValue('phoneNumber', data.phoneNumber);
    setValue('selectedContactNumber', data.isMobileNumber ? '2' : '1');
    setValue('note', data.note);
    setValue('createdAt', data.createdAt);
  }, [data]);

  useEffect(() => {
   trigger(keyProject).then()
  }, [data])



  function handleDeleteProject() {
    // handleDelete([data.id]);
    if(data.claimSubmitted > 0) {
      setOpenDel(true)
    }else {
      setOpen(true)
    }

  }


  const renderProjectName = () => {
    if (_.trim(data.projectName)) {
      return _.trim(data.projectName);
    }

    const firstName = data.firstName;
    const lastName = data.lastName;
    const city = data.projectCity;
    let systemSize = null;

    return _.join(_.remove([_.trim(`${firstName || ''} ${lastName || ''}`), systemSize, city], value => !!value), ', ');
  };

  const renderField = fieldName => {
    const fieldValue = data[fieldName];
    if (!!fieldValue && fieldValue !== 'select') {
      return fieldValue;
    }

    return null;
  };


  const renderBatteryModel = (model) => {
    return (
      <>{model === 'select' ? '' : model} <br/> {!batteryQualified && model !== 'select' && <MessageReview text={'*This product does not qualify for labor cost reimbursement'}/> }</>
    )
  }

  const renderAddress = (array = []) => {
    const newArray = _.remove(array, value => !!value && value !== 'select');
    return newArray.length > 0 ? _.join(newArray, ', ') : '';
  };

  const renderPurchasingChannel = purchasingChannelValue => {
    switch (purchasingChannelValue) {
      case 'Q CELLS':
        return 'Q CELLS';
      case 'distributor':
        return 'Distributor';
      case 'other':
        return 'Other';
      default:
        return 'Q CELLS';
    }
  };

  const renderNameOfPurchasingChannel = () => {
    const purchaseChannel = dataPro.purchasingChannel;
    switch (purchaseChannel) {
      case 'Q CELLS':
        return 'Q CELLS';
      case 'distributor':
        return _.get(dataPro, 'nameOfPurchasingChannelDistributor.name');
      case 'other':
        return dataPro.nameOfPurchasingChannelOther;
      default:
        return '';
    }
  }

  function renderSystemSize() {
    if(data.systemSize > 25) {
      return (
        <p>{`${data.systemSize} kW`} <br/> <MessageReview text={'*System size should be less than or equal to 25kW to qualify for labor reimbursement'}/></p>
      )
    }

    return <p>{data.systemSize} kW</p>
  }

  function renderModuleBrand(){
    if(!errors.moduleBrand) {
      return <p>{`${data.moduleBrand} ${data.moduleBrand === 'Other' ? `-${data.nameOfModuleBrandPanelOther}` : ''}`} <br/> {panelNonQcells && <MessageReview text={'*Project should use Q CELLS residential products only to qualify for labor reimbursement'}/>}</p>
    }else {
      return <p><Error/></p>
    }
  }

  function renderModelPanel() {
    return(
      <>{(data?.moduleBrand !== 'Q CELLS') ? ((data.moduleModel === '' || data.moduleModel === null )? 'N/A'  : data.moduleModel ) : (moduleSerial.length === 0 ?  data.moduleModel : _.get(moduleSerial, '[0].productName', data.moduleModel))} <br/> {!panelQualified && <MessageReview text={'*This product does not qualify for labor cost reimbursement'}/>}</>
    )
  }



  function renderBatteryBrand() {
    if(!errors.batteryBrand) {

      return <p>{`${data.batteryBrand} ${data.batteryBrand === 'Other' ? `-${data.nameOfModuleBrandBatteryOther}` : ''}`} <br/> {batteryNonQcells && <MessageReview text={'*Project should use Q CELLS residential products only to qualify for labor reimbursement'}/>}</p>

    }else {
      return <p><Error/></p>
    }
  }

  function renderOperaDateError() {
    const perateDateI = moment(_.get(data, 'operateDate')).add(3, 'M').format('YYYY-MM-DD');
    const dateNow = moment(_.get(data, 'createdAt')).format('YYYY-MM-DD')
    const isOpe = moment(perateDateI).isSameOrAfter(dateNow)
    return (
      <>
        <Moment format="L">{data.operateDate}</Moment>
        <br/>
        {!isOpe && <MessageReview text={'*This project will not qualify for labor reimbursement as the PTO date is not within the past 3 months'}/>}
      </>
    )
  }

  function checkPaidPanel() {
    const listClaim = _.filter(_.get(data, 'claims', []), s => s.claimLabel !== null && s.type === 1)
    let isPaid = false

    if(listClaim.length > 0) {
      for (let i = 0; i < listClaim.length; i++) {
        if([6, 7, 9, 11, 5].includes(listClaim[i].statusId)) {
          isPaid = true
        }
      }
    }

    return isPaid

  }

  function checkPaidEss() {
    const listClaim = _.filter(_.get(data, 'claims', []), s => s.claimLabel !== null && s.type === 2)
    let isPaid = false

    if(listClaim.length > 0) {
      for (let i = 0; i < listClaim.length; i++) {
        if([6, 7, 9, 11, 5].includes(listClaim[i].statusId)) {
          isPaid = true
        }
      }
    }

    return isPaid

  }

  function suggestType(p) {
    //case 1 : b-1) Q CELLS module only, no ESS - with S/N (valid or awaiting update) (panelNonQcells : false & panelQualified : true)
    const panelQualified  = p.panelQualified;
    const batteryQualified = p.batteryQualified;
    const panelNonQcells  = p.panelNonQcells;
    const batteryNonQcells = p.batteryNonQcells;

    let type = 'select';



    if(!panelNonQcells && !batteryNonQcells) {
      type = 'select'
    }else if(!panelNonQcells) {
      type = '1'
    }else if(!batteryNonQcells) {
      type = '2'
    }




    return type



    /**
     * fine 1 : (only) panelNonQcells : false & panelQualified : true -> type 1 (2) -> []
     * fine 2 : (only) batteryNonQcells : false & batteryQualified : true -> type 2 (1) ->  []
     * fine 3 : fine 1 + fine 2 -> type : 'select'
     *
     *
     *
     *
     * */

  }



  function renderButtonSubmit() {

    const panelQualified  = data.qualifyLaborWarranty;
    const batteryQualified = data.batteryQualified;
    const panelNonQcells  = data.panelNonQcells;
    const batteryNonQcells = data.batteryNonQcells;

    let laborQcell = false;
    let laborQuanlify = false;

    if(!panelNonQcells === true || !batteryNonQcells === true) {
      laborQcell = true
    }

    if(panelQualified === true || batteryQualified === true) {
      laborQuanlify = true
    }

    return(
      <>
        {!data?.isDraft && laborQcell && <CustomButton onClick={() => {
          if(data.battery) {
            history.push({
              pathname : `/claim/info`,
              state : {
                battery : true,
                type : suggestType(data),
                id : data.id,
                // projectInfo : p
              }
            })
          }else {
            history.push({
              pathname : `/claim/info`,
              state : {
                battery : false,
                type : suggestType(data),
                id : data.id,
                // projectInfo : p
              }
            })
          }
        }}>
          Submit a Claim
        </CustomButton>}
      </>
    )
  }



  useEffect(() => {
      setIsPaidPanel(checkPaidPanel())
      setIsPaidEss(checkPaidEss())
  }, [_.filter(_.get(data, 'claims', []), s => s.claimLabel !== null)])

  function renderStatusEss() {

    if(isPaidEss && !data.batteryLaborReimbursement) {
      return <div className={classes.wrapPaid}> <span className={classes.wSpan}>Paid</span> <ShowMessagePaid textInfo={' One-time reimbursement paid or in process with an existing claim. There will be no more reimbursements.'} /></div>
    }

    if(!data.batteryQualified) {
      return <div className={classes.wrapPaid}> <span className={classes.wSpan}>N/A</span> <ShowMessageNa/></div>
    }



    if((!data.batteryNonQcells && !isPaidEss && batteryQualified && data.batteryLaborReimbursement) || (!data.batteryNonQcells && isPaidEss && batteryQualified && data.batteryLaborReimbursement)) {
      return (
        <div className={classes.wrapPaid}> <span className={classes.wSpan}>Unused</span> <ShowMessagePaid textInfo={'One-time reimbursement available (up to $250) with a valid product claim.'}/> </div>
      )
    }

  }

  /**
   * khi nao project la larbor re
   *
   * ((panelNoneQcells : false ,  batteryNoneQcell : false , panelQuanlify : true, batteryQuanlify : true, panelRequiment : true, battery Requirment : true) )
   *
   *
   * */

  function renderStatusLaborReimbursement() {
    let statusInfo = false

    const panelQualified  = data.qualifyLaborWarranty;
    const batteryQualified = data.batteryQualified;
    const panelNonQcells  = data.panelNonQcells;
    const batteryNonQcells = data.batteryNonQcells;

    let laborPanel = false;
    let laborEss = false;

    let laborQcell = false;

    if(!panelNonQcells === true || !batteryNonQcells === true) {
      laborQcell = true
    }

    // if(!panelNonQcells && panelQualified) {
    //   laborPanel = true
    // }

    // if(!batteryNonQcells === true && batteryQualified) {
    //   laborEss = true
    // }


    if(data.batteryLaborReimbursement === true || data.panelLaborReimbursement === true || panelQualified) {
      statusInfo = true
    }

    return statusInfo && !data.isDraft



  }


  function renderStatusPanel() {

    //true true true false
    //isPaidPanel, data.panelLaborReimbursement, panelQualified, panelNonQcells

    if(isPaidPanel && !data.panelLaborReimbursement) {
      return <div className={classes.wrapPaid}> <span className={classes.wSpan}>Paid</span> <ShowMessagePaid textInfo={' One-time reimbursement paid or in process with an existing claim. There will be no more reimbursements.'} /></div>
    }

    if(!panelQualified) {
      return (
        <div className={classes.wrapPaid}> <span className={classes.wSpan}>N/A</span> <ShowMessageNa/></div>
      )
    }



    if((!panelNonQcells && !isPaidPanel && panelQualified && data.panelLaborReimbursement) || (!panelNonQcells && isPaidPanel && panelQualified && data.panelLaborReimbursement)){
      return (
        <div className={classes.wrapPaid}> <span className={classes.wSpan}>Unused</span> <ShowMessagePaid textInfo={'One-time reimbursement available (up to $250) with a valid product claim.'}/> </div>
      )
    }
  }

  return(
    <div className={classes.wrapDetailProject}>
      <div className={classes.headerProject}>
        <div className={classes.leftHeaderProject}>
          <span className={classes.titleProject}>{data?.projectName}</span><br/>
          <span className={classes.subTitleProject}>Project Details</span>
        </div>

        <div className={classes.rightHeaderProject}>
          {renderButtonSubmit()}
        </div>
      </div>
      {data.isDraft && 
        <div className={classes.draftInfo}>
          <div className={classes.leftDraft}>
            <img style={{marginRight: 8, height : 20}} src={PopOver}/> <em>This is a draft that needs to be completed</em>
          </div>
          <div className={classes.rightDraft}>
            <CustomButton style={{height : 35}} onClick={() => {history.push(`/projects/draft/${data.id}/0`)
              // return data.isDraft
              //   ? history.push(`/projects/draft/${data.id}/0`)
              //   : history.push(`/projects/${data.id}/0`)
            }}>
              Continue Submission
            </CustomButton>
          </div>
        </div>
      }
      <div style={{marginTop : 36}} className={classes.reviewSectionContainer}>
        <div className={classes.dateInfo}>
          <span>Registered on {moment(data.createdAt).format('MM/DD/YYYY')}</span>
        </div>
      </div>
      {!data.isDraft && <div className={classes.reviewSectionsContainerDetailProject} style={{marginTop: 0}}>
        {data.claimSubmitted > 0 && <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Claims Submitted ({data.claimSubmitted})</h6>
            <div className={classes.wrapListClaim}>
              {_.filter(_.get(data, 'claims', []), s => s.claimLabel !== null).map((item, index) => {
                return <span onClick={() => history.push(`/claim/detail/${item.id}/${item.type}`)} key={index} className={classes.itemClaim}>{item.claimLabel}</span>
              })}
            </div>
          </div>
        </div>}
        <div className={classes.reviewSectionContainer}>
          <div style={{paddingLeft : 0}} className={classes.reviewSectionContent}>
            <p>Qualifies for Labor Reimbursement</p>
            <p>{!data.qualifyLaborWarranty  ? 'No' : 'Yes'}</p>
          </div>
        </div>
        {renderStatusLaborReimbursement() && <>
        {(isPaidPanel === false || isPaidEss === false) && <div  className={classes.reviewSectionContainer}>
          <div style={{paddingLeft : 0}} className={classes.reviewSectionContent}>
            <p>Labor Reimbursement Payment Status</p>
          </div>
        </div>}
        <div className={classes.reviewSectionContent}>
          <h6>Panel</h6>
          <div style={{display : 'flex'}}>{renderStatusPanel()}</div>
        </div>
        {data.battery && <div className={classes.reviewSectionContent}>
          <h6>ESS</h6>
          <div style={{display : 'flex'}}>{renderStatusEss()}</div>
        </div>}
         </>}
      </div>}
      <div className={classes.reviewSectionsContainerDetailProject}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>Project Information</h5>
          <Button className={classes.editBtn} onClick={() => {
            return data.isDraft
              ? history.push(`/projects/draft/${data.id}/0`)
              : history.push(`/projects/${data.id}/0`)
          }}>
            <span>Edit</span>
            <img src={IconEdit} title='Edit' />
          </Button>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Project Name</h6>
            <p>{renderProjectName()}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Project Address</h6>
            <p>
              {!errors?.projectAddress ? renderAddress([
                data.projectAddress,
                data.projectAddress2,
                data.projectCity,
                data.projectState,
                data.projectZipCode
              ]) : <span style={{color: '#EB5757'}}>Required field missing</span> }
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div
            style={{
              paddingTop: '13px',
              paddingBottom: '13px'
            }}
            className={classes.reviewSectionContent}
          >
            <h6>Project Picture</h6>
            <div className={classes.images}>
              {filesProject && filesProject?.map(p => (
                <div key={p.url}>
                  <img src={p.url} className={classes.image} />
                  <div className={classes.overlay} role="overlay">
                    <img
                      src={OpenImageIcon}
                      onClick={() => {
                        setModalUrl(p.url);
                        setOpenModal(true);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Installation Date</h6>
            <p>
              {data.installationDate && !errors?.installationDate ? <Moment format="L">{data.installationDate}</Moment> : <span style={{color: '#EB5757'}}>Required field missing</span>}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Permission-to-Operate Date</h6>
            <p>
              {data.operateDate && !errors?.operateDate ? renderOperaDateError() : <span style={{color: '#EB5757'}}>Required field missing</span>}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div
            style={{
              paddingTop: '13px',
              paddingBottom: '13px'
            }}
            className={classes.reviewSectionContent}
          >
            <h6>Proof of Permission-to-Operate Date</h6>
            <div className={classes.images}>
              {filesProof?.length > 0 && filesProof?.map(p => (
                <div key={p.url}>
                  <img src={p.url} className={classes.image} />
                  <div className={classes.overlay} role="overlay">
                    <img
                      src={OpenImageIcon}
                      onClick={() => {
                        setModalUrl(p.url);
                        setOpenModal(true);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.reviewSectionsContainerDetailProject}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>System Information</h5>
          <Button className={classes.editBtn} onClick={() => {
            return data.isDraft
              ? history.push(`/projects/draft/${data.id}/1`)
              : history.push(`/projects/${data.id}/1`)
          }}>
            <span>Edit</span>
            <img src={IconEdit} title='Edit' />
          </Button>
        </div>
        <div className={classes.reviewSectionContent} style={{paddingLeft: 0}}><p>Panel</p></div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Brand</h6>
            <p>{!errors?.moduleBrand ? renderModuleBrand() : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Power Class (W)</h6>
            <p>{!errors?.modulePowerClass ? data.modulePowerClass : <span style={{color: '#EB5757'}}>Required field missing</span>} {!errors?.modulePowerClass ? 'W' : ''}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Model</h6>
            <p>{!errors?.moduleModel ? renderModelPanel() : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Number of Modules</h6>
            <p>{!errors?.numberOfModules ? (data?.moduleBrand !== 'Q CELLS') ? data.numberOfModules : (isManualSerialPanelNotMatchDb ?  data.numberOfModules : (isSerialNumber ? moduleSerialList?.length : data.numberOfModules )) : <span style={{color: '#EB5757'}}>Required field missing</span>} {!errors?.numberOfModules ? 'pcs' : ''}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>System Size </h6>
            {renderSystemSize()}
          </div>
        </div>
        <div className={classes.reviewSectionContent} style={{paddingLeft: 0}}><p>Battery</p><p style={{padding : 10}}>{data?.battery ? 'Yes' : 'No'}</p></div>
        {data?.battery && <>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>System Type</h6>
              <p>{!errors?.systemType ? renderField('systemType') :<span style={{color: '#EB5757'}}>Required field missing</span> }</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Brand</h6>
              <p>{!errors?.batteryBrand ? renderBatteryBrand() : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
            </div>
          </div>

          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Model</h6>
              <p>{!errors?.batteryModel ? renderBatteryModel(data?.batteryBrand === 'Q CELLS' ? (isEssSerialNumber && !data.isManualSerialBatteryNotMatchDb ? _.get(essSerial, '[0].productName') : data.batteryModel) : data.batteryModel) : (<span style={{color: '#EB5757'}}>Required field missing</span>)}</p>
            </div>
          </div>
          {['select', 'Q CELLS'].includes(data.batteryBrand) && <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Battery Inverter Power(kW)</h6>
              <p>{!errors?.batteryInverterPower ? data?.batteryBrand === 'Q CELLS' ? `${(isEssSerialNumber && !data.isManualSerialBatteryNotMatchDb ? _.get(essSerial, '[0].volume') / 1000 : data.batteryInverterPower)} kW` : '' : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
            </div>
          </div>}
          {['select', 'Q CELLS'].includes(data.batteryBrand) && <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Battery Energy(kWh)</h6>
              <p>{!errors?.batteryEnergy ? data?.batteryBrand === 'Q CELLS' ? `${data.batteryEnergy} kWh` : '' : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
            </div>
          </div>}
        </>}
        <div className={classes.reviewSectionContent} style={{paddingLeft: 0}}><p>PV Inverter</p></div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Brand</h6>
            <p>{data.pvInverterBrand === 'select' ? '' : data.pvInverterBrand}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Model</h6>
            <p>{data.pvInverterModel === 'select' ? '' : data.pvInverterModel}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContent} style={{paddingLeft: 0}}><p>Racking</p></div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Brand</h6>
            <p>{data.rackingBrand === 'select' ? '' : data.rackingBrand}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Model</h6>
            <p>{data.rackingModel === '' ? '' : data.rackingModel}</p>
          </div>
        </div>
      </div>
      <div className={classes.reviewSectionsContainerDetailProject}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>Purchasing Information</h5>
          <Button className={classes.editBtn} onClick={() => {
            return data.isDraft
              ? history.push(`/projects/draft/${data.id}/2`)
              : history.push(`/projects/${data.id}/2`)
          }}>
            <span>Edit</span>
            <img src={IconEdit} title='Edit' />
          </Button>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Purchasing Channel</h6>
            <p>{!errors?.purchasingChannel ? renderPurchasingChannel(data.purchasingChannel) : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Name of Channel</h6>
            <p>{(!errors?.nameOfPurchasingChannelOther && !errors?.nameOfPurchasingChannelDistributor) ? renderNameOfPurchasingChannel() : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>

          </div>
        </div>
      </div>

      <div className={classes.reviewSectionsContainerDetailProject}>
        <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
          <h5>Project Owner Information</h5>
          <Button className={classes.editBtn} onClick={() => {
            return data.isDraft
              ? history.push(`/projects/draft/${data.id}/3`)
              : history.push(`/projects/${data.id}/3`)
          }}>
            <span>Edit</span>
            <img src={IconEdit} title='Edit' />
          </Button>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Project Owner</h6>
            <p>{(!errors?.firstName || !errors?.lastName) ? `${data.firstName} ${data.lastName}` : <span style={{color: '#EB5757'}}>Required field missing</span> }</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>E-mail</h6>
            <p>{!errors?.email ? data.email : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Contact Number</h6>
            <p>{!errors?.phoneNumber ? data.phoneNumber : <span style={{color: '#EB5757'}}>Required field missing</span>}</p>
          </div>
        </div>
      </div>
      <div className={classNames([classes.reviewSectionsContainerDetailProject], [classes.reviewNoteSection])}>
        <span style={{fontWeight: '500'}}>Notes: </span> {data.note}
      </div>
      <button className={classes.btnDelProject} onClick={handleDeleteProject}>
        {loading ? <CustomLoading size={16} color="#802222"/> : 'Delete Project'}
      </button>

      <ImageModal
        open={openModal}
        url={modalUrl}
        name={modalUrl ? modalUrl.substring(modalUrl.lastIndexOf('/') + 1) : ''}
        handleClose={() => {
          setOpenModal(false);
          setModalUrl('');
        }}
      />
    </div>
  )
}

export default DetailProject;
