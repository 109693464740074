import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Modal, Paper, TextareaAutosize, useMediaQuery } from '@material-ui/core';
import IconEdit from '../../../icons/edit_icon.svg';
import Moment from 'react-moment';
import CustomButton from '../../../common/components/button';
import CustomLoading from '../../../common/components/CustomLoading';
import useStyles from '../components/useStyles';
import _ from 'lodash';
import OpenImageIcon from '../../../config/images/open_image_icon.svg';
import ImageModal from '../../projects/components/ImageModal';
import {useHistory, useParams} from 'react-router-dom';
import classNames from 'classnames';
import { decodeStringHtml } from '../../../utils/helpers';
import Error from '../components/Error';
import { claimService, projectService } from '../../../services';
import axios from '../../../utils/axios';
import { baseUrl } from '../../../config';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaPV } from '../components/schemaPv';
import { defaultStatePv } from '../components/defaultStatePv';
import { keyPv } from '../components/keyPV';
import { useAuth } from '../../../contexts/auth';
import PopOver from '../../../config/images/popover_icon.svg';
import BannerDetail from '../components/BannerDetail';
import PopoverDetail from '../components/PopoverDetail';
import CloseIcon from '@material-ui/icons/Close';
import Text from '../../../common/components/text';
import ImageUploader from '../components/UploadImage';
import { schemaFormUpdate } from '../components/schemaFormUpdate';
import ShowMessageUpdate from '../components/ShowMessageUpdate';
import FormUpDateInfoClaim from '../components/FormUpdateInfoClaim';

function ClaimDetailPanel() {
  const classes = useStyles();
  const history = useHistory();
  const {id} = useParams();

  const [modalUrl, setModalUrl] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [distributors, setDistributors] = useState([]);

  const [fileProofOfPermissionToOperateDate, setFileProofOfPermissionToOperateDate] = useState([]);
  const [fileProofOfPermission, setFileProofOfPermission] = useState([]);
  const [filePictureOfAffectedModule, setFilePictureOfAffectedModule] = useState([]);
  const [filePictureOfVoc, setFilePictureOfVoc] = useState([]);
  const [filePictureOfFront, setFilePictureOfFront] = useState([]);
  const [filePictureOfBack, setFilePictureOfBack] = useState([]);
  const [filePictureCloseUp, setFilePictureCloseUp] = useState([]);
  const [filePictureSitePlan, setFilePictureSitePlan] = useState([]);
  const [filePictureOfOverall, setFilePictureOfOverall] = useState([]);
  const [filePictureOfWireManagement, setFilePictureOfWireManagement] = useState([]);
  const [filePictureProofDelivery, setFilePictureProofDelivery] = useState([]);
  const [filePictureImmediately, setFilePictureImmediately] = useState([]);
  const [filePictureOfVmpp, setFilePictureOfVmpp] = useState([]);
  const [filePictureIr, setFilePictureIr] = useState([]);
  const [filePictureInVoice, setFilePictureInVoice] = useState([])
  const [fileBankAccountInfo, setFileBankAccountInfo] = useState([]);
  const [fileInvoiceForLaborReimbursement, setFileInvoiceForLaborReimbursement] = useState([]);
  const [serials, setSerials] = useState([]);
  const [urlImageProof, setFilesProof] = useState([]);
  const [listProductPti, setListProductPti] = useState([]);
  const [listSerial, setListSerial] = useState([]);
  const [claimLabel, setClaimLabel] = useState('');
  const [isDraft, setIsDraft] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(true);
  const [detailClaim, setDetailClaim] = useState({});
  const [open, setOpen] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [fileUpdate, setFileUpdate] = useState([]);
  const [keyUpload, setKeyUpload] = useState('');
  const [text, setText] = useState('')
  const user = useAuth();

  const { register, errors , control , watch , setValue : setValuePv, getValues, formState: formStatePv, trigger ,clearErrors : clearErrorsPv } = useForm({
    resolver : yupResolver(schemaPV),
    mode: "onChange",
    reValidateMode: "onChange",
    shouldUnregister: false,
    shouldFocusError: false,
    enableReinitialize: true,
    defaultValues: {
      installerCity : '',
      installerAddress1 : '',
      installerAddress2 : '',
      installerState : 'select',
      installerZipcode : '',
      checkedEmail : false,
      infoClaimName : 'PV',
      info_claim_type : 'select',
      projectId : null,
      ...defaultStatePv,
      installation_date_pv : null,
      comission_date_pv : null,
      affected_module_serial_number : 'select',
      region_pv : 'select',
      site_module_layout_pv : '',
      inverter_type_pv : 'select',
      region : '',
      purchase_date_info : null,
      chosePermissionDate : '1',
      choseAffectedSerialNumber : '1',
      serials : [],
      choseWebAddress : '1',
      chooseRefund: '1',
      choseTypeProject : '1',
    }
  });
  const {register: registerUpdate, errors : errorUpdate , control : controlUpdate , watch : watchUpdate} = useForm({
    resolver : yupResolver(schemaFormUpdate),
    mode: "onChange",
    reValidateMode: "onChange",
    shouldUnregister: false,
    shouldFocusError: false,
    enableReinitialize: true,
    defaultValues : {
      isUploadFileUpdate : '',
      evidence_description : ''
    }
  })


  const choseTypeSerialUpload = watch('choseTypeSerialUpload')
  const listSerialPickData = watch('listSerialPick');
  const choseTypeProject = watch('choseTypeProject');

  const data = watch();

  const renderAddress = (array = []) => {
    const newArray = _.remove(array, value => !!value || value === 'select');
    return newArray.length > 0 ? _.join(newArray, ', ') : '';
  };

  const renderInventerType = (type) => {

    const actions = {
      '1' : 'String inverter',
      '2' : 'Central inverter',
      '3' : 'Optimizer',
      '4' : 'Microinverter',
      'default' : ''
    }

    return actions[type] || actions['default']
  }

  function renderRegion(value) {
    const actions = {
      1 : 'United States',
      2 : 'Canada',
      3 : 'Latin America'
    }

    return actions[parseInt(value)] || 'United States'

  }

  const renderClaimType = (type) => {
    const actions = {
      1 : 'Power loss/under performing module',
      2 : 'Hot spot',
      3 : 'Broken glass',
      4 : 'Connector damage',
      5 : 'Detached cable/junction box damage',
      6 : 'Detached frame',
      7 : 'Other',
      'default' : ''
    }

    return actions[parseInt(type)] || actions['default']


  }


  const renderNameOfPurchasingChannel = () => {
    const purchaseChannel = getValues('purchasingChannel');
    switch (purchaseChannel) {
      case 'qcells':
        return 'Q CELLS';
      case 'distributor':
        // return _.get(data, 'nameOfPurchasingChannelDistributor.name');
        return _.get(data, 'nameOfPurchasingChannelDistributor.name') + '' + `${_.get(data, 'nameOfPurchasingChannelDistributor.name') === "Other" ? `-${_.get(data, 'nameOfChanelOtherDistributor', '')}` : ''}`;
      case 'other':
        return getValues('nameOfPurchasingChannelOther');
      default:
        return '';
    }
  };

  const renderPurchasingChannel = purchasingChannelValue => {
    switch (purchasingChannelValue) {
      case 'qcells':
        return 'Q CELLS';
      case 'distributor':
        return 'Distributor';
      case 'other':
        return 'Other';
      default:
        return '';
    }
  };

  function handleBackReview() {


  }

  function renderSerialNumber() {
    if(choseTypeSerialUpload === '1') {
      return getValues('serialNumbers')?.map(item => item.serialNumber).join(', ').toString()
    }else {
      return listSerialPickData?.map(item => item.serialNumber).join(', ').toString()
    }
  }

  function setGroupPv(url) {

    let typeGroup = '';

    if(/^.*picture_of_affected_module_labels.*$/.test(url)) {
      typeGroup = 1
    }

    if(/^.*picture_of_front_of_module.*$/.test(url)) {
      typeGroup = 2
    }

    if(/^.*picture_of_voc_reading.*$/.test(url)) {
      typeGroup = 3
    }

    if(/^.*close_up_picture_of_physical_defect.*$/.test(url)) {
      typeGroup = 4
    }

    if(/^.*site_module_layout_plan.*$/.test(url)) {
      typeGroup = 5
    }

    if(/^.*picture_of_overall_roof_area.*$/.test(url)) {
      typeGroup = 6
    }

    if(/^.*picture_of_wire_management.*$/.test(url)) {
      typeGroup = 7
    }

    if(/^.*proof_of_delivery_document.*$/.test(url)) {
      typeGroup = 8
    }

    if(/^.*picture_of_module_immediately_upon_receipt.*$/.test(url)) {
      typeGroup = 9
    }

    if(/^.*picture_of_vmpp.*$/.test(url)) {
      typeGroup = 10
    }


    if(/^.*ir_picture.*$/.test(url)) {
      typeGroup = 11
    }

    if(/^.*copy_of_invoicereceipt.*$/.test(url)) {
      typeGroup = 12
    }

    if(/^.*invoice_for_labor_cost_reimbursement.*$/.test(url)) {
      typeGroup = 13
    }

    if(/^.*bank_account_information.*$/.test(url)) {
      typeGroup = 14
    }

    if(/^.*picture_of_back_of_module.*$/.test(url)) {
      typeGroup = 15
    }

    if(/^.*proof_of_permission_to_operate_date.*$/.test(url)) {
      typeGroup = 16
    }


    const actions = {
      1 : 'affectModule',
      2 : 'fontOfModule',
      3 : 'vocReading',
      4 : 'physicalDefect',
      5 : 'sitePlan',
      6 : 'overRall',
      7 : 'wireManagement',
      8 : 'proofDelivery',
      9 : 'immedDiately',
      10 : 'offVmpp',
      11 : 'irPicture',
      12 : 'coppyReceipt',
      13 : 'laborCost',
      14 : 'bankAccount',
      15 : 'backOfModule',
      16 : 'proofPermission',
      'default' : 'errorLog'
    }
    return actions[typeGroup] || actions['default'];
  }

  function suggestionsImagePv(strImage) {
    const arrayImage = strImage?.split(',').map(item => {
      return {
        url : item,
        group : setGroupPv(item)
      }
    });

    return _.groupBy(arrayImage, 'group');

  }

  function renderStatusName(statusId) {
    const action = {
      1 : 'Submitted',
      2 : 'Requires more info',
      3 : 'In review',
      4 : 'In review',
      8 : 'In review',
      5 : 'Processing',
      11 : 'Approved',
      12 : 'In review',
      6 : 'Closed/Approved',
      7 : 'Closed/Approved',
      9 : 'Closed/Approved',
      10 : 'Closed/Rejected',
      'default' : ''
    }

    return action[statusId] || action['default']
  }

  function renderAddressProjectInfo() {
    const infoAddressProject = watch('projectAddress')
    const infoAddressProjectManual = watch('projectAddressManual')
    const choseTypeProject = watch('choseTypeProject');

    if(choseTypeProject === '2') {
      return infoAddressProjectManual?.label || ''
    }

    return infoAddressProject

  }

  function renderLaborStatus(laborReimbursement, laborWarranty, choseTypeProject, chooseRefund, panelQualified) {

    if(choseTypeProject === '2') {
      return 'No'
    }

    if(laborReimbursement && laborWarranty && chooseRefund === '1') {
      return 'No'
    }

    if(!panelQualified) {
      return 'No'
    }

    if((!laborReimbursement && laborWarranty) || !laborReimbursement) {
      return 'No'
    }



    return 'Yes'
  }

  useEffect(() => {
    getDetailClaim()
  }, [])

  async function getDetailClaim() {
    const info = await claimService.getDetailClaim(id);
    const { data } = await axios.get(`${baseUrl}/distributors`);
    setDistributors(data);
    setLoadingDetail(false)

    setKeyUpload(_.get(info.data, 'folderId', ''))
    setText(_.get(info.data, 'evidenceDescription', ''))
    setValuePv('qualifyLaborWarranty', _.get(info.data, 'draftData.projectInfo.qualifyLaborWarranty', ''))
    setValuePv('created', _.get(info.data, 'created', ''))
    setValuePv('statusId', _.get(info.data, 'statusId', ''))
    setValuePv('submitterEmail', _.get(info.data, 'draftData.submitterEmail', ''))
    setValuePv('checkedEmail', _.get(info.data, 'draftData.checkedEmail', false))
    setValuePv('infoClaimName', _.get(info.data, 'draftData.claimInfoName', 'select'))
    setValuePv('choseTypeProject', _.get(info.data, 'draftData.choseTypeProject', '1'))
    if(_.get(info.data, 'draftData.projectId', null)) {
      const projectData  = await projectService.getDetailProject(_.get(info.data, 'draftData.projectId', null));
      let projectInfo = projectData?.data;
      setValuePv('projectInfo', projectInfo)

    }else {
      setValuePv('projectInfo', null)
    }
    setValuePv('choseTypeSerialUpload', _.get(info.data, 'draftData.choseTypeSerialUpload', '1'))
    setValuePv('serialNumbers', _.get(info.data, 'draftData.serialNumbers', []))
    _.get(info.data, 'draftData.serialNumbers', []).length === 0 && setValuePv('listSerialPick', _.get(info.data, 'draftData.listSerialPick', []))
    _.get(info.data, 'draftData.choseTypeProject', '1')  === '1'
    && _.get(info.data, 'draftData.choseTypeSerialUpload', '1') === '1' &&
    setSerials(_.get(info.data, 'draftData.serialNumbers', []))
    _.get(info.data, 'draftData.choseTypeProject', '1')  === '1' &&
    _.get(info.data, 'draftData.choseTypeSerialUpload', '1') === '2' &&
    setSerials(_.get(info.data, 'draftData.listSerialPick', []))
    setValuePv('listSerialPick', _.get(info.data, 'draftData.listSerialPick', []))
    //setListSerial(_.get(info.data, 'draftData.listSerialPick', []));
    const moduleSN = _.filter(_.get(info.data, 'draftData.projectInfo.serialNumbers'), s => s.type === 'modules');
    _.get(info.data, 'draftData.listSerialPick', [])?.length > 0 ? setValuePv('isUploadSerialNumber', 'done') : setValuePv('isUploadSerialNumber', '')
    setListSerial(moduleSN)
    setClaimLabel(_.get(info.data, 'claimLabel', ''))
    setIsDraft(_.get(info.data, 'isDraft', true))
    setValuePv('claimType', _.get(info.data, 'draftData.claimType', '1'))
    setValuePv('defectDate', _.get(info.data, 'draftData.defectDate', null))
    setValuePv('description', info.data.description)
    setValuePv('typeProjectName', _.get(info.data, 'draftData.typeProjectName'))
    //setValue('title', info.data.title)
    setValuePv('customerAddress', info.data.customerAddress)
    setValuePv('installationDate', _.get(info.data, 'draftData.installationDate', null))
    setValuePv('projectPermissionDate', _.get(info.data, 'draftData.projectPermissionDate', null))
    setValuePv('systemSize', _.get(info.data, 'draftData.systemSize', ''))
    setValuePv('productName', _.get(info.data, 'draftData.productName', ''))
    setValuePv('powerClass', _.get(info.data, 'draftData.powerClass', ''))
    setValuePv('systemSize', _.get(info.data, 'draftData.systemSize', ''))
    setValuePv('permissionDate', _.get(info.data, 'draftData.permissionDate', null))
    setValuePv('chosePermissionDate', _.get(info.data, 'draftData.chosePermissionDate', '1'))
    setValuePv('choseAffectedSerialNumber', _.get(info.data, 'draftData.choseAffectedSerialNumber', '1'))
    setValuePv('serials', _.get(info.data, 'draftData.serials', ''))

    setValuePv('regionPv', _.get(info.data, 'draftData.region', ''))
    setValuePv('systemTypePv', _.get(info.data, 'draftData.systemTypePv', 'select'))
    setValuePv('choseWebAddress', _.get(info.data, 'draftData.choseWebAddress', ''))
    setValuePv('webAddress', _.get(info.data, 'draftData.webAddress', ''))
    setValuePv('emailPurchase', _.get(info.data, 'draftData.emailPurchase', ''))
    setValuePv('phoneNumber', _.get(info.data, 'draftData.phoneNumber', ''))
    setValuePv('selectedContactNumber', _.get(info.data, 'draftData.selectedContactNumber', '1'))
    setValuePv('qCellSales', _.get(info.data, 'draftData.qCellSales', ''))
    setValuePv('poNumber', _.get(info.data, 'draftData.poNumber', ''))
    setValuePv('purchaseDate', _.get(info.data, 'draftData.purchaseDate', null))
    setValuePv('chooseRefund', _.get(info.data, 'draftData.chooseRefund', '1'))
    setValuePv('chooseBankAccInfo', _.get(info.data, 'draftData.chooseBankAccInfo', '1'))


    //manual
    setValuePv('projectNameSys', _.get(info.data, 'draftData.projectNameSys', ''))
    setValuePv('projectAddress', _.get(info.data, 'draftData.projectAddress', ''))
    setValuePv('projectAddressManual', _.get(info.data, 'draftData.projectAddressManual', null))
    setValuePv('projectInstallationDate', _.get(info.data, 'draftData.projectInstallationDate', null))
    setValuePv('projectPermissionDate', _.get(info.data, 'draftData.projectPermissionDate', null))
    setValuePv('projectSystemSize', _.get(info.data, 'draftData.projectSystemSize', ''))
    setValuePv('projectProductName', _.get(info.data, 'draftData.projectProductName', 'select'))
    setValuePv('projectPowerClass', _.get(info.data, 'draftData.projectPowerClass', 'select'))

    if (_.trim(_.get(info.data, 'draftData.projectInfo.proofOfOperateDate'))) {
      setFilesProof(_.map(_.split(_.get(info.data, 'draftData.projectInfo.proofOfOperateDate'), '|'), p => ({ url: p })));
    }else {
      setFilesProof([]);
    }
    setValuePv('pictureOfModuleLabel', _.get(info.data, 'draftData.pictureOfModuleLabel', ''))
    setValuePv('pictureOfTheFrontSize', _.get(info.data, 'draftData.pictureOfTheFrontSize', ''))
    setValuePv('pictureOfTheBackSize', _.get(info.data, 'draftData.pictureOfTheBackSize', ''))
    setValuePv('pictureOfVocReading', _.get(info.data, 'draftData.pictureOfVocReading', ''))
    setValuePv('closeUpPictureOfPhysicalDefect', _.get(info.data, 'draftData.closeUpPictureOfPhysicalDefect', ''))
    setValuePv('siteModuleLayoutPlan', _.get(info.data, 'draftData.siteModuleLayoutPlan', ''))
    setValuePv('pictureOfOverallRoofArea', _.get(info.data, 'draftData.pictureOfOverallRoofArea', ''))
    setValuePv('pictureOfWireManagement', _.get(info.data, 'draftData.pictureOfWireManagement', ''))
    setValuePv('proofOfDeliveryDocument', _.get(info.data, 'draftData.proofOfDeliveryDocument', ''))
    setValuePv('pictureOfModuleImmediately', _.get(info.data, 'draftData.pictureOfModuleImmediately', ''))
    setValuePv('coppyOfInvoice', _.get(info.data, 'draftData.coppyOfInvoice', ''))
    setValuePv('proofOfPermissionToOperateDate', _.get(info.data, 'draftData.proofOfPermissionToOperateDate', ''))
    setValuePv('invoiceForLaborReimbursement', _.get(info.data, 'draftData.invoiceForLaborReimbursement', ''))
    setValuePv('infoBank', _.get(info.data, 'draftData.infoBank', ''))

    setValuePv('purchasingChannel', _.get(info.data, 'draftData.purchasingChannel'));
    if (_.get(info.data, 'draftData.purchasingChannel') === 'distributor') {
      setValuePv('nameOfPurchasingChannelDistributor', _.get(info.data, 'draftData.nameOfPurchasingChannelDistributor'))
    } else if (_.get(info.data, 'draftData.purchasingChannel') === 'other') {
      setValuePv('nameOfPurchasingChannelOther', _.get(info.data , 'draftData.nameOfPurchasingChannelOther'));
    }

    //setValue('nameOfPurchasingChannelOther', info.data.nameOfPurchasingChannel);
    //setValuePv('nameOfPurchasingChannel', _.get(info.data, 'draftData.nameOfPurchasingChannel', ''))
    //setValuePv('purchasingChannelContactName', _.get(info.data, 'draftData.purchasingChannelContactName', ''))
    setValuePv('emailPurchase', _.get(info.data, 'draftData.emailPurchase', ''))
    setValuePv('contactNumber', _.get(info.data, 'draftData.contactNumber', ''))
    setValuePv('qCellSales', _.get(info.data, 'draftData.qCellSales', ''))
    setValuePv('poNumber', _.get(info.data, 'draftData.poNumber', ''))
    setValuePv('purchaseDate', _.get(info.data, 'draftData.purchaseDate', null))
    setValuePv('typeOfPurchasingChannel', _.get(info.data, 'draftData.typeOfPurchasingChannel', ''))

    //!info.data.isDraft && setCurrentStep( 3);
    //setIsDraft(info.data.isDraft)

    const infoImage = suggestionsImagePv(_.get(info.data, 'draftData.attachments'))

    setFileProofOfPermission(infoImage?.proofPermission || [])
    setFilePictureOfAffectedModule(infoImage?.affectModule || [])
    setFilePictureOfBack(infoImage?.backOfModule || [])
    setFilePictureOfFront(infoImage?.fontOfModule || [])
    setFilePictureOfVoc(infoImage?.vocReading || [])
    setFilePictureCloseUp(infoImage?.physicalDefect || [])
    setFilePictureSitePlan(infoImage?.sitePlan || [])
    setFilePictureOfOverall(infoImage?.overRall || [])
    setFilePictureOfWireManagement(infoImage?.wireManagement || [])
    setFilePictureProofDelivery(infoImage?.proofDelivery || [])
    setFilePictureImmediately(infoImage?.immedDiately || [])
    setFilePictureOfVmpp(infoImage?.offVmpp || [])
    setFilePictureIr(infoImage?.irPicture || [])
    setFilePictureInVoice(infoImage?.coppyReceipt || [])
    setFileInvoiceForLaborReimbursement(infoImage?.laborCost || [])
    setFileBankAccountInfo(infoImage?.bankAccount || [])
    setDetailClaim(info.data)

    trigger(keyPv);
  }

  useEffect(() => {
    setValuePv('installerCity', _.get(user, 'infoUser.city'))
    setValuePv('installerAddress1', _.get(user, 'infoUser.address'))
    setValuePv('installerAddress1', _.get(user, 'infoUser.address'))
    setValuePv('companyName', _.get(user, 'infoUser.companyName'))
    setValuePv('installerEmail', _.get(user, 'infoUser.email'))
    setValuePv('installerName', _.get(user, 'infoUser.fullName'))
    setValuePv('installerState', _.get(user, 'infoUser.state'))
    setValuePv('installerZipcode', _.get(user, 'infoUser.zipCode'))
    setValuePv('installerAddress2', _.get(user, 'infoUser.addressCont'))
    setValuePv('installerPhone', _.get(user, 'infoUser.officeNumber') || _.get(user, 'infoUser.mobileNumber') )
  }, [_.get(user, 'infoUser.city')])


  async function handleUpdateNote() {
    console.log('test');
  }

  function renderInfoClaim() {
    if(isDraft) {
      return (
        <div className={classes.draftInfo}>
          <div className={classes.leftDraft}>
            <img style={{marginRight: 8, height : 20}} src={PopOver}/> <em>This is a draft that needs to be completed</em>
          </div>
          <div className={classes.rightDraft}>
            <CustomButton style={{height : 35}} onClick={() => {
              history.push({
                pathname : `/claim/edit/1/1/${id}`,
                state : {
                  isPickClaimType : true,
                  type : '1'
                }
              })
            }}>
              Continue Submission
            </CustomButton>
          </div>
        </div>
      )
    }else {
      return (
        <>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent} style={{borderTop: '1px solid rgba(0, 0, 0, .1)'}}>
              <h6>Claim Number</h6>
              <p>{claimLabel}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Qualified for labor warranty</h6>
              <p>{_.get(data, 'isDraft', false) ? '----' : renderLaborStatus(_.get(detailClaim, 'draftData.laborReimbursement', false), _.get(detailClaim, 'draftData.laborWarranty', false), _.get(detailClaim, 'draftData.choseTypeProject', '1') , _.get(detailClaim, 'draftData.chooseRefund', '1'), _.get(detailClaim, 'draftData.panelQualified', false))}</p>
              {/* <p>{data.qualifyLaborWarranty ? 'Yes' : 'No'}</p> */}
            </div>
          </div>
          <div className={classes.reviewSectionContainer} style={{marginBottom : 20}}>
            <div className={classes.reviewSectionContent}>
              <h6>Submitted By</h6>
              <p>{decodeStringHtml(getValues('installerName'))}</p>
            </div>
          </div>
        </>
      )
    }
  }
  return (
    <div className={classes.bgDetail}>
      <BannerDetail />
      {loadingDetail ? <div className={classes.loadingEdit}>
        <CustomLoading size={40} color="#00adef"/>
      </div> :
      <div className={classes.reviewDetail}>
        <div className={classes.reviewSectionsContainer}>
          <div className={classes.headerClaim}>
            <div className={classes.leftClaim}>
              <div className={classes.claimInfo}>Claim Details</div>
              <div className={classes.titleClaim}>{!errors?.projectNameSys ? getValues('projectNameSys') : <span style={{color: '#EB5757'}}>Required field missing</span> }</div>
              <div className={classes.statusClaim}>Status:&nbsp;
                <span style={{marginRight: 6, color: [5, 11, 6, 7 , 9].includes(data.statusId) ? '#009EE3' : [2,10].includes(data.statusId) ? '#EB5757' : '#000'}}>{!isDraft ? renderStatusName(data.statusId) : 'Draft' }</span>
                {!isDraft && <PopoverDetail />}
              </div>
            </div>

            <div className={classes.rightClaim}>
              <div className={classes.textTypeClaim}>
                Claim Type : <em className={classes.typeclaimInfo}>Panel</em>
              </div>
              <div className={classes.submited}>
                Submission Date : &nbsp;{data.created ? <em style={{color : '#009EE3'}}><Moment format="L">{data.created}</Moment></em> : '---'}
              </div>
            </div>
          </div>
          {renderInfoClaim()}
          {!isDraft && data.statusId === 2 && <FormUpDateInfoClaim text={text} keyUpload={keyUpload} setKeyUpload={setKeyUpload} id={id}/>}

          <div className={classes.reviewSectionsContainer}>
            <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
              <h5>User Info</h5>
              {isDraft && 
              <Button 
                className={classes.editBtn} 
                onClick={() => history.push({
                  pathname: `/claim/edit/1/1/${id}`,
                  state: {
                    isPickClaimType: true
                  }
                })}
              >
                <span>Edit</span>
                <img src={IconEdit} title='Edit' />
              </Button>}
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Company Name</h6>
                <p>{decodeStringHtml(getValues('companyName'))}</p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Company Address</h6>
                <p>
                  {renderAddress([
                    data.installerAddress1,
                    data.installerAddress2,
                    data.installerCity,
                    data.installerState,
                    data.installerZipcode
                  ])}
                </p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div
                style={{
                  paddingTop: '13px',
                  paddingBottom: '13px'
                }}
                className={classes.reviewSectionContent}
              >
                <h6>User Name</h6>
                <p>{data.installerName}</p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>E-mail </h6>
                <p>
                  {data.installerEmail}
                </p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Phone Number</h6>
                <p>
                  {data.installerPhone}
                </p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Contact E-Mail</h6>
                <p>
                  {data.submitterEmail || data.installerEmail}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.reviewSectionsContainer}>
          <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
            <h5>Issue</h5>
            {isDraft && 
            <Button 
              className={classes.editBtn} 
              onClick={() => history.push({
                pathname: `/claim/edit/1/1/${id}`,
                state: {
                  isPickClaimType: false
                }
              })}
            >
              <span>Edit</span>
              <img src={IconEdit} title='Edit' />
            </Button>}
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Claim Type</h6>
              <p>{getValues('claimType') !== 'select' ? renderClaimType(getValues('claimType')) : <Error/>}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Defect Discovery Date</h6>
              {data.defectDate && <Moment format="L">{data.defectDate}</Moment>}
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Description of Your Issue</h6>
              <p>{!errors.description ? getValues('description') : <Error/>}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Project name</h6>
              <p>{!errors?.projectNameSys ? getValues('projectNameSys') : <Error/>}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Address</h6>
              <p>{(!errors?.projectAddress && !errors.projectAddressManual) ? renderAddressProjectInfo() : <Error/>}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Installation Date</h6>
              <p>
                {!errors?.projectInstallationDate ? data.projectInstallationDate && <Moment format="L">{data.projectInstallationDate}</Moment> : <Error/>}
              </p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Permission-to-Operate Date</h6>
              <p>
                {!errors?.projectPermissionDate ? data.projectPermissionDate && <Moment format="L">{data.projectPermissionDate}</Moment> : <Error/>}
              </p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>System Size (kW)</h6>
              <p>{data.systemSize}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Product Name</h6>
              <p>{!errors?.projectProductName ? getValues('projectProductName') : <Error/>}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Power Class of Module</h6>
              <p>{!errors?.projectPowerClass ? getValues('projectPowerClass') : <Error/>}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Proof of Permission-to-Operate Date</h6>
              <div className={classes.reviewSectionContentImage}>
                <div className={classes.images}>
                  { urlImageProof?.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className={classes.images}>
                  { fileProofOfPermission.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/*<p>{getValues('other_evidence')}</p>*/}
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Affected Module Serial Number(s)</h6>
              <p>{(!errors?.serialNumbers && !errors.isUploadSerialNumber) ? renderSerialNumber() : <Error/>}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Picture of Affected Module Label(s)</h6>
              <div className={classes.reviewSectionContentImage}>
                <div className={classes.images}>
                  {errors.pictureOfModuleLabel && <Error/>}
                  { filePictureOfAffectedModule.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Region</h6>
              <p>{!errors?.regionPv ? renderRegion(getValues('regionPv')) : <Error/>}</p>
            </div>
          </div>
        </div>
        <div className={classes.reviewSectionsContainer}>
          <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
            <h5>Evidence</h5>
            {isDraft && 
            <Button 
              className={classes.editBtn} 
              onClick={() => history.push({
                pathname: `/claim/edit/1/2/${id}`,
                state: {
                  isPickClaimType: false
                }
              })}
            >
              <span>Edit</span>
              <img src={IconEdit} title='Edit' />
            </Button>}
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Picture of Front of Module(s)</h6>
              <div className={classes.reviewSectionContentImage}>
                <div className={classes.images}>
                  {errors?.pictureOfTheFrontSize && <Error/>}
                  { filePictureOfFront.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Picture of Back of Module(s)</h6>
              <div className={classes.reviewSectionContentImage}>
                <div className={classes.images}>
                  {errors?.pictureOfTheBackSize && <Error/>}
                  { filePictureOfBack.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/*<p>{getValues('other_evidence')}</p>*/}
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div
              style={{
                paddingTop: '13px',
                paddingBottom: '13px'
              }}
              className={classes.reviewSectionContent}
            >
              <h6>Picture of Voc Reading</h6>
              <div className={classes.reviewSectionContentImage}>
                <div className={classes.images}>
                  {errors.pictureOfVocReading && <Error/>}
                  { filePictureOfVoc.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Close Up Picture of Physical Defect</h6>
              <div className={classes.reviewSectionContentImage}>
                <div className={classes.images}>
                  {errors?.closeUpPictureOfPhysicalDefect && <Error/>}
                  { filePictureCloseUp.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/*<p>{getValues('other_evidence')}</p>*/}
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Site Module Layout Plan</h6>
              <div className={classes.reviewSectionContentImage}>
                <div className={classes.images}>
                  {errors?.siteModuleLayoutPlan && <Error/>}
                  { filePictureSitePlan.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Picture of Overall Roof Area</h6>
              <div className={classes.reviewSectionContentImage}>
                <div className={classes.images}>
                  {errors.pictureOfOverallRoofArea && <Error/>}
                  { filePictureOfOverall.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Picture of Wire Management</h6>
              <div className={classes.reviewSectionContentImage}>
                <div className={classes.images}>
                  {errors.pictureOfWireManagement && <Error/>}
                  { filePictureOfWireManagement.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Proof-of-Delivery Document</h6>
              <div className={classes.reviewSectionContentImage}>
                <div className={classes.images}>
                  {errors.proofOfDeliveryDocument && <Error/>}
                  { filePictureProofDelivery.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/*<p>{getValues('other_evidence')}</p>*/}
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Picture of Module Immediately Upon Receipt</h6>
              <div className={classes.reviewSectionContentImage}>
                <div className={classes.images}>
                  {errors.pictureOfModuleImmediately && <Error/>}
                  { filePictureImmediately.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/*<p>{getValues('other_evidence')}</p>*/}
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Inverter Type</h6>
              <p>{!errors?.systemTypePv  ? renderInventerType(getValues('systemTypePv')) : <Error/>}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Picture of Vmpp</h6>
              <div className={classes.reviewSectionContentImage}>
                <div className={classes.images}>
                  { filePictureOfVmpp.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/*<p>{getValues('other_evidence')}</p>*/}
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>IR Picture</h6>
              <div className={classes.reviewSectionContentImage}>
                <div className={classes.images}>
                  { filePictureIr.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/*<p>{getValues('other_evidence')}</p>*/}
            </div>
          </div>
        </div>
        <div className={classes.reviewSectionsContainer}>
          <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
            <h5>Purchase Info</h5>
            {isDraft && 
            <Button 
              className={classes.editBtn} 
              onClick={() => history.push({
                pathname: `/claim/edit/1/3/${id}`,
                state: {
                  isPickClaimType: false
                }
              })}
            >
              <span>Edit</span>
              <img src={IconEdit} title='Edit' />
            </Button>}
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Name of Purchasing Channel</h6>
              <p>{!errors?.purchasingChannel ? renderPurchasingChannel(data?.purchasingChannel) : <Error/>}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Purchasing Channel Contact Name</h6>
              <p>{(!errors?.nameOfPurchasingChannelOther && !errors?.nameOfPurchasingChannelDistributor && !errors?.nameOfPurchasingChannelSelect) ? renderNameOfPurchasingChannel() : <Error/>}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>E-mail</h6>
              <p>{!errors?.emailPurchase ? getValues('emailPurchase') : <Error/>}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Contact Number</h6>
              <p>{getValues('phoneNumber')}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Q CELLS Sales Manager</h6>
              <p>{getValues('qCellSales')}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>PO Number</h6>
              <p>{!errors.poNumber ? getValues('poNumber') : <Error/>}</p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Purchase Date</h6>
              <p>
                {data.purchaseDate && <Moment format="L">{data.purchaseDate}</Moment>}
              </p>
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Copy of Invoice/Receipt</h6>
              <div className={classes.reviewSectionContentImage}>
                <div className={classes.images}>
                  {errors.coppyOfInvoice && <Error/>}
                  { filePictureInVoice.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.reviewSectionsContainer} style={{ marginBottom: '92px' }}>
          <div className={classNames([classes.reviewSectionContainer], [classes.reviewTitleSection])}>
            <h5>Labor Reimbursement</h5>
            {isDraft && 
            <Button 
              className={classes.editBtn} 
              onClick={() => history.push({
                pathname: `/claim/edit/1/3/${id}`,
                state: {
                  isPickClaimType: false
                }
              })}
            >
              <span>Edit</span>
              <img src={IconEdit} title='Edit' />
            </Button>}
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Invoice for Labor Cost Reimbursement</h6>
              <div className={classes.reviewSectionContentImage}>
                <div className={classes.images}>
                  {errors.invoiceForLaborReimbursement && <Error/>}
                  {!errors.invoiceForLaborReimbursement && fileInvoiceForLaborReimbursement.length === 0 ? 'N/A' : ''}
                  { fileInvoiceForLaborReimbursement.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <p>{getValues('poNumber')}</p> */}
            </div>
          </div>
          <div className={classes.reviewSectionContainer}>
            <div className={classes.reviewSectionContent}>
              <h6>Bank Account Information</h6>
              <div className={classes.reviewSectionContentImage}>
                <div className={classes.images}>
                  {errors.infoBank && <Error/>}
                  {!errors.infoBank && fileBankAccountInfo.length === 0 ? 'N/A' : ''}
                  { fileBankAccountInfo.map(p => (
                    <div key={p.url}>
                      <img src={p.url} className={classes.image} />
                      <div className={classes.overlay} role="overlay">
                        <img
                          src={OpenImageIcon}
                          onClick={() => {
                            setModalUrl(p.url);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>*/}
        {/*  <CustomButton color="secondary" className="saveDraftBtn" onClick={handleBackReview}>Back</CustomButton>*/}
        {/*</Box>*/}
        <ImageModal
          open={openModal}
          url={modalUrl}
          name={modalUrl ? modalUrl.substring(modalUrl.lastIndexOf('/') + 1) : ''}
          handleClose={() => {
            setOpenModal(false);
            setModalUrl('');
          }}
        />
      </div>}
    </div>

  );
}

export default ClaimDetailPanel;
