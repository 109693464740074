import React from 'react';
import './index.css';

function CardHomePage({title, url, subTitle, contentTitle}) {
  return(
    <div className="list-card">
      <div className="front">
        <p className="img">
          <img src={url} alt={'logo'}/>
        </p>
      </div>
      <div>
        <p className='des title'>{title}</p>
        <p className="des">
          {contentTitle}
        </p>
      </div>
      <div className="over">
        <div className="txt">
          <p style={{fontSize : 18, fontWeight : 600}}>{subTitle}</p>
          <hr className={'hr'}/>
        </div>
      </div>
    </div>
  )
}

export default CardHomePage;
