export const defaultStateEss = {

  infoClaimName : 'select',
  type: 'select',

  description : '',
  error_log : null,
  errorLogName : '',
  other_evidence : null,

  projectSys : 'select',
  choseTypeProject : '1',

  project : '',
  projectNameSys : '',
  projectAddress : '',
  laborReimbursement : false,
  systemType : '',
  systemSize : 0,
  projectInstallationDate : null,
  projectPermissionDate : null,
  projectSystemType : 'select',
  projectPowerClass : 'select',
  projectProductName : 'select',
  projectNumberOfPanel : '',
  projectInverterSerialNumber : '',
  projectInverterPower : 'select',
  nameProject : '',
  permissionOfDate : '',


  projectAddressManual : null,
  projectAddress2manual : '',
  projectStateManual : '',
  projectCityManual : '',
  projectZipCodeManual : '',
  chooseBankAccInfo : '1',
  showMess : false,
  laborWarranty : false,
  panelLaborReimbursement : false,
  batteryLaborReimbursement : false,
  isInValidUpload : false,
  isDuplicateUpload : false,
  isDuplicateOtherProject : false,



  pocketWifiSys : '',
  batterySerialNumber : '',
  batteryType : 'select',
  numberOfBattery : 'select',

  dspMaster : '',
  armVersion : '',
  batteryMaster : '',

  pv1 : '',
  pv2 : '',
  pv3 : '',
  pv4 : '',

  ac1 : '',
  ac2 : '',
  criticalLoadsConnected : '',
  totalPvPowerInstalled : '',
  operationalModeUse : 'select',

  purchasingChannel : 'select',
  nameOfPurchasingChannelQcells : 'Q CELLS',
  nameOfPurchasingChannelDistributor : null,
  nameOfChanelOtherDistributor : '',
  nameOfPurchasingChannelOther : '',
  nameOfPurchasingChannelSelect : '',
  poNumber : '',
  purchaseDate : null,
  invoiceForLaborCost : '',
  infoBank : '',
  chooseRefund : '1',
  chooseBank : '2',
  // addressSys : '',
  // installationDateSys : null,
  // productNameSys : '',
  // systemTypeSys : 'select',
  inverterSerialNumberSys : '',
  inverterPowerSys : '',


  // numberOfBattery : '',

  dsp1SwVersion : '',
  armSwVersion : '',
  batterySwVersion : '',


  loadL1Voltage : '',
  loadL2Voltage : '',
  criticalConnected : '',


  distributorName : '',
  distributorCity : '',
  projectInfo : null,
  choseTypeSerialUpload : '2',
  serialNumbers : [],
  listSerialPick : [],
  isUploadSerialNumber : '',
  batteryQualified : false,
  validateProofOfDate : false,
  isUploadProofDate : ''

};
