import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../common/components/button';
import BackButton from '../../../common/components/button/BackButton';
import Text from '../../../common/components/text';
import ProgressBar from './components/ProgressBar';
import CartProduct from './components/CartProduct';
import Delivery from './components/Delivery';
import EmptyCart from './emptyCart';
import { useAuth } from '../../../contexts/auth';
import { useShoppingCart } from '../../../contexts/shoppingCart';
import useSendOrder from '../../../hooks/api/useSendOrder';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { notiFailed, notiSuccess } from './../../../utils/helpers.js';
import CustomLoading from './../../../common/components/CustomLoading';
import useNavigation from '../../../hooks/useNavigation';
import { pubSpotService } from '../../../services';
import ContactFormHubSpot from '../../../common/components/ContactFormHubSpot';
import OrderSummary from './components/OrderSummary';

const PageContainer = styled.div`
  ${({ theme }) => `
    width: 90%;
    margin: auto;
    padding-top: 1rem; 


  ${theme.breakpoints.up('md')} {
    width: 80%;  
    margin-bottom: 160px;
  }
  `};
`;

const Container = styled.div`

  @media (max-width: 1400px) {
    flex-direction: column !important;
  }
  ${({ theme }) =>
  `display: flex;
    flex-direction: column;
    margin-top: 3rem;
      
  ${theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-between;
  }`}
  
  
`;

const CartContainer = styled.div`
  @media (max-width : 1400px) {
      width: 100% !important;
  }
  
  
  ${({ theme }) => `
  ${theme.breakpoints.up('lg')} {
    width: 65%;
  }
  `}
`;

const Order = props => {
  const user = useAuth();
  const { qpoints, set_user_qpoint } = useAuth();
  const {
    status,
    items,
    statusCard,
    order: { total, shipping },
    updateCartProduct,
    removeCartProduct,
    countProductInAura,
    isUpdatingQuantityCartItem,
    listCartIdThatQuantityGreaterThanStock,
    set_listCartIdThatQuantityGreaterThanStock,
    companyRewards
  } = useShoppingCart();

  const { goToProduct } = useNavigation();
  const { orderStatus, response, sendOrder, addAddress } = useSendOrder();

  const [progress, setProgress] = useState(0);
  const [infoBranch, setInfoBranch] = useState({});
  const [infoDiffAddress, setInfoDiffAddress] = useState(false);
  const [infoRemark, setInfoRemark] = useState({});

  const goBack = () =>
    progress === 0 ? props.history.goBack() : setProgress(prev => prev - 1);

  const submitOrder = async (branch, diffAddress, remark) => {
    await sendOrder({
      products: items,
      shippingInformation: remark,
      type: 'qpoints',
      branchId: _.toString(branch.id),
      shippingTotal: shipping
    });
    if (diffAddress === true && !_.isEmpty(remark)) {
      await addAddress({
        companyId: _.get(branch, 'companyId'),
        primary: true,
        salesRegionId: 61, // temporary harcoded
        address: remark
      });
    }
  };

  const resetCart = useCallback(async () => {
    items &&
    (await Promise.all(items.map(async item => await removeCartProduct(item))));
  }, [items, removeCartProduct]);

  useEffect(() => {
    if (orderStatus === 'submitted') {
      set_user_qpoint(qpoints - _.get(response, 'total', 0));
      resetCart();

      props.history.push({
        pathname: `/checkout/confirmation/${response.id}`
      });
    }
  }, [
    items,
    orderStatus,
    response,
    resetCart,
    props.history,
    qpoints,
    set_user_qpoint
  ]);

  useEffect(() => {
    let listCartIdThatQuantityGreaterThanStock3 = items
      .filter(o => {
        return (
          _.get(o, 'quantity') > _.get(o, 'variant.stock') &&
          _.get(o, 'variant.unlimited') !== true
        );
      })
      .map(i => i.id);
    set_listCartIdThatQuantityGreaterThanStock(
      listCartIdThatQuantityGreaterThanStock3
    );
  }, [items, set_listCartIdThatQuantityGreaterThanStock]);

  useEffect(() => {
    let remainQpoints = companyRewards.availableQpoints - total - shipping - companyRewards.holdQpoints;
    if (remainQpoints < 0) {
      notiFailed('Your total due exceeds total points!', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }, [user, total, shipping]);

  useEffect(() => {
    if (!statusCard && items.length > 0) {
      notiFailed('*Aurora Solar products must be purchased individually and cannot be added to the cart with other products. Please proceed as separate transactions.');
    }
  }, [statusCard]);


  function handleNextProgess(branch, diffAddress, remark) {
    setInfoBranch(branch);
    setInfoDiffAddress(diffAddress);
    setInfoRemark(remark);
    setProgress(pre => pre + 1);
  }

  async function submitOrderAurora(payload) {
    const res = await sendOrder({
      products: items,
      shippingInformation: infoRemark,
      type: 'qpoints',
      branchId: _.toString(infoBranch.id),
      shippingTotal: shipping
    });

    const makePayload = {
      ...payload,
      coupon_code: res.products[0]?.couponCode.code,
      orderId: res.id
    };

    await pubSpotService.sendInfoProductAurora(makePayload);

    if (infoDiffAddress === true && !_.isEmpty(infoRemark)) {
      await addAddress({
        companyId: _.get(infoBranch, 'companyId'),
        primary: true,
        salesRegionId: 61, // temporary harcoded
        address: infoRemark
      });
    }
  }


  function renderUiProductDefault(total, step) {

    if (step === 1) {
      return (
        <Delivery step={progress} nextProgess={handleNextProgess} submitOrder={submitOrder}
                  countProductInAura={countProductInAura}/>
      );
    }
  }

  function renderUiProductAurora(total, step) {
    if (total === 1 && step === 2) {
      return (
        <ContactFormHubSpot submitOrder={submitOrderAurora}/>
      );
    }
  }

  function renderCartDefault(step) {
    if (step === 0) {
      return (
        <>
          <CartContainer>
            <Text as="h3" weight="medium" style={{ marginBottom: '1rem' }}>
              Cart
            </Text>
            <Divider
              style={{ backgroundColor: 'var(--dark-color, #000)' }}
            />
            <List style={{ marginBottom: '1rem' }}>
              {items.map((item, index) => (
                <ListItem
                  key={item.name + '_' + index}
                  component={() => (
                    <>
                      <CartProduct
                        product={item}
                        onUpdate={updateCartProduct}
                        onRemove={removeCartProduct}
                        balance={
                          companyRewards.availableQpoints -
                          total -
                          shipping
                        }
                      />
                      <Divider/>
                    </>
                  )}
                />
              ))}
            </List>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {!statusCard &&
              <em style={{ color: '#EB5757' }}>*Aurora Solar products must be purchased individually and cannot be added to
                the cart with other products. Please proceed as separate transactions.
              </em>}

              {
                statusCard && companyRewards.availableQpoints - total - shipping - companyRewards.holdQpoints <= 0 && companyRewards.holdQpoints > 0 &&
                <em style={{ color: '#EB5757' }}>
                  You are unable to make transaction at this time for points above {Number(companyRewards.availableQpoints - companyRewards.holdQpoints).toLocaleString()} Q.
                  Please contact us for assistance at q.partner@qcells.com.
                </em>
              }
              <Button
                disabled={
                  companyRewards.availableQpoints - total - shipping - companyRewards.holdQpoints <= 0 && companyRewards.holdQpoints > 0 || !statusCard
                }
                className={
                  companyRewards.availableQpoints - total - shipping - companyRewards.holdQpoints <= 0 && companyRewards.holdQpoints > 0 ||
                  isUpdatingQuantityCartItem || !statusCard
                    ? 'disabledconfirm btn_confirm_address'
                    : 'btn_confirm_address'
                }
                style={{ margin: '1rem 0', width: 200 }}
                onClick={() => {
                  if (!_.isEmpty(listCartIdThatQuantityGreaterThanStock)) {
                    notiFailed(
                      'One or more cart item have quantity greater than stock!'
                    );
                    return;
                  }
                  // return;
                  setProgress(prev => prev + 1);
                }}
              >
                Confirm Address
              </Button>
            </div>
          </CartContainer>
        </>
      );
    }
  }


  return (
    status === 'loaded' ? (
      <>
        <div style={{ width: '100%', overflowX: 'hidden' }}>
          {items.length > 0 ? (
            <PageContainer>
              <BackButton onClick={goBack}>Back</BackButton>
              <ProgressBar currentStep={progress} countProductInAura={countProductInAura}/>
              <Container>
                {renderCartDefault(progress)}
                {renderUiProductDefault(countProductInAura, progress)}
                {renderUiProductAurora(countProductInAura, progress)}
                <OrderSummary
                  submittedorder={false}
                  available={companyRewards.availableQpoints}
                  total={total}
                  statusAurora={countProductInAura}
                  shipping={shipping}
                  fullyRendered
                />
              </Container>
            </PageContainer>
          ) : (
            <EmptyCart onClick={product => {
              goToProduct(product.productId);
            }}/>
          )}
        </div>
      </>
    ) : (
      <div className="wr_loading">
        <CustomLoading/>
      </div>
    )
  );
};

export default withRouter(Order);
