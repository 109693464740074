import React from 'react';
import {
  Box,
} from '@material-ui/core';
import classNames from 'classnames';
import CustomButton from '../../../common/components/button';
import useStyles from './useStyles';
import { Controller } from 'react-hook-form';
import ImageUploader from './UploadImage';
import CustomLoading from '../../../common/components/CustomLoading';
import IconSelect from '../../../icons/arrow.svg';
import IconMatch from '../../../icons/match.svg';
import CheckValidate from '../../../common/components/CheckValidate';
import Popover from '../../../common/components/Popover';
import PictureOfTheFrontSize from '../../../icons/pictureOfTheFrontSize.png';
import PictureOfTheBackSize from '../../../icons/pictureOfTheBackSize.png';
import ShowMessageLine from './ShowMessageLine';
import ShowMessage from './ShowMessage';
import MultiUploader from "./UploadMultiType";

const optionPV = [
  { name: 'Please select', value: 'select' },
  { name: 'Power loss/under performing module', value: 1 },
  { name: 'Hot spot', value: 2 },
  { name: 'Broken glass', value: 3 },
  { name: 'Connector damage', value: 4 },
  { name: 'Detached cable/junction box damage', value: 5 },
  { name: 'Detached frame', value: 6 },
  { name: 'Other', value: 7 }
];

const ReactSelectDropdownIndicator = () => {
  return (
    <div style={{ marginRight: '15px' }}>
      <img src={IconSelect} width="14"/>
    </div>
  )
}


function Evidence(
  {
    next, goBack, register, control, errors, watch, setValue, type,
    clearErrors,
    handleSaveDraft, loading,
    fileProofOfPermission, setFileProofOfPermission,
    filePictureOfVoc, setFilePictureOfVoc,
    filePictureOfFront, setFilePictureOfFront,
    filePictureOfBack, setFilePictureOfBack,
    filePictureCloseUp, setFilePictureCloseUp,
    filePictureSitePlan, setFilePictureSitePlan,
    filePictureOfOverall, setFilePictureOfOverall,
    filePictureOfWireManagement, setFilePictureOfWireManagement,
    filePictureProofDelivery, setFilePictureProofDelivery,
    filePictureImmediately, setFilePictureImmediately,
    filePictureOfVmpp, setFilePictureOfVmpp,
    filePictureIr, setFilePictureIr,
    keyUpload, setKeyUpload
  }
) {
  const classes = useStyles();
  const claimType = watch('claimType');


  return (
    <div>

      <h1 style={{fontWeight: 500, fontSize: 16}}>Photo Attachment of Module</h1>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }} />
      <div className={classes.sectionContainer}>
        <div
          className={classes.sectionHeaderContainer}
          style={{
            alignItems: 'flex-start',
            marginTop: '12px'
          }}
        >
        <span className={classes.sectionTitle}>
          Picture of Front of Module(s)
          { ['1', '2', '3', '4', '6', '7'].includes(claimType) && <span>*</span> } <br />
          <Popover>
            <img src={PictureOfTheFrontSize} style={{height: 200, width: 100}}/>
          </Popover>
        </span>
        </div>
        <div className={classes.wrapUploadEx}>
          <MultiUploader
            files={filePictureOfFront}
            setFiles={setFilePictureOfFront}
            fileName={'picture_of_front_of_module'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            errors={errors.pictureOfTheFrontSize}
            name={'pictureOfTheFrontSize'}
            clearErrors={clearErrors}
          />
        </div>
        <div>
          {
            ['1', '2', '3', '4', '6', '7'].includes(claimType) && <CheckValidate value={watch('pictureOfTheFrontSize')} error={errors.pictureOfTheFrontSize}/>
          }
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div
          className={classes.sectionHeaderContainer}
          style={{
            alignItems: 'flex-start',
            marginTop: '12px'
          }}
        >
        <span className={classes.sectionTitle}>
            Picture of Back of Module(s)
            <span>*</span> <br />
          <Popover>
            <img src={PictureOfTheBackSize}  style={{height: 200, width: 100}} />
          </Popover>
        </span>
        </div>
        <div className={classes.wrapUploadEx}>
          <MultiUploader
            files={filePictureOfBack}
            setFiles={setFilePictureOfBack}
            fileName={'picture_of_back_of_module'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            errors={errors.pictureOfTheBackSize}
            name={'pictureOfTheBackSize'}
            clearErrors={clearErrors}
          />
        </div>
        <div>
          <CheckValidate value={watch('pictureOfTheBackSize')} error={errors.pictureOfTheBackSize}/>
        </div>
      </div>

      <h1 style={{fontWeight: 500, fontSize: 16, paddingTop: '22px'}}>Monitoring System Information</h1>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }} />
      <div className={classes.sectionContainer}>
        <div
          className={classes.sectionHeaderContainer}
          style={{
            alignItems: 'flex-start',
            marginTop: '12px'
          }}
        >
        <span className={classes.sectionTitle}>
          Picture of Voc Reading
          { ['1', '7'].includes(claimType) && <span>*</span> } <br />
        </span>
        </div>
        <div className={classes.wrapUploadEx}>
          <MultiUploader
            files={filePictureOfVoc}
            setFiles={setFilePictureOfVoc}
            fileName={'picture_of_voc_reading'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            errors={errors.pictureOfVocReading}
            name={'pictureOfVocReading'}
            clearErrors={clearErrors}
          />
          <ShowMessageLine 
            content1={'Please provide pictures of voltage (Voc) readings of below using multimeter:'}
            content2={'Each of the affected panels, and'}
            content3={'One of the correctly-functioning neighboring panels'}
          />
        </div>
        <div>
          {
            ['1', '7'].includes(claimType) && <CheckValidate value={watch('pictureOfVocReading')} error={errors.pictureOfVocReading}/>
          }

        </div>
      </div>

      <h1 style={{fontWeight: 500, fontSize: 16, paddingTop: '22px'}}>Photo Attachment of Defect</h1>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }} />
      <div className={classes.sectionContainer}>
        <div
          className={classes.sectionHeaderContainer}
          style={{
            alignItems: 'flex-start',
            marginTop: '12px'
          }}
        >
          <span className={classes.sectionTitle}>
              Close Up Picture of Physical Defect
            { ['2', '3', '4', '6', '7'].includes(claimType) && <span>*</span> } <br />
          </span>
        </div>
        <div className={classes.wrapUploadEx}>
          <MultiUploader
            files={filePictureCloseUp}
            setFiles={setFilePictureCloseUp}
            fileName={'close_up_picture_of_physical_defect'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            errors={errors.closeUpPictureOfPhysicalDefect}
            name={'closeUpPictureOfPhysicalDefect'}
            clearErrors={clearErrors}
          />
          <ShowMessage textInfo={'Please provide a clear zoomed-in picture of the physical defect on the panel(s).'} />
        </div>
        <div>
          {['2', '3', '4', '6', '7'].includes(claimType) &&  <CheckValidate value={watch('closeUpPictureOfPhysicalDefect')} error={errors.closeUpPictureOfPhysicalDefect}/> }
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div
          className={classes.sectionHeaderContainer}
          style={{
            alignItems: 'flex-start',
            marginTop: '12px'
          }}
        >
        <span className={classes.sectionTitle}>
          Site Module Layout Plan
          {['2', '7'].includes(claimType) && <span>*</span> } <br />
        </span>
        </div>
        <div className={classes.wrapUploadEx}>
          <MultiUploader
            files={filePictureSitePlan}
            setFiles={setFilePictureSitePlan}
            fileName={'site_module_layout_plan'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            errors={errors.siteModuleLayoutPlan}
            name={'siteModuleLayoutPlan'}
            clearErrors={clearErrors}
          />
          <ShowMessage textInfo={'Please provide a monitoring system dashboard sitemap where the affected panels are highlighted.'} />
        </div>
        <div>
          {
            ['2', '7'].includes(claimType) && <CheckValidate value={watch('siteModuleLayoutPlan')} error={errors.siteModuleLayoutPlan}/>
          }
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div
          className={classes.sectionHeaderContainer}
          style={{
            alignItems: 'flex-start',
            marginTop: '12px'
          }}
        >
        <span className={classes.sectionTitle}>
            Picture of Overall Roof Area
          {['2'].includes(claimType) && <span>*</span> } <br />
        </span>
        </div>
        <div className={classes.wrapUploadEx}>
          <MultiUploader
            files={filePictureOfOverall}
            setFiles={setFilePictureOfOverall}
            fileName={'picture_of_overall_roof_area'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            errors={errors.pictureOfOverallRoofArea}
            name={'pictureOfOverallRoofArea'}
            clearErrors={clearErrors}
          />
          <ShowMessage textInfo={`Please provide pictures of project owner's roof top, showing all the installed panels.`} />
        </div>
        <div>
          {['2'].includes(claimType) && <CheckValidate value={watch('pictureOfOverallRoofArea')} error={errors.pictureOfOverallRoofArea}/>}
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div
          className={classes.sectionHeaderContainer}
          style={{
            alignItems: 'flex-start',
            marginTop: '12px'
          }}
        >
        <span className={classes.sectionTitle}>
            Picture of Wire Management
          {['4', '7'].includes(claimType) && <span>*</span>} <br />
        </span>
        </div>
        <div className={classes.wrapUploadEx}>
          <MultiUploader
            files={filePictureOfWireManagement}
            setFiles={setFilePictureOfWireManagement}
            fileName={'picture_of_wire_management'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            errors={errors.pictureOfWireManagement}
            name={'pictureOfWireManagement'}
            clearErrors={clearErrors}
          />
          <ShowMessage textInfo={`Please provide pictures that show how the panels are connected to MLPE devices.`} />
        </div>
        <div>
          {
            ['4', '7'].includes(claimType) && <CheckValidate value={watch('pictureOfWireManagement')} error={errors.pictureOfWireManagement}/>
          }
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div
          className={classes.sectionHeaderContainer}
          style={{
            alignItems: 'flex-start',
            marginTop: '12px'
          }}
        >
        <span className={classes.sectionTitle}>
            Proof-of-Delivery Document
          {['5'].includes(claimType) && <span>*</span>} <br />
          {/*<Popover>*/}
          {/*  <p>Please provide a purchase agreement or invoice when the panels were purchased by the project owner.</p>*/}
          {/*</Popover>*/}
        </span>
        </div>
        <div className={classes.wrapUploadEx}>
          <MultiUploader
            files={filePictureProofDelivery}
            setFiles={setFilePictureProofDelivery}
            fileName={'proof_of_delivery_document'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            errors={errors.proofOfDeliveryDocument}
            name={'proofOfDeliveryDocument'}
            clearErrors={clearErrors}
          />
          <ShowMessage textInfo={`Please provide a purchase agreement or invoice when the panels were purchased by the project owner.`} />
        </div>
        <div>
          {['5'].includes(claimType) &&  <CheckValidate value={watch('proofOfDeliveryDocument')} error={errors.proofOfDeliveryDocument}/>}
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div
          className={classes.sectionHeaderContainer}
          style={{
            alignItems: 'flex-start',
            marginTop: '12px'
          }}
        >
        <span className={classes.sectionTitle}>
            Picture of Module Immediately Upon Receipt
          {['5'].includes(claimType) && <span>*</span>} <br />
        </span>
        </div>
        <div className={classes.wrapUploadEx}>
          <MultiUploader
            files={filePictureImmediately}
            setFiles={setFilePictureImmediately}
            fileName={'picture_of_module_immediately_upon_receipt'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            errors={errors.pictureOfModuleImmediately}
            name={'pictureOfModuleImmediately'}
            clearErrors={clearErrors}
          />
          <ShowMessage textInfo={`Please provide pictures of front & back side of panels immediately after they were removed from packaging (and before installation).`} />
        </div>
        <div>
          {
            ['5'].includes(claimType) && <CheckValidate value={watch('pictureOfModuleImmediately')} error={errors.pictureOfModuleImmediately}/>
          }
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div
          className={classes.sectionHeaderContainer}
          style={{
            alignItems: 'flex-start',
            marginTop: '12px'
          }}
        >
        <span className={classes.sectionTitle}>
            Inverter Type
        </span>
        </div>
        <div className={classes.inputFieldMediumContainer}>
          <Controller
            name="systemTypePv"
            control={control}
            as={
              <select
                placeholder="Purchasing Channel"
                className={classNames([classes.selectField], {
                  [classes.errorSelectField]: errors.systemTypePv
                })}
              >
                <option value={'select'}>Please select</option>
                <option value={'1'}>String inverter</option>
                <option value={'2'}>Central inverter</option>
                <option value={'3'}>Optimizer</option>
                <option value={'4'}>Microinverter</option>
              </select>
            }
          />
        </div>
        <CheckValidate value={watch('systemTypePv')} error={errors.systemTypePv}/>
      </div>
      <div className={classes.sectionContainer}>
        <div
          className={classes.sectionHeaderContainer}
          style={{
            alignItems: 'flex-start',
            marginTop: '12px'
          }}
        >
        <span className={classes.sectionTitle}>
            Picture of Vmpp <br />
        </span>
        </div>
        <div className={classes.wrapUploadEx}>
          <MultiUploader
            files={filePictureOfVmpp}
            setFiles={setFilePictureOfVmpp}
            fileName={'picture_of_vmpp'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            errors={errors.pictureOfVmpp}
            name={'pictureOfVmpp'}
            clearErrors={clearErrors}
          />
          <ShowMessageLine 
            content1={'Please provide pictures of power (Vmpp) readings of below using multimeter:'}
            content2={'Each of the affected panels, and'}
            content3={' One of the correctly-functioning neighboring panels'}
          />
        </div>
        <div>
          <CheckValidate value={watch('pictureOfVmpp')} error={errors.pictureOfVmpp}/>
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div
          className={classes.sectionHeaderContainer}
          style={{
            alignItems: 'flex-start',
            marginTop: '12px'
          }}
        >
        <span className={classes.sectionTitle}>
            IR Picture <br />
        </span>
        </div>
        <div className={classes.wrapUploadEx}>
          <MultiUploader
            files={filePictureIr}
            setFiles={setFilePictureIr}
            fileName={'ir_picture'}
            keyUpload={keyUpload}
            setKeyUpload={setKeyUpload}
            errors={errors.irPicture}
            name={'irPicture'}
            clearErrors={clearErrors}
          />
          <ShowMessage textInfo={`Please provide pictures of infrared (IR) of each of the affected panels.`} />
        </div>
        <div>
          <CheckValidate value={watch('irPicture')} error={errors.irPicture}/>
        </div>
    </div>
      <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }}/>
      <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
        <CustomButton color="secondary" className="saveDraftBtn" onClick={goBack}>Back</CustomButton>
        <div>
          <CustomButton onClick={handleSaveDraft} color="secondary" className="saveDraftBtn" disabled={loading}>
            { loading ? <CustomLoading size={16} color="#009EE3" /> : 'Save Draft' }
          </CustomButton>
          <CustomButton onClick={next}>Next</CustomButton>
        </div>
      </Box>
    </div>
  );
}


export default Evidence;
