export const defaultStatePv = {

  //installerName: '',
  installerAddress1 : '',
  installerAddress2 : '',
  installerCity : '',
  installerState : 'select',
  companyName : '',
  //installerContact : '',
  installerZipcode : '',
  //installerEmail : '',
  submitterEmail : '',
  installerPhone : '',
  checkedEmail: false,
  isInValidUpload : false,
  isDuplicateUpload : false,
  isDuplicateOtherProject : false,


  purchasingChannel : 'select',
  nameOfPurchasingChannelQcells : 'Q CELLS',
  nameOfPurchasingChannelDistributor : null,
  nameOfPurchasingChannelOther : '',
  nameOfPurchasingChannelSelect : '',
  nameOfChanelOtherDistributor : '',

  infoClaimName : 'PV',
  type : 'select',
  claimType : 'select',
  choseTypeSerialUpload : '2',
  choseTypeProject : '1',
  projectNameSys : '',
  projectAddress : '',
  systemTypePv : 'select',
  laborReimbursement  : false,
  laborWarranty : false,
  panelLaborReimbursement : false,
  batteryLaborReimbursement : false,
  showMess : false,
  projectAddressManual : null,
  projectAddress2manual : '',
  projectStateManual : '',
  projectCityManual : '',
  projectZipCodeManual : '',
  serialNumbers : [],
  chooseBankAccInfo : '2',
  infoBank : '',
  invoiceForLaborReimbursement : '',
  isUploadSerialNumber : '',
  projectInverterPower : 'select',
  selectedContactNumber : '1',
  phoneNumber : '',
  listSerialPick : [],
  systemSize : 0,
  projectInstallationDate : null,
  projectPermissionDate : null,
  projectSystemType : 'select',
  projectPowerClass : 'select',
  projectProductName : 'select',
  projectNumberOfPanel : '',
  projectInverterSerialNumber : '',
  defectDate : null,
  description : '',
  typeProjectName : '',
  title : '',
  customerAddress : '',
  installationDate : null,
  productName : '',
  powerClass : '',
  permissionDate : null,
  serials: '',
  pictureOfModuleLabel : '',
  projectSystemSize : '',
  region : 'select',
  regionPv : 'select',
  //qualifyLaborWarranty : false,
  regionValue: '',
  webAddress : '',
  proofOfPermissionToOperateDate: '',
  pictureOfTheFrontSize : '',
  pictureOfTheBackSize : '',
  pictureOfVocReading: '',
  closeUpPictureOfPhysicalDefect : '',
  siteModuleLayoutPlan : '',
  pictureOfOverallRoofArea : '',
  pictureOfWireManagement : '',
  proofOfDeliveryDocument : '',
  pictureOfModuleImmediately  : '',
  pictureOfVmpp : '',
  irPicture : '',

  typeOfPurchasingChannel : '',
  nameOfPurchasingChannel : '',
  purchasingChannelContactName : '',
  emailPurchase : '',
  contactNumber : '',
  qCellSales : '',
  poNumber : '',
  purchaseDate : null,
  coppyOfInvoice : '',
  projectInfo : null,
  panelQualified : false,
  validateProofOfDate : false,
  isUploadProofDate : ''
}
