import ReactGA from 'react-ga';
import _ from 'lodash';

const CATEGORY_SHOP = 'Shop';
const CATEGORY_REWARD_HISTORY = 'Rewards History';
const CATEGORY_DASHBOARD = 'Dashboard';
const CATEGORY_ORDER_HISTORY = 'Order History';
const CATEGORY_REWARD_INTRODUCTION = 'Rewards Introduction';
const CATEGORY_REWARD_PRODUCT_REGISTRATION = 'Product Registration';
const CATEGORY_REWARD_FORECAST = 'Forecast';
const CATEGORY_REGISTER = 'Register';
const CATEGORY_PROFILE = 'Profile';
const CATEGORY_SUB_ID = 'Sub Id Management';
const CATEGORY_CONTACT_US = 'Contact Us';
const CATEGORY_DATA_HUB = 'Data Hub';
const CATEGORY_NOTICE = 'Notice';

// Action Dashboard
export const ACTION_VIEW_QPOINT_HISTORY = 'View Q.POINT History';
export const ACTION_REGISTER_PRODUCT = 'Register Products';
export const ACTION_SUBMIT_FORCAST_NEXT_MONTH = 'Submit Next Month Forecast';
export const ACTION_VIEW_CURRENT_MONTH_FORECAST = 'View Current Month Forecast';
export const ACTION_VIEW_ORDER_HISTORY = 'View Order History';
export const ACTION_VIEW_MY_ACCOUNT_PAGE = 'View My Account Page';
export const ACTION_VIEW_SUB_ID_PAGE = 'View Sub ID Management';

// Action Order History
export const ACTION_VIEW_CONTACT_US_PAGE = 'Contact Us';
export const ACTION_VIEW_MORE_ORDER_HISTORY = 'View More';
export const ACTION_VIEW_DETAIL_ORDER = 'View Detail Order';
export const ACTION_CANCEL_ORDER = 'Cancel Order';

// Action Reward History
export const ACTION_DOWNLOAD_REPORT = 'Download Report';
export const ACTION_VIEW_MORE_REWARD_HISTORY = 'View More';

// Action Reward Introduction
export const ACTION_REGISTER_PRODUCTS = 'Register Your Products';
export const ACTION_SUBMIT_FORCAST = 'Submit Your Forecast';

// Action Product Registration
export const ACTION_DOWNLOAD_TEMPLATE = 'Download Template';
export const ACTION_UPLOAD_SERIAL_NUMBER = 'Upload Serial Number';

// Action Forecast
export const ACTION_SUBMIT_FORECAST = 'Submit Forecast';

// Action Shop
export const ACTION_VIEW_MORE_SHOP = 'View More';
export const ACTION_VIEW_PRODUCT_DETAIL = 'View Product Detail';
export const ACTION_ADD_TO_CART = 'Add To Cart';
export const ACTION_CONTACT_US = 'Contact Us';
export const ACTION_BUY_PRODUCT = 'Buy Products';

// Action
export const ACTION_SUBMIT_REGISTER = 'Register New User';

// Profile
export const ACTION_EDIT_PROFILE = 'Edit Profile';

// Sub Id
export const ACTION_SEARCH_SUBID = 'Search Sub Id User';
export const ACTION_CHANGE_STATUS_SUBID = 'Change Status Sub Id User';
export const ACTION_REGISTER_NEW_SUBID = 'Register New Sub Id User';

// Contact Us
export const ACTION_SEND_CONTACT = 'Send Contact';

// Data Hub
export const ACTION_SEARCH_DOWNLOAD_CENTER = 'Search Download Center';
export const ACTION_DOWNLOAD_SELECTED = 'Download Selected';
export const ACTION_FILTER_VIDEO_CONTENT = 'Filter Video Content';
export const ACTION_DOWNLOAD_DATAHUB_DETAIL = 'Download Data Hub Detail';
export const ACTION_DOWNLOAD_VIDEO_CONTENT_DETAIL = 'Download Video Content Detail';

// Notice
export const ACTION_DOWNLOAD_NOTICE_DETAIL = 'Download Notice Detail';

export const sendEventForDashBoard = action => {
    ReactGA.event({
        category: CATEGORY_DASHBOARD,
        action: action,
        label: _.kebabCase(action)
    });
}

export const sendEventForOrderHistory = action => {
    ReactGA.event({
        category: CATEGORY_ORDER_HISTORY,
        action: action,
        label: _.kebabCase(action)
    });
}

export const sendEventForRewardIntroduction = action => {
    ReactGA.event({
        category: CATEGORY_REWARD_INTRODUCTION,
        action: action,
        label: _.kebabCase(action)
    });
}

export const sendEventForShop = action => {
    ReactGA.event({
        category: CATEGORY_SHOP,
        action: action,
        label: _.kebabCase(action)
    });
}

export const sendEventForRewardHistory = action => {
    ReactGA.event({
        category: CATEGORY_REWARD_HISTORY,
        action: action,
        label: _.kebabCase(action)
    });
}

export const sendEventForProductRegistration = action => {
    ReactGA.event({
        category: CATEGORY_REWARD_PRODUCT_REGISTRATION,
        action: action,
        label: _.kebabCase(action)
    });
}

export const sendEventForForecast = action => {
    ReactGA.event({
        category: CATEGORY_REWARD_FORECAST,
        action: action,
        label: _.kebabCase(action)
    });
}

export const sendEventForRegister = action => {
    ReactGA.event({
        category: CATEGORY_REGISTER,
        action: action,
        label: _.kebabCase(action)
    });
}

export const sendEventForProfile = action => {
    ReactGA.event({
        category: CATEGORY_PROFILE,
        action: action,
        label: _.kebabCase(action)
    });
}

export const sendEventForSubId = action => {
    ReactGA.event({
        category: CATEGORY_SUB_ID,
        action: action,
        label: _.kebabCase(action)
    });
}

export const sendEventForContactUs = action => {
    ReactGA.event({
        category: CATEGORY_CONTACT_US,
        action: action,
        label: _.kebabCase(action)
    });
}

export const sendEventForDataHub = action => {
    ReactGA.event({
        category: CATEGORY_DATA_HUB,
        action: action,
        label: _.kebabCase(action)
    });
}

export const sendEventForNotice = action => {
    ReactGA.event({
        category: CATEGORY_NOTICE,
        action: action,
        label: _.kebabCase(action)
    });
}