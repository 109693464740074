import React from 'react';
import PopOver from '../../../config/images/popover_icon.svg';
import useStyles from './useStyles';
import {useHistory} from 'react-router-dom';


function ShowMessageLineSpec() {

  const classes = useStyles();
  const history = useHistory();

  return(
    <div className={classes.wrapMessageLineSpec}>
      <img style={{marginRight: 25, height : 20, opacity: 0.7}} src={PopOver}/>
      <ul className={classes.wrapLine}>
        <li>The maximum amount to get reimbursed is $250 per project per product type. <em onClick={() => history.push({
          pathname: '/claim/how-it-works',
          state: {
            isScrollToLaborReimbursement: true
          }
        })} className={classes.boldInfo}>LEARN MORE</em></li>
        {/* <li>Please use the provided invoice template. <em className={classes.boldInfo}>DOWNLOAD TEMPLATE</em></li> */}
      </ul>
    </div>
  )
}


export default ShowMessageLineSpec