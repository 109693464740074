import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Text from '../../../../common/components/text';
import Box from '@material-ui/core/Box';

const OrderDetailLabel = styled.span(
  ({ theme }) => `
  display: inline-block;
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.dark};
  margin-left: 20px;
  font-weight: 500;
`
);
const OrderDetailValue = styled.span(
  ({ theme }) => `
  display: inline-block;
  margin-left: 10px;
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.secondaryDark};
  text-align: right;
`
);

export default function OrderDetailsHeader({ orderNumber, orderDate }) {
  return (
    <Box display="flex" className="foo400">
      <Box flexGrow={1} className="foo401">
        <Text
          className="label_order_detail"
          style={{ height: '47px', lineHeight: '47px' }}
          as="h3"
          weight="medium"
        >
          Order Details
        </Text>
      </Box>
      <Box className="foo402">
        <OrderDetailLabel
          className="foo402_label"
          style={{ height: '47px', lineHeight: '47px' }}
        >
          Order Date
        </OrderDetailLabel>
        <OrderDetailValue>{orderDate}</OrderDetailValue>
      </Box>
      <Box className="foo403">
        <OrderDetailLabel
          className="foo403_label"
          style={{ height: '47px', lineHeight: '47px' }}
        >
          Order Number
        </OrderDetailLabel>
        <OrderDetailValue>{orderNumber}</OrderDetailValue>
      </Box>
      {/* <Grid item container xs={12} sm={6} justify="flex-end">
        <Grid item>
          <OrderDetailLabel>Order Date</OrderDetailLabel>
          <OrderDetailValue>{orderDate}</OrderDetailValue>
        </Grid>
        <Grid item>
          <OrderDetailLabel>Order Number</OrderDetailLabel>
          <OrderDetailValue>{orderNumber}</OrderDetailValue>
        </Grid>
      </Grid> */}
    </Box>
  );
}

OrderDetailsHeader.propTypes = {
  orderNumber: PropTypes.string,
  orderDate: PropTypes.string
};
