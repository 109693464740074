import React from 'react';
import PropTypes from 'prop-types';
// <h1 style={{ fontSize: '48px', fontWeight: 600 }}>Rewards History</h1>

const TitleAnimation = ({ children }) => (
  <h1 className="title_animation" style={{ fontSize: '48px', fontWeight: 500, fontFamily: 'Proxima' }}>
    {children}
  </h1>
);

TitleAnimation.propTypes = {
  children: PropTypes.any.isRequired
};

export default TitleAnimation;
