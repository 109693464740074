import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 52,
    height: 28,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(24px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#000000',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#000000',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 26,
    height: 26,
  },
  track: {
    borderRadius: 28 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


export default function CustomizedSwitches({handleChangeValue, battery}) {
  const [state, setState] = React.useState(false);

  const handleChange = (event) => {
    setState(event.target.checked);
    handleChangeValue(event)
  };

  useEffect(() => {
    setState(battery);
  }, [battery])

  return (
    <FormGroup>
      <FormControlLabel
        control={<IOSSwitch checked={state} onChange={handleChange} name="checkedB" />}
      />
    </FormGroup>
  );
}
