import React from 'react';
import RegistrationBanner from '../../../config/images/registration-banner.jpg';
import BreadCrumb2 from '../../../common/components/BreadCrumb2';
import Banner from '../../../common/components/Banner';
import useStyles from './useStyles';
import IconRequiredFields from '../../../icons/requiredFields.svg';
import BreadCrumbClaim from '../../../common/components/BreadCrumbClaim';
import { useHistory } from 'react-router-dom';

function IntroductionClaim({ title, sub_title, step, tyleClaim }) {
  const history = useHistory();
  const classes = useStyles();
  function renderStatus() {
    switch (step) {
      case 0:
        return 'start';
      case 1:
      case 2:
      case 3:
        return 'progress';
      case 4:
        return 'finish';
    }
  }

  function renderSubText() {
    switch (renderStatus(step)) {
      case 'start':
        return (
          <p style={{ fontSize: 14, color: '#000' }}>
            Fill out the fields and submit the below form for Qcells to begin
            processing your claim. For more specific details on the Claims process,
            visit{' '}
            <span style={{ textTransform: 'uppercase' }}>
              <em
                style={{
                  color: '#009EE3',
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }}
                onClick={() =>
                  history.push({
                    pathname: '/claim/how-it-works'
                  })
                }
              >
                How it works
              </em>
            </span>{' '}
            Please fill all{' '}
            <img
              style={{
                margin: '0 10px',
                display: 'initial',
                verticalAlign: 'middle'
              }}
              src={IconRequiredFields}
            />{' '}
            All other fields are optional.
          </p>
        );
      case 'progress':
        return (
          <p style={{ fontSize: 14, color: '#000' }}>
            Please fill all{' '}
            <img
              style={{
                margin: '0 10px',
                display: 'initial',
                verticalAlign: 'middle'
              }}
              src={IconRequiredFields}
            />{' '}
            All other fields are optional.
          </p>
        );
      case 'finish':
        return null;
      default:
        return (
          <p style={{ fontSize: 14, color: '#000' }}>
            Please fill all{' '}
            <img
              style={{
                margin: '0 10px',
                display: 'initial',
                verticalAlign: 'middle'
              }}
              src={IconRequiredFields}
            />{' '}
            All other fields are optional.
          </p>
        );
    }
  }
  return (
    <Banner
      className="OrderHistory_Banner banner100"
      height={150}
      type={'form'}
      status={renderStatus()}
    >
      <div className={classes.wrapContent}>
        {renderStatus() === 'progress' && (
          <h1 style={{ fontSize: 36, color: '#000', fontWeight: 400 }}>{title}</h1>
        )}
        {renderStatus() === 'start' && (
          <h1 style={{ fontSize: 36, color: '#000', fontWeight: 400 }}>
            Submit a Claim
          </h1>
        )}
        {renderSubText()}
      </div>
      {renderStatus() === 'finish' && (
        <>
          <h1
            style={{ color: '#009EE3', margin: '0 auto' }}
            className="OrderHistory_Title"
          >
            Almost there!
          </h1>
          <h3 className={classes.subClaim}>
            Please review the information below to submit your {tyleClaim} claim.
          </h3>
        </>
      )}
      <BreadCrumbClaim type={'form'} items={sub_title} />
    </Banner>
  );
}

export default IntroductionClaim;
