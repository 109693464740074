import React, {useState, useEffect} from 'react';
import parse from 'html-react-parser'
import Banner from '../../common/components/Banner';
import BannerImage from '../../config/images/visual_area01_3.jpg';
import AccordianArrow from '../../icons/AccordianArrow';
import TitleAnimation from '../../common/components/TitleAnimation';
import BreadCrumb2 from '../../common/components/BreadCrumb2';
import Text from '../../common/components/text';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import axios from 'axios';
import {baseUrl} from '../../config/index';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Pagination from '../notice/components/Pagination';
import MenuIcon from '@material-ui/icons/Menu';
import {Link} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useAuth } from '../../contexts/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '63vw',
    margin: 'auto',
    marginBottom: '40px',
    marginTop: '20px',
    borderTop: '1px solid #000',
    borderBottom: '1px solid #000'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const StyledContainer = styled.div `
  width: 80%;
  margin: auto;
  margin-bottom: 40px;
  margin-top: 40px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  
  @media only screen and (max-width: 1200px) {
    width: 90vw;
  }
  @media only screen and (max-width: 600px) {
    width: 95vw;
  }
  @media only screen and (max-width: 375px) {
    margin-top: 20px;
    width: 95vw;
  }
`;

const StyledTextContainer = styled.div `
  padding-top: 70px;
  width: 80%; 
  margin: auto;
  font-size: 26px;

  @media only screen and (max-width: 1200px) {
    width: 90vw;
  }
  @media only screen and (max-width: 600px) {
    width: 95vw;
  }
  @media only screen and (max-width: 375px) {
    padding-top: 70px;

  }
`;

const PaginationContainer = styled.div `
  border: solid;
  margin: auto;
  margin-bottom: 100px;
`;

const MobileMenu = styled.div `
  width: 100%;
  height: 50px;
  text-align: center;
  background-color: #029ee3;
  cursor: pointer;
  position: absolute;
  z-index: 99;

  @media only screen and (min-width: 376px) {
    display: none;
  }
`

const SubMenu = styled.nav `
  display: ${props => props.mobileMenuOpen ? '' : 'none'};
  transition: all .5s;
`;

const StyledLi = styled.li `
  width: 90vw;
  background-color: #fff;
  height: 50px;
  padding-top: 14px;
  margin: auto;
  border: 1px #cccccc solid;
  z-index: 99;
`;

export default function Account() {
  const user = useAuth();
  const classes = useStyles();
  const [types, setTypes] = useState([]);
  const [faqData, setFaqData] = useState([]);
  const { faqTypeId } = useParams();
  const [pageTitle, setPageTitle] = useState('Account')
  const [currentOpenFaq, setCurrentOpenFaq] = useState();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState();
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState({ limit, offset });
  const [count, setCount] = useState();

  const handleMenuClick = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getPageTitle = () => {
    switch(+faqTypeId) {
      case 1:
        setPageTitle('ACCOUNT')
        break;
      // case 2:
      //   setPageTitle('Q.POINT')
      //   break;
      // case 3:
      //   setPageTitle('REWARDS')
      //   break;
      // case 4:
      //   setPageTitle('PRODUCT ORDER')
      //   break;
      case 5:
        setPageTitle('TECHNICAL SUPPORT')
        break;
      default:
        setPageTitle('Account')
    }
  }

  const handlePageChange = (e, currentPage) => {
    setCurrentPage(currentPage);
    const newOffset = limit * currentPage - limit;
    setOffset(newOffset);
    const filter = {
      ...filters,
      offset: newOffset
    };
    getTypeDetails(filter);
  }

  const getTypes = async () => {
    const {data} = await axios.get(`${baseUrl}/faq/types`);
    setTypes(data)
  }

  const getTypeDetails = async (filters = {}) => {
    const {data} = await axios.get(`${baseUrl}/faq/search?&offset=0&faqTypeId=${faqTypeId}`, {params: filters});
    setCount(Math.ceil(data.total / 10))
    setFaqData(data.data);
    setTotalData(data.total);
  }

  useEffect(()=> {
    getTypes();
    getTypeDetails();
    getPageTitle();
  }, [])

  const handleClick = (link) => {
    setMobileMenuOpen(false);
    if (!user.authenticated) {
      alert('Please try again after logging in.');
      window.location.href = `/sso/login?url=/support/faq/${link}`;
    } else {
      window.location = `/support/faq/${link}`
    }
  }

  const handleOpen = (id) => {
    if (currentOpenFaq === id) {
      setCurrentOpenFaq(false);
    } else {
      setCurrentOpenFaq(id);
    }
  }

  const productDescHtml = (data) => {
    const parseedData = parse(`${data}`);

    return typeof(parseedData) === 'object' ? parse(`${data}`) : parse(parseedData);
  };

  return (
    <section
      style={{
        backgroundColor: 'var(--light-color, #ffffff)',
        position: 'relative'
      }}
    >
      <BreadCrumb2 items={['Support', 'Faq']}></BreadCrumb2>
      <Banner imageUrl={BannerImage} className="banner100" activeTab={faqTypeId} tabsFaq handleClick={handleClick}>
        <TitleAnimation>FAQ</TitleAnimation>
      </Banner>
      <MobileMenu onClick={handleMenuClick}>
        <MenuIcon fontSize="40px" style={{marginTop: '10px', color: '#fff'}} />
        <SubMenu mobileMenuOpen={mobileMenuOpen} className="desktop1" style={{paddingTop: '10px'}}>
          <div className="nav_left">
          <ul className="submenu1">  
            <StyledLi>
              <Link onClick={() => handleClick('1')}>ACCOUNT</Link>
              {+faqTypeId === 1 ? <div style={{backgroundColor: '#029EE3', width: '85%', height: '2px', margin: 'auto', zIndex: '1'}}></div> : false}
            </StyledLi>
            <StyledLi className="li_shop_desktop">
              <Link onClick={() => handleClick('5')}>TECHNICAL SUPPORT</Link>
              {+faqTypeId === 5 ? <div style={{backgroundColor: '#029EE3', width: '85%', height: '2px', margin: 'auto', zIndex: '1'}}></div> : false}
            </StyledLi>
          </ul>
        </div>
        </SubMenu>
      </MobileMenu>
      <StyledTextContainer>
        <Text style={{fontSize: '26px', fontWeight: '600', lineHeight: '26px', color: '#000000'}}>{pageTitle}</Text>
      </StyledTextContainer>
      <StyledContainer>
        {faqData.map(data => {
          return (
            <Accordion expanded={currentOpenFaq === data.id} square elevation="0" onChange={() => {handleOpen(data.id)}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon fontSize="large"/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{paddingRight: '40px'}}
              >
                <Text weight="light" className={classes.heading} style={{fontSize: '16px', paddingLeft: '40px'}}>{data.title}</Text>
              </AccordionSummary>
              <AccordionDetails style={{backgroundColor: '#f4f4f4', padding: '30px 55px'}}>
                <Grid container>
                  <Grid item xs={12}>
                {data.video ? <iframe height="553" src={data.video} style={{width:"56.25vw", paddingTop: '20px'}} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe> : false}
                </Grid>
                <Grid item xs={12}>
                { data.image ? <img src={data.image.assetUrl} /> : false }
                </Grid>
                <Grid item xs={12}>
                <Text weight="light" style={{fontSize: '16px'}}>
                  {productDescHtml(data.content)}
                </Text>
                </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )
        })}
    </StyledContainer>
    <Grid
      container
      justify="center"
      item
      xs={12}
      style={{ marginTop: '10px', marginBottom: '20px' }}
      alignItems="center"
      spacing={2}
    >
      {/* <Pagination style={{width: '120px', margin: 'auto'}} page={1} count={1} variant="outlined" shape="rounded" /> */}
      <Pagination
      uploadsPerPage={10}
      totalUploads={totalData}
      pagenumber={currentPage}
      handlePageChange={handlePageChange}
    />
    </Grid>
    </section>
  )
}