import * as yup from 'yup';
import _ from 'lodash';
export const schemaPV = yup.object().shape({
  // installerName : yup.string().required('Name is required'),
  // installerAddress1 : yup.string().required('Address is required'),
  // installerAddress2 : yup.string().required('Address2 is required'),
  // installerCity : yup.string().required('City is required'),
  // installerState : yup.string().notOneOf(['select'], 'State is required'),
  // installerContact : yup.string().required('Contact name is required'),
  // installerZipcode : yup.number().required('Zipcode is required'),
  emailPurchase: yup.string().required('Email is required').matches(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/, 'Invalid email address'),
  //submitterEmail: yup.string().matches(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/, 'Invalid email address'),
  //installerPhone: yup.string().required("Contact number is required").matches(/^[0-9]*$/, "This entry can only contain numbers (e.g., 9497774444)."),

  // claimType : yup.string().notOneOf(['select'], 'Claim Type is required'),
  // type : yup.string().notOneOf(['select'], 'Info Claim Name is required'),

  description : yup.string().required('Description of your issue is required'),
  projectInfo: yup.object().nullable().when('choseTypeProject', { is: value => value === '1', then: yup.object().nullable().notOneOf([null], 'projectId is required') }),
  projectAddressManual: yup.object().nullable().when('choseTypeProject', { is: value => value === '2', then: yup.object().nullable().notOneOf([null], 'project Address is required') }),
  projectStateManual : yup.string().when('choseTypeProject', {
    is : (value) => value === '2',
    then : yup.string().notOneOf(['select'], 'State is required')
  }),

  projectCityManual : yup.string().when('choseTypeProject', {
    is : (value) => value === '2',
    then : yup.string().required('City is required')
  }),

  projectZipCodeManual : yup.string().when('choseTypeProject', {
    is : (value) => value === '2',
    then : yup.string().required("Zipcode is required").test({ name: 'length', message: 'Zipcode must be 5 digits long', exclusive: true, test: val => val && _.toString(_.trim(val)).length === 5 })
  }),



  projectNameSys : yup.string().when(
    ['choseTypeProject', 'projectInfo'], {
      is: (choseTypeProject, projectInfo) => projectInfo !== null || choseTypeProject === '2' || (choseTypeProject === '1' && projectInfo === null),
      then: yup.string().required('Project Name is required')
    }),

  projectAddress : yup.string().when(
    ['choseTypeProject', 'projectInfo'], {
      is: (choseTypeProject, projectInfo) => projectInfo !== null || (choseTypeProject === '1' && projectInfo === null),
      then: yup.string().required('Project Address is required')
    }),

  // projectAddressManual :

  projectInstallationDate : yup.mixed().when(
    ['choseTypeProject', 'projectInfo'], {
      is: (choseTypeProject, projectInfo) => projectInfo !== null || choseTypeProject === '2' || (choseTypeProject === '1' && projectInfo === null),
      then: yup.mixed().nullable().notOneOf([null], 'Installation date is required')
    }),

  projectPermissionDate : yup.mixed().when(
    ['choseTypeProject', 'projectInfo'], {
      is: (choseTypeProject, projectInfo) => projectInfo !== null || choseTypeProject === '2' || (choseTypeProject === '1' && projectInfo === null),
      then: yup.mixed().nullable().notOneOf([null], 'Permission Date is required')
    }),



  projectProductName : yup.string().when(
    ['choseTypeProject', 'projectInfo'], {
      is: (choseTypeProject, projectInfo) => projectInfo !== null || choseTypeProject === '2' || (choseTypeProject === '1' && projectInfo === null),
      then: yup.string().notOneOf(['select'], 'Product Name is required')
    }),

  //projectInverterPower
  projectPowerClass : yup.string().when(
    ['choseTypeProject', 'projectInfo'], {
      is: (choseTypeProject, projectInfo) => projectInfo !== null || choseTypeProject === '2' || (choseTypeProject === '1' && projectInfo === null),
      then: yup.string().notOneOf(['select'], 'Product Name is required')
    }),

  // projectNumberOfPanel : yup.string().when(
  //   'choseTypeProject', {
  //     is: (choseTypeProject) => choseTypeProject === '2',
  //     then: yup.string().required('Number Of Panel is required')
  //   }),

  // isserialNumber : yup.string().when(
  //   'choseTypeSerialUpload', {
  //     is : (choseTypeSerialUpload) => choseTypeSerialUpload === '1',
  //     then : yup.string().required('Upload Serial Number is required')
  //   }
  // ),

  serialNumbers : yup.array().when('choseTypeSerialUpload', {
    is: value => value === '1',
    then: yup.array().min(1, 'Serial Number is required')
  }),

  // proofOfPermissionToOperateDate : yup.string().when(
  //   'choseTypeProject', {
  //     is : (choseTypeProject) => choseTypeProject === '2',
  //     then : yup.string().required('proofOfPermissionToOperateDate is required')
  //   }
  // ),

  isUploadProofDate : yup.string().when(
    ['validateProofOfDate'], {
      is : (validateProofOfDate) => validateProofOfDate,
      then : yup.string().required('Proof of Permission-to-Operate Date is required')
    }
  ),

  isUploadSerialNumber : yup.string().when(
    'choseTypeSerialUpload', {
      is : (choseTypeSerialUpload) => choseTypeSerialUpload === '2',
      then : yup.string().required('Upload Serial Number is required')
    }
  ),



  // warranty1 : yup.mixed().when('chooseRefund', {
  //   is: value => value === '1',
  //   then: yup.string().required('Warranty is required')
  // }),
  // warranty2 : yup.mixed().when('chooseRefund', {
  //   is: value => value === '2',
  //   then: yup.string().required('Warranty is required')
  // }),

  invoiceForLaborReimbursement : yup.string().when(
    'chooseRefund', {
      is: (chooseRefund) => chooseRefund === '2',
      then : yup.string().required('invoiceForLaborReimbursement is required')
    }
  ),

  infoBank : yup.string().when(
    ['chooseBankAccInfo', 'chooseRefund'], {
      is : (chooseBankAccInfo, chooseRefund) => chooseBankAccInfo === '2' && chooseRefund === '2',
      then : yup.string().required('infoBank is required')
    }
  ),
  //serials : yup.string().required('Affected module serial numbers is required'),
  regionPv : yup.string().required('Region is required'),
  // warranty : yup.string().required('Warranty is required'),

  pictureOfModuleLabel: yup.string().required('Picture of module labels is required'),
  pictureOfTheFrontSize : yup.string().when('claimType', {
    is : (claimType) => ['1', '2', '3', '4', '6', '7'].includes(claimType),
    then : yup.string().required('Picture of the front side of PV module is required')
  }),

  pictureOfTheBackSize : yup.string().when('claimType', {
    is : (claimType) => ['1', '2', '3', '4', '5', '6', '7'].includes(claimType),
    then : yup.string().required('Picture of the backside of pv module is required')
  }),

  pictureOfVocReading : yup.string().when('claimType', {
    is : (claimType) => ['1', '7'].includes(claimType),
    then : yup.string().required('pictureOfVocReading is required')
  }),

  closeUpPictureOfPhysicalDefect : yup.string().when('claimType', {
    is : (claimType) => ['2', '3', '4', '6', '7'].includes(claimType),
    then : yup.string().required('Close Up Picture of Physical Defect is required')
  }),

  siteModuleLayoutPlan : yup.string().when('claimType', {
    is : (claimType) => ['2', '7'].includes(claimType),
    then : yup.string().required('Site Module Layout Plan is required')
  }),

  pictureOfOverallRoofArea : yup.string().when('claimType', {
    is : (claimType) => ['2'].includes(claimType),
    then : yup.string().required('Picture of Overall Roof Area is required')
  }),

  pictureOfWireManagement : yup.string().when('claimType', {
    is : (claimType) => ['4', '7'].includes(claimType),
    then : yup.string().required('Picture of Wire Management is required')
  }),


  proofOfDeliveryDocument : yup.string().when('claimType', {
    is : (claimType) => ['5'].includes(claimType),
    then : yup.string().required('Proof-of-Delivery Document is required')
  }),


  pictureOfModuleImmediately : yup.string().when('claimType', {
    is : (claimType) => ['5'].includes(claimType),
    then : yup.string().required('Picture of Module Immediately Upon Receipt is required')
  }),


  pictureOfVmpp : yup.string().when('claimType', {
    is : (claimType) => ['1', '2', '3', '4', '5', '6', '7'].includes(claimType),
    then : yup.string().required('Picture of Vmpp is required')
  }),

  irPicture : yup.string().when('claimType', {
    is : (claimType) => ['1', '2', '3', '4', '5', '6', '7'].includes(claimType),
    then : yup.string().required('IrPicture is required')
  }),

  systemTypePv : yup.string().notOneOf(['select'], 'Product Name is required'),

  purchasingChannel: yup.string().notOneOf(['select'], 'Purchasing channel is required'),
  nameOfPurchasingChannelDistributor: yup.object().nullable().when('purchasingChannel', { is: value => value === 'distributor', then: yup.object().nullable().notOneOf([null], 'Name of purchasing channel is required') }),
  nameOfPurchasingChannelOther: yup.string().when('purchasingChannel', { is: value => value === 'other', then: yup.string().required('Name of purchasing channel is required') }),
  //nameOfPurchasingChannelSelect: yup.string().when('purchasingChannel', { is: value => value === 'select', then: yup.string().required('Please select Purchasing Channel') }),
  nameOfPurchasingChannelSelect: yup.object().nullable().when('purchasingChannel', { is: value => value === 'select', then: yup.object().nullable().notOneOf([null], 'Name of purchasing channel is required') }),
  nameOfChanelOtherDistributor : yup.string().when(['nameOfPurchasingChannelDistributor'], {
    is : (nameOfPurchasingChannelDistributor) => nameOfPurchasingChannelDistributor !== null && nameOfPurchasingChannelDistributor?.name === 'Other',
    then : yup.string().required('nameOfChanelOtherDistributor is required')
  }),
  poNumber : yup.string().required('PO number is required'),
  coppyOfInvoice : yup.string().required('Copy of Invoice/Receipt is required'),

})
