import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Text from '../../../../common/components/text';
import CartIcon from '../../../../icons/Cart';
import AddressIcon from '../../../../icons/Address';
import BoxIcon from '../../../../icons/Box';
import Pen from '../../../../config/images/Picture.png';
import Recurring from '../../../../icons/Recurring';

const BAR_HEIGHT = '56px';
const Bar = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    width: 100%;
    min-width: 650px;
    margin: 1rem 0;
    overflow: hidden;
  }
  @media (max-width: 1200px) {
    display: flex;
    width: 100%;
    min-width: 650px;
    margin: 1rem 0;
    overflow: hidden;
  }
  
  @media (max-width: 780px) {
    min-width: auto;
  }
`;

const Triangle = css`
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: calc(${BAR_HEIGHT} / 1.7) solid transparent;
  border-bottom: calc(${BAR_HEIGHT} / 1.7) solid transparent;
`;


const Triangle2 = css`
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: calc(${BAR_HEIGHT} / 1.7) solid transparent;
  border-bottom: calc(${BAR_HEIGHT} / 1.7) solid transparent;
`;

const ChevronTab = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 3);
  height: ${BAR_HEIGHT};
  background-color: ${({ current, theme }) =>
  current ? theme.colors.primaryDark : theme.colors.secondaryLight};

  color: ${({ current, theme }) =>
  current ? theme.colors.light : theme.colors.secondaryDark};
  
  @media (max-width: 1600px) {
    flex-direction: column;
  }
  
  &:nth-child(3) {
  background-color: ${({ current, theme }) =>
  current ? theme.colors.primaryDark : '#efefef'};
  }
  
  &:nth-child(3):after,before {
  border-left: calc(${BAR_HEIGHT} / 2) solid
      ${({ current, theme }) =>
  current ? theme.colors.primaryDark : theme.colors.process};
  }

  &:last-child {
     background-color: #efefef73;
  }

  &::before,
  &::after {
    border-left: calc(${BAR_HEIGHT} / 2) solid
      ${({ current, theme }) =>
  current ? theme.colors.primaryDark : theme.colors.secondaryLight};
  }

  &::before {
    ${Triangle}
    left: 0;
    z-index: 1;
  }

  &::after {
    ${Triangle}
    z-index: 2
  }
`;

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 5px;
  
  @media (max-width: 780px) {
    width: 25px;
    height: 25px;
    margin-left: 25px;
  }
`;

const Image = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
  border: 1px solid #eee;
  border-radius: 5px;
`;

const TextProgress  = styled.span`
  margin-left: 3px;
  
  @media (max-width: 850px) {
    display: none;
  }
  
  @media (max-width: 1200px) {
    font-size: 13px;
  }
  
`

const stepsDefaultProduct = [
  { step: 0, text: 'Cart', icon: <CartIcon /> },
  { step: 1, text: 'Confirm Address', icon: <AddressIcon /> },
  { step: 2, text: 'Order Completed', icon: <BoxIcon /> }
];

const stepsProductAura = [
  { step: 0, text: 'Cart', icon: <CartIcon /> },
  { step: 1, text: 'Confirm Address', icon: <AddressIcon /> },
  { step: 2, text: 'Complete Aurora Information', icon: <Recurring/> },
  { step: 3, text: 'Order Completed', icon: <BoxIcon /> }
]



const ProgressBar = ({ currentStep, countProductInAura }) => {

  const [steps, setStep] = useState(stepsDefaultProduct);

  useEffect(() => {
    countProductInAura >= 1 ? setStep(stepsProductAura) : setStep(stepsDefaultProduct)
  }, [countProductInAura])

  return(
    <Bar>
      {steps.map(({ step, text, icon }) => (
        <ChevronTab key={step} current={step <= currentStep}>
          <IconWrapper>
            {React.cloneElement(icon, {
              color:
                step <= currentStep
                  ? 'var(--light-color, #ffffff)'
                  : 'var(--secondary-dark-color, #8c8c8c)'
            })}
          </IconWrapper>
          <TextProgress>{text}</TextProgress>
          {/*<Text style={{ marginLeft: 3 }}>{text}</Text>*/}
        </ChevronTab>
      ))}
    </Bar>
  )
}

export default ProgressBar;
