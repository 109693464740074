import React from 'react';
import { Modal, Paper } from '@material-ui/core';
import useStyles from './useStyles';
import ModalCloseIcon from '../../../config/images/modal_close_icon.svg'

export default function ImageModal({ open, name, url, handleClose }) {
    const classes = useStyles();

    return (
        <Modal onClose={handleClose} open={open} className={classes.modal}>
            <Paper>
                <span onClick={handleClose}>
                    <img src={ModalCloseIcon} />
                </span>
                <div className={classes.modalContent}>
                    <p>{name}</p>
                    <div>
                        <img src={url} />
                    </div>
                </div>
            </Paper>
        </Modal>
    )
}