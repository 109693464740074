import React from 'react';
import Text from '../../../common/components/text';
import clsx from 'clsx';
import moment from 'moment';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import CheckBox from '../../../config/images/bg_checkbox.png';
import CheckBoxOn from '../../../config/images/bg_checkbox_on.png';
import { useAuth } from '../../../contexts/auth';
import _ from 'lodash';

const StyledCard = styled.div `
  position: relative;
  float: left;
  width: 285px;
  height: 419px;
  margin: 30px 0 0 20px;
  background: #fff;
  border: solid #eee;
  border-width: 1px 1px 0 1px;
  box-sizing: border-box;
  border-bottom: 1px solid #000;

  :hover {
    cursor: pointer;
    border-bottom: 2px solid #009DDD;
  }

  @media only screen and (max-width: 375px) {
    margin-left: -20px;
  }
`;

const StyledImage = styled.img `
  position: relative;
  width: 283px;
  height: 278px;
  overflow: hidden;
`;

const StyledText = styled.p `
  min-height: 43px;
  padding-bottom: 15px;
  color: #000;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  word-wrap: break-word;
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 200;
`;

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#009DDD',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#009DDD',
    },
  },
});


export default function Card({id, product ,selectedCards, image, title, fileSize, fileType, updatedAt, detailCategory, handleSelectedCard}) {
  const classes = useStyles();
  const user = useAuth();

  const redirectToDetailPage = (id) => {
    if (!user.authenticated) {
      alert('Please try again after logging in.');
      window.location.href = `/sso/login?url=/salestools/data-hub/${detailCategory}/${id}`;
    } else {
      window.location = `/salestools/data-hub/${detailCategory}/${id}`
    }
  }
  return (
    <StyledCard>
      {detailCategory === 'download-center' ? selectedCards.includes(id) ?
            <img src={CheckBoxOn} style={selectedCards.includes(id) ? {color: '#009DDD', position: 'absolute', zIndex: '999', backgroundColor: 'fff', top: '15px', left: '15px' } : {position: 'absolute', zIndex: '999', backgroundColor: 'fff', top: '15px', left: '15px'}}  onClick={() => handleSelectedCard(id)} /> :
            <img src={CheckBox} style={selectedCards.includes(id) ? {color: '#009DDD', position: 'absolute', zIndex: '999', backgroundColor: 'fff', top: '15px', left: '15px' } : {position: 'absolute', zIndex: '999', backgroundColor: 'fff', top: '15px', left: '15px'}}  onClick={() => handleSelectedCard(id)} />
      : false}

      <StyledImage onClick={() => redirectToDetailPage(id)} src={image}/>
      <StyledText>{title}</StyledText>
      {detailCategory === 'video-content' ? <Text color="secondaryDark" weight="light" size="h5" style={{top: '30px',textAlign: 'center', fontSize: '14px'}}>{moment(updatedAt).format('MM[-]DD[-]YYYY')}</Text> : <Text color="secondaryDark" weight="light" size="h5" style={{textAlign: 'center', position: 'absolute', left: 100, bottom: 30}}>| {_.get(product, 'attachment.assetKey').split('.').pop().toUpperCase()} | {Math.round(fileSize / 1024).toLocaleString()} KB</Text>}
    </StyledCard>
  )
}
