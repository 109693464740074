import React from 'react';

const AddressIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 480 480"
    version="1.1"
    viewBox="0 0 480 480"
    width="100%"
    height="100%"
    fill={color}
  >
    <path d="m240 0c-97.202 0-176 78.798-176 176 0 94.576 162.9 292.71 169.83 301.1 1.52 1.839 3.782 2.904 6.168 2.904s4.648-1.065 6.168-2.904c6.936-8.384 169.83-206.52 169.83-301.1 0-97.202-78.798-176-176-176zm0 459.29c-30.104-37.576-160-204.31-160-283.29 0.101-88.324 71.677-159.9 160-160 88.324 0.101 159.9 71.676 160 160 0 78.96-129.9 245.7-160 283.29z" />
    <path d="m348.88 145.66-104-80c-2.877-2.214-6.883-2.214-9.76 0l-104 80c-3.499 2.697-4.149 7.721-1.452 11.22 1.514 1.964 3.852 3.115 6.332 3.116h24v88c0 4.418 3.582 8 8 8h144c4.418 0 8-3.582 8-8v-88h24c4.418-2e-3 7.998-3.586 7.996-8.004-1e-3 -2.48-1.152-4.818-3.116-6.332zm-92.88 94.336h-32v-48h32v48zm56-96c-4.418 0-8 3.582-8 8v88h-32v-56c0-4.418-3.582-8-8-8h-48c-4.418 0-8 3.582-8 8v56h-32v-88c0-4.418-3.582-8-8-8h-8.48l80.48-61.904 80.48 61.904h-8.48z" />
  </svg>
);

export default AddressIcon;
