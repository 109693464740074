import axios from '../utils/axios';
import config from '../config';
import qcellModules from '../data/qcells-modules.json'
import qcellEss from '../data/qcells-ess.json'
import _ from 'lodash';

function getBrandsSystemInfo(productType, withoutQcells) {
  const request = {
    method: 'GET',
    url: `${config.baseUrl}/projects/manufacturers`,
    // url: `${config.baseUrl}/companies/register/product-brands?productType=${productType}`,
    params : {
      productType : productType,
      withoutQcells: withoutQcells
    }
  };

  return axios(request);
}

function getModelsSystemInfo(manufacturerId) {
  const request = {
    method: 'GET',
    url: `${config.baseUrl}/projects/models-non-qcells`,
    params : {
      manufacturerId : manufacturerId
    }
  };

  return axios(request);
}

function getQCellsEssModel() {
  return {
    data: qcellEss
  };
}

function checkDuplicateAddress(keyword) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/projects/list`,
    params : {
      address : keyword
    }
  }

  return axios(request);
}


function getAddress(condition) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/projects/maps/search`,
    params : {
      key : config.keyGoogleMap,
      ...condition
    }
  }

  return axios(request);
}

function getDetailAddress(placeId) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/projects/maps/detail`,
    params : {
      place_id : placeId,
      key : config.keyGoogleMap
    }
  }

  return axios(request);
}

function getProfile() {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/users/profile`
  }

  return axios(request);
}

function getListProject(condition) {

  //// panelQualified : false,
  //     //   batteryQualified : false,
  //     //   panelNonQcells : false,
  //     //   batteryNonQcells : false,

  let makePayload = {}

  if(condition?.battery) {
    makePayload = {
      battery : true,
      batteryNonQcells : false,
      //batteryQualified : true
    }
  }else {
    makePayload  = {
      panelNonQcells : false,
      //panelQualified : true
    }
  }

  const request = {
    method : 'GET',
    url : `${config.baseUrl}/projects/list`,
    params : {
      isDraft : false,
      ...makePayload
    }
  }

  return axios(request);
}

function getListProjectDraft() {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/projects/search`,
    params : {
      isDraft : false,
      // ...condition
    }
  }

  return axios(request);
}


function getPowerClass(productType) {
  const request = {
    method: 'GET',
    url: `${config.baseUrl}/projects/power-class/search`,
    params : {
      productType : productType
    }
  };

  return axios(request);
}

function getModelsByPowerClass(productType, volume) {
  const request = {
    method: 'GET',
    url: `${config.baseUrl}/projects/product-name/search`,
    params : {
      productType : productType,
      volume: volume
    }
  };

  return axios(request);
}

function getQCellsModelModules() {
  return {
    data: qcellModules
  };
}

function getBatteryInverterPower(productName) {
  const request = {
    method: 'GET',
    url: `${config.baseUrl}/projects/power-class-by-model`,
    params : {
      productName : productName
    }
  };

  return axios(request);
}

function getDetailProject(id) {

  const request = {
    method : 'GET',
    url : `${config.baseUrl}/projects/detail`,
    params : {
      id
    }
  }

  return axios(request)
}

export {
  getBrandsSystemInfo,
  getModelsSystemInfo,
  getBatteryInverterPower,
  getAddress,
  getPowerClass,
  getDetailAddress,
  getProfile,
  getListProjectDraft,
  checkDuplicateAddress,
  getDetailProject,
  getListProject,
  getModelsByPowerClass,
  getQCellsEssModel,
  getQCellsModelModules };
