import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import CustomDatePicker from '../../projects/components/CustomDatePicker';
import useStyles from './useStyles';
import CheckValidate from '../../../common/components/CheckValidate';
import NumberFormat from "react-number-format";
import { projectService } from '../../../services';
import MySelect from '../../projects/components/MySelect';
import _ from 'lodash';
import moment from 'moment';

function PanelsNumberFormat(props) {
  return <NumberFormat {...props} allowNegative={false} />;
}

function ZipCodeNumberFormat(props) {
  return (
    <NumberFormat format="#####" {...props} />
  )
}

function ProjectManualPanel({ next, goBack, type = 2 ,register, control, errors, watch, setValue, handleGoToReview, handleSaveDraft, loading, saleRegions}) {

  const classes = useStyles();
  const [powerClassesModules, setPowerClassesModules] = useState([])
  const [modelModules, setModelModules] = useState([]);
  const volume = watch('projectInverterPower');
  const projectPowerClass = watch('projectPowerClass');
  const projectNumberOfPanel = watch('projectNumberOfPanel');
  const [optionAddress, setOptionAddress] = useState([]);
  const systemSize = watch('projectSystemSize');
  const projectInstallationDate = watch('projectInstallationDate');
  const data  = watch();
  const projectPermissionDate = watch('projectPermissionDate')

  const getQualifyLaborWarranty = () => {
    if(projectPermissionDate && !!systemSize) {
      const perateDateI = moment(projectPermissionDate).add(3, 'M').format('YYYY-MM-DD');
      const dateNow = moment().format('YYYY-MM-DD')
      return moment(perateDateI).isSameOrAfter(dateNow) && _.toNumber(systemSize) <= 25
    }
    return false;
  };



  // useEffect(() => {
  //   //setValue('qualifyLaborWarranty', getQualifyLaborWarranty())
  // }, [systemSize, projectPermissionDate])



  async function getPowerClass() {
    try {
      const { data } = await projectService.getPowerClass('modules');
      setPowerClassesModules(data)
    } catch (error) {
      console.log(error);
    }
  }

  function formatInfo(data) {
    return data?.results?.map(item => {
      return {
        label : item?.formatted_address,
        value : item?.place_id
      }
    })
  }

  async function loadOptionAddress(text) {
    return new Promise(resolve => resolve(getOptions(text)));
  }

  const getOptions = async (inputValue) => {
    if(inputValue !== '') {
      const res = await projectService.getAddress({query : inputValue})
      setOptionAddress(formatInfo(res.data))
      return formatInfo(res.data)
    }
  };

  async function getDetailPlace(placeId) {
    const infoAddressDetail = await projectService.getDetailAddress(placeId)
    if(infoAddressDetail?.data?.result?.address_components?.length > 0) {
      const post_code = infoAddressDetail?.data?.result?.address_components?.filter(item => item?.types[0] === 'postal_code');
      const state = infoAddressDetail?.data?.result?.address_components?.filter(item => item?.types[0] === 'administrative_area_level_1');
      const city = infoAddressDetail?.data?.result?.address_components?.filter(item => item?.types[0] === 'locality');
      const country = infoAddressDetail?.data?.result?.address_components?.filter(item => item?.types[0] === 'country')
      // setValue('projectAddress2Manual', _.get(infoAddressDetail?.data?.result, 'formatted_address', data?.projectAddress2))
      setValue('projectZipCodeManual', post_code?.length > 0 ? post_code[0].short_name : data?.projectZipCode)
      setValue('projectStateManual', state?.length > 0 ? state[0].long_name  : data?.projectState)
      setValue('projectCityManual', city?.length > 0 ? city[0].short_name : data?.projectCity);
      setValue('regionPv', country?.length > 0 ? country[0].long_name : data?.region);
      setValue('latitude', _.get(infoAddressDetail?.data?.result, 'geometry.location.lat', data?.latitude));
      setValue('longitude', _.get(infoAddressDetail?.data?.result, 'geometry.location.lng', data?.longitude))
    }
  }

  const projectAddressManual = watch('projectAddressManual');

  useEffect(() => {
    if((projectAddressManual !== null || projectAddressManual !== '' || projectAddressManual !== undefined)) {
      getDetailPlace(projectAddressManual?.value);
    }
  }, [projectAddressManual?.value])



  async function getModelsByPowerClass(productType, power) {
    try {
      const { data } = await projectService.getModelsByPowerClass(productType, power);
      setModelModules(data)
    } catch (error) {
      console.log(error);
    }
  }

  async function getModuleModels() {
    try {
      const { data } = projectService.getQCellsModelModules();
      setModelModules(data);
    } catch (error) {
      console.log(error);
    }
  }

  function onSelect(option) {
    setValue('projectAddressManual', option)
  }

  // useEffect(() => {
  //   if(projectPowerClass !== null || projectPowerClass !== 'select') {
  //     getModelsByPowerClass('modules', projectPowerClass);
  //   }else {
  //     setModelModules([])
  //   }
  // }, [projectPowerClass])

  useEffect(() => {
    // getPowerClass();
    getModuleModels();
  }, [])




  return(
    <div className={classes.sectionProjectDetail}>
      <div className={classes.sectionContainerProjectManual}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Project Name {''}
            <span>*</span>
          </span>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            name="projectNameSys"
            className={classNames([classes.inputField], {
              [classes.errorInputField]: errors.projectNameSys
            })}
            inputRef={register}
          />
        </div>
        <CheckValidate value={watch('projectNameSys')} error={errors.projectNameSys}/>
      </div>
      <div className={classes.sectionContainerProjectManual}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Address {''}
            <span>*</span>
          </span>
        </div>
        <div>
          <Controller
            name="projectAddressManual"
            key="name-purchasing-channel-projectAddress"
            control={control}
            as={
              <MySelect
                name="addressLookup"
                className="addressLookupContainer"
                label="Address Lookup"
                asyncSelect
                valueAddress={optionAddress}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null
                }}
                loadOptions={loadOptionAddress}
                onSelect={onSelect}
              />
            }
          />

        </div>
        <CheckValidate value={watch('projectAddressManual')} error={errors.projectAddressManual}/>
      </div>
      <div className={classes.sectionContainerProjectManual}>
        <div className={classes.sectionHeaderContainer}>

        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Ste, Unit, Building, Floor, etc"
            name="projectAddress2Manual"
            className={classNames([classes.inputField], {
              // [classes.errorInputField]: errors.projectAddress2
            })}
            inputRef={register}
          />

        </div>
      </div>
      <div className={classes.sectionContainerProjectManual}>
        <div className={classes.sectionHeaderContainer}>
        </div>
        <div>
          <Controller
            name="projectStateManual"
            control={control}
            as={
              <select
                placeholder="State"
                className={classNames([classes.selectField], {
                  [classes.errorSelectField]: errors.projectState
                })}
              >
                <option value={'select'}>State</option>
                {saleRegions.map(pc => {
                  const [id, value] = pc.split(':');
                  return (
                    <option value={value} key={id}>{value}</option>
                  )
                })}
              </select>
            }
          />

        </div>
        <CheckValidate value={watch('projectStateManual')} error={errors.projectAddressManual}/>
      </div>
      <div className={classes.sectionContainerProjectManual}>
        <div className={classes.sectionHeaderContainer}>
        </div>
        <div>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="City"
            name="projectCityManual"
            className={classNames([classes.inputField], {
              [classes.errorInputField]: errors.projectCity
            })}
            inputRef={register}
          />

        </div>
        <CheckValidate value={watch('projectCityManual')} error={errors.projectCityManual}/>
      </div>
      <div className={classes.sectionContainerProjectManual}>
        <div className={classes.sectionHeaderContainer}>
        </div>
        <div>
          <Controller
            name="projectZipCodeManual"
            control={control}
            as={
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Zip Code"
                className={classNames([classes.inputField], {
                  [classes.errorInputField]: errors.projectZipCode
                })}
                InputProps={{
                  inputComponent: ZipCodeNumberFormat
                }}
                inputRef={register}
              />
            }
          />

        </div>
        <CheckValidate value={watch('projectZipCodeManual')} error={errors.projectZipCodeManual}/>
      </div>
      <div className={classes.sectionContainerProjectManual}>
        <div className={classes.sectionHeaderContainer}>
            <span className={classes.sectionTitle}>
                Installation Date {''} <span>*</span>
            </span>
        </div>
        <div>
          <Controller
            name="projectInstallationDate"
            control={control}
            render={(
              { onChange, value }
            ) => (
              <CustomDatePicker
                fullWidth
                size="small"
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                inputVariant="outlined"
                value={value}
                className={classNames([classes.datePicker], {
                  [classes.errorDatePickerField]: errors.projectInstallationDate
                })}
                onChange={date => onChange(date)}
                maxDate={new Date()}
                views={['year', 'month', 'date']}
              />
            )}
          />
        </div>
        <CheckValidate value={watch('projectInstallationDate')} error={errors.projectInstallationDate}/>
      </div>
      <div className={classes.sectionContainerProjectManual}>
        <div className={classes.sectionHeaderContainer}>
            <span className={classes.sectionTitle}>
                Permission-to-Operate Date {''} <span>*</span>
            </span>
        </div>
        <div>
          <Controller
            name="projectPermissionDate"
            control={control}
            render={(
              { onChange, value }
            ) => (
              <CustomDatePicker
                fullWidth
                size="small"
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                inputVariant="outlined"
                value={value}
                className={classNames([classes.datePicker], {
                  [classes.errorDatePickerField]: errors.projectPermissionDate
                })}
                onChange={date => onChange(date)}
                maxDate={new Date()}
                minDate={new Date(projectInstallationDate)}
                views={['year', 'month', 'date']}
              />
            )}
          />
        </div>
        <CheckValidate value={watch('projectPermissionDate')} error={errors.projectPermissionDate}/>
      </div>
      <div className={classes.sectionContainerProjectManual}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              System Size {''}
          </span>
        </div>
        <div>
          <Controller
            name="projectSystemSize"
            rules={{
              required: true
            }}
            control={control}
            as={
              <TextField
                fullWidth
                variant="outlined"
                className={classNames([classes.inputField], {
                })}
                InputProps={{
                  endAdornment: <span>kW</span>,
                  inputComponent: PanelsNumberFormat
                }}
                inputRef={register}
              />
            }
          />
        </div>
      </div>
      <div className={classes.sectionContainerProjectManual}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Power Class of Module {''}
            <span>*</span>
          </span>
        </div>
        <Controller
          name="projectPowerClass"
          control={control}
          as={
            <TextField
              placeholder="Power Class (W)"
              fullWidth
              variant="outlined"
              className={classNames([classes.inputField], {
                [classes.errorInputField]: errors.projectInverterPower
              })}
              InputProps={{
                endAdornment: <span>W</span>,
                inputComponent: PanelsNumberFormat
              }}
              inputProps={{
                maxLength: 3
              }}
            />
            // <select
            //   placeholder="Inverter Power"
            //   className={classNames([classes.selectField], {
            //     [classes.errorSelectField]: errors.projectInverterPower
            //   })}
            // >
            //   <option value={'select'}>Select </option>
            //   {powerClassesModules?.map((item, index) => (
            //     <option key={index} value={item.volume}>
            //       {item.volume}
            //     </option>
            //   ))}
            // </select>
          }
        />
        <CheckValidate value={watch('projectPowerClass')} error={errors.projectPowerClass}/>
      </div>
      <div className={classes.sectionContainerProjectManual}>
        <div className={classes.sectionHeaderContainer}>
          <span className={classes.sectionTitle}>
              Product Model {''}
            <span>*</span>
          </span>
        </div>
        <div>
          <Controller
            name="projectProductName"
            control={control}
            as={
              <select
                placeholder="Model"
                className={classNames([classes.selectField], {
                  [classes.errorSelectField]: errors.projectProductName
                })}
              >
                <option value={'select'}>Select </option>
                {modelModules?.map(item => (
                  <option key={item.id} value={item.productName}>
                    {item.productName}
                  </option>
                ))}
              </select>
            }
          />
        </div>
        <CheckValidate value={watch('projectProductName')} error={errors.projectProductName}/>
      </div>


    </div>
  )
}

export default ProjectManualPanel;
