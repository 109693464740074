import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Text from '../../../common/components/text';
import moment from 'moment';
import parse from 'html-react-parser';

const Container = styled.div `
  position: relative;
  // margin: -30px 0 0 -30px;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  @media (max-width: 400px) {
    margin: 0 0 0 0;
    width: 100%;
  }
`;

const Card = styled.div  `
  position: relative;
  width: 380px;
  height: 380px;
  margin: 30px 0 0 0;
  border: 1px solid #ddd;
  transition: all 0.4s;
  cursor: pointer;
  background-color: ${({ id }) => {
    if (id == 0) return '#009FE2'
    if (id == 2 || id == 3) return '#F4F4F4'
    if (id == 1 || id == 5) return '#FFFFFF'
    if (id == 4) return '#062B55'
  }};

  transition: color 0.25s;
  &:before,
  &:after {
    border: 1px solid transparent;
    width: 0;
    height: 0;
    content: '';
    display: block;
  }

  &:before {
    position:absolute;
    top: 8px;
    left: 8px;
  }

  &:after {
    position:absolute;
    bottom: 8px;
    right: 8px;
  }

  &:hover {
    color: ${({ id }) => [0, 4].includes(id) ? '#fff' : '#ccc'};
  }

  &:hover:before,
  &:hover:after {
    width: calc(100% - 16px);
    height: calc(100% - 16px);
  }

  &:hover:before {
    border-top-color: ${({ id }) => [0, 4].includes(id) ? '#fff' : '#ccc'};
    border-right-color: ${({ id }) => [0, 4].includes(id) ? '#fff' : '#ccc'};
    transition:
      width 0.25s ease-out,
      height 0.25s ease-out 0.25s;
  }

  &:hover:after {
    border-bottom-color: ${({ id }) => [0, 4].includes(id) ? '#fff' : '#ccc'};
    border-left-color: ${({ id }) => [0, 4].includes(id) ? '#fff' : '#ccc'};
    transition:
      border-color 0s ease-out 0.5s,
      width 0.25s ease-out 0.5s,
      height 0.25s ease-out 0.75s;
  }

  @media (max-width: 830px) {
    width: 280px;
    width: 280px;
  }

  @media (max-width: 630px) {
    width: 380px;
    width: 380px;
  }

  @media (max-width: 400px) {
    width: 320px;
    height: 320px;
  }

  @media (max-width: 350px) {
    width: 280px;
    height: 280px;
  }

  @media (max-width: 310px) {
    width: 220px;
    height: 220px;
  }
`
const Content = styled.div `
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 58px 40px;
  // overflow: hidden;
  box-sizing: border-box;
  word-wrap: break-word;
  color: ${({ id }) => {
    if (id == 0 || id == 4) return '#fff'
    if (id == 1 || id == 2 || id == 3 || id == 5)  return '#999999'
  
  }};
`

export default function NewsCard({id, date, title, body, hits, index}) {
  const [width, setWindowWidth] = useState(0);
  const cutBody = width <= 375 ? body.slice(0, 20).concat('...') : body.slice(0, 129).concat('...');

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () =>
      window.removeEventListener('resize',updateDimensions);
   }, [])
   const updateDimensions = () => {
     const width = window.innerWidth
     setWindowWidth(width)
   }

  const productDescHtml = (data) => {
    const parseedData = parse(`${data}`);

    return typeof(parseedData) === 'object' ? parse(`${data}`) : parse(parseedData);
  };

  const cutTitle = () => {
    let splitTitle = title.split(' ').splice(0, 8);
    return splitTitle.join(' ');
  }

  return (
    <Container>
      <Card onClick={() => window.location = `/notice/${id}`} id={index} style={width >= 376 ? {marginBottom: '0'} : {marginBottom: '15px'}}>
        <Content id={index}>
          <Text weight="light" style={{fontSize: '14px'}}>
            {moment(date).format('MM[-]DD[-]YYYY')}
          </Text>
          <Text weight="medium" style={(index == 1 || index == 2 || index == 3 || index == 5) ? {color: '#000', fontSize: '22px', marginTop: '20px', width: '100%'} : {fontSize: '22px', marginTop: '20px', width: '100%'}}>
            {title.length >= 50 ? cutTitle(title) : title}
          </Text>
          <Text weight="light" style={(index == 1 || index == 2 || index == 3 || index == 5) ? {fontSize: '14px', color: '#000', marginTop: '10px'} : {fontSize: '14px', marginTop: '10px'}}>
            {productDescHtml(cutBody)}
          </Text>
          {width <= 375 ? false :
          <Text weight="light" style={{fontSize: '14px', paddingTop: '5vh', position: 'absolute', left: '40px', bottom: '60px'}}>
            Hits {hits}
          </Text>
          }
        </Content>
      </Card>
    </Container>
  )
}
