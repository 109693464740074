import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import { useShoppingCart } from '../../contexts/shoppingCart';
import { useAPIClient } from '../../contexts/api';
import moment from 'moment';

import NavbarDesktop from './NavbarDesktop';
import NavbarMobile from './NavbarMobile';

export default function TopNavBar({ children, showUpdateProfileModal }) {
  const [innerWidth, setInnerWidth] = useState(null);
  const user = useAuth();
  const apiClient = useAPIClient();
  const { numberOfCartItem } = useShoppingCart();

  const currentMonth = moment().month() + 1;
  const currentYear = moment().year();

  const logout = async () => {
    try {
      await apiClient.logout();
    } catch (err) {}
    localStorage.removeItem('qpartner_user');
    window.location = '/';
  };

  useEffect(() => {
    setInnerWidth(window.innerWidth);
    window.addEventListener('resize', function() {
      if( window.innerWidth < 1200 ){
        setInnerWidth(500);
      }else{
        setInnerWidth(1600);
      }
    });
  }, []);

  if (!innerWidth) {
    return <div>Loading navbar</div>;
  } else if (innerWidth < 600) {
    return (
      <NavbarMobile
        currentMonth={currentMonth}
        currentYear={currentYear}
        numberOfCartItem={numberOfCartItem}
        logout={logout}
        user={user}
        showUpdateProfileModal={showUpdateProfileModal}
      />
    );
  } else {
    return (
      <NavbarDesktop
        currentMonth={currentMonth}
        currentYear={currentYear}
        numberOfCartItem={numberOfCartItem}
        logout={logout}
        user={user}
        showUpdateProfileModal={showUpdateProfileModal}
      />
    );
  }
}
