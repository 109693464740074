import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthRoute from '../AuthRoute';
import ProjectRegistration from './ProjectRegistration';
import EditDraftProject from './EditDraftProject';
import EditProject from './EditProject';
import MyProjects from './MyProjects';
import ProjectIntroduction from './ProjectIntroduction';

export default function Projects() {
  return (
    <AuthRoute path="/projects">
      <Switch>
        <Route
          exact
          path="/projects/project-introduction"
          component={ProjectIntroduction}
        />
        <Route
          exact
          path="/projects/project-registration"
          component={ProjectRegistration}
        />
        <Route
          exact
          path="/projects/myprojects"
          component={MyProjects}
        />
        <Route
          exact
          path="/projects/draft/:projectId/:step"
          component={EditDraftProject}
        />
        <Route
          exact
          path="/projects/:projectId/:step"
          component={EditProject}
        />
      </Switch>
    </AuthRoute>
  );
}
