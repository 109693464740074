import React from 'react';
import { Typography } from '@material-ui/core';
import './index.css';
function ItemMobile({title, subTitle, content, subContent, step}) {
  return(
    <div className={`item-page-${step}`}>
      <div className="text-mb-page-one">
        <div className={'title-mb'} style={{fontSize : 24, fontFamily : 'Actual' , fontWeight : 600}}>
          {title}
        </div>
        <span className={'title-mb'} style={{fontSize : 24, fontFamily : 'Actual' , fontWeight : 600}}>
          {subTitle}
        </span>
        <div className="wrap-content-mb">
          <Typography>
            {content}
          </Typography>
          <Typography>
            {subContent}
          </Typography>
        </div>

      </div>
    </div>
  )
}

export default ItemMobile;
