import React from 'react';
import ReactDOM from 'react-dom';
import { Grid, Modal, Paper, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Text from './text';
import CustomButton from './button';
import { useAuth } from '../../contexts/auth';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    modal: {
        width: '40rem',
        height: '50%',
        top: '30px',
        margin: 'auto',
        "& .MuiPaper-root": {
            overflowX: 'hidden'
        },
        [theme.breakpoints.down('md')]: {
            width: '98%',
            overflowY: 'auto'
        }
    },
    header: {
        [theme.breakpoints.down('md')]: {
          fontSize: '20px !important'
        }
    },
    paper: {
        textAlign: 'center',
        backgroundColor: '#ffffff',
        color: '#8C8C8C'
    }
}));

const UpdateProfileModal = ({ isShowing, handleClose }) => {
    const classes = useStyles();
    const user = useAuth();

    return isShowing ? (
        ReactDOM.createPortal(
            <Modal open={isShowing} onClose={handleClose} className={classes.modal}>
                <Paper>
                    <Grid container justify="flex-end">
                        <IconButton onClick={handleClose}>
                            <CloseIcon fontSize="default" />
                        </IconButton>
                    </Grid>
                    <Text
                        style={{ textAlign: 'center', paddingBottom: '30px' }}
                        color="dark"
                        size="h3"
                    >
                        {_.get(user, 'approvalStep') === 1 ? 
                            'Please update your profile to complete registration first. Once admin approves your registration, you will get access to the portal.' 
                            : 'Please update your profile to get access to the portal'}
                    </Text>
        
                    <Grid
                        container
                        justify="center"
                        style={{ paddingBottom: '50px' }}
                    >
                        <Grid item>
                            <CustomButton
                                color="primary"
                                onClick={handleClose}
                            >
                                Update profile
                            </CustomButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>, document.body
        )
    ) : null;
};  

export default UpdateProfileModal;