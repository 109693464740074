import React, { useState } from 'react';
import { Box, TextField } from '@material-ui/core';
import useStyles from './useStyles';
import CustomButton from '../../../common/components/button';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import CustomLoading from '../../../common/components/CustomLoading';
import IconSelect from '../../../icons/project_arrow.svg';
import ReactSelect from 'react-select';
import _ from 'lodash';
import CheckValidate from '../../../common/components/CheckValidate';
import ShowMessage from '../../claim/components/ShowMessage';

const ReactSelectDropdownIndicator = () => {
    return (
      <div style={{ marginRight: '15px' }}>
        <img src={IconSelect} width="12"/>
      </div>
    )
};

export default function PurchasingInfo({ distributors, next, goBack, register, control, watch, errors, isEnablePreviewButton, handleGoToReview, handleSaveDraft, loadingSaveDraft, isEditCompleteProject = false, loading = false, handleSubmit }) {
    const classes = useStyles();
    const selectedPurchaseChannel = watch('purchasingChannel');
    const nameOfPurchasingChannelDistributor  = watch('nameOfPurchasingChannelDistributor');
    const nameOfChanelOtherDistributor = watch('nameOfChanelOtherDistributor');
    const isDuplicateAddress = watch('isDuplicateAddress');

    const [isOpenDistributorMenu, setOpenDistributorMenu] = useState(false);

    const renderCheckValidate = () => {
        switch (selectedPurchaseChannel) {
        case 'qcells':
            return (
                <CheckValidate value={watch('nameOfPurchasingChannelQcells')}/>
            );
        case 'distributor':
            return (
                <CheckValidate value={watch('nameOfPurchasingChannelDistributor')} error={errors.nameOfPurchasingChannelDistributor}/>
            );
        case 'other':
            return (
                <CheckValidate value={watch('nameOfPurchasingChannelOther')} error={errors.nameOfPurchasingChannelOther}/>
            );
        default:
            return (
                <CheckValidate value={watch('nameOfPurchasingChannelSelect')} error={errors.nameOfPurchasingChannelSelect}/>
            )
        }
    }

    const renderOtherDistributor = () => {
        if(nameOfPurchasingChannelDistributor !== null && nameOfPurchasingChannelDistributor.name === 'Other' && selectedPurchaseChannel === 'distributor') {
            return (
              <>
                  <TextField
                    key="nameOfChanelOtherDistributor"
                    name={'nameOfChanelOtherDistributor'}
                    fullWidth
                    inputRef={register}
                    variant="outlined"
                    className={classNames([classes.inputField])}
                  />
                  <CheckValidate value={watch('nameOfChanelOtherDistributor')} error={errors.nameOfChanelOtherDistributor}/>
              </>
            )
        }
    }

    const renderMessageDistributor = () => {
        if(selectedPurchaseChannel === 'distributor') {
            return (
              <ShowMessage textInfo={'If you cannot find your distributor from the list, please type Other.'}/>
            )
        }
    }

    const renderNamePurchasingChannel = () => {
        switch (selectedPurchaseChannel) {
            case 'qcells':
                return (
                    <TextField
                        key="nameOfPurchasingChannelQcells"
                        fullWidth
                        variant="outlined"
                        // className={classes.inputField}
                        className={classNames([classes.inputField], [classes.inputFieldDisabled])}
                        value={"Q CELLS"}
                        disabled
                    />
                );
            case 'distributor':
                return (
                    <div>
                        <Controller
                            name="nameOfPurchasingChannelDistributor"
                            key="name-purchasing-channel-distributor"
                            control={control}
                            as={
                                <ReactSelect
                                    getOptionLabel={option => _.trim(option.name)}
                                    getOptionValue={option => _.trim(option.name)}
                                    isSearchable
                                    isClearable
                                    isMulti={false}
                                    options={distributors}
                                    className={classNames([classes.multiSelect], {
                                        [classes.errorReactSelect]: errors.nameOfPurchasingChannelDistributor
                                    })}
                                    classNamePrefix="select"
                                    placeholder="Type in the distributor name"
                                    defaultMenuIsOpen={false}
                                    menuIsOpen={isOpenDistributorMenu}
                                    onInputChange={(_, { action }) => {
                                        if (action === 'input-change') {
                                            setOpenDistributorMenu(true);
                                        } else if (action === 'menu-close') {
                                            setOpenDistributorMenu(false);
                                        }
                                    }}
                                    components={{
                                    IndicatorSeparator: null,
                                    DropdownIndicator: ReactSelectDropdownIndicator,
                                    }}
                                    styles={{
                                    placeholder: provided => ({
                                        ...provided,
                                        color: '#C4C4C4'
                                    }),
                                    singleValue: provided => ({
                                        ...provided,
                                        fontFamily: 'Actual',
                                        fontSize: '14px',
                                        color: '#333333',
                                        fontWeight: 400,
                                        fontStyle: 'normal'
                                    })
                                    }}
                                />
                            }
                        />
                    </div>
                );
            case 'other':
                return (
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="nameOfPurchasingChannelOther"
                        key="name-purchasing-channel-other"
                        className={classNames([classes.inputField], {
                            [classes.errorInputField]: errors.nameOfPurchasingChannelOther
                        })}
                        inputRef={register}
                    />
                )
            default:
                return (
                    <select
                        name="nameOfPurchasingChannelSelect"
                        className={classes.selectField}
                        disabled
                    >
                        <option>Please Select</option>
                    </select>
                );
        }
    };

    return (
        <div>
            <h1 style={{fontWeight: 500, fontSize: 16}}>Purchasing Information</h1>
            <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }} />
            <div className={classes.sectionContainerSys}>
                <div className={classes.sectionHeaderContainer}>
                    <span className={classes.sectionTitle}>
                        Purchasing <br /> Channel {' '}
                        <span>*</span>
                    </span>
                </div>
                <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                    <Controller
                        name="purchasingChannel"
                        control={control}
                        as={
                            <select
                                className={classNames([classes.selectField], {
                                    [classes.errorSelectField]: errors.purchasingChannel
                                })}
                            >
                                <option value={'select'}>Please Select</option>
                                <option value={'qcells'}>Q CELLS</option>
                                <option value={'distributor'}>Distributor</option>
                                <option value={'other'}>Other</option>
                            </select>
                        }
                    />

                    <CheckValidate value={watch('purchasingChannel')} error={errors.purchasingChannel} />
                </div>
            </div>

            <div className={classes.sectionContainerSys} style={{marginBottom : '-20px'}}>
                <div className={classes.sectionHeaderContainer} style={{alignItems: 'flex-start'}}>
                    <span className={classes.sectionTitle}>
                        Name of Channel {' '}
                        <span>*</span>
                    </span>
                </div>
                <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])}>
                    {renderNamePurchasingChannel()}
                    {renderCheckValidate()}
                </div>
            </div>

            <div className={classes.sectionContainerSys}>
                <div className={classes.sectionHeaderContainer} style={{alignItems: 'flex-start'}}>
                    <span className={classes.sectionTitle}>

                    </span>
                </div>
                <div className={classNames([classes.inputFieldMediumContainer], [classes.addressSection])} style={{marginTop :5}}>
                    {renderOtherDistributor()}
                    {renderMessageDistributor()}
                </div>
                <div></div>
            </div>

            <p
                className={classes.requiredText}
                // style={{ marginTop: '408px' }}
            >* Required</p>
            <div className={classes.separator} style={{ marginTop: '10px', marginBottom: '35px' }}/>
            {isEditCompleteProject ? (
                <Box display="flex" justifyContent="flex-end" className={classes.navigationBtnContainer}>
                    <CustomButton className={`${isDuplicateAddress ? 'saveDraftBtnReview' : ''}`} onClick={handleSubmit} disabled={isDuplicateAddress}>
                        { loading ? <CustomLoading size={16} color="#ffffff" /> : 'Save' }
                    </CustomButton>
                </Box>
            ) : (
                <Box display="flex" justifyContent="space-between" className={classes.navigationBtnContainer}>
                    {isEnablePreviewButton ? (
                        <CustomButton color="secondary" className="saveDraftBtn" onClick={handleGoToReview}>Back to Review</CustomButton>
                    ) : (
                        <CustomButton color="secondary" className="saveDraftBtn" onClick={goBack}>Back</CustomButton>
                    )}
                    <div>
                        <CustomButton color="secondary" className="saveDraftBtn" onClick={handleSaveDraft} disabled={loadingSaveDraft}>
                            { loadingSaveDraft ? <CustomLoading size={16} color="#009EE3" /> : 'Save Draft' }
                        </CustomButton>
                        <CustomButton onClick={next}>Next</CustomButton>
                    </div>
                </Box>
            )}
        </div>
    )
}
