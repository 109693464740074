import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { List, ListItem, ListItemText, Button, Popover } from '@material-ui/core';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import Text from '../components/text';
import _ from 'lodash';
import classNames from 'classnames';

const StyledButton = styled(Button)(
  props => `
  margin-top: 6px;
  height: 57px;
  lineHeight: 57px;
  transform: translateY(-1.5px);
  border-radius: 0;  
  &:hover {
    background-color: '#FFF';
  }

  ${props.open &&
    `
    color: #00ADEF;
    border-bottom: 4px solid #00ADEF;
    font-weight: 700; 
    // border-bottom: solid;
    // border-bottom-color: #00ADEF;
  `}
`
);

const CustomPopover = styled(Popover)`
  & .MuiPaper-rounded {
    border-radius: 0px;
  }

  & .MuiTypography-body2 {
    font-size: 15px;
  }

  & .MuiTypography-body2:hover {
    color: #00adef;
  }

  & .MuiListItem-button:hover {
    background-color: #fff;
  }

  & .MuiPaper-root {
    pointer-events: auto;
    // transform: translateY(15px)!important;
    // margin-top: 100px;
    box-shadow: none;
  }
`;

export default function NavButton({ className, buttonText, links, icon }) {
  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);

  const popoverEnter = ({ currentTarget }) => {
    setOpenedPopover(true);
    popoverAnchor.current.classList.add('foo100');
  };

  const popoverLeave = ({ currentTarget }) => {
    setOpenedPopover(false);
    popoverAnchor.current.classList.remove('foo100');
  };

  const popoverContent = (
    <List dense style={{ width: '264px', textTransform: 'uppercase' }}>
      {links.map((link, index) => {
        return (
          <ListItem
            className={classNames(`navlink ${link.className}`, {
              arrow1: _.get(link, 'arrow'),
              rotate: _.get(link, 'rotate')
            })}
            button
            key={index}
            component={Link}
            to={link.path}
            onClick={link.onClick}
            style={
              link.padding
                ? {
                    color: 'rgb(140,140,140)',
                    height: '31.6px',
                    marginLeft: '25px',
                    borderLeft: 'solid',
                    borderWidth: '0.5px',
                    borderColor: '#DFDFDF',
                    letterSpacing: '-0.1px'
                  }
                : {
                    color: 'rgb(140,140,140)',
                    height: '46px',
                    letterSpacing: '-0.1px'
                  }
            }
          >
            <Text weight="regular">{link.link}</Text>
          </ListItem>
        );
      })}
    </List>
  );
  return (
    <>
      <StyledButton
        className={className}
        ref={popoverAnchor}
        aria-owns="mouse-over-popover-navbar"
        aria-haspopup="true"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
      >
        <Text
          weight="medium"
          style={{
            fontWeight: 500, // medium (navbar level 1)
            fontSize: '15px',
            letterSpacing: '-0.5px',
            paddingLeft: '5px',
            paddingRight: '5px'
          }}
        >
          {buttonText}
        </Text>
      </StyledButton>
      <CustomPopover
        className="gggg"
        id="mouse-over-popover-navbar"
        style={{
          borderRadius: 0,
          pointerEvents: 'none',
          transform: 'translateY(-2px)'
        }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
        disableRestoreFocus={false}
        disableScrollLock={true}
      >
        {popoverContent}
      </CustomPopover>
    </>
  );
}
