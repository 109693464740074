import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Font weights as per defined in the font-face in index.css
// - Allows you to specify the name of the weight instead of a number
const WEIGHT_MAP = {
  light: 100,
  regular: 400,
  medium: 500,
  bold: 700
};

const Typography = styled('p')`
  font-family: ${({ fontFamily }) => fontFamily};
  color: ${({ theme, color }) => theme.colors[color]};
  font-size: ${({ theme, size }) => theme.fontSizes[size]};
  font-weight: ${({ weight }) =>
    weight in WEIGHT_MAP ? WEIGHT_MAP[weight] : weight};
  ${({ inline }) => inline && 'display: inline;'}
  line-height: ${({ theme, lineHeight }) =>
    lineHeight && theme.fontSizes[lineHeight]};
`;

export default function Text({
  style,
  className,
  children,
  weight,
  size,
  color,
  as = 'p',
  fontFamily = 'Actual',
  lineHeight,
  inline
}) {
  return (
    <Typography
      style={style}
      className={className}
      size={size}
      lineHeight={lineHeight}
      color={color}
      as={as}
      weight={weight}
      fontFamily={fontFamily}
      inline={inline}
    >
      {children}
    </Typography>
  );
}

Text.propTypes = {
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'small']),
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']),
  fontFamily: PropTypes.string,
  inline: PropTypes.bool
};
