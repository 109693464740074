import React from 'react';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import Text from '../../../../common/components/text';
import Price from '../../../../common/components/price';

const Container = styled.div`
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 100% !important;
  }
  ${({ fullyRendered, theme }) => `position: relative;
  width: 100%;
  height: fit-content;
  margin-top: 2rem;
  background-color: 
    ${fullyRendered ? '#efefef' : theme.colors.light};

  ${theme.breakpoints.up('md')} {
    width: 100%;
    margin-top: 0;
  } 
  
  ${theme.breakpoints.up('lg')} {
    width: 350px;
  }
  `}
`;

const TitleRow = styled.div`
  padding: 1rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: ${({ emphasized }) => emphasized && '#d3effd'};
`;

const OrderSummary = ({ available, total, shipping, submittedorder, statusAurora, ...props }) => {

  return (
    <Container className="wr_order_summary" {...props}>
      {props.fullyRendered && (
        <>
          <TitleRow>
            <Text as="h4" weight="medium">
              Order Summary
            </Text>
          </TitleRow>
          <Divider variant="middle" />
        </>
      )}
      <Row>
        <Text color="secondaryDark">
          Avaliable Q.POINT {submittedorder ? '' : ''}{' '}
        </Text>
        <Price>{available}</Price>
      </Row>
      <Row emphasized={props.fullyRendered}>
        <Text color="dark" weight="medium">
          Order Total
        </Text>
        <Price color="primaryLight" size="h4" weight="medium">
          {total}
        </Price>
      </Row>
      {statusAurora < 1 && <Row>
        <Text color="secondaryDark">Shipping</Text>
        {shipping === 0 ? (
          <Text color="secondaryDark">FREE</Text>
        ) : (
          <Price>{shipping}</Price>
        )}
      </Row>}
      <Divider variant="middle" />
      <Row>
        <Text color="secondaryDark" weight="medium">
          New Q.POINT Balance
        </Text>
        <Price weight="medium">{available - total - shipping}</Price>
      </Row>
    </Container>
  );
};

export default OrderSummary;
