import React, { useState } from 'react';
import { Box, Button, useMediaQuery } from '@material-ui/core';
import IconEdit from '../../../icons/edit_icon.svg';
import Moment from 'react-moment';
import CustomButton from '../../../common/components/button';
import CustomLoading from '../../../common/components/CustomLoading';
import useStyles from './useStyles';
import _ from 'lodash';
import OpenImageIcon from '../../../config/images/open_image_icon.svg';
import ImageModal from '../../projects/components/ImageModal';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { decodeStringHtml } from '../../../utils/helpers';
import Error from './Error';

function ReviewPv({
  goBack,
  watch,
  goToStep,
  getValues,
  handleSubmit,
  disabled,
  nextStep,
  errorInfo,
  type,
  imagePreviewUrl,
  loading,
  setIsReview,
  errors,
  urlImagePictureOfModule,
  isDraft,
  urlImageProof,
  urlImageProofOfPermisstion,
  urlImageAffectModule,
  urlImageOfVoc,
  urlImageOfFont,
  urlImageOfBack,
  urlImageClose,
  urlImageLayoutPlan,
  urlImageOverall,
  urlImageWireManagement,
  urlImageDelivery,
  urlImageImmed,
  urlImageVmpp,
  urlBankAccountInfo,
  urlImageIr,
  urlImageInvoice,
  urlInvoiceForLaborReimbursement
}) {
  const classes = useStyles();
  const history = useHistory();
  const [modalUrl, setModalUrl] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const data = watch();
  const choseTypeSerialUpload = watch('choseTypeSerialUpload');
  const listSerialPickData = watch('listSerialPick');
  const isUploadProofDate = watch('isUploadProofDate');

  const renderAddress = (array = []) => {
    const newArray = _.remove(array, value => !!value || value === 'select');
    return newArray.length > 0 ? _.join(newArray, ', ') : '';
  };

  const renderInventerType = type => {
    const actions = {
      '1': 'String inverter',
      '2': 'Central inverter',
      '3': 'Optimizer',
      '4': 'Microinverter',
      default: ''
    };

    return actions[type] || actions['default'];
  };

  const renderClaimType = type => {
    const actions = {
      1: 'Power loss/under performing module',
      2: 'Hot spot',
      3: 'Broken glass',
      4: 'Connector damage',
      5: 'Detached cable/junction box damage',
      6: 'Detached frame',
      7: 'Other',
      default: ''
    };

    return actions[parseInt(type)] || actions['default'];
  };

  const renderRegion = type => {
    const actions = {
      1: 'United States',
      2: 'Canada',
      3: 'Latin America',
    };

    return actions[parseInt(type)] || 'United States';
  };

  function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  const renderNameOfPurchasingChannel = () => {
    const purchaseChannel = getValues('purchasingChannel');
    switch (purchaseChannel) {
      case 'qcells':
        return 'Q CELLS';
      case 'distributor':
        return (
          _.get(data, 'nameOfPurchasingChannelDistributor.name') +
          '' +
          `${
            _.get(data, 'nameOfPurchasingChannelDistributor.name') === 'Other'
              ? `-${_.get(data, 'nameOfChanelOtherDistributor', '')}`
              : ''
          }`
        );
      case 'other':
        return getValues('nameOfPurchasingChannelOther');
      default:
        return '';
    }
  };

  const renderPurchasingChannel = purchasingChannelValue => {
    switch (purchasingChannelValue) {
      case 'qcells':
        return 'Q CELLS';
      case 'distributor':
        return 'Distributor';
      case 'other':
        return 'Other';
      default:
        return '';
    }
  };

  function handleBackReview() {
    isDraft && goBack();
    !isDraft && history.push('/claim/history');
  }

  function renderAddressProjectInfo() {
    const infoAddressProject = watch('projectAddress');
    const infoAddressProjectManual = watch('projectAddressManual');
    const choseTypeProject = watch('choseTypeProject');

    if (choseTypeProject === '2') {
      return infoAddressProjectManual?.label || '';
    }

    return infoAddressProject;
  }

  function renderSerialNumber() {
    if (choseTypeSerialUpload === '1') {
      return getValues('serialNumbers')
        ?.map(item => item.serialNumber)
        .join(', ')
        .toString();
    } else {
      return listSerialPickData
        ?.map(item => item.serialNumber)
        .join(', ')
        .toString();
    }
  }

  return (
    <div className={classes.reviewInfoContainer}>
      <div className={classes.reviewSectionsContainer}>
        <div
          className={classNames(
            [classes.reviewSectionContainer],
            [classes.reviewTitleSection]
          )}
        >
          <h5>User Info</h5>
          <Button className={classes.editBtn} onClick={nextStep}>
            <span>Edit</span>
            <img src={IconEdit} title="Edit" />
          </Button>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Company Name</h6>
            <p>{decodeStringHtml(getValues('companyName'))}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Company Address</h6>
            <p>
              {renderAddress([
                data.installerAddress1,
                data.installerAddress2,
                data.installerCity,
                data.installerState,
                data.installerZipcode
              ])}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div
            style={{
              paddingTop: '13px',
              paddingBottom: '13px'
            }}
            className={classes.reviewSectionContent}
          >
            <h6>User Name</h6>
            <p>{data.installerName}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>E-mail </h6>
            <p>{data.installerEmail}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Phone Number</h6>
            <p>{data.installerPhone}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Contact E-Mail</h6>
            <p>{data.submitterEmail || data.installerEmail}</p>
          </div>
        </div>
      </div>
      <div className={classes.reviewSectionsContainer}>
        <div
          className={classNames(
            [classes.reviewSectionContainer],
            [classes.reviewTitleSection]
          )}
        >
          <h5>Issue</h5>
          <Button className={classes.editBtn} onClick={() => goToStep(1)}>
            <span>Edit</span>
            <img src={IconEdit} title="Edit" />
          </Button>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Claim Type</h6>
            <p>
              {!errorInfo?.claimType ? (
                renderClaimType(getValues('claimType'))
              ) : (
                <Error />
              )}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Defect Discovery Date</h6>
            {data.defectDate && <Moment format="L">{data.defectDate}</Moment>}
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Description of Your Issue</h6>
            <p>{!errors.description ? getValues('description') : <Error />}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Project name</h6>
            <p>
              {!errors?.projectNameSys ? getValues('projectNameSys') : <Error />}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Address</h6>
            <p>
              {!errors?.projectAddress && !errors.projectAddressManual ? (
                renderAddressProjectInfo()
              ) : (
                <Error />
              )}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Installation Date</h6>
            <p>
              {!errors?.projectInstallationDate ? (
                data.projectInstallationDate && (
                  <Moment format="L">{data.projectInstallationDate}</Moment>
                )
              ) : (
                <Error />
              )}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Permission-to-Operate Date</h6>
            <p>
              {!errors?.projectPermissionDate ? (
                data.projectPermissionDate && (
                  <Moment format="L">{data.projectPermissionDate}</Moment>
                )
              ) : (
                <Error />
              )}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>System Size (kW)</h6>
            <p>{data.totalPvPowerInstalled}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Product Name</h6>
            <p>
              {!errors?.projectProductName ? (
                getValues('projectProductName')
              ) : (
                <Error />
              )}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Power Class of Module</h6>
            <p>
              {!errors?.projectPowerClass ? (
                getValues('projectPowerClass')
              ) : (
                <Error />
              )}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Proof of Permission-to-Operate Date</h6>
            <div className={classes.reviewSectionContentImage}>
              {errors?.isUploadProofDate && <Error />}
              <div className={classes.images}>
                {urlImageProof?.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className={classes.images}>
                {urlImageProofOfPermisstion.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/*<p>{getValues('other_evidence')}</p>*/}
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Affected Module Serial Number(s)</h6>
            <p>
              {!errors?.serialNumbers && !errors.isUploadSerialNumber ? (
                renderSerialNumber()
              ) : (
                <Error />
              )}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Picture of Affected Module Label(s)</h6>
            <div className={classes.reviewSectionContentImage}>
              <div className={classes.images}>
                {errors.pictureOfModuleLabel && <Error />}
                {urlImageAffectModule.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Region</h6>
            <p>{!errors?.regionPv ? renderRegion(getValues('regionPv')) : <Error />}</p>
          </div>
        </div>
      </div>
      <div className={classes.reviewSectionsContainer}>
        <div
          className={classNames(
            [classes.reviewSectionContainer],
            [classes.reviewTitleSection]
          )}
        >
          <h5>Evidence</h5>
          <Button className={classes.editBtn} onClick={() => goToStep(2)}>
            <span>Edit</span>
            <img src={IconEdit} title="Edit" />
          </Button>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Picture of Front of Module(s)</h6>
            <div className={classes.reviewSectionContentImage}>
              <div className={classes.images}>
                {errors?.pictureOfTheFrontSize && <Error />}
                {urlImageOfFont.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Picture of Back of Module(s)</h6>
            <div className={classes.reviewSectionContentImage}>
              <div className={classes.images}>
                {errors?.pictureOfTheBackSize && <Error />}
                {urlImageOfBack.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/*<p>{getValues('other_evidence')}</p>*/}
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div
            style={{
              paddingTop: '13px',
              paddingBottom: '13px'
            }}
            className={classes.reviewSectionContent}
          >
            <h6>Picture of Voc Reading</h6>
            <div className={classes.reviewSectionContentImage}>
              <div className={classes.images}>
                {errors.pictureOfVocReading && <Error />}
                {urlImageOfVoc.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Close Up Picture of Physical Defect</h6>
            <div className={classes.reviewSectionContentImage}>
              <div className={classes.images}>
                {errors?.closeUpPictureOfPhysicalDefect && <Error />}
                {urlImageClose.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/*<p>{getValues('other_evidence')}</p>*/}
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Site Module Layout Plan</h6>
            <div className={classes.reviewSectionContentImage}>
              <div className={classes.images}>
                {errors?.siteModuleLayoutPlan && <Error />}
                {urlImageLayoutPlan.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Picture of Overall Roof Area</h6>
            <div className={classes.reviewSectionContentImage}>
              <div className={classes.images}>
                {errors.pictureOfOverallRoofArea && <Error />}
                {urlImageOverall.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Picture of Wire Management</h6>
            <div className={classes.reviewSectionContentImage}>
              <div className={classes.images}>
                {errors.pictureOfWireManagement && <Error />}
                {urlImageWireManagement.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Proof-of-Delivery Document</h6>
            <div className={classes.reviewSectionContentImage}>
              <div className={classes.images}>
                {errors.proofOfDeliveryDocument && <Error />}
                {urlImageDelivery.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/*<p>{getValues('other_evidence')}</p>*/}
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Picture of Module Immediately Upon Receipt</h6>
            <div className={classes.reviewSectionContentImage}>
              <div className={classes.images}>
                {errors.pictureOfModuleImmediately && <Error />}
                {urlImageImmed.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/*<p>{getValues('other_evidence')}</p>*/}
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Inverter Type</h6>
            <p>
              {!errors?.systemTypePv ? (
                renderInventerType(getValues('systemTypePv'))
              ) : (
                <Error />
              )}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Picture of Vmpp</h6>
            <div className={classes.reviewSectionContentImage}>
              <div className={classes.images}>
                {errors.pictureOfVmpp && <Error />}
                {urlImageVmpp.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/*<p>{getValues('other_evidence')}</p>*/}
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>IR Picture</h6>
            <div className={classes.reviewSectionContentImage}>
              <div className={classes.images}>
                {errors.irPicture && <Error />}
                {urlImageIr.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/*<p>{getValues('other_evidence')}</p>*/}
          </div>
        </div>
      </div>
      <div className={classes.reviewSectionsContainer}>
        <div
          className={classNames(
            [classes.reviewSectionContainer],
            [classes.reviewTitleSection]
          )}
        >
          <h5>Purchase Info</h5>
          <Button className={classes.editBtn} onClick={() => goToStep(3)}>
            <span>Edit</span>
            <img src={IconEdit} title="Edit" />
          </Button>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Name of Purchasing Channel</h6>
            <p>
              {!errors?.purchasingChannel ? (
                renderPurchasingChannel(data?.purchasingChannel)
              ) : (
                <Error />
              )}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Purchasing Channel Contact Name</h6>
            <p>
              {!errors?.nameOfPurchasingChannelOther &&
              !errors?.nameOfPurchasingChannelDistributor &&
              !errors?.nameOfPurchasingChannelSelect ? (
                renderNameOfPurchasingChannel()
              ) : (
                <Error />
              )}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>E-mail</h6>
            <p>{!errors?.emailPurchase ? getValues('emailPurchase') : <Error />}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Contact Number</h6>
            <p>{getValues('phoneNumber')}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Qcells Sales Manager</h6>
            <p>{getValues('qCellSales')}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>PO Number</h6>
            <p>{!errors.poNumber ? getValues('poNumber') : <Error />}</p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Purchase Date</h6>
            <p>
              {data.purchaseDate && <Moment format="L">{data.purchaseDate}</Moment>}
            </p>
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Copy of Invoice/Receipt</h6>
            <div className={classes.reviewSectionContentImage}>
              <div className={classes.images}>
                {errors.coppyOfInvoice && <Error />}
                {urlImageInvoice.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classes.reviewSectionsContainer}
        style={{ marginBottom: '92px' }}
      >
        <div
          className={classNames(
            [classes.reviewSectionContainer],
            [classes.reviewTitleSection]
          )}
        >
          <h5>Labor Reimbursement</h5>
          <Button className={classes.editBtn} onClick={() => goToStep(3)}>
            <span>Edit</span>
            <img src={IconEdit} title="Edit" />
          </Button>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Invoice for Labor Cost Reimbursement</h6>
            <div className={classes.reviewSectionContentImage}>
              <div className={classes.images}>
                {errors.invoiceForLaborReimbursement && <Error />}
                {urlInvoiceForLaborReimbursement.length === 0 &&
                !errors.invoiceForLaborReimbursement
                  ? 'N/A'
                  : ''}
                {urlInvoiceForLaborReimbursement.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <p>{getValues('poNumber')}</p> */}
          </div>
        </div>
        <div className={classes.reviewSectionContainer}>
          <div className={classes.reviewSectionContent}>
            <h6>Bank Account Information</h6>
            <div className={classes.reviewSectionContentImage}>
              <div className={classes.images}>
                {errors.infoBank && <Error />}
                {urlBankAccountInfo.length === 0 && !errors.infoBank ? 'N/A' : ''}
                {urlBankAccountInfo.map(p => (
                  <div key={p.url}>
                    <img src={p.url} className={classes.image} />
                    <div className={classes.overlay} role="overlay">
                      <img
                        src={OpenImageIcon}
                        onClick={() => {
                          setModalUrl(p.url);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.navigationBtnContainer}
      >
        <CustomButton
          color="secondary"
          className="saveDraftBtn"
          onClick={handleBackReview}
        >
          Back
        </CustomButton>
        <CustomButton
          className={`${!isEmpty({ ...errors, ...errorInfo }) &&
            'saveDraftBtnReview'}`}
          onClick={handleSubmit}
          disabled={!isEmpty({ ...errors, ...errorInfo }) || loading}
        >
          {loading ? <CustomLoading size={18} color="#fff" /> : 'Submit'}
        </CustomButton>
      </Box>
      <ImageModal
        open={openModal}
        url={modalUrl}
        name={modalUrl ? modalUrl.substring(modalUrl.lastIndexOf('/') + 1) : ''}
        handleClose={() => {
          setOpenModal(false);
          setModalUrl('');
        }}
      />
    </div>
  );
}

export default ReviewPv;
