import React from 'react';
import { withStyles, lighten } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Text from '../../../common/components/text';

import { Grid } from '@material-ui/core';

const BorderLinearProgress = withStyles({
  root: {
    width: '100%',
    height: 12,
    borderRadius: '20px',
    // backgroundColor: lighten('#E6E6E6', 0.5)
    backgroundColor: '#e6e6e6'
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#00ADEF'
  }
})(LinearProgress);

export default function ForecastsUploadProgressBar({
  progress,
  goal,
  showVolume = false,
  volume = 0,
  showSuffix = true
}) {
  const ItemProgressBar = ({ progress, goal, volume }) => {
    let num = Math.round((100 - (progress / goal) * 100) * 100) / 100;
    let completed = 100 - num;
    let value = completed > 100 ? 100 : completed;
    return (
      <>
        <div className="foo902">
          <div className="foo900" style={{ position: 'relative' }}>
            {' '}
            <BorderLinearProgress
              variant="determinate"
              value={value}
              color="secondary"
            />
            {showVolume === true ? (
              <div className="foo800" style={{ transform: `translateX(${value}%)` }}>
                <div className="inner">
                  <div className="volume">{progress.toLocaleString()} W</div>
                </div>
              </div>
            ) : (
              <div className="foo802">
                <div
                  className="volumeright"
                  style={{ color: value === 100 ? 'white' : '#131313' }}
                >
                  {volume.toLocaleString()} { showSuffix ? "W" : "" }
                </div>
              </div>
            )}
          </div>
          <div className="foo901">
            <Text size="h6" weight="light" style={{ display: 'inline' }}>
              {Math.round(completed)}%
            </Text>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <ItemProgressBar progress={progress} goal={goal} volume={volume} />
    </>
  );
}
