import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthRoute from '../AuthRoute';
import Notice from './notice';
import News from './news';

export default function Modules() {
  return (
    <AuthRoute path="/notice">
      <Switch>
        <Route
          exact
          path="/notice"
          component={Notice}
        />
        <Route
          exact
          path="/notice/:newsId"
          component={News}
        />
      </Switch>
    </AuthRoute>
  );
}
