import React, { useState } from 'react';
import ShowMessageUpdate from './ShowMessageUpdate';
import ImageUploader from './UploadImage';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  TextareaAutosize
} from '@material-ui/core';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaFormUpdate } from './schemaFormUpdate';
import useStyles from './useStyles';
import CloseIcon from '@material-ui/icons/Close';
import Text from '../../../common/components/text';
import CustomButton from '../../../common/components/button';
import CustomLoading from '../../../common/components/CustomLoading';
import _ from 'lodash';
import { claimService } from '../../../services';
import { notiFailed, notiSuccess } from '../../../utils/helpers';

function FormUpDateInfoClaim({ text, keyUpload, setKeyUpload, id }) {
  const { register, errors: errors, control, watch, clearErrors } = useForm({
    resolver: yupResolver(schemaFormUpdate),
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    shouldFocusError: false,
    enableReinitialize: true,
    defaultValues: {
      isUploadFileUpdate: '',
      evidence_description: ''
    }
  });
  const [fileUpdate, setFileUpdate] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const data = watch();

  async function handleUpdateNote() {
    try {
      setLoadingEdit(true);
      await claimService.updateClaim({
        attachments: _.map(fileUpdate, 'url').toString() || '',
        id,
        evidence_description: data.evidence_description
      });
      setFileUpdate([]);
      notiSuccess('Update evidence successfully !');
    } catch (error) {
      notiFailed('Update evidence failed !');
    } finally {
      setLoadingEdit(false);
      setOpen(false);
    }
  }

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <ShowMessageUpdate
          content1={
            'Qcells administrator requests that you provide more evidence as specified below:'
          }
          content2={text}
        />
      </div>
      <div style={{ marginBottom: 10 }}>
        <ImageUploader
          files={fileUpdate}
          setFiles={setFileUpdate}
          fileName={'file_update_evidence.jpg'}
          keyUpload={keyUpload}
          setKeyUpload={setKeyUpload}
          errors={errors.isUploadProofDate}
          clearErrors={clearErrors}
          name={'file_update_evidence'}
        />
      </div>

      {/*<Controller*/}
      {/*  name="evidence_description"*/}
      {/*  control={control}*/}
      {/*  render={(*/}
      {/*    { onChange, value }*/}
      {/*  ) => (*/}
      {/*    <TextareaAutosize*/}
      {/*      name="evidence_description "*/}
      {/*      onChange={(value) => onChange(value)}*/}
      {/*      aria-label="minimum height"*/}
      {/*      value={value}*/}
      {/*      className={classNames([classes.inputTextArea], {*/}
      {/*        //[classes.inputTextAreaError]: errors.description*/}
      {/*      })} placeholder="Description"/>*/}
      {/*  )}*/}
      {/*/>*/}

      <Button
        style={{ marginLeft: 0 }}
        disabled={fileUpdate.length === 0}
        className={fileUpdate.length === 0 ? classes.btnDisible : classes.editBtn}
        onClick={() => setOpen(true)}
      >
        <span>Submit</span>
      </Button>

      <Modal onClose={() => setOpen(false)} open={open} className={classes.modal}>
        <Paper>
          <Grid container justify="flex-end">
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon fontSize="default" />
            </IconButton>
          </Grid>
          <Text
            style={{ textAlign: 'center', paddingBottom: '30px' }}
            color="dark"
            size="h2"
            className={classes.header}
          >
            Are you sure you?
          </Text>

          <Grid container justify="center" style={{ paddingBottom: '50px' }}>
            <Grid item style={{ marginRight: '10px' }}>
              <CustomButton color="secondary" onClick={() => setOpen(false)}>
                No
              </CustomButton>
            </Grid>
            <Grid item>
              <CustomButton
                color="primary"
                onClick={handleUpdateNote}
                disabled={loadingEdit}
              >
                {loadingEdit ? <CustomLoading size={16} color="#fff" /> : 'Yes'}
              </CustomButton>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
}

export default FormUpDateInfoClaim;
