import React from 'react';
import IconMatch from '../../icons/match.svg';
import IconRequired from '../../icons/required.svg';
import { IconButton } from '@material-ui/core';

function CheckValidate({value, error = undefined}) {

  const iconResult = ((!!error || value === null  || value === '' || value === 'select' || value?.length === 0) ? IconRequired : IconMatch);

  return(
    <div>
      <IconButton
        size="small"
        style={{marginLeft : 5}}
        aria-haspopup="true"
      >
        <img src={iconResult} />
      </IconButton>
    </div>
  )
}

export default CheckValidate;
