import React, { useState, useCallback, useEffect } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import styled from 'styled-components';
import Counter from '../../../../common/components/input/Counter';
import Price from '../../../../common/components/price';
import Text from '../../../../common/components/text';
import { debounce } from 'lodash';
import _ from 'lodash';
import defaultImage from '././../../../../icons/product_default_image.png';
import { useShoppingCart } from './../../../../contexts/shoppingCart';
import classNames from 'classnames';
import { Checkbox, FormControl, IconButton, InputLabel, MenuItem, Popover, Select, TextField } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { VISA_CARD_PRODUCTS_KEYWORD } from './../../../../utils/constants';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Link, useHistory } from 'react-router-dom';
import './index.css';

import moment from 'moment-timezone';
moment.tz.setDefault("America/Los_Angeles");

const useStyles = makeStyles(theme => ({
  consolidateCheckboxLabel: {
    whiteSpace: 'nowrap',
    fontSize: '16px',
    display:'flex',
    alignItems: 'center',
    justifyContent : 'space-between'

  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '60%',
    padding: '0px 10px 0px 30px'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  popover: {
    pointerEvents: 'none',
  },
  poppaper: {
    padding: theme.spacing(1),
    backgroundColor: '#e5f3fb',
    boxShadow: 'none',
    border: 'solid',
    width: 400,
    borderWidth: '1px',
    borderColor: '#00ADEF',
    color: '#00ADEF'
  },
  consolidateHelperText: {
    marginLeft: '28px',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    marginTop: '-10px'
  },

}));

const Row = styled.div`
  display: flex;
  //justify-content: space-between;
  width: 100%;
  padding: 1rem 0;
`;

const Image = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
  border: 1px solid #eee;
  border-radius: 5px;
`;

const CartProduct = ({ product, onUpdate, onRemove, balance, statusCard }) => {
  const classes = useStyles();
  const {
    listCartIdThatQuantityGreaterThanStock,
    set_listCartIdThatQuantityGreaterThanStock
  } = useShoppingCart();
  const [consolidate, setConsolidate] = useState(product.consolidate);
  const [isRecurring, setIsRecurring] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [purchaseEvery, setPurchaseEvery] = useState(null)
  const [statusAnchorEl, setStatusAnchorEl] = useState(false);
  const history = useHistory();
  const [nextDay, setNextDay] = useState(moment(new Date()).format('MM/DD/YY'));

  const handleStatusPopoverOpen = event => {
    setStatusAnchorEl(event.currentTarget);
  };

  const handleStatusPopoverClose = () => {
    setStatusAnchorEl(null);
  };


  const openStatusPopOver = Boolean(statusAnchorEl);


  useEffect(() => {
    setIsRecurring(!!product.purchaseEvery);
    setPurchaseEvery(product.purchaseEvery);
    setQuantity(product.quantity);
  },[])

  useEffect(() => {
    const nextDayPayment = moment().add(purchaseEvery, 'months').format('MM/DD/YY');
    setNextDay(nextDayPayment);
  }, [purchaseEvery])

  React.useEffect(() => {
    let cartId = _.get(product, 'id');
    if (
      product.quantity - product.variant.stock > 0 &&
      product.variant.unlimited !== true
    ) {
      if (!listCartIdThatQuantityGreaterThanStock.includes(cartId)) {
        let new_listCartIdThatQuantityGreaterThanStock = [
          ...listCartIdThatQuantityGreaterThanStock,
          cartId
        ];
        set_listCartIdThatQuantityGreaterThanStock(
          new_listCartIdThatQuantityGreaterThanStock
        );
      }
    } else {
      if (listCartIdThatQuantityGreaterThanStock.includes(cartId)) {
        let listCartIdThatQuantityGreaterThanStock2 = listCartIdThatQuantityGreaterThanStock.filter(
          item => item !== cartId
        );
        set_listCartIdThatQuantityGreaterThanStock(
          listCartIdThatQuantityGreaterThanStock2
        );
      }
    }
  }, []);

  let qpoints =
    _.get(product, 'variant.qpoints') || _.get(product, 'product.prices') || 0;
  const [currBalance, set] = useState(balance - qpoints);

  const updateQuantity = count => {
    let isConsolidate = consolidate;
    if (count === 1) {
      isConsolidate = false;
    }
    setQuantity(count);
    setConsolidate(isConsolidate);

    onUpdate(product, count, isConsolidate, product.purchaseEvery);
  };



  const updatePurseChase = event => {
    setPurchaseEvery(event.target.value);
    onUpdate(product, product.quantity, consolidate, event.target.value);
  }

  function handleChaneRecurring(event) {
    setIsRecurring(event.target.checked);
    if(!!product.purchaseEvery && !event.target.checked) {
      onUpdate(product, quantity, consolidate, null);
    }else {
      onUpdate(product, quantity, consolidate, _.get(product, 'product.purchaseEvery'));
    }
  }

  const updateQuantityDebounced = useCallback(debounce(updateQuantity, 10), []);

  const updatePurseChaseDebounced = useCallback(debounce(updatePurseChase, 10), []);

  const handleConsolidate = async (product, count, consolidate) => {
    setConsolidate(!consolidate);
    onUpdate(product, count, !consolidate);
  };

  const handleIncrease = () => {
    set(prev => prev - product.qpoints);
  };

  const handleDescrease = () => {
    set(prev => prev + product.qpoints);
  };
  let src = _.get(
    product,
    'variantImages[0].imageUrl',
    _.get(product, 'variantImages[0].image_url')
  );
  if (!src) {
    src = defaultImage;
  } else {
    if (src.indexOf('http') === 0) {
    } else {
      src = `${process.env.REACT_APP_QPARTNER_LEGACY_IMAGE_BASE_URL}${src}`;
    }
  }

  return (
    <Row
      className={classNames('onecartitem', {
        greater_than_stock: listCartIdThatQuantityGreaterThanStock.includes(
          _.get(product, 'id')
        ),
        no_greater_than_stock: !listCartIdThatQuantityGreaterThanStock.includes(
          _.get(product, 'id')
        )
      })}
    >
      {product && product.variantImages.length && (
        <div style={{cursor: 'pointer'}} onClick={() => history.push(`/rewards/shop/${_.get(product, 'product.id')}`)}>
          <Image style={{ marginRight: '20px' }} src={src}/>
        </div>
      )}
      {/* <Image
        src={`${process.env.REACT_APP_QPARTNER_LEGACY_IMAGE_BASE_URL}${product?.productImages[0]?.imageUrl}`}
      /> */}

      <div className="wrap-center-item-cart">
        <div className="cart_item_name" style={{ flexGrow: 0 }}>
          <Link style={{color : 'black'}} to={`/rewards/shop/${_.get(product, 'product.id')}`}>
            <Text
              className="cart_item_name_1"
              size="h4"
              weight="medium"
              style={{marginBottom: '1rem', marginTop: '0.2rem' }}
            >
              {product?.product?.name}
            </Text>
          </Link>

          {product?.variant?.custom && <span className="cart-color">Option: {product?.variant.custom}</span>}

          {_.toLower(product.product.name).includes(VISA_CARD_PRODUCTS_KEYWORD) && product.quantity > 1 &&
          (<>
            <FormControlLabel
              control={
                <Checkbox
                  checked={consolidate}
                  size="small"
                  style={{
                    color: '#000000'
                  }}
                  onChange={() => handleConsolidate(product, product.quantity, consolidate)}
                />
              }
              label={
                <Text
                  weight="medium"
                  fontFamily="Actual_Light"
                  className={classes.consolidateCheckboxLabel}
                >
                  Consolidate multiple cards into one
                </Text>
              }
            />

            <Text
              weight="medium"
              color="secondaryDark"
              fontFamily="Actual_Light"
              className={classes.consolidateHelperText}
            >
              e.g., if 2 cards are consolidated, you will recieve 1 card of $
              {Number(_.get(product, 'product.prices') * 2 / 100).toLocaleString()}
            </Text>
          </>)}

          <Text weight="medium" style={{ color: 'grey' }}>
            {_.get(product, 'variant.color') && (
              <span className="cart-color">
              Color: {_.get(product, 'variant.color')}
            </span>
            )}
            {_.get(product, 'variant.size') && (
              <span className="cart-size">Size: {_.get(product, 'variant.size')}</span>
            )}
          </Text>
        </div>
        <Price
          className="price100"
          style={{ flexGrow: 0, minWidth: '85px', marginRight : 7 }}
          color="primaryLight"
          size="h4"
          weight="medium"
        >
          {product?.variant?.qpoints}
        </Price>
        <Counter
          isCallApi={true}
          style={{ flexGrow: 1 }}
          min={1}
          value={product.quantity}
          preIncreaseCheck={currBalance > 0}
          onIncrease={handleIncrease}
          onDecrease={handleDescrease}
          onChange={updateQuantityDebounced}
        />
        {_.get(product, 'variant.unlimited') === false && (
          <div className="stock3">Stock: {_.get(product, 'variant.stock')}</div>
        )}

        {product?.variant?.custom &&
        <div className="select-recurring">
          <div className={'wrapContainer'}>
            <label className="container-aurora">
              <input onChange={handleChaneRecurring} type="checkbox" checked={isRecurring}/>
              <span className="checkmark"></span>
            </label>
            <Text
              weight="medium"
              fontFamily="Actual_Light"
              className={classes.consolidateCheckboxLabel}
            >
              Set up recurring payment

            </Text>
            <IconButton
              size="small"
              aria-owns={openStatusPopOver ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handleStatusPopoverOpen}
              onMouseLeave={handleStatusPopoverClose}
            >
              <InfoOutlinedIcon
                style={{ color: 'black', display: 'inline', fontSize : 18 }}
              />
            </IconButton>
            <Popover
              disableScrollLock={true}
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.poppaper
              }}
              open={openStatusPopOver}
              anchorEl={statusAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'top'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              onClose={handleStatusPopoverClose}
              disableRestoreFocus
            >
              <Text weight="light" size="h5">
                Your order will be placed automatically at your selected time period.
              </Text>
              <Text style={{marginTop : 10}} weight="light" size="h5">
                e.g., If you set up recurring payment on 4/1/2021, your next purchase will be scheduled on 5/1/2021 (for monthly), or 4/1/2022 (for annual).
              </Text>
              <Text style={{marginTop: 10}} weight="light" size="h5">
                If you do not have enough balance on the scheduled payment date, your payment will roll over to the next payment date.
              </Text>

              <Text style={{marginTop: 10}} weight="light" size="h5">
                You can cancel your recurring payment at any time on the Order History page.
              </Text>
            </Popover>
          </div>



          {isRecurring &&
          <>
            <Text style={{ padding: '10px 10px 0px 27px', fontWeight: 300, fontSize: 14, color: '#8c8c8c' }}
                  id="demo-simple-select-label">Purchase every:</Text>

            <Text style={{ padding: '0px 10px 0px 27px', fontWeight: 300, fontSize: 14, color: 'rgb(43 173 239)' }}
                  id="demo-simple-select-label"> {_.get(product, 'product.purchaseEvery') === 1 ? 'Monthly' : 'Annually'}</Text>

            <Text style={{ padding: '10px 10px 0px 27px', fontWeight: 300, fontSize: 12, color: '#8c8c8c'}}
                  id="demo-simple-select-label">
              Your next payment will be scheduled on {nextDay}.</Text>

          </>}
        </div>}
      </div>


      <ClearIcon
        className={'remove-item'}
        style={{ cursor: 'pointer', fill: 'var(--secondary-dark-color, #8c8c8c)' }}
        onClick={() => onRemove(product)}
      />
    </Row>
  );
};

export default CartProduct;
