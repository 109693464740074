import React from 'react';
import Box from '@material-ui/core/Box';

const CustomBox = ({
    type,
    width,
    className,
    children,
  }) => {
    if (type === "1") {
        if (width >= 900) {
            return (<Box borderBottom={1} borderRight = {1} className = {className}>{children}</Box>);
          }
            return (<Box borderBottom={1} borderRight = {1} borderLeft = {1} className = {className}>{children}</Box>);
    }
    if (type === "2") {
        if (width >= 1200) {
            return <Box borderBottom={1} borderRight = {1} className = {className}>{children}</Box>
          }
            return <Box borderBottom={1} borderRight = {1} borderLeft = {1} className = {className}>{children}</Box>
    }
  }

  export default CustomBox;