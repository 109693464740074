import { toast } from 'react-toastify';
import { Grid } from '@material-ui/core';
import ToastImg from './../config/images/toast-icon.png';
import ToastImgFailed from './../config/images/toast-icon-failed.png';
// import ToastImgLabor from './../config/images/';
import PopoverIcon from './../config/images/labor.svg'
import React from 'react';
import { format } from 'date-fns';

export const notiSuccess = content => {
  toast(
    () => (
      <div className="noti_body">
        <div className="noti_img">
          {' '}
          <img src={ToastImg} alt="" />{' '}
        </div>
        <div className="noti_content">{content}</div>
      </div>
    ),
    {
      className: 'toast-body',
      progressClassName: 'toast-progress-bar'
    }
  );
};

export const notiLabor = content => {
  toast(
    () => (
      <div className="noti_body">
        <div className="noti_img">
          {' '}
          <img src={PopoverIcon} alt="" />{' '}
        </div>
        <div className="noti_content">{content}</div>
      </div>
    ),
    {
      className: 'toast-body',
      progressClassName: 'toast-progress-bar'
    }
  );
}

export const notiFailed = content => {
  toast(
    () => (
      <div className="noti_body">
        <div className="noti_img">
          {' '}
          <img src={ToastImgFailed} alt="" />{' '}
        </div>
        <div className="noti_content">{content}</div>
      </div>
    ),
    {
      className: 'toast-error-body',
      progressClassName: 'toast-error-progress-bar'
    }
  );
};

export const setWithExpiry = (key, value, ttl) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = key => {
  const itemStr = localStorage.getItem(key);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const getDateWithZeroTime = (date = new Date()) => {
  let dateString = format(date, 'MM/dd/yyyy 00:00:00');
  return new Date(dateString);
};

export const getDateStringWithTimeZoneCaculated = dateString => {
  let timeZoneOffsetInMinutes = new Date().getTimezoneOffset();
  let timeZoneOffset = timeZoneOffsetInMinutes / 60;
  let date = new Date(dateString);
  date.setHours(date.getHours() + timeZoneOffset);
  let result = format(date, 'MM/dd/yyyy HH:mm:ss');
  return result;
};

export const convertHTMLEntity = str => {
  return String(str)
    .replace(/&amp;/g, '&')
    .replace(/&nbsp;/g, ' ')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"');
};

export const decodeStringHtml = (string) => {
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(`<!doctype html><body>${string}`, 'text/html').body.textContent;

  return decodedString || '';
}

export const suggestionImageClaimPv = () => {

}

export const convertArrayClaim = (
  filePictureOfAffectedModule, filePictureOfFront, filePictureOfBack,
  filePictureOfVoc, filePictureCloseUp, filePictureSitePlan,
  filePictureOfOverall, filePictureOfWireManagement, filePictureProofDelivery,
  filePictureImmediately, filePictureInVoice, fileProofOfPermissionToOperateDate,
  fileInvoiceForLaborReimbursement, fileBankAccountInfo, filePermissionOfDate,
  filePictureOfVmpp, filePictureIr
) => {


  const attackBank = fileBankAccountInfo.length > 0 && fileBankAccountInfo.map(item => {
    return item.url
  })

  const attackInvoiceLabor = fileInvoiceForLaborReimbursement.length > 0 && fileInvoiceForLaborReimbursement.map(item => {
    return item.url
  })

  const attackProofOfPer = fileProofOfPermissionToOperateDate.length > 0 && fileProofOfPermissionToOperateDate.map(item => {
    return item.url
  }, []);

  const attackAffectedModule = filePictureOfAffectedModule.length > 0 && filePictureOfAffectedModule.map(item => {
    return item.url
  }, []);

  const attackAffectedVoc = filePictureOfVoc.length > 0 && filePictureOfVoc.map(item => {
    return item.url
  }, []);

  const attackOfFront = filePictureOfFront.length > 0 && filePictureOfFront.map(item => {
    return item.url
  }, [])

  const attackOfBack = filePictureOfBack.length > 0 && filePictureOfBack.map(item => {
    return item.url
  }, [])

  const attackCloseUp = filePictureCloseUp.length > 0 && filePictureCloseUp.map(item => {
    return item.url
  }, [])

  const attackSitePlan = filePictureSitePlan.length > 0 && filePictureSitePlan.map(item => {
    return item.url
  }, [])

  const attackOverall = filePictureOfOverall.length > 0 && filePictureOfOverall.map(item => {
    return item.url
  }, [])

  const attackWireManage = filePictureOfWireManagement.length > 0 && filePictureOfWireManagement.map(item => {
    return item.url
  }, [])

  const attackProofDelivery = filePictureProofDelivery.length > 0 && filePictureProofDelivery.map(item => {
    return item.url
  }, [])

  const attackImmediately = filePictureImmediately.length > 0 && filePictureImmediately.map(item => {
    return item.url
  }, [])

  const attackFilePermissionOfDate = filePermissionOfDate.length > 0 && filePermissionOfDate.map(item => {
    return item.url
  });

  const attackFileVmpp = filePictureOfVmpp.length > 0 && filePictureOfVmpp.map(item => {
    return item.url
  });

  const attackIr = filePictureIr.length > 0 && filePictureIr.map(item => {
    return item.url
  })



  // const attackVmpp = filePictureOfVmpp.length > 0 && filePictureOfVmpp.map(item => {
  //   return item.url
  // }, [])

  // const attackIr = filePictureIr.length > 0 && filePictureIr.map(item => {
  //   return item.url
  // }, [])


  const attackInVoice = filePictureInVoice.length > 0 && filePictureInVoice.map(item => {
    return item.url
  }, [])

  let combineFile = [];

  attackProofOfPer.length > 0 && combineFile.push(attackProofOfPer);
  attackAffectedModule.length > 0 && combineFile.push(attackAffectedModule);
  attackAffectedVoc.length > 0 && combineFile.push(attackAffectedVoc);
  attackOfFront.length > 0 && combineFile.push(attackOfFront);
  attackOfBack.length > 0 && combineFile.push(attackOfBack);
  attackCloseUp.length > 0 && combineFile.push(attackCloseUp);
  attackSitePlan.length > 0  && combineFile.push(attackSitePlan);
  attackOverall.length > 0 && combineFile.push(attackOverall);
  attackWireManage.length > 0 && combineFile.push(attackWireManage);
  attackProofDelivery.length > 0 && combineFile.push(attackProofDelivery);
  attackImmediately.length > 0 && combineFile.push(attackImmediately)
  attackFilePermissionOfDate.length > 0 && combineFile.push(attackFilePermissionOfDate);
  attackFileVmpp.length > 0 && combineFile.push(attackFileVmpp);
  attackIr.length > 0 && combineFile.push(attackIr);
  //attackVmpp.length > 0 && combineFile.push(attackVmpp);
  //attackIr.length > 0 && combineFile.push(attackIr);
  attackInVoice.length > 0 && combineFile.push(attackInVoice);
  attackInvoiceLabor.length > 0 && combineFile.push(attackInvoiceLabor)
  attackBank.length > 0 && combineFile.push(attackBank);
  console.log(combineFile.toString());

  return combineFile;


}
