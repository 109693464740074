import React from 'react';
import BreadCrumb2 from './../../common/components/BreadCrumb2';
import Banner from '../../common/components/Banner';
import MyPageBanner from '../../config/images/sitemap-banner.jpg';
import {makeStyles} from '@material-ui/core/styles';
import Text from '../../common/components/text';
import {Link} from 'react-router-dom';
import moment from 'moment';
import axios from '../../utils/axios';
import config from '../../config';
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  pageContainer: {
    paddingTop: '52px',
    width: '65%',
    margin: '0 auto',
    transition: 'all 0.4s',
    marginBottom: '100px',
    [theme.breakpoints.down('md')]: {
      width: '90%'
    }
  },
  pageTitle: {
    color: '#000',
    fontSize: '26px',
    fontWeight: 500,
    lineHeight: '26px',
    textTransform: 'uppercase',
    marginBottom: '36px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px !important'
    }
  },
  sitemap: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '15px 15px',
    '& h1': {
      position: 'relative',
      marginBottom: '45px',
      color: '#000',
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '28px'
    },
    '& h1::after': {
      display: 'block',
      position: 'absolute',
      left: 0,
      bottom: '-20px',
      width: '36px',
      height: '3px',
      background: '#009ee3',
      content: '""'
    },
    '& li': {
      marginTop: '6px',
      textTransform: 'uppercase'
    },
    '& a': {
      display: 'inline-block',
      position: 'relative',
      padding: '2px 0',
      color: '#666',
      fontSize: '15px',
      lineHeight: '20px'
    },
    '& a:hover::before': {
      width: '100%'
    },
    '& a::before': {
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: 0,
      height: '1px',
      background: '#666',
      content: '""',
      transition: 'width 0.6s'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr'
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr'
    }
  },
  sitemapSub: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '15px 15px'
  },
  sitemapItem: {
    position: 'relative',
    height: '360px',
    padding: '50px 45px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
    fontWeight: 500
  },
  sitemapSubItem: {
    position: 'relative',
    height: '360px',
    padding: '50px 36px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
    fontWeight: 500
  }
}));

const {baseUrl} = config;

export default function SiteMap() {
  const classes = useStyles();
  const currentMonth = moment().month() + 1;
  const currentYear = moment().year();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleThinkificSSO = async (e, return_url) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const {data} = await axios.post(`${baseUrl}/loginThinkific`);
      const {token} = JSON.parse(localStorage.getItem('qpartner_user'));
      const returnUrl = encodeURIComponent(
        `/pages/academyredirect?qpartnerToken=${token}&rd=${return_url}`
      );
      window.location.href = `${process.env.REACT_APP_THINKIFIC_URL}/api/sso/v2/sso/jwt?jwt=${data.tokenThinkific}&return_to=${returnUrl}&error_url=/404`;
    } catch (err) {
      window.location.href = `${process.env.REACT_APP_THINKIFIC_URL}${return_url}`;
    }
  };

  return (
    <>
      <Banner
        imageUrl={MyPageBanner}
        className="OrderHistory_Banner banner100"
      >
        <h1 className="title_animation OrderHistory_Title">SITEMAP</h1>
        <BreadCrumb2 items={['Sitemap']}></BreadCrumb2>
      </Banner>

      <div className={classes.pageContainer}>
        <Text className={classes.pageTitle}>
          Sitemap
        </Text>

        <div className={classes.sitemap}>
          <div className={classes.sitemapItem}>
            <h1>ABOUT Q.PARTNER</h1>
            <ul>
              <li><Link to="/about/program-introduction">Program Introduction</Link></li>
            </ul>
          </div>
          <div className={classes.sitemapItem}>
            <h1>TRAINING</h1>
            <ul>
              <li><Link onClick={e => handleThinkificSSO(e, '/collections/nabcep')}>Nabcep</Link></li>
              <li><Link onClick={e => handleThinkificSSO(e, '/collections/ess')}>Ess</Link></li>
              <li><Link onClick={e => handleThinkificSSO(e, '/collections/webinar')}>Webinar</Link></li>
              <li><Link onClick={e => handleThinkificSSO(e, '/collections/offline')}>Offline</Link></li>
            </ul>
          </div>
          <div className={classes.sitemapItem}>
            <h1>SALES TOOLS</h1>
            <ul>
              <li><Link to="/salestools/data-hub/download-center">Data Hub</Link></li>
            </ul>
          </div>
          <div className={classes.sitemapItem}>
            <h1>MY PAGE</h1>
            <ul>
              <li><Link to="/dashboard">Dashboard</Link></li>
              <li><Link onClick={e => handleThinkificSSO(e, '/enrollments')}>My Courses</Link></li>
              <li><Link to="/mypage/member">My Account</Link></li>
              <li><Link to="/subidmanagement">Sub id management</Link></li>
            </ul>
          </div>
          <div className={classes.sitemapItem}>
            <h1>SUPPORT</h1>
            <ul>
              <li><Link to="/support/contact-us">Contact Us</Link></li>
              <li><Link to="/support/faq/account">Faq</Link></li>
            </ul>
          </div>
          <div className={classes.sitemapItem}>
            <h1>NEWS</h1>
            <ul>
              <li><Link to="/notice">Notice</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}