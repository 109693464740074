import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import './ForgotForm.css';
import CustomLoading from './../../../common/components/CustomLoading';

const styles = theme => ({
  root: {
    margin: '10px',
    padding: theme.spacing(2),
    borderRadius: 0,
    // backgroundColor: '#319FE3',
    color: '#FFF',
    borderBottom: 0
  },
  closeButton: {
    position: 'absolute',
    right: '0px',
    top: '4px',
    color: 'black'
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    '& .MuiButton-contained': {
      margin: 'auto'
    }
  }
}))(MuiDialogActions);

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#36ADEF'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#36ADEF'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#36ADEF'
      }
    }
  }
})(TextField);

const CustomBox = withStyles({
  root: {
    height: '155.952px',
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid #E7E7E7',
    borderBottom: '1px solid #E7E7E7',
    backgroundColor: '#F8F8F8'
  }
})(Box);

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    backgroundColor: '#36ADEF',
    color: '#FFF',
    margin: theme.spacing(3, 0, 2)
  }
}));

const CustomDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    width: 709px;
    max-width: 709px;
  }

  & .MuiPaper-rounded {
    border-radius: 0px;
  }

  & .MuiDialogContent-dividers {
    border-top: 0;
    border-bottom: 0;
    width: 455px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
  }

  & .MuiDialog-paperScrollPaper {
    // height: 437px;
    // max-height: 437px;
  }

  & .MuiSvgIcon-root {
    font-size: 2rem;
    fill: #939393;
  }

  @media (min-width: 600px) {
    & .MuiTab-root {
      min-width: 217px;
    }
  }

  & .MuiTabs-flexContainer {
    padding: 5px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }

  & .MuiButton-root {
    border-radius: 25px;
    margin-bottom: 70px;
  }

  & .MuiPaper-elevation1 {
    box-shadow: none;
  }
`;

const CustomTabs = withStyles({
  indicator: {
    backgroundColor: '#36ADEF'
  }
})(Tabs);

const CustomTab = withStyles({
  root: {
    fontSize: '18px',
    fontWeight: 600,
    textTransform: 'capitalize',
    color: '#DEDEDE',
    '&$selected': {
      color: '#000'
    }
  },
  selected: {}
})(props => <Tab disableRipple {...props} />);

const CustomButton = withStyles({
  root: {
    width: '200px',
    height: '40px',
    textTransform: 'none',
    fontWeight: 600
  }
})(Button);

export default function ForgotForm({
  open,
  type,
  loadingForgot,
  userID,
  handleConfirm,
  handleClose,
  handleChange,
  forgotPasswordEmail,
  forgotPasswordName,
  toggleTextFieldForgotPasswordError,
  handleForgotPasswordInputChange,
  toggleTextFieldForgotIDError,
  forgotIDName,
  forgotIDMobile,
  handleForgotIDInputChange
}) {
  const classes = useStyles();

  const checkDisabled = () => {
    if (loadingForgot) {
      return true;
    }

    if (type === 0) {
      return !(forgotPasswordEmail && forgotPasswordName);
    } else {
      return !(forgotIDName && forgotIDMobile);
    }
  };

  return (
    <div>
      <CustomDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="ForgotForm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Find {type}
        </DialogTitle>
        <DialogContent dividers>
          <Paper className={classes.root}>
            <CustomTabs
              value={type}
              onChange={handleChange}
              // indicatorColor="primary"
              // textColor="primary"
              centered
            >
              <CustomTab label="Forgot Password" />
              <CustomTab label="Forgot ID" />
            </CustomTabs>
          </Paper>
          {type === 0 && (
            <form className={classes.form} noValidate>
              <CustomTextField
                variant="outlined"
                margin="normal"
                placeholder="E-mail address"
                required
                fullWidth
                value={forgotPasswordEmail}
                name="forgotPasswordEmail"
                type="text"
                autoComplete="email"
                autoFocus
                error={toggleTextFieldForgotPasswordError}
                onChange={handleForgotPasswordInputChange}
                helperText={
                  toggleTextFieldForgotPasswordError ? 'Email is incorrect' : ''
                }
              />
              <CustomTextField
                variant="outlined"
                margin="normal"
                placeholder="Name"
                required
                fullWidth
                value={forgotPasswordName}
                name="forgotPasswordName"
                type="text"
                autoComplete="name"
                error={toggleTextFieldForgotPasswordError}
                onChange={handleForgotPasswordInputChange}
                helperText={
                  toggleTextFieldForgotPasswordError ? 'Name is incorrect' : ''
                }
              />
            </form>
          )}
          {type === 1 && !userID && (
            <form className={classes.form} noValidate>
              <CustomTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="forgotIDName"
                type="text"
                autoComplete="name"
                placeholder="Name"
                autoFocus
                value={forgotIDName}
                error={toggleTextFieldForgotIDError}
                onChange={handleForgotIDInputChange}
                helperText={toggleTextFieldForgotIDError ? 'Name is incorrect' : ''}
              />
              <CustomTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="forgotIDMobile"
                type="text"
                placeholder="Mobie Number"
                autoComplete="mobile_number"
                value={forgotIDMobile}
                error={toggleTextFieldForgotIDError}
                onChange={handleForgotIDInputChange}
                helperText={
                  toggleTextFieldForgotIDError ? 'Mobile number is incorrect' : ''
                }
              />
            </form>
          )}
          {type === 1 && userID && (
            <CustomBox>
              <Typography style={{ color: '#000', fontSize: '18px' }}>
                Your ID is {userID}
              </Typography>
            </CustomBox>
          )}
        </DialogContent>
        <DialogActions>
          <CustomButton
            type="submit"
            variant="contained"
            autoFocus
            onClick={handleConfirm}
            fullwidth
            className={classes.submit}
            disabled={checkDisabled()}
          >
            {loadingForgot ? (
              <CustomLoading color="#00adef" size={16}></CustomLoading>
            ) : (
              'Confirm'
            )}
          </CustomButton>
        </DialogActions>
      </CustomDialog>
    </div>
  );
}
