import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthRoute from '../AuthRoute';
import MyPage from './MyPage';
import EditProfile from './EditProfile';
import EditCompanyAddtional from './EditCompanyAdditional';

export default function MyAccount() {
  return (
    <AuthRoute path="/mypage/member">
      <Switch>
        <Route
          exact
          path="/mypage/member"
          component={MyPage}
        />
        <Route
          exact
          path="/mypage/member/edit-profile"
          component={EditProfile}
        />
        <Route
          exact
          path="/mypage/member/edit-company-additional"
          component={EditCompanyAddtional}
        />
      </Switch>
    </AuthRoute>
  );
}
