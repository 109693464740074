import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '../../../common/components/button';
import Text from '../../../common/components/text';
import PopularProduct from '../../../common/components/product/PopularProduct';
import CartIcon from '../../../icons/Cart';
import { insertAlphaToHexColor } from '../../../utils/colors';
import axios from 'axios';
import {baseUrl} from '../../../config';
import Products from '../../../common/components/products';
import useBreakpoints from '../../../hooks/useBreakpoints';
import './emptyCart.css';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
`;

const InnerCircle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
  background-color: ${({ theme }) =>
    insertAlphaToHexColor(theme.colors.primaryLight, 0.7)};
  color: var(--light-color, #ffffff);
  font-size: 54px;
  z-index: 2;
`;

const OuterCircle = styled(InnerCircle)`
  width: 8rem;
  height: 8rem;
  background-color: ${({ theme }) =>
    insertAlphaToHexColor(theme.colors.primaryLight, 0.4)};
  z-index: 1;
`;

const IconWrapper = styled.div`
  width: 56px;
  height: 56px;
`;

const EmptyCart = ({onClick}) => {
  const size = useBreakpoints();
  const [columns, setColumns] = React.useState(1);
  const [popularProducts, set] = useState([]);

  const getPopularProducts = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/products/popular?qpoints=1000000`
      );
      set(data);
    } 
    catch (err) {
      console.log(err)
    }
  };

  useEffect(() => {
    getPopularProducts()
  }, []);

  useEffect(() => {
    switch (size) {
      case 'xl':
      case 'lg':
        setColumns(6);
        break;
      case 'md':
        setColumns(3);
        break;
      case 'sm':
        setColumns(2);
        break;
      case 'xs':
      default:
        setColumns(1);
    }
  }, [size]);
  
  return (
    <>
      <PageContainer className="EmptyCart">
        <OuterCircle className="EmptyCart_outer">
          <InnerCircle className="EmptyCart_inner">
            <IconWrapper className="EmptyCart_icon">
              <CartIcon color="var(--light-color, #ffffff)" />
            </IconWrapper>
          </InnerCircle>
        </OuterCircle>
        <Text size="h2" className="EmptyCart_title">Your Cart is Empty</Text>
        <Text
          color="secondaryDark"
          size="h4"
          weight="normal"
          style={{ margin: '1rem auto 1.5rem' }}
          className="EmptyCart_subtitle"
        >
          Why don't you head over to our Shop and redeem something with your
          Q.POINTs?
        </Text>
        <Link to="/rewards/shop">
          <Button color="secondary" style={{ backgroundColor: 'transparent' }} className="EmptyCart_button">
            Visit Shop
          </Button>
        </Link>
        <section style={{ width: '90%', marginTop: '120px' }}>
          <Text style={{ marginBottom: '1rem' }} as="h4" weight="medium">
            Popular Products
          </Text>
          <Products columns={columns} productCount={popularProducts.length}>
            {popularProducts.map(product => (
              <PopularProduct key={product.id} product={{...product, productImages: product.productImages}}  onClick={() => {
                onClick(product);
              }}/>
            ))}
          </Products>
        </section>
      </PageContainer>
    </>
  );
};

export default EmptyCart;
